import React from "react";
import ReactDOM from "react-dom";
import { MemoryRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { NivelesProvider } from "./Utils/Providers/NivelesProvider";
import { Provider } from "react-redux";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import "./index.css";
import App from "./App";
import store from "./state/store";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { closeSnackbar } from 'notistack'

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={5}
      preventDuplicate
      autoHideDuration={10000}
      iconVariant={{
        error: <ErrorOutlineIcon color="white" style={{ marginRight: 5, cursor:"pointer" }} />,
      }}
      action={(snackbarId) => (
        <CloseRoundedIcon onClick={() => closeSnackbar(snackbarId)} style={{ marginRight: 5 }} />
      )}
    >
      <NivelesProvider>
        <Router>
          <App />
        </Router>
      </NivelesProvider>
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);
