import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import Tabs from "../../Utils/Tabs";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlanTypeCombination from "../../Utils/PlanTypeCombination";
import CombinationBetweenCampuses from "../../Utils/CombinationBetweenCampuses";
import { getPersonCatalog } from "../../Utils/Funciones/getPersonCatalog";
import { getCampusCatalog } from "../../Utils/Funciones/getCampusCatalog";
import MaximumHoursCampuses from "../../Utils/MaximumHoursCampuses";
import { sleep } from "../../Utils/Funciones/sleep";

const ConfiguracionCampus = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [loadingMaxHour, setLoadingMaxHour] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const permisos = useSelector((state) => state.permisos);
  const [campus, setCampus] = useState("");
  const [tipoProfesorOptions, setTipoProfesorOptions] = useState([]);
  const [tipoProfesor, setTipoProfesor] = useState("");
  const [maxHorasDirector, setMaxHorasDirector] = useState('');
  const [maxHorasDivisonal, setMaxHorasDivisonal] = useState('');
  const [disabledMaxHoras, setDisabledMaxHoras] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [showButtons2, setShowButtons2] = useState(false);
  const [showButtons3, setShowButtons3] = useState(false);
  const [showTables, setShowTables] = useState(false);
  const [data_table2, setData_Table2] = useState([]);
  const [data_table3, setData_Table3] = useState([]);
  const dataTabsEmpty = [[], [], []];
  const [estatusDisabled2, setEstatusDisabled2] = useState(true);
  const [estatusDisabled3, setEstatusDisabled3] = useState(true);
  const [tiposPlanDisabled, setTiposPlanDisabled] = useState(true);

  const [optionsTiposPlan, setOptionsTiposPlan] = useState([]);
  const [campusCatalogo, setCampusCatalogo] = useState([]);
  const [estatus2, setEstatus2] = useState([]);
  const [dataMaxHoras, setDataMaxHoras] = useState('')
  const [activeTab, setActiveTab] = useState(1);
  const [tiposPlan, setTiposPlan] = useState([
    { id: 0, value: 3, label: "3-Cuatrimestral" },
    { id: 1, value: 4, label: "4-Semestral" },
    { id: 2, value: 5, label: "5-Anual" },
    { id: 3, value: 0, label: "" },
  ]);

  const optionsEstatus = [
    {
      value: "Vigente",
      label: "Vigente"
    },
    {
      value: "No Vigente",
      label: "No Vigente"
    }
  ]
  const namesTabs = [
    [1, "Máximo de Horas"],
    [2, "Combinación entre Campus"],
    [3, "Combinación Tipos de Plan"]
  ];

  const handleEstatus2 = async (ev, id) => {
    let newArr = [...data_table2];
    let newEstatus = [...estatus2];
    if (estatus2.length === 0) {
      campusCatalogo.forEach((item, i) => {
        if (item.status === "True") {
          newEstatus[i] = {
            value: 1,
            label: "Vigente"
          }
        } else {
          newEstatus[i] = {
            value: 0,
            label: "No Vigente"
          }
        }
      })
      if (ev.value === 1) {
        newArr[id]["status"] = "True";
        newEstatus[id] = ev;
        setEstatus2(newEstatus);
        setData_Table2(newArr);
      } else {
        newArr[id]["status"] = "False";
        newEstatus[id] = ev;
        setEstatus2(newEstatus);
        setData_Table2(newArr);
      }
    } else {
      if (ev.value === 1) {
        newArr[id]["status"] = "True";
        newEstatus[id] = ev;
        setEstatus2(newEstatus);
        setData_Table2(newArr);
      } else {
        newArr[id]["status"] = "False";
        newEstatus[id] = ev;
        setEstatus2(newEstatus);
        setData_Table2(newArr);
      }
    }
  };

  const handleTiposPlan = async (ev, id) => {
    if (tiposPlan === "") {
      setTiposPlan([ev]);
    } else {
      let newArr = [...tiposPlan];
      newArr[id] = ev;
      setTiposPlan(newArr);
    }
  };

  async function handleEditMaxHoras() {
    setLoadingMaxHour(true)
    try {
      const payload = {
        type: "maximo_horas_campus_tipo_persona",
        clave_campus: dataMaxHoras.clave_campus,
        clave_tipo_persona: tipoProfesor,
        total_horas_por_semana_director: maxHorasDirector,
        total_horas_por_semana_divisional: maxHorasDivisonal
      };
      const response = await postRequest("/catalogo", payload);
      if (response.s === "OK") {

        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        handleClickSearchMaxHours()
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoadingMaxHour(false)
      }
    } catch (error) {
      console.log(error);
      setLoadingMaxHour(false)
    }
  }

  async function handleClickSearchMaxHours() {

    setLoadingMaxHour(true)
    try {
      const request = `/buscar?tipo=maximo_horas_campus_tipo_persona&valor=clave_campus:${campus.clave},clave_tipo_persona:${tipoProfesor}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        const data = {
          total_horas_por_semana_director: response.d.total_horas_por_semana_director,
          total_horas_por_semana_divisional: response.d.total_horas_por_semana_divisional,
          clave_campus: response.d.clave_campus,
          creado: response.d.creado,
          creado_por: response.d.creado_por,
          modificado: response.d.modificado,
          modificado_por: response.d.modificado_por,
          clave_tipo_persona: response.d.clave_tipo_persona
        }
        setDataMaxHoras(data)
        setMaxHorasDirector(data.total_horas_por_semana_director)
        setMaxHorasDivisonal(data.total_horas_por_semana_divisional)
        setShowButtons(false);
        setDisabledMaxHoras(true);
        setShowTables(true);
        setLoadingMaxHour(false);
      }


    } catch (error) {
      setLoadingMaxHour(false)
      console.log(error);

    }
  }

  async function handleClickSearchPlanType() {
    try {
      setLoading3(true)
      const request = `/buscar?tipo=planes_campus_tipo_persona&valor=clave_campus:${campus.clave},clave_tipo_persona:${tipoProfesor}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setShowTables(true);
        setLoadingMaxHour(false);
        setData_Table3(response.d)
        setLoading3(false)
      }

    } catch (error) {
      setLoading3(false)
      console.log(error);

    }
  }

  async function handleClickCancelar() {
    try {
      setLoading2(true);
      setLoading3(true);
      setShowButtons(false);
      setShowButtons2(false);
      setShowButtons3(false);
      setDisabledMaxHoras(true);
      setEstatusDisabled2(true);
      setEstatusDisabled3(true);
      setTiposPlanDisabled(true);
      await sleep(500)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
      setLoading3(false);
    }
  }

  function handleClickEditar1() {
    setDisabledMaxHoras(false);
    setShowButtons(true);
  }

  async function handleClickEditar2() {
    setShowButtons2(true);
    setEstatusDisabled2(false);
  }

  async function handleClickEditar3() {
    setShowButtons3(true);
    setEstatusDisabled3(false);
    setTiposPlanDisabled(false);
  }

  const handleClickSave3 = async (dataModifier) => {
    const payload = {
      type: "planes_campus_tipo_persona",
      data: dataModifier,
    }
    try {
      setLoading3(true);
      const response = await postRequest(
        `/catalogo`,
        payload
      );
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setShowButtons3(false);
        setEstatusDisabled3(true);
        setTiposPlanDisabled(true);
        handleClickSearchPlanType()
      } else {
        setLoading3(false);
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        handleClickSearchPlanType()
      }
    } catch (error) {
      console.log(error)
      handleClickSearchPlanType()
      setLoading3(false);
    }
  }

  async function handleTipoProfesor(e) {
    setTipoProfesor(e);
  }

  async function handleCampus(e) {
    setCampus(e);
  }

  const handleChangeTabs = (tab) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    if (campus && tipoProfesor && activeTab === 1) {
      handleClickSearchMaxHours();
    }
    else if (campus && tipoProfesor && activeTab === 3) {
      handleClickSearchPlanType()
    }
  }, [tipoProfesor, campus, activeTab]);

  useEffect(() => {
    getPersonCatalog().then((data) => setTipoProfesorOptions(data));
    getCampusCatalog().then((data) => setCampusCatalogo(data));
    setCampus(
      campusUser?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const containersTabs = [
    [<MaximumHoursCampuses
      permisos={permisos}
      handleClickCancelar={handleClickCancelar}
      handleClickEditar={handleClickEditar1}
      showButtons={showButtons}
      disabledMaxHoras={disabledMaxHoras}
      maxHorasDirector={maxHorasDirector}
      maxHorasDivisonal={maxHorasDivisonal}
      setMaxHorasDirector={setMaxHorasDirector}
      setMaxHorasDivisonal={setMaxHorasDivisonal}
      dataMaxHoras={dataMaxHoras}
      handleEditMaxHoras={handleEditMaxHoras}
    />]
    ,
    [<CombinationBetweenCampuses
      permisos={permisos}
      handleClickCancelar={handleClickCancelar}
      handleClickEditar={handleClickEditar2}
      data={[]}
      loading={loading2}
      showButtons={!showButtons2}
      handleSaveRow={() => { }}
      tiposPlanDisabled={tiposPlanDisabled}
      optionsTiposPlan={optionsTiposPlan}
      tiposPlan={tiposPlan}
      handleTiposPlan={handleTiposPlan}
      estatusDisabled={estatusDisabled2}
      optionsEstatus={optionsEstatus}
      estatus={estatus2}
      handleEstatus={handleEstatus2}
    />],
    [<PlanTypeCombination
      permisos={permisos}
      handleClickCancelar={handleClickCancelar}
      handleClickEditar={handleClickEditar3}
      handleClickSave={handleClickSave3}
      data={data_table3}
      loading={loading3}
      showButtons={!showButtons3}
      estatusDisabled={estatusDisabled3}
      optionsEstatus={optionsEstatus}
    />]
  ];

  return (
    <>
      <Contenedor title="Configuración / Campus">
        <div className="inputs-asignaciones">
          <Grid container style={{ position: "relative" }}>
            <Grid container item xs={12} sm={9}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "3%" }}>
                  <label>Campus(*):</label>
                </Grid>
                <Grid item xs={12} sm={2} style={{ zIndex: 99 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={campusUser}
                    value={campus}
                    onChange={(e) => handleCampus(e)}
                  />
                </Grid>
                <Grid item xs={3} sm={3} style={{ textAlign: "end", marginLeft: "-1%" }}>
                  <label>Tipo Persona(*):</label>
                </Grid>
                <Grid item xs={12} sm={3} style={{ zIndex: 99 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={tipoProfesorOptions}
                    onChange={(e) => handleTipoProfesor(e.clave)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "90%", marginTop: '1%', zIndex: 6 }}>
          {loadingMaxHour ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "60%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: '999'
              }}
            >
              <CircularProgress size={50} />
            </div>
          ) : <Tabs
            modalTabs
            namesTabs={namesTabs}
            containersTabs={showTables ? containersTabs : dataTabsEmpty}
            handleChangeTabs={handleChangeTabs}
          />}

        </div>
      </Contenedor>
    </>
  );
};

export default ConfiguracionCampus;


