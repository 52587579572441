import { View, Text } from '@react-pdf/renderer'
import ReportKardexAcademicoPDF from '../ReportKardexAcademicoPDF'

export default function KardexAcademicoPDF({
    metadata,
    data,
    title,
    fechaReporte,
    teacherKey = "",
    tipo_profesor,
    direccion = "",
    director,
    grupo,
    materia,
    totalBox
}) {
    return (
        <View>
            <ReportKardexAcademicoPDF
                metadata={metadata}
                data={data}
                fechaReporte={fechaReporte}
                teacherKey={teacherKey}
                tipo_profesor={tipo_profesor}
                direccion={direccion}
                director={director}
                grupo={grupo}
                materia={materia}
                title={title}
                totalBox={totalBox}
            />
            <View
                fixed
                style={{
                    height: 50,
                    position: 'absolute',
                    bottom: 30,
                    left: 0,
                    right: 0,
                    top: 520
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <View>
                        <Text
                            style={{
                                color: 'gray',
                                fontSize: 11,
                            }}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}