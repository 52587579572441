import { Grid, Typography } from "@material-ui/core";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import { getRequest } from "../../Utils/Funciones/requester";
import { generateCSV } from "../../Utils/generateCSV/generateCSV";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { useSelector } from "react-redux";

import Contenedor from "../../Utils/Visuales/Contenedor";
import SearchInput from "../../Utils/Visuales/SearchInput";

const IndicadoresOnline = () => {
  const campusName = useSelector((state) => state.campusName);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [cicloValue, setCicloValue] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueEscuela, setValueEscuela] = useState("");

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 1,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Clave Dirección",
      id: "clave_direccion",
      col: 2,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Nombre Dirección",
      id: "nombre_direccion",
      col: 3,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Nombre Director",
      id: "nombre_director",
      col: 4,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Módulo",
      id: "modulo",
      col: 5,
      fn: null,
      width: "18%",
      align: "center",
    },
    {
      name: "Curso",
      id: "curso",
      col: 6,
      fn: null,
      width: "19%",
      align: "center",
    },
    {
      name: "Total Grupos",
      id: "total_grupos",
      col: 7,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Total Horas Programadas",
      id: "total_horas_programadas",
      col: 8,
      fn: null,
      width: "15%",
      align: "center",
    },
   {
     name: "Total Horas Asignadas",
     id: "total_horas_asignadas",
     col: 9,
     fn: null,
     width: "15%",
     align: "center",
   },
    // {
    //   name: "Total Horas Incapacidad",
    //   id: "total_horas_incapacidad",
    //   col: 10,
    //   fn: null,
    //   width: "15%",
    //   align: "center",
    // },
  ];

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleGeneratedReport = async () => {
    const date = moment().format("DD/MM/YYYY");
    const dataTable = data.map((item) =>
      Object.fromEntries(item.map((_, i) => [headers[i].name, item[i]]))
    );
    await generateCSV({
      data: dataTable,
      fileName: `Reportes_Indicadores_ONLINE_${date}.xlsx`,
    });
  };

  function downloadCsv() {
    setLoadingTable(true);
    handleGeneratedReport().finally(() => {
      setLoadingTable(false);
    });
  }
  const DownloadPDF = async () => {
    setReq(true);
    const dataPDF = data.map((e) => {
      const dataPDF2 = {
        ciclo: e[0],
        escuela: e[1],
        clave_direccion: e[2],
        nombre_direccion: e[3],
        nombre_director: e[4],
        modulo: e[5],
        curso: e[6],
        total_grupos: e[7],
        total_horas_programadas: e[8],
        //total_horas_asignadas: e[9],
        total_horas_incapacidad: e[10],
      };
      return dataPDF2;
    });
    const date = moment().format("DD/MM/YYYY");
    const hour = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={dataPDF ?? []}
        title={`Reportes Indicadores ONLINE`}
        campus={campusName}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_prefactura_${date}_${hour}.pdf`);
    setReq(false);
  };

  async function handleClick() {
    setLoading(true);
    setShowTable(true);
    let valido = true;
    if (cicloValue === "") {
      enqueueSnackbar("Debe Ingresar Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }

    if (valido) {
      try {
        const datos = [];
        let url = `/reportes/indicadores_online?ciclo=${cicloValue}`;
        if (valueEscuela) url += `&id_escuela=${valueEscuela.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["escuela"][i],
              response.d[2]["clave_direccion"][i],
              response.d[3]["nombre_direccion"][i],
              response.d[4]["nombre_director"][i],
              response.d[5]["modulo"][i],
              response.d[6]["curso"][i],
              response.d[7]["total_grupos"][i],
              response.d[8]["total_horas_programadas"][i],
              response.d[9]["total_horas_asignadas"][i],
              //response.d[10]["total_horas_incapacidad"][i],
            ]);
          }
          setLoading(false);
          setData(datos);
          setDataOriginal(datos);
          setShowTable(true);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
    setLoading(false);
  }

  return (
    <Contenedor title="Reportes / Indicadores ONLINE">
      <Grid
        container
        className="nuevoUsuarioForm"
        style={{ position: "relative", zIndex: 9 }}
      >
        <Grid container item xs={12} sm={9}>
          {/* Primera Fila */}
          <Grid
            container
            alignItems="center"
            spacing={2}
            style={{ marginTop: "-85px" }}
          >
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={14} sm={3}>
              <input
                className="filter-input input-ciclo"
                placeholder="Indique el ciclo..."
                value={cicloValue}
                onChange={(e) => setCicloValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Escuela:</label>
            </Grid>
            <Grid item xs={10} sm={3}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueEscuela}
                options={optionsEscuela}
                onChange={setValueEscuela}
              />
            </Grid>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Dirección:</label>
            </Grid>
            <Grid item xs={10} sm={3}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueDireccion}
                options={optionsDireccion}
                onChange={setValueDireccion}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* BOTONES */}
        <Grid container item xs={12} sm={3} spacing={2}>
          <Grid
            container
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
          >
            <EditButton onClick={handleClick} style={{ padding: "10px 25px" }} disabled={loading}>
              Generar
            </EditButton>
            <div style={{ display: "flex", marginLeft: "10px" }} className="export-buttons">
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button
                  onClick={downloadCsv}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={excelIcon} alt="excel-icon" />
                </button>
                {/* <button
                  onClick={DownloadPDF}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={pdfFileIcon} alt="pdf-file-icon" />
                </button> */}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 180px)" }}>
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
};

export default IndicadoresOnline;
