import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";

import NewModal from "../../../Utils/Visuales/NewModal";
import NewButton from "../../../Utils/Botones/NewButton";
import DataTable from "../../../Utils/DataTables";
import EditButton from "../../../Utils/Botones/EditButton";
import styles from "../styles.module.css";
import { getRequest, postRequest } from "../../../Utils/Funciones/requester";
import moment from "moment";
import "moment/locale/es";
import { checkIfJExists } from "../../../Utils/Funciones/checkIfJExists";

let grupoTimer;

function Reposicion(props) {
  const [loading, setLoading] = useState(false);
  const [modalBuscarFaltaIsOpen, setModalBuscarFaltaIsOpen] = useState(false);
  const [dataFinal, setDataFinal] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const campus_id = useSelector((state) => state.campusId);
  const [campusID] = useState(campus_id);
  const [profesor, setProfesor] = useState("");
  const [materia, setMateria] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [grupo, setGrupo] = useState("");
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [datosDeFalta, setDatosDeFalta] = useState({});
  const [profesorValue, setProfesorValue] = useState({});
  const [minutosInicia, setMinutosInicia] = useState("");
  const [horaInicia, setHoraInicia] = useState("");
  const [horaTermina, setHoraTermina] = useState("");
  const [horaIniciaComparation, setHoraIniciaComparation] = useState("");
  const [horaTerminaComparation, setHoraTerminaComparation] = useState("");
  const [tipoSalonOptions, setTipoSalonOptions] = useState([]);
  const [tipoSalon, setTipoSalon] = useState({
    label: "",
    value: "",
  });
  const [selectTipoSalonIsLoading, setSelectTipoSalonIsLoading] =
    useState(false);
  const [salonOptions, setSalonOptions] = useState([]);
  const [salon, setSalon] = useState({
    label: "",
    value: "",
  });
  const [selectSalonIsLoading, setSelectSalonIsLoading] = useState(false);
  const [fechaMovimiento, setFechaMovimiento] = useState("");
  const [tema, setTema] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setDatosDeFalta({});
    setFechaMovimiento("");
    setHoraInicia("");
    setHoraTermina("");
    setTipoSalon({});
    setSalon({});
    setTema("");
    setHoraIniciaComparation("");
    setHoraTerminaComparation("");
  }, [props.open]);

  useEffect(() => {
    clearTimeout(grupoTimer);
    if (grupo.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(
          campusID,
          profesor.value,
          grupo,
          props.ciclo,
          props.direccion.value
        );
      }, waitTime);
    }
  }, [grupo]);

  useEffect(() => {
    setTipoSalon({
      label: "",
      value: "",
    });
    setSalon({
      label: "",
      value: "",
    });
  }, [fechaMovimiento, horaInicia, minutosInicia, profesor]);

  function handleClickBuscarFalta() {
    setGrupo("");
    setMateriaOptions([]);
    setFechaInicio("");
    setFechaFin("");
    setDataFinal([]);
    setModalBuscarFaltaIsOpen(true);
  }

  const headers = [
    {
      name: "Grupo",
      col: 0,
    },
    {
      name: "Clave Materia",
      col: 1,
    },
    {
      name: "Nombre Materia",
      col: 2,
    },
    {
      name: "Clave Profesor",
      col: 3,
    },
    {
      name: "Nombre Profesor",
      col: 4,
    },
    {
      name: "Fecha",
      col: 5,
    },
    {
      name: "Hora Inicio",
      col: 6,
    },
    {
      name: "Hora Llegada",
      col: 7,
    },
    {
      name: "Acciones",
      col: 8,
    },
  ];

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const selectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  function promiseOptions(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  async function searchMateria(id_campus, id_person, grupo, ciclo, direccion) {
    setSelectMateriaIsLoading(true);
    try {
      const options = [];
      let consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      if (id_person) {
        consulta += `,id_person:${id_person}`;
      }
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
          });
        });
        if (options.length === 0) {
          enqueueSnackbar(
            "El grupo ingresado no encuentra materias asignadas",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
        }
        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  function handleChangeGrupo(ev) {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(ev.target.value)) {
      setGrupo(ev.target.value);
      setMateria("");
      setMateriaOptions([]);
    }
  }

  async function handleClickSeleccionar(details, id_profesor = "") {
    const fecha = details.fecha.split("-");
    const newFecha = fecha.reverse().join("-");
    details.fecha = newFecha;
    details.id_profesor = id_profesor ? id_profesor : details.id_person;
    setHoraInicia(details.hora_inicio.substring(0, 2));
    setMinutosInicia(details.hora_inicio.substring(3, 5));
    setHoraTermina(details.hora_inicio.substring(6, 11));
    setHoraIniciaComparation(
      moment(details.hora_inicio.substring(0, 5), "HH:mm")
    );
    setHoraTerminaComparation(
      moment(details.hora_inicio.substring(6, 11), "HH:mm")
    );
    setDatosDeFalta(details);
    getTipoSalonCatalogo();
    setProfesor("");
    setFechaInicio("");
    setFechaFin("");
    setGrupo("");
    setMateria("");
    setModalBuscarFaltaIsOpen(false);
  }

  async function handleClickBuscar() {
    setLoadingTable(true);
    let valido = true;
    if (fechaInicio === "") {
      enqueueSnackbar("Falta Seleccionar Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaFin === "") {
      enqueueSnackbar("Falta Seleccionar Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        let endpoint = `/buscar?tipo=dictamen&valor=ciclo:${props.ciclo},id_campus:${campusID},id_direccion:${props.direccion.value},fi:${fechaInicio},ff:${fechaFin}`;
        if (grupo) {
          endpoint += `,grupo:${grupo}`;
        }
        if (materia) {
          endpoint += `,id_materia:${materia.value}`;
        }
        if (profesor.value !== undefined) {
          endpoint += `,id_profesor:${profesor.value}`;
        }
        const response = await getRequest(endpoint);

        if (response.s === "OK") {
          if (response.d.length === 0) {
            setDataFinal([]);
            enqueueSnackbar("No se han encontrado faltas con estos datos", {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          } else {
           
            let data = [];
            response.d.forEach((item) => {
              data.push([
                item.grupo,
                item.clave_materia,
                item.nombre_materia,
                item.clave_profesor,
                item.nombre_profesor,
                item.fecha,
                item.hora_inicio,
                item.hora_llegada,
                <EditButton
                  onClick={() => handleClickSeleccionar(item, profesor.value)}
                >
                  Seleccionar
                </EditButton>,
              ]);
            });
            setDataFinal(data);
          }
        } else {
          setDataFinal([]);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoadingTable(false);
  }

  function handleHoraInicia(value) {
    var diff =
      horaTerminaComparation.diff(horaIniciaComparation, "minutes") / 60;
    if (diff === 1.5) {
      if (value >= 0 && value <= 23 && value.length < 3) {
        setHoraInicia(value);
        const horaFin = moment(`${value}:${minutosInicia}`, "HH:mm")
          .add(90, "minutes")
          .format("HH:mm");
        setHoraTermina(horaFin);
      }
    } else {
      if (value >= 0 && value <= 23 && value.length < 3) {
        setHoraInicia(value);
        const horaFin = moment(`${value}:${minutosInicia}`, "HH:mm")
          .add(datosDeFalta.duracion_hr, "hours")
          .format("HH:mm");
        setHoraTermina(horaFin);
      }
    }
  }

  function handleMinutosInicia(value) {
    var diff =
      horaTerminaComparation.diff(horaIniciaComparation, "minutes") / 60; // Diff in days
    if (diff === 1.5) {
      if (value >= 0 && value <= 59 && value.length < 3) {
        if (value < 10) {
          let valueModificado = "0" + value;
          setMinutosInicia(valueModificado);
          const horaFin = moment(`${horaInicia}:${valueModificado}`, "HH:mm")
            .add(90, "minutes")
            .format("HH:mm");
          setHoraTermina(horaFin);
        } else {
          setMinutosInicia(value);
          const horaFin = moment(`${horaInicia}:${value}`, "HH:mm")
            .add(90, "minutes")
            .format("HH:mm");
          setHoraTermina(horaFin);
        }
      }
    } else {
      if (value >= 0 && value <= 59 && value.length < 3) {
        if (value < 10) {
          let valueModificado = "0" + value;
          setMinutosInicia(valueModificado);
          const horaFin = moment(`${horaInicia}:${valueModificado}`, "HH:mm")
            .add(datosDeFalta.duracion_hr, "hours")
            .format("HH:mm");
          setHoraTermina(horaFin);
        } else {
          setMinutosInicia(value);
          const horaFin = moment(`${horaInicia}:${value}`, "HH:mm")
            .add(datosDeFalta.duracion_hr, "hours")
            .add(datosDeFalta.duracion_hr, "minutes")
            .format("HH:mm");
          setHoraTermina(horaFin);
        }
      }
    }
  }

  async function getTipoSalonCatalogo() {
    setSelectTipoSalonIsLoading(true);

    try {
      const endpoint = `/catalogo?id=24`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["type"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setTipoSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectTipoSalonIsLoading(false);
    }
  }

  async function getSalonOptions(id_tipo_salon) {
    setSelectSalonIsLoading(true);

    const horarios = `dias: ${moment(fechaMovimiento).format(
      "dddd"
    )} horas_inicio: ${horaInicia}:${minutosInicia} horas_fin: ${horaTermina}`;
    try {
      const endpoint = `/buscar?tipo=salon&valor=id_tipo_salon:${id_tipo_salon},id_campus:${campusID},fecha:${fechaMovimiento},hora_inicio:${horaInicia},hora_fin:${horaTermina.substring(
        0,
        2
      )}`;
      const response = await getRequest(endpoint);
      if (response.s === "OK") {
        const request = `/reportes/salones_ocupados?fecha_inicial=${fechaMovimiento}&fecha_final=${fechaMovimiento}&id_campus=${campusID}&horario=${horarios}`;
        const response2 = await getRequest(request);
        if (response2.s === "OK") {
          const busyClassroomsInitial = [];
          const stringDay = moment(fechaMovimiento)
            .format("dddd")[0]
            .toUpperCase();
          response2.d.forEach((responseItem) => {
            if (
              checkIfJExists(
                `${horaInicia}:${minutosInicia}`,
                `${horaTermina}`,
                responseItem.timeslots,
                moment(fechaMovimiento).format("dddd") === "miércoles"
                  ? "Mi"
                  : stringDay
              )
            ) {
              busyClassroomsInitial.push(responseItem.id_classroom);
            }
          });
          const dataObjectInicio = response.d
            .map((item, i) => {
              if (!busyClassroomsInitial.includes(`${item.id_salon}`)) {
                const data_ = {
                  label: item.nombre,
                  value: item.id_salon,
                };
                return data_;
              }
              return null;
            })
            .filter((item) => item !== null);
          setSalonOptions(dataObjectInicio);
        } else {
          enqueueSnackbar(response2.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectSalonIsLoading(false);
    }
  }

  function handleChangeTipoSalon(ev) {
    setTipoSalon(ev);
    getSalonOptions(ev.value);
  }

  function handleChangeSalon(ev) {
    setSalon(ev);
  }

  async function handleSubmit() {
    if (datosDeFalta["id_dictamen"] === undefined) {
      enqueueSnackbar("Se debe seleccionar una falta", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (
      profesorValue.value === "" ||
      fechaMovimiento === "" ||
      horaInicia === "" ||
      minutosInicia === "" ||
      tipoSalon.value === "" ||
      salon.value === "" ||
      tema === ""
    ) {
      enqueueSnackbar("Se deben llenar todos los datos de la reposición", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const endpoint = `/asignaciones/clases-especiales/nueva`;
      const body = {
        id_asignacion: datosDeFalta.id_assignment,
        id_profesor: datosDeFalta.id_profesor,
        id_profesor_nuevo: profesorValue.value,
        id_dictamen: datosDeFalta.id_dictamen,
        fecha: datosDeFalta.fecha,
        fechaNuevo: fechaMovimiento,
        hora: "",
        hora_inicio: `${horaInicia}:${minutosInicia}`,
        id_salon: salon.value,
        tema: tema,
        tipo_clase_especial: props.tipoClaseEspecial,
      };
      if (datosDeFalta.id_profesor === null) {
        delete body.id_profesor
      }
      const response = await postRequest(endpoint, body);
      if (response.s === "OK") {
        setProfesorValue("");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        props.handleClose();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    moment.locale("es");
  }, []);

  return (
    <>
      <NewModal
        title="Clase Especial Reposición"
        open={props.open}
        handleClose={props.handleClose}
        marginTop={"-35px"}
        height={700}
        maxWidth={1000}
        loading={loading}
        overflowY={"unset"}
        handleSubmit={handleSubmit}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "30px", alignItems: "center" }}
          >
            <Grid item xs={1} style={{ marginLeft: "-3%" }}>
              <label className={styles.inputLabel}>Ciclo:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                className={styles.filterInput}
                style={{ width: "130%" }}
                value={props.ciclo}
                disabled
              />
            </Grid>

            <Grid item xs={1} style={{ marginLeft: "3%" }}>
              <label className={styles.inputLabel}>Dirección:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.direccion.label}
                disabled
              />
            </Grid>

            <Grid item xs={1}>
              <label className={styles.inputLabel}>Director:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.director}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "25px",
              alignItems: "center",
            }}
          >
            <Grid item xs={2}>
              <h4 className={styles.modalSectionName}>Datos de la falta</h4>
            </Grid>
            <Grid item xs={7} />
            <Grid item xs={3}>
              <NewButton
                customText="Buscar Falta"
                noIcon
                style={{ padding: "5px 15px" }}
                onClick={handleClickBuscarFalta}
              />
            </Grid>

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid item xs={5}>
              <input
                disabled
                className={styles.filterInput}
                value={
                  datosDeFalta.clave_profesor === undefined ||
                  datosDeFalta.clave_profesor === null
                    ? ""
                    : `${datosDeFalta.clave_profesor} - ${datosDeFalta.nombre_profesor}`
                }
              />
            </Grid>
            <Grid item xs={5} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                value={datosDeFalta.grupo}
                style={{ width: "160px" }}
                disabled
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={7}>
              <input
                className={styles.filterInput}
                style={{ width: "150px", marginRight: "10px" }}
                value={datosDeFalta.clave_materia}
                disabled
              ></input>
              <input
                disabled
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 150px - 10px - 10px)",
                  marginRight: "10px",
                }}
                value={datosDeFalta.nombre_materia}
              ></input>
            </Grid>

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Fecha:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                label="Seleccionar fecha"
                type="date"
                value={datosDeFalta.fecha}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "160px", fontSize: "16px" }}
                disabled
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Horario:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={datosDeFalta.hora_inicio}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <h4 className={styles.modalSectionName}>
                Datos de la Reposición
              </h4>
            </Grid>
            <Grid item xs={9} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={5} item style={{ padding: 2, zIndex: 79, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                onChange={setProfesorValue}
                value={profesorValue}
              />
            </Grid>
            <Grid item xs={5} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                value={datosDeFalta.grupo}
                style={{ width: "160px" }}
                disabled
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={7}>
              <input
                className={styles.filterInput}
                style={{ width: "150px", marginRight: "10px" }}
                value={datosDeFalta.clave_materia}
                disabled
              ></input>
              <input
                disabled
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 150px - 10px - 10px)",
                  marginRight: "10px",
                }}
                value={datosDeFalta.nombre_materia}
              ></input>
            </Grid>

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Fecha Movimiento:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                label="Seleccionar fecha"
                type="date"
                // defaultValue='2022-11-24'
                value={fechaMovimiento}
                onChange={(ev) => setFechaMovimiento(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "160px", fontSize: "16px" }}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Inicia:</label>
            </Grid>
            <Grid item xs={2} style={{ display: "flex" }}>
              <input
                className="filter-input"
                type="number"
                min="0"
                max="23"
                value={horaInicia}
                onChange={(e) => handleHoraInicia(e.target.value)}
              />
              <input
                className="filter-input"
                style={{ marginLeft: "1%" }}
                type="number"
                min="0"
                max="59"
                // placeholder="00"
                value={minutosInicia}
                onChange={(e) => handleMinutosInicia(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Termina:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={horaTermina}
                disabled
              />
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tipo Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                className={styles.tipoSalon}
                classNamePrefix="select-modal"
                options={tipoSalonOptions}
                styles={selectStyles}
                isLoading={selectTipoSalonIsLoading}
                onChange={handleChangeTipoSalon}
                value={tipoSalon}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={salonOptions}
                styles={selectStyles}
                isLoading={selectSalonIsLoading}
                onChange={handleChangeSalon}
                value={salon}
              />
            </Grid>
            <Grid item xs={5} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tema:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 10px)",
                  marginRight: "10px",
                }}
                value={tema}
                onChange={(ev) => setTema(ev.target.value)}
              ></input>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Búsqueda de Faltas"
        open={modalBuscarFaltaIsOpen}
        handleClose={() => {
          setProfesor("");
          setFechaInicio("");
          setFechaFin("");
          setGrupo("");
          setMateria("");
          setModalBuscarFaltaIsOpen(false);
        }}
        height={750}
        maxWidth={1200}
      >
        <div style={{ padding: "25px" }}>
          <Grid container spacing={1} style={{ alignItems: "center" }}>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Ciclo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                disabled
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={props.ciclo}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.5%" }}>
              <label
                className={styles.inputLabel}
                style={{ whiteSpace: "nowrap" }}
              >
                Clave Profesor:
              </label>
            </Grid>
            <Grid xs={3} item style={{ padding: 2, zIndex: 79, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                value={profesor}
                onChange={setProfesor}
              />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "-6.1%" }}>
              <label className={styles.inputLabel}>Fecha Inicio(*):</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                label="Seleccionar fecha"
                type="date"
                value={fechaInicio}
                onChange={(ev) => setFechaInicio(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "170px" }}
              />
            </Grid>
            <Grid item xs={1}>
              <NewButton
                customText="Buscar"
                noIcon
                style={{ padding: "5px 15px" }}
                onClick={handleClickBuscar}
              />
            </Grid>

            <Grid item xs={1}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={grupo}
                onChange={handleChangeGrupo}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.3%" }}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={3}>
              <Select
                className={styles.materiaSelect}
                classNamePrefix="select-modal"
                isClearable={true}
                options={materiaOptions}
                styles={selectStyles}
                isLoading={selectMateriaIsLoading}
                value={materia}
                onChange={setMateria}
              />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "-5.9%" }}>
              <label className={styles.inputLabel}>Fecha Fin(*):</label>
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "0%" }}>
              <input
                className="filter-input"
                label="Seleccionar fecha"
                type="date"
                value={fechaFin}
                onChange={(ev) => setFechaFin(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "170px" }}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ height: "calc(100% - 120px)" }}>
          <DataTable
            headers={headers}
            data={dataFinal}
            loading={loadingTable}
            centrar={[3, 4]}
            paginate
            bordes
          />
        </div>
      </NewModal>
    </>
  );
}

export default Reposicion;
