import React from "react";
import "./style.css";

export default function EditButton(props) {
  return (
    <button
      disabled={props.disabled}
      id={props.id}
      className='EditButton'
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </button>
  );
}
