
import React, { useMemo } from 'react'
import { View, Text } from '@react-pdf/renderer'
import ReportExtraordinaryPaymentsPDFTable from '../ReportExtraordinaryPaymentsPDFTable'
import { divideArrayIntoParts } from '../Funciones/divideArrayIntoParts'
import HeaderPDFDefault from '../HeaderPDFDefault'

export default function ReportExtraordinaryPaymentsPDF({
    metadata,
    data,
    startDate,
    endDate,
    title
}) {
    const campusName = useMemo(() => {
        const name = []
        data.forEach(element => {
            if (!name.includes(element.campus)) {
                name.push(element.campus)
            }
        });
        return name
    }, [data])
    return campusName.map((item, index) => {
        const dataForTable = data.filter((element) => element.campus === item)
        const splitDataIntoParts = divideArrayIntoParts(dataForTable, 7)
        return splitDataIntoParts.map((dataTable, index2) => {
            return (
                <View break={index === 0 && index2 === 0 ? false : true} key={`${index}-${index2}`} style={{ height: 495 }}>
                    <HeaderPDFDefault title={title} />
                    <View style={{ marginTop: 15 }}>
                        <View style={{ marginTop: 1 }}>
                            {startDate ? (
                                <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                                    <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Quincena Inicial: {startDate}</Text>
                                    {endDate ? (
                                        <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Quincena Final: {endDate}</Text>
                                    ) : null}
                                    <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Campus: {item}</Text>
                                </View>
                            ) : null}
                            <ReportExtraordinaryPaymentsPDFTable
                                key={`${index}-${index2}`}
                                metadata={metadata}
                                data={dataTable}
                                campus={item}
                            />
                        </View>
                    </View>
                </View>
            )
        })
    })
}