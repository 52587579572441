import React, { useState, useEffect } from "react";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
export default function Permisos({
  listaPermisos,
  todosPermisos,
  idPermisoValue = null,
}) {
  const [catalogo, setCatalogo] = useState(null);
  const [permisos, setPermisos] = useState([]);
  useEffect(() => {
    if (catalogo === null) {
      if (idPermisoValue !== null) {
        const catalogoValue = idPermisoValue.value;
        window.localStorage.setItem(
          "permisos",
          JSON.stringify(todosPermisos[`${catalogoValue}`])
        );
        window.localStorage.setItem(
          "idPermisos",
          todosPermisos[`${catalogoValue}`][0].id_catalogo
        );
        setPermisos(todosPermisos[`${catalogoValue}`]);
      }
    } else {
      console.log(catalogo)
      const catalogoValue = catalogo.value;
      window.localStorage.setItem(
        "permisos",
        JSON.stringify(todosPermisos[`${catalogoValue}`])
      );
      window.localStorage.setItem(
        "idPermisos",
        todosPermisos[`${catalogoValue}`][0].id_catalogo
      );
      setPermisos(todosPermisos[`${catalogoValue}`]);
    }
  }, [catalogo, idPermisoValue, todosPermisos]);

  const getTranslate = (e) => {
    if (e === "read") return "Ver";
    else if (e === "write") return "Crear";
    else if (e === "edit") return "Modificar";
    else if (e === "delete") return "Eliminar";
    else return e;
  };
  return (
    <>
      <Grid
        container
        id="contenedor-lista-permisos"
        style={{ height: "100%", overflowY: "scroll" }}
      >
        <Grid xs={12}>
          <label>Seleccionar catálogo permisos:</label>
          <Select
            classNamePrefix="select-modal"
            options={listaPermisos}
            placeholder="Seleccione un catálogo"
            style={{ marginInline: "100px" }}
            value={catalogo ? catalogo : idPermisoValue}
            onChange={setCatalogo}
          />
        </Grid>
        {permisos.map((reg, i) => (
          <>
            <label>{reg.nombre.replace("_", " ")}:</label>
            <Grid xs={12} container className="los-permisos">
              {console.log(reg.permisos)}
              {reg.permisos.map((permiso) => (
                <>
                  {console.log(permiso)}
                  {reg.nombre.split("_")[0] === "reportes" &&
                  permiso !== "read" ? null : (
                    <>
                      <label style={{ marginRight: "100px" }}>
                        {getTranslate(permiso)}
                      </label>
                    </>
                  )}
                </>
              ))}
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
}
