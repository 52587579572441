import React, { useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
//import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import AsyncSelect from "react-select/async";
import saveAs from "file-saver";
import { useSnackbar } from "notistack";
import { getRequest } from "../../Utils/Funciones/requester";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import Contenedor from "../../Utils/Visuales/Contenedor";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import EditButton from "../../Utils/Botones/EditButton";
import "./styles.module.css";
import SearchInput from "../../Utils/Visuales/SearchInput";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";

function KardexAcademico() {
  const campusId = useSelector((state) => state.campusId);
  const campusUser = useSelector((state) => state.campusUser);
  const [materiaValue, setMateriaValue] = useState("");
  const [profesorValue, setProfesorValue] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [valueGrupo, setValueGrupo] = useState("");
  const day = new Date();
  const min_date = moment(day).format("YYYY-MM-DD");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [campusSelected, setCampusSelected] = useState(
    campusUser.map((item) => ({
      ...item,
      isActive: item.value.includes(`${campusId}`),
    }))
  );
  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }

  const heading = [
    "sesion",
    "escuela",
    "campus",
    "clave_direccion",
    "direccion",
    "director",
    "clave_profesor",
    "nombre_profesor",
    "tipo_profesor",
    "ciclo",
    "grupo",
    "clave_materia",
    "materia",
    "salon",
    "tipo_de_grupo",
    "fecha",
    "horario",
    "llegada",
    "asistencia",
    "clase",
    "dia",
    "auto",
    "fecha_auto",
    "hora_auto",
    "canc",
    "q",
    "fecha_env_nomina",
    "tema",
  ];

  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);

  async function handleClick() {
    const allCampusSelected = campusSelected.filter(
      (campus) => campus.isActive
    );
    let valido = true;
    if (allCampusSelected.length === 0) {
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCiclo === "") {
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (
      profesorValue?.value === undefined &&
      valueGrupo === "" &&
      materiaValue === ""
    ) {
      enqueueSnackbar(
        "Se debe de seleccionar uno de estos campos Grupo, Materia o Clave Profesor",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      valido = false;
    }
    if (valido) {
      setLoading(true);
      setButtonDisabled(true);
      setShowTable(true);
      try {
        const arrayCampus = allCampusSelected.map((campus) => campus.value);
        const datosFinal = await dataConsulta(arrayCampus);
        setDataFinal(datosFinal);
        setDataOriginal(datosFinal);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  async function dataConsulta(id) {
    const multiplesDatos = [];
    const id_profesor =
      profesorValue?.value !== undefined ? profesorValue?.value : "";
    for (let llamados = 0; llamados < id.length; llamados++) {
      let consulta = `/reportes/kardex-academico?id_campus=${id[llamados]}&ciclo=${valueCiclo}&materia=${materiaValue}&id_profesor=${id_profesor}&grupo=${valueGrupo}`;
      if (fechaInicio && fechaFinal) {
        let nrofechaInicial = parseInt(
          fechaInicio.substring(0, 4) +
            fechaInicio.substring(5, 7) +
            fechaInicio.substring(8, 10)
        );
        let nrofechaFinal = parseInt(
          fechaFinal.substring(0, 4) +
            fechaFinal.substring(5, 7) +
            fechaFinal.substring(8, 10)
        );
        let nromax_date = parseInt(
          min_date.substring(0, 4) +
            min_date.substring(5, 7) +
            min_date.substring(8, 10)
        );
        if (
          nrofechaInicial <= nrofechaFinal &&
          nrofechaInicial <= nromax_date
        ) {
          consulta += `&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFinal}`;
          const response = await getRequest(consulta);
          try {
            if (response.s === "OK") {
              if (response.d.length !== 0) {
                const nro = response.d["Sesion"].length;
                for (let i = 0; i < nro; i++) {
                  await multiplesDatos.push([
                    response.d["Sesion"][i],
                    response.d["Escuela"][i],
                    response.d["Campus"][i],
                    response.d["Clave Direccion"][i],
                    response.d["Direccion"][i],
                    response.d["Director"][i],
                    response.d["Clave Profesor"][i],
                    response.d["Nombre Profesor"][i],
                    response.d["Tipo Profesor"][i],
                    response.d["Ciclo"][i],
                    response.d["Grupo"][i],
                    response.d["Clave Materia"][i],
                    response.d["Materia"][i],
                    response.d["Salon"][i],
                    response.d["Tipo de Grupo"][i],
                    response.d["Fecha"][i],
                    response.d["Horario"][i],
                    response.d["Llegada"][i],
                    response.d["Asistencia"][i],
                    response.d["Clase"][i],
                    response.d["Día"][i],
                    response.d["Auto"][i],
                    response.d["Fecha Auto"][i],
                    response.d["Hora Auto"][i],
                    response.d["Canc"][i],
                    response.d["Q"][i],
                    response.d["Fecha Env. N\u00f3mina"][i],
                    response.d["Tema"][i],
                  ]);
                }
              }
            } else {
              enqueueSnackbar(response.m, {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          enqueueSnackbar(
            "Fecha Inicial es superior a la Fecha Final o es inválida",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return [];
        }
      } else {
        const response = await getRequest(consulta);
        try {
          if (response.s === "OK") {
            if (response.d.length !== 0) {
              const nro = response.d["Sesion"].length;
              for (let i = 0; i < nro; i++) {
                await multiplesDatos.push([
                  response.d["Sesion"][i],
                  response.d["Escuela"][i],
                  response.d["Campus"][i],
                  response.d["Clave Direccion"][i],
                  response.d["Direccion"][i],
                  response.d["Director"][i],
                  response.d["Clave Profesor"][i],
                  response.d["Nombre Profesor"][i],
                  response.d["Tipo Profesor"][i],
                  response.d["Ciclo"][i],
                  response.d["Grupo"][i],
                  response.d["Clave Materia"][i],
                  response.d["Materia"][i],
                  response.d["Salon"][i],
                  response.d["Tipo de Grupo"][i],
                  response.d["Fecha"][i],
                  response.d["Horario"][i],
                  response.d["Llegada"][i],
                  response.d["Asistencia"][i],
                  response.d["Clase"][i],
                  response.d["Día"][i],
                  response.d["Auto"][i],
                  response.d["Fecha Auto"][i],
                  response.d["Hora Auto"][i],
                  response.d["Canc"][i],
                  response.d["Q"][i],
                  response.d["Fecha Env. N\u00f3mina"][i],
                  response.d["Tema"][i],
                ]);
              }
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
    if (multiplesDatos.lenght === 0) {
      enqueueSnackbar("No se han encontrado datos", {
        variant: "info",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    return multiplesDatos;
  }

  const headers = [
    {
      name: "Sesión",
      id: "sesion",
      col: 0,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 1,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 2,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Dirección",
      id: "clave_direccion",
      col: 3,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Director",
      id: "director",
      col: 0,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 0,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 0,
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Profesor",
      id: "tipo_profesor",
      col: 0,
      width: "6%",
      align: "center",
      fn: null,
    },
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 0,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 0,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Materia",
      id: "materia",
      col: 0,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Salon",
      id: "salon",
      col: 0,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Grupo",
      id: "tipo_de_grupo",
      col: 0,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 0,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      id: "horario",
      col: 0,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Llegada",
      id: "llegada",
      col: 0,
      width: "5%",
      align: "center",
      fn: null,
    },
    {
      name: "Asistencia",
      id: "Asistencia",
      col: 0,
      width: "5%",
      align: "center",
      fn: null,
    },
    {
      name: "Clase",
      id: "clase",
      col: 0,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Día",
      id: "dia",
      col: 0,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Auto",
      id: "auto",
      col: 0,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Auto",
      id: "fecha_auto",
      col: 0,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Auto",
      id: "hora_auto",
      col: 0,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Canc",
      id: "canc",
      col: 0,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Q",
      id: "q",
      col: 0,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Env. Nómina",
      id: "fecha_env_nomina",
      col: 0,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Tema",
      id: "tema",
      col: 0,
      width: "5%",
      align: "center",
      fn: null,
    },
  ];

  const headersPDF = [
    {
      name: "Sesión",
      id: "sesion",
      col: 0,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 1,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Grupo",
      id: "tipo_de_grupo",
      col: 5,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 6,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      id: "horario",
      col: 7,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Llegada",
      id: "llegada",
      col: 8,
      width: "5%",
      align: "center",
      fn: null,
    },
    {
      name: "Asistencia",
      id: "asistencia",
      col: 0,
      width: "5%",
      align: "center",
      fn: null,
    },
    {
      name: "Clase",
      id: "clase",
      col: 9,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Día",
      id: "dia",
      col: 10,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Auto",
      id: "auto",
      col: 11,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Auto",
      id: "fecha_auto",
      col: 12,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Auto",
      id: "hora_auto",
      col: 13,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Canc",
      id: "canc",
      col: 14,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Q",
      id: "q",
      col: 15,
      width: "4%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Env. Nómina",
      id: "fecha_env_nomina",
      col: 16,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Tema",
      id: "tema",
      col: 17,
      width: "5%",
      align: "center",
      fn: null,
    },
  ];

  /* Adding Functionality to Download Button */

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        sesion: e[0],
        escuela: e[1],
        grupo: e[10],
        clave_materia: e[11],
        materia: e[12],
        tipo_de_grupo: e[14],
        fecha: e[15],
        horario: e[16],
        llegada: e[17],
        asistencia: e[18],
        clase: e[19],
        dia: e[20],
        auto: e[21],
        fecha_auto: e[22],
        hora_auto: e[23],
        canc: e[24],
        q: e[25],
        fecha_env_nomina: e[26],
        tema: e[27],
        campus: e[2],
        clave_direccion: e[3],
        direccion: e[4],
        director: e[5],
        clave_profesor: e[6],
        nombre_profesor: e[7],
        tipo_profesor: e[8],
      };
      return data2;
    });

    const nuevoObjeto = {};

    data_.forEach((elemento) => {
      const clavePrincipal =
        elemento.direccion?.trim() + " " + elemento?.director?.trim();
      const claveSecundaria =
        elemento.grupo?.trim() + " " + elemento?.materia?.trim();

      if (!nuevoObjeto[clavePrincipal]) {
        nuevoObjeto[clavePrincipal] = {};
      }

      if (!nuevoObjeto[clavePrincipal][claveSecundaria]) {
        nuevoObjeto[clavePrincipal][claveSecundaria] = [];
      }

      nuevoObjeto[clavePrincipal][claveSecundaria].push(elemento);
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headersPDF ?? []}
        data={data_ ?? []}
        title={`Reportes Kardex Académico`}
        fechaReporte={`${fecha} ${hora}`}
        teacherKey={profesorValue.label}
        tipo_profesor={dataFinal[0][8]}
        direccion={dataFinal[0][4]}
        director={dataFinal[0][5]}
        grupo={valueGrupo ? valueGrupo : ""}
        materia={materiaValue ? materiaValue : ""}
        totalBox={nuevoObjeto}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Kardex_Academico_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        sesion: e[0],
        escuela: e[1],
        campus: e[2],
        clave_direccion: e[3],
        direccion: e[4],
        director: e[5],
        clave_profesor: e[6],
        nombre_profesor: e[7],
        tipo_profesor: e[8],
        ciclo: e[9],
        grupo: e[10],
        clave_materia: e[11],
        materia: e[12],
        tipo_de_grupo: e[14],
        fecha: e[15],
        horario: e[16],
        llegada: e[17],
        asistencia: e[18],
        clase: e[19],
        dia: e[20],
        auto: e[21],
        fecha_auto: e[22],
        hora_auto: e[23],
        canc: e[24],
        q: e[25],
        fecha_env_nomina: e[26],
        tema: e[27],
      };

      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_Kardex_Academico_${fecha}_${hora}`, heading);
    setReq(false);
  };

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const { enqueueSnackbar } = useSnackbar();

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  const minChars = 5;
  const waitTime = 2000;
  let timer;

  const promiseOptionsProfesor = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const handleChangeOpcionCampus = (selected) => {
    if (selected.label === "Todos") {
      const findTodos = campusSelected.find(
        (campus) => campus.label === "Todos"
      );
      setCampusSelected((prev) =>
        prev.map((campus) => ({ ...campus, isActive: !findTodos.isActive }))
      );
    } else {
      setCampusSelected((prev) => {
        const newPrev = prev.map((campus) => {
          if (campus.value === selected.value) {
            return { ...campus, isActive: !campus.isActive };
          }
          return { ...campus };
        });
        const allActive = newPrev.every(
          (campus) => campus.value === "0" || campus.isActive
        );
        return newPrev.map((campus) => {
          if (campus.label === "Todos") {
            return {
              ...campus,
              isActive: allActive,
            };
          }
          return { ...campus };
        });
      });
    }
  };

  return (
    <Contenedor title="Reportes / Kardex Académico">
      <div className="inputs-excepciones">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <label>Campus(*):</label>
          </Grid>
          <Grid item xs={2} className="nuevoUsuarioForm">
            <MultiSelectCampus
              options={campusSelected}
              onChange={handleChangeOpcionCampus}
              value={{
                value: `${
                  campusSelected.filter((item) => item.isActive).length
                }`,
                label: `Total: ${
                  campusSelected.filter((item) => item.isActive).length
                }`,
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-1.5%" }}>
            <label>Ciclo(*):</label>
          </Grid>
          <Grid item xs={2}>
            <input
              className="filter-input input-ciclo"
              placeholder="Indique el ciclo..."
              value={valueCiclo}
              onChange={(e) => setValueCiclo(e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <label>Fecha Inicial:</label>
          </Grid>
          <Grid item xs={1}>
            <input
              className="filter-input"
              type="date"
              max={min_date}
              value={fechaInicio}
              onChange={(e) => setFechaInicio(e.target.value)}
              style={{ width: "160%" }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "4%" }}>
            <label>Fecha Final:</label>
          </Grid>
          <Grid item xs={1} style={{ zIndex: 99 }}>
            <input
              className="filter-input"
              type="date"
              max={min_date}
              value={fechaFinal}
              style={{ width: "160%" }}
              onChange={(e) => setFechaFinal(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={1}>
            <label>Grupo:</label>
          </Grid>
          <Grid item xs={2}>
            <input
              className="filter-input"
              value={valueGrupo}
              onChange={(e) => setValueGrupo(e.target.value)}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-1.5%" }}>
            <label>Materia:</label>
          </Grid>
          <Grid item xs={2}>
            <input
              className="filter-input"
              value={materiaValue}
              onChange={(e) => setMateriaValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={1}>
            <label>Clave Profesor:</label>
          </Grid>
          <Grid item xs={2}>
            <AsyncSelect
              className="select-modal-container"
              classNamePrefix="select-modal"
              styles={asyncSelectStyles}
              loadOptions={promiseOptionsProfesor}
              onChange={setProfesorValue}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid
            item
            xs={2}
            justifyContent={"flex-end"}
            style={{ padding: "20px 10px" }}
          >
            <EditButton
              onClick={handleClick}
              disabled={buttonDisabled || loading}
              style={{ padding: "10px 25px" }}
            >
              Generar
            </EditButton>
          </Grid>
          <Grid item xs={9} />
          <div
            style={{ display: "flex", marginLeft: "3%" }}
            className="export-buttons"
          >
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
              Descargar:
            </Typography>
            <div>
              <button
                onClick={DownloadExcel}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={excelIcon} alt="excel-icon" />
              </button>
              <button
                onClick={DownloadPDF}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={pdfFileIcon} alt="pdf-file-icon" />
              </button>
            </div>
          </div>
        </Grid>
        {/* Adding Button format */}
      </div>
      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 225px)", marginTop: "1.5%" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
}

export default KardexAcademico;
