import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import "./index.css";
import NewButton from "../../Utils/Botones/NewButton";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import SearchInput from "../../Utils/Visuales/SearchInput";
import NewModal from "../../Utils/Visuales/NewModal";
import Grid from "@material-ui/core/Grid";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Select from "react-select";

export default function Dispositivos(props) {
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [nuevoDispositivoModal, setNuevoDispositivoModal] = useState(false);
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalEditar, setLoadingModalEditar] = useState(false);
  const campusId = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);
  const headers = [
    {
      name: "Folio",
      col: 0,
    },
    {
      name: "Nombre",
      col: 1,
    },
    {
      name: "Ultimo acceso",
      col: 2,
    },
    {
      name: "Acciones",
      col: 3,
    },
  ];
  const [refresh, setRefresh] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [activeDispositivos, setActiveDispositivos] = useState(true);
  const [dispositivosDesactivarBtn, setDispositivosDesactivarBtn] = useState(true);
  // Crear y editar usuario
  const [IDactual, setIDactual] = useState("")
  const [dispositivo, setDispositivo] = useState("");
  const [password, setPassword] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [zonah, setZonah] = useState("");
  const [zonahOptions, setZonahOptions] = useState("");
  const [estatus, setEstatus] = useState("");
  const [creado, setCreado] = useState("");
  const [creadoPor, setCreadoPor] = useState("");
  const [modificado, setModificado] = useState("");
  const [modificadoPor, setModificadoPor] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  async function openEditModal(id, data) {
    setModalEditarIsOpen(true);
    setLoadingModalEditar(true);
    try {
      setIDactual(id);
      setDispositivo(data.nombre);
      setPassword(data.password);
      setDescripcion(data.descripcion);
      setEstatus(data.status)
      setCreado(data.creado);
      setCreadoPor(data.creado_por);
      setModificado(data.modificado);
      setModificadoPor(data.modificado_por);
      if (permisos['Dispositivos'].eliminar === '1') {
        setDispositivosDesactivarBtn(true);
      } else {
        setDispositivosDesactivarBtn(false);
      }
      if (permisos['Dispositivos'].modificar !== '1') {
        setDisabled(true);
        setActiveDispositivos(false);
        setDispositivosDesactivarBtn(false);
      } else {
        setDisabled(false);
        setActiveDispositivos(true);
      }
      
      if (data.time_zone === "Central Standard Time") {
        setZonah({
          value: "Central Standard Time",
          label: "Central Standard Time (México)"
        });
      } else if (data.time_zone === "Mountain Standard Time") {
        setZonah({
          value: "Mountain Standard Time",
          label: "Mountain Standard Time (México)"
        });
      } else {
        setZonah({
          value: "Pacific Standard Time",
          label: "Pacific Standard Time (México)"
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModalEditar(false);
    }
  }

  useEffect(() => {
    setDispositivo("");
    setPassword("");
    setDescripcion("");
    setZonah("")
    setZonahOptions([
      {
        value: "Central Standard Time",
        label: "Central Standard Time (México)"
      }, {
        value: "Mountain Standard Time",
        label: "Mountain Standard Time (México)"
      }, {
        value: "Pacific Standard Time",
        label: "Pacific Standard Time (México)"
      }
    ]);
    setCreado("");
    setCreadoPor("");
    setModificado("");
    setModificadoPor("");
  }, [nuevoDispositivoModal]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response = await getRequest(`/dispositivo?id_campus=${campusId}`);
      if (response.s === "OK") {
        let data = [];
        //console.log(response.d);
        response.d.forEach((dispositivo, i) => {
          data.push([
            dispositivo.id,
            dispositivo.nombre,
            dispositivo.ultimo_acceso,
            <EditButton
              onClick={() => openEditModal(dispositivo.id, response.d[i])}
            >
              Editar
            </EditButton>,
            dispositivo.creado,
            dispositivo.creado_por,
            dispositivo.descripcion,
            dispositivo.modificado,
            dispositivo.modificado_por,
            dispositivo.password,
            dispositivo.status,
            dispositivo.time_zone
          ]);
        });
        setDataOriginal(data);
        setDataFinal(data);
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) { }
  };

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }

  async function sendNewDispositivo() {
    let valido = true;
    if (dispositivo === "") {
      enqueueSnackbar("Debe ingresar un dispositivo", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (password === "") {
      enqueueSnackbar("Debe ingresar una contraseña", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (descripcion === "") {
      enqueueSnackbar("Debe ingresar una descripción", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (zonah === "") {
      enqueueSnackbar("Debe seleccionar una zona horaria", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        let json = {
          id_campus: campusId,
          nombre: dispositivo,
          password: password,
          descripcion: descripcion,
          time_zone: zonah.value
        };
        setLoadingModal(true);
        let response = await postRequest("/dispositivo/nuevo", json);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          fetchData();
        } else {
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setNuevoDispositivoModal(false);
        setLoadingModal(false);
        setRefresh(refresh + 1);
      }
    }
  }

  const handleSubmitEditarDispositivo = async (e) => {
    setLoadingModalEditar(true);
    try {
      let json = {
        id: IDactual,
        nombre: dispositivo,
        password: password,
        descripcion: descripcion,
        time_zone: zonah.value
      };
      let response = await postRequest("/dispositivo/editar", json);
      if (response.s === "OK") {
        setDispositivo("");
        setPassword("");
        setDescripcion("");
        setZonah("");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        fetchData();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error)
    } finally {
      setModalEditarIsOpen(false);
      setLoadingModalEditar(false);
    }
  };
  const handleActivar = async (e) => {
    setLoadingModalEditar(true);
    try {
      let json = {
        id: IDactual,
        estatus: 1
      };
      let response = await postRequest("/dispositivo/editar", json);
      if (response.s === "OK") {
        setDispositivo("");
        setPassword("");
        setDescripcion("");
        setZonah("");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        fetchData();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error)
    } finally {
      setModalEditarIsOpen(false);
      setLoadingModalEditar(false);
    }
  }

  const handleDesactivar = async (e) => {
    setLoadingModalEditar(true);
    try {
      let json = {
        id: IDactual,
        estatus: 0
      };
      let response = await postRequest("/dispositivo/editar", json);
      if (response.s === "OK") {
        setDispositivo("");
        setPassword("");
        setDescripcion("");
        setZonah("");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        fetchData();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error)
    } finally {
      setModalEditarIsOpen(false);
      setLoadingModalEditar(false);
    }
  };

  const handleReiniciar = async (e) => {
    setLoadingModalEditar(true);
    try {
      let json = {
        id: IDactual,
        licencia: 0
      };
      let response = await postRequest("/dispositivo/editar", json);
      if (response.s === "OK") {
        setDispositivo("");
        setPassword("");
        setDescripcion("");
        setZonah("");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        fetchData();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error)
    } finally {
      setModalEditarIsOpen(false);
      setLoadingModalEditar(false);
    }
  };

  return (
    <Contenedor title="Dispositivos">
      <Grid container spacing={1} style={{ marginBottom: "8px" }}>
        <Grid item xs={6} md={1}>
          <NewButton
            noIcon
            style={{ padding: "5px 15px", display: permisos['Dispositivos'].escribir === '1' ? 'block' : 'none' }}
            onClick={() => setNuevoDispositivoModal(true)}
          />
        </Grid>
        <Grid item xs={0} md={8} />
        <Grid item xs={6} md={3}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 65px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[3, 4]}
          paginate
          bordes
          moreCells={8}
        />
      </div>
      <NewModal
        title="Nuevo Dispositivo"
        open={nuevoDispositivoModal}
        handleClose={() => setNuevoDispositivoModal(false)}
        height={460}
        maxWidth={700}
        loading={loadingModal}
        handleSubmit={sendNewDispositivo}
      >
        <div className="nuevoUsuarioForm-edit">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label>Dispositivo:</label>
              <input
                className="filter-input"
                placeholder="Ingrese dispositivo"
                value={dispositivo}
                onChange={(ev) => setDispositivo(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Contraseña:</label>
              <input
                className="filter-input"
                placeholder="Ingrese contraseña"
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Descripción:</label>
              <textarea
                className="text-area-modal"
                rows={3}
                value={descripcion}
                onChange={(ev) => setDescripcion(ev.target.value)}
                placeholder="Escriba aquí..."
              />
            </Grid>
            <Grid item xs={12}>
              <label>Zona Horaria:</label>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={zonahOptions}
                onChange={setZonah}
                value={zonah}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Editar Dispositivo"
        open={modalEditarIsOpen}
        handleSubmit={handleSubmitEditarDispositivo}
        handleClose={(e) => {
          setDispositivo("");
          setPassword("");
          setDescripcion("");
          setZonah("");
          setModalEditarIsOpen(false)
        }}
        height={450}
        maxWidth={700}
        dispositivos={activeDispositivos}
        dispositivosDesactivarBtn={dispositivosDesactivarBtn}
        handleActivar={handleActivar}
        handleDesactivar={handleDesactivar}
        handleReiniciar={handleReiniciar}
        noButtons={disabled}
        loading={loadingModalEditar}
      >
        <div className="nuevoUsuarioForm-edit">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label>Dispositivo:</label>
              <input
                className="filter-input"
                disabled={disabled}
                value={dispositivo}
                onChange={(ev) => setDispositivo(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Contraseña:</label>
              <input
                className="filter-input"
                disabled={disabled}
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label>Descripción:</label>
              <textarea
                className="text-area-modal"
                rows={3}
                disabled={disabled}
                value={descripcion}
                onChange={(ev) => setDescripcion(ev.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Zona Horaria:</label>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isDisabled={disabled}
                options={zonahOptions}
                onChange={setZonah}
                value={zonah}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Estatus:</label>
              <input
                disabled
                className="filter-input"
                value={estatus === true ? "Activo" : "Inactivo"}
                onChange={(ev) => setEstatus(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Creado:</label>
              <input
                disabled
                className="filter-input"
                value={creado}
                onChange={(ev) => setDispositivo(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Modificado:</label>
              <input
                disabled
                className="filter-input"
                value={modificado}
                onChange={(ev) => setDispositivo(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Creado por:</label>
              <input
                disabled
                className="filter-input"
                value={creadoPor}
                onChange={(ev) => setDispositivo(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Modificado por:</label>
              <input
                disabled
                className="filter-input"
                value={modificadoPor}
                onChange={(ev) => setDispositivo(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}
