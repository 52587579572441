export const typeDisabilitiesHeaders = [
    {
        accessorKey: 'clave',
        header: 'Clave',
        enableEditing: false,
        size: 10,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'nombre',
        header: 'Nombre',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'maximo_dias',
        header: 'Maximo Dias',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'modificado_por',
        header: 'Modificado por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'modificado',
        header: 'Fecha Modificado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'creado_por',
        header: 'Creado Por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'creado',
        header: 'Fecha Creado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'id',
        header: 'id',
        enableEditing: false,
        enableGlobalFilter: true
    },
]