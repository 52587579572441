import moment from "moment";
export const convertHours = (hours) => {
  try {
    const time = moment(hours, "HH:mm");
    // Check if minutes are less than 30
    if (time.minutes() < 30) {
      time.minutes(0); // Set minutes to 0
    } else if (time.minutes() > 30) {
      time.minutes(30); // Set minutes to 30
    }
    return time.format("HH:mm");
  } catch (error) {
    console.log("error");
    return hours;
  }
};
