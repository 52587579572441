import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import CreateModalInputs from "../CreateModalInputs";
import CreateModalDate from "../CreateModalDate";
import SelectWithLabel from "../SelectWithLabel";
import "./index.css";
import EditButton from "../Botones/EditButton";
import { Typography } from "@mui/material";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import excelIcon from "../../images/excel-icon.png";
import { useSelector } from "react-redux";
import { consultarCatalogo } from "../Funciones/consultarCatalogo";
import { useSnackbar } from "notistack";
import moment from "moment";
import { checkValidationsReporteIndicadoresSemanales } from "../Funciones/checkValidationsReporteIndicadoresSemanales";
import { getReportIndicadoresAcademicosSemanales } from "../data/getReportIndicadoresAcademicosSemanales";
import saveAs from "file-saver";
import { pdf } from "@react-pdf/renderer";
import {
  headersSemanales,
  rowstableColSpan,
} from "../data/reportesIndicadoresAcademicos";
import { DocumentPDFTable } from "../pdf_table/document";
import { PDFformatForReporteIndicadoresAcademicosSemanales } from "../Funciones/PDFformatForReporteIndicadoresAcademicosSemanales";
import { ExportCSV } from "../Excel";
import { EXCELformatForReporteIndicdoresAcademicosSemanales } from "../Funciones/EXCELformatForReporteIndicdoresAcademicosSemanales";

const ReportesIndicadoresAcademicosSemanalesHeader = ({
  showTable,
  setShowTable,
  loading,
  setLoading,
  reportInfo,
  setReportInfo,
  handleOpenModal,
}) => {
  const campus = useSelector((state) => state.campusUser2);
  const campusName = useSelector((state) => state.campusName);
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [req, setReq] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    ciclo: "",
    campus: campus?.filter((item) => item.label.includes(campusName))[0],
    escuela: {},
    direccion: {},
    fechaInicial: moment().format("YYYY-MM-DD"),
    fechaFinal: "",
  });

  useEffect(() => {
    consultarCatalogo(7, enqueueSnackbar).then((data) =>
      setOptionsDireccion(data)
    );
    consultarCatalogo(9, enqueueSnackbar).then((data) =>
      setOptionsEscuela(data)
    );
  }, [enqueueSnackbar]);

  const handleClick = async () => {
    setShowTable(false);
    let valido = checkValidationsReporteIndicadoresSemanales(
      formData,
      enqueueSnackbar
    );
    if (valido) {
      await getReportIndicadoresAcademicosSemanales(
        setLoading,
        formData,
        enqueueSnackbar,
        setReportInfo,
        handleOpenModal
      );
    }
    setShowTable(true);
  };

  const handleDownloadPDF = async () => {
    setReq(true);
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const newHeaders = headersSemanales.filter(
      (element) =>
        !["Ciclo", "Campus", "Escuela", "Acciones"].includes(element.name)
    );
    const dataPDF = await PDFformatForReporteIndicadoresAcademicosSemanales(
      reportInfo
    );
    const doc = (
      <DocumentPDFTable
        metadata={newHeaders ?? []}
        data={dataPDF ?? []}
        title={`Reportes Indicadores Académicos Semanales`}
        campus={formData.campus.label}
        escuela={formData.escuela.label}
        ciclo={formData.ciclo}
        startDate={moment(formData.fechaInicial).format("DD/MM/YYYY")}
        endDate={moment(formData.fechaFinal).format("DD/MM/YYYY")}
        rowstableColSpan={rowstableColSpan}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      `Reporte_Indicadores_Académicos_Semanales_${fecha}_${hora}.pdf`
    );
    setReq(false);
  };

  const handleDownloadExcel = async () => {
    setReq(true);
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const data = EXCELformatForReporteIndicdoresAcademicosSemanales(reportInfo);
    ExportCSV(
      data,
      `Reporte_Indicadores_Académicos_Semanales_${fecha}_${hora}`
    );
    setReq(false);
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      style={{ paddingRight: 10 }}
    >
      <Grid item className={"grid-container"}>
        <Grid className={"grid-sub-container"}>
          <CreateModalInputs
            label={"Ciclo(*)"}
            inputValue={formData.ciclo}
            onChange={(e) => setFormData((prev) => ({ ...prev, ciclo: e }))}
            disabled={false}
            sm={3}
          />
        </Grid>
        <Grid className={"grid-sub-container"}>
          <SelectWithLabel
            label={"Campus(*)"}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, campus: e }));
            }}
            options={campus}
            sm={3}
            value={formData.campus}
            width={212}
          />
        </Grid>
      </Grid>
      <Grid item className={"grid-container"}>
        <Grid className={"grid-sub-container"}>
          <CreateModalDate
            label={"Fecha Inicial(*)"}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, fechaInicial: e }));
            }}
            sm={3}
            value={formData.fechaInicial}
          />
        </Grid>
        <Grid className={"grid-sub-container"}>
          <CreateModalDate
            label={"Fecha Final(*)"}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, fechaFinal: e }));
            }}
            sm={3}
            min={formData.fechaInicial}
          />
        </Grid>
      </Grid>
      <Grid item className={"grid-container"}>
        <Grid className={"grid-sub-container"}>
          <SelectWithLabel
            label={"Escuela(*)"}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, escuela: e }));
            }}
            options={optionsEscuela}
            sm={3}
            width={212}
          />
        </Grid>
        <Grid className={"grid-sub-container"}>
          <SelectWithLabel
            label={"Dirección"}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, direccion: e }));
            }}
            options={optionsDireccion}
            sm={3}
            width={212}
          />
        </Grid>
      </Grid>
      <Grid item className={"grid-container2"}>
        <Grid
          container
          item
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
          }}
        >
          <EditButton
            onClick={handleClick}
            style={{ padding: "10px 25px" }}
            disabled={loading}
          >
            Generar
          </EditButton>
          <div style={{ display: "flex" }} className="export-buttons">
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
              Descargar:
            </Typography>
            <div>
              <button
                onClick={handleDownloadExcel}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={excelIcon} alt="excel-icon" />
              </button>
              <button
                onClick={handleDownloadPDF}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={pdfFileIcon} alt="pdf-file-icon" />
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportesIndicadoresAcademicosSemanalesHeader;
