export const headingDesviacionAsig = [
    "ciclo",
    "campus",
    "escuela",
    "carrera_base",
    "nombre_carrera",
    "direccion",
    "nombre_director",
    "clave_profesor",
    "nombre_profesor",
    "grupo",
    "clave_materia",
    "materia_base",
    "nombre_materia",
    "evaluacion_anterior",
    "total_horas",
    "horas_clase",
    "maximo_horas",
    "modulo",
    "fecha_inicial",
    "fecha_final",
    "total_grupos",
    "maximo_grupos",
    "tipo",
  ];

  export const headersDesviacionAsig = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Carrera Base",
      id: "carrera_base",
      col: 3,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Carrera",
      id: "nombre_carrera",
      col: 4,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 5,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Director",
      id: "nombre_director",
      col: 6,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 7,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 8,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 9,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 10,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Materia Base",
      id: "materia_base",
      col: 11,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Materia",
      id: "nombre_materia",
      col: 12,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Evaluación Anterior",
      id: "evaluacion_anterior",
      col: 13,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Total Horas",
      id: "total_horas",
      col: 14,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Horas Clase",
      id: "horas_clase",
      col: 15,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Máximo Horas",
      id: "maximo_horas",
      col: 16,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Módulo",
      id: "modulo",
      col: 17,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Inicial",
      id: "fecha_inicial",
      col: 18,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Final",
      id: "fecha_final",
      col: 19,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Total Grupos",
      id: "total_grupos",
      col: 20,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Máximo Grupos",
      id: "maximo_grupos",
      col: 21,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo",
      id: "tipo",
      col: 22,
      width: "15%",
      align: "center",
      fn: null,
    },
  ];