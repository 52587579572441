import { View, Text } from '@react-pdf/renderer'

export default function SpecialClassTotalBox({
    title_1,
    title_2,
    title_3,
    title_4,
    title_5,
    title_6,
    value_1,
    value_2,
    value_3,
    value_4,
    value_5,
    value_6,
}) {
    return (
        <View>
            <View style={{
                width: "100%",
                display: "flex",
                flexDirection: "column", justifyContent: "center", alignItems: "center"
            }}>
                <View style={{
                    display: "flex",
                    flexDirection: "row", justifyContent: "center", alignItems: "center",
                }}>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{title_1}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{title_2}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{title_3}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{title_4}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{title_5}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{title_6}</Text>
                    </View>
                </View>
                <View style={{
                    display: "flex",
                    flexDirection: "row", justifyContent: "center", alignItems: "center",
                }}>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{value_1}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{value_2}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{value_3}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{value_4}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{value_5}</Text>
                    </View>
                    <View style={{ fontSize: 8, padding: 10, width: 90, height: 50, justifyContent: "center", alignItems: "center", border: "1px", borderTop: 1, borderBottom: 1, }}>
                        <Text>{value_6}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}