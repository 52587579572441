import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import NewModal from "../../../Utils/Visuales/NewModal";
import { getRequest, postRequest } from "../../../Utils/Funciones/requester";
import styles from "../styles.module.css";
import moment from "moment";

let grupoTimer;
function Sustitucion(props) {
  const [loading, setLoading] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const campusId = useSelector((state) => state.campusId);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [detalleAsignacion, setDetalleAsignacion] = useState("");
  const [optionsFechaProfesor, setOptionsFechaProfesor] = useState([]);
  const [horasIniciales, setHorasIniciales] = useState(["06:00"]);
  const [horasFinales, setHorasFinales] = useState(["23:00"]);

  useEffect(() => {
    if (props.open === true) {
      setDetalleAsignacion("");
      setMateriaOptions([]);
      setOptionsFechaProfesor([]);
      props.setGrupo("");
      props.setMateria("");
      props.setInicia("");
      props.setTermina("");
      props.setFecha({});
    }
  }, [props.open]);

  useEffect(() => {
    clearTimeout(grupoTimer);
    if (props.grupo.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(
          campusId,
          props.clave_profesor.value,
          props.grupo,
          props.ciclo,
          props.direccion.value
        );
      }, waitTime);
    }
  }, [props.grupo]);

  useEffect(() => {
    if (props.materia !== "") {
      consultarDetalleHorario(props.materia.value);
    }
  }, [props.materia]);

  const { enqueueSnackbar } = useSnackbar();

  async function consultarDetalleHorario(id) {
    setLoadingSelect(true);
    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint).then(
      (res) => res.d
    );
    const filterHorario = assignmentDetailResponse.horario_profesor.filter(
      (item) => item.id_person === props.clave_profesor.value
    )[0];
    const scheduleData = [
      filterHorario.horario.lunes[0],
      filterHorario.horario.martes[0],
      filterHorario.horario.miercoles[0],
      filterHorario.horario.jueves[0],
      filterHorario.horario.viernes[0],
      filterHorario.horario.sabado[0],
      filterHorario.horario.domingo[0],
    ];

    const fechas = [];
    filterHorario.horario_fechas.forEach((item) => {
      fechas.push({
        value: item,
        label:
          item.substring(8, 10) +
          "/" +
          item.substring(5, 7) +
          "/" +
          item.substring(0, 4),
      });
    });

    let comp = "";
    let getDay = moment().format("YYYY/MM/DD");
    getDay = parseInt(
      getDay.substring(0, 4) + getDay.substring(5, 7) + getDay.substring(8, 10)
    );
    const fechasFinales = [];
    fechas.filter((item) => {
      comp = parseInt(
        item.value.substring(0, 4) +
          item.value.substring(5, 7) +
          item.value.substring(8, 10)
      );
      if (comp > getDay || comp === getDay) {
        fechasFinales.push(item);
      }
      return false;
    });

    const inicia = [];
    const termina = [];
    if (scheduleData[0]) {
      inicia[0] = scheduleData[0].substring(0, 5);
      termina[0] = scheduleData[0].substring(6, 11);
    }
    if (scheduleData[1]) {
      inicia[1] = scheduleData[1].substring(0, 5);
      termina[1] = scheduleData[1].substring(6, 11);
    }
    if (scheduleData[2]) {
      inicia[2] = scheduleData[2].substring(0, 5);
      termina[2] = scheduleData[2].substring(6, 11);
    }
    if (scheduleData[3]) {
      inicia[3] = scheduleData[3].substring(0, 5);
      termina[3] = scheduleData[3].substring(6, 11);
    }
    if (scheduleData[4]) {
      inicia[4] = scheduleData[4].substring(0, 5);
      termina[4] = scheduleData[4].substring(6, 11);
    }
    if (scheduleData[5]) {
      inicia[5] = scheduleData[5].substring(0, 5);
      termina[5] = scheduleData[5].substring(6, 11);
    }
    if (scheduleData[6]) {
      inicia[6] = scheduleData[6].substring(0, 5);
      termina[6] = scheduleData[6].substring(6, 11);
    }
    setHorasIniciales(inicia);
    setHorasFinales(termina);
    setDetalleAsignacion(assignmentDetailResponse);
    setOptionsFechaProfesor(fechasFinales);
    setLoadingSelect(false);
  }

  async function searchProfesor2(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor_activos&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            valido: item.valido,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  function promiseOptions(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  function promiseOptions2(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor2(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  async function searchMateria(id_campus, id_person, grupo, ciclo, direccion) {
    setSelectMateriaIsLoading(true);
    try {
      const options = [];
      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${id_person},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
          });
        });

        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  async function handleSubmit() {
    if (
      props.clave_profesor.value === undefined ||
      props.profesorSustitucion.value === undefined ||
      props.grupo === "" ||
      props.materia.value === "" ||
      props.fecha.value === "" ||
      props.inicia === "" ||
      props.tema === ""
    ) {
      enqueueSnackbar("Se deben llenar todos los datos de la reposición", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const endpoint = `/asignaciones/clases-especiales/nueva`;
      const body = {
        id_asignacion: props.materia.value,
        id_profesor: props.clave_profesor.value,
        id_profesor_nuevo: props.profesorSustitucion.value,
        id_dictamen: "",
        fecha: props.fecha.value || "",
        fechaNuevo: props.fecha.value || "",
        hora: "",
        hora_inicio: props.inicia || "",
        id_salon: props.salon.value || "",
        tema: props.tema || "",
        tipo_clase_especial: props.tipoClaseEspecial || "",
      };
      const response = await postRequest(endpoint, body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        props.handleClose();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const handleFecha = (e) => {
    props.setFecha(e);
    let dia = new Date(e.value).getDay();
    switch (dia) {
      case 0:
        props.setInicia(horasIniciales[0]);
        props.setTermina(horasFinales[0]);
        break;
      case 1:
        props.setInicia(horasIniciales[1]);
        props.setTermina(horasFinales[1]);
        break;
      case 2:
        props.setInicia(horasIniciales[2]);
        props.setTermina(horasFinales[2]);
        break;
      case 3:
        props.setInicia(horasIniciales[3]);
        props.setTermina(horasFinales[3]);
        break;
      case 4:
        props.setInicia(horasIniciales[4]);
        props.setTermina(horasFinales[4]);
        break;
      case 5:
        props.setInicia(horasIniciales[5]);
        props.setTermina(horasFinales[5]);
        break;
      default:
        props.setInicia(horasIniciales[6]);
        props.setTermina(horasFinales[6]);
        break;
    }
  };

  const handleProfesorSustitucion = (e) => {
    if (e.valido === "OK") {
      props.setProfesorSustitucion(e);
    } else {
      props.setProfesorSustitucion("");
      enqueueSnackbar("Profesor no activo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleChangeMateria = (e) => {
    if (props.clave_profesor.value) {
      props.setMateria(e);
      props.setInicia("");
      props.setTermina("");
      props.setFecha({});
      setOptionsFechaProfesor([]);
    } else {
      enqueueSnackbar("Se debe seleccionar un profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <>
      <NewModal
        title="Reposición Sustitución"
        open={props.open}
        handleClose={props.handleClose}
        height={700}
        maxWidth={1000}
        marginTop={"-35px"}
        loading={loading}
        overflowY={"unset"}
        handleSubmit={handleSubmit}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "30px", alignItems: "center" }}
          >
            <Grid item xs={1} style={{ marginLeft: "-3%" }}>
              <label className={styles.inputLabel}>Ciclo:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                className={styles.filterInput}
                style={{ width: "130%" }}
                value={props.ciclo}
                disabled
              />
            </Grid>

            <Grid item xs={1} style={{ marginLeft: "3%" }}>
              <label className={styles.inputLabel}>Dirección:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.direccion.label}
                disabled
              />
            </Grid>

            <Grid item xs={1}>
              <label className={styles.inputLabel}>Director:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.director}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "25px",
              alignItems: "center",
            }}
          >
            <Grid item xs={4}>
              <h4 className={styles.modalSectionName}>
                Datos de la clase que va a faltar
              </h4>
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={9} item style={{ padding: 2, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 20 }),
                }}
                loadOptions={promiseOptions}
                value={props.clave_profesor}
                onChange={(e) => {
                  setDetalleAsignacion("");
                  props.setGrupo("");
                  props.setMateria("");
                  props.setInicia("");
                  props.setTermina("");
                  props.setFecha({});
                  setMateriaOptions([]);
                  setOptionsFechaProfesor([]);
                  props.setClave_profesor(e);
                }}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={props.grupo}
                onChange={(e) => {
                  props.setGrupo(e.target.value);
                  props.setMateria("");
                  props.setInicia("");
                  props.setTermina("");
                  props.setFecha({});
                  setMateriaOptions([]);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={6}>
              <Select
                classNamePrefix="select-modal"
                options={materiaOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 19 }),
                }}
                isLoading={selectMateriaIsLoading}
                value={props.materia}
                onChange={(e) => handleChangeMateria(e)}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Fecha:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                classNamePrefix="select-modal"
                options={optionsFechaProfesor}
                isLoading={loadingSelect}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 18 }),
                }}
                onChange={(e) => handleFecha(e)}
                value={props.fecha}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Inicia:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                disabled
                value={props.inicia}
                style={{ fontSize: "16px", width: "225px" }}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "7.5%" }}>
              <label className={styles.inputLabel}>Termina:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                style={{ width: "105%" }}
                value={props.termina}
                disabled
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} style={{ marginLeft: "8.3%" }}>
              <label className={styles.inputLabel}>Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "148px" }}
                value={detalleAsignacion?.nombre_salon || ""}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <h4 className={styles.modalSectionName}>
                Datos de la Sustitución
              </h4>
            </Grid>
            <Grid item xs={9} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={6} item style={{ padding: 2, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 16 }),
                }}
                loadOptions={promiseOptions2}
                value={props.profesorSustitucion}
                onChange={(e) => handleProfesorSustitucion(e)}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tema:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 10px)",
                  marginRight: "10px",
                }}
                value={props.tema}
                onChange={(ev) => props.setTema(ev.target.value)}
              ></input>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </>
  );
}

export default Sustitucion;
