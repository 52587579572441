import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { ListaVerificacionProvider } from "./Utils/Providers/ListaVerificacionProvider";
import { ConcentradoProvider } from "./Utils/Providers/ConcentradoProvider";
import { CursoProvider } from "./Utils/Providers/CursoProvider";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import LoginPage from "./Vistas/LoginPage";
import Menu from "./Utils/Menu";
import Usuarios from "./Vistas/Usuarios";
import Profesores from "./Vistas/Profesores";
import Reactivar from "./Vistas/Reactivar";
import ConsultarEvaluacion from "./Vistas/ConsultarEvaluacion";
import ProyeccionesDeAsistencia from "./Vistas/ProyeccionesDeAsistencia";
import Profesiones from "./Vistas/Profesiones";
import Asignaciones from "./Vistas/Asignaciones";
import Excepciones from "./Vistas/Excepciones";
import Horario2 from "./Vistas/Horario2";
import Bitacora from "./Vistas/Bitacora";
import AsignacionesClasesEspeciales from "./Vistas/AsignacionesClasesEspeciales";
import Header from "./Utils/Visuales/Header";
import BloqueoAcademicoDocente from "./Vistas/BloqueoAcademicoDocente";
import Incapacidades from "./Vistas/Incapacidades";
import Pagos from "./Vistas/Pagos";
import KardexAcademico from "./Vistas/KardexAcademico";
import TotalProfCamEsc from "./Vistas/TotalProfCamEsc";
import ClasesEspeciales from "./Vistas/ClasesEspeciales";
import Dispositivos from "./Vistas/Dispositivos";
import FDA from "./Vistas/FDA";
import PagosExtraordinario from "./Vistas/PagosExtraordinario";
import ProfesoresActivos from "./Vistas/ProfesoresActivos";
import Mensajes from "./Vistas/Mensajes";
import { getRequest } from "./Utils/Funciones/requester";
import {
  setIsLoged,
  setCampusUser,
  setCampusUser2,
  setCampusId,
  setCampusName,
  setPersonId,
  setStatePermisos,
  setUserName,
  setUserRol,
} from "./state/actions";
import "./index.css";

import { Catalogos } from "./Vistas/Catalogos";
import AsignacionesKardexAcademico from "./Vistas/AsignacionesKardexAcademico";
import GrupoLogistica from "./Vistas/GrupoLogistica";
import Prefectura from "./Vistas/Prefectura";
import ComentariosDePrefectura from "./Vistas/ComentariosDePrefectura";
import IndicadoresOnline from "./Vistas/IndicadoresOnline";
import IndicadoresAcademicos from "./Vistas/IndicadoresAcademicos";
import DefinitivoBonoProductividad from "./Vistas/DefinitivoBonoProductividad";

import ParametrosInternos from "./Vistas/ParametosInternos";
import ConfiguracionCampus from "./Vistas/ConfiguracionCampus";
import DetalleIncidencias from "./Vistas/DetalleIncidencias";
import DirectoresAcademicos from "./Vistas/ConfiguracionDirectoresAcademicos";

import ReportedeAutorizaciondeFyC from "./Vistas/ReportedeAutorizaciondeFyC";
import ReporteProfesoresTitularesConGrupos from "./Vistas/ReporteProfesoresTitularesConGrupos";
import ReporteDireccionesAcademicas from "./Vistas/ReporteDireccionesAcademicas";
import ReporteCatalogoDeGrupos from "./Vistas/ReporteCatalogoDeGrupos";
import ReporteCatalogoDeGruposOnline from "./Vistas/ReporteCatalogoDeGruposOnline";
import ReporteGradoSuperiorAcademico from "./Vistas/ReporteGradoSuperiorAcademico";
import DesviacionAsig from "./Vistas/DesviacionAsig";
import Divisionales from "./Vistas/Divisionales";
import ConfiguracionDivisionales from "./Vistas/ConfiguracionDivisionales";
import ReporteSalonesDisponibles from "./Vistas/ReporteSalonesDisponibles";
import ReporteDetalleProfesor from "./Vistas/ReporteDetalleProfesor";
import ReporteIndicadoresDirector from "./Vistas/ReporteIndicadoresDirector";
import BitacoraAsignacion from "./Vistas/BitacoraAsignacion";
import PrevioBonoProductividad from "./Vistas/PrevioBonoProductividad";
import SalonesAsignaciones from "./Vistas/SalonesAsignaciones";
import SalonesApartado from "./Vistas/SalonesApartado";
import AsignacionesAutorizacionFaltasCancelaciones from "./Vistas/AsignacionesAutorizacionFaltasCancelaciones";
import ReporteBitacoraMovimientos from "./Vistas/ReporteBitacoraMovimientos";
import MantenimientoAsistencias from "./Vistas/MantenimientoAsistencias";
import ConfiguracionMateriaProfesion from "./Vistas/ConfiguracionMateriaProfesion";
import { ReportesIndicadoresAcademicosSemanales } from "./Vistas/ReportesIndicadoresAcademicosSemanales";
import { GeneracionAsistenciaPersonalDocente } from "./Vistas/GeneracionAsistenciaPersonalDocente";

function App() {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    nombre: "",
    rol: "",
  });
  const isLoged = useSelector((state) => state.isLoged);
  const dispatch = useDispatch();
  const [permisos, setPermisos] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  async function login() {
    setLoading(true);
    const campus_id = window.localStorage.getItem("campus_id");
    try {
      const data = await getRequest(
        `?plt=sica&rol=administrador&c=${campus_id}`,
        null,
        false,
        true
      );
      if (data.s === "OK") {
        window.location.href = data["d"]["data"]["url_login"];
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkLogin();
  }, []);

  async function checkLogin() {
    let token = localStorage.getItem("token_sica");
    if (token) {
      let myUrl = window.location.href;
      let inicioParametros = myUrl.indexOf("?");
      let newUrl = myUrl.substr(0, inicioParametros);

      window.history.pushState("obj", "newtitle", newUrl);
      dispatch(setIsLoged(true));
      let response = await getRequest("/login");
      if (response && response.s === "OK") {
        setIsLoged(true);
        setUserInfo({
          nombre: response.d.nombre,
          rol: response.d.rol_name,
          campus: response.d.campus_name,
        });
        let auxCam = [];
        let auxCam2 = [];
        auxCam.push({
          label: "Todos",
          value: "0",
        });
        response.d.campus.forEach((campus) => {
          auxCam.push({
            label: campus.clave + " - " + campus.nombre,
            value: String(campus.id),
            clave: campus.clave,
          });
          auxCam2.push({
            label: campus.clave + " - " + campus.nombre,
            value: String(campus.id),
            clave: campus.clave,
          });
        });
        dispatch(setCampusUser(auxCam));
        dispatch(setCampusUser2(auxCam2));
        dispatch(setCampusId(response.d.id_campus));
        dispatch(setCampusName(response.d.campus_name));
        dispatch(setPersonId(response.d.id_person));
        dispatch(setStatePermisos(response.d.permisos));
        dispatch(setUserName(response.d.nombre));
        dispatch(setUserRol(response.d.rol_name));
        setPermisos(response.d.permisos);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      const mode = urlParams.get("mode");
      const c = urlParams.get("c");
      const mail = urlParams.get("mail");
      const campus_id = window.localStorage.getItem("campus_id");
      if (mode === "dev" && c && mail) {
        setLoading(true);
        try {
          const data = await getRequest(
            `?plt=sica&code=${code}&c=${c}&rol=administrador&mode=${mode}&mail=${mail}`,
            null,
            false,
            true
          );

          if (data.s === "OK") {
            window.localStorage.setItem(
              "token_sica",
              data["d"]["data"]["token"]
            );
            window.location.href = data["d"]["data"]["reload_url"];
          } else {
            enqueueSnackbar(data.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            window.localStorage.removeItem("token_sica");
            let myUrl = window.location.href;
            let inicioParametros = myUrl.indexOf("?");
            let newUrl = myUrl.substr(0, inicioParametros);
            window.history.pushState("obj", "newtitle", newUrl);
            // setLogging(false);
          }
        } catch (error) {
          console.log(error);
          let myUrl = window.location.href;
          let inicioParametros = myUrl.indexOf("?");
          let newUrl = myUrl.substr(0, inicioParametros);

          window.history.pushState("obj", "newtitle", newUrl);
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          window.localStorage.removeItem("token_sica");
        } finally {
          setLoading(false);
        }
      } else if (code !== null) {
        setLoading(true);
        try {
          const data = await getRequest(
            `?plt=sica&code=${code}&c=${campus_id}&rol=administrador`,
            null,
            false,
            true
          );

          if (data.s === "OK") {
            window.localStorage.setItem(
              "token_sica",
              data["d"]["data"]["token"]
            );
            window.location.href = data["d"]["data"]["reload_url"];
          } else {
            enqueueSnackbar(data.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            window.localStorage.removeItem("token_sica");
            let myUrl = window.location.href;
            let inicioParametros = myUrl.indexOf("?");
            let newUrl = myUrl.substr(0, inicioParametros);
            window.history.pushState("obj", "newtitle", newUrl);
            // setLogging(false);
          }
        } catch (error) {
          console.log(error);
          let myUrl = window.location.href;
          let inicioParametros = myUrl.indexOf("?");
          let newUrl = myUrl.substr(0, inicioParametros);

          window.history.pushState("obj", "newtitle", newUrl);
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          window.localStorage.removeItem("token_sica");
        } finally {
          setLoading(false);
        }
      }
    }
  }

  return (
    <>
      {isLoged ? (
        <ListaVerificacionProvider>
          <ConcentradoProvider>
            <CursoProvider>
              <div className="App">
                <Header
                  username={userInfo.nombre}
                  rol={userInfo.rol}
                  campus={userInfo.campus}
                />
                <div className="container-main">
                  <Menu permisos={permisos} />
                  <Route path="/usuarios">
                    <Usuarios />
                  </Route>
                  <Route path="/profesores">
                    <Profesores />
                  </Route>
                  <Route path="/reactivar">
                    <Reactivar />
                  </Route>
                  <Route path="/Generación-Asistencia-Personal-Docente">
                    <GeneracionAsistenciaPersonalDocente />
                  </Route>
                  <Route path="/MantenimientoAsistencias">
                    <MantenimientoAsistencias />
                  </Route>
                  <Route path="/proyeccionesDeAsistencia">
                    <ProyeccionesDeAsistencia />
                  </Route>
                  <Route path="/consultarEvaluacion">
                    <ConsultarEvaluacion />
                  </Route>
                  <Route path="/profesiones">
                    <Profesiones />
                  </Route>
                  <Route path="/autorizacionFyC">
                    <AsignacionesAutorizacionFaltasCancelaciones />
                  </Route>
                  <Route path="/asignaciones">
                    <Asignaciones />
                  </Route>
                  <Route path="/excepciones">
                    <Excepciones />
                  </Route>
                  <Route path="/bloqueoacademico">
                    <BloqueoAcademicoDocente />
                  </Route>
                  <Route path="/horario">
                    <Horario2 />
                  </Route>
                  <Route path="/bitacora">
                    <Bitacora />
                  </Route>
                  <Route path="/asignacionesClasesEspeciales">
                    <AsignacionesClasesEspeciales />
                  </Route>
                  <Route path="/asignacionesKardexAcademico">
                    <AsignacionesKardexAcademico />
                  </Route>
                  <Route path="/mensajes">
                    <Mensajes />
                  </Route>
                  <Route path="/incapacidades">
                    <Incapacidades />
                  </Route>
                  {/* <Route path='/reportes_grupo'>
                    <ReportesGrupo />
                  </Route> */}
                  <Route path="/reportes_autorizaciondeFyC">
                    <ReportedeAutorizaciondeFyC />
                  </Route>
                  <Route path="/ReporteBitacoraMovimientos">
                    <ReporteBitacoraMovimientos />
                  </Route>
                  <Route path="/ReporteCatalogoDeGrupos">
                    <ReporteCatalogoDeGrupos />
                  </Route>
                  <Route path="/ReporteCatalogoDeGruposOnline">
                    <ReporteCatalogoDeGruposOnline />
                  </Route>
                  <Route path="/ReporteDireccionesAcademicas">
                    <ReporteDireccionesAcademicas />
                  </Route>
                  <Route path="/reporteSalonesDisponibles">
                    <ReporteSalonesDisponibles />
                  </Route>
                  <Route path="/indicadoresGlobalesdetalleProfesor">
                    <ReporteDetalleProfesor />
                  </Route>
                  <Route path="/indicadoresGlobalesDirector">
                    <ReporteIndicadoresDirector />
                  </Route>
                  <Route path="/ReporteGradoSuperiorAcademico">
                    <ReporteGradoSuperiorAcademico />
                  </Route>
                  <Route path="/ReporteProfesoresTitularesConGrupos">
                    <ReporteProfesoresTitularesConGrupos />
                  </Route>
                  <Route path="/profesores_activos">
                    <ProfesoresActivos />
                  </Route>
                  <Route path="/total_prof_cam_esc">
                    <TotalProfCamEsc />
                  </Route>
                  <Route path="/clases_especiales">
                    <ClasesEspeciales />
                  </Route>
                  <Route path="/dispositivos">
                    <Dispositivos />
                  </Route>
                  <Route path="/FDA">
                    <FDA />
                  </Route>
                  <Route path="/pago_extraordinario">
                    <PagosExtraordinario />
                  </Route>
                  <Route path="/detalle_incidencias">
                    <DetalleIncidencias />
                  </Route>
                  <Route path="/bitacora_asignacion">
                    <BitacoraAsignacion />
                  </Route>
                  <Route path="/catalogo">
                    <Catalogos />
                  </Route>
                  <Route path="/parametros_internos">
                    <ParametrosInternos />
                  </Route>
                  <Route path="/configuracion_campus">
                    <ConfiguracionCampus />
                  </Route>
                  <Route path="/directores_academicos">
                    <DirectoresAcademicos />
                  </Route>
                  <Route path="/configuracion_divisionales">
                    <ConfiguracionDivisionales />
                  </Route>
                  <Route path="/configuracion_materia_profesion">
                    <ConfiguracionMateriaProfesion />
                  </Route>
                  <Route path="/prefectura">
                    <Prefectura />
                  </Route>
                  <Route path="/desviacion_de_asignaciones">
                    <DesviacionAsig />
                  </Route>
                  <Route path="/divisionales">
                    <Divisionales />
                  </Route>
                  <Route path="/config">
                    <Divisionales />
                  </Route>
                  <Route path="/comentarios_de_prefectura">
                    <ComentariosDePrefectura />
                  </Route>
                  <Route path="/indicadores_online">
                    <IndicadoresOnline />
                  </Route>
                  <Route path="/indicadores_academicos">
                    <IndicadoresAcademicos />
                  </Route>
                  <Route path="/indicadores_academicos_semanales">
                    <ReportesIndicadoresAcademicosSemanales />
                  </Route>
                  <Route path="/definitivo_bono_productividad">
                    <DefinitivoBonoProductividad />
                  </Route>
                  <Route path="/previo_bono_productividad">
                    <PrevioBonoProductividad />
                  </Route>
                  <Route path="/kardex">
                    <KardexAcademico />
                  </Route>
                  <Route path="/salonesAsignaciones">
                    <SalonesAsignaciones />
                  </Route>
                  <Route path="/salonesApartado">
                    <SalonesApartado />
                  </Route>
                  <Route path="/grupoLogistica">
                    <GrupoLogistica />
                  </Route>
                  <Route path="/pagos">
                    <Pagos />
                  </Route>
                </div>
              </div>
            </CursoProvider>
          </ConcentradoProvider>
        </ListaVerificacionProvider>
      ) : (
        <LoginPage login={login} loading={loading} />
      )}
    </>
  );
}

export default App;
