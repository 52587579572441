import React, { useState } from "react";
import "./index.css";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "../../images/MenuIcon";
import DocentesIcon from "../../images/DocentesIcon";
import AsignacionesIcon from "../../images/AsignacionesIcon";
import EnvioIcon from "../../images/EnvioIcon";
import ReportesIcon from "../../images/ReportesIcon";
import UsuariosIcon from "../../images/UsuariosIcon";
import { ArrowDropDown, ArrowRight } from "@material-ui/icons";

export default function Menu({ permisos }) {
  let { pathname } = useLocation();
  const currentPath = window.location.pathname.replace(/\/$/, "");
  const [asignacionesIsOpen, setAsignacionesIsOpen] = useState(false);
  const [profesoresIsOpen, setProfesoresIsOpen] = useState(false);
  const [reportesIsOpen, setReportesIsOpen] = useState(false);
  const [configuracionIsOpen, setConfiguracionIsOpen] = useState(false);
  const [salonesIsOpen, setSalonesIsOpen] = useState(false);

  const menuChanges = (to) => {
    switch (to) {
      case "asignaciones":
        setAsignacionesIsOpen(!asignacionesIsOpen);
        setReportesIsOpen(false);
        setProfesoresIsOpen(false);
        setConfiguracionIsOpen(false);
        setSalonesIsOpen(false);
        break;
      case "reportes":
        setAsignacionesIsOpen(false);
        setReportesIsOpen(!reportesIsOpen);
        setProfesoresIsOpen(false);
        setConfiguracionIsOpen(false);
        setSalonesIsOpen(false);
        break;
      case "profesores":
        setAsignacionesIsOpen(false);
        setReportesIsOpen(false);
        setProfesoresIsOpen(!profesoresIsOpen);
        setConfiguracionIsOpen(false);
        setSalonesIsOpen(false);
        break;
      case "configuracion":
        setAsignacionesIsOpen(false);
        setReportesIsOpen(false);
        setProfesoresIsOpen(false);
        setConfiguracionIsOpen(!configuracionIsOpen);
        setSalonesIsOpen(false);
        break;
      case "salones":
        setAsignacionesIsOpen(false);
        setReportesIsOpen(false);
        setProfesoresIsOpen(false);
        setConfiguracionIsOpen(false);
        setSalonesIsOpen(!salonesIsOpen);
        break;
      case "none":
        setAsignacionesIsOpen(false);
        setReportesIsOpen(false);
        setConfiguracionIsOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <aside>
      <ul>
        <li className="menu-title">
          <MenuIcon />
          Menú
        </li>
        {permisos.Usuarios ? (
          permisos.Usuarios.leer === "1" ? (
            <li
              className={pathname === "/usuarios" ? "active" : null}
              style={{ position: "relative" }}
              onClick={() => menuChanges("none")}
            >
              <Link to="/usuarios">
                <UsuariosIcon /> Usuarios
              </Link>
            </li>
          ) : null
        ) : null}

        {permisos.Profesores ? (
          permisos.Profesores.leer === "1" ? (
            <li style={{ position: "relative" }}>
              <a href onClick={() => menuChanges("profesores")}>
                <DocentesIcon /> Profesores
                {profesoresIsOpen ? (
                  <ArrowDropDown className="ArrowRight" />
                ) : (
                  <ArrowRight className="ArrowRight" />
                )}
              </a>
              {profesoresIsOpen ? (
                <ul>
                  {permisos["Profesores->Consultar Evaluación"] ? (
                    permisos["Profesores->Consultar Evaluación"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/consultarEvaluacion" ? "active" : null
                        }
                      >
                        <Link to="/consultarEvaluacion">
                          Consultar Evaluación
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Profesores->FDA"] ? (
                    permisos["Profesores->FDA"].leer === "1" ? (
                      <li className={pathname === "/FDA" ? "active" : null}>
                        <Link to="/FDA">FDA</Link>
                      </li>
                    ) : null
                  ) : null}
                  {permisos["Profesores->Generación Asistencia Personal Docente"] ? (
                    permisos["Profesores->Generación Asistencia Personal Docente"].leer === "1" ? (
                      <li className={pathname === "/Generación-Asistencia-Personal-Docente" ? "active" : null}>
                        <Link to="/Generación-Asistencia-Personal-Docente">Generación Asistencia Personal Docente</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Profesores->Mantenimiento de Asistencias"] ? (
                    permisos["Profesores->Mantenimiento de Asistencias"]
                      .leer === "1" ? (
                      <li
                        className={
                          pathname === "/MantenimientoAsistencias"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/MantenimientoAsistencias">
                          Mantenimiento de Asistencias
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Profesores->Mant. Profesiones"] ? (
                    permisos["Profesores->Mant. Profesiones"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/profesiones" ? "active" : null
                        }
                      >
                        <Link to="/profesiones">Mant. Profesiones</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Profesores->Profesores"] ? (
                    permisos["Profesores->Profesores"].leer === "1" ? (
                      <li
                        className={pathname === "/profesores" ? "active" : null}
                      >
                        <Link to="/profesores">Profesores</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Profesores->Proyecciones de Asistencias"] ? (
                    permisos["Profesores->Proyecciones de Asistencias"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/proyeccionesDeAsistencia"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/proyeccionesDeAsistencia">
                          Proyecciones de Asistencias
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Profesores->Reactivar"] ? (
                    permisos["Profesores->Reactivar"].leer === "1" ? (
                      <li
                        className={pathname === "/reactivar" ? "active" : null}
                      >
                        <Link to="/reactivar">Reactivar</Link>
                      </li>
                    ) : null
                  ) : null}
                  {permisos["Profesores->Mantenimiento de Asistencias"] ? (
                    permisos["Profesores->Mantenimiento de Asistencias"]
                      .leer === "1" ? (
                      <li
                        className={pathname === "/checador" ? "active" : null}
                      >
                        <Link
                          to={`${currentPath}/checador`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Reloj Checador
                        </Link>
                      </li>
                    ) : null
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null
        ) : null}

        {permisos.Asignaciones ? (
          permisos.Asignaciones.leer === "1" ? (
            <li style={{ position: "relative" }}>
              <a href onClick={() => menuChanges("asignaciones")}>
                <AsignacionesIcon /> Asignaciones
                {asignacionesIsOpen ? (
                  <ArrowDropDown className="ArrowRight" />
                ) : (
                  <ArrowRight className="ArrowRight" />
                )}
              </a>
              {asignacionesIsOpen ? (
                <ul>
                  {permisos[
                    "Asignaciones->Autorización de Faltas y Cancelaciones"
                  ] ? (
                    permisos[
                      "Asignaciones->Autorización de Faltas y Cancelaciones"
                    ].leer === "1" ? (
                      <li
                        className={
                          pathname === "/autorizacionFyC" ? "active" : null
                        }
                      >
                        <Link to="/autorizacionFyC">
                          Autorización de Faltas y Cancelaciones
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Asignaciones"] ? (
                    permisos["Asignaciones->Asignaciones"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/asignaciones" ? "active" : null
                        }
                      >
                        <Link to="/asignaciones">Asignaciones</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Excepciones"] ? (
                    permisos["Asignaciones->Excepciones"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/excepciones" ? "active" : null
                        }
                      >
                        <Link to="/excepciones">Excepciones</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Bloqueo Académico Docente"] ? (
                    permisos["Asignaciones->Bloqueo Académico Docente"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/bloqueoacademico" ? "active" : null
                        }
                      >
                        <Link to="/bloqueoacademico">
                          Bloqueo Académico Docente
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Horario"] ? (
                    permisos["Asignaciones->Horario"].leer === "1" ? (
                      <li className={pathname === "/horario" ? "active" : null}>
                        <Link to="/horario">Horario</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Bitácora"] ? (
                    permisos["Asignaciones->Bitácora"].leer === "1" ? (
                      <li
                        className={pathname === "/bitacora" ? "active" : null}
                      >
                        <Link to="/bitacora">Bitácora</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Clases Especiales"] ? (
                    permisos["Asignaciones->Clases Especiales"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/asignacionesClasesEspeciales"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/asignacionesClasesEspeciales">
                          Clases Especiales
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Asignaciones->Kardex Académico"] ? (
                    permisos["Asignaciones->Kardex Académico"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/asignacionesKardexAcademico"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/asignacionesKardexAcademico">
                          Kardex Académico
                        </Link>
                      </li>
                    ) : null
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null
        ) : null}

        {permisos.Mensajes ? (
          permisos.Mensajes.leer === "1" ? (
            <li
              className={pathname === "/mensajes" ? "active" : null}
              style={{ position: "relative" }}
              onClick={() => menuChanges("none")}
            >
              <Link to="/mensajes">
                <ReportesIcon /> Mensajes
              </Link>
            </li>
          ) : null
        ) : null}

        {permisos.Incapacidades ? (
          permisos.Incapacidades.leer === "1" ? (
            <li
              className={pathname === "/incapacidades" ? "active" : null}
              style={{ position: "relative" }}
              onClick={() => menuChanges("none")}
            >
              <Link to="/incapacidades">
                <EnvioIcon /> Incapacidades
              </Link>
            </li>
          ) : null
        ) : null}

        {permisos.Dispositivos ? (
          permisos.Dispositivos.leer === "1" ? (
            <li
              className={pathname === "/dispositivos" ? "active" : null}
              style={{ position: "relative" }}
              onClick={() => menuChanges("none")}
            >
              <Link to="/dispositivos">
                <EnvioIcon /> Dispositivos
              </Link>
            </li>
          ) : null
        ) : null}

        {permisos.Reportes ? (
          permisos.Reportes.leer === "1" ? (
            <li style={{ position: "relative" }}>
              <a href onClick={() => menuChanges("reportes")}>
                <DocentesIcon /> Reportes
                {reportesIsOpen ? (
                  <ArrowDropDown className="ArrowRight" />
                ) : (
                  <ArrowRight className="ArrowRight" />
                )}
              </a>
              {reportesIsOpen ? (
                <ul style={{ fontSize: "15px" }}>
                  {permisos[
                    "Reportes->Autorización de Faltas y Cancelaciones"
                  ] ? (
                    permisos["Reportes->Autorización de Faltas y Cancelaciones"]
                      .leer === "1" ? (
                      <li
                        className={
                          pathname === "/reportes_autorizaciondeFyC"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/reportes_autorizaciondeFyC">
                          Autorización de Faltas y Cancelaciones
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Bitácora de Asignación"] ? (
                    permisos["Reportes->Bitácora de Asignación"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/bitacora_asignacion" ? "active" : null
                        }
                      >
                        <Link to="/bitacora_asignacion">
                          Bitácora de Asignación
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Bitácora de Movimientos"] ? (
                    permisos["Reportes->Bitácora de Movimientos"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/ReporteBitacoraMovimientos"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/ReporteBitacoraMovimientos">
                          Bitácora de Movimientos
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Catálogo de Grupos"] ? (
                    permisos["Reportes->Catálogo de Grupos"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/ReporteCatalogoDeGrupos"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/ReporteCatalogoDeGrupos">
                          Catálogo de Grupos
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Catálogo de Grupos Online"] ? (
                    permisos["Reportes->Catálogo de Grupos Online"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/ReporteCatalogoDeGruposOnline"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/ReporteCatalogoDeGruposOnline">
                          Catálogo de Grupos Online
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Clases Especiales"] ? (
                    permisos["Reportes->Clases Especiales"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/clases_especiales" ? "active" : null
                        }
                      >
                        <Link to="/clases_especiales">Clases Especiales</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Comentarios de Prefectura"] ? (
                    permisos["Reportes->Comentarios de Prefectura"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/comentarios_de_prefectura"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/comentarios_de_prefectura">
                          Comentarios de Prefectura
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Definitivo Bono de Productividad"] ? (
                    permisos["Reportes->Definitivo Bono de Productividad"]
                      .leer === "1" ? (
                      <li
                        className={
                          pathname === "/definitivo_bono_productividad"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/definitivo_bono_productividad">
                          Definitivo Bono de Productividad
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Desviación de Asignaciones"] ? (
                    permisos["Reportes->Desviación de Asignaciones"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/desviacion_de_asignaciones"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/desviacion_de_asignaciones">
                          Desviación de Asignaciones
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Detalle de Incidencias"] ? (
                    permisos["Reportes->Detalle de Incidencias"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/detalle_incidencias" ? "active" : null
                        }
                      >
                        <Link to="/detalle_incidencias">
                          Detalle de Incidencias
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Direcciones Académicas"] ? (
                    permisos["Reportes->Direcciones Académicas"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/ReporteDireccionesAcademicas"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/ReporteDireccionesAcademicas">
                          Direcciones Académicas
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Divisionales"] ? (
                    permisos["Reportes->Divisionales"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/divisionales" ? "active" : null
                        }
                      >
                        <Link to="/divisionales">Divisionales</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Grado Superior Académico"] ? (
                    permisos["Reportes->Grado Superior Académico"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/ReporteGradoSuperiorAcademico"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/ReporteGradoSuperiorAcademico">
                          Grado Superior Académico
                        </Link>
                      </li>
                    ) : null
                  ) : null}
                  {permisos["Reportes->Indicadores Académicos"] ? (
                    permisos["Reportes->Indicadores Académicos"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/indicadores_academicos"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/indicadores_academicos">
                          Indicadores Académicos
                        </Link>
                      </li>
                    ) : null
                  ) : null}
                  {permisos["Reportes->Indicadores Académicos"] ? (
                    permisos["Reportes->Indicadores Académicos"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/indicadores_academicos_semanales"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/indicadores_academicos_semanales">
                          Indicadores Académicos Semanales
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Indicadores Globales por Director"] ? (
                    permisos["Reportes->Indicadores Globales por Director"]
                      .leer === "1" ? (
                      <li
                        className={
                          pathname === "/indicadoresGlobalesDirector"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/indicadoresGlobalesDirector">
                          Indicadores Globales por Director
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos[
                    "Reportes->Indicadores Globales – Detalles por Profesor"
                  ] ? (
                    permisos[
                      "Reportes->Indicadores Globales – Detalles por Profesor"
                    ].leer === "1" ? (
                      <li
                        className={
                          pathname === "/indicadoresGlobalesdetalleProfesor"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/indicadoresGlobalesdetalleProfesor">
                          Indicadores Globales – Detalles por Profesor
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Indicadores Online"] ? (
                    permisos["Reportes->Indicadores Online"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/indicadores_online" ? "active" : null
                        }
                      >
                        <Link to="/indicadores_online">Indicadores Online</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Kardex Académico"] ? (
                    permisos["Reportes->Kardex Académico"].leer === "1" ? (
                      <li className={pathname === "/kardex" ? "active" : null}>
                        <Link to="/kardex">Kardex Academico</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Pagos Extraordinarios"] ? (
                    permisos["Reportes->Pagos Extraordinarios"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/pago_extraordinario" ? "active" : null
                        }
                      >
                        <Link to="/pago_extraordinario">
                          Pagos Extraordinarios
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Prefectura"] ? (
                    permisos["Reportes->Prefectura"].leer === "1" ? (
                      <li
                        className={pathname === "/prefectura" ? "active" : null}
                      >
                        <Link to="/prefectura">Prefectura</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Previo Bono de Productividad"] ? (
                    permisos["Reportes->Previo Bono de Productividad"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/previo_bono_productividad"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/previo_bono_productividad">
                          Previo Bono de Productividad
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Profesores Activos"] ? (
                    permisos["Reportes->Profesores Activos"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/profesores_activos" ? "active" : null
                        }
                      >
                        <Link to="/profesores_activos">Profesores Activos</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos[
                    "Reportes->Profesores Titulares con Grupos de Asistentes"
                  ] ? (
                    permisos[
                      "Reportes->Profesores Titulares con Grupos de Asistentes"
                    ].leer === "1" ? (
                      <li
                        className={
                          pathname === "/ReporteProfesoresTitularesConGrupos"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/ReporteProfesoresTitularesConGrupos">
                          Profesores Titulares con Grupos de Asistentes
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Reportes->Salones Disponibles"] ? (
                    permisos["Reportes->Salones Disponibles"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/reporteSalonesDisponibles"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/reporteSalonesDisponibles">
                          Salones Disponibles
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos[
                    "Reportes->Total Horas Asignadas por profesor, campus y escuela"
                  ] ? (
                    permisos[
                      "Reportes->Total Horas Asignadas por profesor, campus y escuela"
                    ].leer === "1" ? (
                      <li
                        className={
                          pathname === "/total_prof_cam_esc" ? "active" : null
                        }
                      >
                        <Link to="/total_prof_cam_esc">
                          Total Horas Asignadas por profesor, campus y escuela
                        </Link>
                      </li>
                    ) : null
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null
        ) : null}

        {permisos.Configuración ? (
          permisos.Configuración.leer === "1" ? (
            <li style={{ position: "relative" }}>
              <a href onClick={() => menuChanges("configuracion")}>
                <DocentesIcon /> Configuración
                {configuracionIsOpen ? (
                  <ArrowDropDown className="ArrowRight" />
                ) : (
                  <ArrowRight className="ArrowRight" />
                )}
              </a>
              {configuracionIsOpen ? (
                <ul>
                  {permisos["Configuración->Cátalogos"] ? (
                    permisos["Configuración->Cátalogos"].leer === "1" ? (
                      <li
                        className={pathname === "/catalogo" ? "active" : null}
                      >
                        <Link to="/catalogo">Catálogos</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Configuración->Parámetros Internos"] ? (
                    permisos["Configuración->Parámetros Internos"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/parametros_internos" ? "active" : null
                        }
                      >
                        <Link to="/parametros_internos">
                          Parámetros Internos
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Configuración->Campus"] ? (
                    permisos["Configuración->Campus"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/configuracion_campus" ? "active" : null
                        }
                      >
                        <Link to="/configuracion_campus">Campus</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Configuración->Directores Académicos"] ? (
                    permisos["Configuración->Directores Académicos"].leer ===
                    "1" ? (
                      <li
                        className={
                          pathname === "/directores_academicos"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/directores_academicos">
                          Directores Académicos
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Configuración->Divisionales"] ? (
                    permisos["Configuración->Divisionales"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/configuracion_divisionales"
                            ? "active"
                            : null
                        }
                      >
                        <Link to="/configuracion_divisionales">
                          Divisionales
                        </Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Configuración->Materia-Profesión"]?.leer ===
                  "1" ? (
                    <li
                      className={
                        pathname === "/configuracion_materia_profesion"
                          ? "active"
                          : null
                      }
                    >
                      <Link to="/configuracion_materia_profesion">
                        Materia - Profesión
                      </Link>
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null
        ) : null}

        {permisos.Salones ? (
          permisos.Salones.leer === "1" ? (
            <li style={{ position: "relative" }}>
              <a href onClick={() => menuChanges("salones")}>
                <DocentesIcon /> Salones
                {salonesIsOpen ? (
                  <ArrowDropDown className="ArrowRight" />
                ) : (
                  <ArrowRight className="ArrowRight" />
                )}
              </a>
              {salonesIsOpen ? (
                <ul>
                  {permisos["Salones->Asignaciones"] ? (
                    permisos["Salones->Asignaciones"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/salonesAsignaciones" ? "active" : null
                        }
                      >
                        <Link to="/salonesAsignaciones">Asignaciones</Link>
                      </li>
                    ) : null
                  ) : null}

                  {permisos["Salones->Apartado"] ? (
                    permisos["Salones->Apartado"].leer === "1" ? (
                      <li
                        className={
                          pathname === "/salonesApartado" ? "active" : null
                        }
                      >
                        <Link to="/salonesApartado">Apartado</Link>
                      </li>
                    ) : null
                  ) : null}
                </ul>
              ) : null}
            </li>
          ) : null
        ) : null}

        {permisos.Pagos ? (
          permisos.Pagos.leer === "1" ? (
            <li
              className={pathname === "/pagos" ? "active" : null}
              style={{ position: "relative" }}
              onClick={() => menuChanges("none")}
            >
              <Link to="/pagos">
                <EnvioIcon /> Pagos
              </Link>
            </li>
          ) : null
        ) : null}
      </ul>
    </aside>
  );
}
