import PropTypes from 'prop-types';
import "./index.css";
import { ThreeDots } from  'react-loader-spinner'

CustomSpinner.propTypes = {
    visible: PropTypes.bool
};

export function CustomSpinner({visible }) {
    return(
        <div className={visible ? 'class_spinner' : ''}>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#026eb7"
                    ariaLabel="three-dots-loading"
                    visible={visible}
                />
            </div>
    )
}