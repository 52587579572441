import { Grid } from "@material-ui/core";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import "./index.css";
import styles from "./styles.module.css";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import AsyncSelect from "react-select/async";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import EditButton from "../../Utils/Botones/EditButton";
import { sleep } from "../../Utils/Funciones/sleep";

const Pagos = () => {
  const headers = [
    {
      name: "Folio",
      col: 0,
    },
    {
      name: "Clave Profesor",
      col: 1,
    },
    {
      name: "Nombre Profesor",
      col: 2,
    },
    {
      name: "Fecha",
      col: 3,
    },
    {
      name: "Motivo Pago",
      col: 4,
    },
    {
      name: "Grupo",
      col: 5,
    },
    {
      name: "Nombre Materia",
      col: 6,
    },
    {
      name: "Horas",
      col: 7,
    },
    {
      name: "Importe",
      col: 8,
    },
    {
      name: "Unidades",
      col: 9,
    },
    {
      name: "Estatus",
      col: 10,
    },
    {
      name: "Comentario",
      col: 11,
    },
    {
      name: "Fecha creada",
      col: 11,
    },
    {
      name: "Creado",
      col: 11,
    },
    {
      name: "Fecha Modificada",
      col: 11,
    },
    {
      name: "Modificado",
      col: 11,
    },
    {
      name: "Acciones",
      col: 11,
    },
  ];
  const [modalHabilitarEstatusIsOpen, setModalHabilitarEstatusIsOpen] = useState(false);
  const [idEstatusHabilitar, setIdEstatusHabilitar] = useState("");
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fechaConditional, setFechaConditional] = useState(false);
  const [quincenaConditional, setQuincenaConditional] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectDataEstatus, setSelectDataEstatus] = useState([]);
  const [selectDataConcepto, setSelectDataConcepto] = useState([]);
  const [listQuincenaInicial, setListQuincenaInicio] = useState([]);
  const [listQuincenaFin, setListQuincenaFin] = useState([]);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [rango, setRango] = useState([]);

  const [fechaMovimientoValueModal, setFechaMovimientoValueModal] = useState("");
  const [secuenciaValueModal, setSecuenciaValueModal] = useState("");
  const [horaInicioValueModal, setHoraInicioValueModal] = useState(null);
  const [importeValueModal, setImporteValueModal] = useState("");
  const [cicloValueModal, setCicloValueModal] = useState("");
  const [comentarioValueModal, setComentarioValueModal] = useState("");
  const [inputImporte, setInputImporte] = useState(false)
  const [inputHora, setInputHora] = useState(false)
  const [auxHoraInicio, setAuxHoraInicio] = useState(false);
  const [grupoValueModal, setGrupoValueModal] = useState("");
  const [escuelaValueModal, setEscuelaValueModal] = useState("");
  const [carreraValueModal, setCarreraValueModal] = useState("");
  const [importeMaxText, setImporteMaxText] = useState("");
  const [importeMaxLabel, setImporteMaxLabel] = useState("");
  const [selectDataDireccionModal, setSelectDataDireccionModal] = useState([]);
  const [horaIniciaValues, setHoraIniciaValues] = useState('[]');
  const [minutosIniciaValues, setMinutosIniciaValues] = useState('[]');
  const [materia, setMateria] = useState({
    label: "",
    value: "",
  });
  const [profesorValueModal, setProfesorValueModal] = useState({
    label: "",
    value: "",
  });
  const [direccionValueModal, setDireccionValueModal] = useState({
    label: "",
    value: "",
  });
  const [conceptoValueModal, setConceptoValueModal] = useState({
    label: "",
    value: "",
  });
  const [statusValue, setStatusValue] = useState("");
  const [conceptoValue, setConceptoValue] = useState({
    label: "",
    value: "",
  });
  const [quincenaInicioValue, setQuincenaInicioValue] = useState({
    label: "",
    value: "",
  });
  const [quincenaFinValue, setQuincenaFinValue] = useState({
    label: "",
    value: "",
  });
  const [fechaInicialValue, setFechaInicialValue] = useState("");
  const [fechaFinalValue, setFechaFinalValue] = useState("");
  const [profesorValue, setProfesorValue] = useState({
    label: "",
    value: "",
  });

  const [listaYear] = useState([
    { label: "2015", value: "2015" },
    { label: "2016", value: "2016" },
    { label: "2017", value: "2017" },
    { label: "2018", value: "2018" },
    { label: "2019", value: "2019" },
    { label: "2020", value: "2020" },
    { label: "2021", value: "2021" },
    { label: "2022", value: "2022" },
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
  ]);

  const [listRango] = useState([
    { label: "Fecha", value: "Fecha" },
    { label: "Quincena", value: "Quincena" },
  ]);
  const campusId = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);

  const GetDataTable = async () => {
    if(statusValue === "") {
      enqueueSnackbar("Seleccione Estatus", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (
      fechaInicialValue === "" &&
      fechaFinalValue === "" &&
      quincenaInicioValue.value === "" &&
      quincenaFinValue.value === ""
    ) {
      enqueueSnackbar("Seleccione Fecha o quincena", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return
    }
    if (
      fechaInicialValue !== "" &&
      fechaFinalValue === "" &&
      quincenaInicioValue.value === "" &&
      quincenaFinValue.value === ""
    ) {
      enqueueSnackbar("Seleccione Fecha final", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return
    }
    if (
      fechaInicialValue === "" &&
      fechaFinalValue !== "" &&
      quincenaInicioValue.value === "" &&
      quincenaFinValue.value === ""
    ) {
      enqueueSnackbar("Seleccione Fecha  inicial", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return
    }
    if (fechaInicialValue > fechaFinalValue) {
      enqueueSnackbar("Fecha inicial debe ser menor que fecha final", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return
    }
    if (
      fechaInicialValue === "" &&
      fechaFinalValue === "" &&
      quincenaInicioValue.value !== "" &&
      quincenaFinValue.value === ""
    ) {
      enqueueSnackbar("Seleccione quincena final", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return
    }
    if (
      fechaInicialValue === "" &&
      fechaFinalValue === "" &&
      quincenaInicioValue.value === "" &&
      quincenaFinValue.value !== ""
    ) {
      enqueueSnackbar("Seleccione quincena inicial", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return
    }
    try {
      setLoading(true);
      const conceptoValueF = conceptoValue.value;
      const profesorValueF = profesorValue.value;
      let consulta = `/pagos-extraordinarios?id_estatus=${statusValue.value}&fecha_inicio=${quincenaConditional ? quincenaInicioValue.value : fechaInicialValue}&fecha_fin=${quincenaConditional ? quincenaFinValue.value : fechaFinalValue}&id_campus=${campusId}&rango=${rango}`;
      if (profesorValueF) consulta += `&id_profesor=${profesorValueF}`
      if (conceptoValueF) consulta += `&id_concepto=${conceptoValueF}`
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        if (response.d.length === 0) {
          enqueueSnackbar("No se hayaron resultados con dichos datos", {
            variant: "info",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          let datos = [];
          const filas = response.d[0]["id"].length;
          for (let i = 0; i < filas; i++) {
            datos[i] = [
              response.d[0]["id"][i],
              response.d[1]["Clave Profesor"][i],
              response.d[2]["Nombre Profesor"][i],
              response.d[3]["Fecha"][i],
              response.d[4]["Motivo Pago"][i],
              response.d[5]["Grupo"][i],
              response.d[6]["Materia"][i],
              response.d[7]["Hrs"][i],
              response.d[8]["Importe"][i],
              response.d[9]["Unidades"][i],
              response.d[10]["Estatus"][i],
              response.d[11]["Comentario"][i],
              response.d[13]["Creado"][i],
              response.d[12]["Creado_por"][i],
              response.d[15]["Modificado"][i],
              response.d[14]["Modificado_por"][i],
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <EditButton
                  onClick={() => { HandleHabilitarEstatus(response.d[0]["id"][i]) }}
                  disabled={response.d[10]["Estatus"][i] == 'V' ? false : true}
                >
                  Cancelar
                </EditButton>
              </div>,
            ];
          }
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setDataOriginal(datos);
          setLoading(false);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setDataOriginal([]);
        //setShowTable(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getDataEscuelaCarrera = async () => {
    try {
      setSelectMateriaIsLoading(true)
      const url = `/buscar?tipo=asignacion_pagos_extraordinarios&valor=id_campus:${campusId},grupo:${grupoValueModal},ciclo:${cicloValueModal},id_direccion:${direccionValueModal.value}`;
      const response = await getRequest(url);
      if (response.s === "OK") {
        setMateriaOptions(response.d.map((element) => {
          return {
            value: element.clave_materia,
            label: `${element.clave_materia} - ${element.nombre_materia}`,
            id_asignacion: element.id_asignacion
          }
        }))
      }
      setSelectMateriaIsLoading(false)
    } catch (error) {
      console.log(error);
      setSelectMateriaIsLoading(false)
    }
  };

  const GetListQuincena = async (e) => {
    try {
      setLoading(true);
      const consulta = `/buscar?tipo=quincena&valor=${e.value}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        const dataObjectInicio = Object.values(response.d).map((e, i) => {
          const data_ = {
            label: `${i + 1} - ${e.fecha_inicio} al ${e.fecha_fin}`,
            value: e.fecha_inicio,
          };
          return data_;
        });

        const dataObjectFin = Object.values(response.d).map((e, i) => {
          const data_ = {
            label: `${i + 1} - ${e.fecha_inicio} al ${e.fecha_fin}`,
            value: e.fecha_fin,
          };
          return data_;
        });

        setListQuincenaInicio(dataObjectInicio);
        setListQuincenaFin(dataObjectFin);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const GetDataEstatus = async (e) => {
    try {
      setLoading(true);
      const consulta = `/catalogo?id=10`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.elementos.forEach((e, i) => {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;
        for (let step = 0; step < numRows_; step++) {
          response.d.elementos.forEach((e, i) => {
            Object.values(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        const dataSelect = rowsP.map((e) => {
          const data_ = {
            label: `${e[0]} - ${e[1]}`,
            value: e[0],
          };
          return data_;
        });
        setSelectDataEstatus(dataSelect);

        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDataConcepto = async (e) => {
    try {
      setLoading(true);
      const consulta = `/catalogo?id=6`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.elementos.forEach((e, i) => {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        //console.log(numRows_)
        for (let step = 0; step < numRows_; step++) {
          response.d.elementos.forEach((e, i) => {
            Object.values(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        let dataSelect = [];
        rowsP.forEach((e) => {
          dataSelect.push({
            label: `${e[1]} - ${e[2]}`,
            value: e[0],
            importMax: parseFloat(e[4]),
            mostrarImporte: e[5],
            horaInicio2: e[6],
            tipo: e[3]
          });
        });
        setSelectDataConcepto(dataSelect);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const conceptoFilteredOptions = (option, inputValue) => {
    if (!inputValue) {
      return true;
    }
    const regex = new RegExp(`\\b${inputValue}`, 'i');
    return regex.test(option.label);
  };

  const clearModal = () => {
    setProfesorValueModal("");
    setConceptoValueModal("");
    setFechaMovimientoValueModal("");
    setSecuenciaValueModal("");
    setHoraInicioValueModal("");
    setImporteValueModal("");
    setCicloValueModal("");
    setComentarioValueModal("");
    setDireccionValueModal("");
    setGrupoValueModal("");
    setEscuelaValueModal("");
    setCarreraValueModal("");
    setHoraIniciaValues("");
    setMinutosIniciaValues("");
    setMateria({
      label: "",
      value: "",
    })
    setInputHora(false)
    setAuxHoraInicio(false);
  };

  /********************* */
  const handleSubmitPagos = async () => {
    let valido = true;
    if (profesorValueModal === "" || profesorValueModal.value === "") {
      valido = false;
      enqueueSnackbar("Falta clave profesor", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (conceptoValueModal.value === "") {
      valido = false;
      enqueueSnackbar("Falta concepto pago", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (fechaMovimientoValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta fecha movimiento", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    // if (secuenciaValueModal === "") {
    //   valido = false;
    //   enqueueSnackbar("Falta secuencia", {
    //     variant: "error", autoHideDuration: null,
    //     anchorOrigin: {
    //       vertical: "top",
    //       horizontal: "left",
    //     },
    //   });
    // }

    if (importeValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta importe a pagar", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (cicloValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta ciclo", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }

    if (grupoValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta grupo", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (materia?.value === "" || materia?.value === undefined) {
      valido = false;
      enqueueSnackbar("Falta materia", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (escuelaValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta escuela", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (carreraValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta carrera", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (direccionValueModal.value === "") {
      valido = false;
      enqueueSnackbar("Falta dirección", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    if (comentarioValueModal === "") {
      valido = false;
      enqueueSnackbar("Falta comentarios", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    try {
      if (valido) {
        if (parseFloat(importeValueModal) > conceptoValueModal.importMax) {
          enqueueSnackbar("El importe supera lo máximo permitido", {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        }
        const consulta = "/pagos-extraordinarios";
        const json = {
          id_profesor: profesorValueModal.value,
          id_concepto_pago_extraordinario: Number(conceptoValueModal.value),
          fecha_movimiento: fechaMovimientoValueModal,
          secuencia: "0",
          hora_inicio: horaIniciaValues !== "" && minutosIniciaValues !== ""
            ? `${horaIniciaValues}:${minutosIniciaValues}`
            : "",
          importe: parseFloat(importeValueModal),
          ciclo: cicloValueModal,
          comentario: comentarioValueModal,
          id_direccion: Number(direccionValueModal.value),
          id_asignacion: materia.id_asignacion,
          id_campus: campusId
        };
        setLoadingModal(true);
        const response = await postRequest(consulta, json);
        setLoadingModal(false);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          clearModal();
          setModalIsOpen(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetDataDireccion = async (e) => {
    try {
      setLoading(true);
      const consulta = `/catalogo?id=7`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.elementos.forEach((e, i) => {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        for (let step = 0; step < numRows_; step++) {
          response.d.elementos.forEach((e, i) => {
            Object.values(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        const dataSelect = rowsP.map((e) => {
          const data_ = {
            label: `${e[1]} - ${e[2]}`,
            value: e[0],
          };
          return data_;
        });
        setSelectDataDireccionModal(dataSelect);

        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetDataEstatus();
    GetDataDireccion();
    GetDataConcepto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openModalConsultar() {
    setModalIsOpen(true);
  }
  const [buttonNuevo, setButtonNuevo] = useState(false);

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const ConditionalRango = (e) => {
    if (e.value === "Fecha") {
      setRango('fecha')
      setFechaConditional(true);
      setQuincenaConditional(false);
    } else {
      setRango('quincena')
      setFechaConditional(false);
      setQuincenaConditional(true);
    }
  };

  useEffect(() => {
    if (modalIsOpen) {
      if (
        conceptoValueModal !== {} &&
        conceptoValueModal.value !== undefined &&
        conceptoValueModal.value !== ""
      ) {
        if (conceptoValueModal.horaInicio2 === "True") {
          setImporteMaxLabel('Horas a Pagar:')
          setImporteMaxText(`Para este concepto el máximo de horas que se puede pagar es de ${conceptoValueModal.importMax}`);
          setInputHora(true)
          setAuxHoraInicio(true);
          setHoraInicioValueModal(null);
        } else {
          setImporteValueModal(0);
          setAuxHoraInicio(false);
          setInputHora(false)
          setHoraInicioValueModal("00:00");
        }
        if (conceptoValueModal.mostrarImporte === "True") {
          if (conceptoValueModal.tipo === 'importe_de_unidades') {
            setImporteMaxLabel('Unidades a Pagar:')
            setInputImporte(true);
            setImporteValueModal("");
            setImporteMaxText(`Para este concepto el máximo de unidades que se puede pagar es de ${conceptoValueModal.importMax}`);
          } else {
            setImporteMaxLabel('Importe a Pagar:')
            setInputImporte(true);
            setImporteValueModal("");
            setImporteMaxText(`Para este concepto el importe máximo que se puede pagar es de $${conceptoValueModal.importMax} Pesos`);
          }
        } else {
          setImporteValueModal(0);
          setInputImporte(false);
        }
      } else {
        setInputImporte(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptoValueModal, modalIsOpen]);

  // const handleSecuencia = (value) => {
  //   if (value < 99) {
  //     setSecuenciaValueModal(value);
  //   }
  // }

  function handleHoraValue(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      let values = [...horaIniciaValues];
      values = value;
      setHoraIniciaValues(values);
    }
  }

  function handleMinutosValue(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      let values = [...minutosIniciaValues];
      values = value;
      setMinutosIniciaValues(values);
    }
  }

  const handleMateria = async (event) => {
    try {
      setMateria(event)
      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${campusId},id_person:${profesorValueModal.value},grupo:${grupoValueModal},ciclo:${cicloValueModal},materia:${event.value}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });

        setEscuelaValueModal(response.d.escuela);
        setCarreraValueModal(response.d.carrera);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  const HandleHabilitarEstatus = (id) => {
    setModalHabilitarEstatusIsOpen(true);
    setIdEstatusHabilitar(id)
    console.log(id)
  }

  const handleSubmitHabilitarEstatus = async () => {
    console.log(idEstatusHabilitar)
    let json = {
      id_pago: idEstatusHabilitar
    };
    try {
      const response = await postRequest("/pagos/cancelar-pago", json);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setModalHabilitarEstatusIsOpen(false)
      }
      else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleSortByName = async () => {
    setLoading(true)
    const sortedByName = dataOriginal.sort((a, b) => a[2] > b[2] ? 1 : b[2] > a[2] ? -1 : 0)
    setDataOriginal(sortedByName)
    await sleep(500)
    setLoading(false)
  }
  const handleSortByConcepto = async () => {
    setLoading(true)
    const sortedByName = dataOriginal.sort((a, b) => a[4] > b[4] ? 1 : b[4] > a[4] ? -1 : 0)
    setDataOriginal(sortedByName)
    await sleep(500)
    setLoading(false)
  }
  const handleSortByFecha = async () => {
    setLoading(true)
    const sortedByName = dataOriginal.sort((a, b) => a[12] > b[12] ? 1 : b[12] > a[12] ? -1 : 0)
    setDataOriginal(sortedByName)
    await sleep(500)
    setLoading(false)
  }

  useEffect(() => {
    if (profesorValueModal?.value !== undefined && cicloValueModal !== "" && grupoValueModal !== "" && direccionValueModal?.value !== undefined) {
      getDataEscuelaCarrera()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profesorValueModal, cicloValueModal, grupoValueModal, direccionValueModal])

  return (
    <>
      <Contenedor title="Pagos Extraordinarios">
        <Grid style={{ padding: 10, fontSize: '14px' }}>
          <Grid container spacing={2} style={{ padding: "10px 0px" }}>
            <Grid item xs={1}>
              <NewButton
                noIcon
                style={{ display: permisos['Pagos'].escribir === '1' ? 'block' : 'none' }}
                onClick={() => {
                  openModalConsultar();
                  setButtonNuevo(true);
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <label style={{ float: "right" }}>Estatus (*):</label>
            </Grid>
            <Grid item xs={2} style={{ padding: 2, zIndex: 90 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={selectDataEstatus}
                onChange={setStatusValue}
              />
            </Grid>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Año:</label>
            </Grid>
            <Grid item xs={2} style={{ padding: 2, zIndex: 99 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={listaYear}
                onChange={(e) => GetListQuincena(e)}
              />
            </Grid>
            <Grid xs={2} item>
              <label style={{ float: "right" }}>Concepto:</label>
            </Grid>
            <Grid xs={2} item style={{ padding: 2, zIndex: 80 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={selectDataConcepto}
                filterOption={conceptoFilteredOptions}
                onChange={setConceptoValue}
              />
            </Grid>
            <Grid item xs={1}>
              <NewButton
                //style={{ float: "right" }}
                customText="Buscar"
                noIcon
                onClick={() => GetDataTable()}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{ padding: "10px 0px" }}
          >
            <Grid xs={2} item>
              <label style={{ float: "right" }}>Rango(*):</label>
            </Grid>
            <Grid xs={2} item style={{ padding: 2, zIndex: 85 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={listRango}
                onChange={(e) => ConditionalRango(e)}
              />
            </Grid>
            {fechaConditional && (
              <>
                <Grid xs={1} item>
                  <label style={{ float: "right" }}>Fecha inicial(*):</label>
                </Grid>
                <Grid xs={2} item style={{ padding: 2 }}>
                  <input
                    className="filter-input"
                    name="fechaInicial"
                    type="date"
                    onChange={(e) => setFechaInicialValue(e.target.value)}
                  />
                </Grid>
                <Grid xs={2} item>
                  <label style={{ float: "right" }}>Fecha final(*):</label>
                </Grid>
                <Grid xs={2} item style={{ padding: 2 }}>
                  <input
                    className="filter-input"
                    name="fechaInicial"
                    type="date"
                    disabled={fechaInicialValue !== "" ? false : true}
                    min={fechaInicialValue}
                    onChange={(e) => setFechaFinalValue(e.target.value)}
                  />
                </Grid>
              </>
            )}

            {quincenaConditional && (
              <>
                <Grid xs={1} item style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <label style={{ float: "right" }}>Quincena inicial(*):</label>
                </Grid>
                <Grid xs={2} item style={{ padding: 2, zIndex: 10 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    isDisabled={!listQuincenaInicial.length > 0}
                    options={listQuincenaInicial}
                    onChange={setQuincenaInicioValue}
                  />
                </Grid>
                <Grid xs={2} item >
                  <label style={{ float: "right" }}>Quincena final(*):</label>
                </Grid>
                <Grid xs={2} item style={{ padding: 2, zIndex: 9 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    isDisabled={!listQuincenaFin.length > 0}
                    options={listQuincenaFin}
                    onChange={setQuincenaFinValue}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={2} style={{ padding: "10px 0px" }}>
            <Grid xs={2} item style={{ marginTop: 5 }}>
              <label style={{ float: "right" }}>Clave Profesor:</label>
            </Grid>
            <Grid xs={2} item style={{ padding: 2, zIndex: 79, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                onChange={setProfesorValue}
                isClearable={true}
              />
            </Grid>
            <Grid xs={1} item style={{ marginTop: 5 }}>
              <label style={{ float: "right" }}>Ordenar Por:</label>
            </Grid>
            <Grid item xs={5}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="nuevoFormularioLabel"
                >
                  <FormControlLabel
                    value="profesor"
                    control={<Radio />}
                    onClick={() => handleSortByName()}
                    label="Profesor"
                  />
                  <FormControlLabel
                    value="concepto"
                    control={<Radio />}
                    onClick={() => handleSortByConcepto()}
                    label="Concepto"
                  />
                  <FormControlLabel
                    value="por_fecha"
                    control={<Radio />}
                    onClick={() => handleSortByFecha()}
                    label="Por Fecha"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <div style={{ height: "calc(100% - 190px)", marginTop: "-1%" }} id="Pagos">
          <DataTable
            headers={headers}
            data={dataOriginal}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
        <NewModal
          title="Confirmación"
          open={modalHabilitarEstatusIsOpen}
          handleClose={() => setModalHabilitarEstatusIsOpen(false)}
          //loading={loadingModal}
          height={130}
          maxWidth={400}
          handleSubmit={handleSubmitHabilitarEstatus}
        >
          <div style={{ padding: "10px" }} className="nuevoUsuarioForm">
            <p style={{ fontSize: '30px', }}>¿Desea cancelar registro?</p>
          </div>
        </NewModal>
        <NewModal
          title="Registro Pagos Extraordinarios"
          open={modalIsOpen}
          handleClose={() => {
            clearModal();
            setModalIsOpen(false);
          }}
          loading={loadingModal}
          height={590}
          overflow={"scroll"}
          maxWidth={1300}
          handleSubmit={() => {
            handleSubmitPagos();
          }}
        >
          <div className="nuevoUsuarioForm PagosNuevoModal">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Clave Profesor:</label>
              </Grid>
              <Grid item xs={4} style={{ zIndex: 999 }}>
                <AsyncSelect
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  value={profesorValueModal}
                  onChange={setProfesorValueModal}
                />
              </Grid>
              <Grid item xs={2}>
                <label>Fecha Movimiento:</label>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="select-modal input-ciclo"
                  type="date"
                  value={fechaMovimientoValueModal}
                  onChange={(e) => setFechaMovimientoValueModal(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <label className={styles.inputLabel}>Concepto Pago:</label>
              </Grid>
              <Grid item xs={4} style={{ zIndex: 998 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  options={selectDataConcepto}
                  filterOption={conceptoFilteredOptions}
                  value={conceptoValueModal || ""}
                  onChange={setConceptoValueModal}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1} />
              {/* <Grid item xs={1}>
                <label>Secuencia:</label>
              </Grid>
              <Grid item xs={1} >
                <input
                  className="filter-input"
                  type="number"
                  min="0"
                  pattern="[0-9]*"
                  value={secuenciaValueModal}
                  onChange={(e) => handleSecuencia(e.target.value)}
                />
              </Grid> */}
              {auxHoraInicio ? (
                <Grid item xs={1}>
                  <label className={styles.inputLabel}>Hora Inicio:</label>
                </Grid>
              ) : (
                <Grid item xs={1} />
              )}
              {auxHoraInicio ? (
                <Grid item xs={3}>
                  {/* <input
                    type="time"
                    className="filter-input"
                    disabled={!auxHoraInicio}
                    value={horaInicioValueModal}
                    onChange={(e) => setHoraInicioValueModal(e.target.value)}
                  /> */}
                  <input
                    className="filter-input"
                    type="number"
                    style={{ width: "30%" }}
                    min="0"
                    max="23"
                    // placeholder="23" 
                    value={horaIniciaValues}
                    onChange={(e) => handleHoraValue(e.target.value)}
                  />
                  <input
                    className="filter-input"
                    style={{ marginLeft: '1%', width: '30%' }}
                    type="number"
                    min="0"
                    max="59"
                    // placeholder="00" 
                    value={minutosIniciaValues}
                    onChange={(e) => handleMinutosValue(e.target.value)}
                  />
                </Grid>
              ) : (
                <Grid item xs={2} />
              )}
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Ciclo:</label>
              </Grid>
              <Grid item xs={2}>
                <input
                  className="filter-input"
                  value={cicloValueModal}
                  onChange={(e) => setCicloValueModal(e.target.value)}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Dirección:</label>
              </Grid>
              <Grid item xs={5} style={{ zIndex: 997 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={direccionValueModal}
                  options={selectDataDireccionModal}
                  onChange={setDireccionValueModal}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Grupo:</label>
              </Grid>
              <Grid item xs={5}>
                <input
                  className="filter-input"
                  value={grupoValueModal}
                  onChange={(e) => setGrupoValueModal(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                {inputImporte || inputHora ? (
                  <label className={styles.inputLabel}>{importeMaxLabel}</label>
                ) : null}
              </Grid>
              <Grid item xs={3}>
                {inputImporte ? (
                  <input
                    className="filter-input"
                    type={"number"}
                    min={0}
                    value={importeValueModal}
                    onChange={(e) => setImporteValueModal(parseFloat(e.target.value) <= 0 ? 0 : e.target.value)}
                  />
                ) : null}
                {inputHora ? (
                  <input
                    className="filter-input"
                    type={"number"}
                    min={0}
                    value={importeValueModal}
                    onChange={(e) => setImporteValueModal(parseFloat(e.target.value) <= 0 ? 0 : e.target.value)}
                  />
                ) : null}
              </Grid>

              <Grid item xs={2}>
                <label className={styles.inputLabel}>Materia:</label>
              </Grid>
              <Grid item xs={5} style={{ zIndex: 996 }}>
                <Select
                  className='select-modal-container input-tipo'
                  classNamePrefix='select-modal'
                  options={materiaOptions}
                  styles={asyncSelectStyles}
                  isLoading={selectMateriaIsLoading}
                  onChange={(e) => handleMateria(e)}
                  value={materia}
                />
              </Grid>
              {inputImporte || inputHora ? null : (<Grid item xs={4} />)}
              {inputImporte || inputHora ? (
                <Grid item xs={5}>
                  <TextareaAutosize
                    className="filter-input"
                    disabled
                    maxRows={3}
                    aria-label="maximum height"
                    style={{
                      position: "absolute",
                      width: "34.5%",
                      height: 60,
                      resize: "none",
                      overflowY: "scroll",
                      marginLeft: "46px",
                    }}
                    value={importeMaxText}
                  />
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Escuela:</label>
              </Grid>
              <Grid item xs={5}>
                <input
                  disabled
                  className="filter-input"
                  value={escuelaValueModal}
                  onChange={(e) => setEscuelaValueModal(e.target.value)}
                />
              </Grid>
              <Grid item xs={5} />

              <Grid item xs={2}>
                <label className={styles.inputLabel}>Carrera:</label>
              </Grid>
              <Grid item xs={5}>
                <input
                  disabled
                  className="filter-input"
                  value={carreraValueModal}
                  onChange={(e) => setCarreraValueModal(e.target.value)}
                />
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={2}>
                <label className={styles.inputLabel}>Comentarios:</label>
              </Grid>
              <Grid item xs={10}>
                <TextareaAutosize
                  maxRows={7}
                  aria-label="maximum height"
                  style={{
                    width: "100%",
                    height: 40,
                    resize: "none",
                    overflowY: "scroll",
                  }}
                  value={comentarioValueModal}
                  onChange={(e) => setComentarioValueModal(e.target.value)}
                />
              </Grid>

              {!buttonNuevo && (
                <>
                  <Grid item xs={2}>
                    <label className={styles.inputLabel}>Creado:</label>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      className="select-modal input-ciclo"
                      type="date"
                      defaultValue="2022-11-24"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .notchedOutlinet": {
                          border: "none !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <input
                      value={"Torres, Francisco"}
                      disabled
                      className="select-modal input-ciclo"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}></Grid>
                  <Grid item xs={12} md={2}>
                    <label className={styles.inputLabel}>Modificado:</label>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      className="select-modal input-ciclo"
                      type="date"
                      defaultValue="2022-11-24"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .notchedOutlinet": {
                          border: "none !important",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <input
                      value={"Torres, Francisco"}
                      disabled
                      className="select-modal input-ciclo"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </NewModal>
      </Contenedor>
    </>
  );
};

export default Pagos;
