import { Grid } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";
import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import SearchInput from "../../Utils/Visuales/SearchInput";
import "./index.css";

const Incapacidades = () => {
  const headers = [
    {
      name: "Folio",
      col: 0,
    },
    {
      name: "Clave Profesor",
      col: 1,
    },
    {
      name: "Nombre Profesor",
      col: 2,
    },
    {
      name: "Fecha Inicio",
      col: 3,
    },
    {
      name: "Fecha Fin",
      col: 4,
    },
    {
      name: "Tipo",
      col: 5,
    },
    {
      name: "Estatus",
      col: 6,
    },
    {
      name: "Acciones",
      col: 7,
    },
  ];
  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [valueCiclo, setValueCiclo] = useState("");
  // const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [fechaInicio, setFechaInicio] = useState(moment().format("YYYY-MM-DD"));
  const [fechaFinal, setFechaFinal] = useState(moment().format("YYYY-MM-DD"));
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalComentario, setLoadingModalComentario] = useState(false);
  const [validSubmit, setValidSubmit] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalComentarioIsOpen, setModalComentarioIsOpen] = useState(false);
  const [dataModalConsultar, setDataModalConsultar] = useState("");
  const [dataModalIncapacidad, setDataModalIncapacidad] = useState("");
  const [valueCicloModal, setValueCicloModal] = useState("");
  const [valueComentariosModal, setValueComentariosModal] = useState("");
  const [profesorValue, setProfesorValue] = useState({
    label: "",
    value: "",
  });
  const [estatusIdActual, setEstatusIdActual] = useState("");
  const [IDactual, setIDactual] = useState("");
  const permisos = useSelector((state) => state.permisos);
  const { enqueueSnackbar } = useSnackbar();
  const dateTime = moment().format("DD/MM/YYYY HH:mm:ss");
  const openModalConsultar = async (id, estatus) => {
    try {
      setLoadingModal(true);
      setModalIsOpen(true);
      const consulta = `/incapacidad?id=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        response.d.estatus = {
          label: response.d.estatus,
          value: response.d.estatus,
        };
        setIDactual(id);
        setEstatusIdActual(estatus);
        setDataModalConsultar(response.d);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const openModalComentario = () => {
    setModalComentarioIsOpen(true);
  };

  const tiposDeIncapacidades = async (id) => {
    try {
      setLoadingModal(true);
      setModalIsOpen(true);
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        let maximumDays = response.d.elementos[3].maximo_dias;
        for (let i = 0; i < id.length; i++) {
          data.push({
            value: id[i],
            label: `${clave[i]} - ${nombre[i]}`,
            days: maximumDays[i],
          });
        }
        setDataModalIncapacidad(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const clearModal = () => {
    setProfesorValueModal("");
    setFechaNotificacionModal("");
    setFechaInicioModal("");
    setFechaFinModal("");
    setIncapacidadValueModal("");
    setDiasModal("");
    setValueComentariosModal("");
    setValueCicloModal("");
  };

  const [profesorValueModal, setProfesorValueModal] = useState("");
  // const [listaProfesores] = useState([
  //   {
  //     label: "36565 - Alejandro Díaz",
  //     value: "36565 - Alejandro Díaz",
  //   },
  //   {
  //     label: "8663 - María Hernandez",
  //     value: "8663 - María Hernandez",
  //   },
  //   {
  //     label: "8665 - Julio Hernadez",
  //     value: "8665 - Julio Hernadez",
  //   },
  //   {
  //     label: "9643 - Julia Ramirez",
  //     value: "9643 - Julia Ramirez",
  //   },
  // ]);

  const [incapacidadValueModal, setIncapacidadValueModal] = useState("");
  const [buttonNuevo, setButtonNuevo] = useState(false);
  const [fechaNotificacionModal, setFechaNotificacionModal] = useState("");
  const [fechaInicioModal, setFechaInicioModal] = useState("");
  const [fechaFinModal, setFechaFinModal] = useState("");
  const [diasModal, setDiasModal] = useState("");
  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true;
      else if (row[1].toString().toLowerCase().includes(text)) return true;
      else if (row[2].toString().toLowerCase().includes(text)) return true;
      else if (row[3].toString().toLowerCase().includes(text)) return true;
      else if (row[4].toString().toLowerCase().includes(text)) return true;
      else if (row[5].toString().toLowerCase().includes(text)) return true;
      else if (row[6].toString().toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }
  const seekIncapacidad = async () => {
    if (fechaInicio > fechaFinal) {
      enqueueSnackbar("Fecha inicio debe ser menor que fecha fin", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    
    //const clave = profesorValue.value;
    const fecha_inicio = moment(fechaInicio).format("YYYY-MM-DD");
    const fecha_final = moment(fechaFinal).format("YYYY-MM-DD");
    let consulta = `/incapacidad?fi=${fecha_inicio}&ff=${fecha_final}`;
    try{
      setLoading(true);
      if (profesorValue) consulta += `&id_profesor=${profesorValue.value}`;
      let response = await getRequest(consulta);
      if (response.s === "OK") {
        let data = [];
        response.d.forEach((incapacidad) => {
          data.push([
            incapacidad.id,
            incapacidad.clave_profesor,
            incapacidad.nombre_profesor,
            incapacidad.fecha_inicio_table,
            incapacidad.fecha_fin_table,
            incapacidad.tipo,
            incapacidad.estatus,
            <div I="edit" a="incapacidades">
              <EditButton
                onClick={() => {
                  setButtonNuevo(false);
                  openModalConsultar(incapacidad.id, incapacidad.estatus);
                }}
              >
                Consultar
              </EditButton>
            </div>,
          ]);
        });
        setDataOriginal(data);
        setDataFinal(data);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setDataOriginal([]);
        setDataFinal([]);
        setLoading(false);
      }
    }catch{
      setLoading(false);
      enqueueSnackbar('ERROR', {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setDataOriginal([]);
      setDataFinal([]);
    }
    
    
  };

  useEffect(() => {
    const aux1 = moment(fechaFinModal);
    const aux2 = moment(fechaInicioModal);

    const totalDays = aux1.diff(aux2, "days") + 1;

    if (!isNaN(aux1) && !isNaN(aux2)) setDiasModal(totalDays);
    if (totalDays > incapacidadValueModal.days) {
      setValidSubmit(false);

      document
        .getElementsByClassName("maximum-days")[0]
        .classList.add("error-input");

      enqueueSnackbar(
        "El rango de fechas supera el máximo de días permitido.",
        {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    }

    if (totalDays < +incapacidadValueModal.days) {
      setValidSubmit(true);
    }
  }, [fechaInicioModal, fechaFinModal, validSubmit]);

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const handleSubmitIncapacidades = async () => {
    if (diasModal > incapacidadValueModal.days) {
      enqueueSnackbar(
        "El rango de fechas supera el máximo de días permitido.",
        {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      return;
    }

    let valido = true;
    if (profesorValueModal === "") {
      document
        .getElementsByClassName("value-profesor")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-profesor")[0]
        .classList.remove("error-input");
    }
    if (valueCicloModal === "") {
      document
        .getElementsByClassName("value-folio")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-folio")[0]
        .classList.remove("error-input");
    }
    if (incapacidadValueModal === "") {
      document
        .getElementsByClassName("value-tipo")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-tipo")[0]
        .classList.remove("error-input");
    }
    if (fechaNotificacionModal === "") {
      document
        .getElementsByClassName("value-notificacion")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-notificacion")[0]
        .classList.remove("error-input");
    }
    if (fechaInicioModal === "") {
      document
        .getElementsByClassName("value-inicio")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-inicio")[0]
        .classList.remove("error-input");
    }
    if (fechaFinModal === "") {
      document
        .getElementsByClassName("value-fin")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-fin")[0]
        .classList.remove("error-input");
    }
    if (valueComentariosModal === "") {
      document
        .getElementsByClassName("value-comentario")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("value-comentario")[0]
        .classList.remove("error-input");
    }
    if (valido) {
      try {
        setLoadingModal(true);
        const consulta = "/incapacidad";
        const json = {
          id_profesor: profesorValueModal.value,
          folio: valueCicloModal,
          id_tipo_incapacidad: incapacidadValueModal.value,
          fecha_notificacion: fechaNotificacionModal,
          fecha_inicio: fechaInicioModal,
          fecha_fin: fechaFinModal,
          dias: diasModal,
          comentarios: valueComentariosModal,
        };
        const response = await postRequest(consulta, json);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          clearModal();
          setModalIsOpen(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingModal(false);
      }
    }
  };

  const handleDeshabilitar = async () => {
    try {
      setLoadingModalComentario(true);
      const json = {
        id: IDactual,
      };
      const response = await postRequest("/incapacidad/update", json);
      if (response.s === "OK") {
        setLoadingModalComentario(false);
        setModalComentarioIsOpen(false);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        clearModal();
        setModalIsOpen(false);
      } else {
        setLoadingModalComentario(false);
        setModalComentarioIsOpen(false);
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setLoadingModalComentario(false);
      setModalComentarioIsOpen(false);
      console.log(error);
    } finally {
    }
  };

  return (
    <>
      <Contenedor title="Incapacidades">
        <div
          className="nuevoUsuarioForm Incapacidades"
          style={{ paddingTop: 0 }}
        >
          <Grid
            container
            spacing={0}
            width="100%"
            alignItems="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid item sm={12} md="auto">
              <label>Fecha Inicio</label>
            </Grid>
            <Grid item sm={12} md="auto" text="end">
              <TextField
                className="select-modal input-fechaInicio"
                type="date"
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .notchedOutlinet": {
                    // MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
                    border: "none !important",
                  },
                }}
              />
            </Grid>
            <Grid item sm={12} md="auto">
              <label>Fecha Fin</label>
            </Grid>
            <Grid item sm={12} md="auto">
              <TextField
                className="select-modal input-fechaFinal"
                type="date"
                value={fechaFinal}
                onChange={(e) => setFechaFinal(e.target.value)}
                disabled={fechaInicio !== "" ? false : true}
                InputProps={{
                  inputProps: { min: fechaInicio, shrink: true },
                }}
              />
            </Grid>
            <Grid item md="auto">
              <label>Clave Profesor</label>
            </Grid>
            <Grid item sm={12} md={3}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                onChange={setProfesorValue}
                placeholder="Buscar Profesor"
                isClearable={true}
              />
            </Grid>
          </Grid>
        </div>
        <div className="nuevoUsuarioForm Incapacidades">
          <Grid
            container
            spacing={0}
            width="100%"
            style={{ marginTop: "-2.5%" }}
          >
            <Grid item xs={12} md={12} style={{ textAlign: "end" }}>
              <NewButton
                customText="Buscar"
                noIcon
                style={{
                  padding: "5px 15px",
                  marginTop: "6px",
                  marginBottom: "6px",
                }}
                onClick={(e) => {
                  seekIncapacidad();
                }}
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <NewButton
                noIcon
                style={{
                  padding: "5px 15px",
                  marginTop: "6px",
                  marginBottom: "6px",
                  display: permisos['Incapacidades'].escribir === '1' ? 'block' : 'none'
                }}
                onClick={() => {
                  tiposDeIncapacidades(15);
                  setButtonNuevo(true);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SearchInput hideIcon search={search} />
            </Grid>
          </Grid>
        </div>
        <div style={{ height: "calc(100% - 110px)" }}>
          <DataTable
            headers={headers}
            data={dataFinal}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7]}
            paginate
            bordes
          />
        </div>

        <NewModal
          title={`${
            buttonNuevo ? "Nueva Incapacidad" : "Consultar Incapacidad"
          }`}
          open={modalIsOpen}
          handleClose={() => {
            clearModal();
            setModalIsOpen(false);
          }}
          loading={loadingModal}
          height={400}
          maxWidth={1140}
          handleSubmit={
            buttonNuevo
              ? () => handleSubmitIncapacidades()
              : () => {
                  setModalIsOpen(false);
                  setButtonNuevo(false);
                }
          }
        >
          <div className="nuevoUsuarioForm">
            <Grid container style={{ marginLeft: "-3%" }}>
              <Grid
                container
                item
                spacing={1}
                alignItems="center"
                style={{ marginTop: "0.5%" }}
              >
                <Grid item xs={12} md={2} style={{ textAlign: "end" }}>
                  <label>Clave Profesor:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  {buttonNuevo ? (
                    // <Select
                    //   className="select-modal-container"
                    //   classNamePrefix="select-modal"
                    //   value={profesorValueModal}
                    //   options={listaProfesores}
                    //   onChange={setProfesorValueModal}
                    // />
                    <AsyncSelect
                      className="select-modal-container value-profesor"
                      classNamePrefix="select-modal"
                      styles={asyncSelectStyles}
                      loadOptions={promiseOptions}
                      onChange={setProfesorValueModal}
                      placeholder="Buscar Profesor"
                      value={profesorValueModal}
                    />
                  ) : (
                    <input
                      disabled
                      className="filter-input"
                      value={`${dataModalConsultar.profesor}`}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ textAlign: "end", marginLeft: "-2.5%" }}
                >
                  <label>Folio Médico:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  <input
                    className="filter-input value-folio"
                    value={`${
                      buttonNuevo
                        ? valueCicloModal
                        : dataModalConsultar.folio_medico
                    }`}
                    onChange={(e) => setValueCicloModal(e.target.value)}
                    disabled={!buttonNuevo}
                    style={{ fontSize: "16px" }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={1}
                alignItems="center"
                style={{ marginTop: "1%" }}
              >
                <Grid item xs={12} md={2} style={{ textAlign: "end" }}>
                  <label>Tipo de Incapacidad:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  {buttonNuevo ? (
                    <Select
                      className="select-modal-container value-tipo"
                      classNamePrefix="select-modal"
                      value={incapacidadValueModal}
                      options={dataModalIncapacidad}
                      onChange={setIncapacidadValueModal}
                    />
                  ) : (
                    <input
                      disabled
                      className="filter-input"
                      value={`${dataModalConsultar.tipo_incapacidad}`}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ textAlign: "end", marginLeft: "-2.5%" }}
                >
                  <label>Fecha de Notificación:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    className="filter-input value-notificacion"
                    type="date"
                    value={`${
                      buttonNuevo
                        ? fechaNotificacionModal
                        : dataModalConsultar.fecha_notificacion
                    }`}
                    onChange={(e) => setFechaNotificacionModal(e.target.value)}
                    disabled={!buttonNuevo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={1}
                alignItems="center"
                style={{ marginTop: "1%" }}
              >
                <Grid item xs={12} md={2} style={{ textAlign: "end" }}>
                  <label>Fecha Inicio:78</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  {/* <TextField
                    className="filter-input value-inicio"
                    type="date"
                    value={`${
                      buttonNuevo
                        ? fechaInicioModal
                        : dataModalConsultar.fecha_inicio
                    }`}
                    onChange={(e) => setFechaInicioModal(e.target.value)}
                    disabled={incapacidadValueModal ? false : true}
                    InputLabelProps={{ 
                      // min: formatDate(new Date()) + "T00:00",
                      // //max: dateTime,
                      max:moment().format("YYYY-MM-DD"),
                      shrink: true,
                    }}
                  /> */}
                  <input
                  className="filter-input value-inicio"
                    type="date"
                    value={`${
                      buttonNuevo
                        ? fechaInicioModal
                        : dataModalConsultar.fecha_inicio
                    }`}
                    onChange={(e) => setFechaInicioModal(e.target.value)}
                    disabled={incapacidadValueModal ? false : true}
                  min={moment().format("YYYY-MM-DD")}
                />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ textAlign: "end", marginLeft: "-2.5%" }}
                >
                  <label>Fecha Fin:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    className="filter-input value-fin"
                    type="date"
                    value={`${
                      buttonNuevo ? fechaFinModal : dataModalConsultar.fecha_fin
                    }`}
                    onChange={(e) => setFechaFinModal(e.target.value)}
                    disabled={
                      incapacidadValueModal && fechaInicioModal !== ""
                        ? false
                        : true
                    }
                    InputProps={{
                      inputProps: { min: fechaInicioModal, shrink: true },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{ textAlign: "end", marginLeft: "-3.3%" }}
                >
                  <label>Días:</label>
                </Grid>
                <Grid item xs={12} md={1}>
                  <input
                    className="filter-input maximum-days"
                    defaultValue={""}
                    value={`${
                      buttonNuevo ? diasModal : dataModalConsultar.dias
                    }`}
                    onChange={(e) => setDiasModal(e.target.value)}
                    disabled
                    style={{ fontSize: "16px" }}
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={2} style={{ marginTop: "1%" }}>
                <Grid item xs={12} md={2} style={{ textAlign: "end" }}>
                  <label>Comentarios:</label>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextareaAutosize
                    className="filter-input value-comentario"
                    maxRows={7}
                    disabled={!buttonNuevo}
                    value={`${
                      buttonNuevo
                        ? valueComentariosModal
                        : dataModalConsultar.comentario
                    }`}
                    onChange={(e) => setValueComentariosModal(e.target.value)}
                    style={{
                      width: "103.5%",
                      height: 100,
                      resize: "none",
                      overflowY: "scroll",
                      fontSize: "18px",
                    }}
                  />
                </Grid>
              </Grid>
              {buttonNuevo ? null : estatusIdActual !== "Finalizada" &&
                estatusIdActual !== "Deshabilitada" ? (
                <Grid
                  item
                  xs={12}
                  sm={1}
                  style={{ marginTop: "1%", marginLeft: "5%" }}
                >
                  <NewButton
                    customText="Deshabilitar"
                    noIcon
                    style={{ padding: "5px 15px" }}
                    onClick={openModalComentario}
                  />
                </Grid>
              ) : null}
            </Grid>
          </div>
        </NewModal>
        <NewModal
          title={"Confirmación"}
          open={modalComentarioIsOpen}
          handleClose={() => {
            setModalComentarioIsOpen(false);
          }}
          handleSubmit={handleDeshabilitar}
          loading={loadingModalComentario}
          style
          height={100}
          maxWidth={600}
          marginTop={"8%"}
        >
          <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={12} md={5} style={{ textAlign: "center" }}>
                <label>¿Desea Finalizar la Incapacidad?</label>
              </Grid>
            </Grid>
          </Grid>
        </NewModal>
      </Contenedor>
    </>
  );
};

export default Incapacidades;
