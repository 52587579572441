import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import "./index.css";
import Select from "react-select";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

function ReporteDetalleProfesor() {
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const { enqueueSnackbar } = useSnackbar();
  const [valueCiclo, setValueCiclo] = useState("");
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [req, setReq] = useState(false);
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueCampus, setValueCampus] = useState({
    label: "",
    value: "",
  });
  const [valueEscuela, setValueEscuela] = useState({
    label: "",
    value: "",
  });
  const [valueDireccion, setValueDireccion] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const heading = [
    "ciclo",
    "campus",
    "escuela",
    "direccion",
    "director",
    "clave_profesor",
    "nombre_profesor",
    "grupo",
    "materia",
    "prog_h",
    "faltas_brutas_h",
    "porc_F_Brutas",
    "aut",
    "rep_h",
    "faltas_netas_h",
    "prog_s",
    "faltas_brutas_s",
    "ut",
    "rep_s",
    "faltas_netas_s",
    "sust",
    "adic",
    "s_ant",
    "ret_s",
    "ret_m",
    "Can",
  ];

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  const GetDataTable = async () => {
    let valido = true;
    if (valueCiclo === "") {
      enqueueSnackbar("Falta campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela.value === "") {
      enqueueSnackbar("Falta campo Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus.value === "") {
      enqueueSnackbar("Falta campo Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      let consulta = `/reportes/indicadores_globales_detalles_profesor?ciclo=${valueCiclo}&id_campus=${valueCampus.value}&id_escuela=${valueEscuela.value}`;
      if (valueDireccion) consulta += `&id_direccion=${valueDireccion.value}`;
      const response = await getRequest(consulta);
      let rowsP = "";

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });

        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.map((e, i) => {
          const objp = Object.values(response.d[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        //console.log(numRows_)
        for (let step = 0; step < numRows_; step++) {
          response.d.map((e, i) => {
            Object.values(response.d[i]).map((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        setData(rowsP);
        setDataOriginal(rowsP);

        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setData([]);
        setDataOriginal([]);
        setLoading(false);
      }
    }
  };

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 3,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Director",
      id: "director",
      col: 4,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave profesor",
      id: "clave_profesor",
      col: 5,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre profesor",
      id: "nombre_profesor",
      col: 6,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 6,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Materia",
      id: "materia",
      col: 7,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Prog",
      id: "prog_h",
      col: 8,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas brutas",
      id: "faltas_brutas_h",
      col: 9,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "%F.Brutas",
      id: "porc_F_Brutas",
      col: 10,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Aut",
      id: "aut",
      col: 11,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Rep",
      id: "rep_h",
      col: 12,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Netas",
      id: "faltas_netas_h",
      col: 13,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Prog",
      id: "prog_s",
      col: 14,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Brutas",
      id: "faltas_brutas_s",
      col: 15,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Aut",
      id: "ut",
      col: 16,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Rep",
      id: "rep_s",
      col: 17,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Netas",
      id: "faltas_netas_s",
      col: 18,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Sust",
      id: "sust",
      col: 19,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Adic",
      id: "adic",
      col: 20,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "S.Ant",
      id: "s_ant",
      col: 21,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Ret",
      id: "ret_s",
      col: 22,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Ret",
      id: "ret_m",
      col: 23,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Can",
      id: "Can",
      col: 24,
      width: "10%",
      align: "center",
      fn: null,
    },
  ];

  const DownloadPDF = async () => {
    setReq(true);
    const totalTableInfo = {
      prog_h: 0,
      faltas_brutas_h: 0,
      porc_F_Brutas: 0,
      aut: 0,
      rep_h: 0,
      faltas_netas_h: 0,
      por_f_netas_h: 0,
      visit_h: 0,
      prog_s: 0,
      faltas_brutas_s: 0,
      ut: 0,
      rep_s: 0,
      faltas_netas_s: 0,
      sust: 0,
      adic: 0,
      visit_s: 0,
      s_ant: 0,
      ret_s: 0,
      ret_m: 0,
      Can: 0,
    };
    const data_ = data.map((e, i) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        director: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        grupo: e[7],
        materia: e[8],
        prog_h: e[9],
        faltas_brutas_h: e[10],
        porc_F_Brutas: e[11],
        aut: e[12],
        rep_h: e[13],
        faltas_netas_h: e[14],
        prog_s: e[15],
        faltas_brutas_s: e[16],
        ut: e[17],
        rep_s: e[18],
        faltas_netas_s: e[19],
        sust: e[20],
        adic: e[21],
        s_ant: e[22],
        ret_s: e[23],
        ret_m: e[24],
        Can: e[25],
      };
      totalTableInfo.prog_h = totalTableInfo.prog_h + parseFloat(data[i][9]);
      totalTableInfo.faltas_brutas_h =
        totalTableInfo.faltas_brutas_h + parseFloat(data[i][10]);
      totalTableInfo.porc_F_Brutas =
        totalTableInfo.porc_F_Brutas + parseFloat(data[i][11]);
      totalTableInfo.aut = totalTableInfo.aut + parseFloat(data[i][12]);
      totalTableInfo.rep_h = totalTableInfo.rep_h + parseFloat(data[i][13]);
      totalTableInfo.faltas_netas_h =
        totalTableInfo.faltas_netas_h + parseFloat(data[i][14]);
      totalTableInfo.prog_s = totalTableInfo.prog_s + parseFloat(data[i][15]);
      totalTableInfo.faltas_brutas_s =
        totalTableInfo.faltas_brutas_s + parseFloat(data[i][16]);
      totalTableInfo.ut = totalTableInfo.ut + parseFloat(data[i][17]);
      totalTableInfo.rep_s = totalTableInfo.rep_s + parseFloat(data[i][18]);
      totalTableInfo.faltas_netas_s =
        totalTableInfo.faltas_netas_s + parseFloat(data[i][19]);
      totalTableInfo.sust = totalTableInfo.sust + parseFloat(data[i][20]);
      totalTableInfo.adic = totalTableInfo.adic + parseFloat(data[i][21]);
      totalTableInfo.s_ant = totalTableInfo.s_ant + parseFloat(data[i][22]);
      totalTableInfo.ret_s = totalTableInfo.ret_s + parseFloat(data[i][23]);
      totalTableInfo.ret_m = totalTableInfo.ret_m + parseFloat(data[i][24]);
      totalTableInfo.Can = totalTableInfo.Can + parseFloat(data[i][25]);
      return data2;
    });
    data_.push({
      materia: "Totales",
      prog_h: parseFloat(totalTableInfo.prog_h).toFixed(0),
      faltas_brutas_h: parseFloat(totalTableInfo.faltas_brutas_h).toFixed(0),
      porc_F_Brutas: parseFloat(totalTableInfo.porc_F_Brutas).toFixed(2),
      aut: parseFloat(totalTableInfo.aut).toFixed(0),
      rep_h: parseFloat(totalTableInfo.rep_h).toFixed(0),
      faltas_netas_h: parseFloat(totalTableInfo.faltas_netas_h).toFixed(0),
      prog_s: parseFloat(totalTableInfo.prog_s).toFixed(0),
      faltas_brutas_s: parseFloat(totalTableInfo.faltas_brutas_s).toFixed(0),
      ut: parseFloat(totalTableInfo.ut).toFixed(0),
      rep_s: parseFloat(totalTableInfo.rep_s).toFixed(0),
      faltas_netas_s: parseFloat(totalTableInfo.faltas_netas_s).toFixed(0),
      sust: parseFloat(totalTableInfo.sust).toFixed(0),
      adic: parseFloat(totalTableInfo.adic).toFixed(0),
      s_ant: parseFloat(totalTableInfo.s_ant).toFixed(0),
      ret_s: parseFloat(totalTableInfo.ret_s).toFixed(0),
      ret_m: parseFloat(totalTableInfo.ret_m).toFixed(0),
      Can: parseFloat(totalTableInfo.Can).toFixed(0),
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const newHeaders = headers.filter(
      (element) =>
        !["Ciclo", "Campus", "Escuela", "Dirección", "Director"].includes(
          element.name
        )
    );
    const doc = (
      <DocumentPDFTable
        metadata={newHeaders ?? []}
        data={data_ ?? []}
        title={`Reportes Indicadores Globales – Detalles por Profesor`}
        campus={valueCampus.label}
        rowstableColSpan={rowstableColSpanPDF}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      `Reporte_Indicadores_Globales_Detalles_Profesor_${fecha}_${hora}.pdf`
    );
    setReq(false);
  };

  const DownloadExcel = () => {
    const totalTableInfo = {
      prog_h: 0,
      faltas_brutas_h: 0,
      porc_F_Brutas_h: 0,
      aut_h: 0,
      rep_h: 0,
      faltas_netas_h: 0,
      prog_s: 0,
      faltas_brutas_s: 0,
      aut_s: 0,
      rep_s: 0,
      faltas_netas_s: 0,
      sust_s: 0,
      adic_s: 0,
      s_ant: 0,
      ret_s: 0,
      ret_m: 0,
      Can_m: 0,
    };
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        director: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        grupo: e[7],
        materia: e[8],
        prog_h: e[9],
        faltas_brutas_h: e[10],
        porc_F_Brutas_h: e[11],
        aut_h: e[12],
        rep_h: e[13],
        faltas_netas_h: e[14],
        prog_s: e[15],
        faltas_brutas_s: e[16],
        aut_s: e[17],
        rep_s: e[18],
        faltas_netas_s: e[19],
        sust_s: e[20],
        adic_s: e[21],
        "S.Ant_s": e[22],
        ret_s: e[23],
        ret_m: e[24],
        Can_m: e[25],
      };
      totalTableInfo.prog_h = totalTableInfo.prog_h + parseFloat(e[9]);
      totalTableInfo.faltas_brutas_h =
        totalTableInfo.faltas_brutas_h + parseFloat(e[10]);
      totalTableInfo.porc_F_Brutas_h =
        totalTableInfo.porc_F_Brutas_h + parseFloat(e[11]);
      totalTableInfo.aut_h = totalTableInfo.aut_h + parseFloat(e[12]);
      totalTableInfo.rep_h = totalTableInfo.rep_h + parseFloat(e[13]);
      totalTableInfo.faltas_netas_h =
        totalTableInfo.faltas_netas_h + parseFloat(e[14]);
      totalTableInfo.prog_s = totalTableInfo.prog_s + parseFloat(e[15]);
      totalTableInfo.faltas_brutas_s =
        totalTableInfo.faltas_brutas_s + parseFloat(e[16]);
      totalTableInfo.aut_s = totalTableInfo.aut_s + parseFloat(e[17]);
      totalTableInfo.rep_s = totalTableInfo.rep_s + parseFloat(e[18]);
      totalTableInfo.faltas_netas_s =
        totalTableInfo.faltas_netas_s + parseFloat(e[19]);
      totalTableInfo.sust_s = totalTableInfo.sust_s + parseFloat(e[20]);
      totalTableInfo.adic_s = totalTableInfo.adic_s + parseFloat(e[21]);
      totalTableInfo.s_ant = totalTableInfo.s_ant + parseFloat(e[22]);
      totalTableInfo.ret_s = totalTableInfo.ret_s + parseFloat(e[23]);
      totalTableInfo.ret_m = totalTableInfo.ret_m + parseFloat(e[24]);
      totalTableInfo.Can_m = totalTableInfo.Can_m + parseFloat(e[25]);
      return data2;
    });
    data_.push({
      ciclo: "",
      campus: "",
      escuela: "",
      direccion: "",
      director: "",
      clave_profesor: "",
      nombre_profesor: "",
      grupo: "",
      materia: "Totales",
      prog_h: parseFloat(parseFloat(totalTableInfo.prog_h).toFixed(0)),
      faltas_brutas_h: parseFloat(
        parseFloat(totalTableInfo.faltas_brutas_h).toFixed(0)
      ),
      porc_F_Brutas_h: parseFloat(
        parseFloat(totalTableInfo.porc_F_Brutas_h).toFixed(2)
      ),
      aut_h: parseFloat(parseFloat(totalTableInfo.aut_h).toFixed(0)),
      rep_h: parseFloat(parseFloat(totalTableInfo.rep_h).toFixed(0)),
      faltas_netas_h: parseFloat(
        parseFloat(totalTableInfo.faltas_netas_h).toFixed(0)
      ),
      prog_s: parseFloat(parseFloat(totalTableInfo.prog_s).toFixed(0)),
      faltas_brutas_s: parseFloat(
        parseFloat(totalTableInfo.faltas_brutas_s).toFixed(0)
      ),
      aut_s: parseFloat(parseFloat(totalTableInfo.aut_s).toFixed(0)),
      rep_s: parseFloat(parseFloat(totalTableInfo.rep_s).toFixed(0)),
      faltas_netas_s: parseFloat(
        parseFloat(totalTableInfo.faltas_netas_s).toFixed(0)
      ),
      sust_s: parseFloat(parseFloat(totalTableInfo.sust_s).toFixed(0)),
      adic_s: parseFloat(parseFloat(totalTableInfo.adic_s).toFixed(0)),
      "S.Ant_s": parseFloat(parseFloat(totalTableInfo.s_ant).toFixed(0)),
      ret_s: parseFloat(parseFloat(totalTableInfo.ret_s).toFixed(0)),
      ret_m: parseFloat(parseFloat(totalTableInfo.ret_m).toFixed(0)),
      Can_m: parseFloat(parseFloat(totalTableInfo.Can_m).toFixed(0)),
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(
      data_,
      `Reporte_Indicadores_Globales_Detalles_Profesor_${fecha}_${hora}`
    );
    setReq(false);
  };

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const rowstableColSpan = [
    {
      name: "",
      col: 9,
    },
    {
      name: "Horas",
      col: 6,
    },
    {
      name: "Sesiones",
      col: 9,
    },
    {
      name: "Minutos",
      col: 2,
    },
  ];
  const rowstableColSpanPDF = [
    {
      name: "",
      width: 204,
      align: "center",
    },
    {
      name: "Horas",
      width: 203,
      align: "center",
    },
    {
      name: "Sesiones",
      width: 306,
      align: "center",
    },
    {
      name: "Minutos",
      width: 68,
      align: "center",
    },
  ];
  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Indicadores Globales – Detalles por Profesor">
        <Grid container>
          <Grid item xs={9}>
            <Grid
              container
              style={{ padding: "10px 0px" }}
              className="nuevoUsuarioForm"
            >
              <Grid item sm={2} container>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid sm={4} item>
                <input
                  className="filter-input"
                  //style={{ width: "170px" }}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid item sm={2} container justifyContent="flex-end">
                <label>Campus(*):</label>
              </Grid>
              <Grid sm={4} item style={{ zIndex: 9 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusValores}
                  onChange={setValueCampus}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ padding: "10px 0px" }}
              className="nuevoUsuarioForm"
            >
              <Grid item sm={2} container>
                <label style={{ margin: "2px 5px" }}>Escuela(*):</label>
              </Grid>
              <Grid sm={4} item style={{ zIndex: 9 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueEscuela}
                  options={optionsEscuela}
                  onChange={setValueEscuela}
                />
              </Grid>
              <Grid item sm={2} container justifyContent="flex-end">
                <label>Dirección:</label>
              </Grid>
              <Grid sm={4} item style={{ zIndex: 8 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container style={{ padding: "20px 10px" }}>
              <Grid justifyContent={"center"} container>
                <EditButton
                  onClick={() => GetDataTable()}
                  disabled={loading}
                  style={{ padding: "10px 25px" }}
                >
                  Generar
                </EditButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadPDF}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(98% - 200px)", marginTop: "40px" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
            rowstableColSpan={rowstableColSpan}
          />
        </div>
      </Contenedor>
    </>
  );
}

export default ReporteDetalleProfesor;
