import { View, Text } from '@react-pdf/renderer'
import HeaderPDFDefault from '../HeaderPDFDefault'
import ReportSpecialClassPDF from '../ReportSpecialClassPDF'
import SpecialClassTotalBox from '../SpecialClassTotalBox'

export default function SpecialClassPDF({
    metadata,
    data,
    title,
    startDate,
    endDate,
    ciclo,
    escuela,
    direccion,
    teacherKey,
    totalBox
}) {
    return (
        <View>
            <ReportSpecialClassPDF
                metadata={metadata}
                data={data}
                schools={data.schools}
                startDate={startDate}
                endDate={endDate}
                title={title}
                ciclo={ciclo}
                escuela={escuela}
                direccion={direccion}
                teacherKey={teacherKey}
                totalBox={totalBox}
            />
            <View break={true}>
                <HeaderPDFDefault title={title} />
                <View style={{ flexDirection: 'row', width: '100%', marginTop: 10, justifyContent: "flex-end" }}>
                    <Text style={{ fontSize: 10, }}>Total de clases Por escuela: {data.length}</Text>
                </View>
                <View style={{ marginTop: 10 }}>
                    <SpecialClassTotalBox
                        title_1={"Normal"}
                        title_2={"Reposición"}
                        title_3={"Adicional"}
                        title_4={"Reposición Adelantada"}
                        title_5={"Sustitución"}
                        title_6={"Visita de Campo"}
                        value_1={totalBox["Normal"]}
                        value_2={totalBox["Reposición"]}
                        value_3={totalBox["Adicional"]}
                        value_4={totalBox["Reposición Adelantada"]}
                        value_5={totalBox["Sustitución"]}
                        value_6={totalBox["Visita de Campo"]}
                    />
                    <SpecialClassTotalBox
                        title_1={'Visita "Antropologica"'}
                        title_2={"Reposición por Incapacidad"}
                        title_3={"Sustitución por Incapacidad"}
                        title_4={"Incapacidad"}
                        title_5={"Festivo"}
                        title_6={"Revision"}
                        value_1={totalBox['Visita "Antropologica"']}
                        value_2={totalBox["Reposición por Incapacidad"]}
                        value_3={totalBox["Sustitución por Incapacidad"]}
                        value_4={totalBox["Incapacidad"]}
                        value_5={totalBox["Festivo"]}
                        value_6={totalBox["Revision"]}
                    />
                    <SpecialClassTotalBox
                        title_1={'Asesoria CADE'}
                        title_2={"Paternidad"}
                        title_3={"Sustitución por Incapacidad"}
                        title_4={"Sustitución por Paternidad"}
                        title_5={"-"}
                        title_6={"-"}
                        value_1={totalBox["Asesoria CADE"]}
                        value_2={totalBox["Paternidad"]}
                        value_3={totalBox["Sustitución por Incapacidad"]}
                        value_4={totalBox["Sustitución por Paternidad"]}
                        value_5={""}
                        value_6={""}
                    />
                </View>
            </View>
            <View
                fixed
                style={{
                    height: 50,
                    position: 'absolute',
                    bottom: 30,
                    left: 0,
                    right: 0,
                    top: 520
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <View>
                        <Text
                            style={{
                                color: 'gray',
                                fontSize: 11,
                            }}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}