import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import NewModal from "../../../Utils/Visuales/NewModal";
import { getRequest, postRequest } from "../../../Utils/Funciones/requester";
import styles from "../styles.module.css";
import moment from "moment";

let grupoTimer;
function SustitucionIncapacidad(props) {
  const [loading, setLoading] = useState(false);
  const campusId = useSelector((state) => state.campusId);
  const [profesor, setProfesor] = useState({
    label: "",
    value: "",
  });
  const [grupo, setGrupo] = useState("");
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [materia, setMateria] = useState({
    label: "",
    value: "",
  });
  const [optionsFechaProfesor, setOptionsFechaProfesor] = useState([]);
  const [fechaProfesor, setFechaProfesor] = useState({
    label: "",
    value: "",
  });
  const [profesorReposicion, setProfesorReposicion] = useState({
    label: "",
    value: "",
  });
  const [horaInicia, setHoraInicia] = useState("");
  const [horaTermina, setHoraTermina] = useState("");
  const [tema, setTema] = useState("");
  const [horasIniciales, setHorasIniciales] = useState(["06:00"]);
  const [horasFinales, setHorasFinales] = useState(["23:00"]);
  const [selectLoading, setSelectLoading] = useState(false);

  useEffect(() => {
    setProfesor({
      label: "",
      value: "",
    });
    setGrupo("");
    setMateria({
      label: "",
      value: "",
    });
    setMateriaOptions([]);
    setFechaProfesor({
      label: "",
      value: "",
    });
    setProfesorReposicion({
      label: "",
      value: "",
    });
    setTema("");
    if (props.open === true) {
      setOptionsFechaProfesor([]);
      setHoraInicia("");
      setHoraTermina("");
    }
  }, [props.open]);

  useEffect(() => {
    clearTimeout(grupoTimer);
    if (grupo.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(
          campusId,
          profesor.value,
          grupo,
          props.ciclo,
          props.direccion.value
        );
      }, waitTime);
    }
  }, [grupo]);

  useEffect(() => {
    if (materia.value !== "") {
      consultarDetalleHorario(materia.value);
    }
  }, [materia]);

  const { enqueueSnackbar } = useSnackbar();

  async function consultarDetalleHorario(id) {
    setSelectLoading(true);
    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint).then(
      (res) => res.d
    );
    const filterHorario = assignmentDetailResponse.horario_profesor.filter(
      (item) => item.id_person === profesor.value
    )[0];
    const scheduleData = [
      filterHorario.horario.lunes[0],
      filterHorario.horario.martes[0],
      filterHorario.horario.miercoles[0],
      filterHorario.horario.jueves[0],
      filterHorario.horario.viernes[0],
      filterHorario.horario.sabado[0],
      filterHorario.horario.domingo[0],
    ];

    const fechas = [];
    filterHorario.horario_fechas.forEach((item) => {
      fechas.push({
        value: item,
        label:
          item.substring(8, 10) +
          "/" +
          item.substring(5, 7) +
          "/" +
          item.substring(0, 4),
      });
    });
    let comp = "";
    let getDay = moment().format("YYYY/MM/DD");
    getDay = parseInt(
      getDay.substring(0, 4) + getDay.substring(5, 7) + getDay.substring(8, 10)
    );
    const fechasFinales = [];
    fechas.forEach((item) => {
      comp = parseInt(
        item.value.substring(0, 4) +
          item.value.substring(5, 7) +
          item.value.substring(8, 10)
      );
      if (comp > getDay || comp === getDay) {
        fechasFinales.push(item);
      }
    });

    let optionsHorarios = [];
    const inicia = [];
    const termina = [];
    if (scheduleData[0]) {
      inicia[0] = scheduleData[0].substring(0, 5);
      termina[0] = scheduleData[0].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[0],
        value: scheduleData[0],
      });
    }
    if (scheduleData[1]) {
      inicia[1] = scheduleData[1].substring(0, 5);
      termina[1] = scheduleData[1].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[1],
        value: scheduleData[1],
      });
    }
    if (scheduleData[2]) {
      inicia[2] = scheduleData[2].substring(0, 5);
      termina[2] = scheduleData[2].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[2],
        value: scheduleData[2],
      });
    }
    if (scheduleData[3]) {
      inicia[3] = scheduleData[3].substring(0, 5);
      termina[3] = scheduleData[3].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[3],
        value: scheduleData[3],
      });
    }
    if (scheduleData[4]) {
      inicia[4] = scheduleData[4].substring(0, 5);
      termina[4] = scheduleData[4].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[4],
        value: scheduleData[4],
      });
    }
    if (scheduleData[5]) {
      inicia[5] = scheduleData[5].substring(0, 5);
      termina[5] = scheduleData[5].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[5],
        value: scheduleData[5],
      });
    }
    if (scheduleData[6]) {
      inicia[6] = scheduleData[6].substring(0, 5);
      termina[6] = scheduleData[6].substring(6, 11);
      optionsHorarios.push({
        label: scheduleData[6],
        value: scheduleData[6],
      });
    }
    setHorasIniciales(inicia);
    setHorasFinales(termina);
    setOptionsFechaProfesor(fechasFinales);
    setSelectLoading(false);
  }

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  async function searchProfesor2(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor_activos&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            valido: item.valido,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  function promiseOptions(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  function promiseOptions2(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor2(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  function handleChangeGrupo(ev) {
    const regex = /^[a-zA-Z0-9]*$/;
    if (regex.test(ev.target.value)) {
      setGrupo(ev.target.value);
      setMateria({
        label: "",
        value: "",
      });
      setHoraInicia("");
      setHoraTermina("");
      setFechaProfesor({});
      setMateriaOptions([]);
    }
  }

  async function searchMateria(id_campus, id_person, grupo, ciclo, direccion) {
    setSelectMateriaIsLoading(true);
    try {
      const options = [];

      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${id_person},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
          });
        });
        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  async function handleSubmit() {
    if (
      profesor.value === "" ||
      grupo === "" ||
      materia.value === "" ||
      fechaProfesor.value === "" ||
      tema === "" ||
      profesorReposicion.value === ""
    ) {
      enqueueSnackbar("Se deben llenar todos los datos de la sustitución", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const endpoint = "/asignaciones/clases-especiales/nueva";
      const body = {
        id_asignacion: materia.value,
        id_profesor: profesor.value,
        id_profesor_nuevo: profesorReposicion.value,
        id_dictamen: "",
        fecha: fechaProfesor.value,
        fechaNuevo: fechaProfesor.value,
        hora: "",
        hora_inicio: horaInicia,
        id_salon: "",
        tema: tema,
        tipo_clase_especial: props.tipoClaseEspecial,
      };
      const response = await postRequest(endpoint, body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        props.handleClose();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const handleFecha = (e) => {
    setFechaProfesor(e);
    let dia = new Date(e.value).getDay();
    switch (dia) {
      case 0:
        setHoraInicia(horasIniciales[0]);
        setHoraTermina(horasFinales[0]);
        break;
      case 1:
        setHoraInicia(horasIniciales[1]);
        setHoraTermina(horasFinales[1]);
        break;
      case 2:
        setHoraInicia(horasIniciales[2]);
        setHoraTermina(horasFinales[2]);
        break;
      case 3:
        setHoraInicia(horasIniciales[3]);
        setHoraTermina(horasFinales[3]);
        break;
      case 4:
        setHoraInicia(horasIniciales[4]);
        setHoraTermina(horasFinales[4]);
        break;
      case 5:
        setHoraInicia(horasIniciales[5]);
        setHoraTermina(horasFinales[5]);
        break;
      default:
        setHoraInicia(horasIniciales[6]);
        setHoraTermina(horasFinales[6]);
        break;
    }
  };

  const handleProfesorSustitucion = (e) => {
    if (e.valido === "OK") {
      setProfesorReposicion(e);
    } else {
      setProfesorReposicion("");
      enqueueSnackbar("Profesor no activo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleChangeMateria = (e) => {
    if (profesor.value) {
      setOptionsFechaProfesor([]);
      setHoraInicia("");
      setHoraTermina("");
      setOptionsFechaProfesor([]);
      setMateria(e);
    } else {
      enqueueSnackbar("Se debe seleccionar un profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <>
      <NewModal
        title="Reposición Sustitución por Incapacidad"
        open={props.open}
        handleClose={() => {
          setHoraInicia("");
          setHoraTermina("");
          props.handleClose();
        }}
        height={700}
        maxWidth={1000}
        marginTop={"-35px"}
        loading={loading}
        overflowY={"unset"}
        handleSubmit={handleSubmit}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "30px", alignItems: "center" }}
          >
            <Grid item xs={1} style={{ marginLeft: "-3%" }}>
              <label className={styles.inputLabel}>Ciclo:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                className={styles.filterInput}
                style={{ width: "130%" }}
                value={props.ciclo}
                disabled
              />
            </Grid>

            <Grid item xs={1} style={{ marginLeft: "3%" }}>
              <label className={styles.inputLabel}>Dirección:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.direccion.label}
                disabled
              />
            </Grid>

            <Grid item xs={1}>
              <label className={styles.inputLabel}>Director:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.director}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "25px",
              alignItems: "center",
            }}
          >
            <Grid item xs={4}>
              <h4 className={styles.modalSectionName}>
                Datos de la clase en que va a faltar
              </h4>
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={6} item style={{ padding: 2, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 20 }),
                }}
                loadOptions={promiseOptions}
                onChange={(e) => {
                  setProfesor(e);
                  setProfesor(e);
                  setOptionsFechaProfesor([]);
                  setHoraInicia("");
                  setHoraTermina("");
                  setOptionsFechaProfesor([]);
                  setFechaProfesor({
                    label: "",
                    value: "",
                  });
                  setGrupo("");
                  setMateria({
                    label: "",
                    value: "",
                  });
                }}
                value={profesor}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={grupo}
                onChange={handleChangeGrupo}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={3}>
              <Select
                classNamePrefix="select-modal"
                options={materiaOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 19 }),
                }}
                isLoading={selectMateriaIsLoading}
                onChange={(e) => {
                  handleChangeMateria(e);
                }}
                value={materia}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Fecha:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                classNamePrefix="select-modal"
                isLoading={selectLoading}
                options={optionsFechaProfesor}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 18 }),
                }}
                onChange={(e) => handleFecha(e)}
                value={fechaProfesor}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Inicia:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                disabled
                value={horaInicia}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ fontSize: "16px", width: "225px" }}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "7.5%" }}>
              <label className={styles.inputLabel}>Termina:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={horaTermina}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <h4 className={styles.modalSectionName}>
                Datos de la sustitución
              </h4>
            </Grid>
            <Grid item xs={9} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={6} item style={{ padding: 2, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 16 }),
                }}
                loadOptions={promiseOptions2}
                onChange={(e) => handleProfesorSustitucion(e)}
                value={profesorReposicion}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tema:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 10px)",
                  marginRight: "10px",
                }}
                value={tema}
                onChange={(ev) => setTema(ev.target.value)}
              ></input>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </>
  );
}

export default SustitucionIncapacidad;
