import { Font, Document, Page } from "@react-pdf/renderer";
import { styles } from "../styled";
import { BodyPDF } from "../body";
import PropTypes from "prop-types";
import RobotoBold from "../../../../src/fonts/Roboto-Bold.ttf";
import RobotoRegular from "../../../../src/fonts/Roboto-Regular.ttf";

DocumentPDFTable.propTypes = {
  matadata: PropTypes.array,
  data: PropTypes.array,
  title: PropTypes.string,
  name_report: PropTypes.string,
};

export function DocumentPDFTable({
  metadata,
  data,
  title,
  campus = "",
  startDate = "",
  endDate = "",
  teacherKey = "",
  ciclo = "",
  escuela = "",
  direccion = "",
  prefectura = false,
  director,
  total_profesores_grupo_materia,
  tipoIncidencias = "",
  tipo_clase,
  fechaReporte,
  tipo_profesor,
  grupo,
  materia,
  totalBox,
  hora,
  minutos,
  rowstableColSpan,
  orientation = "landscape",
  rows,
  tipo_reporte
}) {
  Font.register({
    family: "Bold",
    src: RobotoBold,
  });
  Font.register({
    family: "Regular",
    src: RobotoRegular,
  });

  const rowsPerPage = rows ? rows : 6;

  return (
    <Document title={title}>
      <Page
        style={orientation === "portrait" ? styles.page_v : styles.page}
        orientation={orientation}
      >
        <BodyPDF
          metadata={metadata}
          data={data}
          title={title}
          campus={campus}
          startDate={startDate}
          endDate={endDate}
          teacherKey={teacherKey}
          ciclo={ciclo}
          escuela={escuela}
          direccion={direccion}
          prefectura={prefectura}
          director={director}
          total_profesores_grupo_materia={total_profesores_grupo_materia}
          tipoIncidencias={tipoIncidencias}
          tipo_clase={tipo_clase}
          fechaReporte={fechaReporte}
          tipo_profesor={tipo_profesor}
          grupo={grupo}
          materia={materia}
          totalBox={totalBox}
          hora={hora}
          minutos={minutos}
          rowstableColSpan={rowstableColSpan}
          rows={rowsPerPage}
          tipo_reporte={tipo_reporte}
        />
      </Page>
    </Document>
  );
}
