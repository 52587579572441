import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewButton from "../../Utils/Botones/NewButton";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import CustomTable from "../../Utils/CustomTable";

const DirectoresAcademicos = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const permisos = useSelector((state) => state.permisos);
  const [optionsCampus] = useState(campusUser);
  const [campus, setCampus] = useState("");
  const [profesores, setProfesores] = useState([]);
  const [escuelaOptions, setEscuelaOptions] = useState([]);
  const [escuela, setEscuela] = useState("");
  const [editarDisabled, setEditarDisabled] = useState(true);
  const [editarButtonDisabled, setEditarButtonDisabled] = useState(false);
  const [modifiedTeachers, setModifiedTeachers] = useState([]);
  const headers = [
    {
      accessorKey: "clave_direccion",
      header: "Clave",
      size: 10,
    },
    {
      accessorKey: "nombre_direccion",
      header: "Nombre",
    },
    {
      accessorKey: "profesor",
      header: "Profesor",
      size: 400,
      Cell: ({ row }) => {
        return (
          <AsyncSelect
            className="react-select-container"
            classNamePrefix="react-select"
            styles={asyncRowSelectStyles}
            loadOptions={promiseOptions}
            isDisabled={editarDisabled}
            value={profesores[row.original.id]}
            onChange={(event) => handleProfesores(event, row.original)}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
          />
        );
      },
    },
    {
      accessorKey: "modificado",
      header: "Modificado",
    },
    {
      accessorKey: "modificado_por",
      header: "Modificado por",
    },
    {
      accessorKey: "creado",
      header: "Creado",
    },
    {
      accessorKey: "creado_por",
      header: "Creado por",
    },
  ];

  const asyncRowSelectStyles = {
    menu: (base) => ({
      ...base,
      position: "static",
    }),
  };

  useEffect(() => {
    searchEscuela();
    consultarDirecciones(escuela.value, campus.value);
  }, [escuela, campus]);

  useEffect(() => {
    setCampus(optionsCampus?.filter((item) => item.label.includes(campusName))[0]);
  }, []);

  async function searchEscuela() {
    try {
      setLoading(true);
      let arrElements = [];
      const request = `/catalogo?id=9`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        let nroEscuelas = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nroEscuelas; i++) {
          arrElements.push({
            value: parseInt(response.d.elementos[0]["id"][i]),
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
          });
        }
        setLoading(false);
        setEscuelaOptions(arrElements);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const consultarDirecciones = async (idEscuela, idCampus) => {
    try {
      if (
        typeof idCampus === "string" &&
        idCampus !== "" &&
        typeof idEscuela === "number" &&
        idEscuela !== ""
      ) {
        setLoading(true);
        const request = `/buscar?tipo=directores_academicos&valor=id_campus:${idCampus},id_escuela:${idEscuela}`;
        const response = await getRequest(request);
        if (response.s === "OK") {
          const newTeacherArray = [];
          response.d.forEach((element) => {
            if (element.profesor?.length > 0) {
              newTeacherArray.push(element.profesor[0]);
            }
          });
          setData(response.d);
          setProfesores(newTeacherArray);
          setLoading(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData([]);
          setLoading(false);
        }
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const waitTime = 2000;
  let timer;

  async function searchProfesor(valor) {
    try {
      const options = [];
      const request = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            value: item.id,
            label: item.nss + " - " + item.nombre,
            name: item.nombre,
            id_teacher: item.id,
            valido: item.valido,
          });
        });
        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length > 2) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue + ":academicos"));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const handleProfesores = async (ev, element) => {
    if (profesores === "") {
      setProfesores([ev]);
    } else if (ev?.valido === "ERROR") {
      enqueueSnackbar("Profesor seleccionado no es de tipo persona válido", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      let newArr = [...profesores];
      newArr[element.id] = ev;
      setProfesores(newArr);
      setModifiedTeachers((prev) => {
        if (prev[0] !== false) {
          return [
            ...prev,
            { ...ev, id_director: element.value, id_teacher: ev.id_teacher },
          ];
        }
        return [
          { ...ev, id_director: element.value, id_teacher: ev.id_teacher },
        ];
      });
    }
  };

  async function handleClickBuscar() {
    setLoading(true);
    try {
      setEditarDisabled(true);
      setEditarButtonDisabled(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleClickEditar() {
    let valido = true;
    if (data.length === 0) {
      enqueueSnackbar("No hay datos en la tabla para editar", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        setEditarDisabled(false);
        setEditarButtonDisabled(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleClickSave = async () => {
    const payload = {
      type: "directores_academicos",
      teachers: modifiedTeachers,
    };
    try {
      setLoading(true);
      setEditarDisabled(true);
      const response = await postRequest(`/catalogo`, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setModifiedTeachers([]);
        setEditarButtonDisabled(false);
        setLoading(false);
        consultarDirecciones(escuela.value, campus.value);
      } else {
        setLoading(false);
        setEditarDisabled(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setEditarDisabled(false);
    }
  };

  async function handleEscuela(e) {
    setEscuela(e);
    if (campus) {
      handleClickBuscar();
    }
  }

  async function handleCampus(e) {
    setCampus(e);
    if (escuela) {
      handleClickBuscar();
    }
  }

  const handleClickCancelar = async () => {
    setEditarDisabled(true);
    setEditarButtonDisabled(false);
  };

  useEffect(() => {}, [data]);

  return (
    <>
      <Contenedor title="Configuración / Directores Académicos">
        <div className="inputs-asignaciones">
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
              zIndex: 9,
            }}
          >
            <Grid container item xs={12} sm={8}>
              <Grid container alignItems="center">
                <Grid
                  item
                  xs={12}
                  sm={1}
                  style={{ textAlign: "end", marginLeft: "3%" }}
                >
                  <label>Campus(*):</label>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginRight: "4%" }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={optionsCampus}
                    value={campus}
                    onChange={(e) => handleCampus(e)}
                  />
                </Grid>
                <Grid item xs={3} sm={1} style={{ textAlign: "end" }}>
                  <label>Escuela(*):</label>
                </Grid>
                <Grid item xs={12} sm={3} style={{ zIndex: 98 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={escuelaOptions}
                    onChange={(e) => handleEscuela(e)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={3} spacing={2}>
              <Grid
                container
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", marginLeft: "-10%" }}>
                  <NewButton
                    customText="Editar"
                    noIcon
                    style={{
                      marginLeft: "12%",
                      padding: "5px 15px",
                      display:
                        permisos["Configuración->Directores Académicos"]
                          .modificar === "1"
                          ? "block"
                          : "none",
                    }}
                    disabled={editarButtonDisabled}
                    onClick={handleClickEditar}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <CustomTable
          data={loading ? [] : data}
          headers={headers}
          loading={loading}
          isFooterSaveButtons={editarDisabled}
          handleClickSave={handleClickSave}
          handleClickCancelar={handleClickCancelar}
        />
      </Contenedor>
    </>
  );
};

export default DirectoresAcademicos;
