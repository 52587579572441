import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { ExportCSV } from "../../Utils/Excel";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ReporteBitacoraMovimientos = () => {
  const headers = [
    {
      name: "Fecha",
      col: 0,
      id: "fecha",
      align: "center",
      width: 140,
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      width: 250,
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "escuela",
      width: 350,
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Movimiento",
      col: 3,
      id: "tipo_movimiento",
      width: 150,
      align: "center",
      fn: null,
    },
    {
      name: "Usuario",
      col: 4,
      id: "usuario",
      width: 250,
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 5,
      id: "clave_profesor",
      width: 100,
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 6,
      id: "nombre_profesor",
      width: 400,
      align: "center",
      fn: null,
    },
  ];
  const { enqueueSnackbar } = useSnackbar();
  const campusName = useSelector((state) => state.campusName);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [input, setInput] = useState({
    fechaInicial: "",
    fechaFinal: "",
  });

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        console.log(row);
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        fecha: e[0],
        campus: e[1],
        escuela: e[2],
        tipo_movimiento: e[3],
        usuario: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_Bitácora_de_Movimientos_${fecha}_${hora}`);
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        fecha: e[0],
        campus: e[1],
        escuela: e[2],
        tipo_movimiento: e[3],
        usuario: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Reporte Bitácor de Movimientos`}
        campus={campusName}
        startDate={input.fechaInicial}
        endDate={input.fechaFinal}
        teacherKey={
          valueClaveProfesor.value === undefined ? "" : valueClaveProfesor.label
        }
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Bitácora_de_Movimientos${fecha}_${hora}.pdf`);
    setReq(false);
  };

  function handleChange(e) {
    e.preventDefault();
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  async function handleClickGenerar() {
    let valido = true;
    if (input.fechaInicial === "") {
      enqueueSnackbar("Falta llenar campo Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (input.fechaFinal === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    let nrofechaInicial = parseInt(
      input.fechaInicial.substring(0, 4) +
        input.fechaInicial.substring(5, 7) +
        input.fechaInicial.substring(8, 10)
    );
    let nrofechaFinal = parseInt(
      input.fechaFinal.substring(0, 4) +
        input.fechaFinal.substring(5, 7) +
        input.fechaFinal.substring(8, 10)
    );
    let nrofechaActual = parseInt(moment().format("YYYYMMDD"));
    if (nrofechaInicial > nrofechaActual) {
      enqueueSnackbar("Fecha Inicial es superior a la Fecha Actual", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (nrofechaFinal > nrofechaActual) {
      enqueueSnackbar("Fecha Final es superior a la Fecha Actual", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (nrofechaInicial > nrofechaFinal) {
      enqueueSnackbar("Fecha Inicial es Superior a la Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        setLoading(true);
        let consulta = `/reportes/bitacora_de_movimientos?fecha_inicio=${input.fechaInicial}&fecha_fin=${input.fechaFinal}`;
        if (valueClaveProfesor)
          consulta += `&id_profesor=${valueClaveProfesor.value}`;

        const response = await getRequest(consulta);
        if (response.s === "OK") {
          if (response.d === "") {
            setData([]);
            enqueueSnackbar(
              "No se ha encontrado información con los datos ingresados",
              {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          } else {
            const datos = [];
            const nro = response.d["fecha"].length;
            for (let i = 0; i < nro; i++) {
              datos.push([
                response.d["fecha"][i],
                response.d["campus"][i],
                response.d["escuela"][i],
                response.d["tipo_movimiento"][i],
                response.d["usuario"][i],
                response.d["clave_profesor"][i],
                response.d["nombre_profesor"][i],
              ]);
            }
            setData(datos);
            setDataOriginal(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } else {
          setData([]);
          enqueueSnackbar("Error al obtener reporte bitácora de movimientos", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setShowTable(true);
      }
    }
  }

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Bitácora de Movimientos">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={12}>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                xs={1}
                md={2}
                className="textEnd"
                style={{ marginLeft: "-7%", marginRight: "0.5%" }}
              >
                <label>Fecha Inicio(*):</label>
              </Grid>
              <Grid xs={1} md={1} style={{ zIndex: 99 }}>
                <input
                  className="filter-input"
                  style={{ width: "140px" }}
                  value={input.fechaInicial}
                  name="fechaInicial"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => handleChange(e)}
                ></input>
              </Grid>
              <Grid
                xs={1}
                md={2}
                className="textEnd"
                style={{ marginLeft: "-4%", marginRight: "0.5%" }}
              >
                <label>Fecha Fin(*):</label>
              </Grid>
              <Grid xs={1} md={1} style={{ zIndex: 99 }}>
                <input
                  className="filter-input"
                  style={{ width: "140px" }}
                  disabled={input.fechaInicial !== "" ? false : true}
                  value={input.fechaFinal}
                  min={input.fechaInicial}
                  max={moment().format("YYYY-MM-DD")}
                  name="fechaFinal"
                  type="date"
                  onChange={(e) => handleChange(e)}
                ></input>
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                className="textEnd"
                style={{ marginLeft: "-1%" }}
              >
                <label>Clave Profesor:</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ zIndex: 99 }}>
                <AsyncSelect
                  className="select-modal-container input-claveProfesor clave-profesor-index0"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  value={valueClaveProfesor}
                  onChange={setValueClaveProfesor}
                  placeholder="Buscar Profesor"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={3}
            spacing={2}
            style={{ marginLeft: "-26%" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  {/* <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>

        <div style={{ height: "calc(100% - 165px)" }} id="Pagos">
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ReporteBitacoraMovimientos;
