import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";
export const getTabulators = async () => {
  try {
    let arrElements = [];
    const request = `/catalogo?id=34`;
    const response = await getRequest(request);
    if (response.s === "OK") {
      let nro = response.d.elementos[0]["id"].length;
      for (let i = 0; i < nro; i++) {
        if (
          response.d.elementos[2]["estatus"][i] === "Activo" &&
          response.d.elementos[7]["active"][i] === "True"
        )
          arrElements.push({
            value: parseInt(response.d.elementos[0]["id"][i]),
            label: response.d.elementos[1]["tabulador"][i],
          });
      }
      return arrElements;
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  } catch (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
  }
};
