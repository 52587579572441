import { View, Text } from "@react-pdf/renderer";
import { styles } from "../pdf_table/styled";
import HeaderPDFDefault from "../HeaderPDFDefault";
import { divideArrayIntoParts } from "../Funciones/divideArrayIntoParts";

export default function ReportPDFDefault({
  title,
  metadata,
  data,
  campus,
  startDate,
  endDate,
  teacherKey = "",
  ciclo,
  escuela,
  direccion,
  director,
  rows,
  total_profesores_grupo_materia,
}) {
  const Headers = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {metadata &&
          metadata.map((e, i) => (
            <View
              key={i}
              style={{
                width: e.width,
                ...styles.boxHeader,
                justifyContent: e.align,
              }}
            >
              <Text style={styles.cell}>{e.name}</Text>
            </View>
          ))}
      </View>
    );
  };
  const splitDataIntoParts = divideArrayIntoParts(data, rows);
  return splitDataIntoParts.map((dataTable, index2) => {
    return (
      <View break={index2 === 0 ? false : true} key={`-${index2}`}>
        <HeaderPDFDefault title={title} />
        <View style={{ marginTop: 15 }}>
          <View style={{ marginTop: 1 }}>
            <View
              style={{ flexDirection: "row", width: "100%", marginTop: 10 }}
            >
              {ciclo ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Ciclo: {ciclo}
                </Text>
              ) : null}
              {campus ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Campus: {campus}
                </Text>
              ) : null}
              {teacherKey ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Clave Profesor: {teacherKey}
                </Text>
              ) : null}
              {escuela ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Escuela: {escuela}
                </Text>
              ) : null}
              {direccion ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Dirección: {direccion}
                </Text>
              ) : null}
              {director ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Director: {director}
                </Text>
              ) : null}
              {startDate ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Fecha Inicial: {startDate}
                </Text>
              ) : null}
              {endDate ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Fecha Final: {endDate}
                </Text>
              ) : null}
            </View>
            <View
              style={{
                marginTop: 10,
                borderLeft: "1px",
                borderLeftColor: "#828282",
                borderTop: "1px",
                borderTopColor: "#828282",
              }}
            >
              <Headers />
              {dataTable &&
                dataTable.map((e, i) => {
                  if (i > 230) {
                    return null;
                  }
                  return (
                    <View style={{ flexDirection: "row" }} key={i}>
                      {metadata.map((el, il) => (
                        <View
                          key={il}
                          style={{
                            width: el.width,
                            ...styles.boxRow,
                            justifyContent: el.align,
                            borderTop: i === 0 ? "1px" : "0px",
                          }}
                        >
                          {el.fn === null ? (
                            <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                          ) : (
                            <Text> {el.fn(e[el.id])}</Text>
                          )}
                        </View>
                      ))}
                    </View>
                  );
                })}
            </View>
          </View>
        </View>

        {total_profesores_grupo_materia ? (
          <View style={{ flexDirection: "row", width: "100%", marginTop: 15 }}>
            <Text
              style={{ fontSize: 10, color: "#000000", marginRight: "25px" }}
            >
              Total Profesores Grupo-Materia: {total_profesores_grupo_materia}
            </Text>
          </View>
        ) : null}
      </View>
    );
  });
}
