import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const getMaterias = async () => {
    try {
        const consulta = `/buscar?tipo=materias`;
        const response = await getRequest(consulta);
        if (response.s === "OK") {
          return response.d;
        } else {
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
}