import { postRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const editCatalogo = async (listCatalogo, valueCatalogo, row, values, dataFinal, getDataTable, setLoading, setShowTable, setTableEdit, exitEditingMode) => {
    const getCampus = listCatalogo.filter((element) => element.value === valueCatalogo)[0]
    const payload = {
        ...values,
        type: getCampus.name,
        id: parseInt(row.original.id),
    }
    delete payload.creado
    delete payload.creado_por
    delete payload.modificado
    delete payload.modificado_por
    if (dataFinal.filter((element) => element.clave === values?.clave && element.clave !== undefined && row.original.id !== element.id).length > 0) {
        enqueueSnackbar(`Ya existe un registro con la misma clave ${values.clave}`, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
    } else {
        try {
            setLoading(true);
            setShowTable(false);
            const response = await postRequest(
                `/catalogo`,
                payload
            );
            if (response.s === "OK") {
                enqueueSnackbar(response.m, {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                });
                setTableEdit(false);
                getDataTable(valueCatalogo);
            } else {
                setLoading(false);
                setShowTable(true);
                enqueueSnackbar(response.m, {
                    variant: "error", autoHideDuration: null,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left",
                    },
                });
            }
        } catch (error) {
            console.log(error)
            setLoading(false);
            setShowTable(true);
        }
        exitEditingMode()
    }
}