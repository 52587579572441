import React, { useState } from "react";
import saveAs from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

import Contenedor from "../../Utils/Visuales/Contenedor";
import { getRequest } from "../../Utils/Funciones/requester";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { ExportCSV } from "../../Utils/Excel";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import SearchInput from "../../Utils/Visuales/SearchInput";
import moment from "moment";
import "./index.css";
import { removeHearsFromPDF } from "../../Utils/Funciones/removeHearsFromPDF";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";

const ProfesoresActivos = () => {
  const heading = [
    "ciclo",
    "campus",
    "modalidad",
    "tipo_ingreso",
    "tipo_persona",
    "tipo_profesor",
    "clave_profesor",
    "nombre_profesor",
    "grado_academico",
    "grupo",
    "clave_materia",
    "nombre_materia",
    "total_hrs_semana",
    "escuela",
    "direccion",
    "carrera",
    "tipo_de_grupo",
    "tipo_de_asistencia",
  ];

  const { enqueueSnackbar } = useSnackbar();
  const campusId = useSelector((state) => state.campusId);
  const campusArray = useSelector((state) => state.campusUser);
  const [valueCiclo, setValueCiclo] = useState("");
  const [opcionesCampus, setOpcionesCampus] = useState([]);
  const [valueCampus, setValueCampus] = useState(campusId.toString());
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [campusSelected, setCampusSelected] = useState(
    campusArray.map((item) => ({
      ...item,
      isActive: item.value.includes(`${campusId}`),
    }))
  );

  const headers = [
    {
      name: "Ciclo",
      col: 0,
      id: "ciclo",
      align: "center",
      width: 60,
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      align: "center",
      width: 100,
      fn: null,
    },
    {
      name: "Modalidad",
      col: 2,
      id: "modalidad",
      align: "center",
      width: 70,
      fn: null,
    },
    {
      name: "Tipo Ingreso",
      col: 3,
      id: "tipo_ingreso",
      align: "center",
      width: 80,
      fn: null,
    },
    {
      name: "Tipo Persona",
      col: 4,
      id: "tipo_persona",
      align: "center",
      width: 80,
      fn: null,
    },
    {
      name: "Tipo Profesor",
      col: 5,
      id: "tipo_profesor",
      align: "center",
      width: 80,
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 6,
      id: "clave_profesor",
      align: "center",
      width: 70,
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 7,
      id: "nombre_profesor",
      align: "center",
      width: 130,
      fn: null,
    },
    {
      name: "Grado Académico",
      col: 8,
      id: "grado_academico",
      align: "center",
      width: 70,
      fn: null,
    },
    {
      name: "Grupo",
      col: 9,
      id: "grupo",
      align: "center",
      width: 70,
      fn: null,
    },
    {
      name: "Clave Materia",
      col: 10,
      id: "clave_materia",
      align: "center",
      width: 70,
      fn: null,
    },
    {
      name: "Nombre Materia",
      col: 11,
      id: "nombre_materia",
      align: "center",
      width: 140,
      fn: null,
    },
    {
      name: "Total Horas Semana",
      col: 12,
      id: "total_hrs_semana",
      align: "center",
      width: 60,
      fn: null,
    },
    {
      name: "Escuela",
      col: 13,
      id: "escuela",
      align: "center",
      width: 120,
      fn: null,
    },
    {
      name: "Dirección",
      col: 14,
      id: "direccion",
      align: "center",
      width: 120,
      fn: null,
    },
    {
      name: "Carrera",
      col: 15,
      id: "carrera",
      align: "center",
      width: 100,
      fn: null,
    },
    {
      name: "Tipo de Grupo",
      col: 16,
      id: "tipo_de_grupo",
      align: "center",
      width: 80,
      fn: null,
    },
    {
      name: "Tipo de Asistencia",
      col: 17,
      id: "tipo_de_asistencia",
      align: "center",
      width: 80,
      fn: null,
    },
  ];
  const [dataFinal, setDataFinal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const handleChangeOpcionCampus = (selected) => {
    if (selected.label === "Todos") {
      const findTodos = campusSelected.find(
        (campus) => campus.label === "Todos"
      );
      setCampusSelected((prev) =>
        prev.map((campus) => ({ ...campus, isActive: !findTodos.isActive }))
      );
    } else {
      setCampusSelected((prev) => {
        const newPrev = prev.map((campus) => {
          if (campus.value === selected.value) {
            return { ...campus, isActive: !campus.isActive };
          }
          return { ...campus };
        });
        const allActive = newPrev.every(
          (campus) => campus.value === "0" || campus.isActive
        );
        return newPrev.map((campus) => {
          if (campus.label === "Todos") {
            return {
              ...campus,
              isActive: allActive,
            };
          }
          return { ...campus };
        });
      });
    }
  };

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }

  async function handleClick() {
    const allCampusSelected = campusSelected.filter(
      (campus) => campus.isActive
    );
    let valido = true;
    if (valueCiclo === "" || valueCiclo === undefined) {
      document
        .getElementsByClassName("input-ciclo")[0]
        .classList.add("error-input");
      enqueueSnackbar("Debe ingresar ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    } else {
      document
        .getElementsByClassName("input-ciclo")[0]
        .classList.remove("error-input");
    }
    if (allCampusSelected.length === 0) {
      enqueueSnackbar("Debe seleccionar campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setShowTable(true);
      setLoading(true);
      setButtonDisabled(true);
      try {
        const arrayCampus = allCampusSelected.map((campus) => campus.value);
        const datosFinal = await dataConsulta(arrayCampus);
        setDataFinal(datosFinal);
        setDataOriginal(datosFinal);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  async function dataConsulta(id) {
    if (Array.isArray(id)) {
      const multiplesDatos = [];
      for (let llamados = 0; llamados < id.length; llamados++) {
        const consulta = `/reportes/profesores-activos?&ciclo=${valueCiclo}&id_campus=${id[llamados]}`;
        const response = await getRequest(consulta);
        try {
          if (response.s === "OK") {
            const nro = response.d[0]["ciclo"].length;
            for (let i = 0; i < nro; i++) {
              multiplesDatos.push([
                response.d[0]["ciclo"][i],
                response.d[1]["campus"][i],
                response.d[2]["modalidad"][i],
                response.d[3]["tipo_ingreso"][i],
                response.d[4]["tipo_persona"][i],
                response.d[5]["tipo_profesor"][i],
                response.d[6]["clave_profesor"][i],
                response.d[7]["nombre_profesor"][i],
                response.d[8]["grado_academico"][i],
                response.d[9]["grupo"][i],
                response.d[10]["clave_materia"][i],
                response.d[11]["nombre_materia"][i],
                response.d[12]["total_horas_semana"][i],
                response.d[13]["escuela"][i],
                response.d[14]["direccion"][i],
                response.d[15]["carrera"][i],
                response.d[16]["tipo_grupo"][i],
                response.d[17]["tipo_asistencia"][i],
                response.d[18]["id_profesor"][i],
                response.d[19]["id_campus"][i],
              ]);
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      return multiplesDatos;
    } else {
      const datos = [];
      const consulta = `/reportes/profesores-activos?&ciclo=${valueCiclo}&id_campus=${id}`;
      const response = await getRequest(consulta);
      try {
        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["modalidad"][i],
              response.d[3]["tipo_ingreso"][i],
              response.d[4]["tipo_persona"][i],
              response.d[5]["tipo_profesor"][i],
              response.d[6]["clave_profesor"][i],
              response.d[7]["nombre_profesor"][i],
              response.d[8]["grado_academico"][i],
              response.d[9]["grupo"][i],
              response.d[10]["clave_materia"][i],
              response.d[11]["nombre_materia"][i],
              response.d[12]["total_horas_semana"][i],
              response.d[13]["escuela"][i],
              response.d[14]["direccion"][i],
              response.d[15]["carrera"][i],
              response.d[16]["tipo_grupo"][i],
              response.d[17]["tipo_asistencia"][i],
              response.d[18]["id_profesor"][i],
              response.d[19]["id_campus"][i],
            ]);
          }
          return datos;
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        modalidad: e[2],
        tipo_ingreso: e[3],
        tipo_persona: e[4],
        tipo_profesor: e[5],
        clave_profesor: e[6],
        nombre_profesor: e[7],
        grado_academico: e[8],
        grupo: e[9],
        clave_materia: e[10],
        nombre_materia: e[11],
        total_hrs_semana: e[12],
        escuela: e[13],
        direccion: e[14],
        carrera: e[15],
        tipo_de_grupo: e[16],
        tipo_de_asistencia: e[17],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    if (data_.length > 230) {
      enqueueSnackbar("Supera limites de Páginas", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    const doc = (
      <DocumentPDFTable
        metadata={removeHearsFromPDF(
          valueCiclo,
          valueCampus,
          "",
          "",
          "",
          "",
          headers
        )}
        data={data_ ?? []}
        ciclo={valueCiclo}
        campus={valueCampus}
        title={`Reportes Profesores Activos`}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_profesores_activos_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        modalidad: e[2],
        tipo_ingreso: e[3],
        tipo_persona: e[4],
        tipo_profesor: e[5],
        clave_profesor: e[6],
        nombre_profesor: e[7],
        grado_academico: e[8],
        grupo: e[9],
        clave_materia: e[10],
        nombre_materia: e[11],
        total_hrs_semana: e[12],
        escuela: e[13],
        direccion: e[14],
        carrera: e[15],
        tipo_de_grupo: e[16],
        tipo_de_asistencia: e[17],
      };
      return data2;
    });
    const date = moment().format("DD/MM/YYYY");
    const hour = moment().format("LTS");
    ExportCSV(data_, `Reporte_profesores_activos_${date}_${hour}`, heading);
    setReq(false);
  };

  return (
    <>
      <Contenedor title="Reportes / Profesores Activos">
        <div className="nuevoUsuarioForm">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <label>Ciclo(*):</label>
            </Grid>
            <Grid xs={2}>
              <input
                className="filter-input input-ciclo"
                placeholder="Indique el ciclo..."
                value={valueCiclo}
                onChange={(e) => setValueCiclo(e.target.value)}
              />
            </Grid>
            <Grid item xs={0} md={1} />
            <Grid item xs={1}>
              <label>Campus(*):</label>
            </Grid>
            <Grid item xs={3}>
              <MultiSelectCampus
                options={campusSelected}
                onChange={handleChangeOpcionCampus}
                value={{
                  value: `${
                    campusSelected.filter((item) => item.isActive).length
                  }`,
                  label: `Total: ${
                    campusSelected.filter((item) => item.isActive).length
                  }`,
                }}
              />
            </Grid>
            <Grid item xs={0} md={3} />
            <Grid item xs={0} md={10} />
            <Grid
              item
              xs="auto"
              justifyContent={"flex-end"}
              style={{ padding: "20px 10px", marginTop: "-4.5%" }}
            >
              <EditButton
                onClick={handleClick}
                style={{ padding: "10px 25px" }}
                disabled={buttonDisabled || loading}
              >
                Generar
              </EditButton>
            </Grid>
            <Grid item xs={0} md={9} />
            <div
              style={{ display: "flex", marginTop: "-0.5%", marginLeft: "5%" }}
              className="export-buttons"
            >
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button
                  onClick={DownloadExcel}
                  className={"buttonDownload"}
                  disabled={req}
                  variant="contained"
                >
                  <img src={excelIcon} alt="excel-icon" />
                </button>
                <button
                  className={"buttonDownload"}
                  onClick={DownloadPDF}
                  disabled={req}
                  variant="contained"
                >
                  <img src={pdfFileIcon} alt="pdf-file-icon" />
                </button>
              </div>
            </div>
          </Grid>
        </div>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 205px)" }}>
          <DataTable
            headers={headers}
            data={dataFinal}
            loading={loading}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ProfesoresActivos;
