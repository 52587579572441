import { hourIndex } from "../data/hourIndex";
import { convertHours } from "./convertHours";

export const salonesGetDaysApart = (array, length, data) => {
  const newData = [...array];
  const daysIndex = {
    Lun: 0,
    Mar: 1,
    Mie: 2,
    Jue: 3,
    Vie: 4,
    Sab: 5,
  };

  for (let i = 0; i < length; i++) {
    if (data.estatus[i]) {
      const arrayOfDays = data.dias[i].split(",");
      for (let f = 0; f < arrayOfDays.length; f++) {
        const initialHour = convertHours(data.horario[i].split(" - ")[0]);
        const endHour = convertHours(data.horario[i].split(" - ")[1]);
        const indexI = daysIndex[arrayOfDays[f]];
        const indexInitialJ = hourIndex[initialHour];
        const indexFinalJ = hourIndex[endHour];
        newData[indexI][indexInitialJ] = "Apartado";

        for (let j = indexInitialJ + 1; j < indexFinalJ; j++) {
          newData[indexI][j] = "Apartado";
        }
      }
    }
  }
  return newData;
};
