export const dayOrder = (day) => {
  const daysOfWeek = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo",
  ];
  return daysOfWeek.indexOf(day.toLowerCase());
};
