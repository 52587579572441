import React from "react";
import { Grid } from "@material-ui/core";
import ModalInput from "../ModalInput";

const ModalCreateProfesion = ({ setClave, setName }) => {
    return (
        <Grid style={{ padding: 20, paddingTop: 0 }}>
            <ModalInput lable={'Clave:'} onChange={(event) => setClave(event)} />
            <ModalInput lable={'Nombre:'} onChange={(event) => setName(event)} />
        </Grid>
    )

}

export default ModalCreateProfesion