import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewButton from "../../Utils/Botones/NewButton";
import Select from "react-select";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import NewModal from "../../Utils/Visuales/NewModal";
import CreateMateriaProfesioModal from "../../Utils/CreateMateriaProfesioModal";
import CopyMateriaProfesionModal from "../../Utils/CopyMateriaProfesionModal";
import { getAddresses } from "../../Utils/Funciones/getAddresses";
import CustomTable from "../../Utils/CustomTable";
import TextField from "@mui/material/TextField";
import CheckBoxStatusComponent from "../../Utils/CheckBoxStatusComponent";
import Typography from "@material-ui/core/Typography";
import excelIcon from "../../images/excel-icon.png";
// import moment from "moment";
import { ExportCSV } from "../../Utils/Excel";
import { sleep } from "../../Utils/Funciones/sleep";
import { getProfessions } from "../../Utils/Funciones/getProfessions";
import { getMaterias } from "../../Utils/Funciones/getMaterias";

const ConfiguracionMateriaProfesion = () => {
  const asyncRowSelectStyles = {
    menu: (base) => ({
      ...base,
      position: "static",
    }),
  };

  const headers = [
    {
      accessorKey: "clave_profesion",
      header: "Clave Profesión",
    },
    {
      accessorKey: "profesion",
      header: "Profesión",
    },
    {
      accessorKey: "estatus",
      header: "Estatus",
      size: 200,
      Cell: ({ cell, row }) => (
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          styles={asyncRowSelectStyles}
          options={estatusOptions}
          isDisabled={editarDisabled}
          defaultValue={
            cell.getValue() === "Vigente"
              ? { value: 1, label: "Vigente" }
              : { value: 0, label: "No Vigente" }
          }
          onChange={(event) => handleEstatus(event, row.original)}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      ),
    },
    {
      accessorKey: "observaciones",
      header: "Observaciones",
      Cell: ({ cell, row }) => {
        if (editarDisabled) {
          return <span>{cell.getValue()}</span>;
        }
        return (
          <TextField
            id="standard-basic"
            label=""
            variant="standard"
            defaultValue={cell.getValue()}
            onChange={(event) => {
              handleChangeInput(event, row.original);
            }}
          />
        );
      },
    },
    {
      accessorKey: "modificado",
      header: "Modificado",
    },
    {
      accessorKey: "modificado_por",
      header: "Modificado por",
    },
    {
      accessorKey: "creado",
      header: "Creado",
    },
    {
      accessorKey: "creado_por",
      header: "Creado por",
    },
  ];
  const [isSetearData, setIsSetearData] = useState(false);
  const [dataModifier, setDataTableModifier] = useState([]);
  const [carreraOptions, setCarreraOptions] = useState([]);
  const [carreraOptionsFilter, setCarreraOptionsFilter] = useState([]);
  const [carrera, setCarrera] = useState("");
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [materiaAllOptions, setMateriaAllOptions] = useState([]);
  const [materia, setMateria] = useState("");
  const [editarDisabled, setEditarDisabled] = useState(true);
  const [editarButtonDisabled, setEditarButtonDisabled] = useState(false);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
  const [copyButtonDisabled, setCopyButtonDisabled] = useState(false);
  const [createProfesion, setCreateProfesion] = useState("");
  const [createCarrera, setCreateCarrera] = useState("");
  const [createMateria, setCreateMateria] = useState("");
  const [createObservaciones, setCreateObservaciones] = useState("");
  const [profesionOptions, setProfesionOptions] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modalLoadingCreate, setModalLoadingCreate] = useState(false);
  const [modalLoadingCopy, setModalLoadingCopy] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableOriginal, setDataTableOriginal] = useState([]);
  const [req, setReq] = useState(false);
  const estatusOptions = [
    {
      value: 1,
      label: "Vigente",
    },
    {
      value: 0,
      label: "No Vigente",
    },
  ];

  const handleEstatus = (ev, row) => {
    let newDataModifier = dataModifier.filter(
      (element) => element.id !== row.id
    );
    newDataModifier = [
      ...newDataModifier,
      {
        ...row,
        ...dataModifier.filter((element) => element.id === row.id)[0],
        estatus: ev.label === "No Vigente" ? 0 : 1,
      },
    ];
    newDataModifier = newDataModifier.map((element) => {
      return {
        ...element,
        estatus:
          element.estatus === "No Vigente" || element.estatus === 0 ? 0 : 1,
      };
    });
    setDataTableModifier(newDataModifier);
  };

  const handleChangeInput = (ev, row) => {
    let newDataModifier = dataModifier.filter(
      (element) => element.id !== row.id
    );
    newDataModifier = [
      ...newDataModifier,
      {
        ...row,
        ...dataModifier.filter((element) => element.id === row.id)[0],
        observaciones: ev.target.value,
      },
    ];
    newDataModifier = newDataModifier.map((element) => {
      return {
        ...element,
        estatus:
          element.estatus === "No Vigente" || element.estatus === 0 ? 0 : 1,
      };
    });
    setDataTableModifier(newDataModifier);
  };

  async function handleClickEditar() {
    let valido = true;
    if (dataTable.length === 0) {
      enqueueSnackbar("No hay datos en la tabla para editar", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        setEditarDisabled(false);
        setEditarButtonDisabled(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleClickCreate() {
    setEditarDisabled(true);
    setEditarButtonDisabled(false);
    setCreateButtonDisabled(true);
    setModalLoadingCreate(true);
    getAddresses().then((data) => {
      setProfesionOptions(data);
    });
    getProfessions().then((data) => {
      setProfesionOptions(data);
    });
    getMaterias().then((data) => {
      setMateriaAllOptions(data);
      setModalLoadingCreate(false);
    });
  }

  async function handleClickCopy() {
    setEditarDisabled(true);
    setEditarButtonDisabled(false);
    setCopyButtonDisabled(true);
    getMaterias().then((data) => {
      setMateriaAllOptions(data);
      setModalLoadingCopy(false);
    });
  }

  const handleClickSave = async () => {
    const payload = {
      type: "materia_profesion",
      professions: dataModifier,
    };
    try {
      setLoading(true);
      const response = await postRequest(`/catalogo`, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        consultarMateria(carrera.value, materia.value);
        setEditarDisabled(true);
        setEditarButtonDisabled(false);
        setCreateButtonDisabled(false);
        setLoading(false);
        setDataTableModifier([]);
      } else {
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  async function handleClickBuscar() {
    setLoading(true);
    setDataTableModifier([]);
    try {
      setEditarDisabled(true);
      setEditarButtonDisabled(false);
      setCreateButtonDisabled(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleMateria(e) {
    setDataTableModifier([]);
    setMateria(e);
    setIsSetearData(false);
    setLoading(true);
  }

  async function handleCarrera(e) {
    setDataTableModifier([]);
    setIsSetearData(true);
    setCarrera(e);
    setMateria("");
    setMateriaOptions([]);
    setDataTable([]);
  }

  const getListOfCareers = async () => {
    try {
      setLoading(true);
      const consulta = `/buscar?tipo=materia_profesion&valor=carrera:list`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setCarreraOptions(response.d);
        setCarreraOptionsFilter(
          response.d.filter((element) => element.total > 0)
        );
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setCarreraOptions([]);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const consultarMateria = async (idCarrera, idMateria) => {
    try {
      if (
        typeof idCarrera === "number" &&
        idCarrera !== "" &&
        typeof idMateria === "number" &&
        idMateria !== ""
      ) {
        setLoading(true);
        const consulta = `/buscar?tipo=materia_profesion&valor=id_carrera:${idCarrera},id_materia:${idMateria}`;
        const response = await getRequest(consulta);
        if (response.s === "OK") {
          if (isSetearData) {
            setDataTable([]);
          } else {
            setDataTable([]);
            const newDataArray = response.d.map((obj) => {
              return {
                ...obj,
                modificado: obj.modificado === "NaT" ? "" : obj.modificado,
              };
            });
            setDataTableOriginal(newDataArray);
            setDataTable(newDataArray);
          }
          setLoading(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setDataTable([]);
          setLoading(false);
        }
      } else {
        setDataTable([]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function searchCarrera() {
    try {
      setLoading(true);
      const request = `/buscar?tipo=materia_profesion&valor=id_carrera:${carrera.value}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setLoading(false);
        setMateriaOptions(response.d);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const handleChangeStatus = async (value, label) => {
    setLoading(true);
    if (label === "Vigente" && value) {
      setDataTable(
        dataTableOriginal.filter((element) => element?.estatus === "Vigente")
      );
      await sleep(500);
      setLoading(false);
    } else if (label === "No Vigente" && value) {
      setDataTable(
        dataTableOriginal.filter((element) => element?.estatus === "No Vigente")
      );
      await sleep(500);
      setLoading(false);
    } else {
      setDataTable(dataTableOriginal);
      await sleep(500);
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setEditarButtonDisabled(false);
    setCreateButtonDisabled(false);
    setCopyButtonDisabled(false);
    consultarMateria(carrera.value, materia.value);
    setCreateCarrera("");
    setCreateMateria("");
    setCreateProfesion("");
    setCreateObservaciones("");
  };

  const handleSubmitCreate = async () => {
    if (
      createProfesion.value === undefined ||
      createCarrera.value === undefined ||
      createMateria.value === undefined
    ) {
      enqueueSnackbar(
        "Debes seleccionar una carrera, una materia y una profesión",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    } else {
      const payload = {
        type: "materia_profesion-create",
        id_carrera: parseInt(createCarrera.value),
        id_materia: parseInt(createMateria.value),
        id_profesion: parseInt(createProfesion.value),
        observaciones: createObservaciones,
        estatus: 1,
      };
      try {
        setCreateProfesion(true);
        const response = await postRequest(`/catalogo`, payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          consultarMateria(carrera.value, materia.value);
          setEditarDisabled(true);
          setEditarButtonDisabled(false);
          setCreateButtonDisabled(false);
          setCreateCarrera("");
          setCreateMateria("");
          setCreateProfesion("");
          setCreateObservaciones("");
          setDataTableModifier([]);
        } else {
          setCreateProfesion(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
        setCreateProfesion(false);
      }
    }
  };

  const handleSubmitCopy = async () => {
    if (
      createCarrera.value === undefined ||
      createMateria.value === undefined
    ) {
      enqueueSnackbar(
        "Debes seleccionar una carrera, una materia y una profesión",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    } else {
      const payload = {
        type: "materia_profesion-copy",
        id_carrera: parseInt(carrera.value),
        id_materia: parseInt(materia.value),
        id_carrera_copy: parseInt(createCarrera.value),
        id_materia_copy: parseInt(createMateria.value),
      };
      setModalLoadingCopy(true);
      try {
        const response = await postRequest(`/catalogo`, payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          getListOfCareers();
          setMateriaOptions([]);
          setCarrera(createCarrera);
          setMateria(createMateria);
          setEditarDisabled(true);
          setEditarButtonDisabled(false);
          setCreateCarrera("");
          setCreateMateria("");
          setDataTableModifier([]);
          setModalLoadingCopy(false);
          setCopyButtonDisabled(false);
        } else {
          setModalLoadingCopy(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setModalLoadingCopy(false);
        }
      } catch (error) {
        console.log(error);
        setModalLoadingCopy(false);
      }
    }
  };
  const handleClickCancelar = async () => {
    setEditarDisabled(true);
    setEditarButtonDisabled(false);
  };

  const DownloadExcel = () => {
    setLoading(true);
    setReq(true);
    const data_ = dataTable.map((e) => {
      const data2 = {
        "Clave Profesión": e.clave_profesion,
        Profesión: e.profesion,
        Estatus: e.estatus,
        Observaciones: e.observaciones,
        Creado: e.creado,
        "Creado Por": e.creado_por,
        Modificado: e.modificado,
        "Modificado Por": e.modificado_por,
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Materia-Profesión_${fecha}_${hora}`);
    setReq(false);
    setLoading(false);
  };

  useEffect(() => {}, [dataTable]);

  useEffect(() => {
    if (carrera.value !== undefined && materia.value === undefined) {
      setMateria("");
      searchCarrera();
    }
    if (isSetearData) {
      setDataTable([]);
    }
    if (carrera) {
      handleClickBuscar();
      consultarMateria(carrera.value, materia.value);
    }
    if (materia === "" && carrera === "") {
      getListOfCareers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materia, carrera]);

  return (
    <>
      <Contenedor title="Configuración / Materia - Profesión">
        <div className="inputs-asignaciones">
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
              zIndex: 9,
            }}
          >
            <Grid container item xs={12} sm={9}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                  <label>Carrera Origen(*):</label>
                </Grid>
                <Grid item xs={12} sm={4} style={{ marginRight: "4%" }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={carreraOptionsFilter}
                    value={carrera}
                    onChange={(e) => handleCarrera(e)}
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <label>Materia Legal(*):</label>
                </Grid>
                <Grid item xs={12} sm={4} style={{ marginRight: "1%" }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={materiaOptions}
                    isDisabled={carrera.value ? false : true}
                    onChange={(e) => handleMateria(e)}
                    value={materia}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{ display: "flex", cursor: "pointer" }}
              className="export-buttons"
              onClick={DownloadExcel}
            >
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button className={"buttonDownload"} variant="contained">
                  <img src={excelIcon} alt="excel-icon" />
                </button>
              </div>
            </div>
          </Grid>
          <Grid
            container
            xs={12}
            spacing={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <NewButton
                  customText="Editar"
                  noIcon
                  style={{ marginRight: 30, padding: "5px 15px" }}
                  disabled={editarButtonDisabled}
                  onClick={handleClickEditar}
                />
                <NewButton
                  customText="Crear"
                  noIcon
                  style={{ marginRight: 30, padding: "5px 15px" }}
                  onClick={handleClickCreate}
                />
                <NewButton
                  customText="Copia"
                  noIcon
                  disabled={
                    createButtonDisabled ||
                    carrera.value === undefined ||
                    materia.value === undefined
                  }
                  style={{ marginRight: 30, padding: "5px 15px" }}
                  onClick={handleClickCopy}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <CustomTable
          data={loading ? [] : dataTable}
          headers={headers}
          loading={loading}
          isFooterSaveButtons={editarDisabled}
          handleClickSave={handleClickSave}
          handleClickCancelar={handleClickCancelar}
          isRenderTopToolbarCustomActions={true}
          topToolBardComponent={
            <CheckBoxStatusComponent handleChangeStatus={handleChangeStatus} />
          }
        />
        <NewModal
          title="Crear Profesion"
          open={createButtonDisabled}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmitCreate}
          loading={modalLoadingCreate}
          minHeight={400}
          overflowY={"unSet"}
        >
          <CreateMateriaProfesioModal
            setCreateCarrera={setCreateCarrera}
            setCreateMateria={setCreateMateria}
            setCreateProfesion={setCreateProfesion}
            setCreateObservaciones={setCreateObservaciones}
            profesionOptions={profesionOptions}
            materiaOptions={materiaAllOptions}
            carreraOptions={carreraOptions}
            createObservaciones={createObservaciones}
          />
        </NewModal>
        <NewModal
          title="Copia Profesion"
          open={copyButtonDisabled}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmitCopy}
          loading={modalLoadingCopy}
          minHeight={400}
          overflowY={"unSet"}
        >
          <CopyMateriaProfesionModal
            setCreateCarrera={setCreateCarrera}
            setCreateMateria={setCreateMateria}
            materiaOptions={materiaAllOptions}
            carreraOptions={carreraOptions}
            carrera={carrera.label}
            materia={materia.label}
          />
        </NewModal>
      </Contenedor>
    </>
  );
};

export default ConfiguracionMateriaProfesion;
