import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import Select from "react-select";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import { ExportCSV } from "../../Utils/Excel";
import saveAs from "file-saver";
import "./index.css";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";

const ReporteGradoSuperiorAcademico = () => {
  const headersConcentradoPDF = [
    {
      name: "Dirección",
      col: 0,
      id: "direccion",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupos",
      col: 1,
      id: "grupos",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Horas",
      col: 2,
      id: "horas",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Licenciatura",
      col: 3,
      id: "licenciatura",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Especialidad",
      col: 4,
      id: "especialidad",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Maestría",
      col: 5,
      id: "maestria",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Doctorado",
      col: 6,
      id: "doctorado",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Post Doctorado",
      col: 7,
      id: "post_doctorado",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Horas GSI",
      col: 8,
      id: "horas_gsi",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Porcentaje GSI",
      col: 9,
      id: "porcentaje_gsi",
      width: "10%",
      align: "center",
      fn: null,
    },
  ];

  const headersConcentrado = [
    {
      name: "Ciclo",
      col: 0,
      id: "ciclo",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "escuela",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 3,
      id: "direccion",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupos",
      col: 4,
      id: "grupos",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Horas",
      col: 5,
      id: "horas",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Licenciatura",
      col: 6,
      id: "licenciatura",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Especialidad",
      col: 7,
      id: "especialidad",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Maestría",
      col: 8,
      id: "maestria",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Doctorado",
      col: 9,
      id: "doctorado",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Post Doctorado",
      col: 10,
      id: "post_doctorado",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Horas GSI",
      col: 11,
      id: "horas_gsi",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Porcentaje GSI",
      col: 12,
      id: "porcentaje_gsi",
      width: "10%",
      align: "center",
      fn: null,
    },
  ];

  const headersDetalle = [
    {
      name: "Ciclo",
      col: 0,
      id: "ciclo",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "escuela",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 3,
      id: "direccion",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Director Académico",
      col: 4,
      id: "director_academico",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Carrera",
      col: 5,
      id: "carrera",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Ciclo",
      col: 6,
      id: "tipo_ciclo",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 7,
      id: "clave_profesor",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 8,
      id: "nombre_profesor",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grado Académico",
      col: 9,
      id: "grado_academico",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 10,
      id: "grupo",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      col: 11,
      id: "clave_materia",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Materia",
      col: 12,
      id: "nombre_materia",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Horas Semanales",
      col: 13,
      id: "horas_semanales",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Grupo",
      col: 14,
      id: "tipo_grupo",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Asistencia",
      col: 15,
      id: "tipo_asistencia",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Baja",
      col: 16,
      id: "fecha_baja",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Estatus Grupo",
      col: 17,
      id: "estatus_grupo",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Asignados",
      col: 18,
      id: "asignados",
      width: "15%",
      align: "center",
      fn: null,
    },
  ];

  const headersTotalesTable = [
    {
      name: "Grupos",
      col: 0,
    },
    {
      name: "Horas",
      col: 1,
    },
    {
      name: "Licenciatura",
      col: 2,
    },
    {
      name: "Especialidad",
      col: 3,
    },
    {
      name: "Maestría",
      col: 4,
    },
    {
      name: "Doctorado",
      col: 5,
    },
    {
      name: "Post Doctorado",
      col: 6,
    },
    {
      name: "Horas GSI",
      col: 7,
    },
    {
      name: "Porcentaje GSI",
      col: 8,
    },
  ];

  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [headersFinal, setHeadersFinal] = useState([]);
  const [valueCiclo, setValueCiclo] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueGrupos, setValueGrupos] = useState("");
  const [optionsGrupos, setOptionsGrupos] = useState([]);
  const [valueReporte, setValueReporte] = useState("");
  const [optionsReporte, setOptionsReporte] = useState("");
  const [tipoReporte, setTipoReporte] = useState("");
  const [sumaTotales, setSumaTotales] = useState([]);
  const [showTotales, setShowTotales] = useState(false);
  const [campusSelected, setCampusSelected] = useState(
    campusUser.map((item) => ({
      ...item,
      isActive: item.label.includes(campusName),
    }))
  );
  const { enqueueSnackbar } = useSnackbar();

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  var tablasConcentrado = [
    <div style={{ height: "calc(60% - 30px)" }} id="Pagos">
      <DataTable
        headers={headersFinal}
        data={data}
        loading={loading}
        centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        paginate
        bordes
      />
    </div>,
    <div container alignItems="center" spacing={2} style={{ display: "flex" }}>
      <Grid item xs={12} sm={1} style={{ fontSize: "22px" }}>
        <label>Totales: </label>
      </Grid>
      <Grid style={{ height: "calc(5% - 10px)", width: "95%" }} id="Pagos">
        <DataTable
          headers={headersTotalesTable}
          data={sumaTotales}
          loading={loading}
        />
      </Grid>
    </div>,
  ];

  async function handleClickGenerar() {
    const campusFilter = campusSelected.filter((item) => item.isActive);
    if (valueCiclo === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (campusFilter.length === 0) {
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valueGrupos === "") {
      enqueueSnackbar("Falta seleccionar Modalidad de Grupos", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valueReporte === "") {
      enqueueSnackbar("Falta seleccionar Tipo Reporte", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        var mostrarReporte = "";
        if (valueReporte["label"] === "Concentrado") {
          setShowTotales(true);
          setLoading(true);
          mostrarReporte = "Concentrado - Grado Superior Académico";
          const datos = [];
          for (let j in campusFilter) {
            let url = `/reportes/grado_superior_academico_concentrado?ciclo=${valueCiclo}&id_campus=${campusFilter[j].value}&id_modalidad_grupos=${valueGrupos.value}&id_tipo_reporte=${valueReporte.value}`;
            if (selectedEscuela) url += `&id_escuela=${selectedEscuela.value}`;
            if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
            const request = url;
            const response = await getRequest(request);

            if (response.s === "OK") {
              const nro = response.d[0]["ciclo"].length;
              for (let i = 0; i < nro; i++) {
                datos.push([
                  response.d[0]["ciclo"][i],
                  response.d[1]["campus"][i],
                  response.d[2]["escuela"][i],
                  response.d[3]["direccion"][i],
                  response.d[4]["grupos"][i],
                  response.d[5]["horas"][i],
                  response.d[6]["licenciatura"][i],
                  response.d[7]["especialidad"][i],
                  response.d[8]["maestria"][i],
                  response.d[9]["doctorado"][i],
                  response.d[10]["post_doctorado"][i],
                  response.d[11]["horas_gsi"][i],
                  parseFloat(response.d[12]["porcentaje_gsi"][i]).toFixed(2) ===
                  "0.00"
                    ? 0
                    : parseFloat(response.d[12]["porcentaje_gsi"][i]).toFixed(
                        2
                      ),
                ]);
              }
              const suma = {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
              };
              datos.forEach((i) => {
                suma["0"] += parseFloat(i[4]);
                suma["1"] += parseFloat(i[5]);
                suma["2"] += parseFloat(i[6]);
                suma["3"] += parseFloat(i[7]);
                suma["4"] += parseFloat(i[8]);
                suma["5"] += parseFloat(i[9]);
                suma["6"] += parseFloat(i[10]);
                suma["7"] += parseFloat(i[11]);
                suma["8"] += parseFloat(i[12]);
              });
              setHeadersFinal(headersConcentrado);
              setSumaTotales([
                [
                  suma["0"],
                  suma["1"],
                  suma["2"],
                  suma["3"],
                  suma["4"],
                  suma["5"],
                  suma["6"],
                  suma["7"],
                  suma["8"].toFixed(2),
                ],
              ]);
            }
          }
          setTipoReporte(mostrarReporte);
          setData(datos);
          setDataOriginal(datos);
          setShowTable(true);
          setLoading(false);
          enqueueSnackbar("Reporte generado correctamente", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(true);
          mostrarReporte = "Detalle - Grado Superior Académico";
          const datos = [];
          for (let j in campusFilter) {
            let url = `/reportes/grado_superior_academico_detalle?ciclo=${valueCiclo}&id_campus=${campusFilter[j].value}&id_modalidad_grupos=${valueGrupos.value}&id_tipo_reporte=${valueReporte.value}`;
            if (selectedEscuela) url += `&id_escuela=${selectedEscuela.value}`;
            if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
            const request = url;
            const response = await getRequest(request);
            if (response.s === "OK") {
              const nro = response.d[0]["ciclo"].length;
              //console.log(nro);
              for (let i = 0; i < nro; i++) {
                datos.push([
                  response.d[0]["ciclo"][i],
                  response.d[1]["campus"][i],
                  response.d[2]["escuela"][i],
                  response.d[3]["direccion"][i],
                  response.d[4]["director_academico"][i],
                  response.d[5]["carrera"][i],
                  response.d[6]["tipo_ciclo"][i],
                  response.d[7]["clave_profesor"][i],
                  response.d[8]["nombre_profesor"][i],
                  response.d[9]["grado_academico"][i],
                  response.d[10]["grupo"][i],
                  response.d[11]["clave_materia"][i],
                  response.d[12]["nombre_materia"][i],
                  response.d[13]["horas_semanales"][i],
                  response.d[14]["tipo_grupo"][i],
                  response.d[15]["tipo_asistencia"][i],
                  response.d[16]["fecha_baja"][i],
                  response.d[17]["estatus_grupo"][i],
                  response.d[18]["asignados"][i],
                ]);
              }
            }
          }
          setShowTotales(false);
          setHeadersFinal(headersDetalle);
          setTipoReporte(mostrarReporte);
          setData(datos);
          setDataOriginal(datos);
          // setShowTablaDetalle(true);
          setShowTable(true);
          setLoading(false);
          enqueueSnackbar("Reporte generado correctamente", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        //console.log(error);
        setLoading(false);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({
            value: parseInt(id[i]),
            label: `${clave[i]} - ${nombre[i]}`,
          });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setSelectEscuela(data));
    setOptionsGrupos([
      {
        value: 1,
        label: "Presencial",
      },
      {
        value: 2,
        label: "Online",
      },
    ]);
    setOptionsReporte([
      {
        value: 1,
        label: "Concentrado",
      },
      {
        value: 2,
        label: "Detalle",
      },
    ]);
  }, []);

  const DownloadPDF = async () => {
    setReq(true);
    let data_ = "";
    if (valueReporte.value === 1) {
      data_ = data.map((e) => {
        const data2 = {
          ciclo: e[0],
          campus: e[1],
          escuela: e[2],
          direccion: e[3],
          grupos: e[4],
          horas: e[5],
          licenciatura: e[6],
          especialidad: e[7],
          maestria: e[8],
          doctorado: e[9],
          post_doctorado: e[10],
          horas_gsi: e[11],
          porcentaje_gsi: e[12],
        };
        return data2;
      });
      const total = {
        direccion: "TOTALES",
        grupos: sumaTotales[0][0],
        horas: sumaTotales[0][1],
        licenciatura: sumaTotales[0][2],
        especialidad: sumaTotales[0][3],
        maestria: sumaTotales[0][4],
        doctorado: sumaTotales[0][5],
        post_doctorado: sumaTotales[0][6],
        horas_gsi: sumaTotales[0][7],
        porcentaje_gsi: ((sumaTotales[0][7] / sumaTotales[0][1]) * 100).toFixed(
          2
        ),
      };
      data_.push(total);
    } else {
      data_ = data.map((e) => {
        const data2 = {
          ciclo: e[0],
          campus: e[1],
          escuela: e[2],
          direccion: e[3],
          director_academico: e[4],
          carrera: e[5],
          tipo_ciclo: e[6],
          clave_profesor: e[7],
          nombre_profesor: e[8],
          grado_academico: e[9],
          grupo: e[10],
          clave_materia: e[11],
          nombre_materia: e[12],
          horas_semanales: e[13],
          tipo_grupo: e[14],
          tipo_asistencia: e[15],
          fecha_baja: e[16],
          estatus_grupo: e[17],
          asignados: e[18],
        };
        return data2;
      });
    }
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={
          valueReporte.value === 1 ? headersConcentradoPDF : headersFinal ?? []
        }
        data={data_ ?? []}
        title={`Reportes Grado Superior Académico`}
        ciclo={valueCiclo}
        tipo_reporte={valueReporte.value}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_GSA_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    let data_ = "";
    if (valueReporte.value === 1) {
      data_ = data.map((e) => {
        const data2 = {
          ciclo: e[0],
          campus: e[1],
          escuela: e[2],
          direccion: e[3],
          grupos: e[4],
          horas: e[5],
          licenciatura: e[6],
          especialidad: e[7],
          maestria: e[8],
          doctorado: e[9],
          post_doctorado: e[10],
          horas_gsi: e[11],
          porcentaje_gsi: e[12],
        };
        return data2;
      });
      const total = {
        ciclo: "",
        campus: "",
        escuela: "",
        direccion: "TOTALES",
        grupos: sumaTotales[0][0],
        horas: sumaTotales[0][1],
        licenciatura: sumaTotales[0][2],
        especialidad: sumaTotales[0][3],
        maestria: sumaTotales[0][4],
        doctorado: sumaTotales[0][5],
        post_doctorado: sumaTotales[0][6],
        horas_gsi: sumaTotales[0][7],
        porcentaje_gsi: sumaTotales[0][8],
      };
      data_.push(total);
    } else {
      data_ = data.map((e) => {
        const data2 = {
          ciclo: e[0],
          campus: e[1],
          escuela: e[2],
          direccion: e[3],
          director_academico: e[4],
          carrera: e[5],
          tipo_ciclo: e[6],
          clave_profesor: e[7],
          nombre_profesor: e[8],
          grado_academico: e[9],
          grupo: e[10],
          clave_materia: e[11],
          nombre_materia: e[12],
          horas_semanales: e[13],
          tipo_grupo: e[14],
          tipo_asistencia: e[15],
          fecha_baja: e[16],
          estatus_grupo: e[17],
          asignados: e[18],
        };
        return data2;
      });
    }
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_GradoSuperiorAcademico_${fecha}_${hora}`);
    setReq(false);
  };

  const handleChangeOpcionCampus = (selected) => {
    if (selected.label === "Todos") {
      const findTodos = campusSelected.find(
        (campus) => campus.label === "Todos"
      );
      setCampusSelected((prev) =>
        prev.map((campus) => ({ ...campus, isActive: !findTodos.isActive }))
      );
    } else {
      setCampusSelected((prev) => {
        const newPrev = prev.map((campus) => {
          if (campus.value === selected.value) {
            return { ...campus, isActive: !campus.isActive };
          }
          return { ...campus };
        });
        const allActive = newPrev.every(
          (campus) => campus.value === "0" || campus.isActive
        );
        return newPrev.map((campus) => {
          if (campus.label === "Todos") {
            return {
              ...campus,
              isActive: allActive,
            };
          }
          return { ...campus };
        });
      });
    }
  };

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Grado Superior Académico">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                xs={12}
                sm={1}
                style={{ textAlign: "end", marginLeft: "-1.5%" }}
              >
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginLeft: "2.1%" }}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                style={{ textAlign: "end", marginRight: "9.2%" }}
              >
                <label>Campus(*):</label>
              </Grid>
              <Grid item xs={1} sm={3} style={{ marginRight: "4.2%" }}>
                <MultiSelectCampus
                  options={campusSelected}
                  onChange={handleChangeOpcionCampus}
                  value={{
                    value: `${
                      campusSelected.filter((item) => item.isActive).length
                    }`,
                    label: `Total: ${
                      campusSelected.filter((item) => item.isActive).length
                    }`,
                  }}
                />
              </Grid>
              <Grid xs={1} md={1} style={{ marginLeft: "-2.8%" }}>
                <label>Escuela:</label>
              </Grid>
              <Grid
                xs={1}
                md={2}
                item
                style={{ zIndex: 99, marginLeft: "4.9%" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectEscuela}
                  onChange={setSelectedEscuela}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "0%", marginLeft: "-1%" }}
            >
              <Grid
                item
                xs={12}
                sm={1}
                style={{ textAlign: "end", marginRight: "1%" }}
              >
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className="textEnd"
                style={{ marginLeft: "-7.2%", marginRight: "-0.5%" }}
              >
                <label>Modalidad de Grupos(*):</label>
              </Grid>
              <Grid item xs={1} sm={3}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  options={optionsGrupos}
                  onChange={setValueGrupos}
                />
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginLeft: "0.5%" }}>
                <label>Tipo Reporte(*):</label>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginLeft: "-2.5%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  options={optionsReporte}
                  onChange={setValueReporte}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={1} sm={3} spacing={2}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  {valueReporte.value === 1 && (
                    <button
                      onClick={DownloadPDF}
                      disabled={!showTable || req}
                      className={"buttonDownload"}
                      variant="contained"
                    >
                      <img src={pdfFileIcon} alt="pdf-file-icon" />
                    </button>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5} style={{ marginTop: "0.5%" }}>
            <Typography style={{ fontWeight: "bold" }}>
              {tipoReporte}
            </Typography>
          </Grid>
        </Grid>

        {showTotales ? (
          tablasConcentrado
        ) : (
          <div style={{ height: "calc(100% - 210px)" }} id="Pagos">
            <DataTable
              headers={headersFinal}
              data={data}
              loading={loading}
              centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              paginate
              bordes
            />
          </div>
        )}
      </Contenedor>
    </>
  );
};

export default ReporteGradoSuperiorAcademico;
