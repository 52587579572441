import React from "react";
import ModalCreateConceptoPago from "../ModalCreateConceptoPago";
import ModalCreateQuincena from "../ModalCreateQuincena";
import ModalCreateProfesion from "../ModalCreateProfesion";
import ModalCreateTabulator from "../ModalCreateTabulator";

const SwitchModal = ({
  year,
  number,
  startDate,
  endDate,
  processDate,
  paymentDate,
  catalogo,
  setClave = () => {},
  setDescription = () => {},
  setType = () => {},
  setMaxImport = () => {},
  setShowImporte = () => {},
  setShowSchedule = () => {},
  setYear = () => {},
  setNumber = () => {},
  setStartDate = () => {},
  setEndDate = () => {},
  setProcessDate = () => {},
  setPaymentDate = () => {},
  setName = () => {},
  setTabulador = () => {},
}) => {
  switch (catalogo) {
    case 6:
      return (
        <ModalCreateConceptoPago
          setClave={setClave}
          setDescription={setDescription}
          setType={setType}
          setMaxImport={setMaxImport}
          setShowImporte={setShowImporte}
          setShowSchedule={setShowSchedule}
        />
      );
    case 14:
      return (
        <ModalCreateQuincena
          year={year}
          number={number}
          startDate={startDate}
          endDate={endDate}
          processDate={processDate}
          paymentDate={paymentDate}
          setYear={setYear}
          setNumber={setNumber}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setProcessDate={setProcessDate}
          setPaymentDate={setPaymentDate}
        />
      );
    case 18:
    case 26:
    case 27:
      return <ModalCreateProfesion setClave={setClave} setName={setName} />;
    case 34:
      return <ModalCreateTabulator setTabulador={setTabulador} />;
    default:
      return null;
  }
};

export default SwitchModal;
