import React, { useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";

function Row(props) {
  const [selectedRow, setSelectedRow] = useState(false);
  function handleClick(i) {
    props.onClick(props.data);
    setSelectedRow(true);
    setTimeout(function () {
      setSelectedRow(false);
    }, 1700);
  }

  return (
    <TableRow>
      {props.headers.map((_, i) => {
        return (
          <TableCell
            key={`${i}-key-2`}
            onClick={() => handleClick(i)}
            align="center"
            style={{
              backgroundColor:
                props.data[i] === "Apartado"
                  ? "#bed7ea"
                  : props.selectedRowbyOnclick
                  ? selectedRow
                    ? "#f58a1d"
                    : "white"
                  : "white",
              color: props.selectedRowbyOnclick
                ? selectedRow
                  ? "white"
                  : "black"
                : "black",
              cursor: props.selectedRowbyOnclick
                ? selectedRow
                  ? "pointer"
                  : "pointer"
                : "default",
            }}
          >
            {props.data[i]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default Row;
