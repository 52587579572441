import { enqueueSnackbar } from "notistack";
export const validateInternalParameters = (createName, createDay, createMonth, createPayble, createAssistanceType, createCurrent) => {
    if (!createName || !createDay || !createMonth || !createPayble || !createAssistanceType || !createCurrent) {
        enqueueSnackbar("Por favor llenar todos los campos", {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    }
    if (createDay > 31 || createDay === 0){
        enqueueSnackbar("Formato dia invalido", {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    }
    if (createMonth > 12 || createMonth === 0){
        enqueueSnackbar("Formato mes invalido", {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    }
    return true
}