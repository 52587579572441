import React from "react";
import { Grid } from "@material-ui/core";
import CreateModalInputs from "../CreateModalInputs";
import ModalSelect from "../ModalSelect";

const CreateMateriaProfesioModal = ({
    setCreateCarrera,
    setCreateMateria,
    setCreateProfesion,
    setCreateObservaciones,
    profesionOptions,
    materiaOptions,
    carreraOptions,
    createObservaciones
}) => {
    return (
        <Grid style={{ padding: 20 }}>
            <ModalSelect
                label={'Carrera Origen'}
                options={carreraOptions}
                onChange={(event) => setCreateCarrera(event)}
            />
            <ModalSelect
                label={'Materia Legal'}
                options={materiaOptions}
                onChange={(event) => setCreateMateria(event)}
            />
            <ModalSelect
                label={'Profesion'}
                options={profesionOptions}
                onChange={(event) => setCreateProfesion(event)}
            />
            <CreateModalInputs label={'Observaciones'} inputValue={createObservaciones} onChange={setCreateObservaciones} disabled={false} />
            <CreateModalInputs label={'Estatus'} inputValue={'Vigente'} />
        </Grid>
    )
}

export default CreateMateriaProfesioModal