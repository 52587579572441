export const conceptoPEHeaders = [
    {
        accessorKey: 'clave',
        header: 'Clave',
        enableEditing: false,
        size: 10,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'descripcion',
        header: 'Descripción',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'tipo',
        header: 'Tipo',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'importe_maximo',
        header: 'Importe Máximo',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'mostrar_importe',
        header: 'Mostrar Importe',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'mostrar_hora_inicio',
        header: 'Mostrar hora de inicio',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'modificado_por',
        header: 'Modificado por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'modificado',
        header: 'Fecha Modificada',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'creado_por',
        header: 'Creado Por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'creado',
        header: 'Fecha Creado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'id',
        header: 'id',
        size: 10,
        enableEditing: false,
        enableGlobalFilter: true
    },
]