import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import AsyncSelect from "react-select/async";

const CreateModalAsyncSelect = ({
  label,
  promiseOptions,
  value,
  onChange,
  width = "",
}) => {
  const [typingTimeout, setTypingTimeout] = useState(null);

  // This function is called when the user types in the input field
  function handleInputChange(event) {
    return new Promise((resolve) => {
      const newValue = event;
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      const newTypingTimeout = setTimeout(() => {
        resolve(promiseOptions(newValue));
      }, 500);
      setTypingTimeout(newTypingTimeout);
    });
  }

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid item xs={12} sm={2} style={{ textAlign: "end", marginRight: 20 }}>
        <label>{label}:</label>
      </Grid>
      <Grid style={{ flexGrow: 1, width: width === "" ? "" : width }}>
        <AsyncSelect
          className="select-modal-container"
          classNamePrefix="select-modal"
          isClearable={true}
          onChange={onChange}
          value={value}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          styles={{
            maxWidth: 604,
            menu: (styles) => ({
              ...styles,
              zIndex: 999,
            }),
            indicatorsContainer: (styles) => ({
              ...styles,
            }),
          }}
          loadOptions={handleInputChange}
        />
      </Grid>
    </Grid>
  );
};

export default CreateModalAsyncSelect;
