import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import TextField from "@mui/material/TextField";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { useSelector } from "react-redux";
import "./index.css";
import SearchInput from "../../Utils/Visuales/SearchInput";

function ClasesEspeciales() {
  const heading = [
    "ciclo",
    "campus",
    "direccion",
    "escuela",
    "clave_profesor",
    "nombre_profesor",
    "grupo",
    "materia",
    "descripcion_materia",
    "fecha_clase",
    "tipo_clase",
    "fecha_creado",
    "horario",
    "salon",
    "estatus",
  ];

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 3,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 4,
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 5,
      width: "19%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 6,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "materia",
      col: 7,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      id: "descripcion_materia",
      col: 8,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Clase",
      id: "fecha_clase",
      col: 9,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Clase",
      id: "tipo_clase",
      col: 10,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Creado",
      id: "fecha_creado",
      col: 11,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      id: "horario",
      col: 12,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Salón",
      id: "salon",
      col: 13,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Estatus",
      id: "estatus",
      col: 14,
      width: "10%",
      align: "center",
      fn: null,
    },
  ];
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [optionsTipoClase, setOptionsTipoClase] = useState("");
  const [valueEscuela, setValueEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [valueMateria, setValueMateria] = useState("");
  const [valueTipoClase, setValueTipoClase] = useState("");
  const [valueGrupo, setValueGrupo] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [valueCampus, setValueCampus] = useState({
    label: "",
    value: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function handleClick() {
    let valido = true;
    if (valueCiclo === "" || valueCiclo === undefined) {
      enqueueSnackbar("Falta llenar campo ciclo ", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaInicio === "" || fechaInicio === undefined) {
      enqueueSnackbar("Falta llenar campo Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaFin === "" || fechaFin === undefined) {
      enqueueSnackbar("Falta llenar campo Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valido) {

      if (valueCampus?.value !== undefined) {

        setLoading(true);
        try {
          let url = `/reportes/clases-especiales?ciclo=${valueCiclo}&fi=${fechaInicio}&ff=${fechaFin}`;
          if (valueEscuela) url += `&id_escuela=${valueEscuela.value}`;
          if (valueClaveProfesor)
            url += `&id_profesor=${valueClaveProfesor.value}`;
          if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
          if (valueTipoClase) url += `&id_tipo_clase=${valueTipoClase.value}`;
          if (valueGrupo) url += `&grupo=${valueGrupo}`;
          if (valueMateria) url += `&materia=${valueMateria}`;
          if (valueCampus?.value !== undefined)
            url += `&id_campus=${valueCampus?.value}`;

          const consulta = url;
          const response = await getRequest(consulta);
          let rowsP = "";
          if (response.s === "OK") {
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            //data
            const array1 = [];
            const array2 = [];
            const arrayRows = [];
            for (let i = 0; i < response.d.length; i++) {
              const objp = Object.values(response.d[i]);
              array1[i] = objp;
            }

            const numRows_ = array1[0][0].length;

            for (let step = 0; step < numRows_; step++) {
              for (let i = 0; i < response.d.length; i++) {
                Object.values(response.d[i]).forEach((value) => {
                  let numj = i - i + step;
                  array2[i] = value[numj];
                });
              }
              arrayRows[step] = [...array2];
            }
            rowsP = arrayRows;
            setData(rowsP);
            setDataOriginal(rowsP);
            setLoading(false);
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setData([]);
            setDataOriginal([]);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }

        setShowTable(true);


      } else {
        setLoading(true);
        let datos = [];

        const diff = campusUser.length
        for (let i = 0; i < diff; i += 1) {
          console.log(campusUser[i])
          console.log(campusUser[i]?.value)
          try {
            let url = `/reportes/clases-especiales?ciclo=${valueCiclo}&fi=${fechaInicio}&ff=${fechaFin}`;
            if (valueEscuela) url += `&id_escuela=${valueEscuela.value}`;
            if (valueClaveProfesor)
              url += `&id_profesor=${valueClaveProfesor.value}`;
            if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
            if (valueTipoClase) url += `&id_tipo_clase=${valueTipoClase.value}`;
            if (valueGrupo) url += `&grupo=${valueGrupo}`;
            if (valueMateria) url += `&materia=${valueMateria}`;
              url += `&id_campus=${campusUser[i]?.value}`;

            const consulta = url;
            const response = await getRequest(consulta);
            let rowsP = "";
            if (response.s === "OK") {
              enqueueSnackbar(response.m, {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              //data
              const array1 = [];
              const array2 = [];
              const arrayRows = [];
              for (let i = 0; i < response.d.length; i++) {
                const objp = Object.values(response.d[i]);
                array1[i] = objp;
              }

              const numRows_ = array1[0][0].length;

              for (let step = 0; step < numRows_; step++) {
                for (let i = 0; i < response.d.length; i++) {
                  Object.values(response.d[i]).forEach((value) => {
                    let numj = i - i + step;
                    array2[i] = value[numj];
                  });
                }
                arrayRows[step] = [...array2];
              }
              rowsP = arrayRows;
              datos.push(...rowsP)

            } else {
              enqueueSnackbar(response.m, {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              setData([]);
              setDataOriginal([]);
              setLoading(false);
            }
          } catch (error) {
            console.log(error);
          }
        }
        console.log(datos);
        setData(datos);
        setDataOriginal(datos);
        setLoading(false);
        setShowTable(true);
      }
    }
    setLoading(false);
  }

  const consultarCatalogo = async (caltalogoId) => {
    try {
      const consulta = `/catalogo?id=${caltalogoId}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        if (caltalogoId === 22) {
          let id = response.d.elementos[0].id;
          let clave = response.d.elementos[1].clave;
          let nombre = response.d.elementos[2].name;
          for (let i = 0; i < id.length; i++) {
            data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
          }
        } else {
          let id = response.d.elementos[0].id;
          let clave = response.d.elementos[1].clave;
          let nombre = response.d.elementos[2].nombre;
          for (let i = 0; i < id.length; i++) {
            data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
          }
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(22).then((data) => setOptionsTipoClase(data));
    setValueCampus(
      campusUser?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  };

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        direccion: e[2],
        escuela: e[3],
        clave_profesor: e[4],
        nombre_profesor: e[5],
        grupo: e[6],
        materia: e[7],
        descripcion_materia: e[8],
        fecha_clase: e[9],
        tipo_clase: e[10],
        fecha_creado: e[11],
        horario: e[12],
        salon: e[13],
        estatus: e[14],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const totalBoxDataObject = {
      Normal: 0,
      Reposición: 0,
      Adicional: 0,
      "Reposición Adelantada": 0,
      Sustitución: 0,
      "Visita de Campo": 0,
      "Visitas Guiadas": 0,
      'Visita "Antropologica"': 0,
      "Reposición por Incapacidad": 0,
      "Sustitución por Incapacidad": 0,
      Incapacidad: 0,
      Festivo: 0,
      Revision: 0,
      "Asesoria CADE": 0,
      Paternidad: 0,
      "Sustitución por Paternidad": 0,
      schools: [],
    };
    data_.forEach((element) => {
      totalBoxDataObject[element.tipo_clase] =
        totalBoxDataObject[element.tipo_clase] + 1;
      if (!totalBoxDataObject.schools.includes(element.escuela)) {
        totalBoxDataObject.schools.push(element.escuela);
      }
    });
    const PDFHeader = headers.filter(
      (element) =>
        ["Ciclo", "Campus", "Escuela"].includes(element.name) === false
    );
    const doc = (
      <DocumentPDFTable
        metadata={PDFHeader}
        data={data_ ?? []}
        title={`Reportes Clases Especiales`}
        ciclo={valueCiclo}
        startDate={fechaInicio}
        endDate={fechaFin}
        escuela={valueEscuela.value === undefined ? "" : valueEscuela.label}
        direccion={
          valueDireccion.value === undefined ? "" : valueDireccion.label
        }
        teacherKey={
          valueClaveProfesor.value === undefined ? "" : valueClaveProfesor.label
        }
        totalBox={totalBoxDataObject}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_clases_especiales_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        direccion: e[2],
        escuela: e[3],
        clave_profesor: e[4],
        nombre_profesor: e[5],
        grupo: e[6],
        materia: e[7],
        descripcion_materia: e[8],
        fecha_clase: e[9],
        tipo_clase: e[10],
        fecha_creado: e[11],
        horario: e[12],
        salon: e[13],
        estatus: e[14],
      };
      return data2;
    });

    var today = new Date();
    var fecha = `${today.getFullYear()}${today.getMonth() + 1
      }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_clases_especiales_${fecha}_${hora}`, heading);
    setReq(false);
  };

  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Clases Especiales">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                xs={12}
                sm={1}
                style={{ textAlign: "end", marginLeft: "1.5%" }}
              >
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginLeft: "-0.5%" }}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                style={{ textAlign: "end", marginLeft: "-1%" }}
              >
                <label>Fecha Inicio(*):</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  className="filter-input-fecha"
                  label="Seleccionar fecha"
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => setFechaInicio(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Fecha Fin(*):</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  className="filter-input-fecha"
                  label="Seleccionar fecha"
                  type="date"
                  value={fechaFin}
                  onChange={(e) => setFechaFin(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Escuela:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueEscuela}
                  options={optionsEscuela}
                  onChange={setValueEscuela}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginLeft: "1%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginLeft: "-1%" }}
              >
                <label>Tipo Clase:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueTipoClase}
                  options={optionsTipoClase}
                  onChange={setValueTipoClase}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Grupo:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <input
                  className="filter-input"
                  value={valueGrupo}
                  onChange={(e) => setValueGrupo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Materia:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <input
                  className="filter-input"
                  value={valueMateria}
                  onChange={(e) => setValueMateria(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginLeft: "1.5%" }}
              >
                <label>Campus:</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginLeft: "-0.5%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusUser}
                  onChange={setValueCampus}
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Clave Profesor:</label>
              </Grid>
              <Grid item xs={12} sm={5} style={{ marginLeft: "-0.5%" }}>
                <AsyncSelect
                  className="select-modal-container input-claveProfesor clave-profesor-index0"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  onChange={setValueClaveProfesor}
                  placeholder="Buscar Profesor"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} spacing={2}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClick}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 190px)" }}>
          <DataTable headers={headers} data={data} loading={loading} paginate bordes />
        </div>
      </Contenedor>
    </>
  );
}

export default ClasesEspeciales;
