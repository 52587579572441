import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
const SimpleCheck = ({ isActive, label, onClick }) => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "auto",
        justifyContent: "space-between",
        cursor: "pointer",
        "&:hover": {
          bgcolor: "#DEEBFF",
        },
      }}
      onClick={onClick}
    >
      <Grid item sx={{ paddingLeft: 1 }}>
        <label style={{ cursor: "pointer" }}>{label}:</label>
      </Grid>
      <Grid item sx={{ width: "auto" }}>
        <Checkbox size="small" checked={isActive} />
      </Grid>
    </Grid>
  );
};

export default SimpleCheck;
