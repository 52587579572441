import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 30,
    paddingTop: 40,
    paddingBottom: 50
  },
  page_v: {
    paddingHorizontal: 30,
    paddingTop: 40,
    paddingBottom: 0
  },
  logo: {
    height: '40px',
    width: '100px',
    paddingBottom: '6px'
  },
  description: {
    borderLeftColor: 'gray',
    borderLeftWidth: 1,
    paddingLeft: 25,
    marginTop: 6,
    marginLeft: 4,
  },
  boxHeader: {
    height: 'auto',
    paddingVertical: 2.5,
    paddingHorizontal:2,
    //border: '1px solid #828282',
    borderRight: '1px',
    borderRightColor:'#828282',
    backgroundColor: '#026eb7',
    //justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    
    
  },
  cell: {
    color: '#ffffff',
    fontSize: 8,
    fontFamily: 'Bold'
  },
  cell_v: {
    color: '#ffffff',
    fontSize: 6,
    fontFamily: 'Bold'
  },
  rows: {
    color: '#000000',
    fontSize: 8,
    padding: 3,
    fontFamily: 'Regular',
    marginVertical: 'auto'
  },
  rows_v: {
    color: '#000000',
    fontSize: 6,
    padding: 2,
    fontFamily: 'Regular',
    marginVertical: 'auto'
  },
  boxRow: {
    borderRight: '1px',
    borderRightColor:'#828282',
    borderBottom: '1px',
    borderTopColor: '1px',
    height: 'auto',
    minHeight: "50px",
    backgroundColor: 'white',
    flexDirection: 'row',
  },
  boxRow_v: {
    borderRight: '1px',
    borderRightColor:'#828282',
    borderBottom: '1px',
    borderTopColor: '1px',
    height: 'auto',
    backgroundColor: 'white',
    flexDirection: 'row',
  },

})
