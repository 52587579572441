import React, { useState } from "react";
import { Grid } from "@material-ui/core";

const CreateModalDate = ({
  label,
  onChange,
  min = "",
  max = "",
  value = "",
  sm = 2,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const handleChange = (value) => {
    setInputValue(value);
    onChange(value);
  };
  return (
    <Grid
      container
      style={{ marginTop: 20, display: "flex", alignItems: "center" }}
    >
      <Grid
        item
        sm={sm}
        style={{
          textAlign: "start",
          marginRight: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <label>{label}:</label>
      </Grid>
      <Grid style={{ flexGrow: 1 }}>
        <input
          className="filter-input input-ciclo"
          label="Seleccionar fecha"
          type="date"
          value={inputValue}
          min={min}
          max={max}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default CreateModalDate;
