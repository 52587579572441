import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import Contenedor from "../../Utils/Visuales/Contenedor";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import moment from "moment";
import { generateCSV } from "../../Utils/generateCSV/generateCSV";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";

function GrupoLogistica() {
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [loading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [req, setReq] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [valueMateria, setValueMateria] = useState("");

  const headers = [
    {
      name: "Ciclo",
      id: "Ciclo",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Campus",
      id: "Campus",
      col: 1,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "Grupo",
      col: 2,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Clave Materia",
      id: "Clave Materia",
      col: 3,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Nombre Materia",
      id: "Nombre Materia",
      col: 4,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Dirección",
      id: "Dirección",
      col: 5,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Turno",
      id: "Turno",
      col: 6,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Definitivo",
      id: "Definitivo",
      col: 7,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Asignados",
      id: "Asignados",
      col: 8,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Lun",
      id: "Lun",
      col: 9,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Mar",
      id: "Mar",
      col: 10,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Mie",
      id: "Mie",
      col: 11,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Jue",
      id: "Jue",
      col: 12,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Vie",
      id: "Vie",
      col: 13,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Sab",
      id: "Sab",
      col: 14,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Salones",
      id: "Salones",
      col: 15,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Tipo Asistencia",
      id: "Tipo Asistencia",
      col: 16,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Tipo Grupo",
      id: "Tipo Grupo",
      col: 17,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Grupo Complementario",
      id: "Grupo Complementario",
      col: 18,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Profesor",
      id: "Profesor",
      col: 19,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Tipo Profesor",
      id: "Tipo Profesor",
      col: 20,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fechas",
      id: "Fechas",
      col: 21,
      fn: null,
      width: "15%",
      align: "center",
    },
  ];

  const handleGeneratedReport = async () => {
    const date = moment().format("DD/MM/YYYY");
    const dataTable = data.map((item) =>
      Object.fromEntries(item.map((_, i) => [headers[i].name, item[i]]))
    );
    await generateCSV({
      data: dataTable,
      fileName: `Reporte_grupo_logistica_${date}.csv`,
    });
  };

  useEffect(() => {
    const dummyData = [
      [
        "2022-3",
        "34-Marina",
        "PR03A",
        "TB401",
        "Matematicas IV",
        "951-Direccion1",
        "M",
        "60",
        "58",
        "09:00 - 11:00",
        "",
        "09:00 - 11:00",
        "",
        "",
        "",
        "A18, L20",
        "1-Periodo",
        "1-Normal",
        "N",
        "5263 - Torres, Francisco",
        "Titular",
        "",
      ],
      [
        "2022-3",
        "34-Marina",
        "PR02B",
        "TB401",
        "Matematicas IV",
        "951-Direccion1",
        "N",
        "55",
        "31",
        "",
        "09:00 - 11:00",
        "",
        "",
        "09:00 - 11:00",
        "",
        "A230, A230",
        "2-Fecha Determinada",
        "1-Normal",
        "N",
        "2563 - Lopez, Jonas",
        "Titular",
        "01/11/2022, 04/11/2022",
      ],
    ];

    setData(dummyData);
  }, []);

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const minChars = 5;
  const waitTime = 2000;
  let timer;

  async function searchMateria(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=materia&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            value: item.id,
            label: item.clave + " - " + item.nombre,
            name: item.nombre
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const promiseMateriaOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchMateria(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
  });

  function handleClick() {
    setShowTable(true);
  }

  const DownloadPDF = async () => {
    setReq(true);
    const date = moment().format("L");
    const hour = moment().format("LTS");
    const dataTable = data.map((item) =>
      Object.fromEntries(item.map((_, i) => [headers[i].name, item[i]]))
    );
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={dataTable ?? []}
        title={`Fecha Reporte: ${date} ${hour}`}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_grupo_logistica_${date}_${hour}.pdf`);
    setReq(false);
  };

  function downloadCsv() {
    setLoadingTable(true);
    handleGeneratedReport().finally(() => {
      setLoadingTable(false);
    });
  }
  return (
    <Contenedor title="Reportes / Reporte de Grupos de Logistica">
      <Grid container className="nuevoUsuarioForm" style={{ marginBottom: 30 }}>
        <Grid container item xs={12} sm={9}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={12} sm={2}>
              <input className="filter-input" />
            </Grid>
            <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
              <label>Campus(*):</label>
            </Grid>
            <Grid item xs={12} sm={3}>
              <input className="filter-input" />
            </Grid>
            <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
              <label>Grupo(*):</label>
            </Grid>
            <Grid item xs={12} sm={3}>
              <input className="filter-input" />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
              <label>Materia(*):</label>
            </Grid>
            <Grid item xs={12} sm={4}>
              <AsyncSelect
                className="select-modal-container input-claveProfesor clave-profesor-index0"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseMateriaOptions}
                value={valueMateria}
                onChange={setValueMateria}
                placeholder="Buscar Materia"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input className="filter-input" disabled />
            </Grid>
          </Grid>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={2} style={{ textAlign: "end" }}>
              <label>Profesor(*):</label>
            </Grid>
            <Grid item xs={12} sm={4}>
              <input className="filter-input" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input className="filter-input" disabled />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={3} spacing={2}>
          <Grid
            container
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
          >
            <EditButton onClick={handleClick} style={{ padding: "10px 25px" }}>
              Generar
            </EditButton>
            <div style={{ display: "flex" }} className="export-buttons">
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button
                  onClick={downloadCsv}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={excelIcon} alt="excel-icon" />
                </button>
                <button
                  onClick={DownloadPDF}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={pdfFileIcon} alt="pdf-file-icon" />
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {showTable && (
        <div style={{ height: "calc(100% - 175px)" }}>
          <DataTable headers={headers} data={data} loading={loading} paginate bordes/>
        </div>
      )}
    </Contenedor>
  );
}

export default GrupoLogistica;
