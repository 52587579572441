import NewModal from "../Visuales/NewModal";

const ConfirmationModal = ({
  message,
  setModal,
  modal,
  onSubmit,
  loading = false,
  height,
}) => {
  return (
    <NewModal
      title="Confirmación"
      open={modal}
      handleClose={() => {
        setModal(false);
      }}
      handleSubmit={onSubmit}
      height={height}
      loading={loading}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ textAlign: "center" }}>{message}</h2>
      </div>
    </NewModal>
  );
};

export default ConfirmationModal;
