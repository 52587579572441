import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Contenedor from "../../Utils/Visuales/Contenedor";
import DataTable from "../../Utils/DataTables";
import TextField from "@mui/material/TextField";
import NewModal from "../../Utils/Visuales/NewModal";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import NewButton from "../../Utils/Botones/NewButton";
import AsyncSelect from "react-select/async";
import EditButton from "../../Utils/Botones/EditButton";
import { checkIfJExists } from "../../Utils/Funciones/checkIfJExists";
import { dayOrder } from "../../Utils/Funciones/dayOrder";

function SalonesApartado() {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalNuevoIsOpen, setModalNuevoIsOpen] = useState(false);
  const [valueCiclo, setValueCiclo] = useState("");
  const [fechaInicialValue, setFechaInicialValue] = useState("");
  const [fechaFinalValue, setFechaFinalValue] = useState("");

  const [materiaValue, setMateriaValue] = useState("");
  const [salonOptions, setSalonOptions] = useState([]);
  const [selectSalonIsLoading, setSelectSalonIsLoading] = useState(false);
  const [tipoSalonOptions, setTipoSalonOptions] = useState([]);
  const [tipoSalon, setTipoSalon] = useState("");
  const [tipoSalonN, setTipoSalonN] = useState("");
  const [salon, setSalon] = useState("");
  const [salonN, setSalonN] = useState("");

  const campusName = useSelector((state) => state.campusName);
  const campusId = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);
  const [profesorNValue, setProfesorNValue] = useState("");
  const [fechaInicialN, setFechaInicialN] = useState("");
  const [fechaFinalN, setFechaFinalN] = useState("");
  const [horaIniciaValues, setHoraIniciaValues] = useState("");
  const [minutosIniciaValues, setMinutosIniciaValues] = useState("");
  const [horaTerminaValues, setHoraTerminaValues] = useState("");
  const [minutosTerminaValues, setMinutosTerminaValues] = useState("");
  const [diasForm, setDiasForm] = useState([]);
  const [grupoValueN, setGrupoValueN] = useState("");
  const [materiaValueN, setMateriaValueN] = useState("");
  const [materiaNOptions, setMateriaNOptions] = useState("");
  const [motivoOptions, setMotivoOptions] = useState([]);
  const [selectMotivoIsLoading, setSelectMotivoIsLoading] = useState(false);
  const [modalHabilitarEstatusIsOpen, setModalHabilitarEstatusIsOpen] =
    useState(false);
  const [motivo, setMotivo] = useState("");
  const [comentario, setComentario] = useState("");
  const [capacidad, setCapacidad] = useState("");
  const [grupo, setGrupo] = useState("");
  const [horaIniciaValuesN, setHoraIniciaValuesN] = useState("");
  const [minutosIniciaValuesN, setMinutosIniciaValuesN] = useState("");
  const [horaTerminaValuesN, setHoraTerminaValuesN] = useState("");
  const [minutosTerminaValuesN, setMinutosTerminaValuesN] = useState("");
  const [idEstatusHabilitar, setIdEstatusHabilitar] = useState("");
  let grupoTimer;

  const selectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  function openModalNuevo() {
    setModalNuevoIsOpen(true);
    setLoadingModal(true);
    setLoadingModal(false);
  }

  const HandleHabilitarEstatus = (id) => {
    setModalHabilitarEstatusIsOpen(true);
    setIdEstatusHabilitar(id);
    console.log(id);
  };

  const handleSubmitHabilitarEstatus = async () => {
    setLoadingModal(true);
    let json = {
      request_id: idEstatusHabilitar,
    };
    try {
      const response = await postRequest(
        "/salones/cancelar_apartado_salon",
        json
      );

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        GetDataTable();
        setLoadingModal(false);
        setModalHabilitarEstatusIsOpen(false);
      } else {
        setLoadingModal(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetDataTable = async () => {
    let valido = true;
    if (fechaInicialValue === "") {
      enqueueSnackbar("Falta Seleccionar Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaFinalValue === "") {
      enqueueSnackbar("Falta Seleccionar Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    let nroFechaInicial = parseInt(
      fechaInicialValue.substring(0, 4) +
        fechaInicialValue.substring(5, 7) +
        fechaInicialValue.substring(8, 10)
    );
    let nroFechaFinal = parseInt(
      fechaFinalValue.substring(0, 4) +
        fechaFinalValue.substring(5, 7) +
        fechaFinalValue.substring(8, 10)
    );
    if (nroFechaInicial > nroFechaFinal) {
      enqueueSnackbar("Fecha Inicial es superior a Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    let horarios = false;
    if (
      horaIniciaValues &&
      minutosIniciaValues &&
      horaTerminaValues &&
      minutosTerminaValues
    ) {
      if (
        horaIniciaValues > horaTerminaValues ||
        (horaIniciaValues === horaTerminaValues &&
          minutosIniciaValues > minutosTerminaValues)
      ) {
        enqueueSnackbar(
          "El Horario Inicial es Igual o Superior al Horario Final",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }
        );
        valido = false;
      } else {
        horarios = true;
      }
    }
    if (valido) {
      setLoading(true);
      try {
        let optionalParameters = "";
        if (valueCiclo) optionalParameters += `&ciclo=${valueCiclo}`;
        if (horarios) {
          let horaInicial = "";
          let horaFinal = "";
          let minutosInicial = "";
          let minutosFinal = "";
          horaIniciaValues.length < 2
            ? (horaInicial = `0${horaIniciaValues}`)
            : (horaInicial = horaIniciaValues);
          minutosIniciaValues.length < 2
            ? (minutosInicial = `0${minutosIniciaValues}`)
            : (minutosInicial = minutosIniciaValues);
          horaTerminaValues.length < 2
            ? (horaFinal = `0${horaTerminaValues}`)
            : (horaFinal = horaTerminaValues);
          minutosTerminaValues.length < 2
            ? (minutosFinal = `0${minutosTerminaValues}`)
            : (minutosFinal = minutosTerminaValues);
          optionalParameters += `&hi=${horaInicial}:${minutosInicial}&hf=${horaFinal}:${minutosFinal}`;
        }
        if (grupo) optionalParameters += `&grupo=${grupo}`;
        if (tipoSalon)
          optionalParameters += `&id_tipo_salon=${tipoSalon.value}`;
        if (salon) optionalParameters += `&id_salon=${salon.value}`;
        if (materiaValue) optionalParameters += `&materia=${materiaValue}`;
        let endpoint =
          `/salones/apartado?fi=${fechaInicialValue}&ff=${fechaFinalValue}&id_campus=${campusId}` +
          optionalParameters;
        const response = await getRequest(endpoint);

        if (response.s === "OK") {
          if (response.d.length === 0) {
            enqueueSnackbar("No se encontraron datos", {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setData([]);
          } else {
            const datos = [];
            const largo = response.d.fecha_inicio.length;
            for (let i = 0; i < largo; i++) {
              datos.push([
                response.d.fecha_inicio[i],
                response.d.fecha_fin[i],
                response.d.horario[i],
                response.d.dias[i],
                response.d.salon[i],
                response.d.motivo[i],
                response.d.grupo[i],
                response.d.materia[i],
                response.d.profesor[i],
                response.d.capacidad[i],
                response.d.comentario[i],
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <EditButton
                    onClick={() => {
                      HandleHabilitarEstatus(response.d.request_id[i]);
                    }}
                    disabled={!response.d.estatus[i]}
                  >
                    Cancelar
                  </EditButton>
                </div>,
              ]);
            }
            setData(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  };

  const heading = [
    "fecha_inicio",
    "fecha_fin",
    "horario",
    "dias",
    "salon",
    "motivo",
    "grupo",
    "materia",
    "profesor",
    "capacidad",
    "comentario",
  ];

  const headers = [
    {
      name: "Fecha Inicio",
      col: 0,
      id: "fecha_inicio",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Fin",
      col: 1,
      id: "fecha_fin",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      col: 2,
      id: "horario",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Días",
      col: 3,
      id: "dias",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Salón",
      col: 4,
      id: "salon",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Motivo",
      col: 5,
      id: "motivo",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 6,
      id: "grupo",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Materia",
      col: 7,
      id: "materia",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Profesor",
      col: 8,
      id: "profesor",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Capacidad",
      col: 9,
      id: "capacidad",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Comentario",
      col: 10,
      id: "comentario",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Acciones",
      col: 11,
      id: "acciones",
      width: "20%",
      align: "center",
      fn: null,
    },
  ];

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        fecha_inicio: e[0],
        fecha_fin: e[1],
        horario: e[2],
        dias: e[3],
        salon: e[4],
        motivo: e[5],
        grupo: e[6],
        materia: e[7],
        profesor: e[8],
        capacidad: e[9],
        comentario: e[10],
      };
      return data2;
    });
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={"Fecha Reporte: 30/11/2022 15:33:33"}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();

    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    saveAs(blob, `Salones_Apartado_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        fecha_inicio: e[0],
        fecha_fin: e[1],
        horario: e[2],
        dias: e[3],
        salon: e[4],
        motivo: e[5],
        grupo: e[6],
        materia: e[7],
        profesor: e[8],
        capacidad: e[9],
        comentario: e[10],
      };

      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Salones_Apartado_${fecha}_${hora}`, heading);
    setReq(false);
  };

  async function searchMateria(id_campus, id_person, grupo) {
    try {
      var options = [];
      // const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${id_person},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${id_person.value},grupo:${grupo}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
          });
        });
        if (options.length === 0) {
          enqueueSnackbar(
            "El grupo ingresado no encuentra materias asignadas",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
        }
        let hash = {};
        options = options.filter((o) =>
          hash[o.label] ? false : (hash[o.label] = true)
        );
        setMateriaNOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const asyncSelectStylesTipoSalon = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
    menuList: (base) => ({
      ...base,
      maxHeight: "260px",
    }),
  };

  async function getTipoSalonCatalogo() {
    try {
      const endpoint = `/catalogo?id=24&id_campus=${campusId}`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["type"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setTipoSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  async function getSalonOptions(id, modal = false) {
    setSelectSalonIsLoading(true);
    try {
      if (modal) {
        if (fechaInicialN === "") {
          enqueueSnackbar("Se debe de selecionar una fecha inicial", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        } else if (fechaFinalN === "") {
          enqueueSnackbar("Se debe de selecionar una fecha final", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        } else if (diasForm.length === 0) {
          enqueueSnackbar("Se debe de selecionar al menos un dia", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        } else if (horaIniciaValuesN === "" || minutosIniciaValuesN === "") {
          enqueueSnackbar("Se debe de selecionar una hora y minuto inicial", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        } else if (horaTerminaValuesN === "" || minutosTerminaValuesN === "") {
          enqueueSnackbar("Se debe de selecionar una hora y minuto final", {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        }
      }
      const endpoint = `/buscar?tipo=salon&valor=id:${id},id_campus:${campusId}`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const horaIinciaN =
          horaIniciaValuesN < 10
            ? `0${horaIniciaValuesN}`
            : `${horaIniciaValuesN}`;
        const minutosIinciaN =
          minutosIniciaValuesN < 10
            ? `0${minutosIniciaValuesN}`
            : `${minutosIniciaValuesN}`;
        const horaTerminaN =
          horaTerminaValuesN < 10
            ? `0${horaTerminaValuesN}`
            : `${horaTerminaValuesN}`;
        const minutosTerminaN =
          minutosTerminaValuesN < 10
            ? `0${minutosTerminaValuesN}`
            : `${minutosTerminaValuesN}`;

        if (modal) {
          const horasInicio = diasForm.map(() => {
            return `${horaIinciaN}:${minutosIinciaN}`;
          });
          const horasFin = diasForm.map(() => {
            return `${horaTerminaN}:${minutosTerminaN}`;
          });
          const horarios = `dias: ${diasForm.join(
            ","
          )} horas_inicio: ${horasInicio.join(",")} horas_fin: ${horasFin.join(
            ","
          )}`;
          try {
            const request = `/reportes/salones_ocupados?fecha_inicial=${fechaInicialN}&fecha_final=${fechaFinalN}&id_campus=${campusId}&horario=${horarios}`;
            const response2 = await getRequest(request);
            if (response2.s === "OK") {
              const busyClassroomsInitial = [];
              response2.d.forEach((responseItem) => {
                const found = diasForm.some((item) =>
                  checkIfJExists(
                    `${horaIinciaN}:${minutosIinciaN}`,
                    `${horaTerminaN}:${minutosTerminaN}`,
                    responseItem.timeslots,
                    item[0].toLowerCase() === "miercoles"
                      ? "Mi"
                      : item[0].toUpperCase()
                  )
                );
                if (found) {
                  busyClassroomsInitial.push(responseItem.id_classroom);
                }
              });
              const dataObjectInicio = response.d
                .map((item, i) => {
                  if (!busyClassroomsInitial.includes(`${item.id_salon}`)) {
                    const data_ = {
                      label: item.id_salon + " - " + item.nombre,
                      value: item.id_salon,
                      capacidad: item.capacidad,
                    };
                    return data_;
                  }
                  return null;
                })
                .filter((item) => item !== null);
              setSalonOptions(dataObjectInicio);
            } else {
              enqueueSnackbar(response2.m, {
                variant: "error",
                autoHideDuration: null,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
            }
          } catch (error) {
            console.error(error);
            enqueueSnackbar(error, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } else {
          response.d.forEach((item) => {
            data.push({
              label: item.id_salon + " - " + item.nombre,
              value: item.id_salon,
              capacidad: item.capacidad,
            });
          });
          setSalonOptions(data);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectSalonIsLoading(false);
    }
  }
  function handleChangeTipoSalon(ev) {
    setTipoSalon(ev);
    setSalon("");
    getSalonOptions(ev.value);
  }

  function handleChangeTipoSalonN(ev) {
    setTipoSalonN(ev);
    setSalonN("");
    setCapacidad("");
    getSalonOptions(ev.value, true);
  }

  function handleChangeSalon(ev) {
    setSalon(ev);
  }

  async function handleChangeSalonN(ev) {
    if (diasForm.length === 0) {
      enqueueSnackbar("Debes seleccionar al menos un Dias", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (
      horaIniciaValuesN === "" ||
      minutosIniciaValuesN === "" ||
      minutosTerminaValuesN === "" ||
      minutosTerminaValuesN === ""
    ) {
      enqueueSnackbar("Debes seleccionar un una hora incial y hora final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (fechaInicialN === "" || fechaFinalN === "") {
      enqueueSnackbar("Debes seleccionar un una fecha inicial y fecha fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      const horaIinciaN =
        horaIniciaValuesN < 10
          ? `0${horaIniciaValuesN}`
          : `${horaIniciaValuesN}`;
      const minutosIinciaN =
        minutosIniciaValuesN < 10
          ? `0${minutosIniciaValuesN}`
          : `${minutosIniciaValuesN}`;
      const horaTerminaN =
        horaTerminaValuesN < 10
          ? `0${horaTerminaValuesN}`
          : `${horaTerminaValuesN}`;
      const minutosTerminaN =
        minutosTerminaValuesN < 10
          ? `0${minutosTerminaValuesN}`
          : `${minutosTerminaValuesN}`;
      for (let i in diasForm) {
        const request = `/buscar?tipo=verificar_salon&valor=id_salon:${ev.value},id_campus:${campusId},fecha_inicio:${fechaInicialN},fecha_fin:${fechaFinalN},hora_inicio:${horaIinciaN},hora_fin:${horaTerminaN},minuto_inicio:${minutosIinciaN},minuto_fin:${minutosTerminaN},dia:${diasForm[i]}`;
        const response = await getRequest(request);
        if (response.s === "OK") {
          setSalonN(ev);
          setCapacidad(ev.capacidad);
        } else {
          setSalonN("");
          setCapacidad("");
          enqueueSnackbar(`${response.m} día ${diasForm[i]}`, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          break;
        }
      }
      console.log();
    }
  }

  useEffect(() => {
    getTipoSalonCatalogo();
    getMotivoCatalogo();
  }, []);

  async function getMotivoCatalogo() {
    setSelectMotivoIsLoading(true);
    try {
      const endpoint = `/catalogo?id=16`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nMotivos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nMotivos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setMotivoOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMotivoIsLoading(false);
    }
  }

  useEffect(() => {
    clearTimeout(grupoTimer);
    if (grupoValueN.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(campusId, profesorNValue, grupoValueN);
      }, waitTime);
    }
  }, [campusId, profesorNValue, grupoValueN]);

  function handleChangeMotivo(ev) {
    setMotivo(ev);
  }

  async function handleSubmitNuevoApartado() {
    setLoadingModal(true);
    if (
      fechaInicialN === "" ||
      fechaFinalN === "" ||
      horaIniciaValuesN === "" ||
      minutosIniciaValuesN === "" ||
      horaTerminaValuesN === "" ||
      minutosTerminaValuesN === "" ||
      diasForm.length === 0 ||
      tipoSalonN === "" ||
      salonN === "" ||
      motivo === "" ||
      comentario === ""
    ) {
      enqueueSnackbar(
        "Todos los campos excepto Clave Profesor, Grupo y Materia son obligatorios",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      setLoadingModal(false);
      return;
    }
    const fechaCompInicial = new Date(fechaInicialN); // Primera fecha
    const fechaCompFinal = new Date(fechaFinalN);
    if (fechaCompFinal < fechaCompInicial) {
      enqueueSnackbar("La Fecha Inicial es Superior a la Final", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setLoadingModal(false);
      return;
    }
    const initialHour = new Date(
      2024,
      0,
      1,
      horaIniciaValuesN,
      minutosIniciaValuesN
    );
    const finalHour = new Date(
      2024,
      0,
      1,
      horaTerminaValuesN,
      minutosTerminaValuesN
    );
    if (
      initialHour > finalHour ||
      initialHour.getTime() === finalHour.getTime()
    ) {
      enqueueSnackbar(
        "El Horario Inicial es Igual o Superior al Horario Final",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      setLoadingModal(false);
      return;
    }
    const diferencia =
      1 +
      Math.floor((fechaCompFinal - fechaCompInicial) / (1000 * 60 * 60 * 24));
    if (diferencia < 7) {
      const nombresDias = [
        "lunes",
        "martes",
        "miercoles",
        "jueves",
        "viernes",
        "sabado",
        "domingo",
      ];
      const diasSeleccionados = [];
      for (
        let fecha = fechaCompInicial;
        fecha <= fechaCompFinal;
        fecha.setDate(fecha.getDate() + 1)
      ) {
        const diaSemana = nombresDias[fecha.getDay()];
        diasSeleccionados.push(diaSemana);
      }
      for (let i = 0; i < diasForm.length; i++) {
        let encontrado = false;
        diasSeleccionados.forEach((item) => {
          if (diasForm[i] === item) encontrado = true;
        });
        if (encontrado === false) {
          enqueueSnackbar(
            "Uno de los Días no está dentro del rango de fechas seleccionado",
            {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          setLoadingModal(false);
          return;
        }
      }
    }
    try {
      const horaInicial =
        horaIniciaValuesN < 10
          ? `0${horaIniciaValuesN}`
          : `${horaIniciaValuesN}`;
      const minutosInicial =
        minutosIniciaValuesN < 10
          ? `0${minutosIniciaValuesN}`
          : `${minutosIniciaValuesN}`;
      const horaFinal =
        horaTerminaValuesN < 10
          ? `0${horaTerminaValuesN}`
          : `${horaTerminaValuesN}`;
      const minutosFinal =
        minutosTerminaValuesN < 10
          ? `0${minutosTerminaValuesN}`
          : `${minutosTerminaValuesN}`;
      let json = {
        id_campus: campusId,
        fi: fechaInicialN,
        ff: fechaFinalN,
        hi: `${horaInicial}:${minutosInicial}`,
        hf: `${horaFinal}:${minutosFinal}`,
        dias: diasForm,
        id_salon: salonN.value,
        id_person: profesorNValue === "" ? "" : profesorNValue.value,
        id_motivo: motivo.value,
        comentario: comentario,
      };
      if (profesorNValue) json.id_profesor = profesorNValue.value;
      if (grupoValueN) json.grupo = grupoValueN;
      if (materiaValueN) json.id_assignment = materiaValueN.value;
      const response = await postRequest("/salones/apartado", json);
      if (response.s === "OK") {
        setProfesorNValue("");
        setFechaInicialN("");
        setFechaFinalN("");
        setHoraIniciaValuesN("");
        setMinutosIniciaValuesN("");
        setHoraTerminaValuesN("");
        setMinutosTerminaValuesN("");
        setDiasForm([]);
        setTipoSalonN("");
        setSalonN("");
        setCapacidad("");
        setGrupoValueN("");
        setMateriaValueN("");
        setMotivo("");
        setComentario("");
        setModalNuevoIsOpen(false);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingModal(false);
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            name: item.nombre,
            nss: item.nss,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function handleHoraValue(value, type) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      if (type === "i") {
        setHoraIniciaValues(value);
      } else {
        setHoraTerminaValues(value);
      }
    }
  }

  function handleMinutosValue(value, type) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      if (type === "i") {
        setMinutosIniciaValues(value);
      } else {
        setMinutosTerminaValues(value);
      }
    }
  }

  function handleGrupoValue(value) {
    if (value.length < 11) {
      setGrupo(value);
    }
  }

  function handleGrupoValueN(value) {
    if (value.length < 11) {
      setGrupoValueN(value);
      setMateriaNOptions([]);
      setMateriaValueN("");
    }
  }

  function handleMateriaValue(value) {
    if (value.length < 11) {
      setMateriaValue(value);
    }
  }

  function handleHoraValueN(value, type) {
    setSalonN("");
    setCapacidad("");
    setTipoSalonN("");
    if ((value >= 0 && value <= 23 && value.length < 3) || value === "") {
      if (type === "i") {
        setHoraIniciaValuesN(value === "" ? "" : parseInt(value));
      } else {
        setHoraTerminaValuesN(value === "" ? "" : parseInt(value));
      }
    }
  }

  function handleMinutosValueN(value, type) {
    setSalonN("");
    setCapacidad("");
    setTipoSalonN("");
    if ((value >= 0 && value <= 59 && value.length < 3) || value === "") {
      if (type === "i") {
        setMinutosIniciaValuesN(value === "" ? "" : parseInt(value));
      } else {
        setMinutosTerminaValuesN(value === "" ? "" : parseInt(value));
      }
    }
  }

  function handleDias(checked, dia) {
    setSalonN("");
    setCapacidad("");
    setTipoSalonN("");
    setDiasForm((prev) => {
      const days = [...prev];
      if (checked) {
        days.push(dia);
        const sortedDays = days.sort((a, b) => dayOrder(a) - dayOrder(b));
        return sortedDays;
      } else {
        return days.filter((item) => item !== dia);
      }
    });
  }

  return (
    <>
      <Contenedor title="Salones / Apartado">
        <Grid style={{ padding: 10 }}>
          <Grid
            container
            spacing={1}
            style={{ padding: "10px 0px", alignItems: "center" }}
            className="nuevoUsuarioForm"
          >
            <Grid item xs={1}>
              <NewButton
                noIcon
                style={{
                  display:
                    permisos["Salones->Apartado"].escribir === "1"
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  openModalNuevo();
                }}
              />
            </Grid>

            <Grid item xs={1} style={{ marginLeft: "-4%" }}>
              <label style={{ float: "right" }}>Ciclo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                onChange={(e) => setValueCiclo(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} style={{ marginLeft: "0.2%" }}>
              <label style={{ float: "right" }}>Fecha Inicial(*):</label>
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="select-modal input-ciclo"
                type="date"
                onChange={(e) => setFechaInicialValue(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .notchedOutlinet": {
                    border: "none !important",
                  },
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Fecha Final(*):</label>
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "-1.5%" }}>
              <TextField
                className="select-modal input-ciclo"
                type="date"
                onChange={(e) => setFechaFinalValue(e.target.value)}
                disabled={fechaInicialValue !== "" ? false : true}
                InputProps={{
                  inputProps: { min: fechaInicialValue, shrink: true },
                }}
                sx={{
                  "& .notchedOutlinet": {
                    border: "none !important",
                  },
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "-4%" }}>
              <NewButton
                style={{ float: "right" }}
                customText="Buscar"
                noIcon
                onClick={() => GetDataTable()}
              />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "-4%" }}>
              <label style={{ float: "right" }}>Hora Inicial:</label>
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex", alignItem: "center" }}>
                <input
                  className="filter-input"
                  type="number"
                  style={{ width: "50%" }}
                  min="0"
                  max="23"
                  value={horaIniciaValues}
                  onChange={(e) => handleHoraValue(e.target.value, "i")}
                />
                <label>:</label>
                <input
                  className="filter-input"
                  style={{ marginLeft: "2%", width: "50%" }}
                  type="number"
                  min="0"
                  max="59"
                  value={minutosIniciaValues}
                  onChange={(e) => handleMinutosValue(e.target.value, "i")}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              style={{ marginLeft: "-1.5%", marginRight: "1.7%" }}
            >
              <label style={{ float: "right" }}>Hora Final:</label>
            </Grid>
            <Grid item xs={2}>
              <div style={{ display: "flex" }}>
                <input
                  className="filter-input"
                  type="number"
                  style={{ width: "50%" }}
                  min="0"
                  max="23"
                  value={horaTerminaValues}
                  onChange={(e) => handleHoraValue(e.target.value, "t")}
                />
                <label>:</label>
                <input
                  className="filter-input"
                  style={{ marginLeft: "2%", width: "50%" }}
                  type="number"
                  min="0"
                  max="59"
                  value={minutosTerminaValues}
                  onChange={(e) => handleMinutosValue(e.target.value, "t")}
                />
              </div>
            </Grid>
            <Grid item xs={1} style={{ marginRight: "-1.5%" }}>
              <label>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                onChange={(e) => handleGrupoValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2} style={{ marginLeft: "-4%" }}>
              <label style={{ float: "right" }}>Tipo de Salón:</label>
            </Grid>
            <Grid item xs={2} style={{ zIndex: 90 }}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={tipoSalonOptions}
                styles={selectStyles}
                onChange={handleChangeTipoSalon}
                value={tipoSalon}
                isClearable={true}
              />
            </Grid>

            <Grid
              item
              xs={1}
              style={{ marginLeft: "1%", marginRight: "-0.8%" }}
            >
              <label>Salón:</label>
            </Grid>
            <Grid item xs={2} style={{ zIndex: 90 }}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={salonOptions}
                styles={selectStyles}
                isLoading={selectSalonIsLoading}
                onChange={handleChangeSalon}
                value={salon}
                isClearable={true}
              />
            </Grid>
            <Grid item xs={1} style={{ marginRight: "-1.5%" }}>
              <label>Materia:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                value={materiaValue}
                onChange={(e) => handleMateriaValue(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Grid
              container
              style={{ padding: "10px 0px", margin: 0 }}
              text="end"
              className="nuevoUsuarioForm"
            ></Grid>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "-5.5%" }}>
            <Grid container justifyContent="center">
              <Grid style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadPDF}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 190px)" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            paginate
            bordes
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          />
        </div>
        <NewModal
          title="Apartado de Salones"
          open={modalHabilitarEstatusIsOpen}
          handleClose={() => setModalHabilitarEstatusIsOpen(false)}
          loading={loadingModal}
          height={130}
          maxWidth={400}
          handleSubmit={handleSubmitHabilitarEstatus}
        >
          <div style={{ padding: "10px" }} className="nuevoUsuarioForm">
            <p style={{ fontSize: "30px" }}>¿Desea cancelar registro?</p>
          </div>
        </NewModal>

        <NewModal
          title="Apartado de Salones"
          open={modalNuevoIsOpen}
          handleClose={() => {
            setProfesorNValue("");
            setFechaInicialN("");
            setFechaFinalN("");
            setHoraIniciaValuesN("");
            setMinutosIniciaValuesN("");
            setHoraTerminaValuesN("");
            setMinutosTerminaValuesN("");
            setDiasForm([]);
            setTipoSalonN("");
            setSalonN("");
            setCapacidad("");
            setGrupoValueN("");
            setMateriaValueN("");
            setMotivo("");
            setComentario("");
            setModalNuevoIsOpen(false);
          }}
          loading={loadingModal}
          height={540}
          maxWidth={900}
          handleSubmit={handleSubmitNuevoApartado}
        >
          <div style={{ padding: "10px" }} className="nuevoUsuarioForm">
            <Grid container spacing={1} sx={12}>
              <Grid
                item
                xs={1}
                style={{ marginLeft: "2.8%", marginRight: "-2.5%" }}
              >
                <label>Campus:</label>
              </Grid>
              <Grid item xs={3} style={{ marginLeft: "3.5%" }}>
                <input
                  className="filter-input"
                  style={{ width: "85%" }}
                  disabled
                  value={campusName}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                container
                style={{ justifyContent: "flex-end", marginLeft: "-6.5%" }}
              >
                <label>Fecha Inicial(*):</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <TextField
                  className="select-modal input-ciclo"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ width: "115%", marginLeft: "-5%" }}
                  onChange={(e) => {
                    setFechaInicialN(e.target.value);
                    setSalonN("");
                    setCapacidad("");
                    setTipoSalonN("");
                  }}
                  value={fechaInicialN}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                container
                style={{ justifyContent: "flex-end", marginLeft: "-3%" }}
              >
                <label>Fecha Final(*):</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <TextField
                  className="select-modal input-ciclo"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ width: "115%", marginLeft: "-5%" }}
                  onChange={(e) => {
                    setFechaFinalN(e.target.value);
                    setSalonN("");
                    setCapacidad("");
                    setTipoSalonN("");
                  }}
                  value={fechaFinalN}
                />
              </Grid>
              <Grid
                xs={2}
                item
                container
                style={{
                  marginTop: "2%",
                  marginLeft: "-1.3%",
                  marginRight: "-7.5%",
                }}
              >
                <label>Hora Inicial(*):</label>
              </Grid>
              <Grid item xs={3} style={{ marginLeft: "3%", marginTop: "2%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="filter-input"
                    type="number"
                    style={{ width: "30%" }}
                    min="0"
                    max="23"
                    value={horaIniciaValuesN}
                    onChange={(e) => handleHoraValueN(e.target.value, "i")}
                  />
                  <label>:</label>
                  <input
                    className="filter-input"
                    style={{ marginLeft: "1%", width: "30%" }}
                    type="number"
                    min="0"
                    max="59"
                    // placeholder="00"
                    value={minutosIniciaValuesN}
                    onChange={(e) => handleMinutosValueN(e.target.value, "i")}
                  />
                </div>
              </Grid>
              <Grid
                xs={2}
                item
                container
                style={{
                  marginTop: "2%",
                  marginLeft: "-1.3%",
                  marginRight: "-7.5%",
                }}
              >
                <label>Hora Final(*):</label>
              </Grid>
              <Grid item xs={3} style={{ marginLeft: "3.5%", marginTop: "2%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="filter-input"
                    type="number"
                    style={{ width: "30%" }}
                    min="0"
                    max="23"
                    // placeholder="23"
                    value={horaTerminaValuesN}
                    onChange={(e) => handleHoraValueN(e.target.value, "t")}
                  />
                  <label>:</label>
                  <input
                    className="filter-input"
                    style={{ marginLeft: "1%", width: "30%" }}
                    type="number"
                    min="0"
                    max="59"
                    // placeholder="00"
                    value={minutosTerminaValuesN}
                    onChange={(e) => handleMinutosValueN(e.target.value, "t")}
                  />
                </div>
              </Grid>
              <Grid xs={3} />
              <Grid
                xs={2}
                item
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-4.5%",
                }}
              >
                <label>Días(*):</label>
              </Grid>
              <Grid
                item
                xs={7}
                style={{ marginLeft: "0.5%", marginTop: "2%", display: "flex" }}
              >
                <input
                  id="lock"
                  type="checkbox"
                  checked={diasForm.some((day) => day === "lunes")}
                  onChange={(e) => handleDias(e.target.checked, "lunes")}
                />
                <label htmlFor="lock">Lunes</label>
                <input
                  id="lock"
                  style={{ marginLeft: "3%" }}
                  type="checkbox"
                  checked={diasForm.some((day) => day === "martes")}
                  onChange={(e) => handleDias(e.target.checked, "martes")}
                />
                <label htmlFor="lock">Martes</label>
                <input
                  id="lock"
                  style={{ marginLeft: "3%" }}
                  type="checkbox"
                  checked={diasForm.some((day) => day === "miercoles")}
                  onChange={(e) => handleDias(e.target.checked, "miercoles")}
                />
                <label htmlFor="lock">Miércoles</label>
                <input
                  id="lock"
                  style={{ marginLeft: "3%" }}
                  type="checkbox"
                  checked={diasForm.some((day) => day === "jueves")}
                  onChange={(e) => handleDias(e.target.checked, "jueves")}
                />
                <label htmlFor="lock">Jueves</label>
                <input
                  id="lock"
                  style={{ marginLeft: "3%" }}
                  type="checkbox"
                  checked={diasForm.some((day) => day === "viernes")}
                  onChange={(e) => handleDias(e.target.checked, "viernes")}
                />
                <label htmlFor="lock">Viernes</label>
                <input
                  id="lock"
                  style={{ marginLeft: "3%" }}
                  type="checkbox"
                  checked={diasForm.some((day) => day === "sabado")}
                  onChange={(e) => handleDias(e.target.checked, "sabado")}
                />
                <label htmlFor="lock">Sábado</label>
                {/* <FormControl onChange={(e) => setDiasForm(e.target.value)}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="nuevoFormularioLabel"
                  >
                    <FormControlLabel
                      value="lunes"
                      control={<Radio />}
                      label="Lunes"
                    />
                    <FormControlLabel
                      value="martes"
                      control={<Radio />}
                      label="Martes"
                      style={{ marginLeft: "-0.5%" }}
                    />
                    <FormControlLabel
                      value="miercoles"
                      control={<Radio />}
                      label="Miércoles"
                      style={{ marginLeft: "-0.5%" }}
                    />
                    <FormControlLabel
                      value="viernes"
                      control={<Radio />}
                      label="Viernes"
                      style={{ marginLeft: "-0.5%" }}
                    />
                    <FormControlLabel
                      value="sabado"
                      control={<Radio />}
                      label="Sábado"
                      style={{ marginLeft: "-0.5%" }}
                    />
                  </RadioGroup>
                </FormControl> */}
              </Grid>
              <Grid item xs={3} />
              <Grid
                item
                xs={2}
                container
                style={{
                  justifyContent: "flex-end",
                  marginLeft: "-4.5%",
                  marginTop: "2%",
                }}
              >
                <label>Tipo de Salón(*):</label>
              </Grid>
              <Grid item xs={3} style={{ zIndex: 99, marginTop: "2%" }}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  options={tipoSalonOptions}
                  styles={asyncSelectStylesTipoSalon}
                  onChange={handleChangeTipoSalonN}
                  value={tipoSalonN}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-6.5%",
                }}
              >
                <label>Salón(*):</label>
              </Grid>
              <Grid item xs={2} style={{ zIndex: 99, marginTop: "2%" }}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  options={salonOptions}
                  styles={asyncSelectStyles}
                  isLoading={selectSalonIsLoading}
                  onChange={handleChangeSalonN}
                  value={salonN}
                />
              </Grid>
              <Grid
                xs={2}
                item
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-6.5%",
                }}
              >
                <label>Capacidad:</label>
              </Grid>
              <Grid item xs={1} style={{ marginTop: "2%" }}>
                <input className="filter-input" disabled value={capacidad} />
              </Grid>
              <Grid item md={2} />
              <Grid
                xs={2}
                item
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-4.7%",
                }}
              >
                <label>Clave Profesor:</label>
              </Grid>
              <Grid xs={10} item style={{ zIndex: 98, marginTop: "2%" }}>
                <AsyncSelect
                  className="select-professor"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  value={profesorNValue}
                  onChange={setProfesorNValue}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-4.5%",
                }}
              >
                <label>Grupo:</label>
              </Grid>
              <Grid item xs={2} style={{ marginTop: "2%" }}>
                <input
                  className="filter-input"
                  onChange={(e) => handleGrupoValueN(e.target.value)}
                  value={grupoValueN}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-8.5%",
                }}
              >
                <label>Materia:</label>
              </Grid>
              <Grid item xs={7} style={{ marginTop: "2%" }}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  options={materiaNOptions}
                  styles={asyncSelectStyles}
                  onChange={setMateriaValueN}
                  value={materiaValueN}
                />
              </Grid>
              <Grid
                xs={2}
                item
                container
                style={{
                  justifyContent: "flex-end",
                  marginTop: "2%",
                  marginLeft: "-4.7%",
                }}
              >
                <label>Motivo(*):</label>
              </Grid>
              <Grid item sm={3} style={{ marginTop: "2%", zIndex: 97 }}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  options={motivoOptions}
                  styles={asyncSelectStyles}
                  isLoading={selectMotivoIsLoading}
                  onChange={handleChangeMotivo}
                  value={motivo}
                />
              </Grid>
              <Grid xs={7} />
              <Grid
                xs={2}
                item
                style={{ marginTop: "2%", marginLeft: "-1.8%" }}
              >
                <label>Comentario(*):</label>
              </Grid>
              <Grid xs={10} item style={{ marginTop: "2%", marginLeft: "-3%" }}>
                <textarea
                  className="text-area-modal"
                  rows={5}
                  placeholder="Escribe aquí"
                  style={{ fontSize: "14px" }}
                  onChange={(e) => setComentario(e.target.value)}
                  value={comentario}
                ></textarea>
              </Grid>
            </Grid>
          </div>
        </NewModal>
      </Contenedor>
    </>
  );
}

export default SalonesApartado;
