import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Contenedor from "../../Utils/Visuales/Contenedor";
import DataTable from "../../Utils/DataTables";
import TextField from "@mui/material/TextField";
import ModalEspacios from "./modal/modal";
import NewModal from "../../Utils/Visuales/NewModal";
import "./index.css";
import Select from "react-select";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import NewButton from "../../Utils/Botones/NewButton";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";
import moment from "moment";
import "moment/locale/es";
import { salonesHeaders } from "../../Utils/data/salonesHeaders";
import { salonesGetDaysData } from "../../Utils/Funciones/salonesGetDaysData";
import { salonesGetDaysApart } from "../../Utils/Funciones/salonesGetDaysApart";

function SalonesAsignaciones() {
  useEffect(() => {
    moment.locale("es");
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  const selectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const IDcampus = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);
  const [valueCiclo, setValueCiclo] = useState("");
  const [fechaInicialValue, setFechaInicialValue] = useState("");
  const [fechaFinalValue, setFechaFinalValue] = useState("");
  const [salonOptions, setSalonOptions] = useState([]);
  const [selectSalonIsLoading, setSelectSalonIsLoading] = useState(false);
  const [salon, setSalon] = useState("");
  const [capacity, setCapacity] = useState("");
  const [selectTipoSalonIsLoading, setSelectTipoSalonIsLoading] =
    useState(false);
  const [tipoSalonOptions, setTipoSalonOptions] = useState([]);
  const [tipoSalon, setTipoSalon] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [maxFinalDate, setMaxFinalDate] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState([]);

  function openModalConsultar() {
    setModalIsOpen(true);
  }
  const GetDataTable = async () => {
    if (fechaInicialValue === "") {
      enqueueSnackbar("Falta Seleccionar Fecha Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaFinalValue === "") {
      enqueueSnackbar("Falta Seleccionar Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (salon?.value === undefined) {
      enqueueSnackbar("Falta Seleccionar un Salón", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaInicialValue > fechaFinalValue) {
      enqueueSnackbar("Fecha inicial debe ser menor que fecha final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setLoading(true);
    setDisabled(true);
    try {
      let urlSalones = `/salones/asignaciones?fecha_inicio=${fechaInicialValue}&fecha_fin=${fechaFinalValue}&id_campus=${IDcampus}&tipo_reporte=espacios_ocupados`;
      let urlApartados = `/salones/apartado?fi=${fechaInicialValue}&ff=${fechaFinalValue}&id_campus=${IDcampus}`;
      if (valueCiclo) {
        urlApartados += `&ciclo=${valueCiclo}`;
        urlSalones += `&ciclo=${valueCiclo}`;
      }
      if (tipoSalon) {
        urlApartados += `&id_tipo_salon=${tipoSalon?.value}`;
        urlSalones += `&id_tipo_salon=${tipoSalon?.value}`;
      }
      if (salon) {
        urlApartados += `&id_salon=${salon?.value}`;
        urlSalones += `&id_salon=${salon?.value}`;
      }

      const responseSalones = await getRequest(urlSalones);
      const responseApartado = await getRequest(urlApartados);
      if (responseSalones.s === "OK") {
        const nro = responseSalones?.d["dia"]?.length;
        let datos = salonesGetDaysData(responseSalones?.d, nro);
        if (responseApartado?.d?.["dias"] !== undefined) {
          const nroApartado = responseApartado?.d["dias"]?.length;
          datos = salonesGetDaysApart(datos, nroApartado, responseApartado?.d);
        }
        setData(datos);
        setDataOriginal(datos);
        enqueueSnackbar(responseSalones.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(responseSalones.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setDisabled(false);
  };

  function search(text) {
    text = text.toLowerCase();
    let result = data.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      else if (row[3].toLowerCase().includes(text)) return true;
      else if (row[4].toLowerCase().includes(text)) return true;
      else if (row[5].toLowerCase().includes(text)) return true;
      else if (row[6].toLowerCase().includes(text)) return true;
      //No filtra por capacidad Row 7
      return false;
    });
    if (text.length === 0) result = dataOriginal;
    setData(result);
  }

  async function getSalonOptions(id) {
    setSelectSalonIsLoading(true);
    try {
      const endpoint = `/buscar?tipo=salon&valor=id:${id},id_campus:${IDcampus}`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        response.d.forEach((item) => {
          data.push({
            label: item.id_salon + " - " + item.nombre,
            value: item.id_salon,
            capacidad: item.capacidad,
          });
        });
        setSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectSalonIsLoading(false);
    }
  }
  async function getTipoSalonCatalogo(IDcampus, enqueueSnackbar) {
    setSelectTipoSalonIsLoading(true);

    try {
      const endpoint = `/catalogo?id=24&id_campus=${IDcampus}`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["type"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setTipoSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectTipoSalonIsLoading(false);
    }
  }

  useEffect(() => {
    getTipoSalonCatalogo(IDcampus, enqueueSnackbar);
  }, [IDcampus, enqueueSnackbar]);

  function handleChangeTipoSalon(ev) {
    if (ev !== null) {
      setTipoSalon(ev);
      setSalon("");
      getSalonOptions(ev.value);
    } else {
      setTipoSalon("");
      setSalon("");
    }
  }

  async function handleSubmitNuevaReserva() {
    let asig_ind = localStorage.getItem("asignacion");
    const data_asig_ind = JSON.parse(asig_ind);
    let dataTab = localStorage.getItem("dataTable");
    let tipo_modal = localStorage.getItem("tipo_modal");
    const dataTable = JSON.parse(dataTab);
    const checkData = data_asig_ind?.filter((item) => item.delete === true);
    if (checkData?.length && openConfirmationModal === false) {
      if (data_asig_ind.length === checkData.length) {
        setConfirmationMessage([
          "¿Desea realizar la baja de t͟o͟d͟o͟s los salones que",
          "tiene asignado el grupo-materia?",
        ]);
      } else {
        setConfirmationMessage([
          "¿Desea realizar la baja del salón que el grupo-materia",
          "tiene asignado en ese momento?",
        ]);
      }
      setOpenConfirmationModal(true);
      return;
    } else {
      setOpenConfirmationModal(false);
    }
    if (data_asig_ind === null) {
      enqueueSnackbar("Faltan seleccionar datos", {
        variant: "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (dataTable.length < 1) {
      enqueueSnackbar(
        "Faltan seleccionar datos de la tabla Salones Reservados",
        {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    } else if (tipo_modal === "individual") {
      setLoadingModal(true);
      let nuevoArray = {};
      const groupedSchedules = {};
      data_asig_ind.forEach((x) => {
        if (!groupedSchedules[x.dia]) {
          groupedSchedules[x.dia] = [];
        }
        groupedSchedules[x.dia].push(x);
        if (x === null || x.capacidad === undefined || x.id_area === 0) {
          return;
        }
        if (!nuevoArray.hasOwnProperty(x.dia)) {
          nuevoArray[x.dia] = [];
        }
        nuevoArray[x.dia].push({
          capacidad: x.capacidad,
          dia: x.dia,
          hora_fin: x.hora_fin,
          hora_inicio: x.hora_inicio,
          id_area: x.id_area,
          id_assignment: x.id_assignment,
          id_schedule: x.id_schedule,
          delete: x.delete,
          old_id_area: x.old_id_area,
          default_salon: x.default_salon
        });
      });
      let checkArea = false;
      for (const day in groupedSchedules) {
        const daySchedules = groupedSchedules[day];
        const hasZeroArea = daySchedules.filter(
          (schedule) => schedule.id_area === 0
        );
        if (
          hasZeroArea.length !== daySchedules.length &&
          hasZeroArea.length !== 0
        ) {
          enqueueSnackbar(
            `Se debe de enviar el horario completo para el dia ${daySchedules[0]?.dia}`,
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          setLoadingModal(false);
          checkArea = true;
          break;
        }
      }

      if (checkArea) {
        return;
      }
      const data_custom = Object.values(nuevoArray);
      try {
        for (let i = 0; i < data_custom.length; i++) {
          let response = await postRequest(
            "/salones/asignar_salon",
            data_custom[i]
          );
          if (response.s === "OK") {
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            window.localStorage.setItem("asignacion", JSON.stringify([]));
            window.localStorage.setItem("dataTable", JSON.stringify([]));
            window.localStorage.removeItem("asignacion");
            window.localStorage.removeItem("dataTable");
            window.localStorage.removeItem("tipo_modal");
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            window.localStorage.removeItem("asignacion");
            window.localStorage.removeItem("dataTable");
            window.localStorage.removeItem("tipo_modal");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setModalIsOpen(false);
        setLoadingModal(false);
      }
    } else {
      setLoadingModal(true);
      let nuevoArray = {};
      data_asig_ind.forEach((x) => {
        if (x.id_area === 0) {
          return;
        }
        if (!nuevoArray.hasOwnProperty(x.id_schedule)) {
          nuevoArray[x.id_schedule] = [];
        }
        nuevoArray[x.id_schedule].push({
          capacidad: x.capacidad,
          dia: x.dia,
          hora_fin: x.hora_fin,
          hora_inicio: x.hora_inicio,
          id_area: x.id_area,
          id_assignment: x.id_assignment,
          id_schedule: x.id_schedule,
          delete: x.delete,
          old_id_area: x.old_id_area,
          default_salon: x.default_salon
        });
      });
      const data_custom = Object.values(nuevoArray);
      for (let i = 0; i < data_custom.length; i++) {
        try {
          let response = await postRequest(
            "/salones/asignar_salon",
            data_custom[i]
          );
          if (response.s === "OK") {
            setModalIsOpen(false);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            window.localStorage.setItem("asignacion", JSON.stringify([]));
            window.localStorage.setItem("dataTable", JSON.stringify([]));
            window.localStorage.removeItem("asignacion");
            window.localStorage.removeItem("dataTable");
            window.localStorage.removeItem("tipo_modal");
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            window.localStorage.removeItem("asignacion");
            window.localStorage.removeItem("dataTable");
            window.localStorage.removeItem("tipo_modal");
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingModal(false);
        }
      }
    }
  }

  const handleChangeInitialDate = (e) => {
    setFechaInicialValue(e.target.value);
    let futureDate = moment(e.target.value);
    let isSunday = true;
    while (isSunday) {
      if (moment(futureDate).add(1, "days").day() === 0) {
        isSunday = false;
      } else {
        futureDate.add(1, "days");
      }
    }
    setMaxFinalDate(futureDate.format("YYYY-MM-DD"));
  };

  const handleChangeFechaFinal = (e) => {
    if (
      moment(e.target.value).isBetween(
        fechaInicialValue,
        maxFinalDate,
        null,
        "[]"
      )
    ) {
      setFechaFinalValue(e.target.value);
    }
  };

  return (
    <>
      <Contenedor title="Salones / Asignaciones">
        <Grid style={{ padding: 5 }}>
          <Grid
            container
            spacing={1}
            style={{
              padding: "10px 0px",
              alignItems: "center",
            }}
            className="nuevoUsuarioForm"
          >
            <Grid item xs={1}>
              <NewButton
                noIcon
                style={{
                  display:
                    permisos["Salones->Asignaciones"].escribir === "1"
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  openModalConsultar();
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <label style={{ float: "right" }}>Ciclo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                onChange={(e) => setValueCiclo(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Fecha Inicial(*):</label>
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="select-modal input-ciclo"
                type="date"
                onChange={(e) => handleChangeInitialDate(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .notchedOutlinet": {
                    border: "none !important",
                  },
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Fecha Final(*):</label>
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={fechaFinalValue}
                className="select-modal input-ciclo"
                type="date"
                onChange={(e) => handleChangeFechaFinal(e)}
                disabled={fechaInicialValue !== "" ? false : true}
                InputProps={{
                  inputProps: {
                    min: fechaInicialValue,
                    max: maxFinalDate,
                    shrink: true,
                  },
                }}
                sx={{
                  "& .notchedOutlinet": {
                    border: "none !important",
                  },
                }}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={1}>
              <NewButton
                style={{ float: "right" }}
                customText="Buscar"
                noIcon
                onClick={() => GetDataTable()}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2}>
              <label style={{ float: "right" }}>Tipo de Salón(*):</label>
            </Grid>
            <Grid item xs={2} style={{ zIndex: 90 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                options={tipoSalonOptions}
                styles={selectStyles}
                isLoading={selectTipoSalonIsLoading}
                onChange={handleChangeTipoSalon}
                value={tipoSalon}
                isClearable={true}
              />
            </Grid>
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Salón(*):</label>
            </Grid>
            <Grid item xs={2} style={{ zIndex: 90 }}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={salonOptions}
                styles={selectStyles}
                isLoading={selectSalonIsLoading}
                onChange={(ev) => {
                  setSalon(ev !== null ? ev : "");
                  setCapacity(ev !== null ? ev.capacidad : "");
                }}
                value={salon}
                isClearable={true}
              />
            </Grid>
            {/* <Grid item xs={0} lg={1} /> */}
            <Grid item xs={1}>
              <label style={{ float: "right" }}>Capacidad:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                disabled={true}
                value={capacity}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          md={3}
          container
          style={{ marginLeft: "auto", marginBottom: "1%" }}
        >
          <SearchInput hideIcon search={search} />
        </Grid>
        <div
          style={{
            height: "calc(97% - 170px)",
          }}
        >
          <DataTable
            headers={salonesHeaders}
            data={data}
            loading={loading}
            paginate
            bordes
            centrar={[1, 2, 3, 4, 5, 6]}
            moreCells={2}
          />
        </div>

        <NewModal
          title="Reserva de salones"
          open={modalIsOpen}
          handleClose={() => {
            window.localStorage.setItem("asignacion", JSON.stringify([]));
            window.localStorage.setItem("dataTable", JSON.stringify([]));
            window.localStorage.removeItem("asignacion");
            window.localStorage.removeItem("dataTable");
            setModalIsOpen(false);
          }}
          loading={loadingModal}
          height={550}
          maxWidth={"1500px"}
          handleSubmit={() => {
            handleSubmitNuevaReserva();
          }}
        >
          <ModalEspacios />
        </NewModal>
        <NewModal
          title="Confirmación"
          open={openConfirmationModal}
          handleClose={() => {
            setOpenConfirmationModal(false);
          }}
          handleSubmit={() => {
            handleSubmitNuevaReserva();
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2 style={{ textAlign: "center", marginBottom: 0 }}>
              {confirmationMessage[0]}
            </h2>
            <h2 style={{ textAlign: "center", marginTop: 0 }}>
              {confirmationMessage[1]}
            </h2>
          </div>
        </NewModal>
      </Contenedor>
    </>
  );
}

export default SalonesAsignaciones;
