import React, { useState } from "react";
import { Grid } from "@material-ui/core";

const ModalInput = ({
  lable,
  onChange,
  type = "string",
  defaultValue = "",
  disabled = false,
  limit = 0,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const handleChange = (value) => {
    if (type === "string") {
      if (limit > 0 && value.length <= limit) {
        setInputValue(value);
        onChange(value);
      } else if (limit === 0) {
        setInputValue(value);
        onChange(value);
      }
    } else if (value === "") {
      setInputValue(value);
      onChange(value);
    } else if (type === "number") {
      /* eslint-disable no-useless-escape */
      if (limit > 0 && value.length <= limit) {
        const regex = new RegExp(/^[0-9]+(?:\.[0-9]*)?$/);
        if (regex.test(value)) {
          setInputValue(value);
          onChange(value);
        }
      } else if (limit === 0) {
        const regex = new RegExp(/^[0-9]+(?:\.[0-9]*)?$/);
        if (regex.test(value)) {
          setInputValue(value);
          onChange(value);
        }
      }
    }
  };
  return (
    <Grid style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
      <Grid item sm={2} style={{ textAlign: "end", marginRight: 20 }}>
        <label>{lable}</label>
      </Grid>
      <Grid style={{ flexGrow: 1 }}>
        <input
          className="filter-input"
          style={{ textAlign: "center" }}
          disabled={disabled}
          value={inputValue}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default ModalInput;
