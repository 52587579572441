import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import CreateModalInputs from "../CreateModalInputs";
import "./index.css";
import { Typography } from "@mui/material";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import excelIcon from "../../images/excel-icon.png";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { DocumentPDFTable } from "../pdf_table/document";
import moment from "moment";
import { PDFformatForReporteIndicadoresAcademicosSemanalesDatalle } from "../Funciones/PDFformatForReporteIndicadoresAcademicosSemanalesDatalle";
import { ModalHeadersSemanales } from "../data/reportesIndicadoresAcademicos";
import { ExportCSV } from "../Excel";
import { EXCELformatForReporteIndicdoresAcademicosSemanalesDatalle } from "../Funciones/EXCELformatForReporteIndicdoresAcademicosSemanalesDatalle";

const HeadersModalReporteIndicadoresAcademicosSemanales = ({
  infoHeaders,
  info,
}) => {
  const [req, setReq] = useState(false);

  const handleDownloadPDF = async () => {
    setReq(true);
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const newHeaders = ModalHeadersSemanales.filter(
      (element) => !["Ciclo", "Campus", "Escuela"].includes(element.name)
    );
    const dataPDF =
      await PDFformatForReporteIndicadoresAcademicosSemanalesDatalle(info);
    const doc = (
      <DocumentPDFTable
        metadata={newHeaders ?? []}
        data={dataPDF ?? []}
        title={`Reportes Indicadores Académicos Semanales Detalle Por Profesor`}
        campus={infoHeaders.campus}
        escuela={infoHeaders.escuela}
        direccion={infoHeaders.claveDireccion}
        director={infoHeaders.nombreDirector}
        ciclo={infoHeaders.ciclo}
        startDate={infoHeaders.fechaInicial}
        endDate={infoHeaders.fechaFinal}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(
      blob,
      `Reporte_Indicadores_Académicos_Semanales_Detalle_Por_Profesor_${fecha}_${hora}.pdf`
    );
    setReq(false);
  };

    const handleDownloadExcel = async () => {
      setReq(true);
      const fecha = moment().format("DD/MM/YYYY");
      const hora = moment().format("HH:mm:ss");
      const data = EXCELformatForReporteIndicdoresAcademicosSemanalesDatalle(info, infoHeaders);
      ExportCSV(
        data,
        `Reporte_Indicadores_Académicos_Semanales_Detalle_Por_Profesor_${fecha}_${hora}`
      );
      setReq(false);
    };

  return (
    <Grid
      container
      justifyContent="space-between"
      style={{ paddingLeft: 20, paddingRight: 20 }}
    >
      <Grid item className={"grid-container"}>
        <Grid className={"grid-sub-container"}>
          <CreateModalInputs
            label={"Ciclo"}
            inputValue={infoHeaders.ciclo}
            disabled={true}
            sm={3}
          />
        </Grid>
        <Grid className={"grid-sub-container"}>
          <CreateModalInputs
            label={"Campus"}
            inputValue={infoHeaders.campus}
            disabled={true}
            sm={3}
          />
        </Grid>
      </Grid>
      <Grid item className={"grid-container3"}>
        <Grid className={"grid-sub-container-2"}>
          <Grid className={"grid-sub-container"}>
            <CreateModalInputs
              label={"Escuela"}
              inputValue={infoHeaders.escuela}
              disabled={true}
              sm={2}
            />
          </Grid>
          <Grid className={"grid-sub-container"} style={{ marginLeft: 10 }}>
            <CreateModalInputs
              label={"Dirección"}
              inputValue={infoHeaders.claveDireccion}
              disabled={true}
              sm={2}
            />
          </Grid>
        </Grid>
        <Grid className={"grid-sub-container"}>
          <CreateModalInputs
            label={"Nombre Director"}
            inputValue={infoHeaders.nombreDirector}
            disabled={true}
            sm={1}
          />
        </Grid>
      </Grid>
      {/* <Grid item className={"grid-container"}></Grid> */}
      <Grid item className={"grid-container2"}>
        <Grid
          container
          item
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
            marginTop: 20,
          }}
        >
          <div style={{ display: "flex" }} className="export-buttons">
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
              Descargar:
            </Typography>
            <div>
              <button
                onClick={handleDownloadExcel}
                disabled={req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={excelIcon} alt="excel-icon" />
              </button>
              <button
                onClick={handleDownloadPDF}
                disabled={req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={pdfFileIcon} alt="pdf-file-icon" />
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeadersModalReporteIndicadoresAcademicosSemanales;
