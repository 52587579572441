import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "./style.css";

export default function NewButton({ onClick, noIcon, style, customText,disabled }) {
  return (
    <button className='NewButton' onClick={onClick} style={style} disabled={disabled}>
      {!noIcon && <AddCircleIcon />}
      <span>{customText || "Nuevo"}</span>
    </button>
  );
}
