import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import Contenedor from "../../Utils/Visuales/Contenedor";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { ExportCSV } from "../../Utils/Excel";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import { CustomSpinner } from "../../Utils/Loader";
import { sleep } from "../../Utils/Funciones/sleep";
import SearchInput from "../../Utils/Visuales/SearchInput";

const DefinitivoBonoProductividad = () => {
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const { enqueueSnackbar } = useSnackbar();
  const [cicloValue, setCicloValue] = useState("");
  const [valueCampus, setValueCampus] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueEscuela, setValueEscuela] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalValue, setTotalValue] = useState(0);

  const headersPDF = [
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Nombre",
      id: "nombre_profesor",
      col: 1,
      fn: null,
      width: "19%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 2,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Materia",
      id: "clave_materia",
      col: 3,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Fecha Inicio",
      id: "fecha_inicio",
      col: 4,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fecha Fin",
      id: "fecha_fin",
      col: 5,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Total Clases",
      id: "total_clase",
      col: 6,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Total Horas",
      id: "total_horas",
      col: 7,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "% Bono",
      id: "bono",
      col: 8,
      fn: null,
      width: "15%",
      align: "center",
    },
  ];

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 3,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Director",
      id: "grupo",
      col: 4,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 5,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 6,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Nombre Materia",
      id: "nombre_materia",
      col: 7,
      fn: null,
      width: "18%",
      align: "center",
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 8,
      fn: null,
      width: "19%",
      align: "center",
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 9,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fecha de Inicio",
      id: "fecha_inicio",
      col: 10,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fecha Fin",
      id: "fecha_fin",
      col: 11,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Total Clase",
      id: "total_clase",
      col: 12,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Total Horas",
      id: "total_horas",
      col: 13,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "% Bono",
      id: "bono",
      col: 14,
      fn: null,
      width: "15%",
      align: "center",
    },
  ];

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));

    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const DownloadExcel = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      return {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        director: e[4],
        grupo: e[5],
        clave_materia: e[6],
        nombre_materia: e[7],
        clave_profesor: e[8],
        nombre_profesor: e[9],
        fecha_inicio: e[10],
        fecha_fin: e[11],
        total_clase: e[12],
        total_horas: e[13],
        bono: e[14],
      };
    });
    const date = moment().format("L");
    const hour = moment().format("LTS");
    ExportCSV(data_, `Reporte_Definitivo_BonoProductividad_${date}_${hour}`);
    setReq(false);
  };

  const DownloadPDF = async () => {
    if (data.length >= 300) {
      enqueueSnackbar("Supera limites de Páginas.", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setReq(true);
    const dataPDF = data
      .map((e) => {
        return {
          escuela: e[2],
          direccion: e[3],
          director: e[4],
          grupo: e[5],
          clave_materia: e[6],
          nombre_materia: e[7],
          clave_profesor: e[8],
          nombre_profesor: e[9],
          fecha_inicio: e[10],
          fecha_fin: e[11],
          total_clase: e[12],
          total_horas: e[13],
          bono: e[14],
        };
      })
      .sort((a, b) =>
        a.direccion > b.direccion ? 1 : a.direccion < b.direccion ? -1 : 0
      );
    const date = moment().format("DD/MM/YYYY");
    const hour = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headersPDF ?? []}
        data={dataPDF ?? []}
        title={`Reportes Definitivo de Bono de Productividad`}
        ciclo={cicloValue ?? "-"}
        campus={valueCampus.label ?? "-"}
        escuela={valueEscuela.label ?? "-"}
        total_profesores_grupo_materia={dataPDF.length ?? 0}
      />
    );

    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Definitivo_BonoProductividad_${date}_${hour}.pdf`);
    setReq(false);
  };

  async function handleClick() {
    let valido = true;
    if (cicloValue === "") {
      enqueueSnackbar("Debe Ingresar Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela === "") {
      enqueueSnackbar("Debe Ingresar Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === "") {
      enqueueSnackbar("Debe Ingresar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        const datos = [];
        let url = `/reportes/definitivo_bono_productividad?ciclo=${cicloValue}&id_campus=${valueCampus.value}&id_escuela=${valueEscuela.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;

          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["escuela"][i],
              response.d[3]["direccion"][i],
              response.d[14]["director"][i],
              response.d[4]["grupo"][i],
              response.d[5]["clave_materia"][i],
              response.d[6]["nombre_materia"][i],
              response.d[7]["clave_profesor"][i],
              response.d[8]["nombre_profesor"][i],
              response.d[9]["fecha_inicio"][i],
              response.d[10]["fecha_fin"][i],
              response.d[11]["total_clase"][i],
              response.d[12]["total_horas"][i],
              response.d[13]["bono"][i],
            ]);
          }
          setData(datos);
          setDataOriginal(datos);
          setShowTable(true);
          setTotalValue(nro);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoading(false);
    }
  }

  const handleSortByName = async () => {
    setLoading(true);
    const sortedByName = data.sort((a, b) =>
      a[8] > b[8] ? 1 : b[8] > a[8] ? -1 : 0
    );
    setData(sortedByName);
    setDataOriginal(sortedByName);
    await sleep(500);
    setLoading(false);
  };

  const handleSortByNote = async () => {
    // TODO
  };

  return (
    <>
      <Contenedor title="Reportes / Definitivo de Bono de Productividad">
        <Grid
          container
          className="nuevoUsuarioForm"
          style={{ position: "relative", zIndex: 9 }}
        >
          {/* Container Formulario */}
          <Grid
            container
            item
            xs={12}
            sm={12}
            style={{ marginLeft: "-3%", marginTop: "-2%" }}
          >
            {/* Primera Fila */}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={14} sm={2}>
                <input
                  className="filter-input input-ciclo"
                  placeholder="Indique el ciclo..."
                  value={cicloValue}
                  onChange={(e) => setCicloValue(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sm={1}
                style={{ textAlign: "end", marginLeft: "-2%" }}
              >
                <label>Campus(*):</label>
              </Grid>
              <Grid item xs={10} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusValores}
                  onChange={setValueCampus}
                />
              </Grid>
              {/* <Grid
                xs={1}
                sm={2}
                item
                style={{
                  textAlign: "end",
                  marginLeft: "-7.3%",
                  marginRight: "-0.5%",
                }}
              >
                <label>Ordenar Por:</label>
              </Grid>
              <Grid item xs={5} sm={4} style={{ marginTop: "0.5%" }}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="nuevoFormularioLabel"
                  >
                    <FormControlLabel
                      value="nombreProfesor"
                      control={<Radio />}
                      onClick={() => handleSortByName()}
                      label="Nombre Profesor"
                    />
                    <FormControlLabel
                      value="concepto"
                      control={<Radio />}
                      onClick={() => handleSortByNote()}
                      label="Promedio"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> */}
            </Grid>
            {/* Segunda Fila */}
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "-3%" }}
            >
              <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
                <label>Escuela(*):</label>
              </Grid>
              <Grid item xs={10} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueEscuela}
                  options={optionsEscuela}
                  onChange={setValueEscuela}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sm={1}
                style={{ textAlign: "end", marginLeft: "-2%" }}
              >
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={10} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sm={2}
                style={{
                  textAlign: "end",
                  marginLeft: "-5.4%",
                  marginRight: "-0.5%",
                }}
              >
                <label>Total Profesores:</label>
              </Grid>
              <Grid item xs={10} sm={2}>
                <input
                  className="filter-input"
                  value={totalValue}
                  style={{ width: "255px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Container Botones */}
          <Grid
            container
            item
            xs={12}
            sm={3}
            spacing={2}
            style={{ marginLeft: "-24%" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClick}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 190px)" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
      <CustomSpinner visible={req} />
    </>
  );
};

export default DefinitivoBonoProductividad;
