export const fixCatalogoName = (catalogo) => {
    let data2 = {}
    switch (catalogo.nombre) {
        case 'concepto_pagoextraordinario':
          data2 = {
            label: `${catalogo.id} - Concepto Pago Extraordinario`,
            value: catalogo.id,
            editable: catalogo.editable,
            name: catalogo.nombre
          };
          return data2;
        case 'Tipo_incapacidades':
          data2 = {
            label: `${catalogo.id} - Tipo Incapacidades`,
            value: catalogo.id,
            editable: catalogo.editable,
            name: catalogo.nombre
          };
          return data2;
        default:
          data2 = {
            label: `${catalogo.id} - ${catalogo.nombre}`,
            value: catalogo.id,
            editable: catalogo.editable,
            name: catalogo.nombre
          };
          return data2;
      }
}