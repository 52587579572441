import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";

export default function NuevoUsuario({
  usuarioNuevo,
  setUsuarioNuevo,
  opcionesRol,
  opcionesCampus,
  handleChangeOpcionCampus,
}) {
  const handleMultiSelectChange = (selectedOptions) => {
    setUsuarioNuevo({ ...usuarioNuevo, rol: selectedOptions });
  };

  return (
    <div className="nuevoUsuarioForm">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <label>Clave (*)</label>
          <input
            placeholder="Ingresa Clave"
            value={usuarioNuevo.nss}
            onChange={(e) =>
              setUsuarioNuevo({ ...usuarioNuevo, nss: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Nombre (*)</label>
          <input
            placeholder="Ingresa Nombre"
            value={usuarioNuevo.nombre}
            onChange={(e) =>
              setUsuarioNuevo({ ...usuarioNuevo, nombre: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Apellido Paterno (*)</label>
          <input
            placeholder="Ingresa Apellido"
            value={usuarioNuevo.apellidoP}
            onChange={(e) =>
              setUsuarioNuevo({
                ...usuarioNuevo,
                apellidoP: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Apellido Materno (*)</label>
          <input
            placeholder="Ingresa Apellido"
            value={usuarioNuevo.apellidoM}
            onChange={(e) =>
              setUsuarioNuevo({
                ...usuarioNuevo,
                apellidoM: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <label>Correo electrónico (*)</label>
          <input
            placeholder="Ingresa Correo"
            value={usuarioNuevo.email}
            onChange={(e) =>
              setUsuarioNuevo({ ...usuarioNuevo, email: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Campus (*)</label>
          <MultiSelectCampus
            options={opcionesCampus}
            onChange={handleChangeOpcionCampus}
            value={{
              value: `${opcionesCampus.filter((item) => item.isActive).length}`,
              label: `Total: ${
                opcionesCampus.filter((item) => item.isActive).length
              }`,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            zIndex: 999,
            position: "absolute",
            marginTop: 195,
            width: "96%",
          }}
        >
          <label>Rol (*)</label>
          <Select
            options={opcionesRol}
            placeholder="Seleccione rol..."
            className="select-modal input-rol"
            classNamePrefix="select-modal"
            defaultValue={usuarioNuevo.rol}
            onChange={(e) => handleMultiSelectChange(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
