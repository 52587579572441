import { getRequest, postRequest } from "./requester";
import { sleep } from "./sleep";

export async function runJenkinsJob(body, enqueueSnackbar,retries = 5) {
  try {
    // Step 1: Fetch the location ID
    let url = `/interface_ws`;
    const request = url;
    const response = await postRequest(
      request,
      body,
      new AbortController().signal,
      false,
      true
    );
    if (response.s !== "OK") {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    await sleep(5000);
    let data = response.d;
    let locationId = data.location_id;

    // Step 2: Fetch job ID (with retries if necessary)
    let jobId = await fetchJobId(locationId, enqueueSnackbar, body.type, retries);
    if (!jobId) {
      return;
    }
    // Step 3: Check if the job is done
    let jobStatus = await checkJobStatus(jobId, enqueueSnackbar, body.type, retries);
    console.log(jobStatus);
    if (!jobStatus) {
      return;
    }
    if (jobStatus.s === "OK" || response.s !== "error") {
      return jobStatus;
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

async function fetchJobId(
  locationId,
  enqueueSnackbar,
  type,
  retries = 5,
  delay = 5000
) {
  for (let i = 0; i < retries; i++) {
    try {
      let response = await getRequest(
        `/interface_ws?type=${type}&location_id=${locationId}`,
        new AbortController().signal,
        false,
        false,
        true
      );
      if (response.s !== "OK") {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return;
      }
      let data = response.d;
      if (data.id_job) {
        return data.id_job;
      }
      console.log("Job ID not returned, retrying...");
      await new Promise((resolve) => setTimeout(resolve, delay));
    } catch (error) {
      console.error("Error fetching job ID:", error);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
  enqueueSnackbar(
    "No se pudo recuperar el ID del trabajo después de varios intentos",
    {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    }
  );
  return;
}

async function checkJobStatus(
  jobId,
  enqueueSnackbar,
  type,
  retries = 10,
  delay = 5000
) {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await getRequest(
        `/interface_ws?type=${type}&job_id=${jobId}`,
        new AbortController().signal,
        false,
        false,
        true
      );
      if (response.s !== "OK") {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return response;
      }
      if (response.s === "OK" && response.d?.result !== "pending") {
        enqueueSnackbar(response.m, {
          variant: "success",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return response;
      }
      console.log("Job is not done, retrying...");
      await new Promise((resolve) => setTimeout(resolve, delay));
    } catch (error) {
      console.error("Error fetching job:", error);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
  enqueueSnackbar(
    "No se pudo verificar el trabajo después de varios intentos",
    {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    }
  );
  return;
}
