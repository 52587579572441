import { hourIndex } from "../data/hourIndex";
import { convertHours } from "./convertHours";

const daysIndex = {
  Lun: "L",
  Mar: "M",
  Mie: "Mi",
  Jue: "J",
  Vie: "V",
  Sab: "S",
};

export const salonesGetDaysByLetter = (datos, response, responseApartados) => {
  let result = [...datos];
  for (let i = 0; i < datos.length; i++) {
    for (let f = 0; f < response.d.length; f++) {
      if (datos[i][36] === parseInt(response.d[f]["id_classroom"])) {
        let nro = 0;
        for (let clave in response.d[f].timeslots) {
          if (response.d[f].timeslots[clave].length > 0) {
            let editado = "";
            for (let h = 0; h < response.d[f].timeslots[clave].length; h++) {
              const regex = new RegExp(
                `\\b${response.d[f].timeslots[clave][h]}\\b`,
                "g"
              );
              editado = result[i][nro + 3].replace(regex, "");
              result[i][nro + 3] = editado;
            }
          }
          nro++;
        }
      }
    }
  }

  for (let i in result) {
    for (let h in responseApartados.d?.salon) {
      if (
        responseApartados.d?.salon[h] === result[i][1] &&
        responseApartados.d?.estatus[h]
      ) {
        const arrayOfDays = responseApartados.d.dias[h].split(",");
        const initialHour = convertHours(
          responseApartados.d.horario[h].split(" - ")[0]
        );
        const endHour = convertHours(
          responseApartados.d.horario[h].split(" - ")[1]
        );
        const indexInitialJ = hourIndex[initialHour] + 2;
        const indexFinalJ = hourIndex[endHour] + 2;
        for (let j = 0; j < arrayOfDays.length; j++) {
          const regex = new RegExp(`\\b${daysIndex[arrayOfDays[j]]}\\b`, "g");
          for (let k = 3; k < result[i].length; k++) {
            if (indexFinalJ === k) {
              break;
            } else if (k >= indexInitialJ) {
              result[i][k] = result[i][k].replace(regex, "");
            }
          }
        }
      }
    }
  }
  return result;
};
