import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import Select from "react-select";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
//import AsyncSelect from "react-select/async";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import { ExportCSV } from "../../Utils/Excel";
import saveAs from "file-saver";
import "./index.css";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ReporteCatalogoDeGruposOnline = () => {
  const headers = [
    {
      name: "Ciclo",
      col: 0,
      id: "ciclo",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "escuela",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 3,
      id: "direccion",
      align: "center",
      fn: null,
    },
    {
      name: "Carrera",
      col: 4,
      id: "carrera",
      align: "center",
      fn: null,
    },
    {
      name: "Clave de Grupo ONLINE",
      col: 5,
      id: "clave_de_grupo_online",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 6,
      id: "grupo",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      col: 7,
      id: "clave_materia",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Materia",
      col: 8,
      id: "nombre_materia",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Curso",
      col: 9,
      id: "tipo_de_curso",
      align: "center",
      fn: null,
    },
    {
      name: "Módulo",
      col: 10,
      id: "modulo",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Inicial",
      col: 11,
      id: "fecha_inicial",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Final",
      col: 12,
      id: "fecha_final",
      align: "center",
      fn: null,
    },
    {
      name: "Asignados",
      col: 13,
      id: "asignados",
      align: "center",
      fn: null,
    },
    {
      name: "Horas Por Semanas",
      col: 14,
      id: "horas_por_semanas",
      align: "center",
      fn: null,
    },
    {
      name: "Nro-Semanas",
      col: 15,
      id: "nro_semanas",
      align: "center",
      fn: null,
    },
    {
      name: "Estatus",
      col: 16,
      id: "estatus",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Grupo",
      col: 17,
      id: "tipo_de_grupo",
      align: "center",
      fn: null,
    },
    {
      name: "Complementario",
      col: 18,
      id: "complementario",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 19,
      id: "clave_profesor",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 20,
      id: "nombre_profesor",
      align: "center",
      fn: null,
    },
  ];
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const [valueCampus, setValueCampus] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueMateria, setValueMateria] = useState("");
  const [tipoCurso, setTipoCurso] = useState("");
  const [optionsTipoCurso] = useState([
    {
      label: "Escolarizado",
      value: "Escolarizado",
    },
    {
      label: "Propedéutico",
      value: "Propedéutico",
    },
    {
      label: "CADE Online",
      value: "CADE online",
    },
    {
      label: "Inglés Lic",
      value: "Ingles Lic",
    },
    {
      label: "Inglés Postgrado",
      value: "Inglés Posgrado",
    },
  ]);
  const [input, setInput] = useState({
    periodoInicial: "",
    periodoFinal: "",
    grupo: "",
    modulo: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function handleClickGenerar() {
    setLoading(true);
    if (valueCiclo === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (input.periodoInicial === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Periodo Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (input.periodoFinal === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Periodo Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        const datos = [];
        let url = `/reportes/catalogo_grupos_online?ciclo=${valueCiclo}&fi=${input.periodoInicial}&ff=${input.periodoFinal}`;
        if (valueCampus) url += `&id_campus=${valueCampus.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        if (selectedEscuela) url += `&id_escuela=${selectedEscuela.value}`;
        if (input.grupo) url += `&grupo=${input.grupo}`;
        if (valueMateria) url += `&id_materia=${valueMateria}`;
        if (tipoCurso) url += `&id_tipo_curso=${tipoCurso.value}`;
        if (input.modulo) url += `&modulo=${input.modulo}`;
        const request = url;
        const response = await getRequest(request);
        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          if (nro === 0) {
            setData([]);
            enqueueSnackbar("No se han encontrado datos", {
              variant: "info",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          } else {
            for (let i = 0; i < nro; i++) {
              datos.push([
                response.d[0]["ciclo"][i],
                response.d[1]["campus"][i],
                response.d[2]["escuela"][i],
                response.d[3]["direccion"][i],
                response.d[4]["carrera"][i],
                response.d[5]["clave_grupo_online"][i],
                response.d[6]["grupo"][i],
                response.d[7]["clave_materia"][i],
                response.d[8]["nombre_materia"][i],
                response.d[9]["tipo_curso"][i],
                response.d[10]["modulo"][i],
                response.d[11]["fecha_inicial"][i],
                response.d[12]["fecha_final"][i],
                response.d[13]["asignados"][i],
                response.d[14]["horas_semanas"][i],
                response.d[15]["nro_semanas"][i],
                response.d[16]["estatus"][i],
                response.d[17]["tipo_grupo"][i],
                response.d[18]["complementario"][i],
                response.d[19]["clave_profesor"][i],
                response.d[20]["nombre_profesor"][i],
              ]);
            }
            setData(datos);
            setDataOriginal(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setShowTable(true);
    }
    setLoading(false);
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setSelectEscuela(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        carrera: e[4],
        clave_de_grupo_online: e[5],
        grupo: e[6],
        clave_materia: e[7],
        nombre_materia: e[8],
        tipo_de_curso: e[9],
        modulo: e[10],
        fecha_inicial: e[11],
        fecha_final: e[12],
        asignados: e[13],
        horas_por_semana: e[14],
        nro_semanas: e[15],
        estatus: e[16],
        tipo_de_grupo: e[17],
        complementario: e[18],
        clave_profesor: e[19],
        nombre_profesor: e[20],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_Catálogo_de_Grupos_Online_${fecha}_${hora}`);
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        carrera: e[4],
        clave_de_grupo_online: e[5],
        grupo: e[6],
        clave_materia: e[7],
        nombre_materia: e[8],
        tipo_de_curso: e[9],
        modulo: e[10],
        fecha_inicial: e[11],
        fecha_final: e[12],
        asignados: e[13],
        horas_por_semana: e[14],
        nro_semanas: e[15],
        estatus: e[16],
        tipo_de_grupo: e[17],
        complementario: e[18],
        clave_profesor: e[19],
        nombre_profesor: e[20],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Fecha Reporte: ${fecha} ${hora}`}
        campus={valueCampus.label}
        startDate={input.periodoInicial}
        endDate={input.periodoFinal}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Catalogo_de_Grupos_Online_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  function handleChange(e) {
    e.preventDefault();
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Catálogo de Grupos ONLINE">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginRight: "-1.5%" }}>
                <input
                  className="filter-input"
                  style={{ width: "100%" }}
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid
                xs={12}
                md={1}
                className="textEnd"
                style={{ marginLeft: "-0.2%", marginRight: "1.4%" }}
              >
                <label>Fecha Inicial(*):</label>
              </Grid>
              <Grid xs={1} md={2}>
                <input
                  className="filter-input"
                  style={{ width: "91%" }}
                  value={input.periodoInicial}
                  name="periodoInicial"
                  type="date"
                  onChange={(e) => handleChange(e)}
                ></input>
              </Grid>
              <Grid
                xs={12}
                md={1}
                className="textEnd"
                style={{ marginLeft: "-2.4%", marginRight: "2.9%" }}
              >
                <label>Fecha Final(*):</label>
              </Grid>
              <Grid
                xs={1}
                md={2}
                style={{ marginLeft: "-1.5%", marginRight: "0.5%" }}
              >
                <input
                  className="filter-input"
                  style={{ width: "140px" }}
                  value={input.periodoFinal}
                  name="periodoFinal"
                  type="date"
                  onChange={(e) => handleChange(e)}
                ></input>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                style={{ textAlign: "end", marginRight: "-0.2%" }}
              >
                <label>Campus:</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueCampus}
                  options={campusValores}
                  onChange={setValueCampus}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "1.5%" }}
            >
              <Grid
                xs={12}
                md={1}
                className="textEnd"
                style={{ marginLeft: "-0.7%", marginRight: "0.7%" }}
              >
                <label>Escuela:</label>
              </Grid>
              <Grid xs={12} md={2} item style={{ zIndex: 99 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectEscuela}
                  onChange={setSelectedEscuela}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginLeft: "-1%" }}
              >
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginLeft: "-1%" }}
              >
                <label>Grupo:</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginRight: "1%" }}>
                <input
                  className="filter-input"
                  style={{ width: "140px" }}
                  value={input.grupo}
                  name="grupo"
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Materia:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <input
                  className="filter-input"
                  value={valueMateria}
                  onChange={(e) => setValueMateria(e.target.value)}
                />
                {/* <AsyncSelect
                  className="select-modal-container input-claveProfesor clave-profesor-index0"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  loadOptions={promiseMateriaOptions}
                  value={valueMateria}
                  onChange={setValueMateria}
                  placeholder="Buscar Materia"
                /> */}
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "1.5%" }}
            >
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginLeft: "-1%", marginRight: "0%" }}
              >
                <label>Módulo:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <input
                  className="filter-input"
                  style={{ width: "100%" }}
                  value={input.modulo}
                  name="modulo"
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Tipo Curso:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={tipoCurso}
                  options={optionsTipoCurso}
                  onChange={setTipoCurso}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={3}
            spacing={2}
            style={{ marginTop: "1%" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex", marginLeft: "10px" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  {/* <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "465px", marginTop: "0.5%" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ReporteCatalogoDeGruposOnline;
