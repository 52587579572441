import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import NewButton from "../Botones/NewButton";
import CustomTable from "../CustomTable";
import Select from "react-select";

export default function PlanTypeCombination({
    permisos,
    handleClickCancelar,
    handleClickEditar,
    data,
    loading,
    showButtons,
    estatusDisabled,
    optionsEstatus,
    handleClickSave
}) {
    const [dataModifier, setDataModifier] = useState([]);
    const asyncRowSelectStyles = {
        menu: (base) => ({
            ...base,
            position: 'static',
        }),
    };
    const headers = [
        {
            accessorKey: "plan",
            header: "Tipos de Plan",
        },
        {
            accessorKey: "estatus",
            header: "Estatus",
            Cell: ({ cell, row }) => (
                <Select
                    className="select-modal-container"
                    styles={asyncRowSelectStyles}
                    isDisabled={estatusDisabled}
                    options={optionsEstatus}
                    defaultValue={cell.getValue() === "Vigente"
                        ? { value: 1, label: "Vigente" }
                        : { value: 0, label: "No Vigente" }
                    }
                    onChange={(event) => handleEstatus(event, row.original)}
                />
            ),
        },
        {
            accessorKey: "modificado",
            header: "Modificado",
        },
        {
            accessorKey: "modificado_por",
            header: "Modificado por",
        },
        {
            accessorKey: "creado",
            header: "Creado",
        },
        {
            accessorKey: "creado_por",
            header: "Creado por",
        }
    ];
    const handleClickCancelarButton = () => {
        handleClickCancelar()
        setDataModifier([])
    }

    const handleEstatus = async (ev, row) => {
        let newDataModifier = dataModifier.filter((element) => element.plan !== row.plan)
        newDataModifier = [...newDataModifier, { ...row, ...dataModifier.filter((element) => element.plan === row.plan)[0], estatus: ev.label }]
        setDataModifier(newDataModifier)
    };

    return (
        <Grid style={{ display: "flex", flexDirection: "column", padding: 10, paddingBottom: 50 }}>
            <Grid style={{ display: 'flex', width: '100%', justifyContent: "space-around", paddingTop: 10, paddingBottom: 10 }}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <label>Tipos de Plan que son Combinables en el Campus</label>
                    </Grid>
                </Grid>
                <Grid>
                    <NewButton
                        customText="Editar"
                        noIcon
                        style={{ padding: "5px 15px", display: permisos['Configuración->Campus'].modificar === '1' ? 'block' : 'none' }}
                        onClick={handleClickEditar}
                    />
                </Grid>
            </Grid>
            <CustomTable
                data={loading ? [] : data}
                headers={headers}
                loading={loading}
                isFooterSaveButtons={showButtons}
                handleClickCancelar={() => handleClickCancelarButton()}
                handleClickSave={() => {
                    handleClickSave(dataModifier)
                    setDataModifier([])
                }}
            />
        </Grid>
    );
}