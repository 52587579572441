import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./index.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Row from "./Row";
import { setComentariosPagos } from "../../state/actions";
import { useDispatch } from "react-redux";

export default function DataTables(props) {
  const dispatch = useDispatch();
  let data = props.data;
  const [orderBy, setOrderBy] = useState({ col: 0, asc: true });
  const [rowsPerPage, setRowsPerPage] = useState(data.length);
  const [pageActual, setPageActual] = useState(0);
  const [refresh, setRefresh] = useState(true);
  const tableRef = useRef(null);
  const [windowsHeight, setWindowsHeight] = useState("");
  let i = 0;
  let centrados = props.centrar ? props.centrar : [];
  const secionComentarios_pagos = (e) => {
    const item = document.getElementById("Pagos");
    if (item) {
      dispatch(setComentariosPagos(true));
    } else {
      return;
    }
  };
  useEffect(() => {
    if (orderBy.asc) {
      data.sort((a, b) => {
        // console.log(a);
        // console.log(b);
        a = a[orderBy.col];
        b = b[orderBy.col];

        if (a !== null) {
          while (typeof a === "object") {
            if (a.props !== null && a.props !== undefined) {
              a = a.props.children;
            }
          }
        }
        if (b !== null) {
          while (typeof b === "object") {
            if (b.props !== null && b.props !== undefined) {
              b = b.props.children;
            }
          }
        }

        if (a !== null && b !== null) {
          if (typeof a !== "number" && typeof b !== "number") {
            if (a?.includes("%")) {
              a = a.split("%")[0];
            }

            if (b?.includes("%")) {
              b = b.split("%")[0];
            }

            if (!isNaN(a) && !isNaN(b)) {
              a = parseFloat(a);
              b = parseFloat(b);
            }
          }

          if (a === "N/A") {
            return 1;
          }

          if (b === "N/A") {
            return -1;
          }

          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          return 0;
        }
      });
    } else {
      data.sort((a, b) => {
        a = a[orderBy.col];
        b = b[orderBy.col];

        if (a !== null) {
          while (typeof a === "object") {
            a = a.props.children;
          }
        }

        if (b !== null) {
          while (typeof b === "object") {
            b = b.props.children;
          }
        }

        if (a !== null && b !== null) {
          if (typeof a !== "number" && typeof b !== "number") {
            if (a?.includes("%")) {
              a = a.split("%")[0];
            }

            if (b?.includes("%")) {
              b = b.split("%")[0];
            }

            if (!isNaN(a) && !isNaN(b)) {
              a = parseFloat(a);
              b = parseFloat(b);
            }
          }

          if (a === "N/A") {
            return 1;
          }

          if (b === "N/A") {
            return -1;
          }

          if (a < b) {
            return 1;
          }
          if (a > b) {
            return -1;
          }
          return 0;
        }
      });
    }
    setRefresh(!refresh);
  }, [orderBy]);

  function start() {
    console.log("La resolución del navegador es: " + window.innerHeight);
    setWindowsHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", start);
  }, [windowsHeight]);

  useEffect(() => {
    if (props.paginate) {
      setPageActual(0);
      let height = windowsHeight;
      let valor = 0;
      let width = windowsHeight;
      //const rowHeight = width < 1200 ? 45 : 28;
      const rowHeight = 45;
      const rowHeightCriterios = width < 1200 ? 51 : 33;
      height -= 35;
      let newRows;
      if (props.criterios) {
        newRows = Math.trunc(height / rowHeightCriterios);
      } else {
        newRows = Math.trunc(window.innerHeight / rowHeight);
        newRows = newRows - 12;
      }
      setRowsPerPage(newRows);
    } else {
      setRowsPerPage(data.length);
    }
  }, [data, props.paginate, windowsHeight]);

  function handleClick(data) {
    if (props.onClickRow) {
      props.onClickRow(data);
    }
    secionComentarios_pagos();
  }

  function getRows() {
    let rows = [];
    const moreCells = props.moreCells !== undefined ? props.moreCells : 0;
    let maxLength =
      pageActual < Math.ceil(data.length / rowsPerPage + moreCells) - 1
        ? rowsPerPage * pageActual + rowsPerPage + moreCells
        : data.length;
    if (data.length > 0) {
      for (let i = rowsPerPage * pageActual; i < maxLength; i++) {
        if (props.data[i] !== undefined) {
          // Verifica si data[i] es distinto de undefined
          rows.push(
            <Row
              key={`${i}-key`}
              headers={props.headers}
              data={props.data[i]}
              onClick={handleClick}
              selectedRowbyOnclick={props.onClickRow}
            />
          );
        }
      }
    } else {
      return null;
    }
    return rows;
  }

  function getPaginate() {
    let buttons = [];
    if (props.bordes) {
      buttons.push(
        <button
          key={1}
          className="pagina-inicio-btn"
          onClick={() => {
            setPageActual(0);
          }}
        >
          Primera
        </button>
      );
    }
    buttons.push(
      <button
        key={2}
        onClick={() => {
          if (pageActual > 0) {
            setPageActual(pageActual - 1);
          }
        }}
      >
        Anterior
      </button>
    );
    if (Math.ceil(data.length / rowsPerPage) > 6) {
      if (pageActual <= 2) {
        for (i = 0; i < 3; i++) {
          buttons.push(
            <button
              key={i + 13}
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? "active" : null}
            >
              {i + 1}
            </button>
          );
        }
        buttons.push(<button key={3}>...</button>);
      } else if (pageActual >= Math.ceil(data.length / rowsPerPage) - 3) {
        buttons.push(<button key={4}>...</button>);
        for (
          i = Math.ceil(data.length / rowsPerPage) - 3;
          i < Math.ceil(data.length / rowsPerPage);
          i++
        ) {
          buttons.push(
            <button
              key={i + 12}
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? "active" : null}
            >
              {i + 1}
            </button>
          );
        }
      } else {
        buttons.push(<button key={5}>...</button>);
        for (i = pageActual - 2; i < pageActual + 3; i++) {
          buttons.push(
            <button
              key={i + 11}
              onClick={setPageActual.bind(this, i)}
              className={pageActual === i ? "active" : null}
            >
              {i + 1}
            </button>
          );
        }
        buttons.push(<button key={6}>...</button>);
      }
    } else {
      for (i = 0; i < Math.ceil(data.length / rowsPerPage); i++) {
        buttons.push(
          <button
            key={i + 10}
            onClick={setPageActual.bind(this, i)}
            className={pageActual === i ? "active" : null}
          >
            {i + 1}
          </button>
        );
      }
    }
    buttons.push(
      <button
        key={8}
        className="pagina-siguiente-btn"
        onClick={() => {
          if (pageActual < data.length / rowsPerPage - 1) {
            setPageActual(pageActual + 1);
          }
        }}
      >
        Siguiente
      </button>
    );
    if (props.bordes) {
      buttons.push(
        <button
          key={9}
          className="pagina-fin-btn"
          onClick={() => {
            setPageActual(Math.ceil(data.length / rowsPerPage) - 1);
          }}
        >
          Última
        </button>
      );
    }
    return <div className="contenedor-buttons">{buttons}</div>;
  }

  return (
    <>
      <div
        ref={tableRef}
        style={
          props.paginate
            ? {
                height: "calc(100% - 50px)",
                overflow: "hidden",
                position: "relative",
                overflowX: "auto",
                overflowY: "auto",
              }
            : { height: "100%", overflow: "auto", position: "relative" }
        }
      >
        <Table stickyHeader className="table-dash">
          <TableHead>
            {props.rowstableColSpan && (
              <tr>
                {props.rowstableColSpan.map((e, i) => (
                  <th key={`${i}-${e.name}`} colSpan={e.col}>
                    {e.name}
                  </th>
                ))}
              </tr>
            )}

            <TableRow>
              {props.headers.map((header, index) => {
                return (
                  <TableCell
                    key={`${index}-${header.name}`}
                    className="HeadCell"
                    align={
                      centrados.indexOf(props.headers.indexOf(header) + 1) >= 0
                        ? "center"
                        : "left"
                    }
                    col={i++}
                    onClick={(e) => {
                      let col = e.target.getAttribute("col");
                      if (!isNaN(parseInt(col))) {
                        if (orderBy.col === header.col) {
                          setOrderBy({
                            col: orderBy.col,
                            asc: !orderBy.asc,
                          });
                        } else {
                          setOrderBy({
                            col: header.col,
                            asc: true,
                          });
                        }
                      }
                    }}
                  >
                    {header.name}
                    <div className="HeadArrows">
                      <ArrowDropUpIcon
                        onClick={(e) => {
                          setOrderBy({
                            col: header.col,
                            asc: true,
                          });
                        }}
                        style={
                          orderBy.col === header.col && orderBy.asc
                            ? { color: "red" }
                            : null
                        }
                      />
                      <ArrowDropDownIcon
                        onClick={(e) => {
                          setOrderBy({
                            col: header.col,
                            asc: false,
                          });
                        }}
                        style={
                          orderBy.col === header.col && !orderBy.asc
                            ? { color: "red" }
                            : null
                        }
                      />
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {props.loading ? null : <TableBody>{getRows()}</TableBody>}
        </Table>
        {props.loading ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "10px",
              position: "absolute",
              width: "100%",
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : null}
        {data.length === 0 && !props.loading ? (
          <p
            style={{ textAlign: "center", width: "100%", position: "absolute" }}
          >
            No hay registros que mostrar.
          </p>
        ) : null}
      </div>
      {props.paginate ? getPaginate() : null}
    </>
  );
}
