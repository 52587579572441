import FileSaver from "file-saver";
import ExcelJS from "exceljs";

const CHUNK_SIZE = 1000; // Set the desired chunk size

export async function ExportCSV(csvData, fileName) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");

  // Add header row
  const headers = Object.keys(csvData[0]);
  worksheet.addRow(headers);

  // Add data rows
  let rowCounter = 0;
  let currentChunk = [];

  csvData.forEach((rowData) => {
    if (rowCounter < CHUNK_SIZE) {
      currentChunk.push(rowData);
      rowCounter++;
    } else {
      currentChunk.forEach((row) => {
        worksheet.addRow(Object.values(row));
      });
      currentChunk = [rowData];
      rowCounter = 1;
    }
  });

  // Add the last chunk (if any)
  if (currentChunk.length > 0) {
    currentChunk.forEach((row) => {
      worksheet.addRow(Object.values(row));
    });
  }

  // Generate the Excel file
  const excelBuffer = await workbook.xlsx.writeBuffer();
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}