import moment from "moment";
import { hourIndex } from "../data/hourIndex";
export const salonesGetDaysData = (data, length) => {
  const newData = [
    ["Lunes", ...new Array(32).fill("")],
    ["Martes", ...new Array(32).fill("")],
    ["Miércoles", ...new Array(32).fill("")],
    ["Jueves", ...new Array(32).fill("")],
    ["Viernes", ...new Array(32).fill("")],
    ["Sábado", ...new Array(32).fill("")],
  ];
  const daysIndex = {
    lunes: 0,
    martes: 1,
    miercoles: 2,
    jueves: 3,
    viernes: 4,
    sabado: 5,
  };
  for (let i = 0; i < length; i++) {
    const indexI = daysIndex[data.dia[i]];
    const indexInitialJ = hourIndex[data.hora_inicio[i]];
    const indexFinalJ =
      hourIndex[
        moment(`2023/01/01 ${data.hora_fin[i]}`)
          .add(1, "minutes")
          .format("HH:mm")
      ];
    newData[indexI][indexInitialJ] =
      `${data.grupo[i]} ${data.materia[i]} - ${data.total_alumnos_inscritos[i]}`.trim();

    newData[indexI][indexFinalJ] = "";

    for (let j = indexInitialJ + 1; j < indexFinalJ; j++) {
      newData[indexI][j] =
        `${data.grupo[i]} ${data.materia[i]} - ${data.total_alumnos_inscritos[i]}`.trim();
    }
  }
  return newData;
};
