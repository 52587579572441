export const headers = [
  {
    name: "",
    id: "",
    col: 0,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Programadas",
    id: "programadas",
    col: 1,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "Impartidas",
    id: "impartidas",
    col: 2,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "Faltas Brutas",
    id: "faltas_brutas",
    col: 3,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Faltas Autorizadas",
    id: "faltas_autorizadas",
    col: 4,
    fn: null,
    width: "9%",
    align: "center",
  },
  {
    name: "Faltas Netas",
    id: "faltas_netas",
    col: 5,
    fn: null,
    width: "18%",
    align: "center",
  },
  {
    name: "Reposiciones",
    id: "reposiciones",
    col: 6,
    fn: null,
    width: "19%",
    align: "center",
  },
  {
    name: "Retardos",
    id: "retardos",
    col: 7,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Canceladas por Asistencia",
    id: "canceladas_asistencia",
    col: 8,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Por Cancelación de la Clase",
    id: "cancelacion_de_clase",
    col: 9,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Salidas Anticipadas",
    id: "salidas_anticipadas",
    col: 10,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Normales",
    id: "normales",
    col: 11,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Adicionales",
    id: "adicionales",
    col: 12,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Sustitucionales",
    id: "sustitucionales",
    col: 13,
    fn: null,
    width: "15%",
    align: "center",
  },
  {
    name: "Visitas de Campo",
    id: "visitas_campo",
    col: 14,
    fn: null,
    width: "15%",
    align: "center",
  },
];

export const headersSemanales = [
  {
    name: "Ciclo",
    id: "ciclo",
    col: 0,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Campus",
    id: "campus",
    col: 1,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Escuela",
    id: "escuela",
    col: 2,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Clave Dirección",
    id: "direccion",
    col: 3,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Director",
    id: "director",
    col: 4,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Acciones",
    id: "acciones",
    col: 4,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Prog",
    id: "prog_h",
    col: 5,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Faltas Brutas",
    id: "faltas_brutas_h",
    col: 6,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "%F.Brutas",
    id: "porc_F_Brutas_h",
    col: 7,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Aut",
    id: "aut",
    col: 8,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Rep",
    id: "rep_h",
    col: 9,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Faltas Netas",
    id: "faltas_netas_h",
    col: 10,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "%F.Netas",
    id: "por_f_netas_h",
    col: 11,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Sust",
    id: "sust_h",
    col: 12,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Adic",
    id: "adic_h",
    col: 13,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Visit",
    id: "visit_h",
    col: 14,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Prog",
    id: "prog_s",
    col: 15,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Faltas Brutas",
    id: "faltas_brutas_s",
    col: 16,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Aut",
    id: "ut",
    col: 17,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Rep",
    id: "rep_s",
    col: 18,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Faltas Netas",
    id: "faltas_netas_s",
    col: 19,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Sust",
    id: "sust_s",
    col: 20,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Adic",
    id: "adic_s",
    col: 21,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Visit",
    id: "visit_s",
    col: 22,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "S.Ant",
    id: "s_ant",
    col: 23,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Ret",
    id: "ret_s",
    col: 24,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Ret",
    id: "ret_m",
    col: 25,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Can",
    id: "can",
    col: 26,
    width: "10%",
    align: "center",
    fn: null,
  },
];

export const rowstableColSpan = [
  {
    name: "",
    col: 6,
  },
  {
    name: "Horas",
    col: 10,
  },
  {
    name: "Sesiones",
    col: 10,
  },
  {
    name: "Minutos",
    col: 2,
  },
];
export const rowstableColSpanPDF = [
  {
    name: "",
    width: 104,
    align: "center",
  },
  {
    name: "Horas",
    width: 312,
    align: "center",
  },
  {
    name: "Sesiones",
    width: 312,
    align: "center",
  },
  {
    name: "Minutos",
    width: 63,
    align: "center",
  },
];

export const ModalHeadersSemanales = [
  {
    name: "Clv Prof",
    id: "clave_profesor",
    col: 0,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Nombre Profesor",
    id: "nombre_profesor",
    col: 1,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Grupo",
    id: "grupo",
    col: 2,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Materia",
    id: "materia",
    col: 3,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Clase",
    id: "clase",
    col: 4,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Fecha Origen",
    id: "fecha_origen",
    col: 5,
    width: "15%",
    align: "center",
    fn: null,
  },
  {
    name: "Fec Clase Espc",
    id: "fecha_clase_especial",
    col: 6,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Clv Prof Clase Espc",
    id: "clave_profesor_clase_especial",
    col: 7,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Nom Prof Clase Espc",
    id: "nombre_profesor_clase_especial",
    col: 8,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "Asistencia",
    id: "asistencia",
    col: 9,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "A",
    id: "autorizado",
    col: 10,
    width: "10%",
    align: "center",
    fn: null,
  },
  {
    name: "C",
    id: "incidencia",
    col: 11,
    width: "10%",
    align: "center",
    fn: null,
  },
];
