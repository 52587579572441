import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import Logo from "../../images/unitec-en-linea.jpeg";
import Typography from "@material-ui/core/Typography";
import CampusLoginCard from "../../Utils/Visuales/CampusLoginCard";
import Footer from "../../Utils/Visuales/Footer";
import "../../Utils/Visuales/Header/index.css";
import "./style.css";

const LoginPage = (props) => {
  const [campusValue, setCampusValue] = useState("");
  const campusArray = [
    {
      nombre: "TOLUCA",
      url_img:
        "https://unitecsica.com/img_campus/toluca.jpg",
      campus_id: 25,
    },
    {
      nombre: "SUR",
      url_img:
        "https://unitecsica.com/img_campus/sur.jpg",
      campus_id: 31,
    },
    {
      nombre: "QUERETARO",
      url_img:
        "https://unitecsica.com/img_campus/queretaro.jpg",
      campus_id: 28,
    },
    {
      nombre: "GUADALAJARA",
      url_img:
        "https://unitecsica.com/img_campus/guadalajara.jpg",
      campus_id: 27,
    },
    {
      nombre: "LOS REYES",
      url_img:
        "https://unitecsica.com/img_campus/reyes.jpg",
      campus_id: 29,
    },
    {
      nombre: "MARINA",
      url_img:
        "https://unitecsica.com/img_campus/marina.jpg",
      campus_id: 1,
    },
    {
      nombre: "ATIZAPAN",
      url_img:
        "https://unitecsica.com/img_campus/atizapan.jpg",
      campus_id: 30,
    },
    {
      nombre: "LEON",
      url_img:
        "https://unitecsica.com/img_campus/leon.jpg",
      campus_id: 26,
    },
    {
      nombre: "CUITLAHUAC",
      url_img:
        "https://unitecsica.com/img_campus/cuitlahuac.jpg",
      campus_id: 33,
    },
    {
      nombre: "ECATEPEC",
      url_img:
        "https://unitecsica.com/img_campus/ecatepec.jpg",
      campus_id: 11,
    },
    {
      nombre: "ON LINE",
      url_img:
        "https://unitecsica.com/img_campus/online.jpg",
      campus_id: 12,
    },
  ];
  return (
    <>
      <div className='LoginPage'>
        <header>
          <div className='logo'>
            <img src={Logo} alt='Logo UVM' />
          </div>
          <div className='page-title'>
            <h1>SICA 2.0</h1>
          </div>
        </header>
        <div className='contenido'>
          <Typography variant='h5'>Selecciona campus</Typography>
        </div>
      </div>

      {props.loading ? (
        <div
          style={{
            marginTop: "10px",
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={70} />
        </div>
      ) : (
        <Grid container  className='margen' justifyContent='center'>
          {campusArray.map((campus) => {
            return (
              <>
                <CampusLoginCard
                  campus={campus.nombre}
                  imgn={campus.url_img}
                  login={props.login}
                  campus_id={campus.campus_id}
                />
              </>
            );
          })}
        </Grid>
      )}

      <Footer />
    </>
  );
};

export default LoginPage;
