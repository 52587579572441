import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "../index.css";
import Select from "react-select";
import { getRequest } from "../../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import EditButton from "../../../Utils/Botones/EditButton";
import { checkIfJExists } from "../../../Utils/Funciones/checkIfJExists";
import moment from "moment";
import { Delete } from "@material-ui/icons";

let grupoTimer;

export const AsignacionFechas = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [valueCiclo, setValueCiclo] = useState("");
  const [listSalon, setListSalon] = useState([]);
  const campusUser = useSelector((state) => state.campusName);
  const [data, setData] = useState([]);
  const [grupoValue, setGrupoValue] = useState("");
  const [materiaOptions, setMateriaOptions] = useState([]);
  const campusId = useSelector((state) => state.campusId);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [escuela, setEscuela] = useState("");
  const [direccion, setDireccion] = useState("");
  const [claveProfesor, setClaveProfesor] = useState("");
  const [asignado, setAsignado] = useState("");
  const [creado, setCreado] = useState("");
  const [creadoPor, setCreadoPor] = useState("");
  const [modificado, setModificado] = useState("");
  const [modificadoPor, setModificadoPor] = useState("");
  const [busyClassrooms, setBusyClassrooms] = useState("");
  const [dataCustom, setDataCustom] = useState([]);
  const [tipoSalonSelectValue, setTipoSalonSelectValue] = useState([]);
  const [salonSelectValue, setSalonSelectValue] = useState([]);
  const [materia, setMateria] = useState({
    label: "",
    value: "",
  });
  const [tipoSalon, setSelectDataTipoSalon] = useState({
    label: "",
    value: "",
  });
  const [horasFinalesAnteriores, setHorasFinalesAnteriores] = useState([]);
  const [minutosFinalesAnteriores, setMinutosFinalesAnteriores] = useState([]);
  const [tipoSalonesPost, setTipoSalonesPost] = useState([]);
  const [maxValue, setMaxValue] = useState(30);
  const [indexSig, setIndexSig] = useState(null);
  const [indexAct, setIndexAct] = useState(null);
  const [defaultSalonSelectValue, setDefaultSalonSelectValue] = useState({});
  const defaultTipoSalonSelectValue = {
    label: ` - `,
    value: null,
  };
  const selectUpsStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "250px",
    }),
  };

  const getDefultSalon = async (campusId, enqueueSnackbar) => {
    try {
      const consulta = `/buscar?tipo=salon&valor=id:1,id_campus:${campusId}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        const defaultSalon = response.d.find((salon) => salon.nombre === " ");
        setDefaultSalonSelectValue({
          label: ` - `,
          value: defaultSalon.id_salon,
          name: " - ",
          capacidad: 0,
          delete: true,
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setListSalon([]);
      console.log(error);
    }
  };

  const GetTipoSalon = async (enqueueSnackbar) => {
    try {
      const consulta = `/catalogo?id=24`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.elementos.forEach((e, i) => {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        for (let step = 0; step < numRows_; step++) {
          response.d.elementos.forEach((e, i) => {
            Object.values(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        const dataSelect = rowsP.map((e) => {
          const data_ = {
            label: `${e[1]} - ${e[2]}`,
            value: e[0],
          };
          return data_;
        });
        setSelectDataTipoSalon(dataSelect);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetListSalonByTipo = async (
    event,
    i,
    element,
    isAll = false,
    getSalon = true
  ) => {
    const day = element[0].split("-");
    if (
      moment(moment().format("YYYY-MM-DD")).isAfter(
        `${day[2]}-${day[1]}-${day[0]}`
      )
    ) {
      enqueueSnackbar(
        `La fecha de la asignación a modificar es menor a la actual`,
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      return;
    }
    if (isAll) {
      setTipoSalonSelectValue((prev) => prev.map(() => event));
    } else {
      setTipoSalonSelectValue((prev) =>
        prev.map((tipoSalon, index) => {
          if (index === i) {
            return event;
          }
          return tipoSalon;
        })
      );
      if (getSalon) {
        let array =
          tipoSalonesPost.length !== 0 ? [...tipoSalonesPost] : dataCustom;
        array[i] = "";
        try {
          const consulta = `/buscar?tipo=salon&valor=id:${event.value},id_campus:${campusId}`;
          const response = await getRequest(consulta);
          if (response.s === "OK") {
            if (
              response.d === null ||
              response.d === "" ||
              response.d.length === 0
            ) {
              enqueueSnackbar("No se ha encontrado ningún salón", {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              return;
            }
            const busyClassroomsInitial = [];
            const stringDay = element[1][0].toUpperCase();
            busyClassrooms.forEach((responseItem) => {
              if (
                checkIfJExists(
                  `${element[2]}`,
                  `${element[3]}`,
                  responseItem.timeslots,
                  element[1].toLowerCase() === "miercoles" ? "Mi" : stringDay
                )
              ) {
                busyClassroomsInitial.push(responseItem.id_classroom);
              }
            });
            const dataObjectInicio = Object.values(response.d)
              .map((e, i) => {
                if (!busyClassroomsInitial.includes(`${e.id_salon}`)) {
                  const data_ = {
                    label: `${e.id_salon} - ${e.nombre}`,
                    value: e.id_salon,
                    name: e.nombre,
                    capacidad: e.capacidad,
                  };
                  return data_;
                }
                return null;
              })
              .filter((item) => item !== null);
            if (dataObjectInicio.length === 0) {
              enqueueSnackbar(
                "Salones no disponibles en el horario seleccionado",
                {
                  variant: "error",
                  autoHideDuration: null,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }
              );
            } else {
              enqueueSnackbar(response.m, {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              setListSalon((prev) =>
                prev.map((salon, index) => {
                  if (i === index) {
                    return dataObjectInicio;
                  }
                  return salon;
                })
              );
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const waitTime = 2000;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  async function searchMateria(id_campus, grupo, ciclo, enqueueSnackbar) {
    setSelectMateriaIsLoading(true);
    try {
      var options = [];
      const consulta = `/buscar?tipo=asignacion_profesor&valor=id_campus:${id_campus},grupo:${grupo},ciclo:${ciclo}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        if (
          response.d === null ||
          response.d === "" ||
          response.d.length === 0
        ) {
          setMateriaOptions([]);
          setMateria("");
          enqueueSnackbar("Validar datos del grupo", {
            variant: "info",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        }
        response.d.forEach((item, i) => {
          if (item.tipo_registro === "fecha determinada") {
            options.push({
              id: item.id_asignacion,
              value: i,
              label: `${item.clave_materia} - ${item.nombre_materia}`,
              id_person: item.id_person,
              tipo_registro: item.tipo_registro,
              escuela: item.escuela,
              direccion: item.direccion,
              nombre_profesor: item.nombre_profesor,
              start_date: item.start_date,
              end_date: item.end_date,
              asignados: item.asignados,
              horario: item.horario,
              salon_creado: item.salon_creado === null ? "" : item.salon_creado,
              salon_creado_por:
                item.salon_creado_por === null ? "" : item.salon_creado_por,
              salon_modificado:
                item.salon_modificado === null ? "" : item.salon_modificado,
              salon_modificado_por:
                item.salon_modificado_por === null
                  ? ""
                  : item.salon_modificado_por,
              ultima_sesion: item.ultima_sesion,
              primera_sesion: item.primera_sesion,
            });
          }
        });
        if (options.length === 0) {
          setMateriaOptions([]);
          enqueueSnackbar(
            "No se ha encontrado ninguna materia con fechas determinadas",
            {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return;
        }
        let hash = {};
        options = options.filter((o) =>
          hash[o.label] ? false : (hash[o.label] = true)
        );
        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  const handleGrupoValue = (e) => {
    setGrupoValue(e.target.value);
    setMateria("");
    setEscuela("");
    setDireccion("");
    setClaveProfesor("");
    setAsignado("");
    setData([]);
    setCreado("");
    setCreadoPor("");
    setModificado("");
    setModificadoPor("");
  };

  function convertirFecha(fechaString) {
    var fechaSp = fechaString.split("-");
    var anio = new Date().getFullYear();
    if (fechaSp.length === 3) {
      anio = fechaSp[2];
    }
    var mes = fechaSp[1] - 1;
    var dia = fechaSp[0];

    return new Date(anio, mes, dia);
  }

  const handleMateria = async (e) => {
    setDataCustom([]);
    setData([]);
    setMateria(e);
    setEscuela(e.escuela);
    setDireccion(e.direccion);
    setClaveProfesor(e.nombre_profesor);
    setAsignado(e.asignados);
    let datos_salones_reservados = [];
    const defaultSelectSalones = [];
    var i = 0;
    const dayVariables = {};
    e.horario.forEach((item) => {
      const { dia, hora_inicio, hora_fin } = item;
      const combinedHours = [hora_inicio, hora_fin];
      if (!dayVariables[dia]) {
        dayVariables[dia] = combinedHours;
      } else {
        dayVariables[dia][0] =
          dayVariables[dia][0] > hora_inicio
            ? hora_inicio
            : dayVariables[dia][0];
        dayVariables[dia][1] =
          dayVariables[dia][1] < hora_fin ? hora_fin : dayVariables[dia][1];
      }
      datos_salones_reservados.push([
        item.fecha,
        item.dia,
        item.hora_inicio,
        item.hora_fin,
        item.tipo_salon === "default" ? "" : item.tipo_salon,
        item.salon,
        item.capacidad,
        item.id_schedule,
        "dissable",
        item.hora_inicio,
        item.hora_fin,
        i,
        item.salon_id,
      ]);
      i++;
      defaultSelectSalones.push("");
    });
    const scheduleData = [
      "lunes" in dayVariables ? dayVariables["lunes"].join("-") : "-",
      "martes" in dayVariables ? dayVariables["martes"].join("-") : "-",
      "miercoles" in dayVariables ? dayVariables["miercoles"].join("-") : "-",
      "jueves" in dayVariables ? dayVariables["jueves"].join("-") : "-",
      "viernes" in dayVariables ? dayVariables["viernes"].join("-") : "-",
      "sabado" in dayVariables ? dayVariables["sabado"].join("-") : "-",
      "domingo" in dayVariables ? dayVariables["domingo"].join("-") : "-",
    ];
    const days = [];
    const initialHour = [];
    const finalHour = [];
    scheduleData.forEach((item, index) => {
      if (item === "-") {
        return null;
      }
      const time = item.split("-");
      initialHour.push(time[0]);
      finalHour.push(time[1]);
      switch (index) {
        case 0:
          days.push("lunes");
          break;
        case 1:
          days.push("martes");
          break;
        case 2:
          days.push("miercoles");
          break;
        case 3:
          days.push("jueves");
          break;
        case 4:
          days.push("viernes");
          break;
        case 5:
          days.push("sabado");
          break;
        case 6:
          days.push("domingo");
          break;
        default:
          break;
      }
    });
    const horarios = `dias: ${days.join(",")} horas_inicio: ${initialHour.join(
      ","
    )} horas_fin: ${finalHour.join(",")}`;
    datos_salones_reservados.sort(function (a, b) {
      return convertirFecha(a[0]) - convertirFecha(b[0]);
    });
    setTipoSalonSelectValue(defaultSelectSalones);
    setSalonSelectValue(defaultSelectSalones);
    setListSalon(defaultSelectSalones);
    try {
      const initialDateArray = e.horario[0]?.fecha.split("-");
      const endDateArray = e.horario[e.horario.length - 1]?.fecha.split("-");
      const initialDate = `${initialDateArray[2]}-${initialDateArray[1]}-${initialDateArray[0]}`;
      const endDate = `${endDateArray[2]}-${endDateArray[1]}-${endDateArray[0]}`;
      const request = `/reportes/salones_ocupados?fecha_inicial=${initialDate}&fecha_final=${endDate}&id_campus=${campusId}&horario=${horarios}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        const fechas = datos_salones_reservados.map((item) => {
          return {
            capacidad: item[6],
            dia: item[1],
            hora_fin: item[3],
            hora_inicio: item[2],
            id_area: item[12],
            id_assignment: e.id,
            id_schedule: item[7],
            old_id_area: item[12],
            delete: false,
            default_salon: defaultSalonSelectValue.value,
          };
        });
        setDataCustom(fechas);
        setBusyClassrooms(response.d);
        setData(datos_salones_reservados);
        setCreado(e.salon_creado);
        setCreadoPor(e.salon_creado_por);
        setModificado(e.salon_modificado);
        setModificadoPor(e.salon_modificado_por);
        setTipoSalonesPost(fechas);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Ocurrio un error", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  function handleHoraInicia(value, i, type) {
    const valueNew = parseInt(value);
    if (type === "i") {
      if (valueNew < 10) {
        let values = [...data];
        const data_map = values[i]?.with(
          2,
          `0${valueNew}:${values[i]?.[2].slice(-2)}`
        );
        values[i] = data_map;
        setData(values);
      } else {
        let values = [...data];
        const data_map = values[i]?.with(
          2,
          `${valueNew}:${values[i]?.[2].slice(-2)}`
        );
        values[i] = data_map;
        setData(values);
      }
    } else {
      if (valueNew < 10) {
        let values = [...data];
        const data_map = values[i]?.with(
          3,
          `0${valueNew}:${values[i]?.[3].slice(-2)}`
        );
        values[i] = data_map;
        setData(values);
      } else {
        let values = [...data];
        const data_map = values[i]?.with(
          3,
          `${valueNew}:${values[i]?.[3].slice(-2)}`
        );
        values[i] = data_map;
        setData(values);
      }
    }
  }
  function insertAtIndex(array, index, element) {
    array.splice(index, 0, element);
    return array;
  }

  function handleMinutosValue(value, i, type) {
    if (value === "00") {
      return;
    }
    const valueNew = parseInt(value);
    let array = tipoSalonesPost.map((element, index) => {
      if (index === i) {
        return "";
      }
      return element;
    });
    setTipoSalonesPost([...array]);
    if (type === "i") {
      if (valueNew < 10) {
        let values = [...data];
        const data_map = values[i]?.with(
          2,
          `${values[i]?.[2].substring(0, 2)}:0${valueNew}`
        );
        values[i] = data_map;
        setData(values);
      } else {
        let values = [...data];
        const data_map = values[i]?.with(
          2,
          `${values[i][2].substring(0, 2)}:${valueNew}`
        );
        values[i] = data_map;
        setData(values);
      }
    } else {
      if (valueNew < 10) {
        let values = [...data];
        const data_map = values[i]?.with(
          3,
          `${values[i]?.[3].substring(0, 3)}:0${valueNew}`
        );
        values[i] = data_map;
        setData(values);
      } else {
        let values = [...data];
        const data_map = values[i]?.with(
          3,
          `${values[i]?.[3].substring(0, 3)}${valueNew}`
        );
        values[i] = data_map;
        setData(values);
      }
    }
  }

  const handleChangeSalon = async (e, i, row, isAll = false) => {
    let array = [...tipoSalonesPost];
    let array_table = [...tipoSalonesPost];
    const day = row[0].split("-");
    if (
      moment(moment().format("YYYY-MM-DD")).isAfter(
        `${day[2]}-${day[1]}-${day[0]}`
      )
    ) {
      return;
    }
    if (isAll) {
      setSalonSelectValue((prev) => prev.map(() => e));
      array = tipoSalonesPost.map((salon, index) => {
        return {
          old_id_area: data[index][12],
          id_schedule: salon.id_schedule,
          id_area: e.value,
          capacidad: e.capacidad,
          dia: salon.dia,
          hora_inicio: salon.hora_inicio,
          hora_fin: salon.hora_fin,
          id_assignment: materia.id,
          index: index,
          delete: e.delete ? true : false,
          default_salon: defaultSalonSelectValue.value,
        };
      });
      array_table = tipoSalonesPost.map((salon, index) => {
        return {
          old_id_area: data[index][12],
          id_schedule: salon.id_schedule,
          value: e.value,
          id_area: e.value,
          label: e.label,
          capacidad: e.capacidad,
          dia: salon.dia,
          hora_inicio: salon.hora_inicio,
          hora_fin: salon.hora_fin,
          id_assignment: materia.id,
          index: index,
          delete: e.delete ? true : false,
          default_salon: defaultSalonSelectValue.value,
        };
      });
    } else {
      array[i] = {
        old_id_area: data[i][12],
        id_schedule: data[i][7],
        id_area: e.value,
        capacidad: e.capacidad,
        dia: row[1],
        hora_inicio: row[2],
        hora_fin: row[3],
        id_assignment: materia.id,
        value: e.value,
        label: e.label,
        delete: e.delete ? true : false,
        default_salon: defaultSalonSelectValue.value,
      };
      array_table[i] = {
        old_id_area: data[i][12],
        id_schedule: data[i][7],
        id_area: e.value,
        value: e.value,
        label: e.label,
        capacidad: e.capacidad,
        dia: row[1],
        hora_inicio: row[2],
        hora_fin: row[3],
        id_assignment: materia.id,
        delete: e.delete ? true : false,
        default_salon: defaultSalonSelectValue.value,
      };
      setSalonSelectValue((prev) =>
        prev.map((salon, index) => {
          if (index === i) {
            return e;
          }
          return salon;
        })
      );
    }
    let fechas = [];
    const array_storage = array;
    array_storage.forEach((e, i) => {
      if (e !== undefined) {
        const fecha_init = moment(e.hora_inicio, "HH:mm");
        const fecha_fin = moment(e.hora_fin, "HH:mm");
        for (
          var hour = moment(fecha_init);
          hour.isBefore(fecha_fin);
          hour.add(30, "minutes")
        ) {
          var ffin = moment(hour).add(30, "minutes");
          //pushear todas las fechas en un solo array
          fechas.push({
            id_schedule: e.id_schedule,
            id_area: e.id_area,
            capacidad: e.capacidad,
            dia: e.dia,
            hora_inicio: hour.format("HH:mm"),
            hora_fin: ffin.format("HH:mm"),
            id_assignment: e.id_assignment,
            old_id_area: e.old_id_area,
            delete: e.delete,
            default_salon: defaultSalonSelectValue.value,
          });
        }
      }
    });
    setTipoSalonesPost(array_table);
    window.localStorage.removeItem("asignacion");
    window.localStorage.removeItem("dataTable");
    window.localStorage.removeItem("tipo_modal");
    window.localStorage.setItem("asignacion", JSON.stringify(fechas));
    window.localStorage.setItem("tipo_modal", "fechas");
    window.localStorage.setItem("dataTable", JSON.stringify(data));
  };

  const agregarNuevaFila = (e, key) => {
    handleAdd(e);
  };
  const eliminarNuevaFila = (key) => {
    handleDelete(key);
  };

  useEffect(() => {
    GetTipoSalon(enqueueSnackbar);
    getDefultSalon(campusId, enqueueSnackbar);
  }, [campusId, enqueueSnackbar]);

  useEffect(() => {
    clearTimeout(grupoTimer);
    setTipoSalonesPost([]);
    if (grupoValue.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(campusId, grupoValue, valueCiclo, enqueueSnackbar);
      }, waitTime);
    }
  }, [grupoValue, campusId, valueCiclo, enqueueSnackbar]);

  let indexAnterior = -1;
  const handleAdd = (valueNuevaFila, key) => {
    const newData = [...data];
    const data_nueva = valueNuevaFila;
    const data_map = data_nueva.with(8, "notDissable").with(2, data_nueva[3]);
    newData.push(data_map);
    newData.sort((a, b) => convertirFecha(a[0]) - convertirFecha(b[0]));
    setData(newData);
    setListSalon((prev) => {
      const newPrev = [...prev];
      return insertAtIndex(newPrev, key, "");
    });
    setTipoSalonSelectValue((prev) => {
      const newPrev = [...prev];
      return insertAtIndex(newPrev, key, "");
    });
    setSalonSelectValue((prev) => {
      const newPrev = [...prev];
      return insertAtIndex(newPrev, key, "");
    });
  };
  const handleDelete = (keyDelete) => {
    const newData = [...data];
    newData.splice(keyDelete, 1);
    newData.sort((a, b) => convertirFecha(a[0]) - convertirFecha(b[0]));
    setData(newData);
    setListSalon((prev) => prev.filter((salon, index) => index !== keyDelete));
    setTipoSalonSelectValue((prev) =>
      prev.filter((salon, index) => index !== keyDelete)
    );
    setSalonSelectValue((prev) =>
      prev.filter((salon, index) => index !== keyDelete)
    );
  };
  const handleOpenConfirmationModalAll = () => {
    GetListSalonByTipo(
      defaultTipoSalonSelectValue,
      0,
      dataCustom[0],
      true,
      false
    );
    handleChangeSalon(defaultSalonSelectValue, 0, dataCustom[0], true);
  };

  const handleOpenConfirmationModalIndividual = (index, row) => {
    if (tipoSalonesPost[index]?.delete === true) {
      const reverseSalon = {
        label: `${row[12]} - ${row[5]}`,
        value: row[12],
        name: row[5],
        capacidad: row[6],
        delete: false,
      };
      const reverseSalonTipo = {
        label: `${row[4]}`,
        value: null,
      };
      GetListSalonByTipo(reverseSalonTipo, index, row, false, false);
      handleChangeSalon(reverseSalon, index, row);
    } else {
      GetListSalonByTipo(defaultTipoSalonSelectValue, index, row, false, false);
      handleChangeSalon(defaultSalonSelectValue, index, row);
    }
  };

  return (
    <Grid>
      <Grid sx={12}>
        <Grid
          container
          spacing={2}
          style={{ padding: "10px 0px", alignItems: "center" }}
        >
          <Grid
            xs={1}
            item
            container
            style={{ justifyContent: "flex-end", marginLeft: "-2%" }}
          >
            <label>Campus:</label>
          </Grid>
          <Grid xs={2} item>
            <input className="filter-input" value={campusUser} disabled></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            style={{ justifyContent: "flex-end", marginLeft: "-2.8%" }}
          >
            <label>Ciclo:</label>
          </Grid>
          <Grid xs={1} item>
            <input
              className="filter-input"
              onChange={(e) => setValueCiclo(e.target.value)}
              value={valueCiclo}
            ></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            style={{
              justifyContent: "flex-end",
              marginLeft: "-3%",
              marginRight: "-0.7%",
            }}
          >
            <label>Grupo:</label>
          </Grid>
          <Grid xs={1} item container style={{ marginLeft: "10px" }}>
            <input
              className="filter-input"
              value={grupoValue}
              onChange={handleGrupoValue}
            />
          </Grid>
          <Grid
            xs={1}
            item
            container
            style={{ justifyContent: "flex-end", marginLeft: "-1.2%" }}
          >
            <label>Materia:</label>
          </Grid>
          <Grid xs={4} item container style={{ zIndex: 99 }}>
            <Select
              className="select-modal-container input-tipo"
              classNamePrefix="select-modal"
              options={materiaOptions}
              styles={asyncSelectStyles}
              isLoading={selectMateriaIsLoading}
              onChange={(e) => handleMateria(e)}
              value={materia}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ padding: "10px 0px", alignItems: "center" }}
        >
          <Grid
            xs={1}
            item
            container
            style={{ justifyContent: "flex-end", marginLeft: "-2%" }}
          >
            <label>Escuela:</label>
          </Grid>
          <Grid xs={2} item style={{ zIndex: 99 }}>
            <input className="filter-input" value={escuela} disabled></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            style={{ justifyContent: "flex-end", marginLeft: "-1%" }}
          >
            <label>Dirección:</label>
          </Grid>
          <Grid xs={2} item>
            <input className="filter-input" value={direccion} disabled></input>
          </Grid>
          <Grid
            xs={2}
            item
            container
            justifyContent="flex-end"
            style={{ marginLeft: "-6%" }}
          >
            <label>Clave Profesor:</label>
          </Grid>
          <Grid xs={2} item style={{ zIndex: 90 }}>
            <input
              className="filter-input"
              value={claveProfesor}
              disabled
            ></input>
          </Grid>
          <Grid xs={1} item container justifyContent="flex-end">
            <label>Asignado:</label>
          </Grid>
          <Grid xs={2} item>
            <input className="filter-input" value={asignado} disabled></input>
          </Grid>
        </Grid>
      </Grid>

      <hr class="rounded"></hr>

      <Typography>Salones Reservados</Typography>
      <table className="table_dias table_background">
        <tr>
          <th>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  background: data.length === 0 ? "gray" : "#ed8501",
                  display: "flex",
                  alignItems: "center",
                  padding: 2,
                  borderRadius: 5,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenConfirmationModalAll()}
              >
                <Delete style={{ width: 20, height: 20, color: "white" }} />
              </div>
            </div>
          </th>
          <th style={{ width: "200px" }}>Acciones</th>
          <th style={{ width: "80px" }}>Fecha </th>
          <th style={{ width: "55px" }}>Dia</th>
          <th style={{ width: "155px" }}>Hora Inicio</th>
          <th style={{ width: "155px" }}>Hora Fin</th>
          <th>Salón Anterior</th>
          <th>Tipo Salón</th>
          <th>Salón</th>
          <th>Capacidad</th>
        </tr>

        {data.map((el, i) => {
          let horaFinalAnterior;
          let minutosFinalAnterior;
          let indexActual = el?.[11];

          // Comprobar si la fecha actual es igual a la fecha anterior
          if (indexActual === indexAnterior) {
            horaFinalAnterior = horasFinalesAnteriores[i - 1] || ""; // Obtener la hora de inicio de la fila anterior
            minutosFinalAnterior = minutosFinalesAnteriores[i - 1] || "";
          }

          indexAnterior = indexActual;
          return (
            <tr key={i}>
              <td>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      background: salonSelectValue[i]?.delete ? "green" : "red",
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                      borderRadius: 5,
                      cursor: "pointer",
                      width: 20,
                    }}
                    onClick={() => {
                      handleOpenConfirmationModalIndividual(i, el);
                    }}
                  >
                    <Delete style={{ width: 20, height: 20, color: "white" }} />
                  </div>
                </div>
              </td>
              <td>
                <EditButton onClick={() => agregarNuevaFila(el, i)}>
                  Agregar
                </EditButton>
                <EditButton
                  onClick={() => eliminarNuevaFila(i)}
                  disabled={el?.[8] === "dissable"}
                >
                  Eliminar
                </EditButton>
              </td>
              <td>{el?.[0]}</td>
              <td>{el?.[1]}</td>
              <td>
                <Grid style={{ display: "flex" }}>
                  <input
                    className="filter-input"
                    type="number"
                    min={el?.[9].substring(0, 2)}
                    max={el?.[10].substring(0, 2)}
                    value={horaFinalAnterior || el?.[2].substring(0, 2)}
                    onChange={(e) => {
                      if (el?.[8] === "dissable") {
                        e.target.value = e.target.min;
                        handleHoraInicia(e.target.value, i, "t");
                        const nuevosHorasFinalesAnteriores = [
                          ...horasFinalesAnteriores,
                        ];
                        nuevosHorasFinalesAnteriores[i] = e.target.min;
                        setHorasFinalesAnteriores(nuevosHorasFinalesAnteriores);
                        enqueueSnackbar(`La hora inicio debe ser ${el?.[9]}`, {
                          variant: "error",
                          autoHideDuration: null,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        });
                      } else {
                        handleHoraInicia(e.target.value, i, "i");
                        enqueueSnackbar(
                          "Debe cambiar la hora fin de la fila anterior",
                          {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }
                        );
                      }
                    }}
                  />

                  <input
                    className="filter-input"
                    style={{ marginLeft: "1%" }}
                    type="number"
                    min={el?.[9].substring(3, 5)}
                    max={30}
                    step={30}
                    value={minutosFinalAnterior || el?.[9].substring(3, 5)}
                    onChange={(e) => {
                      if (el?.[8] === "dissable") {
                        enqueueSnackbar(`La hora inicio debe ser ${el?.[9]}`, {
                          variant: "error",
                          autoHideDuration: null,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        });
                      } else {
                        handleMinutosValue(e.target.value, i, "i");
                        enqueueSnackbar(
                          "Debe cambiar los minutos fin de la fila anterior",
                          {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }
                        );
                      }
                    }}
                  />
                </Grid>
              </td>
              <td>
                <Grid style={{ display: "flex" }}>
                  <input
                    className="filter-input"
                    type="number"
                    min={el?.[9].substring(0, 2)}
                    max={el?.[10].substring(0, 2)}
                    defaultValue={el?.[3].substring(0, 2)}
                    onFocus={() => {
                      setIndexAct(el?.[11]);
                      setIndexSig(data[i + 1]?.[11]);
                    }}
                    onChange={(e) => {
                      handleHoraInicia(e.target.value, i, "t");
                      if (indexAct === indexSig) {
                        handleHoraInicia(e.target.value, i + 1, "i");
                        const value = parseInt(e.target.value);
                        const nuevosHorasFinalesAnteriores = [
                          ...horasFinalesAnteriores,
                        ];
                        nuevosHorasFinalesAnteriores[i] = value;
                        setHorasFinalesAnteriores(nuevosHorasFinalesAnteriores);
                      }
                    }}
                    onBlur={(e) => {
                      const value = parseInt(e.target.value);
                      const minValue = parseInt(e.target.min);
                      const maxValue = parseInt(e.target.max);

                      if (
                        (value >= minValue &&
                          data[i]?.[11] !== data[i + 1]?.[11] &&
                          value < maxValue) ||
                        isNaN(value)
                      ) {
                        e.target.value = e.target.max;
                        handleHoraInicia(e.target.value, i, "t");
                        const value = parseInt(e.target.value);
                        const nuevosHorasFinalesAnteriores = [
                          ...horasFinalesAnteriores,
                        ];
                        nuevosHorasFinalesAnteriores[i] = value;
                        setHorasFinalesAnteriores(nuevosHorasFinalesAnteriores);
                        enqueueSnackbar(
                          `El ultimo bloque de la hora fin debe ser ${el?.[10]}`,
                          {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }
                        );
                      }

                      if (value < minValue) {
                        e.target.value = e.target.min;
                        handleHoraInicia(e.target.value, i, "t");
                        const value = parseInt(e.target.value);
                        const nuevosHorasFinalesAnteriores = [
                          ...horasFinalesAnteriores,
                        ];
                        nuevosHorasFinalesAnteriores[i] = value;
                        setHorasFinalesAnteriores(nuevosHorasFinalesAnteriores);
                        enqueueSnackbar(`El valor mínimo es ${minValue}`, {
                          variant: "error",
                          autoHideDuration: null,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        });
                      } else if (value > maxValue) {
                        e.target.value = e.target.max;
                        handleHoraInicia(e.target.value, i, "t");
                        const value = parseInt(e.target.value);
                        const nuevosHorasFinalesAnteriores = [
                          ...horasFinalesAnteriores,
                        ];
                        nuevosHorasFinalesAnteriores[i] = value;
                        setHorasFinalesAnteriores(nuevosHorasFinalesAnteriores);
                        enqueueSnackbar(`El valor máximo es ${maxValue}`, {
                          variant: "error",
                          autoHideDuration: null,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        });
                      } else {
                        handleHoraInicia(e.target.value, i, "t");
                        const value = parseInt(e.target.value);
                        const nuevosHorasFinalesAnteriores = [
                          ...horasFinalesAnteriores,
                        ];
                        nuevosHorasFinalesAnteriores[i] = value;
                        setHorasFinalesAnteriores(nuevosHorasFinalesAnteriores);
                      }
                      if (
                        horasFinalesAnteriores[i] === el?.[10].substring(0, 2)
                      ) {
                        setMaxValue(el?.[10].substring(3, 5));
                      } else {
                        setMaxValue(30);
                      }
                    }}
                  />
                  <input
                    className="filter-input"
                    style={{ marginLeft: "1%" }}
                    type="number"
                    min={0}
                    max={maxValue}
                    step={30}
                    defaultValue={el?.[10].substring(3, 5)}
                    onFocus={() => {
                      setIndexAct(el?.[11]);
                      setIndexSig(data[i + 1]?.[11]);
                    }}
                    onChange={(e) => {
                      if (e.target.value === "00") {
                        return;
                      }
                      const value = parseInt(e.target.value);
                      handleMinutosValue(e.target.value, i, "t");
                      if (indexAct === indexSig) {
                        handleMinutosValue(e.target.value, i + 1, "i");
                        const nuevosMinutosFinalesAnteriores = [
                          ...minutosFinalesAnteriores,
                        ];
                        nuevosMinutosFinalesAnteriores[i] = value;
                        setMinutosFinalesAnteriores(
                          nuevosMinutosFinalesAnteriores
                        );
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === "00") {
                        return;
                      }
                      const value = parseInt(e.target.value);
                      if (
                        horasFinalesAnteriores[i] ===
                          el?.[10].substring(0, 2) &&
                        data[i]?.[11] !== data[i + 1]?.[11]
                      ) {
                        const value = parseInt(el?.[10].substring(3, 5));
                        e.target.value = value;
                        setMaxValue(el?.[10].substring(3, 5));
                        handleMinutosValue(el?.[10].substring(3, 5), i, "t");
                        const nuevosMinutosFinalesAnteriores = [
                          ...minutosFinalesAnteriores,
                        ];
                        nuevosMinutosFinalesAnteriores[i] = value;
                        setMinutosFinalesAnteriores(
                          nuevosMinutosFinalesAnteriores
                        );
                        enqueueSnackbar(
                          `El valor máximo de hora fin es ${el?.[10]}`,
                          {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }
                        );
                        return;
                      }

                      if (value === 0 || value === 30) {
                        handleMinutosValue(value, i, "t");
                        const nuevosMinutosFinalesAnteriores = [
                          ...minutosFinalesAnteriores,
                        ];
                        nuevosMinutosFinalesAnteriores[i] = value;
                        setMinutosFinalesAnteriores(
                          nuevosMinutosFinalesAnteriores
                        );
                      } else if (value < maxValue) {
                        e.target.value = e.target.max;
                        handleMinutosValue(e.target.value, i, "t");
                        const value = parseInt(e.target.value);
                        const nuevosMinutosFinalesAnteriores = [
                          ...minutosFinalesAnteriores,
                        ];
                        nuevosMinutosFinalesAnteriores[i] = value;
                        setMinutosFinalesAnteriores(
                          nuevosMinutosFinalesAnteriores
                        );
                        enqueueSnackbar(
                          `Solo se permiten bloquen de 30 minutos`,
                          {
                            variant: "error",
                            autoHideDuration: null,
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          }
                        );
                      } else if (value > maxValue) {
                        e.target.value = e.target.min;
                        handleMinutosValue(e.target.value, i, "t");
                        const value = parseInt(e.target.value);
                        const nuevosMinutosFinalesAnteriores = [
                          ...minutosFinalesAnteriores,
                        ];
                        nuevosMinutosFinalesAnteriores[i] = value;
                        setMinutosFinalesAnteriores(
                          nuevosMinutosFinalesAnteriores
                        );
                      }
                    }}
                  />
                </Grid>
              </td>
              <td>{`${el?.[4]} - ${el?.[5]}`}</td>
              <td
                className="bg_select"
                style={{ zIndex: 999, position: "relative" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={selectUpsStyles}
                  options={tipoSalon}
                  onChange={(e) => GetListSalonByTipo(e, i, el)}
                  menuPlacement="top"
                  value={tipoSalonSelectValue[i]}
                />
              </td>
              <td
                className="bg_select"
                style={{ zIndex: 999, position: "relative" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={selectUpsStyles}
                  isDisabled={listSalon[i].length === 0}
                  options={listSalon[i]}
                  onChange={(e) => handleChangeSalon(e, i, el)}
                  menuPlacement="top"
                  value={salonSelectValue[i]}
                />
              </td>
              <td>{salonSelectValue[i]?.capacidad}</td>
            </tr>
          );
        })}
      </table>
      <Grid sx={12} style={{ marginTop: "1%", marginLeft: "-2.2%" }}>
        <Grid
          container
          spacing={2}
          style={{ padding: "10px 0px", alignItems: "center" }}
        >
          <Grid xs={1} item container justifyContent="flex-end">
            <label>Creado:</label>
          </Grid>
          <Grid xs={1} item style={{ padding: 2 }}>
            <input className="filter-input" value={creado} disabled></input>
          </Grid>
          <Grid xs={2} item style={{ padding: 2, marginLeft: "0.5%" }}>
            <input className="filter-input" value={creadoPor} disabled></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            justifyContent="flex-end"
            style={{ padding: 2, marginLeft: "5%" }}
          >
            <label>Modificado:</label>
          </Grid>
          <Grid xs={1} item style={{ padding: 2 }}>
            <input className="filter-input" value={modificado} disabled></input>
          </Grid>
          <Grid xs={2} item style={{ padding: 2, marginLeft: "0.5%" }}>
            <input
              className="filter-input"
              value={modificadoPor}
              disabled
            ></input>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
