import { View, Text } from "@react-pdf/renderer";
import { styles } from "../pdf_table/styled";
import { divideArrayIntoParts } from "../Funciones/divideArrayIntoParts";
import HeaderPDFDefaultVertical from "../HeaderPDFDefaultVertical";

export default function ReportPrefecturaPDF({
  title,
  metadata,
  data,
  campus,
  startDate,
  endDate,
  ciclo,
  escuela,
  direccion,
  hora,
  minutos,
}) {
  const Headers = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {metadata &&
          metadata.map((e, i) => (
            <View
              key={i}
              style={{
                width: e.width,
                ...styles.boxHeader,
                justifyContent: e.align,
              }}
            >
              <Text style={styles.cell_v}>{e.name}</Text>
            </View>
          ))}
      </View>
    );
  };
  const splitDataIntoParts = divideArrayIntoParts(data, 28);
  return splitDataIntoParts.map((dataTable, index2) => {
    return (
      <View break={index2 === 0 ? false : true} key={`-${index2}`}>
        <HeaderPDFDefaultVertical title={title} />
        <View style={{ marginTop: 15 }}>
          <View style={{ marginTop: 1 }}>
            {startDate ? (
              <View
                style={{ flexDirection: "row", width: "100%", marginTop: 2 }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Fecha Inicial: {startDate}
                </Text>
                {endDate ? (
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#000000",
                      marginRight: "25px",
                    }}
                  >
                    Fecha Final: {endDate}
                  </Text>
                ) : null}
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Campus: {campus}
                </Text>
              </View>
            ) : null}

            <View style={{ flexDirection: "row", width: "100%", marginTop: 5 }}>
              {ciclo ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "25px",
                  }}
                >
                  Ciclo: {ciclo}
                </Text>
              ) : null}
              <Text style={{ fontSize: 10, color: "#000000", marginLeft: 57 }}>
                Hora: {hora}{`:${minutos}`}
              </Text>
              <Text style={{ fontSize: 10, color: "#000000",marginLeft: 93 }}>
                Dirección: {direccion}
              </Text>
              {escuela ? (
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginLeft: "25px",
                  }}
                >
                  Escuela: {escuela}
                </Text>
              ) : null}
            </View>

            <View
              style={{
                borderLeft: "1px",
                borderLeftColor: "#828282",
                borderTop: "1px",
                borderTopColor: "#828282",
                marginTop: 5,
              }}
            >
              <Headers />
              {dataTable &&
                dataTable.map((e, i) => (
                  <View style={{ flexDirection: "row" }} key={i}>
                    {metadata.map((el, il) => (
                      <View
                        key={il}
                        style={{
                          width: el.width,
                          ...styles.boxRow_v,
                          justifyContent: el.align,
                        }}
                      >
                        {el.fn === null ? (
                          <Text style={{ ...styles.rows_v }}>{e[el.id]}</Text>
                        ) : (
                          <Text> {el.fn(e[el.id])}</Text>
                        )}
                      </View>
                    ))}
                  </View>
                ))}
            </View>
          </View>
        </View>
      </View>
    );
  });
}
