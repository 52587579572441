export const isEnableEditing = (value, number) => {
    if (number === 5) {
        if (['id', 'creado_por', 'creado', 'modificado_por', 'modificado', 'active', 'clave', 'nombre'].includes(value)) {
            return false
        }
        return true
    }
    else if (['id', 'creado_por', 'creado', 'modificado_por', 'modificado', 'active'].includes(value)) {
        return false
    }
    return true
}