import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import SearchInput from "../../Utils/Visuales/SearchInput";
import styles from "./styles.module.css";

function Reactivar() {
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const permisos = useSelector((state) => state.permisos);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [modalBitacoraIsOpen, setModalBitacoraIsOpen] = useState(false);
  //const [loadingBitacora] = useState(false);
  const [dataBitacoraOriginal, setDataBitacoraOriginal] = useState([]);
  const [dataBitacoraFinal, setDataBitacoraFinal] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");

  /* function handleAplicarClick(){
    setDataBitacoraFinal(dataBitacoraOriginal);

    const dataFiltered = dataBitacoraOriginal.filter(item => {
      const fechaItem = moment(item.fecha, "YYYY-MM-DD").valueOf();
      return fechaItem >= moment(fechaInicio, "YYYY-MM-DD").valueOf() && fechaItem <= moment(fechaFinal, "YYYY-MM-DD").valueOf();
      });
      setDataBitacoraFinal(dataFiltered);
  } */

  const [modalActivarData, setModalActivarData] = useState({
    id: "",
    clave: "",
    nombre: "",
    rfc: "",
    estatus: "",
  });

  const [validation, setValidation] = useState({
    accion: false,
  });

  function validateAccion(value) {
    if (value === "") {
      setValidation({ ...validation, accion: false });
    } else {
      setValidation({ ...validation, accion: true });
    }
  }

  const [accion, setAccion] = useState("Activar");
  const [motivo, setMotivo] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const [errorOcurred, setErrorOcurred] = useState(false);

  function openModalActivar(data) {
    setModalIsOpen(true);
    setModalActivarData({
      id: data.id,
      clave: data.nss,
      nombre: data.name,
      rfc: data.RFC,
      estatus: data.status,
    });
  }

  function closeModal() {
    setValidation({ motivo: false, accion: false });
    setAccion("Activar");
    setMotivo("");
    setLoadingModal(false);
  }

  function openBitacora() {
    setModalBitacoraIsOpen(true);
    setShowTable(false);
  }

  /* function handleClick() {
    setShowTable(true);
  } */

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setLoading(true);
    try {
      const consulta = `/docente-reactivar`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        let data = [];
        response.d.forEach((item) => {
          data.push([
            item.nss,
            item.name,
            item.RFC,
            item.status ? "Desbloqueado" : "Bloqueado",
            <div>
              <EditButton onClick={() => openModalActivar(item)} disabled={ permisos['Profesores->Reactivar'].modificar === '1' ? false : true }>
                Editar
              </EditButton>
            </div>,
          ]);
        });
        setDataOriginal(data);
        setDataFinal(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const dummyData = [
      [
        "107922",
        "SANCHEZ ORGANISTA, SILVIA LETICIA",
        "SAOS6112082U0",
        "ARCAYA, IVAN ENRIQUE",
        "2022-10-01 12:25:33",
        "Reingreso al plantel",
      ],
      [
        "200114",
        "RIVERA ZAGACERA, LIBRADO JOSE ANTONIO",
        "RIZL651112PW2",
        "ARCAYA, IVAN ENRIQUE",
        "2022-10-10 15:10:23",
        "Reingreso al plantel",
      ],
      [
        "300148",
        "HERNÁNDEZ, JUAN JOSÉ",
        "LOKIJU41256396",
        "ARCAYA, IVAN ENRIQUE",
        "2022-10-25 9:52:00",
        "Reingreso al plantel",
      ],
      [
        "485621",
        "TORRES BRICEÑO, FRANCISCO ANDRÉS",
        "GFBVHRY452136",
        "ARCAYA, IVAN ENRIQUE",
        "2022-11-01 18:25:41",
        "Reingreso al plantel",
      ],
    ];

    setDataBitacoraOriginal(dummyData);
    /* setDataBitacoraFinal(dummyData); */
  }, []);

  const headers = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "RFC",
      col: 2,
    },
    {
      name: "Estatus",
      col: 3,
    },
    {
      name: "Acción",
      col: 4,
    },
  ];

  const headersBitacora = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "RFC",
      col: 2,
    },
    {
      name: "Modificado por",
      col: 3,
    },
    {
      name: "Fecha Modificado",
      col: 4,
    },
    {
      name: "Motivo",
      col: 4,
    },
  ];

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      else if (row[3].toLowerCase() === text) return true;
      return false;
    });
    setDataFinal(result);
  }
  useEffect(() => {
    if (motivo !== "" || !modalIsOpen) {
      setErrorOcurred(false);
    }
  }, [motivo, modalIsOpen]);
  function searchBitacora(text) {
    text = text.toLowerCase();
    let result = dataBitacoraOriginal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      return false;
    });
    setDataBitacoraFinal(result);
  }

  function resetDataBitacora() {
    setDataBitacoraFinal([]);
  }

  //Busqueda Por Fechas
  const buscarFechas = async () => {
    //console.log("Aqui", fechaInicio);
    if (fechaInicio === "") {
      enqueueSnackbar("Falta llenar campo fecha inicio", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaFinal === "") {
      enqueueSnackbar("Falta llenar campo fecha fin", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaInicio > fechaFinal) {
      enqueueSnackbar("Fecha inicio debe ser menor que fecha fin", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setLoadingModal(true);
    const fecha_inicio = moment(fechaInicio).format("YYYY-MM-DD");
    const fecha_final = moment(fechaFinal).format("YYYY-MM-DD");
    const consulta = `/docente-reactivar/bitacora?fi=${fecha_inicio}&ff=${fecha_final}`;
    let response = await getRequest(consulta);
    if (response.s === "OK") {
      let data = [];
      response.d.forEach((item) => {
        data.push([
          item.clave_profesor,
          item.nombre_profesor,
          item.RFC,
          item.modificado_por,
          item.modificado,
          item.motivo,
        ]);
      });
      setDataBitacoraOriginal(data);
      setDataBitacoraFinal(data);
      enqueueSnackbar(response.m, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      enqueueSnackbar(response.m, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    setLoadingModal(false);
  };

  function handleChangeAccion(ev) {
    setAccion(ev.target.value);
    validateAccion(ev.target.value);
  }

  async function handleSubmit() {
    if (!validation.accion) {
      //Show alert message
      setErrorOcurred(true);
      return;
    }

    if (motivo === "") {
      //Show alert
      setErrorOcurred(true);
      return;
    }
    setLoadingModal(true);
    try {
      const payload = {
        id: modalActivarData.id,
        status: accion,
        motivo: motivo,
      };
      const response = await postRequest("/docente-reactivar", payload);
      if (response.s === "OK") {
        //Update dataFinal status
        let newData = dataOriginal.map((item) => {
          if (item[0] === modalActivarData.clave) {
            item[3] = accion === "activar" ? "Desbloqueado" : "Bloqueado";
          }
          return item;
        });
        fetchData();
        setDataOriginal(newData);
        setDataFinal(newData);
        setModalIsOpen(false);
        setAccion("");
        setMotivo("");
        setModalActivarData({
          id: 0,
          clave: "",
          nombre: "",
          rfc: "",
          status: "",
        });

        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
    setValidation({ accion: false });
    setAccion("Activar");
    setMotivo("");
    setLoadingModal(false);
  }

  function handleChangeMotivo(ev) {
    setMotivo(ev.target.value);
  }

  return (
    <Contenedor title="Profesores/Reactivar">
      <div className="inputs-asignaciones">
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid xs={12} md={4} item>
            <NewButton customText="Bitácora" noIcon onClick={openBitacora} />
          </Grid>
          <Grid xs={12} md={4} item>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
      </div>

      <div style={{ height: "calc(100% - 115px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
          moreCells={8}
        />
      </div>

      <NewModal
        title="Bitácora Activación de Profesor"
        open={modalBitacoraIsOpen}
        handleClose={() => {
          setModalBitacoraIsOpen(false);
          resetDataBitacora();
        }}
        height={600}
        maxWidth={1200}
        loading={loadingModal}
      >
        <div style={{ height: "calc(91% - 115px)", padding: "0 25px" }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: "15px" }}
          >
            <Grid container item xs={9} style={{ width: "100%" }}>
              <Grid xs={2} md={2} style={{ textAlign: "center" }}>
                <label>Fecha inicio:</label>
              </Grid>
              <Grid xs={2} md={3}>
                <TextField
                  className="filter-input-fecha"
                  label="Seleccionar fecha"
                  type="date"
                  onChange={(e) => setFechaInicio(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                />
              </Grid>
              <Grid xs={2} md={2} style={{ textAlign: "center" }}>
                <label className="padding:10px">Fecha fin:</label>
              </Grid>
              <Grid xs={2} md={3}>
                <TextField
                  className="filter-input-fecha"
                  label="Seleccionar fecha"
                  type="date"
                  onChange={(e) => setFechaFinal(e.target.value)}
                  disabled={fechaInicio !== "" ? false : true}
                  InputProps={{
                    inputProps: { min: fechaInicio, shrink: true },
                  }}
                />
              </Grid>
              <Grid xs={2} md={2} justifyContent="flex-end">
                {/* //agregar boton de busqueda */}
                <button
                  onClick={() => {
                    buscarFechas();
                  }}
                  class="NewButton"
                  style={{ display: "flex" }}
                >
                  Buscar
                </button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={3}
              justifyContent="flex-end"
              style={{ marginTop: "60px" }}
            >
              <div className={styles.opcionesBar}>
                <div style={{ float: "right" }}>
                  <SearchInput hideIcon search={searchBitacora} />
                </div>
              </div>
            </Grid>
          </Grid>
          {dataBitacoraFinal && (
            <DataTable
              headers={headersBitacora}
              data={dataBitacoraFinal}
              loading={loading}
              centrar={[1, 2, 3, 4, 5, 6]}
              paginate
              bordes
            />
          )}
        </div>
      </NewModal>

      <NewModal
        title="Activación de Profesor"
        open={modalIsOpen}
        handleClose={() => {
          setModalIsOpen(false);
          setErrorOcurred(false);
          setValidation({ accion: false });
          setAccion("");
          setMotivo("");
          setLoadingModal(false);
        }}
        handleSubmit={handleSubmit}
        loading={loadingModal}
        height={600}
        errorMessage={modalActivarData.errorMessage}
        onClose={closeModal}
      >
        <div style={{ padding: "25px" }}>
          <Grid container>
            {errorOcurred ? (
              <Alert className={styles.alertRoot} severity="error">
                TODOS LOS CAMPOS SON OBLIGATORIOS
              </Alert>
            ) : null}
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Clave Profesor
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={modalActivarData.clave}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Nombre Profesor
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                className="filter-input"
                style={{ width: "350px" }}
                value={modalActivarData.nombre}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                RFC
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                className="filter-input"
                style={{ width: "350px" }}
                value={modalActivarData.rfc}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}></Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <form className="radio-form" style={{ marginLeft: 0 }}>
                <fieldset>
                  <div>
                    <div onChange={handleChangeAccion}>
                      <input
                        type="radio"
                        id="activar"
                        name="accion"
                        value="activar"
                        onChange={handleChangeAccion}
                        disabled={modalActivarData.estatus ? true : false}
                      />
                      <label for="activar">Activar</label>
                      <input
                        type="radio"
                        id="desactivar"
                        name="accion"
                        value="desactivar"
                        onChange={handleChangeAccion}
                        disabled={modalActivarData.estatus ? false : true}
                      />
                      <label for="desactivar">Desactivar</label>
                    </div>
                  </div>
                </fieldset>
              </form>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}></Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <label style={{ display: "block" }}>Motivo:</label>
              <textarea
                onChange={handleChangeMotivo}
                value={motivo}
                style={{ width: "450px", height: "90px", marginTop: "10px" }}
              ></textarea>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default Reactivar;
