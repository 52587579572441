import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import "./index.css";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import moment from "moment";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

function PagosExtraordinario() {
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [req, setReq] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectDataConcepto, setSelectDataConcepto] = useState([]);
  const [listQuincenaInicial, setListQuincenaInicio] = useState([]);
  const [listQuincenaFin, setListQuincenaFin] = useState([]);
  const [conceptoValue, setConceptoValue] = useState("");
  const [quincenaInicioValue, setQuincenaInicioValue] = useState("");
  const [quincenaFinValue, setQuincenaFinValue] = useState("");
  const [selectYear, setSelectYear] = useState({ value: "", label: "" });
  const [profesorValue, setProfesorValue] = useState({
    label: "",
    value: "",
  });
  const [tipoRegistroValue, setTipoRegistroValue] = useState({
    label: "",
    value: "",
  });
  const [valueCampus, setValueCampus] = useState("");

  const [listaYear] = useState([
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
  ]);

  const [listTipoRegistro] = useState([
    { label: "no pagados", value: "no pagados" },
    { label: "pagados", value: "pagados" },
    { label: "Todos", value: "todos" },
  ]);

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  const GetDataTable = async () => {
    let valido = true;
    if (tipoRegistroValue.value === "") {
      enqueueSnackbar("Falta Seleccionar Registro", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    } else if (selectYear.value === "") {
      enqueueSnackbar("Falta Seleccionar un año", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    } else if (!quincenaInicioValue || !quincenaFinValue) {
      enqueueSnackbar(
        "Se necesita seleccionar una Quincena Inicial y Quincena Final",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      valido = false;
    }
    if (valido) {
      setLoading(true);
      const tiopRegistroValueF = tipoRegistroValue.value;
      let consulta = `/reportes/pago-extraordinario?tipo_registro=${tiopRegistroValueF}`;
      if (quincenaInicioValue && quincenaFinValue) {
        consulta += `&fecha_inicio=${quincenaInicioValue.value}&fecha_fin=${quincenaFinValue.value}`;
      }
      if (conceptoValue) consulta += `&id_concepto=${conceptoValue.value}`;
      if (profesorValue?.value !== undefined) {
        consulta += `&id_profesor=${profesorValue.value}`;
      }
      if (valueCampus?.value !== undefined) {
        consulta += `&id_campus=${valueCampus.value}`;
      }

      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });

        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.forEach((e, i) => {
          const objp = Object.values(response.d[i]);
          if (i !== 15) {
            array1[i] = objp;
          }
        });

        const numRows_ = array1[0][0].length;
        for (let step = 0; step < numRows_; step++) {
          response.d.forEach((e, i) => {
            Object.values(response.d[i]).forEach((value) => {
              if (i !== 16) {
                let numj = i - i + step;
                array2[i > 15 ? i - 1 : i] = value[numj];
              }
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        setData(rowsP);
        setDataOriginal(rowsP);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setData([]);
        setDataOriginal([]);
        setLoading(false);
      }
    }
  };

  const GetListQuincena = async (e) => {
    try {
      setLoading(true);
      setSelectYear(e);
      const consulta = `/buscar?tipo=quincena&valor=${e.value}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        const dataObjectInicio = Object.values(response.d).map((e, i) => {
          const data_ = {
            label: `${i + 1} - ${e.fecha_inicio} al ${e.fecha_fin}`,
            value: e.fecha_inicio,
          };
          return data_;
        });

        const dataObjectFin = Object.values(response.d).map((e, i) => {
          const data_ = {
            label: `${i + 1} - ${e.fecha_inicio} al ${e.fecha_fin}`,
            value: e.fecha_fin,
          };
          return data_;
        });

        setListQuincenaInicio(dataObjectInicio);
        setListQuincenaFin(dataObjectFin);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const GetDataConcepto = async (e) => {
    try {
      setLoading(true);
      const consulta = `/catalogo?id=6`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        const data = [];
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let descripcion = response.d.elementos[2].descripcion;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${descripcion[i]}` });
        });
        setSelectDataConcepto(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  useEffect(() => {
    GetDataConcepto();
    setValueCampus(
      campusUser?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  const headers = [
    {
      name: "Folio",
      id: "folio",
      col: 0,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 2,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 3,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 4,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Número Quincena",
      id: "numero",
      col: 5,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Concepto",
      id: "cpto",
      col: 6,
      width: "7%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción",
      id: "descripcion",
      col: 7,
      width: "14%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 8,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 9,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre de la Materia",
      id: "materia",
      col: 10,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Cantidad",
      id: "cant",
      col: 11,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Status",
      id: "stt",
      col: 12,
      width: "7%",
      align: "center",
      fn: null,
    },
    {
      name: "Usuario Alta",
      id: "usuario_alta",
      col: 13,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Alta",
      id: "fecha_alta",
      col: 14,
      width: "14%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha env Nómina",
      id: "fecha_env_nomina",
      col: 15,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Comentario",
      id: "comentario",
      col: 16,
      width: "18%",
      align: "center",
      fn: null,
    },
  ];

  const DownloadPDF = async () => {
    if (quincenaInicioValue.label !== quincenaFinValue.label) {
      enqueueSnackbar("No se puede generar PDF de quincenas diferentes.", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setReq(true);
      const data_ = [];
      for (let i = 0; i < data.length; i++) {
        data_.push({
          folio: data[i][0],
          campus: data[i][1],
          clave_profesor: data[i][2],
          nombre_profesor: data[i][3],
          fecha: data[i][4],
          numero: data[i][5],
          cpto: data[i][6],
          descripcion: data[i][7],
          grupo: data[i][8],
          clave_materia: data[i][9],
          materia: data[i][10],
          cant: data[i][11],
          stt: data[i][12],
          usuario_alta: data[i][13],
          fecha_alta: data[i][14],
          fecha_env_nomina: data[i][15],
          comentario: data[i][16],
          importe: data[i][17],
        });
      }
      var fecha = moment().format("DD/MM/YYYY");
      var hora = moment().format("HH:mm:ss");
      const totalBoxDataObject = {
        header: {
          title: "Concepto",
          hour: "Horas",
          importTotal: "Importe",
          unit: "Unidades",
        },
        total: {
          title: "Sub Total",
          hour: 0,
          importTotal: 0,
          unit: 0,
        },
      };
      data_.forEach((element) => {
        if (totalBoxDataObject[element.cpto] === undefined) {
          totalBoxDataObject[element.cpto] = {
            title: element.descripcion,
            hour: element.importe === "importe_de_horas" ? element.cant : 0,
            importTotal: element.importe === "importe" ? element.cant : 0,
            unit: element.importe === "importe_de_unidades" ? element.cant : 0,
          };
        } else {
          totalBoxDataObject[element.cpto].hour =
            element.importe === "importe_de_horas"
              ? (
                  parseFloat(element.cant) +
                  parseFloat(totalBoxDataObject[element.cpto].hour)
                ).toFixed(2)
              : 0;
          totalBoxDataObject[element.cpto].importTotal =
            element.importe === "importe"
              ? (
                  parseFloat(element.cant) +
                  parseFloat(totalBoxDataObject[element.cpto].importTotal)
                ).toFixed(2)
              : 0;
          totalBoxDataObject[element.cpto].unit =
            element.importe === "importe_de_unidades"
              ? (
                  parseFloat(element.cant) +
                  parseFloat(totalBoxDataObject[element.cpto].unit)
                ).toFixed(2)
              : 0;
        }
        totalBoxDataObject.total.hour =
          element.importe === "importe_de_horas"
            ? (
                parseFloat(element.cant) +
                parseFloat(totalBoxDataObject.total.hour)
              ).toFixed(2)
            : totalBoxDataObject.total.hour;
        totalBoxDataObject.total.importTotal =
          element.importe === "importe"
            ? (
                parseFloat(element.cant) +
                parseFloat(totalBoxDataObject.total.importTotal)
              ).toFixed(2)
            : totalBoxDataObject.total.importTotal;
        totalBoxDataObject.total.unit =
          element.importe === "importe_de_unidades"
            ? (
                parseFloat(element.cant) +
                parseFloat(totalBoxDataObject.total.unit)
              ).toFixed(2)
            : totalBoxDataObject.total.unit;
      });
      const newHeader = headers.filter((element) => element.name !== "Campus");
      const doc = (
        <DocumentPDFTable
          metadata={newHeader ?? []}
          data={data_ ?? []}
          title={`Reportes Pagos Extraordinarios`}
          campus={campusName}
          startDate={quincenaInicioValue.label}
          endDate={quincenaFinValue.label}
          totalBox={totalBoxDataObject}
        />
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      // AGREGADO EL AWAIT PARA QUE MUESTRE AL MENOS LA ANIMACION
      saveAs(blob, `Reporte_pagos_extraordinarios_${fecha}_${hora}.pdf`);
      setReq(false);
    }
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        Folio: e[0],
        Campus: e[1],
        "Clave profesor": e[2],
        "Nombre Profesor": e[3],
        Fecha: e[4],
        "Número Quincena": e[5],
        Concepto: e[6],
        Descripción: e[7],
        Grupo: e[8],
        "Clave Materia": e[9],
        "Nombre de la Materia": e[10],
        Cantidad: e[11],
        Status: e[12],
        "Usuario Alta": e[13],
        "Fecha Alta": e[14],
        "Fecha env Nómina": e[15],
        Comentario: e[16],
      };
      return data2;
    });

    var fecha = moment().format("DD/MM/YYYY");
    var hora = moment().format("HH:mm:ss");
    ExportCSV(data_, `Reporte_pagos_extraordinarios_${fecha}_${hora}`);
    setReq(false);
  };

  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Pagos Extraordinarios">
        <Grid container>
          <Grid item xs={9}>
            <Grid
              container
              spacing={2}
              style={{ padding: "10px 0px" }}
              className="nuevoUsuarioForm">
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 91 }}>
                <label style={{ margin: "2px 5px" }}>Registro (*):</label>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  options={listTipoRegistro}
                  onChange={(e) => setTipoRegistroValue(e)}
                />
              </Grid>
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 90 }}>
                <label style={{ float: "right" }}>Año (*):</label>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  options={listaYear}
                  onChange={(e) => GetListQuincena(e)}
                />
              </Grid>
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 90 }}>
                <label>Quincena Inicial (*):</label>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isDisabled={!listQuincenaInicial.length > 0}
                  //value={profesorValue}
                  options={listQuincenaInicial}
                  onChange={setQuincenaInicioValue}
                />
              </Grid>
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 90 }}>
                <label>Quincena Final (*):</label>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isDisabled={!listQuincenaFin.length > 0}
                  //value={profesorValue}
                  options={listQuincenaFin}
                  onChange={setQuincenaFinValue}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ padding: 10 }}
              className="detailsForm">
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 89 }}>
                <label style={{ float: "right" }}>Campus:</label>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusUser}
                  onChange={setValueCampus}
                  isClearable={true}
                />
              </Grid>
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 89 }}>
                <label style={{ float: "right" }}>Concepto:</label>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectDataConcepto}
                  onChange={setConceptoValue}
                />
              </Grid>
              <Grid
                xs={12}
                sm={3}
                item
                container
                style={{ justifyContent: "space-between", zIndex: 89 }}>
                <label style={{ float: "right" }}>Clave Profesor:</label>
                <AsyncSelect
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  onChange={setProfesorValue}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container style={{ padding: "20px 10px" }}>
              <Grid justifyContent={"center"} container>
                <EditButton
                  onClick={() => GetDataTable()}
                  disabled={loading}
                  style={{ padding: "10px 25px" }}>
                  Generar
                </EditButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained">
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadPDF}
                    disabled={req}
                    variant="contained">
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 275px)" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
}

export default PagosExtraordinario;
