import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import Contenedor from "../../Utils/Visuales/Contenedor";

import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSnackbar } from "notistack";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import NewButton from "../../Utils/Botones/NewButton";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import MultiSelect from "../../Utils/Visuales/MultiSelect";
import NewModal from "../../Utils/Visuales/NewModal";
import SearchInput from "../../Utils/Visuales/SearchInput";
import moment from "moment";

function Mensajes() {
  const headers = [
    {
      name: "Folio",
      col: 0,
    },
    {
      name: "Fecha Creado",
      col: 1,
    },
    {
      name: "Mensaje",
      col: 2,
    },
    {
      name: "Estatus",
      col: 3,
    },
    {
      name: "Acciones",
      col: 4,
    },
  ];

  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalComentario, setLoadingModalComentario] = useState(false);
  const [modalConsultaIsOpen, setModalConsultaIsOpen] = useState(false);
  const [modalComentarioIsOpen, setModalComentarioIsOpen] = useState(false);
  const [modalNuevoMensaje, setModalNuevoMensaje] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const campusId = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);
  const [profesorValue, setProfesorValue] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState({
    label: "",
    value: "",
  });
  const [input, setInput] = useState({
    motivo: "",
    fechaInicial: "",
    fechaFinal: "",
    message: "",
  });
  const [escuelaMultiOptions, setEscuelaMultiOptions] = useState([]);
  const [direccionMultiOptions, setDireccionMultiOptions] = useState([]);
  const [profesorMulti, setProfesorMulti] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState([]);
  const [idConsulta, setIdConsulta] = useState("");
  const [valueEstatus, setValueEstatus] = useState("");
  const [valueFolio, setValueFolio] = useState("");
  const [valueCreado, setValueCreado] = useState("");
  const [valueCreadoPor, setValueCreadoPor] = useState("");
  const [valueModificado, setValueModificado] = useState("");
  const [valueModificadoPor, setValueModificadoPor] = useState("");
  const [dtp_inicio, set_dtp_inicio] = React.useState("");
  const [dtp_fin, set_dtp_fin] = React.useState("");
  const [quantityEscuela, setQuantityEscuela] = useState(0);
  const [valueMultiEscuela, setValueMultiEscuela] = useState("");
  const [quantityDireccion, setQuantityDireccion] = useState(0);
  const [valueMultiDireccion, setValueMultiDireccion] = useState("");
  const [marginBottom, setMarginBottom] = useState(1);
  const [paddingBottom, setPaddingBottom] = useState(7);
  const [minHeight, setMinHeight] = useState(30);
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [ModalAlertaProfesor, setModalAlertaProfesor] = useState(false);
  const [ModalAlertaProfesorAceptar, setModalAlertaProfesorAceptar] =
    useState(false);

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const asyncMultiSelectStyles = {
    container: (styles) => ({
      ...styles,
      zIndex: 9,
      height: "30px",
      width: "95%",
      marginBottom: `${marginBottom}%`,
    }),
    control: (base) => ({
      ...base,
      paddingTop: "5px",
      paddingBottom: `${paddingBottom}%`,
    }),
    valueContainer: (base) => ({
      ...base,
      minHeight: minHeight,
    }),
  };

  async function openConsulta(id, message, estatus) {
    setLoadingModal(true);
    setModalConsultaIsOpen(true);
    try {
      let consulta = `/mensajes?id=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setIdConsulta(id);
        set_dtp_inicio(
          `${response.d.fecha_inicio} - ${response.d.hora_inicio}`
        );
        set_dtp_fin(`${response.d.fecha_fin} - ${response.d.hora_fin}`);
        setValueMultiEscuela(response.d.escuela.join(", "));
        setValueFolio(response.d.folio);
        setValueMultiDireccion(response.d.direccion.join(", "));
        setProfesorMulti(response.d.profesor.join(", "));
        setInput({
          ...input,
          message: response.d.message,
        });
        setValueEstatus(response.d.estatus);
        setValueCreado(response.d.creado);
        setValueCreadoPor(response.d.creado_por);
        setValueModificado(response.d.modificado);
        setValueModificadoPor(response.d.modificado_por);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const openModalComentario = () => {
    setModalComentarioIsOpen(true);
  };

  function nuevoMensaje() {
    setLoading(true);
    setModalNuevoMensaje(true);
    setLoading(false);
  }

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogoMulti(7).then((data) => setDireccionMultiOptions(data));
    consultarCatalogo(9).then((data) => setSelectEscuela(data));
    consultarCatalogoMulti(9).then((data) => setEscuelaMultiOptions(data));
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({
            id: id[i],
            value: `${clave[i]} - ${nombre[i]}`,
            label: `${clave[i]} - ${nombre[i]}`,
          });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const consultarCatalogoMulti = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [{ label: "Todas", value: "0" }];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const waitTime = 2000;
  let timer;

  async function searchProfesor(valor) {
    try {
      const options = [];
      const request = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            id: item.id,
            label: item.nss + " - " + item.nombre,
            value: item.nss + " - " + item.nombre,
          });
        });
        handleChangeMultiProfesor();
        return options;
      } else {
        handleChangeMultiProfesor();
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      handleChangeMultiProfesor();
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const promiseProfesor = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length > 2) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }

  const RealizarBusqueda = async () => {
    let valido = true;
    if (input.fechaInicial === "") {
      enqueueSnackbar("Falta llenar campo Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (input.fechaFinal === "") {
      enqueueSnackbar("Falta llenar campo Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      setDataFinal([]);
      setDataOriginal([]);
      try {
        const datos = [];
        let url = `/mensajes?fi=${input.fechaInicial}&ff=${input.fechaFinal}&id_campus=${campusId}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.id}`;
        if (selectedEscuela.value !== "")
          url += `&id_escuela=${selectedEscuela.id}`;
        if (profesorValue) url += `&id_profesor=${profesorValue.id}`;
        const consulta = url;
        const response = await getRequest(consulta);

        if (response.s === "OK") {
          const nro = response.d["Folio"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d["Folio"][i],
              response.d["Fecha Creado"][i],
              response.d["Mensaje"][i],
              response.d["Estatus"][i],
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <EditButton
                  onClick={() =>
                    openConsulta(
                      response.d["Folio"][i],
                      response.d["Mensaje"][i],
                      response.d["Estatus"][i]
                    )
                  }
                >
                  Consulta
                </EditButton>
              </div>,
            ]);
          }
          setDataFinal(datos);
          setDataOriginal(datos);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  function handleChange(e) {
    e.preventDefault();
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  async function handleChangeMultiProfesor() {
    if (profesorMulti.length === 1) {
      setMarginBottom(5.2);
      setPaddingBottom(12);
      setMinHeight(60);
    } else if (profesorMulti.length > 5) {
      setMarginBottom(22);
      setPaddingBottom(32);
      setMinHeight(180);
    } else {
      setMarginBottom(parseFloat(1 + 4.2 * profesorMulti.length - 1));
      setPaddingBottom(parseInt(7 + 5 * profesorMulti.length - 1));
      setMinHeight(parseInt(30 + 30 * profesorMulti.length - 1));
    }
  }

  function DissabledDate() {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate();
    mm = today.getMonth() + 1;
    yyyy = today.getFullYear();
    var mesStr = `${mm}`;
    var mes = "";
    if (mesStr.length === 1) {
      mes = `0${mesStr}`;
    } else {
      mes = mesStr;
    }
    return yyyy + mes + dd;
  }

  async function handlePreSubmitNuevoMensaje() {
    if (profesorMulti.length > 0) {
      setModalAlertaProfesor(true);
    }
    if (ModalAlertaProfesorAceptar === true) {
      handleSubmitNuevoMensaje();
    }
    if (
      (input.message.length > 0 && valueMultiEscuela.length > 0) ||
      (valueMultiDireccion.length > 0 && input.message.length)
    ) {
      setModalAlertaProfesor(true);
    }
  }
  async function handleSubmitNuevoMensaje() {
    let valido = true;
    if (dtp_inicio === null) {
      enqueueSnackbar("Campo Fecha Inicio es obligatorio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (dtp_fin === null) {
      enqueueSnackbar("Campo Fecha Fin es obligatorio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (input.message === "") {
      enqueueSnackbar("Campo Message es obligatorio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (
      isNaN(dtp_inicio.$D) ||
      isNaN(dtp_inicio.$H) ||
      isNaN(dtp_inicio.$M) ||
      isNaN(dtp_inicio.$W) ||
      isNaN(dtp_inicio.$m) ||
      isNaN(dtp_inicio.$H) ||
      isNaN(dtp_inicio.$y) ||
      error1 === "invalidDate"
    ) {
      enqueueSnackbar("Fecha Inicio tiene una Fecha u Hora Inválida", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (
      isNaN(dtp_fin.$D) ||
      isNaN(dtp_fin.$H) ||
      isNaN(dtp_fin.$M) ||
      isNaN(dtp_fin.$W) ||
      isNaN(dtp_fin.$m) ||
      isNaN(dtp_fin.$H) ||
      isNaN(dtp_fin.$y) ||
      error2 === "invalidDate"
    ) {
      enqueueSnackbar("Fecha Fin tiene una Fecha u Hora Inválida", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }

    //NUEVAS VALIDACIONES PARA LAS FECHAS Y HORARIOS
    const dtp_inicial_min = parseInt(DissabledDate());
    let dtp_inicial = dtp_inicio.$d.toISOString().split("T")[0];
    let dtp_final = dtp_fin.$d.toISOString().split("T")[0];
    let dtp_inicial_max = "";
    let dtp_final_min = "";

    dtp_inicial =
      dtp_inicial.substring(0, 4) +
      dtp_inicial.substring(5, 7) +
      dtp_inicial.substring(8, 10);
    dtp_final =
      dtp_final.substring(0, 4) +
      dtp_final.substring(5, 7) +
      dtp_final.substring(8, 10);

    dtp_inicial = parseInt(dtp_inicial);
    dtp_final = parseInt(dtp_final);

    if (dtp_final !== dtp_inicial) {
      dtp_final_min = dtp_inicial;
    } else if (parseInt(dtp_inicio.$H) < parseInt(dtp_fin.$H)) {
      dtp_final_min = dtp_inicial;
    } else if (
      parseInt(dtp_inicio.$H) === parseInt(dtp_fin.$H) &&
      parseInt(dtp_inicio.$m) < parseInt(dtp_fin.$m)
    ) {
      dtp_final_min = dtp_inicial;
    } else {
      enqueueSnackbar(
        "La hora/minutos seleccionados es superior a la Hora de Fin",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      setModalAlertaProfesorAceptar(false);
      valido = false;
    }

    if (dtp_inicial !== dtp_final) {
      dtp_inicial_max = dtp_final;
    } else if (parseInt(dtp_fin.$H) > parseInt(dtp_inicio.$H)) {
      dtp_inicial_max = dtp_final;
    } else if (
      parseInt(dtp_fin.$H) === parseInt(dtp_inicio.$H) &&
      parseInt(dtp_fin.$m) > parseInt(dtp_inicio.$m)
    ) {
      dtp_inicial_max = dtp_final;
    } else {
      enqueueSnackbar(
        "La hora/minutos seleccionados es inferior a la Hora de Inicio",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
      setModalAlertaProfesorAceptar(false);
      valido = false;
    }

    if (dtp_inicial < dtp_inicial_min) {
      enqueueSnackbar("La Fecha Inicial es inferior al dia de hoy", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setModalAlertaProfesorAceptar(false);
      valido = false;
    } else if (dtp_inicial > dtp_inicial_max) {
      enqueueSnackbar("La Fecha Fin es inferior a la Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setModalAlertaProfesorAceptar(false);
      valido = false;
    } else if (dtp_final < dtp_final_min) {
      enqueueSnackbar("La Fecha Fin es inferior a la Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setModalAlertaProfesorAceptar(false);
      valido = false;
    }
    if (valido) {
      try {
        setLoading(true);
        let profesorFinal = "";

        if (profesorMulti.length === 0) {
          profesorFinal = "";
        } else if (profesorMulti.length > 1) {
          for (let i = 0; i < profesorMulti.length; i++) {
            if (profesorMulti[i]["id"] !== undefined) {
              profesorFinal = profesorFinal + profesorMulti[i]["id"] + ",";
            }
          }
          profesorFinal = profesorFinal.substring(0, profesorFinal.length - 1);
        } else {
          profesorFinal = profesorMulti[0]["id"];
        }

        let fechaIFormateada = `${dtp_inicio.$y}-${dtp_inicio.$M + 1}-${
          dtp_inicio.$D
        } ${dtp_inicio.$H}:${dtp_inicio.$m}`;
        let fechaI = moment(fechaIFormateada).format("YYYY-MM-DD HH:mm");

        let fechaFFormateada = `${dtp_fin.$y}-${dtp_fin.$M + 1}-${dtp_fin.$D} ${
          dtp_fin.$H
        }:${dtp_fin.$m}`;
        let fechaF = moment(fechaFFormateada).format("YYYY-MM-DD HH:mm");

        const payload = {
          id_campus: campusId,
          fi: fechaI,
          ff: fechaF,
          id_escuela: valueMultiEscuela,
          id_direccion: valueMultiDireccion,
          id_profesor: profesorFinal,
          message: input.message,
        };
        const response = await postRequest("/mensajes", payload);

        if (response.s === "OK") {
          set_dtp_inicio(null);
          set_dtp_fin(null);
          setQuantityEscuela(0);
          setValueMultiEscuela("");
          setQuantityDireccion(0);
          setValueMultiDireccion("");
          setValueFolio("");
          setProfesorMulti("");
          setInput({
            ...input,
            message: "",
          });
          setMarginBottom(1);
          setPaddingBottom(7);
          setMinHeight(30);
          setModalNuevoMensaje(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setModalAlertaProfesorAceptar(false);
        }
      } catch (error) {
        alert("La Fecha Ingresada es Inválida");
        console.log(error);
        setModalAlertaProfesorAceptar(false);
      } finally {
        setLoading(false);
        setModalAlertaProfesorAceptar(false);
      }
    }
  }

  const handleCambiarEstado = async () => {
    try {
      setLoadingModalComentario(true);
      const json = {
        id: idConsulta,
      };
      const response = await postRequest("/mensajes/update", json);
      if (response.s === "OK") {
        setValueEstatus("Cancelado");
        let newArr = [...dataFinal];
        newArr.forEach((item) => {
          if (item[0] === idConsulta) newArr[idConsulta][3] = "Cancelado";
        });
        setDataFinal(newArr);
        setDataOriginal(newArr);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log();
    } finally {
      setLoadingModalComentario(false);
      setModalComentarioIsOpen(false);
    }
  };

  return (
    <Contenedor title="Mensajes">
      <Grid style={{ padding: 10 }}>
        <Grid
          container
          spacing={2}
          style={{
            padding: "10px 0px",
            alignItems: "center",
            textAlign: "end",
          }}
        >
          <Grid item xs={1}>
            <NewButton
              noIcon
              style={{
                zIndex: 99,
                display:
                  permisos["Mensajes"].escribir === "1" ? "block" : "none",
              }}
              customText="Nuevo"
              onClick={nuevoMensaje}
            />
          </Grid>

          <Grid item xs={1}>
            <label>Fecha Inicio(*):</label>
          </Grid>
          <Grid item xs={2} style={{ zIndex: 99 }}>
            <input
              className="filter-input"
              value={input.fechaInicial}
              name="fechaInicial"
              type="date"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={1}>
            <label>Fecha Fin(*):</label>
          </Grid>
          <Grid item xs={2} style={{ zIndex: 99 }}>
            <input
              className="filter-input"
              value={input.fechaFinal}
              name="fechaFinal"
              type="date"
              disabled={input.fechaInicial !== "" ? false : true}
              min={input.fechaInicial}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={5}>
            <NewButton
              noIcon
              style={{ padding: "5px 15px" }}
              onClick={() => RealizarBusqueda()}
              customText="Buscar"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{
            padding: "10px 0px",
            alignItems: "center",
            textAlign: "end",
            marginTop: "-1%",
          }}
        >
          <Grid item xs={1} />
          <Grid item xs={1}>
            <label>Dirección:</label>
          </Grid>
          <Grid item xs={2} style={{ zIndex: 98 }}>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              isClearable={true}
              value={valueDireccion}
              options={optionsDireccion}
              onChange={setValueDireccion}
            />
          </Grid>
          <Grid xs={1} item>
            <label>Escuela:</label>
          </Grid>
          <Grid xs={2} item style={{ zIndex: 98 }}>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              isClearable={true}
              options={selectEscuela}
              onChange={setSelectedEscuela}
            />
          </Grid>
          <Grid xs={1} item>
            <label>Clave Profesor:</label>
          </Grid>
          <Grid xs={2} item>
            <AsyncSelect
              className="select-modal-container"
              classNamePrefix="select-modal"
              isClearable={true}
              styles={asyncSelectStyles}
              loadOptions={promiseProfesor}
              onChange={setProfesorValue}
              placeholder="Buscar Profesor"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid style={{ marginLeft: "auto" }} xs={2}>
          <SearchInput hideIcon disabled search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 185px)", marginTop: "0.5%" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
        />
      </div>

      <NewModal
        maxWidth={600}
        title="Alerta"
        handleClose={(e) => setModalAlertaProfesor(false)}
        handleSubmit={(e) => {
          handleSubmitNuevoMensaje();
          setModalAlertaProfesorAceptar(true);
          setModalAlertaProfesor(false);
        }}
        open={ModalAlertaProfesor}
      >
        <h4>
          El mensaje será enviado a todos los profesores, ¿Desea continuar?
        </h4>
      </NewModal>

      <NewModal
        title="Nuevo Mensaje"
        open={modalNuevoMensaje}
        handleClose={() => {
          setModalNuevoMensaje(false);
          set_dtp_inicio(null);
          set_dtp_fin(null);
          setQuantityEscuela(0);
          setValueMultiEscuela("");
          setQuantityDireccion(0);
          setValueMultiDireccion("");
          setValueFolio("");
          setProfesorMulti("");
          setInput({
            ...input,
            message: "",
          });
          setMarginBottom(1);
          setPaddingBottom(7);
          setMinHeight(30);
          setError1("");
          setError2("");
        }}
        height={650}
        loading={loading}
        mensaje
        handleSubmit={handlePreSubmitNuevoMensaje}
      >
        <div style={{ padding: "25px" }}>
          <Grid container alignItems="center">
            <div style={{ display: "flex", marginLeft: "1.5%" }}>
              <Grid
                item
                xs={3}
                md={5}
                style={{ marginTop: "45px", marginLeft: "-11%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Fecha Inicio(*):
                </label>
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  marginTop: "30px",
                  marginRight: "5%",
                  zIndex: 99,
                  width: "215px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    className="filter-input"
                    ampm={false}
                    onError={(newError) => setError1(newError)}
                    value={dtp_inicio}
                    inputFormat="DD-MM-YYYY HH:mm"
                    onChange={(newValue) => set_dtp_inicio(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={3}
                md={4}
                style={{ marginTop: "45px", marginLeft: "-5.5%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Fecha Fin(*):
                </label>
              </Grid>
              <Grid item xs={7} style={{ marginTop: "30px", zIndex: 99 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    className="filter-input"
                    ampm={false}
                    value={dtp_fin}
                    onError={(newError) => setError2(newError)}
                    inputFormat="DD/MM/YYYY HH:mm:ss"
                    onChange={(newValue) => set_dtp_fin(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </div>
            <Grid container style={{ marginTop: "-1%" }}>
              <Grid
                item
                xs={3}
                style={{ marginTop: "48px", marginLeft: "-11%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Escuela:
                </label>
              </Grid>
              <Grid
                item
                xs={3}
                md={9}
                style={{ marginTop: "35px", zIndex: "99" }}
                className="nuevoUsuarioForm"
              >
                <MultiSelect
                  fromUser={true}
                  loading={false}
                  options={escuelaMultiOptions}
                  setCantidad={setQuantityEscuela}
                  cantidad={quantityEscuela}
                  onSet={setValueMultiEscuela}
                  selecteds={valueMultiEscuela}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                style={{ marginTop: "28px", marginLeft: "-11%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Dirección:
                </label>
              </Grid>
              <Grid
                item
                xs={3}
                md={9}
                className="inputs-excepciones nuevoUsuarioForm"
                style={{ marginTop: "2%", zIndex: 98 }}
              >
                <MultiSelect
                  fromUser={true}
                  loading={false}
                  options={direccionMultiOptions}
                  setCantidad={setQuantityDireccion}
                  cantidad={quantityDireccion}
                  onSet={setValueMultiDireccion}
                  selecteds={valueMultiDireccion}
                />
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "32px", marginLeft: "-11%" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Profesor:
              </label>
            </Grid>
            <Grid item xs={7} sm={9} style={{ marginTop: "30px", zIndex: 97 }}>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={promiseProfesor}
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                styles={asyncMultiSelectStyles}
                value={profesorMulti}
                onChange={setProfesorMulti}
              />
            </Grid>
            <Grid item xs={9} style={{ marginTop: "7%", marginLeft: "1%" }}>
              <label style={{ display: "block" }}>Message(*):</label>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "100%",
                  marginLeft: "17.5%",
                  height: 150,
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                name="message"
                value={input.message}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Consulta"
        open={modalConsultaIsOpen}
        handleClose={() => {
          set_dtp_inicio(null);
          set_dtp_fin(null);
          setValueMultiEscuela("");
          setValueMultiDireccion("");
          setValueFolio("");
          setProfesorMulti("");
          setInput({
            ...input,
            message: "",
          });
          setValueEstatus("");
          setValueCreado("");
          setValueCreadoPor("");
          setValueModificado("");
          setValueModificadoPor("");
          setModalConsultaIsOpen(false);
        }}
        height={650}
        loading={loadingModal}
        noButtons
      >
        <div style={{ padding: "25px" }}>
          <Grid container alignItems="center">
            <div
              style={{
                display: "flex",
                marginLeft: "1.5%",
                marginTop: "-4%",
                marginBottom: "18px",
              }}
            >
              <Grid
                item
                xs={3}
                md={5}
                style={{ marginTop: "35px", marginLeft: "-7%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Folio
                </label>
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  marginTop: "30px",
                  marginRight: "5%",
                  zIndex: 99,
                  width: "208px",
                }}
              >
                <input
                  disabled
                  className="filter-input"
                  value={valueFolio}
                  style={{ width: "105%", fontSize: "16px" }}
                />
              </Grid>
            </div>
            <div
              style={{ display: "flex", marginLeft: "1.5%", marginTop: "-4%" }}
            >
              <Grid
                item
                xs={3}
                md={5}
                style={{ marginTop: "35px", marginLeft: "-7%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Fecha Inicio(*)
                </label>
              </Grid>
              <Grid
                item
                xs={7}
                style={{
                  marginTop: "30px",
                  marginRight: "5%",
                  zIndex: 99,
                  width: "208px",
                }}
              >
                <input
                  disabled
                  className="filter-input"
                  value={dtp_inicio}
                  style={{ width: "105%", fontSize: "16px" }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                md={5}
                style={{ marginTop: "35px", marginLeft: "-1%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Fecha Fin(*)
                </label>
              </Grid>
              <Grid
                item
                xs={7}
                style={{ marginTop: "30px", zIndex: 99, marginRight: "-1%" }}
              >
                <input
                  disabled
                  className="filter-input"
                  value={dtp_fin}
                  style={{ width: "105%", fontSize: "16px" }}
                />
              </Grid>
            </div>
            <Grid container style={{ marginTop: "-2.5%" }}>
              <Grid
                item
                xs={3}
                style={{ marginTop: "48px", marginLeft: "-11%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Escuela
                </label>
              </Grid>
              <Grid
                item
                xs={3}
                md={9}
                row={3}
                style={{ marginTop: "35px", zIndex: "99" }}
                className="nuevoUsuarioForm"
              >
                <textarea
                  disabled
                  className="filter-input"
                  value={valueMultiEscuela}
                  style={{ fontSize: "16px", height: "80px", resize: "none" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={3}
                style={{ marginTop: "28px", marginLeft: "-11%" }}
              >
                <label
                  style={{
                    float: "right",
                    marginRight: "10px",
                    textAlign: "end",
                  }}
                >
                  Dirección
                </label>
              </Grid>
              <Grid
                item
                xs={3}
                md={9}
                className="inputs-excepciones nuevoUsuarioForm"
                style={{ marginTop: "2%", zIndex: 98 }}
              >
                <textarea
                  disabled
                  className="filter-input"
                  value={valueMultiDireccion}
                  style={{ fontSize: "16px", height: "80px", resize: "none" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ marginTop: "32px", marginLeft: "-11%" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Profesor
              </label>
            </Grid>
            <Grid item xs={7} sm={9} style={{ marginTop: "30px", zIndex: 97 }}>
              <textarea
                disabled
                className="filter-input"
                value={profesorMulti}
                style={{ fontSize: "16px", height: "80px", resize: "none" }}
              />
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginTop: "35px", marginLeft: "10.5%" }}
            >
              <label style={{ display: "block" }}>Message (*):</label>
              <textarea
                disabled
                className="filter-input"
                style={{
                  width: "580px",
                  height: "100px",
                  marginTop: "10px",
                  fontSize: "16px",
                  resize: "none",
                }}
                name="message"
                value={input.message}
              ></textarea>
            </Grid>
            <Grid item xs={7} md={1} />
            <Grid item xs={3} style={{ marginTop: "30px", marginLeft: "-11%" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Estatus
              </label>
            </Grid>
            <Grid
              item
              xs={3}
              md={9}
              className="inputs-excepciones nuevoUsuarioForm"
              style={{ marginTop: "2.5%", zIndex: 98 }}
            >
              <input
                disabled
                className="filter-input"
                value={valueEstatus}
                style={{ fontSize: "16px" }}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px", marginLeft: "-11%" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Creado
              </label>
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              className="inputs-excepciones nuevoUsuarioForm"
              style={{ marginTop: "2.5%", zIndex: 98 }}
            >
              <input
                disabled
                className="filter-input"
                value={valueCreado}
                style={{ fontSize: "16px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              md={6}
              className="inputs-excepciones nuevoUsuarioForm"
              style={{ marginTop: "2.5%", marginLeft: "1%", zIndex: 98 }}
            >
              <input
                disabled
                className="filter-input"
                value={valueCreadoPor}
                style={{ fontSize: "16px", width: "98%" }}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px", marginLeft: "-11%" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Modificado
              </label>
            </Grid>
            <Grid
              item
              xs={3}
              md={3}
              className="inputs-excepciones nuevoUsuarioForm"
              style={{ marginTop: "2.5%", zIndex: 98 }}
            >
              <input
                disabled
                className="filter-input"
                value={valueModificado}
                style={{ fontSize: "16px" }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              md={6}
              className="inputs-excepciones nuevoUsuarioForm"
              style={{ marginTop: "2.5%", marginLeft: "1%", zIndex: 98 }}
            >
              <input
                disabled
                className="filter-input"
                value={valueModificadoPor}
                style={{ fontSize: "16px", width: "98%" }}
              />
            </Grid>
            {valueEstatus === "Vigente" || valueEstatus === "Pendiente" ? (
              <Grid
                item
                xs={3}
                md={2}
                className="inputs-excepciones nuevoUsuarioForm"
                style={{ marginTop: "2%", marginLeft: "1%", zIndex: 98 }}
              >
                <NewButton
                  customText="Deshabilitar"
                  noIcon
                  style={{ padding: "5px 15px" }}
                  onClick={openModalComentario}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title={"Comentario"}
        open={modalComentarioIsOpen}
        handleClose={() => {
          setModalComentarioIsOpen(false);
        }}
        handleSubmit={handleCambiarEstado}
        loading={loadingModalComentario}
        style
        height={100}
        maxWidth={660}
        marginTop={"12%"}
      >
        <Grid container style={{ marginTop: "3.5%", marginLeft: "-1%" }}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid item xs={12} md={7} style={{ textAlign: "center" }}>
              <label>¿Desea cambiar el estado del mensaje a "Cancelado"?</label>
            </Grid>
          </Grid>
        </Grid>
      </NewModal>
    </Contenedor>
  );
}

export default Mensajes;
