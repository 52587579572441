import { enqueueSnackbar } from "notistack";
export const validateConceptoPago = (clave, description, type, maxImport, showImporte, showSchedule, dataFinal) => {
    if (!clave || !description || type.value === undefined || !maxImport || showImporte.value === undefined || showSchedule.value === undefined) {
        enqueueSnackbar("Por favor llenar todos los campos", {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    } else if (dataFinal.filter((element) => element.clave === clave).length > 0) {
        enqueueSnackbar(`Ya existe un registro con la clave ${clave}`, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    }
    else if (maxImport[maxImport.length - 1] === ".") {
        enqueueSnackbar(`Importa maximo no puede termina en .`, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    }
    return true
}