"Use strict mode";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
export const validateQuincena = (
  year,
  number,
  startDate,
  endDate,
  processDate,
  paymentDate,
  dataFinal,
  id = ""
) => {
  const dateFormatYear = "YYYY";
  const dateFormat = "DD-MM-YYYY";
  const toDateFormatYear = moment(new Date(year)).format(dateFormatYear);
  const toStartDateFormat = moment(new Date(startDate)).format(dateFormat);
  const toEndDateFormat = moment(new Date(endDate)).format(dateFormat);
  const toProcessDateFormat = moment(new Date(processDate)).format(dateFormat);
  const toPaymentDateFormat = moment(new Date(paymentDate)).format(dateFormat);
  const isNumberValid = dataFinal.filter(
    (element) =>
      element.año === year &&
      element["Número Quincena"] === number &&
      element.id !== id
  );
  let resultIndex = 0;
  const lastResult = dataFinal
    .sort((a, b) => {
      const firstRow = `${a?.fecha_fin?.split("-")?.[2]}-${
        a?.fecha_fin?.split("-")?.[1]
      }-${a?.fecha_fin?.split("-")?.[0]}`;
      const secondRow = `${b?.fecha_fin?.split("-")?.[2]}-${
        b?.fecha_fin?.split("-")?.[1]
      }-${b?.fecha_fin?.split("-")?.[0]}`;
      return firstRow < secondRow ? 1 : secondRow < firstRow ? -1 : 0;
    })
    .map((element, index) => {
      if (element.año === year && element.id === id) {
        resultIndex = index;
      }
      return {
        ...element,
        fecha_fin: `${element?.fecha_fin?.split("-")?.[2]}-${
          element?.fecha_fin?.split("-")?.[1]
        }-${element?.fecha_fin?.split("-")?.[0]}`,
      };
    });
  if (
    !year ||
    !number ||
    !startDate ||
    !endDate ||
    !processDate ||
    !paymentDate
  ) {
    enqueueSnackbar("Por favor llenar todos los campos", {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (
    !moment(toDateFormatYear, dateFormatYear, true).isValid() ||
    year.length !== 4
  ) {
    enqueueSnackbar(`Formato de año incorrecto. Ejemplo YYYY`, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (
    !moment(toStartDateFormat, dateFormat, true).isValid() ||
    startDate.split("-").length !== 3
  ) {
    enqueueSnackbar(`Formato de Fecha inicio incorrecto. Ejemplo DD-MM-YYYY`, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (
    !moment(toEndDateFormat, dateFormat, true).isValid() ||
    endDate.split("-").length !== 3
  ) {
    enqueueSnackbar(`Formato de Fecha Final incorrecto. Ejemplo DD-MM-YYYY`, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (
    !moment(toProcessDateFormat, dateFormat, true).isValid() ||
    processDate.split("-").length !== 3
  ) {
    enqueueSnackbar(`Formato de Fecha Proceso incorrecto. Ejemplo DD-MM-YYYY`, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (
    !moment(toPaymentDateFormat, dateFormat, true).isValid() ||
    paymentDate.split("-").length !== 3
  ) {
    enqueueSnackbar(`Formato de Fecha Pago incorrecto. Ejemplo DD-MM-YYYY`, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (
    moment(startDate).isSameOrBefore(
      lastResult[id === "" ? 0 : resultIndex + 1]?.fecha_fin
    )
  ) {
    enqueueSnackbar(
      `La Fecha Inicial debe ser mayor a la Fecha Final de la quincena inmediata anterior`,
      {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }
    );
    return false;
  } else if (moment(endDate).isSameOrBefore(startDate)) {
    enqueueSnackbar(`Fecha Final no puede ser menor o igual a Fecha Inicial`, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    return false;
  } else if (moment(processDate).isSameOrBefore(endDate)) {
    enqueueSnackbar(
      `Fecha de Proceso no puede ser menor o igual a Fecha Final`,
      {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }
    );
    return false;
  } else if (moment(paymentDate).isSameOrBefore(processDate)) {
    enqueueSnackbar(
      `Fecha de Pago no puede ser menor o igual a Fecha de Proceso`,
      {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }
    );
    return false;
  } else if (isNumberValid.length > 0) {
    enqueueSnackbar(
      `Ya existe un Número Quincena ${number} para el año ${year}`,
      {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }
    );
    return false;
  }
  return true;
};
