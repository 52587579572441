import React from "react";
import { Grid } from "@material-ui/core";
import ModalSelect from "../ModalSelect";
import CreateModalInputs from "../CreateModalInputs";

const CopyMateriaProfesionModal = ({
    setCreateCarrera,
    setCreateMateria,
    materiaOptions,
    carreraOptions,
    materia,
    carrera
}) => {
    return (
        <Grid style={{ padding: 20 }}>
            <CreateModalInputs label={'Carrera Origen'} inputValue={carrera} disabled={true} />
            <CreateModalInputs label={'Materia Legal'} inputValue={materia} disabled={true} />
            <ModalSelect
                label={'Carrera Origen Copia'}
                options={carreraOptions}
                onChange={(event) => setCreateCarrera(event)}
            />
            <ModalSelect
                label={'Materia Legal Copia'}
                options={materiaOptions}
                onChange={(event) => setCreateMateria(event)}
            />
        </Grid>
    )
}

export default CopyMateriaProfesionModal