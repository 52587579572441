import React from "react";
import { View, Text } from "@react-pdf/renderer";
import ReportGlobalIndicatorsDirectorTable from "../ReportGlobalIndicatorsDirectorTable";
import { divideArrayIntoParts } from "../Funciones/divideArrayIntoParts";
import HeaderPDFDefault from "../HeaderPDFDefault";

export default function ReportGlobalIndicatorsDirectorPDF({
  metadata,
  data,
  title,
  campus,
  rowstableColSpan,
}) {
  const splitDataIntoParts = divideArrayIntoParts(data, 6);
  return splitDataIntoParts.map((dataTable, index2) => {
    return (
      <View break={index2 === 0 ? false : true} key={`-${index2}`}>
        <HeaderPDFDefault title={title} />
        <View style={{ marginTop: 15 }}>
          <View style={{ marginTop: 1 }}>
            <View
              style={{ flexDirection: "row", width: "100%", marginTop: 10 }}
            >
              <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Ciclo: {dataTable[0]?.ciclo.trim()}</Text>
              <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Campus: {dataTable[0]?.campus.trim()}</Text>
              <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Escuela: {dataTable[0]?.escuela.trim()}</Text>
            </View>
            <ReportGlobalIndicatorsDirectorTable
              metadata={metadata}
              data={dataTable}
              campus={campus}
              rowstableColSpan={rowstableColSpan}
            />
          </View>
        </View>
      </View>
    );
  });
}
