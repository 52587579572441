import { getRequest } from "../Funciones/requester";

export const getModalReporteIndicadoresAcademicosSemanales = async (
  payload,
  setInfo,
  setLoading,
  enqueueSnackbar
) => {
  setLoading(true);
  let consulta = `/reportes/detalle_indicadores_academicos_semanal?fecha_inicio=${payload.fechaInicio}&fecha_fin=${payload.fechaFin}&id_direcciones_director=${payload.iddireccionesDirector}&ciclo=${payload.ciclo}`;
  const response = await getRequest(consulta);
  //   let rowsP = "";
  if (response.s === "OK") {
    enqueueSnackbar(response.m, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    const data = response.d.clave_profesor.map((item, index) => {
      return [
        response.d.clave_profesor[index],
        response.d.nombre_profesor[index],
        response.d.grupo[index],
        response.d.materia[index],
        response.d.clase[index],
        response.d.fecha_origen[index],
        response.d.fecha_clase_especial[index],
        response.d.clave_profesor_clase_especial[index],
        response.d.nombre_profesor_clase_especial[index],
        response.d.asistencia[index],
        response.d.autorizado[index],
        response.d.incidencia[index],
      ];
    });
    setInfo(data);
    setLoading(false);
  } else {
    enqueueSnackbar(response.m, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    setInfo([]);
    setLoading(false);
  }
};
