import { View, Text } from '@react-pdf/renderer'

export default function ExtraordinaryPaymentsTotalBox({
    title,
    hour,
    importTotal,
    unit,
    borderTop,
    borderBottom
}) {
    return (
        <View>
            <View style={{
                width: "100%",
                display: "flex",
                flexDirection: "column", justifyContent: "center", alignItems: "center"
            }}>
                <View style={{
                    display: "flex",
                    flexDirection: "row", justifyContent: "center", alignItems: "center",
                }}>
                    <View style={{ fontSize: 10, padding: 10, width: 200, justifyContent: "center", alignItems: "center", border: "1px", borderTop: borderTop, borderBottom: borderBottom }}>
                        <Text>{title}</Text>
                    </View>
                    <View style={{ fontSize: 10, padding: 10, width: 80, justifyContent: "center", alignItems: "center", border: "1px", borderLeft: 0, borderTop: borderTop, borderBottom: borderBottom }}>
                        <Text>{hour}</Text>
                    </View>
                    <View style={{ fontSize: 10, padding: 10, width: 100, justifyContent: "center", alignItems: "center", border: "1px", borderLeft: 0, borderTop: borderTop, borderBottom: borderBottom }}>
                        <Text>{importTotal}</Text>
                    </View>
                    <View style={{ fontSize: 10, padding: 10, width: 100, justifyContent: "center", alignItems: "center", border: "1px", borderLeft: 0, borderTop: borderTop, borderBottom: borderBottom }}>
                        <Text>{unit}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}