
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../pdf_table/styled'

export default function ReportDefinitivoBonoProdTable({
    metadata,
    data,
    campus,
    ciclo,
    escuela,
    direccion,
    isNotfirstPage,
    
}) {
    const Headers = () => {
        return (
            <View style={{ flexDirection: 'row' }}>
                {
                    metadata && (
                        metadata.map((e, i) => (
                            <View key={i} style={{ width: e.width, ...styles.boxHeader, justifyContent: e.align }}>
                                <Text style={styles.cell}>{e.name}</Text>
                            </View>
                        ))
                    )
                }
            </View>
        )
    }

    return (
        <View style={{ marginTop: 0 }} >
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 0 }}>
                {ciclo ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Ciclo: {ciclo}</Text>) : null}
                {campus ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Campus: {campus}</Text>) : null}
                {escuela ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Escuela: {escuela}</Text>) : null}
            </View>
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10, justifyContent: "space-between" }}>
                <Text style={{ fontSize: 10, color: '#000000' }}>Dirección: {data[0].direccion}</Text>
                <Text style={{ fontSize: 10, color: '#000000' }}>Director: {data[0].director}</Text>
            </View>
            <View style={{ borderLeft: '1px', borderLeftColor: '#828282', borderTop: '1px', borderTopColor: '#828282', marginTop: 5 }}>
                <Headers />
                {
                    data && (
                        data.map((e, i) => (
                            <View style={{ flexDirection: 'row' }} key={i}>
                                {metadata.map((el, il) => (
                                    <View key={il} style={{ width: el.width, ...styles.boxRow, justifyContent: el.align }}>
                                        {
                                            el.fn === null ? (
                                                <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                                            ) :
                                                <Text> {el.fn(e[el.id])}</Text>
                                        }
                                    </View>
                                ))}
                            </View>
                        ))
                    )
                }
            </View>
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10, justifyContent: "flex-start" }}>
                <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Totales Profesores Grupo Materia: {data.length}</Text>
            </View>
        </View>
    )
}