export const setIsLoged = (payload) => ({
  type: "SET_ISLOGED",
  payload,
});

export const setComentariosPagos = (payload) => ({
  type: "SET_COMENTARIOSPAGOS",
  payload,
});

export const setCampusUser = (payload) => ({
  type: "SET_CAMPUSUSER",
  payload,
});

export const setCampusUser2 = (payload) => ({
  type: "SET_CAMPUSUSER2",
  payload,
});

export const setCampusId = (payload) => ({
  type: "SET_CAMPUSID",
  payload,
});

export const setCampusName = (payload) => ({
  type: "SET_CAMPUSNAME",
  payload,
});

export const setPersonId = (payload) => ({
  type: "SET_PERSONID",
  payload,
});

export const setStatePermisos = (payload) => ({
  type: "SET_PERMISOS",
  payload,
});

export const setUserName = (payload) => ({
  type: "USER_NAME",
  payload,
});

export const setUserRol = (payload) => ({
  type: "USER_ROL",
  payload,
});
