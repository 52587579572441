import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
//import TextField from "@material-ui/core/TextField";
import SearchInput from "../../Utils/Visuales/SearchInput";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import NewModal from "../../Utils/Visuales/NewModal";
//import { NuevaExcepcion } from "./modal/nueva_excepcion";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import "./index.css";
import moment from "moment";

const Excepciones = () => {
  const headers = [
    {
      name: "Folio",
      col: 0,
    },
    {
      name: "Grupo",
      col: 1,
    },
    {
      name: "Docente",
      col: 2,
    },
    {
      name: "Tipo",
      col: 3,
    },
    {
      name: "Acciones",
      col: 4,
    },
  ];
  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [valueCampus, setValueCampus] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const permisos = useSelector((state) => state.permisos);
  const [optionsCampus, setOptionsCampus] = useState(campusUser);
  const [valueEscuela, setValueEscuela] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueGrupo, setValueGrupo] = useState("");
  const [valueMateria, setValueMateria] = useState("");
  const [valueTipo, setValueTipo] = useState("");
  const [optionsTipo, setOptionsTipo] = useState("");
  const [optionsTipoModal, setOptionsTipoModal] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [dataModalConsultar, setDataModalConsultar] = useState("");
  const [buttonNuevo, setButtonNuevo] = useState(false);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);

  //const [valueFolioModal, setValueFolioModal] = useState("");
  const [valueCampusModal, setValueCampusModal] = useState("");
  const [valueClaveProfesorModal, setValueClaveProfesorModal] = useState("");
  // const [valueMateriClaveModal, setValueMateriaClaveModal] = useState("");
  // const [valueDireccionModal, setValueDireccionModal] = useState("");
  const [valueDireccionClaveModal, setValueDireccionClaveModal] = useState("");
  const [valueGrupoModal, setValueGrupoModal] = useState("");
  const [valueFechaInicioModal, setValueFechaInicioModal] = useState("");
  const [valueFechaFinModal, setValueFechaFinModal] = useState("");
  const [valueTipoExcepcionModal, setValueTipoModal] = useState("");
  const [valueComentariosModal, setValueComentarioModal] = useState("");
  const [valueMateriaModal, setValueMateriaModal] = useState("");
  //const [valueDocenteModal, setValueDocenteModal] = useState("");

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    // let data = [];
    // const campus = JSON.parse(localStorage.getItem("campus"));
    // campus.map((item, i) => {
    //   data.push({ value: item.id, label: `${item.clave} - ${item.nombre}` });
    // });
    // setOptionsCampus(data);
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    //consultarCatalogo(16).then((data) => setOptionsTipo(data));
    GetListParametro();
    setValueCampus(
      optionsCampus?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const GetListParametro = async () => {
    try {
      const consulta = `/parametro_reglas?tipo=excepciones`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        let arrayResponse = response.d.map((e) => {
          const data2 = {
            label: e.tipo_regla,
            name: e.nombre,
            value: e.id,
          };
          return data2;
        });
        let arrayResponse2 = response.d.map((e) => {
          const data2 = {
            label: `${e.id} - ${e.nombre}`,
            name: e.tipo_regla,
            value: e.id,
          };
          return data2;
        });
        //console.log(arrayResponse)
        let hash = {};
        let result = arrayResponse.filter((o) =>
          hash[o.label] ? false : (hash[o.label] = true)
        );
        setOptionsTipoModal(arrayResponse2);
        setOptionsTipo(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;

  // const promiseOptionsMateria = (inputValue) =>
  //   new Promise((resolve) => {
  //   clearTimeout(timer);
  //   if (inputValue.length >= minChars) {
  //     timer = setTimeout(() => {
  //       resolve(searchMateria(inputValue));
  //     }, waitTime);
  //   } else {
  //     resolve([]);
  //   }
  // });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9, fontSize: "16px" }),
  };

  const selectReglaStyle = {
    menuList: (base) => ({
      ...base,
      maxHeight: "270px",
    }),
  };

  const openConsultar = async (id) => {
    setLoadingModal(true);
    setModalIsOpen(true);
    setButtonNuevo(false);
    //console.log("Consultar: ", buttonNuevo);
    if (!buttonNuevo) {
      try {
        const consulta = `/excepciones/detalle?id=${id}`;
        const response = await getRequest(consulta);
        if (response.s === "OK") {
          setDataModalConsultar(response.d);
        } else {
          console.log("error");
        }
        setLoadingModal(false);
      } catch (error) {
        console.log(error);
        setLoadingModal(false);
        setModalIsOpen(false);
      }
    }
  };

  function openNueva() {
    setLoadingModal(true);
    setModalIsOpen(true);
    setButtonNuevo(true);
    //console.log("Nuevo: ", buttonNuevo);
    setLoadingModal(false);
  }

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      //console.log("row", row);
      if (row[0].toString().toLowerCase().includes(text)) return true;
      else if (row[1].toString().toLowerCase().includes(text)) return true;
      else if (row[2].toString().toLowerCase().includes(text)) return true;
      else if (row[3].toString().toLowerCase().includes(text)) return true;
      else if (row[4].toString().toLowerCase().includes(text)) return true;
      else if (row[5].toString().toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }

  const SearchExcepciones = async () => {
    let valido = true;
    if (
      valueClaveProfesor.value === "" ||
      valueClaveProfesor.value === undefined
    ) {
      document
        .getElementsByClassName("input-claveProfesor")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("input-claveProfesor")[0]
        .classList.remove("error-input");
    }
    if (valueCampus.value === "" || valueCampus.value === undefined) {
      document
        .getElementsByClassName("input-campus")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      document
        .getElementsByClassName("input-campus")[0]
        .classList.remove("error-input");
    }
    if (valueTipo.value === "" || valueTipo.value === undefined) {
      //console.log("if", valueTipo.value);
      document
        .getElementsByClassName("input-tipo")[0]
        .classList.add("error-input");
      valido = false;
    } else {
      //console.log("else", valueTipo.value);
      document
        .getElementsByClassName("input-tipo")[0]
        .classList.remove("error-input");
    }
    //console.log(valido);
    if (valido) {
      setLoading(true);
      const clave = valueClaveProfesor.value;
      const campus = valueCampus.value;
      const tipo = valueTipo.label;
      const escuela = valueEscuela.value;
      const direccion = valueDireccion.value;
      const grupo = valueGrupo;
      const materia = valueMateria.value;
      let url = `/excepciones?id_profesor=${clave}&id_campus=${campus}&id_tipo=${tipo}`;
      if (escuela) url += `&id_escuela=${escuela}`;
      if (direccion) url += `&id_direccion=${direccion}`;
      if (grupo) url += `&grupo=${grupo}`;
      if (materia) url += `&id_materia=${materia}`;

      const consulta = url;
      //console.log(consulta);
      let response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        response.d.forEach((excepciones) => {
          data.push([
            excepciones.folio,
            excepciones.grupo,
            excepciones.docente,
            excepciones.tipo,
            <div I="edit" a="excepciones">
              <EditButton onClick={() => openConsultar(excepciones.folio)}>
                Consultar
              </EditButton>
            </div>,
          ]);
        });
        setDataOriginal(data);
        setDataFinal(data);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
        setDataOriginal([]);
        setDataFinal([]);
      }
    }
  };

  const handleSubmitExcepciones = async () => {
    try {
      const consulta = "/excepciones";
      const json = {
        id_campus: parseInt(valueCampusModal.value),
        id_docente: valueClaveProfesorModal.value,
        id_direccion: parseInt(valueDireccionClaveModal.value),
        grupo: valueGrupoModal,
        fecha_inicio: valueFechaInicioModal,
        fecha_fin: valueFechaFinModal,
        id_tipo_excepcion: valueTipoExcepcionModal.value,
        comentarios: valueComentariosModal,
        materia: valueMateriaModal.value,
        id_asignacion: valueMateriaModal.id_asignacion,
      };
      setLoadingModal(true);
      const response = await postRequest(consulta, json);
      if (response.s === "OK") {
        setValueClaveProfesorModal("");
        setValueCampusModal("");
        setValueDireccionClaveModal("");
        setValueGrupoModal("");
        setValueTipoModal("");
        setValueMateriaModal("");
        setValueComentarioModal("");
        setValueFechaInicioModal("");
        setValueFechaFinModal("");
        setValueCiclo("");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setModalIsOpen(false);
        setButtonNuevo(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  };

  async function searchMateria(id_campus, id_person, grupo, ciclo, direccion) {
    setSelectMateriaIsLoading(true);
    try {
      const options = [];
      const idCampus = modalIsOpen ? valueCampusModal.value : id_campus;
      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${idCampus},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
            id_asignacion: item.id_asignacion,
          });
        });

        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  const handleChangeMateria = (e) => {
    console.log(e);
    setValueMateriaModal(e);
  };

  useEffect(() => {
    let grupoTimer;
    clearTimeout(grupoTimer);
    if (
      valueGrupoModal.length >= 4 &&
      valueCiclo > 4 &&
      valueDireccionClaveModal.value !== undefined
    ) {
      grupoTimer = setTimeout(() => {
        searchMateria(
          valueCampus.value,
          valueClaveProfesorModal.value,
          valueGrupoModal,
          valueCiclo,
          valueDireccionClaveModal.value
        );
      }, waitTime);
    }
  }, [
    valueCampus,
    valueClaveProfesorModal,
    valueGrupoModal,
    valueCiclo,
    valueDireccionClaveModal,
  ]);

  return (
    <Contenedor title="Asignaciones / Excepciones">
      <div className="buttons-bar">
        <div>
          <NewButton
            onClick={openNueva}
            noIcon
            style={{
              padding: "5px 15px",
              display:
                permisos["Asignaciones->Excepciones"].escribir === "1"
                  ? "block"
                  : "none",
            }}
          />
        </div>
        <div>
          <NewButton
            noIcon
            style={{ padding: "5px 15px" }}
            customText="Buscar"
            onClick={(e) => {
              SearchExcepciones();
            }}
          />
        </div>
      </div>

      <div className="inputs-excepciones">
        <Grid container>
          <Grid xs={2} md={2}>
            <label>Campus (*)</label>
          </Grid>
          <Grid xs={2} md={2}>
            <Select
              className="select-modal-container input-campus"
              classNamePrefix="select-modal"
              value={valueCampus}
              options={optionsCampus}
              onChange={setValueCampus}
            />
          </Grid>
          <Grid xs={2} md={2}>
            <label>Escuela</label>
          </Grid>
          <Grid xs={2} md={2}>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              value={valueEscuela}
              options={optionsEscuela}
              onChange={setValueEscuela}
              isClearable={true}
            />
          </Grid>
          <Grid xs={2} md={2}>
            <label>Dirección</label>
          </Grid>
          <Grid xs={2} md={2}>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              value={valueDireccion}
              options={optionsDireccion}
              onChange={setValueDireccion}
              isClearable={true}
            />
          </Grid>
          <Grid xs={2} md={2}>
            <label>Grupo</label>
          </Grid>
          <Grid xs={2} md={2}>
            <input
              className="filter-input"
              value={valueGrupo}
              onChange={(e) => setValueGrupo(e.target.value)}
            />
          </Grid>
          <Grid xs={2} md={2}>
            <label>Materia</label>
          </Grid>
          <Grid xs={2} md={2}>
            <input
              className="filter-input"
              value={valueMateria}
              onChange={(e) => setValueMateria(e.target.value)}
            />
          </Grid>
          <Grid xs={2} md={2}>
            <label>Profesor(*)</label>
          </Grid>
          <Grid xs={2} md={2}>
            <AsyncSelect
              className="select-modal-container input-claveProfesor clave-profesor-index0"
              classNamePrefix="select-modal"
              styles={asyncSelectStyles}
              loadOptions={promiseOptions}
              onChange={setValueClaveProfesor}
              placeholder="Buscar Profesor"
            />
          </Grid>
          <Grid xs={2} md={2}>
            <label>Tipo (*)</label>
          </Grid>
          <Grid xs={2} md={2}>
            <Select
              className="select-modal-container input-tipo"
              classNamePrefix="select-modal"
              value={valueTipo}
              defaultValue=""
              options={optionsTipo}
              onChange={setValueTipo}
            />
          </Grid>
        </Grid>
      </div>
      <Grid md={3} container style={{ marginLeft: "auto", marginBottom: 4 }}>
        <SearchInput hideIcon search={search} />
      </Grid>
      <div style={{ height: "calc(100% - 227px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4, 5]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title={`${buttonNuevo ? "Nueva Excepción" : "Consultar Excepción"}`}
        open={modalIsOpen}
        handleClose={() => {
          setValueClaveProfesorModal("");
          setValueCampusModal("");
          setValueDireccionClaveModal("");
          setValueGrupoModal("");
          setValueTipoModal("");
          setValueMateriaModal("");
          setValueComentarioModal("");
          setValueFechaInicioModal("");
          setValueFechaFinModal("");
          setValueCiclo("");
          setModalIsOpen(false);
          setButtonNuevo(false);
        }}
        loading={loadingModal}
        height={450}
        maxWidth={1000}
        handleSubmit={
          buttonNuevo
            ? () => handleSubmitExcepciones()
            : () => {
                setModalIsOpen(false);
                setButtonNuevo(false);
              }
        }
      >
        <div style={{ padding: "25px" }}>
          <Grid container item spacing={1} alignItems="center">
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Clave Profesor(*):" : "Clave Profesor:"
              }`}</label>
            </Grid>
            <Grid xs={12} style={{ maxWidth: "675px", display: "flex" }} item>
              {buttonNuevo ? (
                <AsyncSelect
                  className="select-modal-container input-claveProfesor input-claveProfesor-modal"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  value={valueClaveProfesorModal}
                  loadOptions={promiseOptions}
                  onChange={setValueClaveProfesorModal}
                  placeholder="Buscar Profesor"
                />
              ) : (
                <input
                  disabled
                  className="filter-input"
                  value={dataModalConsultar.docente}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            style={{ marginTop: "1%" }}
          >
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Campus:(*):" : "Campus:"
              }`}</label>
            </Grid>
            <Grid xs={12} md={4} item style={{}}>
              {buttonNuevo ? (
                <Select
                  className="select-modal-container input-campus"
                  classNamePrefix="select-modal"
                  value={valueCampusModal}
                  options={optionsCampus}
                  onChange={setValueCampusModal}
                />
              ) : (
                <input
                  disabled
                  className="filter-input"
                  value={dataModalConsultar.campus_name}
                />
              )}
            </Grid>
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Ciclo:(*):" : "Ciclo:"
              }`}</label>
            </Grid>
            <Grid xs={12} md={4} item>
              <input
                disabled={!buttonNuevo}
                className="filter-input"
                style={{ fontSize: "15px" }}
                value={buttonNuevo ? valueCiclo : dataModalConsultar?.ciclo}
                onChange={(e) => setValueCiclo(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            style={{ marginTop: "1%" }}
          >
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Dirección:(*):" : "Dirección:"
              }`}</label>
            </Grid>
            <Grid xs={12} md={4} item>
              {buttonNuevo ? (
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueDireccionClaveModal}
                  options={optionsDireccion}
                  onChange={setValueDireccionClaveModal}
                />
              ) : (
                <input
                  disabled
                  className="filter-input"
                  value={dataModalConsultar.direccion}
                />
              )}
            </Grid>
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Grupo:(*):" : "Grupo:"
              }`}</label>
            </Grid>
            <Grid xs={12} md={4} item>
              <input
                disabled={!buttonNuevo}
                className="filter-input"
                style={{ fontSize: "15px" }}
                value={buttonNuevo ? valueGrupoModal : dataModalConsultar.grupo}
                onChange={(e) => setValueGrupoModal(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            style={{ marginTop: "1%" }}
          >
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Regla:(*):" : "Regla:"
              }`}</label>
            </Grid>
            <Grid xs={12} md={4} item>
              {buttonNuevo ? (
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  styles={selectReglaStyle}
                  value={valueTipoExcepcionModal}
                  options={optionsTipoModal}
                  onChange={setValueTipoModal}
                />
              ) : (
                <input
                  disabled
                  className="filter-input"
                  value={dataModalConsultar.tipo}
                />
              )}
            </Grid>
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Materia:(*):" : "Materia:"
              }`}</label>
            </Grid>
            <Grid
              xs={12}
              md={4}
              item
              style={{
                position: "absolute",
                zIndex: 9999,
                marginLeft: "56.5%",
                width: 255,
              }}
            >
              {buttonNuevo ? (
                <Select
                  classNamePrefix="select-modal"
                  options={materiaOptions}
                  styles={{
                    container: (styles) => ({ ...styles, zIndex: 999 }),
                  }}
                  isLoading={selectMateriaIsLoading}
                  onChange={handleChangeMateria}
                  value={valueMateriaModal}
                />
              ) : (
                <input
                  disabled
                  className="filter-input"
                  value={dataModalConsultar.materia}
                />
              )}
            </Grid>
          </Grid>
          <Grid container item spacing={1} style={{ marginTop: "1%" }}>
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>{`${
                buttonNuevo ? "Comentario:(*):" : "Comentario:"
              }`}</label>
            </Grid>
            <Grid xs={12} md={8} item>
              <textarea
                className="text-area-modal"
                rows={5}
                placeholder="Escribe aquí"
                disabled={!buttonNuevo}
                value={
                  buttonNuevo
                    ? valueComentariosModal
                    : dataModalConsultar.comentario
                }
                style={{ fontSize: "15px", width: "106%" }}
                onChange={(e) => setValueComentarioModal(e.target.value)}
              ></textarea>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            style={{ marginTop: "1%" }}
          >
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>Fecha Inicial(*):</label>
            </Grid>
            <Grid xs={12} md={4} item>
              <input
                className="filter-input"
                type={buttonNuevo ? "date" : "text"}
                disabled={!buttonNuevo}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .notchedOutlinet": {
                    border: "none !important",
                  },
                }}
                style={{ fontSize: "15px" }}
                value={
                  buttonNuevo
                    ? valueFechaInicioModal
                    : moment(dataModalConsultar.fecha_inicio).format(
                        "DD/MM/YYYY"
                      )
                }
                onChange={(e) => setValueFechaInicioModal(e.target.value)}
              />
            </Grid>
            <Grid xs={12} md={2} item>
              <label style={{ float: "right" }}>Fecha Final(*):</label>
            </Grid>
            <Grid xs={12} md={4} item>
              <input
                className="filter-input"
                type={buttonNuevo ? "date" : "text"}
                disabled={!buttonNuevo}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .notchedOutlinet": {
                    border: "none !important",
                  },
                }}
                style={{ fontSize: "15px" }}
                value={
                  buttonNuevo
                    ? valueFechaFinModal
                    : moment(dataModalConsultar.fecha_fin).format("DD/MM/YYYY")
                }
                onChange={(e) => setValueFechaFinModal(e.target.value)}
              />
            </Grid>
          </Grid>
          {!buttonNuevo && (
            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              style={{ marginTop: "1%" }}
            >
              <Grid xs={12} md={2} item>
                <label style={{ float: "right" }}>Creado Por</label>
              </Grid>
              <Grid xs={12} md={4} item>
                <input
                  className="filter-input"
                  type={buttonNuevo ? "date" : "text"}
                  disabled={!buttonNuevo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ fontSize: "15px" }}
                  value={dataModalConsultar.created_by}
                  onChange={(e) => setValueFechaInicioModal(e.target.value)}
                />
              </Grid>
              <Grid xs={12} md={2} item>
                <label style={{ float: "right" }}>Fecha Creado:</label>
              </Grid>
              <Grid xs={12} md={4} item>
                <input
                  className="filter-input"
                  type={buttonNuevo ? "date" : "text"}
                  disabled={!buttonNuevo}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ fontSize: "15px" }}
                  value={moment(dataModalConsultar.created_at).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                  onChange={(e) => setValueFechaFinModal(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </NewModal>
    </Contenedor>
  );
};

export default Excepciones;
