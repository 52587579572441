import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import Select from "react-select";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import { ExportCSV } from "../../Utils/Excel";
import saveAs from "file-saver";
import "./index.css";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ReporteCatalogoDeGrupos = () => {
  const headers = [
    {
      name: "Ciclo",
      col: 0,
      id: "Ciclo",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "Campus",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "Escuela",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 3,
      id: "Direccion",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 4,
      id: "Clave_Profesor",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 5,
      id: "Nombre_Profesor",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Persona",
      col: 6,
      id: "Tipo_Persona",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Profesor",
      col: 7,
      id: "Tipo_Profesor",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Ingreso",
      col: 8,
      id: "Tipo_Ingreso",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 9,
      id: "Grupo",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Grupo",
      col: 10,
      id: "Tipo_Grupo",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      col: 11,
      id: "Clave_Materia",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Materia",
      col: 12,
      id: "Nombre_Materia",
      align: "center",
      fn: null,
    },
    {
      name: "Complementario",
      col: 13,
      id: "Complementario",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Asistencia",
      col: 14,
      id: "Tipo_Asistencia",
      align: "center",
      fn: null,
    },
    {
      name: "Turno",
      col: 15,
      id: "Turno",
      align: "center",
      fn: null,
    },
    {
      name: "Definitivo",
      col: 16,
      id: "Definitivo",
      align: "center",
      fn: null,
    },
    {
      name: "Máximo",
      col: 17,
      id: "Máximo",
      align: "center",
      fn: null,
    },
    {
      name: "Asignados",
      col: 18,
      id: "Asignados",
      align: "center",
      fn: null,
    },
    {
      name: "Lun",
      col: 19,
      id: "Lun",
      align: "center",
      fn: null,
    },
    {
      name: "Mar",
      col: 20,
      id: "Mar",
      align: "center",
      fn: null,
    },
    {
      name: "Mie",
      col: 21,
      id: "Mie",
      align: "center",
      fn: null,
    },
    {
      name: "Jue",
      col: 22,
      id: "Jue",
      align: "center",
      fn: null,
    },
    {
      name: "Vie",
      col: 23,
      id: "Vie",
      align: "center",
      fn: null,
    },
    {
      name: "Sab",
      col: 24,
      id: "Sab",
      align: "center",
      fn: null,
    },
    {
      name: "Salones",
      col: 25,
      id: "Salones",
      align: "center",
      fn: null,
    },
    {
      name: "Fechas",
      col: 26,
      id: "Fechas",
      align: "center",
      fn: null,
    },
    {
      name: "Horas x Semana",
      col: 27,
      id: "HorasxSemana",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Alta Profesor",
      col: 28,
      id: "Fecha_Alta_Profesor",
      align: "center",
      fn: null,
    },
    {
      name: "Ult. Fecha Profesor",
      col: 29,
      id: "Ult_Fecha_Profesor",
      align: "center",
      fn: null,
    },
    {
      name: "Estatus Grupo",
      col: 30,
      id: "Estatus_Grupo",
      align: "center",
      fn: null,
    },
  ];

  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [valueCampus, setValueCampus] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [estatusGrupo, setEstatusGrupo] = useState("");

  const [optionsEstatusGrupo] = useState([
    { label: "Vigente", value: "Vigente" },
    { label: "No Vigente", value: "No Vigente" },
    { label: "Ambos", value: "Ambos" },
  ]);
  const { enqueueSnackbar } = useSnackbar();

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function handleClickGenerar() {
    setLoading(true);
    setData([]);
    if (valueCiclo === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valueCampus === "") {
      setLoading(false);
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        const datos = [];
        let url = `/reportes/catalogo_grupos?ciclo=${valueCiclo}&id_campus=${valueCampus.value}`;
        if (selectedEscuela) url += `&id_escuela=${selectedEscuela.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        if (estatusGrupo) url += `&id_estatus_grupo=${estatusGrupo.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["escuela"][i],
              response.d[3][" direccion"][i],
              response.d[4]["clave_profesor"][i],
              response.d[5]["nombre_profesor"][i],
              response.d[6]["tipo_persona"][i],
              response.d[7]["tipo_profesor"][i],
              response.d[8]["tipo_ingreso"][i],
              response.d[9]["grupo"][i],
              response.d[10]["tipo_grupo"][i],
              response.d[11]["clave_materia"][i],
              response.d[12]["nombre_materia"][i],
              response.d[13]["complementario"][i],
              response.d[14]["tipo_asistencia"][i],
              response.d[15]["turno"][i],
              response.d[16]["definitivo"][i],
              response.d[17]["maximo"][i],
              response.d[18]["asignados"][i],
              response.d[19]["lun"][i].substring(0, 17),
              response.d[20]["mar"][i].substring(0, 17),
              response.d[21]["mie"][i].substring(0, 17),
              response.d[22]["jue"][i].substring(0, 17),
              response.d[23]["vie"][i].substring(0, 17),
              response.d[24]["sab"][i].substring(0, 17),
              response.d[25]["salones"][i],
              response.d[26]["fechas"][i],
              response.d[27]["h_curr"][i],
              response.d[28]["fecha_alta_profesor"][i],
              response.d[29]["ult_fecha_profesor"][i],
              response.d[30]["estatus_grupo"][i],
            ]);
          }
          setData(datos);
          setDataOriginal(datos);
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
      setShowTable(true);
    }
    setLoading(false);
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setSelectEscuela(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        Ciclo: e[0],
        Campus: e[1],
        Escuela: e[2],
        Direccion: e[3],
        Clave_Profesor: e[4],
        Nombre_Profesor: e[5],
        Tipo_Persona: e[6],
        Tipo_Profesor: e[7],
        Tipo_Ingreso: e[8],
        Grupo: e[9],
        Tipo_Grupo: e[10],
        Clave_Materia: e[11],
        Nombre_Materia: e[12],
        Complementario: e[13],
        Tipo_Asistencia: e[14],
        Turno: e[15],
        Definitivo: e[16],
        Máximo: e[17],
        Asignados: e[18],
        Lun: e[19],
        Mar: e[20],
        Mie: e[21],
        Jue: e[22],
        Vie: e[23],
        Sab: e[24],
        Salones: e[25],
        Fechas: e[26],
        HorasxSemana: e[27],
        Fecha_Alta_Profesor: e[28],
        Ult_Fecha_Profesor: e[29],
        Estatus_Grupo: e[30],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_CatálogodeGrupos_${fecha}_${hora}`);
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = [];
    data.forEach((item) => {
      data_.push({
        Ciclo: item[0],
        Campus: item[1],
        Escuela: item[2],
        Direccion: item[3],
        Clave_Profesor: item[4],
        Nombre_Profesor: item[5],
        Tipo_Persona: item[6],
        Tipo_Profesor: item[7],
        Tipo_Ingreso: item[8],
        Grupo: item[9],
        Tipo_Grupo: item[10],
        Clave_Materia: item[11],
        Nombre_Materia: item[12],
        Complementario: item[13],
        Tipo_Asistencia: item[14],
        Turno: item[15],
        Definitivo: item[16],
        Máximo: item[17],
        Asignados: item[18],
        Lun: item[19],
        Mar: item[20],
        Mie: item[21],
        Jue: item[22],
        Vie: item[23],
        Sab: item[24],
        Salones: item[25],
        Fechas: item[26],
        HorasxSemana: item[27],
        Fecha_Alta_Profesor: item[28],
        Ult_Fecha_Profesor: item[29],
        Estatus_Grupo: item[30],
      });
    });

    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Reportes Catálogo de Grupos`}
        campus={valueCampus.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_CatálogodeGrupos_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Catálogo de Grupos">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "-2%" }}
            >
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginRight: "-0.5%" }}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                style={{ textAlign: "end", marginRight: "1%" }}
              >
                <label>Campus(*):</label>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginRight: "1%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusValores}
                  onChange={setValueCampus}
                />
              </Grid>
              <Grid xs={12} md={1}>
                <label>Escuela:</label>
              </Grid>
              <Grid
                xs={1}
                md={2}
                item
                style={{ marginLeft: "-2%", marginRight: "-1%" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectEscuela}
                  onChange={setSelectedEscuela}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginRight: "0.5%" }}
              >
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Estatus Grupo:</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={estatusGrupo}
                  options={optionsEstatusGrupo}
                  onChange={setEstatusGrupo}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={10}
            sm={3}
            spacing={2}
            style={{ marginTop: "1%" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <Grid style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  {false && (
                    <button
                      className={"buttonDownload"}
                      onClick={DownloadPDF}
                      disabled={req}
                      variant="contained"
                    >
                      <img src={pdfFileIcon} alt="pdf-file-icon" />
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>

        <div style={{ height: "calc(100% - 195px)" }} id="Pagos">
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ReporteCatalogoDeGrupos;
