import React from "react";
import Select, { components } from "react-select";
import SimpleCheck from "../SimpleCheck";

const asyncRowSelectStyles = {
  menu: (base) => ({
    ...base,
    maxHeight: "225px",
    zIndex: 9999,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "225px",
  }),
};

const MenuList = (props) => {
  return (
    <components.Menu {...props}>
      <components.MenuList {...props}>
        {props.options.map((item) => (
          <SimpleCheck
            label={item.label}
            isActive={item.isActive}
            onClick={() => {
              props.selectProps.onChange(item);
            }}
          />
        ))}
      </components.MenuList>
    </components.Menu>
  );
};

const MultiSelectCampus = ({ options, onChange, value }) => {
  return (
    <Select
      components={{ Menu: MenuList }}
      closeMenuOnSelect={false}
      options={options}
      className="select-modal-container"
      classNamePrefix="select-modal"
      styles={asyncRowSelectStyles}
      onChange={onChange}
      value={value}
    />
  );
};

export default MultiSelectCampus;
