import { enqueueSnackbar } from "notistack";
export const validateProfesion = (clave, name, dataFinal) => {
    if (!clave || !name) {
        enqueueSnackbar("Por favor llenar todos los campos", {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    } else if (dataFinal.filter((element) => element.clave === clave).length > 0) {
        enqueueSnackbar(`Ya existe un registro con la clave ${clave}`, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    } else if (dataFinal.filter((element) => element.nombre.toLowerCase().trim() === name.toLowerCase().trim()).length > 0) {
        enqueueSnackbar(`Ya existe un registro con ese nombre ${name.trim()}`, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
        return false
    }
    return true
}