import React, { useState } from "react";
import "./index.css";

export default function Tabs({
  containersTabs,
  namesTabs,
  modalTabs,
  changeBox = () => { },
  handleChangeTabs = () => { }
}) {
  let tabColor = "#026eb7",
    tabComponent;
  tabComponent = containersTabs;
  const [activeTab, setActiveTab] = useState(1);
  const handleChangeClick = (tab) => {
    handleChangeTabs(tab)
  }
  return (
    <>
      <div className='tabs-list'>
        {namesTabs.map((tab) => (
          <div
            key={tab[0]}
            className={activeTab === tab[0] ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab(tab[0]);
              changeBox(tab[0]);
              handleChangeClick(tab[0])
            }}
          >
            {tab[1]}
          </div>
        ))}
      </div>
      <div className={modalTabs ? "tab-content-modal" : "tab-content"}>
        <div className='tab-header' style={{ backgroundColor: tabColor }}></div>
        {tabComponent[activeTab - 1][0]}
      </div>
    </>
  );
}
