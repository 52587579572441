import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CreateModalInputs from "../../Utils/CreateModalInputs";
import CreateModalAsyncSelect from "../../Utils/CreateModalAsyncSelect";
import ModalSelect from "../ModalSelect";
import { getRequest } from "../../Utils/Funciones/requester";
import DateInput from "../DateInput";
import moment from "moment";

function CreateDictamentModal({
  createTeacher,
  setCreateTeacher,
  createGrupoValue,
  setCreateGrupoValue,
  createMateria,
  setCreateMateria,
  createCiclo,
  setCreateCiclo,
  createDate,
  setCreateDate,
  createComment,
  setCreateComment,
  createHours,
  setCreateHours,
  createMinutes,
  setCreateMinutes,
  tipoClase,
  setTipoClase
}) {
  const minChars = 5;
  const waitTime = 2000;
  const campusName = useSelector((state) => state.campusName);
  const campusId = useSelector((state) => state.campusId);
  const { enqueueSnackbar } = useSnackbar();
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const tipoClaseOptions  = [
    {
      label: "1 - Normal",
      value: "1",
    },
    {
      label: "2 - Reposición",
      value: "2",
    },
    {
      label: "3 - Adicional",
      value: "3",
    },
    {
      label: "4 - Reposición Adelantada",
      value: "4",
    },
    {
      label: "5 - Sustitución",
      value: "5",
    },
    {
      label: "6 - Visita de Campo",
      value: "6",
    },
    {
      label: "7 - Visitas Guiadas",
      value: "7",
    },
    {
      label: "9 - Reposición por Incapacidad",
      value: "9",
    },
    {
      label: "10 - Sustitución por Incapacidad",
      value: "10",
    },
    {
      label: "16 - Sustitución por Paternidad",
      value: "16",
    },
  ];
  const handleCreateTeacher = (event) => {
    setCreateTeacher(event);
  };

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor_activos&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            valido: item.valido,
            valido_incapacidad: item.valido_incapacidad,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  const handleGrupoValue = (e) => {
    console.log("HERE!!!!")
    console.log(e)
    setCreateGrupoValue(e);
    setCreateMateria("");
  };

  function promiseOptions(inputValue) {
    let timer;
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  async function searchMateria(id_campus, grupo, ciclo, createTeacher, tipoClase) {
    setSelectMateriaIsLoading(true);
    if (createTeacher?.value === undefined) {
      enqueueSnackbar("Se debe selecionar un profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    try {
      var options = [];
      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${createTeacher.value},grupo:${grupo},ciclo:${ciclo},tipo_clase:${tipoClase.value}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
            id_asignacion: item.id_asignacion,
          });
        });
        if (options.length === 0) {
          setMateriaOptions([]);
          enqueueSnackbar(
            "El grupo ingresado no encuentra materias asignadas",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
        }
        let hash = {};
        options = options.filter((o) =>
          hash[o.label] ? false : (hash[o.label] = true)
        );
        setMateriaOptions(options);
      } else {
        setMateriaOptions([])
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  function handleHoraValue(value, type) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      if (type === "i") {
        setCreateHours(value);
      }
    }
  }

  function handleMinutosValue(value, type) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      if (type === "i") {
        setCreateMinutes(value);
      }
    }
  }

  useEffect(() => {
    if (createGrupoValue.length >= 4 && createCiclo.length >= 5) {
      setTimeout(() => {
        searchMateria(campusId, createGrupoValue, createCiclo, createTeacher, tipoClase);
      }, waitTime);
    }
  }, [createGrupoValue, createCiclo, campusId, createTeacher, tipoClase]);

  return (
    <Grid container style={{ marginTop: 10, overflow: "hidden" }}>
      <Grid
        container
        item
        justifyContent="space-around"
        style={{ position: "absolute" }}
      >
        <Grid item xs={5}>
          <CreateModalInputs
            label={"Campus"}
            inputValue={campusName}
            disabled={true}
          />
        </Grid>
        <Grid item xs={5}>
          <CreateModalInputs
            label={"Ciclo(*)"}
            inputValue={createCiclo}
            onChange={(e) => setCreateCiclo(e)}
            disabled={false}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={1}
        justifyContent="space-around"
        style={{ position: "absolute", marginTop: 50, zIndex: 999 }}
      >
        <Grid item xs={5}>
          <ModalSelect
            label={"Tipo de Clase(*)"}
            onChange={(e) => {
              setTipoClase(e);
            }}
            options={tipoClaseOptions}
            value={tipoClase}
            width={100}
            isClearable={false}
          />
        </Grid>
        <Grid item xs={5}>
          <CreateModalAsyncSelect
            label={"Clave Profesor(*)"}
            onChange={handleCreateTeacher}
            promiseOptions={promiseOptions}
            value={createTeacher}
            width={100}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={1}
        justifyContent="space-around"
        style={{ position: "absolute", marginTop: 100, zIndex: 998 }}
      >
        <Grid item xs={5}>
          <CreateModalInputs
            label={"Grupo(*)"}
            inputValue={createGrupoValue}
            onChange={handleGrupoValue}
            disabled={false}
          />
        </Grid>
        <Grid item xs={5}>
          <ModalSelect
            label={"Materia(*)"}
            onChange={(e) => {
              setCreateMateria(e);
            }}
            options={materiaOptions}
            isLoading={selectMateriaIsLoading}
            value={createMateria}
            width={180}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={1}
        justifyContent="space-around"
        style={{ position: "absolute", marginTop: 150, zIndex: 997 }}
      >
        <Grid item xs={5}>
          <DateInput
            lable={"Fecha(*):"}
            onChange={(e) => {
              setCreateDate(e);
            }}
            value={createDate}
            max={moment().format("YYYY-MM-DD")}
          />
        </Grid>
        <Grid
          item
          xs={5}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: 22,
          }}
        >
          <Grid
            item
            xs={1}
            container
            style={{ justifyContent: "flex-end", marginRight: 10 }}
          >
            <label>Hora(*):</label>
          </Grid>
          <Grid item style={{ display: "flex", width: 300 }}>
            <input
              className="filter-input"
              type="number"
              min="0"
              max="23"
              onChange={(e) => handleHoraValue(e.target.value, "i")}
              value={createHours}
            />
            <label style={{ marginRight: 5, marginLeft: 5 }}>:</label>
            <input
              className="filter-input"
              type="number"
              min="0"
              max="59"
              onChange={(e) => handleMinutosValue(e.target.value, "i")}
              value={createMinutes}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={1}
        justifyContent="space-around"
        style={{ position: "absolute", marginTop: 225, zIndex: 996 }}
      >
        <Grid item xs={1}>
          <label>Comentario(*):</label>
        </Grid>
        <Grid xs={9} item>
          <textarea
            className="text-area-modal"
            rows={5}
            placeholder="Escribe aquí"
            value={createComment}
            onChange={(e) => {
              setCreateComment(e.target.value);
            }}
          ></textarea>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreateDictamentModal;
