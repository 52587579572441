import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import "./index.css";
import NewButton from "../../Utils/Botones/NewButton";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import SearchInput from "../../Utils/Visuales/SearchInput";
import NewModal from "../../Utils/Visuales/NewModal";
import Grid from "@material-ui/core/Grid";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Tabs from "../../Utils/Tabs";
import EditarUsuario from "./EditarUsuario";
import Permisos from "./Permisos";
import NuevoUsuario from "./NuevoUsuario";
import { useSelector } from "react-redux";

export default function Usuarios(props) {
  const campusUser = useSelector((state) => state.campusUser);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [nuevoUsuarioModal, setNuevoUsuarioModal] = useState(false);
  const [valoresPermisos, setvaloresPermisos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModalEditar, setLoadingModalEditar] = useState(false);
  const [loadingModalNuevo, setLoadingModalNuevo] = useState(false);
  const [opcionesRol, setOpcionesRol] = useState([]);
  const [opcionesPermisos, setOpcionesPermisos] = useState([]);
  const [todosPermisos, setTodosPermisos] = useState([]);
  const permisos = useSelector((state) => state.permisos.Usuarios);
  const [rolesDefault, setRolesDefault] = useState("");
  const [idPermisos, setIdPermisos] = useState("");
  const [modalEditarUsuarioIsOpen, setModalEditarUsuarioIsOpen] =
    useState(false);
  const [campusSelected, setCampusSelected] = useState(
    campusUser.map((item) => ({
      ...item,
      isActive: false,
    }))
  );
  const headers = [
    {
      name: "Clave",
      col: 0,
    },
    {
      name: "Nombre",
      col: 1,
    },
    {
      name: "Rol",
      col: 2,
    },
    {
      name: "Permisos",
      col: 3,
    },
    {
      name: "Acciones",
      col: 4,
    },
  ];

  // Crear y editar usuario
  const [usuarioEditar, setUsuarioEditar] = useState({});
  const [usuarioNuevo, setUsuarioNuevo] = useState({
    nss: "",
    nombre: "",
    apellidoP: "",
    apellidoM: "",
    email: "",
    campus: "",
    rol: "",
  });
  const namesTabs = [
    [1, "Información"],
    [2, "Permisos"],
  ];
  const { enqueueSnackbar } = useSnackbar();

  async function openEditModal(id) {
    setLoadingModalEditar(true);
    setModalEditarUsuarioIsOpen(true);
    try {
      let usuarioResponse = await getRequest(`/usuarios?id=${id}`);
      let response = await getRequest("/usuarios/rol");
      if (response.s === "OK") {
        let data = [];
        response.d.forEach((rol) => {
          data.push({
            label: rol,
            value: rol,
          });
        });
        setOpcionesRol(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      const campusResponse = await getRequest("/catalogo?id=5");
      const campusData = [];
      if (campusResponse.s === "OK") {
        campusData.push({
          label: "Todos",
          value: "0",
          isActive: false,
        });
        for (let i = 0; i < campusResponse.d.elementos[0].id.length; i++) {
          campusData.push({
            label: `${campusResponse.d.elementos[1].clave[i]} - ${campusResponse.d.elementos[2].nombre[i]}`,
            value: String(campusResponse.d.elementos[0].id[i]),
            isActive: false,
          });
        }
      }
      if (usuarioResponse.s === "OK") {
        const campusArray = usuarioResponse.d.campus.split(",");
        const userCampus = campusData.map((campus) => {
          if (campusArray.includes(campus.value)) {
            return { ...campus, isActive: true };
          }
          return { ...campus };
        });
        const allActive = userCampus.every(
          (campus) => campus.value === "0" || campus.isActive
        );
        userCampus[0]["isActive"] = allActive;
        setUsuarioEditar(usuarioResponse.d);
        setCampusSelected(userCampus);
        setRolesDefault({
          label: usuarioResponse.d.rol,
          value: usuarioResponse.d.rol,
        });

        setvaloresPermisos(usuarioResponse.d.permisos);
        setIdPermisos({
          label: usuarioResponse.d.catalogo_permiso,
          value: usuarioResponse.d.catalogo_permiso,
        });
      }
      await catalogo();
      setLoadingModalEditar(false);
    } catch (error) {
      console.log(error);
    }
  }

  const setValores = () => {
    setUsuarioNuevo({
      nss: "",
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      email: "",
      campus: "",
      rol: "",
    });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let response = await getRequest("/usuarios");
      if (response.s === "OK") {
        let data = [];
        response.d.forEach((usuario) => {
          data.push([
            usuario.nss,
            usuario.nombre,
            usuario.rol,
            usuario.permiso,
            <EditButton onClick={() => openEditModal(usuario.id)}>
              Editar
            </EditButton>,
          ]);
        });
        setDataOriginal(data);
        setDataFinal(data);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchData();
  }, []);

  async function handleOpenModalNuevoUsuario() {
    window.localStorage.setItem("permisos", JSON.stringify([]));
    setNuevoUsuarioModal(true);
    setUsuarioNuevo({
      nss: "",
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      email: "",
      campus: "",
      rol: "",
    });
    let response = await getRequest("/usuarios/rol");
    if (response.s === "OK") {
      let data = [];
      response.d.forEach((rol) => {
        data.push({
          label: rol,
          value: rol,
        });
      });
      setOpcionesRol(data);
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    const campusResponse = await getRequest("/catalogo?id=5");
    const campusData = [];
    if (campusResponse.s === "OK") {
      campusData.push({
        label: "Todos",
        value: "0",
        isActive: false,
      });
      for (let i = 0; i < campusResponse.d.elementos[0].id.length; i++) {
        campusData.push({
          label: `${campusResponse.d.elementos[1].clave[i]} - ${campusResponse.d.elementos[2].nombre[i]}`,
          value: String(campusResponse.d.elementos[0].id[i]),
          isActive: false,
        });
      }
    }
    setCampusSelected(campusData);
    await catalogo();
  }
  const catalogo = async () => {
    try {
      const response = await getRequest("/usuarios/catalogo_permiso");
      if (response.s === "OK") {
        let dataP = [];
        setTodosPermisos(response.d);
        for (const catalogo in response.d) {
          dataP.push({ value: catalogo, label: `${catalogo}` });
        }
        setOpcionesPermisos(dataP);
      }
    } catch (error) {
      console.log(error);
    } finally {
      console.log(opcionesPermisos);
    }
  };
  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }

  const handleSubmitEditarUsuario = async (e) => {
    setLoadingModalEditar(true);
    try {
      const arraycampus = campusSelected
        .filter((campus) => campus.isActive && campus.label !== "Todos")
        .map((campus) => campus.value);
      const id_permisos_ls = localStorage.getItem("idPermisos")
        ? localStorage.getItem("idPermisos")
        : usuarioEditar.id_catalogo_permiso;
      const permisos_ls = JSON.parse(localStorage.getItem("permisos"))
        ? JSON.parse(localStorage.getItem("permisos"))
        : todosPermisos[usuarioEditar.catalogo_permiso];

      let arreglado_per = permisos_ls.map((p, i) => {
        p[p.nombre] = p.permisos;
        delete p.permisos;
        return p;
      });

      let payload = {
        id_administrador: usuarioEditar.id,
        username: usuarioEditar.email,
        lista_campus: arraycampus,
        lista_rol: [usuarioEditar.rol],
        id_catalogo_permisos: id_permisos_ls,
        permisos: arreglado_per,
        apellidoM: usuarioEditar.apellidoM,
        apellidoP: usuarioEditar.apellidoP,
        nombre: usuarioEditar.nombre,
      };
      const response = await postRequest("/usuarios", payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        window.localStorage.removeItem("permisos");
        window.localStorage.removeItem("idPermisos");
        fetchData();
        setModalEditarUsuarioIsOpen(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingModalEditar(false);
    } catch (error) {
      console.log(error);
      setLoadingModalEditar(false);
    } finally {
      setLoadingModalEditar(false);
    }
  };
  const handleSubmitNuevoUsuario = async (e) => {
    const arraycampus = campusSelected
      .filter((campus) => campus.isActive && campus.label !== "Todos")
      .map((campus) => campus.value);
    const permisos = JSON.parse(localStorage.getItem("permisos"));
    if (
      usuarioNuevo.nss === "" ||
      usuarioNuevo.nombre === "" ||
      usuarioNuevo.apellidoP === "" ||
      usuarioNuevo.apellidoM === "" ||
      arraycampus.length === 0 ||
      usuarioNuevo.rol === "" ||
      permisos.length === 0
    ) {
      enqueueSnackbar("Todos los campos son obligatorios", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    try {
      let payload = {
        clave: usuarioNuevo.nss,
        nombre: usuarioNuevo.nombre,
        apellido_paterno: usuarioNuevo.apellidoP,
        apellido_materno: usuarioNuevo.apellidoM,
        correo: usuarioNuevo.email,
        lista_campus: arraycampus,
        lista_rol: [usuarioNuevo.rol.value],
        id_catalogo_permisos: permisos[0].id_catalogo,
        permisos: permisos,
      };
      setLoadingModalNuevo(true);
      const response = await postRequest("/usuarios/nuevo", payload);
      if (response.s === "OK") {
        window.localStorage.removeItem("permisos");
        window.localStorage.removeItem("idPermisos");
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setNuevoUsuarioModal(false);
        fetchData();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoadingModalNuevo(false);
      setValores();
    }
  };

  function changePermiso(key, index, permiso, value) {
    let misValores = [];
    valoresPermisos.forEach((valor) => {
      misValores.push(valor);
    });
    if (permiso === "todos") {
      if (value) {
        Object.keys(misValores[index]).forEach((reg) => {
          if (
            reg === "actualizar" ||
            reg === "eliminar" ||
            reg === "crear" ||
            reg === "leer" ||
            reg === "todos"
          ) {
            misValores[index][reg] = true;
          }
        });
      } else {
        Object.keys(misValores[index]).forEach((reg) => {
          if (
            reg === "actualizar" ||
            reg === "eliminar" ||
            reg === "crear" ||
            reg === "leer" ||
            reg === "todos"
          ) {
            misValores[index][reg] = false;
          }
        });
      }
    } else {
      misValores[index][permiso] = value;
      if (!value) {
        misValores[index]["todos"] = false;
      }
      if (permiso === "leer" && !value) {
        Object.keys(misValores[index]).forEach((reg) => {
          if (reg === "actualizar" || reg === "eliminar" || reg === "crear") {
            misValores[index][reg] = false;
          }
        });
      }
    }
    setvaloresPermisos(misValores);
    // setConfirgPermisos(data.d);
  }

  function selectAll() {
    valoresPermisos.forEach((valor, i) => {
      changePermiso("", i, "todos", true);
    });
  }
  function deselectAll() {
    valoresPermisos.forEach((valor, i) => {
      changePermiso("", i, "todos", false);
    });
  }
  const handleChangeOpcionCampus = (selected) => {
    if (selected.label === "Todos") {
      const findTodos = campusSelected.find(
        (campus) => campus.label === "Todos"
      );
      setCampusSelected((prev) =>
        prev.map((campus) => ({ ...campus, isActive: !findTodos.isActive }))
      );
    } else {
      setCampusSelected((prev) => {
        const newPrev = prev.map((campus) => {
          if (campus.value === selected.value) {
            return { ...campus, isActive: !campus.isActive };
          }
          return { ...campus };
        });
        const allActive = newPrev.every(
          (campus) => campus.value === "0" || campus.isActive
        );
        return newPrev.map((campus) => {
          if (campus.label === "Todos") {
            return {
              ...campus,
              isActive: allActive,
            };
          }
          return { ...campus };
        });
      });
    }
  };

  const containersTabsEditar = [
    [
      <EditarUsuario
        usuarioEditar={usuarioEditar}
        setUsuarioEditar={setUsuarioEditar}
        opcionesRol={opcionesRol}
        rolesDefault={rolesDefault}
        opcionesCampus={campusSelected}
        handleChangeOpcionCampus={handleChangeOpcionCampus}
      />,
    ],
    [
      <Permisos
        deselectAll={deselectAll}
        selectAll={selectAll}
        valoresPermisos={valoresPermisos}
        changePermiso={changePermiso}
        listaPermisos={opcionesPermisos}
        todosPermisos={todosPermisos}
        idPermisoValue={idPermisos}
      />,
    ],
  ];

  const containersTabsNuevo = [
    [
      <NuevoUsuario
        usuarioNuevo={usuarioNuevo}
        setUsuarioNuevo={setUsuarioNuevo}
        opcionesRol={opcionesRol}
        rolesDefault={rolesDefault}
        opcionesCampus={campusSelected}
        handleChangeOpcionCampus={handleChangeOpcionCampus}
      />,
    ],
    [
      <Permisos
        deselectAll={deselectAll}
        selectAll={selectAll}
        valoresPermisos={valoresPermisos}
        changePermiso={changePermiso}
        listaPermisos={opcionesPermisos}
        todosPermisos={todosPermisos}
      />,
    ],
  ];

  return (
    <Contenedor title="Usuarios">
      <Grid container spacing={1} style={{ marginBottom: "8px" }}>
        <Grid item xs={6} md={1}>
          {(permisos.escribir === 1 || permisos.escribir === "1") && (
            <NewButton
              noIcon
              style={{ padding: "5px 15px" }}
              onClick={handleOpenModalNuevoUsuario}
            />
          )}
        </Grid>
        <Grid item xs={0} md={8} />
        <Grid item xs={6} md={3}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 65px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[3, 4]}
          paginate
          bordes
          moreCells={9}
        />
      </div>
      <NewModal
        title="Nuevo Usuario"
        open={nuevoUsuarioModal}
        handleClose={() => {
          setNuevoUsuarioModal(false);
          setValores();
          window.localStorage.removeItem("permisos");
          window.localStorage.removeItem("idPermisos");
        }}
        height={550}
        loading={loadingModalNuevo}
        handleSubmit={handleSubmitNuevoUsuario}
      >
        <Tabs
          modalTabs
          namesTabs={namesTabs}
          containersTabs={containersTabsNuevo}
        />
      </NewModal>

      <NewModal
        title="Editar Usuario"
        open={modalEditarUsuarioIsOpen}
        handleSubmit={handleSubmitEditarUsuario}
        handleClose={(e) => {
          setModalEditarUsuarioIsOpen(false);
          setValores();
          window.localStorage.removeItem("permisos");
          window.localStorage.removeItem("idPermisos");
        }}
        height={550}
        loading={loadingModalEditar}
        desactivar={permisos.eliminar === "1" || permisos.eliminar === 1}
        handleDesactivar={() => {}}
        noSaveBtn={permisos.modificar === "0" || permisos.modificar === 0}
      >
        <Tabs
          modalTabs
          namesTabs={namesTabs}
          containersTabs={containersTabsEditar}
        />
      </NewModal>
    </Contenedor>
  );
}
