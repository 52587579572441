import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { ExportCSV } from "../../Utils/Excel";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import * as XLSX from "xlsx";
import TextField from "@mui/material/TextField";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import EditButton from "../../Utils/Botones/EditButton";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import NewModal from "../../Utils/Visuales/NewModal";
import styles from "../Reactivar/styles.module.css";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import moment from "moment";
import "./index.css";
import InputCheck from "../InputCheck";
import { CircularProgress } from "@material-ui/core";

function BloqueoAcademicoDocente() {
  const headers = [
    {
      name: "Clave Profesor",
      col: 0,
      id: "clave_profesor",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 1,
      id: "nombre_profesor",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 2,
      id: "campus",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 3,
      id: "escuela",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha",
      col: 4,
      id: "fecha",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Estatus Bloqueo",
      col: 5,
      id: "estatus",
      width: "15%",
      align: "center",
      fn: null,
    },
  ];

  const headersTable = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre",
      col: 1,
    },
    {
      name: "Campus",
      col: 2,
    },
    {
      name: "Escuela",
      col: 3,
    },
    {
      name: "Fecha",
      col: 4,
    },
    {
      name: "Estatus",
      col: 4,
    },
    {
      name: "Acciones",
      col: 6,
    },
  ];

  const [dataFinal, setDataFinal] = useState([]);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalConfirmacionIsOpen, setModalConfirmacionIsOpen] = useState(false);
  const [modalConsultaIsOpen, setModalConsultaIsOpen] = useState(false);
  const [modalBloqueoIsOpen, setModalBloqueoIsOpen] = useState(false);
  const [count, setCount] = useState(1);
  const [modalBloqueoIndividualIsOpen, setModalBloqueoIndividualIsOpen] =
    useState(false);
  const [fileAvailable, setFileAvailable] = useState(false);
  const [dataBloqueo, setDataBloqueo] = useState([]);
  // const [dataBloqueoOriginal, setDataBloqueoOriginal] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [profesorValue, setProfesorValue] = useState("");
  const [profesorValueBloqueo, setProfesorValueBloqueo] = useState("");
  const campusUser = useSelector((state) => state.campusUser2);
  const permisos = useSelector((state) => state.permisos);
  // const [selectDataCampus, setSelectDataCampus] = useState([]);
  const [dataPrev, setDataPrev] = useState({});
  const [checkedGroup, setCheckedGroup] = useState(false);
  const [headersBloque, setHeadersBloque] = useState(false);
  const [noFooterBloqueoGrupal, setNoFooterBloqueoGrupal] = useState(false);
  const [checkedGroupValidate, setCheckedGroupValidate] = useState(false);
  const [selectedEstatus, setSelectedEstatus] = useState("");
  const headersBloqueoProcesar = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre",
      col: 1,
    },
    {
      name: "Campus",
      col: 2,
    },
    {
      name: "Escuela",
      col: 3,
    },
    {
      name: "Motivo",
      col: 4,
    },
    {
      name: "Fecha",
      col: 5,
    },
    {
      name: "Estatus",
      col: 6,
    },
    {
      name: "Procesar",
      col: 7,
    },
  ];
  const headersBloqueoEstatus = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre",
      col: 1,
    },
    {
      name: "Campus",
      col: 2,
    },
    {
      name: "Escuela",
      col: 3,
    },
    {
      name: "Estatus",
      col: 4,
    },
  ];
  const campusName = useSelector((state) => state.campusName);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const optionsEstatus = [
    {
      label: "Activo",
      value: "Activo",
    },
    {
      label: "Inactivo",
      value: "Inactivo",
    },
    {
      label: "Ambos",
      value: "Ambos",
    },
  ];
  const [input, setInput] = useState({
    motivo: "",
    fechaInicial: "",
    fechaFinal: "",
  });
  const [campusValores] = useState(campusUser);
  const [errorOcurred, setErrorOcurred] = useState(false);
  const [loadingBloqueo, setLoadingBloqueo] = useState(false);
  const [optionsCampus, setOptionsCampus] = useState([]);
  const [campusSelectedOptions, setCampusSelectedOptions] = useState([]);
  const [escuelaOptions, setEscuelaOptions] = useState([]);
  const [selectedEscuelaOptions, setSelectedEscuelaOptions] = useState([]);
  const [estatus, setEstatus] = useState([]);
  const [fechaInicialValue, setFechaInicialValue] = useState("");
  const [fechaFinalValue, setFechaFinalValue] = useState("");
  const [editarBoton, setEditarBoton] = useState({
    actualizado_por: "",
    clave_profesor: "",
    creado_por: "",
    estatus: "",
    fecha_actualizado: "",
    fecha_creado: "",
    id: "",
    motivo: "",
    nombre_campus: "",
    nombre_escuela: "",
    nombre_profesor: "",
  });

  const [updatedStatus, setUpdatedStatus] = useState("");
  const [checkEstatus, setCheckEstatus] = useState([]);

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const asyncSelectStylesB = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
    menuList: (base) => ({
      ...base,
      maxHeight: "180px",
    }),
  };

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "220px",
    }),
  };

  function updateEditarBoton(item) {
    setLoading(true);
    setEditarBoton({
      actualizado_por: item.actualizado_por,
      clave_profesor: item.clave_profesor,
      creado_por: item.creado_por,
      estatus: item.estatus,
      fecha_actualizado: item.fecha_actualizado,
      fecha_creado: item.fecha_creado,
      id: item.id,
      motivo: item.motivo,
      nombre_campus: item.nombre_campus,
      nombre_escuela: item.nombre_escuela,
      nombre_profesor: item.nombre_profesor,
    });

    setEstatus(item.estatus);
    setLoading(false);
  }

  function openConfirmacion(item) {
    updateEditarBoton(item);
    setModalConfirmacionIsOpen(true);
  }

  function openConsulta(item) {
    updateEditarBoton(item);
    setModalConsultaIsOpen(true);
  }

  function openBloqueo() {
    setNoFooterBloqueoGrupal(false);
    setHeadersBloque(false);
    setLoading(true);
    setModalBloqueoIsOpen(true);
    setLoading(false);
  }

  function openNuevoBloqueo() {
    setLoading(true);
    setModalBloqueoIndividualIsOpen(true);
    setProfesorValueBloqueo("");
    setLoading(false);
  }

  useEffect(() => {
    searchEscuela();
    buscarCampusCatalogo();
    setSelectedCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  function downloadFile() {
    window.location.href =
      "https://s3.us-west-1.amazonaws.com/unitecsica.com/plantilla_bloqueo_academico_masivo.xlsx";
  }

  async function searchEscuela() {
    try {
      setLoading(true);
      let arrElements = [];
      // let arrElements2 = [{ label: "todas", value: "*" }];
      let arrElements2 = [];
      const request = `/catalogo?id=9`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        let nroEscuelas = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nroEscuelas; i++) {
          arrElements.push({
            value: parseInt(response.d.elementos[0]["id"][i]),
            clave: response.d.elementos[1]["clave"][i],
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
          });
          arrElements2.push({
            id: response.d.elementos[0]["id"][i],
            clave: response.d.elementos[1]["clave"][i],
            value:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
          });
        }
        var ordenado = arrElements.sort(SortArray);
        var ordenado2 = arrElements2.sort(SortArray);
        setLoading(false);
        setSelectEscuela(ordenado);
        setEscuelaOptions(ordenado2);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function SortArray(x, y) {
    if (x.clave < y.clave) {
      return -1;
    }
    if (x.clave > y.clave) {
      return 1;
    }
    return 0;
  }

  const waitTime = 2000;
  let timer;

  async function searchProfesor(valor) {
    try {
      const options = [];
      const request = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            nss: item.nss,
            name: item.nombre,
            value: item.id,
          });
        });
        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const promiseProfesor = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length > 2) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const RealizarBusqueda = async (
    valor1,
    valor2,
    valor3,
    valor4,
    valor5,
    valor6
  ) => {
    let nrofechaInicial = parseInt(
      fechaInicialValue.substring(0, 4) +
        fechaInicialValue.substring(5, 7) +
        fechaInicialValue.substring(8, 10)
    );
    let nrofechaFinal = parseInt(
      fechaFinalValue.substring(0, 4) +
        fechaFinalValue.substring(5, 7) +
        fechaFinalValue.substring(8, 10)
    );
    if (nrofechaFinal < nrofechaInicial) {
      enqueueSnackbar("Fecha Final es inferior a la Fecha Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setLoading(true);
    if (valor6) {
      try {
        const datos = [];
        // let request = `/asignaciones/bloqueo-academico?fecha_inicio=${valor2}&fecha_fin=${valor3}`;
        let request = `/asignaciones/bloqueo-academico?`;
        if (valor1) request += `id_profesor=${valor1.value}&`;
        if (valor2) request += `fecha_inicio=${valor2}&`;
        if (valor3) request += `fecha_fin=${valor3}&`;
        if (valor4) request += `id_campus=${valor4.value}&`;
        if (valor5) request += `id_escuela=${valor5.value}&`;
        if (valor6)
          request += valor6.value === "Ambos" ? "" : `estatus=${valor6.value}&`;

        const response = await getRequest(
          request.substring(0, request.length - 1)
        );
        if (response.s === "OK") {
          response.d.forEach((item) => {
            datos.push({ ...item });
          });

          const datosFinal = [];
          datos.forEach((item) => {
            datosFinal.push([
              item.clave_profesor,
              item.nombre_profesor,
              item.nombre_campus,
              item.nombre_escuela,
              item.fecha_creado,
              item.estatus,
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <EditButton
                  onClick={() => openConfirmacion(item)}
                  style={{
                    display:
                      permisos["Asignaciones->Bloqueo Académico Docente"]
                        .modificar === "1"
                        ? "block"
                        : "none",
                  }}
                >
                  Editar
                </EditButton>
                <EditButton onClick={() => openConsulta(item)}>
                  Consultar
                </EditButton>
              </div>,

              item.id,
            ]);
          });
          setDataFinal(datosFinal);
          setLoading(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } finally {
        setShowTable(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      enqueueSnackbar("Faltan llenar campo Estatus Bloqueo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
  };

  async function buscarCampusCatalogo() {
    try {
      setLoading(true);
      //let arrElements = [{ label: "TODOS", value: "*" }];
      let arrElements = [];
      // let arrElementsOptions = [];
      const request = `/catalogo?id=5`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        let nroCampus = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nroCampus; i++) {
          arrElements.push({
            id: response.d.elementos[0]["id"][i],
            clave: response.d.elementos[1]["clave"][i],
            value:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
          });
          // arrElementsOptions.push({
          //   value: response.d.elementos[0]["id"][i],
          //   label:
          //     response.d.elementos[1]["clave"][i] +
          //     " - " +
          //     response.d.elementos[2]["nombre"][i],
          // })
        }
        var ordenado = arrElements.sort(SortArray);
        setLoading(false);
        setOptionsCampus(ordenado);
        // setSelectDataCampus(arrElementsOptions)
      } else {
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function handleChange(e) {
    e.preventDefault();
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  const DownloadExcel = () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        clave_profesor: e[0],
        nombre: e[1],
        campus: e[2],
        escuela: e[3],
        fecha: e[4],
        estatus_bloqueo: e[5],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_BloqueoAcademicoDocente_${fecha}_${hora}`);
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        clave_profesor: e[0],
        nombre_profesor: e[1],
        campus: e[2],
        escuela: e[3],
        fecha: e[4],
        estatus: e[5],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Asignaciones Bloqueo Académico Docente`}
        campus={selectedCampus?.label}
        startDate={fechaInicialValue}
        endDate={fechaFinalValue}
        teacherKey={
          profesorValue?.value === undefined ? "" : profesorValue?.label
        }
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Asignaciones_BloqueoAcademicoDocente_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  async function handleSubmitConfirmacion() {
    let valido = true;
    if (input.motivo === "") {
      setErrorOcurred(true);
      valido = false;
    }
    if (updatedStatus === "") {
      setErrorOcurred(true);
      valido = false;
    }
    if (valido) {
      try {
        setLoading(true);
        setErrorOcurred(false);
        const payload = {
          id: parseInt(editarBoton.id),
          estatus: updatedStatus,
          comentario: input.motivo,
        };
        const response = await postRequest(
          "/asignaciones/bloqueo-academico",
          payload
        );
        if (response.s === "OK") {
          setModalConfirmacionIsOpen(false);
          setInput({
            ...input,
            motivo: "",
          });
          setUpdatedStatus("");
          await RealizarBusqueda(
            profesorValue,
            fechaInicialValue,
            fechaFinalValue,
            selectedCampus,
            selectedEscuela,
            selectedEstatus
          );
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  }
  async function handleSubmitBloqueoGrupal() {
    if (checkEstatus.length === 0) {
      enqueueSnackbar("SELECCIONE ELEMENTOS A PROCESAR", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setNoFooterBloqueoGrupal(true);
      setHeadersBloque(true);
      setLoadingBloqueo(true);
      const dataCopy = [...checkEstatus].slice(1);
      setDataBloqueo(dataCopy);
      const data_ = [];
      for (let i = 0; i < dataCopy.length; i++) {
        const body = {
          clave_profesor: `${dataCopy[i][0]}`,
          clave_campus: `${dataCopy[i][2]}`,
          clave_escuela: `${dataCopy[i][3]}`,
          comentario: dataCopy[i][4],
        };
        try {
          setErrorOcurred(false);
          const response = await postRequest(
            "/asignaciones/bloqueo-academico/nuevo",
            body
          );
          setCount((prev) => prev + 1);
          if (response.s === "OK") {
            data_.push([
              dataCopy[i][0],
              dataCopy[i][1],
              dataCopy[i][2],
              dataCopy[i][3],
              response.s,
            ]);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          } else {
            data_.push([
              dataCopy[i][0],
              dataCopy[i][1],
              dataCopy[i][2],
              dataCopy[i][3],
              `Error: ${response.m}`,
            ]);
            enqueueSnackbar(`${response.m}`, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          setLoadingBloqueo(false);
          enqueueSnackbar(error, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
        setTimeout(() => {}, 5000);
      }
      setLoadingBloqueo(false);
      setDataBloqueo([]);
      setDataBloqueo(data_);
    }
  }

  async function handleSubmitNuevoBloqueo() {
    setLoading(true);
    if (
      profesorValueBloqueo["value"] === "" ||
      campusSelectedOptions.id === "" ||
      selectedEscuelaOptions.id === "" ||
      input.motivo === ""
    ) {
      setErrorOcurred(true);
      setLoading(false);
      return;
    } else {
      try {
        setErrorOcurred(false);
        const payload = {
          id_profesor: profesorValueBloqueo["value"],
          campus: campusSelectedOptions.id,
          escuela: selectedEscuelaOptions.id,
          comentario: input.motivo,
        };
        const response = await postRequest(
          "/asignaciones/bloqueo-academico/nuevo",
          payload
        );
        if (response.s === "OK") {
          setLoading(false);
          setModalBloqueoIndividualIsOpen(false);
          setInput({
            ...input,
            motivo: "",
          });
          setCampusSelectedOptions([]);
          setSelectedEscuelaOptions([]);
          setProfesorValueBloqueo("");
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        setLoading(false);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
  }

  async function handleSubmitConsulta() {
    setModalConsultaIsOpen(false);
  }

  const handleUpload = (e) => {
    e.preventDefault();
    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      try {
        const datos = [];
        const fechaActual = moment().format("DD-MM-YYYY");
        if (
          dataParse[0].length === 5 &&
          dataParse[0][0] === "clave_profesor" &&
          dataParse[0][1] === "nombre_profesor" &&
          dataParse[0][2] === "campus" &&
          dataParse[0][3] === "escuela" &&
          dataParse[0][4] === "motivo"
        ) {
          for (let f = 1; f < dataParse.length; f++) {
            const fila = [];
            for (let i = 0; i < dataParse[f].length; i++) {
              if (dataParse[f][i] === undefined) {
                enqueueSnackbar("Hay datos faltantes en el documento subido", {
                  variant: "error",
                  autoHideDuration: null,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                });
                return;
              }
              fila.push(dataParse[f][i]);
            }
            fila.push(fechaActual, "Activo");
            fila.push(
              <InputCheck
                handleCheckEstatus={handleCheckEstatus}
                data={dataParse[f]}
                index={dataParse[f].length}
              />
            );
            datos.push(fila);
          }
          setDataBloqueo(datos);
          setFileAvailable(true);
          setCheckEstatus(dataParse);
        } else {
          enqueueSnackbar(
            "El documento subido no es formato xlsx o tiene datos erróneos en las columnas clave_profesor, nombre_profesor, campus, escuela o motivo",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return;
        }
      } catch (error) {
        console.log(error);
      }
    };
    reader.readAsBinaryString(f);
  };

  const handleCheckEstatus = (data, idRow, valor) => {
    const checked = valor.target.checked;
    const p = checkEstatus;
    if (checked === true) {
      p.push(data);
      setCheckEstatus(p);
    } else {
      setCheckEstatus((prev) =>
        prev.filter((x) => {
          return `${x[0]}-${x[3]}` !== `${data[0]}-${data[3]}`;
        })
      );
    }
  };

  return (
    <Contenedor title="Asignaciones / Bloqueo Académico Docente">
      <div className="inputs-asignaciones">
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <NewButton
              noIcon
              customText="Nuevo"
              style={{
                padding: "5px 15px",
                display:
                  permisos["Asignaciones->Bloqueo Académico Docente"]
                    .escribir === "1"
                    ? "block"
                    : "none",
              }}
              onClick={openNuevoBloqueo}
            />
          </Grid>
          <Grid item xs={3} className="flex-item nuevoUsuarioForm">
            <label htmlFor="initialDate">Fecha Inicial:</label>
            <TextField
              type="date"
              id="initialDate"
              className="select-modal input-ciclo"
              onChange={(e) => setFechaInicialValue(e.target.value)}
              style={{ width: "200px" }}
              value={fechaInicialValue}
              name="fechaInicial"
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .notchedOutlinet": {
                  border: "none !important",
                },
              }}
            />
          </Grid>
          <Grid item xs={3} className="flex-item nuevoUsuarioForm">
            <label htmlFor="endDate">Fecha Final:</label>
            <TextField
              className="select-modal input-ciclo"
              id="endDate"
              type="date"
              style={{ width: "200px" }}
              InputProps={{
                inputProps: { min: fechaInicialValue, shrink: true },
              }}
              disabled={fechaInicialValue !== "" ? false : true}
              sx={{
                "& .notchedOutlinet": {
                  border: "none !important",
                },
              }}
              value={fechaFinalValue}
              name="fechaFinal"
              onChange={(e) => setFechaFinalValue(e.target.value)}
            />
          </Grid>
          <Grid item xs={3} className="flex-item" style={{ zIndex: "999" }}>
            <label htmlFor="school">Estatus Bloqueo(*):</label>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              options={optionsEstatus}
              onChange={setSelectedEstatus}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <NewButton
              customText="Buscar"
              noIcon
              style={{ padding: "5px 15px" }}
              onClick={() =>
                RealizarBusqueda(
                  profesorValue,
                  fechaInicialValue,
                  fechaFinalValue,
                  selectedCampus,
                  selectedEscuela,
                  selectedEstatus
                )
              }
            />
          </Grid>

          {/* Second row on Grid */}

          <Grid item xs={1}></Grid>
          <Grid item xs={3} className="flex-item" style={{ zIndex: 98 }}>
            <label htmlFor="campus">Campus:</label>
            <Select
              id="campus"
              className="select-modal-container"
              classNamePrefix="select-modal"
              style={{ width: "200px" }}
              options={campusValores}
              value={selectedCampus}
              onChange={setSelectedCampus}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={3} className="flex-item" style={{ zIndex: 98 }}>
            <label htmlFor="school">Escuela:</label>
            <Select
              id="school"
              className="select-modal-container"
              classNamePrefix="select-modal"
              options={selectEscuela}
              onChange={setSelectedEscuela}
              value={selectedEscuela}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={3} className="flex-item">
            <label htmlFor="professor">Clave Profesor: </label>
            <AsyncSelect
              id="professor"
              className="select-modal-container"
              classNamePrefix="select-modal"
              styles={asyncSelectStyles}
              loadOptions={promiseProfesor}
              value={profesorValue}
              onChange={setProfesorValue}
              placeholder="Buscar Profesor"
              isClearable={true}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <NewButton
              noIcon
              customText="Subir Archivo"
              style={{ padding: "5px 25px", textAlign: "center" }}
              onClick={openBloqueo}
            />
          </Grid>

          <Grid item xs={3} style={{ marginLeft: "-1%" }}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "calc(100% - 205px)" }}>
        <DataTable
          headers={headersTable}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Confirmación"
        open={modalConfirmacionIsOpen}
        handleClose={() => {
          setModalConfirmacionIsOpen(false);
          setInput({
            ...input,
            motivo: "",
          });
          setUpdatedStatus("");
          setErrorOcurred(false);
        }}
        handleSubmit={handleSubmitConfirmacion}
        height={600}
        loading={loading}
      >
        <div style={{ padding: "25px" }}>
          <Grid container>
            {errorOcurred ? (
              <Alert className={styles.alertRoot} severity="error">
                Campo Motivo y Checkbox son obligatorios
              </Alert>
            ) : null}
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Clave Profesor:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={editarBoton.clave_profesor}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Nombre Profesor:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={editarBoton.nombre_profesor}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Campus:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "520px",
                  // height: "90px",
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                disabled
                value={editarBoton.nombre_campus}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Escuela:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "520px",
                  // height: "90px",
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                disabled
                value={editarBoton.nombre_escuela}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Bloqueo:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                id="estatus"
                className="filter-input"
                style={{ width: "300px" }}
                value={estatus}
              />
            </Grid>
            <div
              style={{
                display:
                  permisos["Asignaciones->Bloqueo Académico Docente"]
                    .eliminar === "1"
                    ? "flex"
                    : "none",
                justifyContent: "center",
                marginTop: "30px",
                marginLeft: "25%",
              }}
            >
              <input
                id="lock"
                type="checkbox"
                value="Inactivo"
                style={{
                  display:
                    permisos["Asignaciones->Bloqueo Académico Docente"]
                      .eliminar === "1"
                      ? "block"
                      : "none",
                }}
                onChange={(e) => {
                  updatedStatus === "Inactivo"
                    ? setUpdatedStatus("")
                    : setUpdatedStatus(e.target.value);
                }}
                disabled={editarBoton.estatus === "Activo" ? false : true}
              />
              <label
                htmlFor="lock"
                style={{
                  display:
                    permisos["Asignaciones->Bloqueo Académico Docente"]
                      .eliminar === "1"
                      ? "block"
                      : "none",
                }}
              >
                Desactivar
              </label>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginLeft: "3%",
              }}
            >
              <input
                id="unlock"
                type="checkbox"
                value="Activo"
                style={{
                  display:
                    permisos["Asignaciones->Bloqueo Académico Docente"]
                      .eliminar === "1"
                      ? "block"
                      : "none",
                }}
                onChange={(e) => {
                  updatedStatus === "Activo"
                    ? setUpdatedStatus("")
                    : setUpdatedStatus(e.target.value);
                }}
                disabled={editarBoton.estatus === "Inactivo" ? false : true}
              />
              <label
                style={{
                  display:
                    permisos["Asignaciones->Bloqueo Académico Docente"]
                      .eliminar === "1"
                      ? "block"
                      : "none",
                }}
                htmlFor="unlock"
              >
                Activar
              </label>
            </div>
            <Grid
              item
              xs={9}
              style={{ marginTop: "30px", marginLeft: "13.5%" }}
            >
              <label style={{ display: "block" }}>Motivo:</label>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "520px",
                  height: "90px",
                  marginLeft: "11.5%",
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                name="motivo"
                value={input.motivo}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginTop: "20px", marginLeft: "10.5%" }}
            >
              <div className="bitacora">
                <p>Creado:</p>
                <span className="date">{editarBoton.fecha_creado}</span>
                <span className="user">{editarBoton.creado_por}</span>
              </div>
              <div className="bitacora">
                <p>Modificado:</p>
                <span className="date">{editarBoton.fecha_actualizado}</span>
                <span className="user">{editarBoton.actualizado_por}</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Consulta"
        open={modalConsultaIsOpen}
        handleClose={() => {
          setModalConsultaIsOpen(false);
        }}
        handleSubmit={handleSubmitConsulta}
        height={620}
        loading={loading}
      >
        <div style={{ padding: "25px" }}>
          <Grid container>
            <Grid item xs={3} style={{ marginTop: "10px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Clave Profesor:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "10px" }}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100px",
                  marginRight: "10px",
                }}
                value={editarBoton.clave_profesor}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Nombre Profesor:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                className="filter-input"
                style={{ width: "300px" }}
                value={editarBoton.nombre_profesor}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Campus:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "520px",
                  // height: "90px",
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                disabled
                value={editarBoton.nombre_campus}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Escuela:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "520px",
                  // height: "90px",
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                disabled
                value={editarBoton.nombre_escuela}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Bloqueo:
              </label>
            </Grid>
            <Grid item xs={9} style={{ marginTop: "30px" }}>
              <input
                disabled
                id="estatus"
                className="filter-input"
                style={{ width: "300px" }}
                value={estatus}
              />
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginTop: "30px", marginLeft: "16.5%" }}
            >
              <label style={{ display: "block" }}>Motivo:</label>
              <TextareaAutosize
                className="filter-input value-comentario"
                maxRows={5}
                style={{
                  width: "450px",
                  height: "90px",
                  marginLeft: "11.5%",
                  resize: "none",
                  overflowY: "scroll",
                  fontSize: "15px",
                }}
                name="motivo"
                value={editarBoton.motivo}
                disabled
              />
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginTop: "30px", marginLeft: "16.5%" }}
            >
              <div className="bitacora">
                <p>Creado:</p>
                <span className="date">{editarBoton.fecha_creado}</span>
                <span className="user">{editarBoton.creado_por}</span>
              </div>
              <div className="bitacora">
                <p>Modificado:</p>
                <span className="date">{editarBoton.fecha_actualizado}</span>
                <span className="user">{editarBoton.actualizado_por}</span>
              </div>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Bloqueo Académico Grupal"
        open={modalBloqueoIsOpen}
        handleClose={() => {
          setDataBloqueo([]);
          setCheckEstatus([]);
          setFileAvailable(false);
          setModalBloqueoIsOpen(false);
          setCount(1);
        }}
        height={600}
        loading={loading}
        handleSubmit={handleSubmitBloqueoGrupal}
        noFooter={noFooterBloqueoGrupal}
        maxWidth={1500}
      >
        <div style={{ padding: "25px", height: "90%" }}>
          <div style={{ height: "calc(100% - 65px)" }}>
            {fileAvailable ? (
              <>
                <h3>Seleccionar elementos a procesar</h3>
                {loadingBloqueo ? (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        position: "absolute",
                        width: "100%",
                      }}
                    >
                      <CircularProgress size={50} />
                      <p>
                        {count}/{checkEstatus.length - 1}
                      </p>
                    </div>
                  </>
                ) : (
                  <DataTable
                    headers={
                      headersBloque
                        ? headersBloqueoEstatus
                        : headersBloqueoProcesar
                    }
                    data={dataBloqueo}
                    centrar={[1, 2, 3, 4, 5]}
                    bordes
                  />
                )}
              </>
            ) : (
              <Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <NewButton
                    onClick={downloadFile}
                    noIcon
                    style={{ padding: "5px 15px" }}
                    customText="Descargar plantilla"
                  />
                </Grid>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <label htmlFor="">Cargar Archivo</label>
                  <input type="file" onInput={(e) => handleUpload(e)} />
                </div>
              </Grid>
            )}
          </div>
        </div>
      </NewModal>

      <NewModal
        title="Bloqueo Académico Individual"
        open={modalBloqueoIndividualIsOpen}
        handleClose={() => {
          setModalBloqueoIndividualIsOpen(false);
          setInput({
            ...input,
            motivo: "",
          });
          setCampusSelectedOptions([]);
          setSelectedEscuelaOptions([]);
          setErrorOcurred(false);
        }}
        height={500}
        loading={loading}
        handleSubmit={handleSubmitNuevoBloqueo}
      >
        <div style={{ padding: "25px" }}>
          <Grid container>
            {errorOcurred ? (
              <Alert className={styles.alertRoot} severity="error">
                TODOS LOS CAMPOS SON OBLIGATORIOS
              </Alert>
            ) : null}
            <Grid item xs={3} style={{ marginTop: "30px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Clave Profesor:
              </label>
            </Grid>
            <Grid item xs={7} style={{ marginTop: "25px", zIndex: 99 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={asyncSelectStylesB}
                loadOptions={promiseProfesor}
                value={profesorValueBloqueo}
                onChange={setProfesorValueBloqueo}
                placeholder="Buscar Profesor"
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "45px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Campus:
              </label>
            </Grid>
            <Grid item xs={7} style={{ marginTop: "40px", zIndex: 98 }}>
              <Select
                id="campus"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                options={[...optionsCampus]}
                value={campusSelectedOptions}
                onChange={setCampusSelectedOptions}
                isClearable={true}
              />
            </Grid>
            <Grid item xs={3} style={{ marginTop: "45px" }}>
              <label
                style={{
                  float: "right",
                  marginRight: "10px",
                  textAlign: "end",
                }}
              >
                Escuela:
              </label>
            </Grid>
            <Grid item xs={7} style={{ marginTop: "40px" }}>
              <Select
                id="campus"
                className="select-modal-container"
                classNamePrefix="select-modal"
                style={{ width: "200px" }}
                styles={selectStyles}
                options={[...escuelaOptions]}
                alue={selectedEscuelaOptions}
                onChange={setSelectedEscuelaOptions}
                isClearable={true}
              />
              {/* <ReactMultiSelectCheckboxes
                options={[...escuelaOptions]}
                placeholderButtonLabel="Seleccionado"
                getDropdownButtonLabel={getDropdownButtonLabel}
                styles={asyncSelectStylesB}
                value={selectedEscuelaOptions}
                onChange={onChangeEscuela}
                setState={setSelectedEscuelaOptions}
              /> */}
            </Grid>
            <Grid
              item
              xs={9}
              style={{ marginTop: "40px", marginLeft: "16.5%" }}
            >
              <label style={{ display: "block" }}>Motivo:</label>
              <textarea
                className="text-area-modal"
                style={{ width: "500px", height: "140px", marginTop: "10px" }}
                name="motivo"
                value={input.motivo}
                onChange={handleChange}
              ></textarea>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default BloqueoAcademicoDocente;
