
import React from 'react'
import { View, Text } from '@react-pdf/renderer'
import ReportSpecialClassPDFTable from '../ReportSpecialClassPDFTable'
import { divideArrayIntoParts } from '../Funciones/divideArrayIntoParts'
import HeaderPDFDefault from '../HeaderPDFDefault'

export default function ReportSpecialClassPDF({
    metadata,
    data,
    startDate,
    endDate,
    title,
    direccion,
    teacherKey,
    totalBox,
}) {
    return totalBox.schools.map((item, index) => {
        const dataForTable = data.filter((element) => element.escuela === item)
        const splitDataIntoParts = divideArrayIntoParts(dataForTable, 6)
        return splitDataIntoParts.map((dataTable, index2) => {
            return (
                <View break={index === 0 && index2 === 0 ? false : true} key={`${index}-${index2}`}>
                    <HeaderPDFDefault title={title} />
                    <View style={{ marginTop: 15 }}>
                        <View style={{ marginTop: 1 }}>
                            {startDate ? (
                                <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                                    <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Inicial: {startDate}</Text>
                                    {endDate ? (
                                        <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Final: {endDate}</Text>
                                    ) : null}
                                </View>
                            ) : null}
                            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                                <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Ciclo: {dataTable[0]?.ciclo.trim()}</Text>
                                <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Campus: {dataTable[0]?.campus.trim()}</Text>
                                <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Escuela: {dataTable[0]?.escuela.trim()}</Text>
                                {teacherKey ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Clave Profesor: {teacherKey.trim()}</Text>) : null}
                                {direccion ? (<Text style={{ fontSize: 10, color: '#000000' }}>Dirección: {direccion.trim()}</Text>) : null}
                            </View>
                            <ReportSpecialClassPDFTable
                                metadata={metadata}
                                data={dataTable}
                                campus={item}
                            />
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', width: '100%', marginTop: 10, justifyContent: "flex-end" }}>
                        <Text style={{ fontSize: 10, }}>Total de clases: {splitDataIntoParts.length}</Text>
                    </View>
                </View>
            )
        })
    })
}