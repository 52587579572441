import React from "react";
import { Grid } from "@material-ui/core";
import ModalInput from "../ModalInput";

const ModalCreateTabulator = ({ setTabulador }) => {
  return (
    <Grid style={{ padding: 20, paddingTop: 0 }}>
      <ModalInput
        lable="Tabulador:"
        onChange={(event) => setTabulador(event)}
      />
      <ModalInput lable="Estatus:" disabled defaultValue="Activo" />
    </Grid>
  );
};

export default ModalCreateTabulator;
