import { getRequest } from "../Funciones/requester";

export const getReportIndicadoresAcademicosSemanales = async (
  setLoading,
  object,
  enqueueSnackbar,
  setReportInfo,
  handleOpenModal
) => {
  setLoading(true);
  let consulta = `/reportes/indicadores_academicos_semanal?ciclo=${object.ciclo}&id_campus=${object.campus?.value}&id_escuela=${object.escuela?.value}&fecha_inicio=${object.fechaInicial}&fecha_fin=${object.fechaFinal}`;
  if (object.direccion?.value) {
    consulta += `&id_direccion=${object.direccion?.value}`;
  }
  const response = await getRequest(consulta);
  //   let rowsP = "";
  if (response.s === "OK") {
    enqueueSnackbar(response.m, {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    const data = response.d.ciclo.map((item, index) => {
      return [
        response.d.ciclo[index],
        response.d.campus[index],
        response.d.escuela[index],
        response.d.clave_direccion[index],
        response.d.nombre_director[index],
        <button
          className="EditButton"
          onClick={() => {
            const payload = {
              fechaInicio: object.fechaInicial,
              fechaFin: object.fechaFinal,
              ciclo: response.d.ciclo[index],
              claveDireccion: response.d.clave_direccion[index],
              iddireccionesDirector: response.d.id_direcciones_director[index],
              nombreDirector: response.d.nombre_director[index],
              campus: response.d.campus[index],
              escuela: response.d.escuela[index],
            };
            handleOpenModal(payload);
          }}
        >
          Consultar
        </button>,
        parseFloat(response.d.hr_prog[index].toFixed(2)),
        parseFloat(response.d.hr_faltas_brutas[index].toFixed(2)),
        parseFloat(response.d.hr_pctge_faltas_brutas[index].toFixed(2)),
        parseFloat(response.d.hr_aut[index].toFixed(2)),
        parseFloat(response.d.hr_rep[index].toFixed(2)),
        parseFloat(response.d.hr_faltas_netas[index].toFixed(2)),
        parseFloat(response.d.hr_pctge_faltas_netas[index].toFixed(2)),
        parseFloat(response.d.hr_sust[index].toFixed(2)),
        parseFloat(response.d.hr_adic[index].toFixed(2)),
        parseFloat(response.d.hr_visit[index].toFixed(2)),
        parseFloat(response.d.se_prog[index].toFixed(2)),
        parseFloat(response.d.se_faltas_brutas[index].toFixed(2)),
        parseFloat(response.d.se_aut[index].toFixed(2)),
        parseFloat(response.d.se_rep[index].toFixed(2)),
        parseFloat(response.d.se_faltas_netas[index].toFixed(2)),
        parseFloat(response.d.se_sust[index].toFixed(2)),
        parseFloat(response.d.se_adic[index].toFixed(2)),
        parseFloat(response.d.se_visit[index].toFixed(2)),
        parseFloat(response.d.se_salida_ant[index].toFixed(2)),
        parseFloat(response.d.se_ret[index].toFixed(2)),
        parseFloat(response.d.min_ret[index].toFixed(2)),
        parseFloat(response.d.min_can[index].toFixed(2)),
        parseFloat(response.d.id_direcciones_director[index]),
      ];
    });
    setReportInfo(data);
    setLoading(false);
  } else {
    enqueueSnackbar(response.m, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    setReportInfo([]);
    setLoading(false);
  }
};
