import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Prueba from "../../../images/femenino.png";
import Login from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button";
import "./index.css";

const CampusLoginCard = (props) => {
  const handleClick = () => {
    window.localStorage.setItem("campus_id", props.campus_id);
    props.login();
  };
  return (
    <>
      <Grid item md={4} xs={12} className='paper'>
        <div>
          <img className='img' alt='imagen del campus' src={props.imgn} />
        </div>
        <div className='text'>
          <Typography variant='h6'>{props.campus}</Typography>
        </div>
        <Button size='large' onClick={(e) => handleClick()}>
          <Login color='primary' />
          <Typography variant='h7' color='primary' className='ingresar'>
            INGRESAR
          </Typography>
        </Button>
      </Grid>
    </>
  );
};

export default CampusLoginCard;
