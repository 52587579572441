import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "../index.css";
import Select from "react-select";
import { getRequest } from "../../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import moment from "moment";
import { checkIfJExists } from "../../../Utils/Funciones/checkIfJExists";
import { Delete } from "@material-ui/icons";

let grupoTimer;

function AsignacionIndividual() {
  const { enqueueSnackbar } = useSnackbar();
  const campusUser = useSelector((state) => state.campusName);
  const campusId = useSelector((state) => state.campusId);
  const [valueCiclo, setValueCiclo] = useState("");
  const [grupoValue, setGrupoValue] = useState("");
  const [listSalon, setListSalon] = useState([]);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [dataHorarioFinal, setDataHorarioFinal] = useState([]);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [escuelaValue, setEscuelaValue] = useState("");
  const [direccion, setDireccion] = useState("");
  const [profesor, setProfesor] = useState("");
  const [horasSemanales, setHorasSemanales] = useState("");
  const [horasLaboratorio, setHorasLaboratorio] = useState("");
  const [asignados, setAsignados] = useState("");
  const [creado, setCreado] = useState("");
  const [creadoPor, setCreadoPor] = useState("");
  const [modificado, setModificado] = useState("");
  const [dataCustom, setDataCustom] = useState([]);
  const [modificadoPor, setModificadoPor] = useState("");
  const [busyClassrooms, setBusyClassrooms] = useState("");
  const [tipoSalonSelectValue, setTipoSalonSelectValue] = useState([]);
  const [salonSelectValue, setSalonSelectValue] = useState([]);

  const [materia, setMateria] = useState({
    label: "",
    value: "",
  });
  const [tipoSalon, setSelectDataTipoSalon] = useState({
    label: "",
    value: "",
  });
  const [tipoSalonesPost, setTipoSalonesPost] = useState([]);
  const [defaultSalonSelectValue, setDefaultSalonSelectValue] = useState({});
  const defaultTipoSalonSelectValue = {
    label: ` - `,
    value: null,
  };
  const selectUpsStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "250px",
    }),
  };

  const GetTipoSalon = async (campusId, enqueueSnackbar) => {
    try {
      const consulta = `/catalogo?id=24&id_campus=${campusId}`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.elementos.forEach((e, i) => {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        for (let step = 0; step < numRows_; step++) {
          response.d.elementos.forEach((e, i) => {
            Object.values(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        const dataSelect = rowsP.map((e) => {
          const data_ = {
            label: `${e[1]} - ${e[2]}`,
            value: e[0],
          };
          return data_;
        });
        setSelectDataTipoSalon(dataSelect);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function searchMateria(id_campus, grupo, ciclo, enqueueSnackbar) {
    setSelectMateriaIsLoading(true);
    try {
      var options = [];
      const consulta = `/buscar?tipo=asignacion_profesor&valor=id_campus:${id_campus},grupo:${grupo},ciclo:${ciclo}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        if (
          response.d === null ||
          response.d === "" ||
          response.d.length === 0
        ) {
          setMateriaOptions([]);
          setMateria("");
          enqueueSnackbar("Validar datos del grupo", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        }
        response.d.forEach((item, i) => {
          if (
            item.tipo_registro === "periodo fijo" &&
            item.id_person === "sin profesor"
          ) {
            options.push({
              id: item.id_asignacion,
              value: i,
              label: `${item.clave_materia} - ${item.nombre_materia}`,
              id_person: item.id_person,
              tipo_registro: item.tipo_registro,
              escuela: item.escuela,
              direccion: item.direccion,
              nombre_profesor: response.d.find(
                (item2) =>
                  item2.id_asignacion === item.id_asignacion &&
                  item2.tipo_registro === "periodo fijo" &&
                  item2.id_person !== "sin profesor"
              )?.nombre_profesor,
              start_date: item.start_date,
              end_date: item.end_date,
              horas_semanales: item.total_horas_semana,
              horas_laboratorio: item.total_horas_laboratorio,
              asignados: item.asignados,
              horario: item.horario.filter(
                (element) => element.id_person_ae === 0
              ),
              salon_creado: item.salon_creado === null ? "" : item.salon_creado,
              salon_creado_por:
                item.salon_creado_por === null ? "" : item.salon_creado_por,
              salon_modificado:
                item.salon_modificado === null ? "" : item.salon_modificado,
              salon_modificado_por:
                item.salon_modificado_por === null
                  ? ""
                  : item.salon_modificado_por,
              ultima_sesion: item.ultima_sesion,
              primera_sesion: item.primera_sesion,
            });
          }
        });
        if (options.length === 0) {
          setMateriaOptions([]);
          enqueueSnackbar(
            "No se ha encontrado ninguna materia con periodos fijos",
            {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return;
        }
        let hash = {};
        options = options.filter((o) =>
          hash[o.label] ? false : (hash[o.label] = true)
        );
        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  const getDefultSalon = async (campusId, enqueueSnackbar) => {
    try {
      const consulta = `/buscar?tipo=salon&valor=id:1,id_campus:${campusId}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        const defaultSalon = response.d.find((salon) => salon.nombre === " ");
        setDefaultSalonSelectValue({
          label: ` - `,
          value: defaultSalon.id_salon,
          name: " - ",
          capacidad: 0,
          delete: true,
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setListSalon([]);
      console.log(error);
    }
  };

  const GetListSalonByTipo = async (
    event,
    i,
    element,
    isAll = false,
    getSalon = true
  ) => {
    if (isAll) {
      setTipoSalonSelectValue((prev) => prev.map(() => event));
    } else {
      setTipoSalonSelectValue((prev) =>
        prev.map((tipoSalon, index) => {
          if (index === i) {
            return event;
          }
          return tipoSalon;
        })
      );
      if (getSalon) {
        try {
          const consulta = `/buscar?tipo=salon&valor=id:${event.value},id_campus:${campusId}`;
          const response = await getRequest(consulta);
          if (response.s === "OK") {
            if (
              response.d === null ||
              response.d === "" ||
              response.d.length === 0
            ) {
              enqueueSnackbar("No se ha encontrado ningún salón", {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              return;
            }
            const busyClassroomsInitial = [];
            const stringDay = element[0][0].toUpperCase();
            busyClassrooms.forEach((responseItem) => {
              if (
                checkIfJExists(
                  `${element[2]}`,
                  `${element[1]}`,
                  responseItem.timeslots,
                  element[0].toLowerCase() === "miercoles" ? "Mi" : stringDay
                )
              ) {
                busyClassroomsInitial.push(responseItem.id_classroom);
              }
            });
            const dataObjectInicio = Object.values(response.d)
              .map((e, i) => {
                if (!busyClassroomsInitial.includes(`${e.id_salon}`)) {
                  const data_ = {
                    label: `${e.id_salon} - ${e.nombre}`,
                    value: e.id_salon,
                    name: e.nombre,
                    capacidad: e.capacidad,
                  };
                  return data_;
                }
                return null;
              })
              .filter((item) => item !== null);
            if (dataObjectInicio.length === 0) {
              enqueueSnackbar(
                "Salones no disponibles en el horario seleccionado",
                {
                  variant: "error",
                  autoHideDuration: null,
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }
              );
            } else {
              enqueueSnackbar(response.m, {
                variant: "success",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              });
              setListSalon((prev) =>
                prev.map((salon, index) => {
                  if (i === index) {
                    return dataObjectInicio;
                  }
                  return salon;
                })
              );
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleChangeSalon = async (e, i, el, isAll = false) => {
    let array = [...tipoSalonesPost];
    let array_table = [...tipoSalonesPost];
    const fecha_fin_array = moment(el[1], "HH:mm")
      .add(30, "minutes")
      .format("HH:mm");
    if (isAll) {
      setSalonSelectValue((prev) => prev.map(() => e));
      array = tipoSalonesPost.map((salon, index) => {
        return {
          old_id_area: dataCustom[index][10],
          id_schedule: salon.id_schedule,
          id_area: e.value,
          capacidad: e.capacidad,
          dia: salon.dia,
          hora_inicio: salon.hora_inicio,
          hora_fin: salon.hora_fin,
          id_assignment: materia.id,
          index: index,
          delete: e.delete ? true : false,
          default_salon: defaultSalonSelectValue.value,
        };
      });
      array_table = tipoSalonesPost.map((salon, index) => {
        return {
          old_id_area: dataCustom[index][10],
          id_schedule: salon.id_schedule,
          value: e.value,
          id_area: e.value,
          label: e.label,
          capacidad: e.capacidad,
          dia: salon.dia,
          hora_inicio: salon.hora_inicio,
          hora_fin: salon.hora_fin,
          id_assignment: materia.id,
          index: index,
          delete: e.delete ? true : false,
          default_salon: defaultSalonSelectValue.value,
        };
      });
    } else {
      array[i] = {
        old_id_area: dataCustom[i][10],
        id_schedule: dataCustom[i][7],
        id_area: e.value,
        capacidad: e.capacidad,
        dia: el[0],
        hora_inicio: el[1],
        hora_fin: fecha_fin_array,
        id_assignment: materia.id,
        index: i,
        delete: e.delete ? true : false,
        default_salon: defaultSalonSelectValue.value,
      };
      array_table[i] = {
        old_id_area: dataCustom[i][10],
        id_schedule: dataCustom[i][7],
        value: e.value,
        id_area: e.value,
        label: e.label,
        capacidad: e.capacidad,
        dia: el[0],
        hora_inicio: el[1],
        hora_fin: fecha_fin_array,
        id_assignment: materia.id,
        delete: e.delete ? true : false,
        default_salon: defaultSalonSelectValue.value,
      };
      setSalonSelectValue((prev) =>
        prev.map((salon, index) => {
          if (index === i) {
            return e;
          }
          return salon;
        })
      );
    }
    setTipoSalonesPost(array);
    window.localStorage.removeItem("asignacion");
    window.localStorage.removeItem("dataTable");
    window.localStorage.removeItem("tipo_modal");
    window.localStorage.setItem("tipo_modal", "individual");
    window.localStorage.setItem("asignacion", JSON.stringify(array));
    window.localStorage.setItem("dataTable", JSON.stringify(dataCustom));
  };
  const waitTime = 2000;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const handleGrupoValue = (e) => {
    setGrupoValue(e.target.value);
    setMateria("");
    setEscuelaValue("");
    setDireccion("");
    setProfesor("");
    setDataHorarioFinal([]);
    setHorasSemanales("");
    setHorasLaboratorio("");
    setAsignados("");
    setCreado("");
    setCreadoPor("");
    setModificado("");
    setModificadoPor("");
    setTipoSalonesPost([]);
  };

  const handleMateria = async (e) => {
    setDataCustom([]);
    setMateria(e);
    setEscuelaValue(e.escuela);
    setDireccion(e.direccion);
    setProfesor(e.nombre_profesor);
    const datos_salones_reservados = [];
    const capacidadesVacias = [];
    const dayVariables = {};
    e.horario.forEach((item) => {
      const { dia, hora_inicio, hora_fin } = item;
      const combinedHours = [hora_inicio, hora_fin];
      if (!dayVariables[dia]) {
        dayVariables[dia] = combinedHours;
      } else {
        dayVariables[dia][0] =
          dayVariables[dia][0] > hora_inicio
            ? hora_inicio
            : dayVariables[dia][0];
        dayVariables[dia][1] =
          dayVariables[dia][1] < hora_fin ? hora_fin : dayVariables[dia][1];
      }
      datos_salones_reservados.push([
        item.dia,
        item.hora_inicio,
        item.hora_fin,
        item.tipo_salon === "default" ? "" : item.tipo_salon,
        item.salon,
        item.capacidad,
        item.id_schedule,
        e.start_date,
        e.end_date,
        item.salon_id,
      ]);
      capacidadesVacias.push([""]);
    });
    const scheduleData = [
      "lunes" in dayVariables ? dayVariables["lunes"].join("-") : "-",
      "martes" in dayVariables ? dayVariables["martes"].join("-") : "-",
      "miercoles" in dayVariables ? dayVariables["miercoles"].join("-") : "-",
      "jueves" in dayVariables ? dayVariables["jueves"].join("-") : "-",
      "viernes" in dayVariables ? dayVariables["viernes"].join("-") : "-",
      "sabado" in dayVariables ? dayVariables["sabado"].join("-") : "-",
      "domingo" in dayVariables ? dayVariables["domingo"].join("-") : "-",
    ];
    const days = [];
    const initialHour = [];
    const finalHour = [];
    scheduleData.forEach((item, index) => {
      if (item === "-") {
        return null;
      }
      const time = item.split("-");
      initialHour.push(time[0]);
      finalHour.push(time[1]);
      switch (index) {
        case 0:
          days.push("lunes");
          break;
        case 1:
          days.push("martes");
          break;
        case 2:
          days.push("miercoles");
          break;
        case 3:
          days.push("jueves");
          break;
        case 4:
          days.push("viernes");
          break;
        case 5:
          days.push("sabado");
          break;
        case 6:
          days.push("domingo");
          break;
        default:
          break;
      }
    });
    const horarios = `dias: ${days.join(",")} horas_inicio: ${initialHour.join(
      ","
    )} horas_fin: ${finalHour.join(",")}`;
    const fechas = []; //Array para almacenar las fechas
    datos_salones_reservados.forEach((e, i) => {
      const fecha_init = moment(e[1], "HH:mm");
      const fecha_fin = moment(e[2], "HH:mm");
      var finalHourMoment = moment(fecha_init);
      for (
        var hour = moment(fecha_init);
        hour.isBefore(fecha_fin);
        hour.add(30, "minutes")
      ) {
        finalHourMoment.add(30, "minutes");
        fechas.push([
          e[0],
          hour.format("HH:mm"),
          e[1],
          e[2],
          e[3],
          e[4],
          e[5],
          e[6],
          e[7],
          e[8],
          e[9],
          finalHourMoment.format("HH:mm"),
        ]);
      }
    });

    setTipoSalonSelectValue(new Array(fechas.length).fill(""));
    setSalonSelectValue(new Array(fechas.length).fill(""));
    setListSalon(new Array(fechas.length).fill(""));
    try {
      console.log(e);
      const request = `/reportes/salones_ocupados?fecha_inicial=${e.start_date}&fecha_final=${e.end_date}&id_campus=${campusId}&horario=${horarios}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        const array = fechas.map((item) => {
          return {
            capacidad: item[6],
            dia: item[0],
            hora_fin: item[11],
            hora_inicio: item[1],
            id_area: item[10],
            id_assignment: e.id,
            id_schedule: item[7],
            old_id_area: item[10],
            delete: false,
            default_salon: defaultSalonSelectValue.value,
          };
        });
        setDataCustom(fechas);
        setBusyClassrooms(response.d);
        setDataHorarioFinal(scheduleData);
        setHorasSemanales(e.horas_semanales);
        setHorasLaboratorio(e.horas_laboratorio);
        setAsignados(e.asignados);
        setCreado(e.salon_creado);
        setCreadoPor(e.salon_creado_por);
        setModificado(e.salon_modificado);
        setModificadoPor(e.salon_modificado_por);
        setTipoSalonesPost(array);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Ocurrio un error", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    GetTipoSalon(campusId, enqueueSnackbar);
    getDefultSalon(campusId, enqueueSnackbar);
  }, [campusId, enqueueSnackbar]);

  useEffect(() => {
    clearTimeout(grupoTimer);
    setDataCustom([]);
    setTipoSalonesPost([]);
    if (grupoValue.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(campusId, grupoValue, valueCiclo, enqueueSnackbar);
      }, waitTime);
    }
  }, [grupoValue, campusId, valueCiclo, enqueueSnackbar]);

  const handleOpenConfirmationModalAll = () => {
    GetListSalonByTipo(
      defaultTipoSalonSelectValue,
      0,
      dataCustom[0],
      true,
      false
    );
    handleChangeSalon(defaultSalonSelectValue, 0, dataCustom[0], true);
  };

  const handleOpenConfirmationModalIndividual = (index, row) => {
    if (tipoSalonesPost[index]?.delete === true) {
      const reverseSalon = {
        label: `${row[10]} - ${row[5]}`,
        value: row[10],
        name: row[5],
        capacidad: row[6],
        delete: false,
      };
      const reverseSalonTipo = {
        label: `${row[4]}`,
        value: null,
      };
      GetListSalonByTipo(reverseSalonTipo, index, row, false, false);
      handleChangeSalon(reverseSalon, index, row);
    } else {
      GetListSalonByTipo(defaultTipoSalonSelectValue, index, row, false, false);
      handleChangeSalon(defaultSalonSelectValue, index, row);
    }
  };

  return (
    <Grid>
      <Grid sx={12} style={{ marginLeft: "-1.5%" }}>
        <Grid
          container
          spacing={2}
          style={{ padding: "10px 0px", alignItems: "center" }}
        >
          <Grid xs={1} item container justifyContent="flex-end">
            <label>Campus:</label>
          </Grid>
          <Grid xs={2} item>
            <input className="filter-input" value={campusUser} disabled></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            justifyContent="flex-end"
            style={{ marginLeft: "-3.8%" }}
          >
            <label>Ciclo:</label>
          </Grid>
          <Grid xs={1} item>
            <input
              className="filter-input"
              onChange={(e) => setValueCiclo(e.target.value)}
              value={valueCiclo}
            ></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            style={{
              justifyContent: "flex-end",
              marginLeft: "-3%",
              marginRight: "-0.7%",
            }}
          >
            <label>Grupo:</label>
          </Grid>
          <Grid xs={1} item container style={{ marginLeft: "10px" }}>
            <input
              className="filter-input"
              onChange={handleGrupoValue}
              value={grupoValue}
            ></input>
          </Grid>
          <Grid
            xs={1}
            item
            style={{ justifyContent: "flex-end", marginLeft: "-1.2%" }}
            container
          >
            <label>Materia:</label>
          </Grid>
          <Grid xs={4} item container style={{ zIndex: 99 }}>
            <Select
              className="select-modal-container input-tipo"
              classNamePrefix="select-modal"
              options={materiaOptions}
              styles={asyncSelectStyles}
              isLoading={selectMateriaIsLoading}
              onChange={(e) => handleMateria(e)}
              value={materia}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{ padding: "10px 0px", alignItems: "center" }}
        >
          <Grid xs={1} item container justifyContent="flex-end">
            <label>Escuela:</label>
          </Grid>
          <Grid xs={2} item>
            <input
              className="filter-input"
              value={escuelaValue}
              disabled
            ></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            justifyContent="flex-end"
            style={{ marginLeft: "-1.5%" }}
          >
            <label>Dirección:</label>
          </Grid>
          <Grid xs={2} item>
            <input className="filter-input" value={direccion} disabled></input>
          </Grid>
          <Grid
            xs={2}
            item
            container
            justifyContent="flex-end"
            style={{ marginLeft: "-6.5%" }}
          >
            <label>Clave Profesor:</label>
          </Grid>
          <Grid xs={4} item>
            <input className="filter-input" value={profesor} disabled></input>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ padding: "0 150px" }}>
        <table className="table_dias">
          <tr>
            <th>Lunes</th>
            <th>Martes</th>
            <th>Miércoles</th>
            <th>Jueves</th>
            <th>Viernes</th>
            <th>Sábado</th>
            <th>Domingo</th>
          </tr>
          {dataHorarioFinal.length >= 1 ? (
            <tr>
              {dataHorarioFinal.map((e) => (
                <td>{e ? e : "-"}</td>
              ))}
            </tr>
          ) : (
            <tr style={{ textAlign: "center" }}>No hay datos que msotrar</tr>
          )}
        </table>
        <Grid sx={12} container justifyContent="center">
          <Grid container spacing={2} style={{ padding: "10px 0px" }}>
            <Grid
              xs={3}
              item
              style={{ justifyContent: "flex-end", marginLeft: "-11%" }}
              container
            >
              <label>Horas Semanales:</label>
            </Grid>
            <Grid xs={1} item style={{ padding: 2 }}>
              <input
                className="filter-input"
                disabled
                value={horasSemanales}
              ></input>
            </Grid>
            <Grid xs={1} item />
            <Grid xs={3} item style={{ justifyContent: "flex-end" }} container>
              <label>Horas Laboratorio:</label>
            </Grid>
            <Grid xs={1} item style={{ padding: 2 }}>
              <input
                className="filter-input"
                disabled
                value={horasLaboratorio}
                style={{ width: "100%", marginLeft: "10px" }}
              ></input>
            </Grid>
            <Grid xs={3} item style={{ justifyContent: "flex-end" }} container>
              <label>Asignados:</label>
            </Grid>
            <Grid xs={1} item style={{ padding: 2 }}>
              <input
                className="filter-input"
                disabled
                value={asignados}
                style={{ width: "100%", marginLeft: "10px" }}
              ></input>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <hr class="rounded"></hr>

      <Typography>Salones Reservados</Typography>
      <table className="table_dias table_background">
        <tr>
          <th>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  background: dataCustom.length === 0 ? "gray" : "#ed8501",
                  display: "flex",
                  alignItems: "center",
                  padding: 2,
                  borderRadius: 5,
                  cursor: "pointer",
                }}
                onClick={() => handleOpenConfirmationModalAll()}
              >
                <Delete style={{ width: 20, height: 20, color: "white" }} />
              </div>
            </div>
          </th>
          <th>Dia</th>
          <th>Hora Inicio</th>
          <th>Salón Anterior</th>
          <th>Tipo Salón</th>
          <th>Salón</th>
          <th>Capacidad</th>
        </tr>
        {dataCustom.map((el, i) => {
          return (
            <tr style={{ height: "40px" }}>
              <td>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      background: salonSelectValue[i]?.delete ? "green" : "red",
                      display: "flex",
                      alignItems: "center",
                      padding: 2,
                      borderRadius: 5,
                      cursor: "pointer",
                      width: 20,
                    }}
                    onClick={() => {
                      handleOpenConfirmationModalIndividual(i, el);
                    }}
                  >
                    <Delete style={{ width: 20, height: 20, color: "white" }} />
                  </div>
                </div>
              </td>
              <td>{el[0]}</td>
              <td>{el[1]}</td>
              <td>{`${el[4]} - ${el[5]}`}</td>
              <td
                className="bg_select"
                style={{ zIndex: 90, position: "relative" }}
              >
                <Select
                  className="select-modal-container "
                  classNamePrefix="select-modal"
                  styles={selectUpsStyles}
                  options={tipoSalon}
                  onChange={(e) => GetListSalonByTipo(e, i, el)}
                  menuPlacement="top"
                  value={tipoSalonSelectValue[i]}
                />
              </td>
              <td
                className="bg_select"
                style={{ zIndex: 90, position: "relative" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={selectUpsStyles}
                  isDisabled={listSalon[i].length === 0}
                  options={listSalon[i]}
                  onChange={(e) => handleChangeSalon(e, i, el)}
                  menuPlacement="top"
                  value={salonSelectValue[i]}
                />
              </td>
              <td>{salonSelectValue[i]?.capacidad}</td>
            </tr>
          );
        })}
      </table>
      <Grid sx={12} style={{ marginTop: "1%", marginLeft: "-2.2%" }}>
        <Grid
          container
          spacing={2}
          style={{ padding: "10px 0px", alignItems: "center" }}
        >
          <Grid xs={1} item container justifyContent="flex-end">
            <label>Creado:</label>
          </Grid>
          <Grid xs={1} item style={{ padding: 2 }}>
            <input className="filter-input" value={creado} disabled></input>
          </Grid>
          <Grid xs={2} item style={{ padding: 2, marginLeft: "0.5%" }}>
            <input className="filter-input" value={creadoPor} disabled></input>
          </Grid>
          <Grid
            xs={1}
            item
            container
            justifyContent="flex-end"
            style={{ padding: 2, marginLeft: "5%" }}
          >
            <label>Modificado:</label>
          </Grid>
          <Grid xs={1} item style={{ padding: 2 }}>
            <input className="filter-input" value={modificado} disabled></input>
          </Grid>
          <Grid xs={2} item style={{ padding: 2, marginLeft: "0.5%" }}>
            <input
              className="filter-input"
              value={modificadoPor}
              disabled
            ></input>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AsignacionIndividual;
