import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewButton from "../../Utils/Botones/NewButton";
import Select from "react-select";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import NewModal from "../../Utils/Visuales/NewModal";
import CreateDivisionalModal from "../../Utils/CreateDivisionalModal";
import { getAddresses } from "../../Utils/Funciones/getAddresses";
import { getSchools } from "../../Utils/Funciones/getSchools";
import { searchTeacher } from "../../Utils/Funciones/searchTeacher";
import CustomTable from "../../Utils/CustomTable";
import CheckBoxStatusComponent from "../../Utils/CheckBoxStatusComponent";
import { sleep } from "../../Utils/Funciones/sleep";

const ConfiguracionDivisionales = () => {
  const asyncRowSelectStyles = {
    menu: (base) => ({
      ...base,
      position: "static",
    }),
  };

  const headers = [
    {
      accessorKey: "clave_direccion",
      header: "Clave Dirección",
      size: 20,
    },
    {
      accessorKey: "direccion",
      header: "Dirección",
      size: 200,
    },
    {
      accessorKey: "estatus",
      header: "Estatus",
      size: 200,
      Cell: ({ cell, row }) => (
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          styles={asyncRowSelectStyles}
          options={estatusOptions}
          isDisabled={editarDisabled}
          defaultValue={
            cell.getValue() === "Vigente"
              ? { value: 1, label: "Vigente" }
              : { value: 0, label: "No Vigente" }
          }
          onChange={(event) => handleEstatus(event, row.original)}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        />
      ),
    },
    {
      accessorKey: "modificado",
      header: "Modificado",
      width: 120,
    },
    {
      accessorKey: "modificado_por",
      header: "Modificado por",
    },
    {
      accessorKey: "creado",
      header: "Creado",
    },
    {
      accessorKey: "creado_por",
      header: "Creado por",
    },
  ];

  const [isSetearData, setIsSetearData] = useState(false);
  const [dataModifier, setDataModifier] = useState([]);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [optionsCampus] = useState(campusUser);
  const [campus, setCampus] = useState("");
  const [divisionalOptions, setDivisionalOptions] = useState([]);
  const [divisional, setDivisional] = useState({ label: "", value: "" });
  const [editarDisabled, setEditarDisabled] = useState(true);
  const [editarButtonDisabled, setEditarButtonDisabled] = useState(false);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
  const [createDireccion, setCreateDireccion] = useState("");
  const [createSchool, setCreateSchool] = useState("");
  const [createTeacher, setCreateTeacher] = useState([]);
  const [addressOptions, setAddressOptions] = useState("");
  const [schoolsOptions, setSchoolsOptions] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const estatusOptions = [
    {
      value: 1,
      label: "Vigente",
    },
    {
      value: 0,
      label: "No Vigente",
    },
  ];

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchTeacher(inputValue + ":divisional"));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const handleEstatus = async (ev, row) => {
    let newDataModifier = dataModifier.filter(
      (element) => element.id !== row.id
    );
    newDataModifier = [
      ...newDataModifier,
      {
        ...row,
        ...dataModifier.filter((element) => element.id === row.id)[0],
        estatus: ev.label === "No Vigente" ? 0 : 1,
      },
    ];
    newDataModifier = newDataModifier.map((element) => {
      return {
        ...element,
        estatus:
          element.estatus === "No Vigente" || element.estatus === 0 ? 0 : 1,
      };
    });
    setDataModifier(newDataModifier);
  };

  async function handleClickEditar() {
    let valido = true;
    if (data.length === 0) {
      enqueueSnackbar("No hay datos en la tabla para editar", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        setEditarDisabled(false);
        setEditarButtonDisabled(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  async function handleClickCreate() {
    setEditarDisabled(true);
    setEditarButtonDisabled(false);
    setCreateButtonDisabled(true);
    setModalLoading(true);
    getAddresses().then((data) => {
      setAddressOptions(data);
      setModalLoading(false);
    });
    getSchools().then((data) => {
      setSchoolsOptions(data);
      setModalLoading(false);
    });
  }

  const handleClickSave = async () => {
    const payload = {
      type: "divisionales",
      teachers: dataModifier,
    };
    try {
      setLoading(true);
      const response = await postRequest(`/catalogo`, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        consultarDivisionales(divisional.value, campus.value);
        setEditarDisabled(true);
        setEditarButtonDisabled(false);
        setCreateButtonDisabled(false);
        setLoading(false);
        setDataModifier([]);
      } else {
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  async function handleClickBuscar() {
    setLoading(true);
    setDataModifier([]);
    try {
      setEditarDisabled(true);
      setEditarButtonDisabled(false);
      setCreateButtonDisabled(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleDivisional(e) {
    setDataModifier([]);
    setDivisional(e);
    setCreateTeacher(e);
    setIsSetearData(false);
    setLoading(true);
  }

  async function handleCampus(e) {
    setDataModifier([]);
    setIsSetearData(true);
    setCampus(e);
    setDivisional("");
    setDivisionalOptions([]);
    setData([]);
  }

  const consultarDivisionales = async (idDivisional, idCampus) => {
    try {
      if (
        typeof idCampus === "string" &&
        idCampus !== "" &&
        typeof idDivisional === "number" &&
        idDivisional !== ""
      ) {
        setLoading(true);
        const consulta = `/buscar?tipo=divisionales&valor=id_campus:${idCampus},id_persona:${idDivisional}`;
        const response = await getRequest(consulta);
        if (response.s === "OK") {
          if (isSetearData) {
            setData([]);
          } else {
            setData([]);
            setData(response.d);
            setDataOriginal(response.d);
          }
          setLoading(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData([]);
          setLoading(false);
        }
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function searchDivisional() {
    try {
      setLoading(true);
      const request = `/buscar?tipo=divisionales&valor=id_campus:${campus.value}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        let arrayResponse = response.d.map((e) => {
          const data2 = {
            label: e.nombre,
            value: e.id,
          };
          return data2;
        });
        setLoading(false);
        setDivisionalOptions(arrayResponse);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const handleCloseModal = () => {
    setCreateDireccion("");
    setCreateSchool("");
    setCreateTeacher(divisional);
    setEditarButtonDisabled(false);
    setCreateButtonDisabled(false);
    consultarDivisionales(divisional.value, campus.value);
  };

  const handleSubmitCreate = async () => {
    if (
      createDireccion?.value === undefined ||
      createSchool?.value === undefined ||
      createTeacher?.value === undefined
    ) {
      enqueueSnackbar(
        "Debes seleccionar un Divisonal, una Dirección y una Escuela",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    } else {
      const payload = {
        type: "divisionales_create",
        id_campus: parseInt(campus.value),
        id_person: parseInt(createTeacher.value),
        id_cat_direcciones: parseInt(createDireccion.value),
        id_escuela: parseInt(createSchool.value),
        estatus: 1,
      };
      try {
        setModalLoading(true);
        const response = await postRequest(`/catalogo`, payload);
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          const newDivisionalValue = createTeacher.value;
          setDivisional(createTeacher);
          setCreateDireccion("");
          setCreateSchool("");
          setCreateTeacher(divisional);
          searchDivisional();
          setEditarDisabled(true);
          setEditarButtonDisabled(false);
          setCreateButtonDisabled(false);
          setModalLoading(false);
          setDataModifier([]);
          consultarDivisionales(newDivisionalValue, campus.value);
        } else {
          setModalLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
        setModalLoading(false);
      }
    }
  };

  const handleChangeStatus = async (value, label) => {
    setLoading(true);
    if (label === "Vigente" && value) {
      setData(dataOriginal.filter((element) => element?.estatus === "Vigente"));
      await sleep(500);
      setLoading(false);
    } else if (label === "No Vigente" && value) {
      setData(
        dataOriginal.filter((element) => element?.estatus === "No Vigente")
      );
      await sleep(500);
      setLoading(false);
    } else {
      setData(dataOriginal);
      await sleep(500);
      setLoading(false);
    }
  };

  const handleClickCancelar = async () => {
    setEditarDisabled(true);
    setEditarButtonDisabled(false);
  };

  const handleCreateTeacher = (event) => {
    if (event?.valido === "ERROR") {
      enqueueSnackbar("Profesor seleccionado no es de tipo persona válido", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setCreateTeacher(event);
    }
  };

  useEffect(() => {}, [data]);

  useEffect(() => {
    if (campus.value !== undefined && (divisional.value === "" || divisional.value === undefined)) {
      setDivisional("");
      searchDivisional();
    }
    if (isSetearData) {
      setData([]);
    }
    if (campus) {
      handleClickBuscar();
      consultarDivisionales(divisional.value, campus.value);
    }
  }, [divisional, campus]);

  useEffect(() => {
    setCampus(
      optionsCampus?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  return (
    <>
      <Contenedor title="Configuración / Divisionales">
        <div className="inputs-asignaciones">
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              position: "relative",
              zIndex: 9,
              marginBottom: 20,
            }}
          >
            <Grid container item xs={12} sm={9}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                  <label>Campus(*):</label>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginRight: "4%" }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={optionsCampus}
                    onChange={(e) => handleCampus(e)}
                    value={campus}
                  />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <label>Divisional(*):</label>
                </Grid>
                <Grid item xs={12} sm={4} style={{ marginRight: "1%" }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={divisionalOptions}
                    isDisabled={campus.value ? false : true}
                    onChange={(e) => handleDivisional(e)}
                    value={divisional}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <NewButton
                  customText="Editar"
                  noIcon
                  style={{ marginRight: 30, padding: "5px 15px" }}
                  disabled={editarButtonDisabled}
                  onClick={handleClickEditar}
                />
                <NewButton
                  customText="Crear"
                  noIcon
                  style={{ marginRight: 30, padding: "5px 15px" }}
                  disabled={createButtonDisabled || campus.value === undefined}
                  onClick={handleClickCreate}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <CustomTable
          data={loading ? [] : data}
          headers={headers}
          loading={loading}
          isFooterSaveButtons={editarDisabled}
          handleClickSave={handleClickSave}
          handleClickCancelar={handleClickCancelar}
          isRenderTopToolbarCustomActions={true}
          topToolBardComponent={
            <CheckBoxStatusComponent handleChangeStatus={handleChangeStatus} />
          }
        />
        <NewModal
          title="Crear divisional"
          open={createButtonDisabled}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmitCreate}
          loading={modalLoading}
          minHeight={400}
          overflowY={"unSet"}
        >
          <CreateDivisionalModal
            campus={campus.label}
            setCreateDireccion={setCreateDireccion}
            createDireccion={createDireccion}
            addressOptions={addressOptions}
            schoolsOptions={schoolsOptions}
            setCreateSchool={setCreateSchool}
            promiseOptions={promiseOptions}
            handleCreateTeacher={handleCreateTeacher}
            createTeacher={createTeacher}
          ></CreateDivisionalModal>
        </NewModal>
      </Contenedor>
    </>
  );
};

export default ConfiguracionDivisionales;
