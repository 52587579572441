import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";
export async function searchTeacher(valor) {
    try {
        const options = [];

        const consulta = `/buscar?tipo=profesor&valor=${valor}`;
        const response = await getRequest(consulta);

        if (response.s === "OK") {
            response.d.forEach((item) => {
                options.push({
                    label: item.nss + " - " + item.nombre,
                    value: item.id,
                    valido: item.valido
                });
            });
            return options;
        } else {
            enqueueSnackbar(response.m, {
                variant: "error", autoHideDuration: null,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            });
        }
    } catch (error) {
        console.error(error);
        enqueueSnackbar(error, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
    }
}