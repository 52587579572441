import React from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";

export default function EditarUsuario({
  usuarioEditar,
  setUsuarioEditar,
  opcionesRol,
  rolesDefault,
  opcionesCampus,
  handleChangeOpcionCampus,
}) {
  
  return (
    <div className="nuevoUsuarioForm">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <label>Clave (*)</label>
          <input
            placeholder="Ingresa Clave"
            value={usuarioEditar.nss}
            onChange={(e) =>
              setUsuarioEditar({ ...usuarioEditar, nss: e.target.value })
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Nombre (*)</label>
          <input
            placeholder="Ingresa Nombre"
            value={usuarioEditar.nombre}
            onChange={(e) =>
              setUsuarioEditar({ ...usuarioEditar, nombre: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Apellido Paterno (*)</label>
          <input
            placeholder="Ingresa Apellido"
            value={usuarioEditar.apellidoP}
            onChange={(e) =>
              setUsuarioEditar({
                ...usuarioEditar,
                apellidoP: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Apellido Materno (*)</label>
          <input
            placeholder="Ingresa Apellido"
            value={usuarioEditar.apellidoM}
            onChange={(e) =>
              setUsuarioEditar({
                ...usuarioEditar,
                apellidoM: e.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <label>Correo electrónico</label>
          <input
            placeholder="Ingresa Correo"
            value={usuarioEditar.email}
            onChange={(e) =>
              setUsuarioEditar({ ...usuarioEditar, email: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <label>Campus (*)</label>
          <MultiSelectCampus
            options={opcionesCampus}
            onChange={handleChangeOpcionCampus}
            value={{
              value: `${
                opcionesCampus.filter((item) => item.isActive).length
              }`,
              label: `Total: ${
                opcionesCampus.filter((item) => item.isActive).length
              }`,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            position: "absolute",
            marginTop: 195,
            width: "96%",
          }}
        >
          <label>Rol (*)</label>
          <Select
            options={opcionesRol}
            defaultValue={rolesDefault}
            placeholder="Seleccione rol..."
            className="sselect-modal-container input-campus"
            classNamePrefix="select-modal"
            // defaultValue={opcionesConvertidas}
            onChange={(e) => {
              console.log(e);
              setUsuarioEditar({ ...usuarioEditar, rol: e.value });
              console.log(usuarioEditar);
            }}
            //isMulti
          />
        </Grid>
      </Grid>
    </div>
  );
}
