export function checkIfJExists(startKey, endKey, schedule, element) {
  let foundJ = false;
  // Iterate through the keys within the specified range
  for (const key in schedule) {
    if (key >= startKey && key <= endKey && schedule[key].includes(element)) {
      foundJ = true;
      break; // "J" found, no need to continue searching
    }
  }
  return foundJ;
}
