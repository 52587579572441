export const orderSchedules = (schedules, clave) => {
    const dataForTable = []
    for (let i in schedules) {
        if (schedules[i]?.clave_docente === clave && schedules[i]?.tipo_registro === "fecha determinada") {
            dataForTable.push([
                schedules[i]?.dia,
                `${schedules[i]?.inicial?.split(":")?.[0]}:${schedules[i]?.inicial?.split(":")?.[1]} - ${schedules[i]?.final?.split(":")?.[0]}:${schedules[i]?.final?.split(":")?.[1]}`,
                schedules[i]?.fecha_inicio
            ])
        }
    }
    return dataForTable.sort((a, b) => Date.parse(b[2]) - Date.parse(a[2])).map((element) => {
        const newElement = [element[0], element[1], `${element[2].split("-")[2]}-${element[2].split("-")[1]}-${element[2].split("-")[0]}`]
        return newElement
    })
}