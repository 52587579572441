import PropTypes from 'prop-types';
import PDFDefault from '../../PDFDefault';
import PreviousProductivityBonusPDF from '../../PreviousProductivityBonusPDF';
import CommentsPrefecturePDF from '../../CommentsPrefecturePDF';
import ExtraordinaryPaymentsPDF from '../../ExtraordinaryPaymentsPDF';
import PrefecturaPDF from '../../PrefecturaPDF/prefecturaPDF';
import DefinitivoBonoProfPDF from '../../DefinitivoBonoProductividad/definitivoBonoProd';
import TotalProfCamEscPDF from '../../TotalProfCamEscPDF/totalProfCamEscPDF';
import IndicadoresAcademicosPDF from '../../IndicadoresAcademicosPDF/indicadoresAcademicos';
import KardexAcademicoPDF from '../../KardexAcademicoPDF';
import SpecialClassPDF from '../../SpecialClassPDF';
import GlobalIndicatorsDirectorPDF from '../../GlobalIndicatorsDirectorPDF';
import DireccionesAcademicasPDF from '../../DireccionesAcademicasPDF';
import GradoSuperiorAcademicoPDF from '../../GradoSuperiorAcademicoPDF';

BodyPDF.propTypes = {
    matadata: PropTypes.array,
    data: PropTypes.array,
    title: PropTypes.string,
};

export function BodyPDF({
    metadata,
    data,
    title,
    campus,
    startDate,
    endDate,
    teacherKey = "",
    ciclo = "",
    escuela = "",
    direccion = "",
    prefectura = false,
    director,
    total_profesores_grupo_materia,
    tipoIncidencias = "",
    tipo_clase,
    fechaReporte,
    tipo_profesor,
    grupo,
    materia,
    totalBox,
    rowstableColSpan,
    hora,
    minutos,
    rows,
    tipo_reporte
}) {
    switch (title) {
        case "Reportes Total Horas Asignadas por Profesor, Campus y Escuela":
            return (
                <TotalProfCamEscPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    ciclo={ciclo}
                />
            )
        case "Reportes Definitivo de Bono de Productividad":
            return (
                <DefinitivoBonoProfPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    director={director}
                    total_grupo_materia={total_profesores_grupo_materia}
                />
            )
        case "Reporte Prefectura":
            return (
                <PrefecturaPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    tipo_clase={tipo_clase}
                    hora={hora}
                    minutos={minutos}
                />
            )
        case "Reportes Indicadores Académicos":
            return (
                <IndicadoresAcademicosPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    tipo_clase={tipo_clase}
                />
            )
        case "Reportes Previo Bono de Productividad":
            return (
                <PreviousProductivityBonusPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    teacherKey={teacherKey}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    prefectura={prefectura}
                    director={director}
                    total_profesores_grupo_materia={total_profesores_grupo_materia}
                />
            )
        case "Reporte Comentarios de Prefectura":
            return (
                <CommentsPrefecturePDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    teacherKey={teacherKey}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    prefectura={prefectura}
                    director={director}
                    tipoIncidencias={tipoIncidencias}
                />
            )
        case "Reportes Kardex Académico":
            return (
                <KardexAcademicoPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    fechaReporte={fechaReporte}
                    campus={campus}
                    teacherKey={teacherKey}
                    tipo_profesor={tipo_profesor}
                    direccion={direccion}
                    director={director}
                    grupo={grupo}
                    materia={materia}
                    totalBox={totalBox}
                />
            )
        case "Reportes Pagos Extraordinarios":
            return (
                <ExtraordinaryPaymentsPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    totalBox={totalBox}
                />
            )
        case "Reportes Clases Especiales":
            return (
                <SpecialClassPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    ciclo={ciclo}
                    startDate={startDate}
                    endDate={endDate}
                    escuela={escuela}
                    direccion={direccion}
                    teacherKey={teacherKey}
                    totalBox={totalBox}
                />
            )
        case "Reportes Indicadores Globales – Detalles por Profesor":
        case "Reportes Indicadores Globales por Director":
            return (
                <GlobalIndicatorsDirectorPDF
                    metadata={metadata}
                    data={data}
                    campus={campus}
                    rowstableColSpan={rowstableColSpan}
                />
            )

            case "Reportes Direcciones Académicas":
                return (
                    <DireccionesAcademicasPDF
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    teacherKey={teacherKey}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    prefectura={prefectura}
                    director={director}
                    total_profesores_grupo_materia={total_profesores_grupo_materia}
                    rows={rows}
                />
                )

                case "Reportes Grado Superior Académico":
                    return (
                        <GradoSuperiorAcademicoPDF
                        metadata={metadata}
                        data={data}
                        title={title}
                        campus={campus}
                        startDate={startDate}
                        endDate={endDate}
                        teacherKey={teacherKey}
                        ciclo={ciclo}
                        escuela={escuela}
                        direccion={direccion}
                        prefectura={prefectura}
                        director={director}
                        total_profesores_grupo_materia={total_profesores_grupo_materia}
                        rows={rows}
                        tipo_reporte={tipo_reporte}
                    />
                    )

        default:
            return (
                <PDFDefault
                    metadata={metadata}
                    data={data}
                    title={title}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    teacherKey={teacherKey}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    prefectura={prefectura}
                    director={director}
                    total_profesores_grupo_materia={total_profesores_grupo_materia}
                    rows={rows}
                />
            )
    }
}