import { View, Text } from '@react-pdf/renderer'
import ReportGlobalIndicatorsDirectorPDF from '../ReportGlobalIndicatorsDirectorPDF'

export default function GlobalIndicatorsDirectorPDF({
    metadata,
    data,
    campus,
    rowstableColSpan
}) {
    return (
        <View>
            <ReportGlobalIndicatorsDirectorPDF
                metadata={metadata}
                data={data}
                campus={campus}
                rowstableColSpan={rowstableColSpan}
            />
            <View
                fixed
                style={{
                    height: 50,
                    position: 'absolute',
                    bottom: 30,
                    left: 0,
                    right: 0,
                    top: 520
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <View>
                        <Text
                            style={{
                                color: 'gray',
                                fontSize: 11,
                            }}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}