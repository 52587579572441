import React from "react";
import { Grid } from "@material-ui/core";
import ModalInput from "../ModalInput";
import DateInput from "../DateInput";
import moment from "moment";

const ModalCreateQuincena = ({
  year,
  number,
  startDate,
  endDate,
  processDate,
  paymentDate,
  setYear,
  setNumber,
  setStartDate,
  setEndDate,
  setProcessDate,
  setPaymentDate,
}) => {
  return (
    <Grid style={{ padding: 20, paddingTop: 0 }}>
      <ModalInput
        lable={"Año:"}
        type={"number"}
        onChange={(event) => setYear(event)}
        inputValue={year}
        limit={4}
      />
      <ModalInput
        lable={"Número Quincena:"}
        type={"number"}
        onChange={(event) => setNumber(event)}
        inputValue={number}
      />
      <DateInput
        lable={"Fecha Inicio:"}
        onChange={(event) => setStartDate(event)}
        min={moment().add(1, "day").format("YYYY-MM-DD")}
        value={startDate}
      />
      <DateInput
        lable={"Fecha Fin:"}
        onChange={(event) => setEndDate(event)}
        min={startDate}
        value={endDate}
      />
      <DateInput
        lable={"Fecha Proceso:"}
        onChange={(event) => setProcessDate(event)}
        min={endDate}
        value={processDate}
      />
      <DateInput
        lable={"Fecha Pago:"}
        onChange={(event) => setPaymentDate(event)}
        min={processDate}
        value={paymentDate}
      />
    </Grid>
  );
};

export default ModalCreateQuincena;
