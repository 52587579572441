import { View, Text } from '@react-pdf/renderer'
import ReportPrefecturaPDF from './ReportPrefecturaPDF'

export default function PrefecturaPDF({
    metadata,
    data,
    title,
    campus,
    startDate,
    endDate,
    ciclo = "",
    escuela = "",
    direccion = "",
    tipo_clase = "",
    hora,
    minutos
}) {
    return (
        <View>
            <ReportPrefecturaPDF
                title={title}
                metadata={metadata}
                data={data}
                campus={campus}
                startDate={startDate}
                endDate={endDate}
                ciclo={ciclo}
                escuela={escuela}
                direccion={direccion}
                tipo_clase={tipo_clase}
                hora={hora}
                minutos={minutos}
            />
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 15, fontFamily: 'Bold' }}>
                <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Tipo de Clase: {tipo_clase}</Text>
            </View>

            <View style={{ flexDirection: 'row', width: '100%', marginTop: 20 }}>
                <View style={{ width: '10%', }}>
                    <Text style={{ fontSize: 10, color: '#000000', textAlign: 'right' }}>Elaboro:</Text>
                </View>

                <View style={{ width: '20%' }}>
                    <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                </View>

                <View style={{ width: '20%' }}>
                    <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                </View>

                <View style={{ width: '10%', }}>
                    <Text style={{ fontSize: 10, color: '#000000', textAlign: 'right' }}>Capturo:</Text>
                </View>

                <View style={{ width: '20%' }}>
                    <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                </View>

                <View style={{ width: '20%' }}>
                    <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                </View>
            </View>

            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                <View style={{ width: '12%', }} />

                <View style={{ width: '26%' }}>
                    <Text style={{ fontSize: 10, color: '#000000', }}>Nombre del Perfecto</Text>
                </View>

                <View style={{ width: '17%' }}>
                    <Text style={{ fontSize: 10, color: '#000000', }}>Firma</Text>
                </View>

                <View style={{ width: '10%', }} />

                <View style={{ width: '16%' }}>
                    <Text style={{ fontSize: 10, color: '#000000', textAlign: 'center' }}>Nombre</Text>
                </View>

                <View style={{ width: '24%' }}>
                    <Text style={{ fontSize: 10, color: '#000000', textAlign: 'center' }}>Firma</Text>
                </View>
            </View>
            <View
                fixed
                style={{
                    height: 50,
                    position: 'absolute',
                    bottom: 20,
                    left: 0,
                    right: 0,
                    top: 750
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <View>
                        <Text
                            style={{
                                color: 'gray',
                                fontSize: 11,
                            }}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}