import { View, Text } from '@react-pdf/renderer'
import ReporteGradoSuperiorAcademicoPDF from '../ReporteGradoSuperiorAcademicoPDF'
import ReportPDFDefault from '../ReportPDFDefault'
import GradoSuperiorAcademicoPDFTotales from './GradoSuperiorAcademicoPDFTotales'

export default function GradoSuperiorAcademicoPDF({
    metadata,
    data,
    title,
    campus,
    startDate,
    endDate,
    teacherKey = "",
    ciclo = "",
    escuela = "",
    direccion = "",
    prefectura = false,
    director,
    total_profesores_grupo_materia,
    rows,
    tipo_reporte
}) {
    if (tipo_reporte === 1) {
        const totales = [data[data.length - 1]]
        data.pop()
        return (
            <View>
                <ReporteGradoSuperiorAcademicoPDF
                    title={title}
                    metadata={metadata}
                    data={data}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    teacherKey={teacherKey}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    prefectura={prefectura}
                    director={director}
                    total_profesores_grupo_materia={total_profesores_grupo_materia}
                    rows={rows}
                />
                <GradoSuperiorAcademicoPDFTotales
                    title={title}
                    metadata={metadata}
                    data={totales}
                    ciclo={ciclo}
                    escuela={' '}
                    campus={campus}
                />
                <View
                    fixed
                    style={{
                        height: 50,
                        position: 'absolute',
                        bottom: 30,
                        left: 0,
                        right: 0,
                        top: 520
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <View>
                            <Text
                                style={{
                                    color: 'gray',
                                    fontSize: 11,
                                }}
                                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                            />
                        </View>
                    </View>
                </View>
            </View>
        )
    } else {
        return (
            <View>
                <ReportPDFDefault
                    title={title}
                    metadata={metadata}
                    data={data}
                    campus={campus}
                    startDate={startDate}
                    endDate={endDate}
                    teacherKey={teacherKey}
                    ciclo={ciclo}
                    escuela={escuela}
                    direccion={direccion}
                    prefectura={prefectura}
                    director={director}
                    total_profesores_grupo_materia={total_profesores_grupo_materia}
                    rows={rows}
                />
                <View
                    fixed
                    style={{
                        height: 50,
                        position: 'absolute',
                        bottom: 30,
                        left: 0,
                        right: 0,
                        top: 520
                    }}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <View>
                            <Text
                                style={{
                                    color: 'gray',
                                    fontSize: 11,
                                }}
                                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                            />
                        </View>
                    </View>
                </View>
            </View>
        )
    }

}