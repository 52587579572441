import { Grid } from "@material-ui/core";

export const BlueBar = ({ radius }) => {
  return (
    <Grid
      container
      style={{
        height: 15,
        borderRadius: radius,
        background: "#026eb7",
        marginTop: 40,
      }}
    />
  );
};
