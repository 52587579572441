import { Grid } from "@material-ui/core";
import Contenedor from "../../Utils/Visuales/Contenedor";
import CreateModalInputs from "../../Utils/CreateModalInputs";
import { useSelector } from "react-redux";
import { BlueBar } from "../../Utils/BlueBar";
import HoursInput from "../../Utils/HoursInput";
import { CommentInput } from "../../Utils/CommentInput";
import DateInput from "../../Utils/DateInput";
import NewButton from "../../Utils/Botones/NewButton";
import { useState } from "react";
import moment from "moment";
import ConfirmationModal from "../../Utils/ConfirmationModal";
import { useSnackbar } from "notistack";
import { runJenkinsJob } from "../../Utils/Funciones/runJenkinsJob";

export const GeneracionAsistenciaPersonalDocente = () => {
  const { enqueueSnackbar } = useSnackbar();
  const campusName = useSelector((state) => state.campusName);
  const campusId = useSelector((state) => state.campusId);
  const [loadingModal, setLoadingModal] = useState(false);
  const [formData, setFormData] = useState({
    schedule: "00:00",
    date: moment().format("YYYY-MM-DD"),
    comment: "",
  });
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = async () => {
    setLoadingModal(true);
    const payload = {
      type: "generar_franja_horaria",
      fecha_asistencia: formData.date,
      hora_inicio: formData.schedule,
      comentario: formData.comment,
      id_campus: campusId,
    };
    
    const response = await runJenkinsJob(payload, enqueueSnackbar, 10);
    if (response?.s === "OK") {
      enqueueSnackbar(response.m, {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setFormData({
        schedule: "00:00",
        date: moment().format("YYYY-MM-DD"),
        comment: "",
      });
      setOpenModal(false);
    } else if (response !== undefined) {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    setLoadingModal(false);
  };

  const handleOpenModal = () => {
    if (formData.comment === "" || formData.schedule === "00:00") {
      enqueueSnackbar("Todos los campos son obligatorios", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setOpenModal(true);
    }
  };
  return (
    <Contenedor title="Profesores / Generación Asistencia Personal Docente">
      <Grid container style={{ width: 400 }}>
        <CreateModalInputs
          label={"Campus"}
          disabled={true}
          inputValue={campusName}
        />
      </Grid>
      <BlueBar radius={"0px 5px 0px 0px"} />
      <Grid
        container
        style={{
          border: "1px solid #B7B7B7",
          height: "calc(100% - 150px)",
          borderRadius: "0px 0px 5px 0px",
          borderTop: 0,
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
        }}
      >
        <Grid container style={{ maxWidth: 800, display: "flex" }}>
          <Grid item style={{ marginRight: 20, width: 390 }}>
            <HoursInput
              label={"Hora Inicio(*)"}
              inputValue={formData.schedule}
              disabled={false}
              onChange={(hours, minutes) =>
                setFormData((prev) => ({
                  ...prev,
                  schedule: `${hours}:${minutes}`,
                }))
              }
            />
          </Grid>
          <Grid item style={{ width: 390 }}>
            <DateInput
              lable={"Fecha Asistencia(*):"}
              sm={5}
              value={formData.date}
              onChange={(e) => setFormData((prev) => ({ ...prev, date: e }))}
              max={moment().format("YYYY-MM-DD")}
            />
          </Grid>
        </Grid>
        <Grid container style={{ maxWidth: 800 }}>
          <CommentInput
            label={"Comentario(*)"}
            onChange={(e) => setFormData((prev) => ({ ...prev, comment: e }))}
            rows={10}
            value={formData.comment}
          />
        </Grid>
        <Grid
          container
          style={{
            maxWidth: 800,
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <NewButton
            noIcon
            customText="Generar"
            onClick={() => handleOpenModal()}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        height={100}
        setModal={setOpenModal}
        modal={openModal}
        message={
          "¿Desea ejecutar el proceso para generar la asistencia del personal docente?"
        }
        loading={loadingModal}
        onSubmit={() => {
          handleSubmit();
        }}
      />
    </Contenedor>
  );
};
