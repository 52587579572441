import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import TextField from "@mui/material/TextField";
import "./index.css";
import Select from "react-select";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
// import InputAdornment from '@mui/material/InputAdornment';
// import Button from '@mui/material/Button';
import moment from "moment";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { getWeekdayNamesInRange } from "../../Utils/Funciones/getWeekdayNamesInRange";
import { salonesGetDaysByLetter } from "../../Utils/Funciones/salonesGetDaysByLetter";

function ReporteSalonesDisponibles() {
  const { enqueueSnackbar } = useSnackbar();
  const heading = [
    "tipo",
    "salon",
    "cupo",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
  ];

  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [req, setReq] = useState(false);
  const [loading, setLoading] = useState(false);
  const IDcampus = useSelector((state) => state.campusId);
  const [fechaInicialValue, setFechaInicialValue] = useState("");
  const [fechaFinalValue, setFechaFinalValue] = useState("");
  const [horaValue, setHoraValue] = useState("");
  const [minutosValue, setMinutosValue] = useState("");
  const [listSalon, setListSalon] = useState([]);
  const [selectSalonIsLoading, setSelectSalonIsLoading] = useState(false);
  const [profesorValue, setProfesorValue] = useState({
    label: "",
    value: "",
  });
  const [selectTipoSalonIsLoading, setSelectTipoSalonIsLoading] =
    useState(false);
  const [tipoSalonOptions, setTipoSalonOptions] = useState("");
  const [tipoSalon, setTipoSalon] = useState("");
  const [salonValue, setSalonValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [valueCampus, setValueCampus] = useState({
    label: "",
    value: "",
  });
  const [maxFinalDate, setMaxFinalDate] = useState("");

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  const GetTipoSalon = async (e) => {
    setSelectTipoSalonIsLoading(true);
    try {
      const endpoint = `/catalogo?id=24`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["type"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setTipoSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
    setSelectTipoSalonIsLoading(false);
  };

  const headers = [
    {
      name: "Tipo",
      id: "tipo",
      col: 0,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Salón",
      id: "salon",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Cupo",
      id: "cupo",
      col: 2,
      width: "7%",
      align: "center",
      fn: null,
    },
    {
      name: "07:00",
      id: "7",
      col: 3,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "07:30",
      id: "7:30",
      col: 4,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "08:00",
      id: "8",
      col: 5,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "08:30",
      id: "8:30",
      col: 6,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "09:00",
      id: "9",
      col: 7,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "09:30",
      id: "9:30",
      col: 8,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "10:00",
      id: "10",
      col: 9,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "10:30",
      id: "10:30",
      col: 10,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "11:00",
      id: "11",
      col: 11,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "11:30",
      id: "11:30",
      col: 12,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "12:00",
      id: "12",
      col: 13,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "12:30",
      id: "12:30",
      col: 14,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "13:00",
      id: "13",
      col: 15,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "13:30",
      id: "13:30",
      col: 16,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "14:00",
      id: "14",
      col: 17,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "14:30",
      id: "14:30",
      col: 18,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "15:00",
      id: "15",
      col: 19,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "15:30",
      id: "15:30",
      col: 20,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "16:00",
      id: "16",
      col: 21,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "16:30",
      id: "16:30",
      col: 22,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "17:00",
      id: "17",
      col: 23,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "17:30",
      id: "17:30",
      col: 24,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "18:00",
      id: "18",
      col: 25,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "18:30",
      id: "18:30",
      col: 26,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "19:00",
      id: "19",
      col: 27,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "19:30",
      id: "19:30",
      col: 28,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "20:00",
      id: "20",
      col: 29,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "20:30",
      id: "20:30",
      col: 30,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "21:00",
      id: "21",
      col: 31,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "21:30",
      id: "21:30",
      col: 32,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "22:00",
      id: "22",
      col: 33,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "22:30",
      id: "22:30",
      col: 34,
      width: "10%",
      align: "center",
      fn: null,
    },
  ];

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  useEffect(() => {
    GetTipoSalon();
    setValueCampus(
      campusUser?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  async function handleClickGenerar() {
    if (fechaInicialValue === "") {
      enqueueSnackbar("Falta llenar campo Fecha Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaFinalValue === "") {
      enqueueSnackbar("Falta llenar campo Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valueCampus?.value === "" || valueCampus?.value === undefined) {
      enqueueSnackbar("Falta llenar campo Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaInicialValue > fechaFinalValue) {
      enqueueSnackbar("Fecha inicial debe ser menor que fecha final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      setLoading(true);
      setDisabled(true);
      const datosListado = await llamarListado();
      await llamarSalones(datosListado);
      setLoading(false);
      setDisabled(false);
    }
    setLoading(false);
  }

  async function llamarSalones(datos) {
    try {
      let url = `/reportes/salones_ocupados?fecha_inicial=${fechaInicialValue}&fecha_final=${fechaFinalValue}`;
      let urlApartados = `/salones/apartado?fi=${fechaInicialValue}&ff=${fechaFinalValue}&id_campus=${valueCampus.value}`;
      if (horaValue && minutosValue)
        url += `&hora_inicial=${horaValue}:${minutosValue}`;
      if (tipoSalon) url += `&tipo_salon=${tipoSalon.value}`;
      if (salonValue) url += `&salon=${salonValue.value}`;
      if (profesorValue?.value !== undefined)
        url += `&id_profesor=${profesorValue.value}`;
      if (valueCampus?.value !== undefined)
        url += `&id_campus=${valueCampus.value}`;
      const response = await getRequest(url);
      const responseApartados = await getRequest(urlApartados);
      if (response.s === "OK") {
        const result = salonesGetDaysByLetter(
          datos,
          response,
          responseApartados
        );
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setData(result);
        setDataOriginal(result);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function llamarListado() {
    try {
      const datosListado = [];
      let url = `/buscar?tipo=lista_salones&valor=id_campus:${valueCampus.value}`;
      if (salonValue?.value !== undefined)
        url += `,id_salon:${salonValue.value}`;
      if (tipoSalon?.value !== undefined)
        url += `,id_tipo_salon:${tipoSalon.value}`;
      const response2 = await getRequest(url);
      const arrayOfDays = new Array(33).fill(
        getWeekdayNamesInRange(fechaInicialValue, fechaFinalValue)
      );
      if (response2.s === "OK") {
        for (let i = 0; i < response2.d.length; i++) {
          if (response2.d[i]["active"] === 1) {
            datosListado.push([
              response2.d[i]["tipo_area"],
              response2.d[i]["nombre"],
              response2.d[i]["capacidad"],
              ...arrayOfDays,
              response2.d[i]["id_salon"],
              response2.d[i]["id_area_type"],
            ]);
          }
        }
        enqueueSnackbar(response2.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return datosListado;
      } else {
        enqueueSnackbar(response2.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getSalonOptions(id) {
    setSelectSalonIsLoading(true);
    try {
      const endpoint = `/buscar?tipo=salon&valor=id:${id},id_campus:${valueCampus.value}`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        response.d.forEach((item) => {
          data.push({
            label: item.id_salon + " - " + item.nombre,
            value: item.id_salon,
            capacidad: item.capacidad,
          });
        });
        setListSalon(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectSalonIsLoading(false);
    }
  }

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        tipo: e[0],
        salon: e[1],
        cupo: e[2],
        "07:00": e[3],
        "07:30": e[4],
        "08:00": e[5],
        "08:30": e[6],
        "09:00": e[7],
        "09:30": e[8],
        "10:00": e[9],
        "10:30": e[10],
        "11:00": e[11],
        "11:30": e[12],
        "12:00": e[13],
        "12:30": e[14],
        "13:00": e[15],
        "13:30": e[16],
        "14:00": e[17],
        "14:30": e[18],
        "15:00": e[19],
        "15:30": e[20],
        "16:00": e[21],
        "16:30": e[22],
        "17:00": e[23],
        "17:30": e[24],
        "18:00": e[25],
        "18:30": e[26],
        "19:00": e[27],
        "19:30": e[28],
        "20:00": e[29],
        "20:30": e[30],
        "21:00": e[31],
        "21:30": e[32],
        "22:00": e[33],
        "22:30": e[34],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Fecha Reporte: ${fecha} ${hora}`}
        campus={campusName}
        startDate={fechaInicialValue}
        endDate={fechaFinalValue}
        teacherKey={
          profesorValue.value === undefined ? "" : profesorValue.label
        }
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_salones_disponibles_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        tipo: e[0],
        salon: e[1],
        cupo: e[2],
        "07:00": e[3],
        "07:30": e[4],
        "08:00": e[5],
        "08:30": e[6],
        "09:00": e[7],
        "09:30": e[8],
        "10:00": e[9],
        "10:30": e[10],
        "11:00": e[11],
        "11:30": e[12],
        "12:00": e[13],
        "12:30": e[14],
        "13:00": e[15],
        "13:30": e[16],
        "14:00": e[17],
        "14:30": e[18],
        "15:00": e[19],
        "15:30": e[20],
        "16:00": e[21],
        "16:30": e[22],
        "17:00": e[23],
        "17:30": e[24],
        "18:00": e[25],
        "18:30": e[26],
        "19:00": e[27],
        "19:30": e[28],
        "20:00": e[29],
        "20:30": e[30],
        "21:00": e[31],
        "21:30": e[32],
        "22:00": e[33],
        "22:30": e[34],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_salones_disponibles_${fecha}_${hora}`, heading);
    setReq(false);
  };

  function handleChangeTipoSalon(ev) {
    setTipoSalon(ev);
    setSalonValue("");
    if (ev) {
      getSalonOptions(ev.value);
    }
  }

  function handleHoraValue(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      setHoraValue(value);
    }
  }

  function handleMinutosValue(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      setMinutosValue(value);
    }
  }

  const handleChangeInitialDate = (e) => {
    setFechaInicialValue(e.target.value);
    let futureDate = moment(e.target.value);
    let isSunday = true;
    while (isSunday) {
      if (moment(futureDate).add(1, "days").day() === 0) {
        isSunday = false;
      } else {
        futureDate.add(1, "days");
      }
    }
    setMaxFinalDate(futureDate.format("YYYY-MM-DD"));
  };

  const handleChangeFechaFinal = (e) => {
    if (
      moment(e.target.value).isBetween(
        fechaInicialValue,
        maxFinalDate,
        null,
        "[]"
      )
    ) {
      setFechaFinalValue(e.target.value);
    }
  };

  const handleChangeCampus = (e) => {
    setTipoSalon("");
    setSalonValue("");
    setValueCampus(e);
  };

  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Salones Disponibles">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className="nuevoUsuarioForm"
              style={{ marginLeft: "-1%" }}
            >
              <Grid item xs={1} sm={2} container style={{ textAlign: "end" }}>
                <label>Fecha Inicial(*):</label>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginLeft: "-4%" }}>
                <TextField
                  className="select-modal input-ciclo"
                  type="date"
                  onChange={(e) => handleChangeInitialDate(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={1} sm={2} container style={{ textAlign: "end" }}>
                <label>Fecha Final(*):</label>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginLeft: "-5%" }}>
                <TextField
                  className="select-modal input-ciclo"
                  type="date"
                  value={fechaFinalValue}
                  onChange={(e) => handleChangeFechaFinal(e)}
                  disabled={fechaInicialValue !== "" ? false : true}
                  InputProps={{
                    inputProps: {
                      min: fechaInicialValue,
                      max: maxFinalDate,
                      shrink: true,
                    },
                  }}
                  sx={{
                    "& .notchedOutlinet": {
                      border: "none !important",
                    },
                  }}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={1} sm={2} container style={{ textAlign: "end" }}>
                <label>Hora Inicial:</label>
              </Grid>
              <Grid
                item
                xs={1}
                sm={3}
                style={{ marginLeft: "-6%", display: "flex", zIndex: 99 }}
              >
                <input
                  className="filter-input"
                  type="number"
                  min="0"
                  max="23"
                  value={horaValue}
                  onChange={(e) => handleHoraValue(e.target.value)}
                />
                <input
                  className="filter-input"
                  style={{ marginLeft: "1%" }}
                  type="number"
                  min="0"
                  max="59"
                  value={minutosValue}
                  onChange={(e) => handleMinutosValue(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className="nuevoUsuarioForm"
            >
              <Grid
                item
                xs={1}
                sm={2}
                container
                style={{ textAlign: "end", marginRight: "-4.3%" }}
              >
                <label>Tipo de Salón:</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  options={tipoSalonOptions}
                  isLoading={selectTipoSalonIsLoading}
                  onChange={(e) => handleChangeTipoSalon(e)}
                  isClearable={true}
                  value={tipoSalon}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                container
                style={{ textAlign: "end", marginRight: "3.3%" }}
              >
                <label>Salón:</label>
              </Grid>
              <Grid item xs={1} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  // isDisabled={!listSalon.length > 0}
                  isLoading={selectSalonIsLoading}
                  options={listSalon}
                  onChange={(ev) => setSalonValue(ev)}
                  value={salonValue}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                container
                style={{ textAlign: "end", marginRight: "-5.9%" }}
              >
                <label>Clave Profesor:</label>
              </Grid>
              <Grid item xs={1} sm={3}>
                <AsyncSelect
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  onChange={setProfesorValue}
                  isClearable={true}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              className="nuevoUsuarioForm"
              style={{ marginLeft: "-1%" }}
            >
              <Grid item xs={1} sm={2} container style={{ textAlign: "end" }}>
                <label>Campus(*):</label>
              </Grid>
              <Grid item xs={1} sm={2} style={{ marginLeft: "-4%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusUser}
                  onChange={(e) => handleChangeCampus(e)}
                  isClearable={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} style={{ marginTop: "-1%" }}>
            <Grid container style={{ padding: "20px 10px" }}>
              <Grid justifyContent={"center"} container>
                <EditButton
                  onClick={handleClickGenerar}
                  style={{ padding: "10px 25px" }}
                  disabled={disabled || loading}
                >
                  Generar
                </EditButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadPDF}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 170px)", marginTop: "20px" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
}

export default ReporteSalonesDisponibles;
