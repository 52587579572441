import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import Contenedor from "../../Utils/Visuales/Contenedor";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { generateCSV } from "../../Utils/generateCSV/generateCSV";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { headers } from "../../Utils/data/reportesIndicadoresAcademicos";

const IndicadoresAcademicos = () => {
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [cicloValue, setCicloValue] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [valueCampus, setValueCampus] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueEscuela, setValueEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");

  

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };
  const handleGeneratedReport = async () => {
    const date = moment().format("DD/MM/YYYY");
    const dataTable = data.map((item) =>
      Object.fromEntries(item.map((_, i) => [headers[i].name, item[i]]))
    );
    await generateCSV({
      data: dataTable,
      fileName: `Reporte_Indicadores_Academicos_${date}.xlsx`,
    });
  };

  async function handleClick() {
    setLoading(true);
    let valido = true;
    if (cicloValue === "") {
      enqueueSnackbar("Debe Ingresar un Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela === "") {
      enqueueSnackbar("Debe Seleccionar una Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === "") {
      enqueueSnackbar("Debe Seleccionar un Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        const datos = [];
        let url = `/reportes/indicadores_academicos?ciclo=${cicloValue}&id_escuela=${valueEscuela.value}&id_campus=${valueCampus.value}`;
        url += `${
          valueDireccion?.value === undefined
            ? ""
            : "&id_direccion=" + valueDireccion.value
        }`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0][""].length;
          const salidasAnticipadas = response.d[10]["salidas_anticipadas"];
          const programadas = response.d[1]["programadas"];
          const impartidas = response.d[2]["impartidas"];
          const faltasBrutas = response.d[3]["faltas_brutas"];
          const faltasAutorizadas = response.d[4]["faltas_autorizadas"];
          const faltasNetas = response.d[5]["faltas_netas"];
          const reposiciones = response.d[6]["reposiciones"];
          const retardos = response.d[7]["retardos"];
          const canceladasAsistencia = response.d[8]["canceladas_asistencia"];
          const cancelacionClase = response.d[9]["cancelacion_clase"];
          const normales = response.d[11]["normales"];
          const adicionales = response.d[12]["adicionales"];
          const sustitucionales = response.d[13]["sustitucionales"];
          const visitasCampo = response.d[14]["visitas_campo"];

          for (let i = 0; i < nro; i++) {
            let numero = salidasAnticipadas[i];
            let redondeado = Math.round(numero * 100) / 100;
            datos.push([
              response.d[0][""][i],
              programadas[i],
              impartidas[i],
              faltasBrutas[i],
              faltasAutorizadas[i],
              faltasNetas[i],
              reposiciones[i],
              retardos[i],
              canceladasAsistencia[i],
              cancelacionClase[i],
              isNaN(redondeado) ? "-" : redondeado,
              normales[i],
              adicionales[i],
              sustitucionales[i],
              visitasCampo[i],
            ]);
          }
          setData(datos);
          setDataOriginal(datos);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
    setShowTable(true);
  }

  const DownloadPDF = async () => {
    setReq(true);
    const dataPDF = data.map((e) => {
      const dataPDF2 = {
        "": e[0],
        programadas: e[1],
        impartidas: e[2],
        faltas_brutas: e[3],
        faltas_autorizadas: e[4],
        faltas_netas: e[5],
        reposiciones: e[6],
        retardos: e[7],
        canceladas_asistencia: e[8],
        cancelacion_de_clase: e[9],
        salidas_anticipadas: e[10],
        normales: e[11],
        adicionales: e[12],
        sustitucionales: e[13],
        visitas_campo: e[14],
      };
      return dataPDF2;
    });
    const date = moment().format("DD/MM/YYYY");
    const hour = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={dataPDF ?? []}
        title={`Reportes Indicadores Académicos`}
        campus={valueCampus.label}
        ciclo={cicloValue}
        escuela={valueEscuela.label}
        direccion={valueDireccion.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Indicadores_Academicos_${date}_${hour}.pdf`);
    setReq(false);
  };
  function downloadCsv() {
    handleGeneratedReport().finally(() => {});
  }

  return (
    <Contenedor title="Reportes / Indicadores Académicos">
      <Grid container className="nuevoUsuarioForm" style={{ zIndex: 9 }}>
        {/* Grid para los inputs */}
        <Grid container item xs={12} sm={9}>
          {/* Primera Fila */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={14} sm={3}>
              <input
                className="filter-input input-ciclo"
                placeholder="Indique el ciclo..."
                value={cicloValue}
                onChange={(e) => setCicloValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Escuela(*):</label>
            </Grid>
            <Grid item xs={10} sm={3} style={{ zIndex: 8 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={valueEscuela}
                options={optionsEscuela}
                onChange={setValueEscuela}
              />
            </Grid>
            <Grid item xs={10} sm={1} style={{ textAlign: "end" }}>
              <label>Campus(*):</label>
            </Grid>
            <Grid item xs={10} sm={3} style={{ zIndex: 8 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={valueCampus}
                options={campusValores}
                onChange={setValueCampus}
              />
            </Grid>
          </Grid>
          <Grid item sm={1} container justifyContent="flex-end">
            <label>Dirección:</label>
          </Grid>
          <Grid sm={3} item style={{ zIndex: 8 }}>
            <Select
              className="select-modal-container"
              classNamePrefix="select-modal"
              isClearable={true}
              value={valueDireccion}
              options={optionsDireccion}
              onChange={setValueDireccion}
            />
          </Grid>
        </Grid>
        {/* Grid para los botones */}
        <Grid container item xs={12} sm={3} spacing={2}>
          <Grid
            container
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 16,
            }}
          >
            <EditButton onClick={handleClick} disabled={loading} style={{ padding: "10px 25px" }}>
              Generar
            </EditButton>
            <div style={{ display: "flex" }} className="export-buttons">
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button
                  onClick={downloadCsv}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={excelIcon} alt="excel-icon" />
                </button>
                <button
                  onClick={DownloadPDF}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={pdfFileIcon} alt="pdf-file-icon" />
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 180px)" }}>
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
};

export default IndicadoresAcademicos;
