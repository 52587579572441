import React from "react";
import { Grid } from "@material-ui/core";
import ModalInput from "../ModalInput";

const CreateInternalParametersModal = ({
    setName,
    setDay,
    setMonth,
    setPayble,
    setAssistanceType,
    setCurrent
}) => {

    return (
        <Grid style={{ padding: 20 }}>
            <ModalInput lable={'Nombre:'} onChange={(event) => { setName(event) }} />
            <ModalInput lable={'Dia:'} type={'number'} onChange={(event) => { setDay(event) }} limit={2}/>
            <ModalInput lable={'Mes:'} type={'number'} onChange={(event) => { setMonth(event) }} limit={2}/>
            <ModalInput lable={'Pagable:'} onChange={(event) => { setPayble(event.toUpperCase()) }} limit={1} />
            <ModalInput lable={'Tipo Asistencia:'} onChange={(event) => { setAssistanceType(event) }} />
            <ModalInput lable={'Vigente:'} onChange={(event) => { setCurrent(event.toUpperCase()) }} defaultValue="S" limit={1} />
        </Grid>
    )
}

export default CreateInternalParametersModal