
import React, { useMemo } from 'react'
import { View, Text } from '@react-pdf/renderer'
import ReportCommentsPrefecturePDFTable from '../ReportCommentsPrefecturePDFTable'
import { divideArrayIntoParts } from '../Funciones/divideArrayIntoParts'

export default function ReportCommentsPrefecturePDF({
    metadata,
    data,
    startDate,
    endDate,
    teacherKey = "",
    ciclo,
    escuela,
    campus,
    director,
    tipoIncidencias,
}) {
    const direccionName = useMemo(() => {
        const name = []
        data.forEach(element => {
            if (!name.includes(element.direccion)) {
                name.push(element.direccion)
            }
        });
        return name
    }, [data])

    return (
        <View style={{ marginTop: 15 }}>
            <View style={{ marginTop: 1 }}>
                {startDate ? (
                    <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                        <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Inicial: {startDate}</Text>
                        {endDate ? (
                            <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Final: {endDate}</Text>
                        ) : null}
                    </View>
                ) : null}
                {direccionName.map((item, index) => {
                    const dataForTable = data.filter((element) => element.direccion === item)
                    const splitDataIntoParts = divideArrayIntoParts(dataForTable)
                    return splitDataIntoParts.map((dataTable, index2) => {
                        return (
                            <ReportCommentsPrefecturePDFTable
                                key={`${index}-${index2}`}
                                metadata={metadata}
                                data={dataTable}
                                campus={campus}
                                teacherKey={teacherKey}
                                ciclo={ciclo}
                                escuela={escuela}
                                direccion={item}
                                director={director}
                                isNotfirstPage={index === 0 && index2 === 0 ? false : true}
                                tipoIncidencias={tipoIncidencias}
                            />
                        )
                    })
                })}
            </View>
        </View>
    )
}