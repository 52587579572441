export const checkValidationsReporteIndicadoresSemanales = (
  object,
  enqueueSnackbar
) => {
  let valido = true;
  if (!object.ciclo) {
    enqueueSnackbar("Falta Ciclo", {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    valido = false;
  } else if (!object.campus?.value) {
    enqueueSnackbar("Falta Campus", {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    valido = false;
  } else if (!object.escuela?.value) {
    enqueueSnackbar("Falta Escuela", {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    valido = false;
  } else if (!object.fechaInicial) {
    enqueueSnackbar("Falta Fecha Inicial", {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    valido = false;
  } else if (!object.fechaFinal) {
    enqueueSnackbar("Falta Fecha Final", {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
    valido = false;
  }
  return valido;
};
