import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const getPersonCatalog = async () => {
    try {
      const consulta = `/catalogo?id=26`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ id: [i], value: id[i], label: `${clave[i]} - ${nombre[i]}`, clave: clave[i], nombre: nombre[i] });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };