import React from "react";
import { Grid } from "@material-ui/core";
import NewButton from "../Botones/NewButton";

export default function MaximumHoursCampuses({
    permisos,
    handleClickCancelar,
    handleClickEditar,
    showButtons,
    disabledMaxHoras,
    maxHorasDirector,
    maxHorasDivisonal,
    setMaxHorasDirector,
    setMaxHorasDivisonal,
    dataMaxHoras,
    handleEditMaxHoras
}) {
    return (
        <div>
            <div >
                <Grid container alignItems="center" style={{ marginTop: "1%", marginLeft: "1%" }}>
                    <NewButton
                        customText="Editar"
                        noIcon
                        style={{ padding: "5px 15px", display: permisos['Configuración->Campus'].modificar === '1' ? 'block' : 'none' }}
                        onClick={handleClickEditar}
                    />
                </Grid>
                <Grid container alignItems="center" style={{ marginLeft: "8%", marginTop: "1%" }}>
                    <Grid item xs={12} sm={5}>
                        <label>Máximo de horas por semanas para todos los campus donde se encuentre el Profesor</label>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" style={{ marginLeft: "8%", marginTop: "1%" }}>
                    <Grid item xs={12} sm={1}>
                        <label>Director:</label>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <input
                            type="number"
                            min="0"
                            max="99"
                            className="filter-input"
                            style={{ width: "70%" }}
                            disabled={disabledMaxHoras}
                            value={maxHorasDirector}
                            onChange={(e) => setMaxHorasDirector(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <label>Divisional:</label>
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <input
                            type="number"
                            min="0"
                            max="99"
                            className="filter-input"
                            style={{ width: "70%" }}
                            disabled={disabledMaxHoras}
                            value={maxHorasDivisonal}
                            onChange={(e) => setMaxHorasDivisonal(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginLeft: "8%", marginTop: "1%" }}>
                    <Grid item xs={12} sm={1}>
                        <label>Creado:</label>
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "0.5%" }}>
                        <input
                            className="filter-input"
                            style={{ width: "120%" }}
                            disabled
                            value={dataMaxHoras.creado}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "3%" }}>
                        <input
                            className="filter-input"
                            style={{ width: "250%" }}
                            disabled
                            value={dataMaxHoras.creado_por}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center" style={{ marginLeft: "8%", marginTop: "1%" }}>
                    <Grid item xs={12} sm={1}>
                        <label>Modificado:</label>
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "0.5%" }}>
                        <input
                            className="filter-input"
                            style={{ width: "120%" }}
                            disabled
                            value={dataMaxHoras.modificado}
                        />
                    </Grid>
                    <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "3%" }}>
                        <input
                            className="filter-input"
                            style={{ width: "250%" }}
                            disabled
                            value={dataMaxHoras.modificado_por}
                        />
                    </Grid>
                </Grid>
                {showButtons ?
                    <Grid container alignItems="center" style={{ marginLeft: "20%", marginTop: "3%" }}>
                        <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "0.5%" }}>
                            <NewButton
                                customText="Cancelar"
                                noIcon
                                style={{ padding: "5px 15px", backgroundColor: "#aca7a1" }}
                                onClick={handleClickCancelar}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1} style={{ textAlign: "end", marginLeft: "3%" }}>
                            <NewButton
                                customText="Guardar"
                                noIcon
                                style={{ padding: "5px 15px" }}
                                onClick={handleEditMaxHoras}
                            />
                        </Grid>
                    </Grid>
                    : null}
            </div>
        </div>
    );
}