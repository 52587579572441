import { useState } from "react";
import { Grid } from "@material-ui/core";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckBoxStatusComponent = ({ handleChangeStatus }) => {
    const [active, setActive] = useState(false)
    const [noActive, setNoActive] = useState(false)

    const handleClickCheck = (value, label) => {
        if (label === 'Vigente') {
            setActive(value)
            setNoActive(false)
            handleChangeStatus(value, label)
        }
        else if (label === 'No Vigente') {
            handleChangeStatus(value, label)
            setNoActive(value)
            setActive(false)
        }

    }
    return (
        <Grid container style={{ display: "flex", flexDirection: "row", alignItems: "center", width: 'auto' }}>
            <Grid item style={{ marginRight: 20 }}>
                <label>Estatus:</label>
            </Grid>
            <Grid item style={{ width: 'auto' }}>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel control={<Checkbox size="small" onChange={(event) => { handleClickCheck(event.target.checked, 'Vigente') }} checked={active} />} label="Vigente" />
                    <FormControlLabel control={<Checkbox size="small" onChange={(event) => { handleClickCheck(event.target.checked, 'No Vigente') }} checked={noActive} />} label="No Vigente" />
                </FormGroup>
            </Grid>
        </Grid>
    )
}

export default CheckBoxStatusComponent