import React from "react";
import { Grid } from "@material-ui/core";
import CreateModalInputs from "../CreateModalInputs";
import ModalSelect from "../ModalSelect";
import CreateModalAsyncSelect from "../CreateModalAsyncSelect";

const CreateDivisionalModal = ({ campus, setCreateDireccion, addressOptions, schoolsOptions, setCreateSchool, promiseOptions, handleCreateTeacher, createTeacher = [] }) => {

    return (
        <Grid style={{ padding: 20 }}>
            <CreateModalInputs label={'Campus'} inputValue={campus} />
            <CreateModalAsyncSelect
                label={'Divisional'}
                onChange={handleCreateTeacher}
                promiseOptions={promiseOptions}
                value={createTeacher}
            />
            <ModalSelect
                label={'Escuela'}
                options={schoolsOptions}
                onChange={(event) => setCreateSchool(event)}
            />
            <ModalSelect
                label={'Dirección'}
                options={addressOptions}
                onChange={(event) => setCreateDireccion(event)}
            />
            <CreateModalInputs label={'Estatus'} inputValue={'Vigente'} />
        </Grid>
    )
}

export default CreateDivisionalModal