import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import moment from "moment";
import { ExportCSV } from "../../Utils/Excel";
import Contenedor from "../../Utils/Visuales/Contenedor";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import EditButton from "../../Utils/Botones/EditButton";
import "./styles.module.css";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { runJenkinsJob } from "../../Utils/Funciones/runJenkinsJob";
import {
  headersDesviacionAsig,
  headingDesviacionAsig,
} from "../../Utils/data/headersDesviacionAsig";

function DesviacionAsig() {
  const { enqueueSnackbar } = useSnackbar();
  const [valueCiclo, setValueCiclo] = useState("");
  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }

  async function handleClick() {
    setLoading(true);
    let valido = true;
    if (valueCiclo === "") {
      enqueueSnackbar("Falta llenar campo ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        const payload = {
          type: "generar_reporte_desviaciones",
          ciclo: valueCiclo,
        };
        const response = await runJenkinsJob(payload, enqueueSnackbar);
        if (response.s === "OK" && response?.data?.s === "OK") {
          const data = response?.data?.d;
          const newData = [];
          const nro = data["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            newData.push([
              data["ciclo"][i],
              data["campus"][i],
              data["escuela"][i],
              data["carrera_base"][i],
              data["nombre_carrera"][i],
              data["direccion"][i],
              data["nombre_director"][i],
              data["clave_profesor"][i],
              data["nombre_profesor"][i],
              data["grupo"][i],
              data["clave_materia"][i],
              data["materia_base"][i],
              data["nombre_materia"][i],
              data["evaluacion_anterior"][i],
              data["horas_clase"][i],
              data["total_horas"][i],
              data["maximo_horas"][i],
              data["modulo"][i],
              data["fecha_inicial"][i],
              data["fecha_final"][i],
              data["total_grupos"][i],
              data["maximo_grupos"][i],
              data["tipo"][i],
            ]);
          }
          setDataFinal(newData);
          setDataOriginal(newData);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    setLoading(false);
    setShowTable(true);
  }

  const DownloadExcel = () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        carrera_base: e[3],
        nombre_carrera: e[4],
        direccion: e[5],
        nombre_director: e[6],
        clave_profesor: e[7],
        nombre_profesor: e[8],
        grupo: e[9],
        clave_materia: e[10],
        materia_base: e[11],
        nombre_materia: e[12],
        evaluacion_anterior: e[13],
        total_horas: e[14],
        horas_clase: e[15],
        maximo_horas: e[16],
        modulo: e[17],
        fecha_inicial: e[18],
        fecha_final: e[19],
        total_grupos: e[20],
        maximo_grupos: e[21],
        tipo: e[22],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(
      data_,
      `Reporte_Desviacion_Asignaciones_${fecha}_${hora}`,
      headingDesviacionAsig
    );
    setReq(false);
  };

  return (
    <Contenedor title="Reportes / Desviación de Asignaciones">
      <div className="inputs-excepciones nuevoUsuarioForm">
        <Grid
          container
          spacing={1}
          alignItems="center"
          style={{ marginTop: "-1.5%" }}
        >
          <Grid item xs={1}>
            <label>Ciclo(*)</label>
          </Grid>
          <Grid item xs={2}>
            <input
              className="filter-input input-ciclo"
              placeholder="Indique el ciclo..."
              value={valueCiclo}
              onChange={(e) => setValueCiclo(e.target.value)}
            />
          </Grid>
          <Grid item xs={7} />
          <Grid
            item
            xs={2}
            style={{ padding: "20px 10px", alignItems: "center" }}
          >
            <EditButton
              onClick={handleClick}
              style={{ padding: "10px 25px" }}
              disabled={loading}
            >
              Generar
            </EditButton>
          </Grid>
          <Grid item xs={9} />
          <div
            style={{ display: "flex", marginLeft: "130px" }}
            className="export-buttons"
          >
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
              Descargar:
            </Typography>
            <div>
              <button
                onClick={DownloadExcel}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={excelIcon} alt="excel-icon" />
              </button>
              {/* <button
                onClick={DownloadPDF}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={pdfFileIcon} alt="pdf-file-icon" />
              </button> */}
            </div>
          </div>
        </Grid>
        {/* Adding Button format */}
      </div>
      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 190px)" }}>
        <DataTable
          headers={headersDesviacionAsig}
          data={dataFinal}
          loading={loading}
          centrar={[
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22,
          ]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
}

export default DesviacionAsig;
