import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";

import NewModal from "../../Utils/Visuales/NewModal";
import NewButton from "../../Utils/Botones/NewButton";
import SearchInput from "../../Utils/Visuales/SearchInput";
import Select from "react-select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import styles from "./styles.module.css";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import moment from "moment";

function ProyeccionesDeAsistencia() {
  const headers = [
    {
      name: "Folio",
      col: 0,
    },
    {
      name: "Campus",
      col: 1,
    },
    {
      name: "Escuela",
      col: 2,
    },
    {
      name: "Fecha Inicial",
      col: 3,
    },
    {
      name: "Fecha Final",
      col: 4,
    },
    {
      name: "Fecha Generación",
      col: 5,
    },
    {
      name: "Año",
      col: 6,
    },
    {
      name: "Quincena",
      col: 7,
    },
    {
      name: "Proyección",
      col: 8,
    },
    {
      name: "Estatus Proyección",
      col: 9,
    },
    {
      name: "Descripción",
      col: 10,
    },
    {
      name: "Estatus Folio",
      col: 11,
    },
    {
      name: "Acciones",
      col: 12,
    },
  ];

  const [listaYear] = useState([
    { label: "2023", value: "2023" },
    { label: "2024", value: "2024" },
    { label: "2025", value: "2025" },
    { label: "2026", value: "2026" },
    { label: "2027", value: "2027" },
    { label: "2028", value: "2028" },
  ]);

  const [dataFinal, setDataFinal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalNuevaProyeccion, setModalNuevaProyeccion] = useState(false);
  const [modalEditarProyeccion, setModalEditarProyeccion] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [escuela, setEscuela] = useState("");
  const [escuelaN, setEscuelaN] = useState("");
  const [escuelaOptions, setEscuelaOptions] = useState([]);
  const campusUser2 = useSelector((state) => state.campusUser.filter((item) => {
    if(item.label !== 'Todos') return item;
  }));
  const permisos = useSelector((state) => state.permisos);
  const [campusOptions2] = useState(campusUser2);
  const campusName = useSelector((state) => state.campusName);
  const [campus, setCampus] = useState("");
  const [campusN, setCampusN] = useState("");
  const [year, setYear] = useState("");
  const [input, setInput] = useState({
    fechaInicial: "",
    fechaFinal: "",
    fechaGeneracion: "",
    descripcion: "",
    fechaInicialN: "",
    fechaFinalN: "",
  });
  const [quincena, setQuincena] = useState("");
  const [quincenaOptions, setQuincenaOptions] = useState([]);
  const [selectQuincenaIsLoading, setSelectQuincenaIsLoading] = useState(false);
  const [proyeccion, setProyeccion] = useState("");
  const [proyeccionOptions, setProyeccionOptions] = useState([]);
  const [estatusProyeccion, setEstatusProyeccion] = useState("");
  const [estatusProyeccionOptions, setEstatusProyeccionOptions] = useState([]);
  const [btnDeshabilitar, setBtnDeshabilitar] = useState(true);
  const [editarBoton, setEditarBoton] = useState({
    folio: "",
    creado_por: "",
    fecha_creado: "",
    campus: "",
    escuela: "",
    fecha_inicial: "",
    fecha_final: "",
    fecha_generacion: "",
    year: "",
    quincena: "",
    proyeccion: "",
    estatus_proyeccion: "",
    descripcion: "",
    estatus_folio: "",
  });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  function nuevaProyeccion() {
    setLoading(true);
    setModalNuevaProyeccion(true);
    setLoading(false);
  }

  useEffect(() => {
    consultarCatalogo(9).then((data) => setEscuelaOptions(data));
    setProyeccionOptions([
      { value: 1, label: "Si" },
      { value: 2, label: "No" },
    ]);
    setEstatusProyeccionOptions([
      { value: 1, label: "Pagable" },
      { value: 2, label: "No Pagable" },
    ]);

    setCampus(
      campusOptions2?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({
            id: id[i],
            value: `${clave[i]} - ${nombre[i]}`,
            label: `${clave[i]} - ${nombre[i]}`,
          });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  function search(text) {
    text = text.toLowerCase();
    let result = dataFinal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }

  async function openEditar(folio) {
    setModalEditarProyeccion(true);
    setLoadingModal(true);
    permisos['Profesores->Proyecciones de Asistencias'].eliminar === '1' ? setBtnDeshabilitar(true) : setBtnDeshabilitar(false);
    try {
      const request = `/docentes/proyecciones-asistencias?id=${folio}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setEditarBoton({
          ...editarBoton,
          folio: folio,
          creado_por: response.d["creado_por"],
          fecha_creado: response.d["fecha_creado"],
          campus: response.d["campus"],
          escuela: response.d["id_escuela"],
          fecha_inicial: response.d["fecha_inicio"],
          fecha_final: response.d["fecha_fin"],
          fecha_generacion: response.d["fecha_generacion"],
          year: response.d["year"],
          quincena: response.d["quincena"],
          proyeccion: response.d["proyeccion"],
          estatus_proyeccion: response.d["estatus_proyeccion"],
          descripcion: response.d["descripcion"],
          estatus_folio: response.d["estatus_folio"],
        });
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setModalEditarProyeccion(false);
    } finally {
      setLoadingModal(false);
    }
  }

  const RealizarBusqueda = async (valor1, valor2, valor3, valor4) => {
    setLoading(true);
    if (valor1 === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Fecha Inicial", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valor2 === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Fecha Final", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valor3 === "") {
      setLoading(false);
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valor4 === "") {
      setLoading(false);
      enqueueSnackbar("Falta seleccionar Escuela", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valor1 > valor2) {
      setLoading(false);
      enqueueSnackbar("Fecha inicial debe ser menor que fecha final", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        //AQUI VA LA PETICION AL WS PARA BUSCAR EN LA BASE CON LOS FILTROS DE LO QUE ESTE EN CAMPUS, ESCUELA, PROFESOR Y LAS FECHAS
        const datos = [];
        const request = `/docentes/proyecciones-asistencias?fecha_inicio=${valor1}&fecha_fin=${valor2}&id_campus=${valor3.value}&id_escuela=${valor4.id}`;
        const response = await getRequest(request);
        if (response.s === "OK") {
          const nro = response.d["id"].length;
          // console.log(nro);
          // console.log(response.d);
          if (nro !== 0) {
            //console.log('diferente de cero')
            for (let i = 0; i < nro; i++) {
              datos.push([
                response.d["id"][i],
                response.d["campus"][i],
                response.d["escuela"][i],
                response.d["fecha_inicial"][i],
                response.d["fecha_final"][i],
                response.d["fecha_generacion"][i],
                response.d["year"][i],
                response.d["quincena"][i],
                response.d["proyeccion"][i],
                response.d["estatus_proyeccion"][i],
                response.d["descripcion"][i],
                response.d["estatus_folio"][i],
                <EditButton onClick={() => openEditar(response.d["id"][i])}>
                  Editar
                </EditButton>,
              ]);
            }

            setDataFinal(datos);
            setLoading(false);
            setDataFinal(datos);
            //console.log(dataFinal);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          } else {
            setDataFinal([]);
            setLoading(false);
            //console.log('diferente de cero')
            enqueueSnackbar(
              "No se encontraron Proyecciones que coincidan con la búsqueda",
              {
                variant: "info",
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }
            );
          }
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setDataFinal([]);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
        enqueueSnackbar(error, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    }
  };

  const GetListQuincena = async (e) => {
    setSelectQuincenaIsLoading(true);
    try {
      const year = e.value;
      const consulta = `/buscar?tipo=quincena&valor=${year}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        //console.log(response.d);
        const dataObjectInicio = Object.values(response.d).filter((element) => moment(element.fecha_fin).isSameOrAfter(moment())).map((e, i) => {
          const data_ = {
            label: `Q${e.numero} del ${e.fecha_inicio} al ${e.fecha_fin}`,
            value: e.id,
          };
          return data_;
        });
        setYear(e);
        setQuincenaOptions(dataObjectInicio);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      //console.log(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectQuincenaIsLoading(false);
    }
  };

  function handleChange(e) {
    e.preventDefault();
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  async function handleSubmitNuevaProyeccion() {
    var fIni = new Date(input.fechaInicialN);
    var fHoy = new Date(moment().format("YYYY-MM-DD"));
    var fAct = new Date(input.fechaGeneracion);
    
    if (
      campusN.value === "" ||
      escuelaN.id === "" ||
      input.fechaInicialN === "" ||
      input.fechaFinalN === "" ||
      //input.fechaGeneracion === "" ||
      year === "" ||
      quincena.value === "" ||
      quincena === "" ||
      proyeccion.value === "" ||
      estatusProyeccion.value === "" ||
      input.descripcion === ""
    ) {
      enqueueSnackbar("Todos los campos son obligatorios", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    
    if (input.fechaInicialN > input.fechaFinalN) {
      enqueueSnackbar("Fecha inicial debe ser menor que fecha final", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (input.fechaGeneracion < moment().add(0, "days").format("YYYY-MM-DD") || input.fechaGeneracion > input.fechaInicialN) {
      enqueueSnackbar("Fecha generación debe ser menor a la Fecha Inicial y Mayor o Igual al dia de hoy", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fAct >= fHoy && fAct <= fIni){
      setLoading(true);
      try {
        const payload = {
          id_campus: campusN.value,
          id_escuela: escuelaN.id,
          fecha_inicio: input.fechaInicialN,
          fecha_fin: input.fechaFinalN,
          fecha_generacion: input.fechaGeneracion,
          year: year.value,
          id_calendario: quincena.value,
          proyeccion: proyeccion.value,
          estatus_proyeccion: estatusProyeccion.value,
          descripcion: input.descripcion,
        };
        const response = await postRequest(
          "/docentes/proyecciones-asistencias/nuevo",
          payload
        );
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setModalNuevaProyeccion(false);
          setCampusN("");
          setEscuelaN("");
          setYear("");
          setQuincena("");
          setQuincenaOptions([]);
          setProyeccion("");
          setEstatusProyeccion("");
          setInput({
            ...input,
            fechaInicialN: "",
            fechaFinalN: "",
            fechaGeneracion: "",
            descripcion: "",
          });
          
          setLoading(false);
          
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
        enqueueSnackbar(error, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } 
    }
    else
    enqueueSnackbar("Fecha Generación no está dentro del rango de fechas...", {
      variant: "error", autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });

  }

  const handleDeshabilitar = async () => {
    setLoadingModal(true);
    try {
      const payload = {
        id: editarBoton.folio,
        estatus: "inactivo",
      };
      const response = await postRequest(
        "/docentes/proyecciones-asistencias/editar",
        payload
      );
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        RealizarBusqueda(
          input.fechaInicial,
          input.fechaFinal,
          campus,
          escuela
        )
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
    } finally {
      setLoadingModal(false);
      setModalEditarProyeccion(false);
    }
  };

  return (
    <Contenedor title="Profesores / Proyecciones de Asistencia">
      <div className="inputs-asignaciones">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid container alignItems="center" spacing={2}>
              <NewButton
                noIcon
                style={{
                  padding: "5px 15px",
                  marginTop: "1%",
                  marginLeft: "1%",
                  zIndex: 99,
                  display: permisos['Profesores->Proyecciones de Asistencias'].escribir === '1' ? 'block' : 'none'
                }}
                customText="Nuevo"
                onClick={nuevaProyeccion}
              />
              <Grid xs={1} md={2} style={{ marginLeft: permisos['Profesores->Proyecciones de Asistencias'].escribir === '1' ? '-2%' : '6%'  }}>
                <label>Fecha Inicial(*)</label>
              </Grid>
              <Grid xs={1} md={2} style={{ zIndex: "99" }}>
                <input
                  className="filter-input"
                  style={{ width: "150px" }}
                  value={input.fechaInicial}
                  name="fechaInicial"
                  type="date"
                  onChange={(e) => handleChange(e)}
                ></input>
              </Grid>
              <Grid xs={1} md={3} style={{ marginLeft: "-11%" }}>
                <label>Fecha Final(*)</label>
              </Grid>
              <Grid xs={1} md={2}>
                <input
                  className="filter-input"
                  style={{ width: "150px" }}
                  value={input.fechaFinal}
                  name="fechaFinal"
                  type="date"
                  disabled={input.fechaInicial !== "" ? false : true}
                  min={input.fechaInicial}
                  onChange={(e) => handleChange(e)}
                ></input>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              style={{ marginTop: "1%", marginLeft: "16%" }}
            >
              <Grid item xs={1} md={1} className="textEnd">
                <label>Campus(*)</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ marginRight: "10.2%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={campus}
                  options={campusOptions2}
                  onChange={setCampus}
                />
              </Grid>
              <Grid xs={1} md={1}>
                <label>Escuela(*)</label>
              </Grid>
              <Grid xs={12} sm={2} style={{ marginRight: "0%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={escuela}
                  options={escuelaOptions}
                  onChange={setEscuela}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={1}
            spacing={2}
            style={{ marginLeft: "3%" }}
          >
            <Grid
              container
              item
              style={{ display: "flex", flexDirection: "column" }}
            >
              <NewButton
                noIcon
                style={{ padding: "5px 15px", marginLeft: "190%" }}
                onClick={() =>
                  RealizarBusqueda(
                    input.fechaInicial,
                    input.fechaFinal,
                    campus,
                    escuela
                  )
                }
                customText="Buscar"
              />
              <div className={styles.opcionesBar}>
                <div
                  style={{ float: "right", width: "250px", marginTop: "10%" }}
                >
                  <SearchInput hideIcon disabled search={search} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "calc(100% - 120px)", marginTop: "0.5%" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Nueva Proyección"
        open={modalNuevaProyeccion}
        handleClose={() => {
          setModalNuevaProyeccion(false);
          setCampusN("");
          setEscuelaN("");
          setYear("");
          setQuincena("");
          setQuincenaOptions([]);
          setProyeccion("");
          setEstatusProyeccion("");
          setInput({
            ...input,
            fechaInicialN: "",
            fechaFinalN: "",
            fechaGeneracion: "",
            descripcion: "",
          });
        }}
        height={420}
        maxWidth={1100}
        loading={loading}
        guardar
        handleSubmit={handleSubmitNuevaProyeccion}
      >
        <div className="inputs-asignaciones">
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12}>
              <Grid container alignItems="center" style={{ marginTop: "1%" }}>
                <Grid item xs={1} md={1} style={{ marginLeft: "3.2%" }}>
                  <label>Campus(*)</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 99 }}>
                  <Select
                    className={styles.select}
                    classNamePrefix="select-modal"
                    value={campusN}
                    options={campusOptions2}
                    onChange={setCampusN}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginLeft: "10.3%", marginRight: "7.1%" }}
                >
                  <label>Escuela(*)</label>
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 99 }}>
                  <Select
                    className={styles.selectEscuela}
                    classNamePrefix="select-modal"
                    value={escuelaN}
                    options={escuelaOptions}
                    onChange={setEscuelaN}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "1%", marginLeft: "0%" }}
              >
                <Grid item xs={1} md={2} style={{ marginLeft: "-5.1%" }}>
                  <label>Fecha Inicial(*)</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{ zIndex: 98, marginLeft: "-0.1%" }}
                >
                  <input
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={input.fechaInicialN}
                    name="fechaInicialN"
                    type="date"
                    min={moment().format("YYYY-MM-DD")}
                    onChange={(e) => handleChange(e)}
                  ></input>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  style={{ marginLeft: "4.5%", marginRight: "4.7%" }}
                >
                  <label>Fecha Final(*)</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 98 }}>
                  <input
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={input.fechaFinalN}
                    name="fechaFinalN"
                    type="date"
                    disabled={input.fechaInicialN !== "" ? false : true}
                    min={input.fechaInicialN}
                    onChange={(e) => handleChange(e)}
                  ></input>
                </Grid>
                <Grid item xs={1} md={2} style={{ marginLeft: "7%" }}>
                  <label>Fecha Generación(*)</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 98 }}>
                  {/* <input
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={input.fechaGeneracion}
                    name="fechaGeneracion"
                    type="date"
                    min={moment().add(1, "days").format("YYYY-MM-DD")}
                    onChange={(e) => handleChange(e)}
                  ></input> */}
                  <Grid item xs={12} md={1} style={{ zIndex: 98 }}>
                    <input
                      className="filter-input"
                      style={{ width: "178px" }}
                      value={input.fechaGeneracion}
                      name="fechaGeneracion"
                      type="date"
                      defaultValue={''}
                      min={moment().add(0, "days").format("YYYY-MM-DD")}
                      max={input.fechaInicialN}
                      disabled={input.fechaFinalN !== "" ? false : true}
                      onChange={(e) => handleChange(e)}
                    ></input>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "1%", marginLeft: "3.5%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Año(*)</label>
                </Grid>
                <Grid item xs={12} md={2} style={{ zIndex: 97 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={listaYear}
                    value={year}
                    onChange={(e) => GetListQuincena(e)}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ marginLeft: "-12.3%", marginRight: "6%" }}
                >
                  <label>Quincena(*)</label>
                </Grid>
                <Grid item xs={7} md={6} style={{ zIndex: 97 }}>
                  <Select
                    className="select-modal-container input-tipo"
                    classNamePrefix="select-modal"
                    value={quincena}
                    options={quincenaOptions}
                    styles={asyncSelectStyles}
                    isLoading={selectQuincenaIsLoading}
                    onChange={setQuincena}
                    noOptionsMessage={() =>
                      "No se encontraron elementos para la quincena en el año seleccionado"
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "2%", marginLeft: "3.5%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Proyección(*)</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ zIndex: 96, marginRight: "1%" }}
                >
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={proyeccion}
                    options={proyeccionOptions}
                    onChange={setProyeccion}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  style={{ marginLeft: "1.1%", marginRight: "-0.1%" }}
                >
                  <label>Estatus Proyección(*)</label>
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 96 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={estatusProyeccion}
                    options={estatusProyeccionOptions}
                    onChange={setEstatusProyeccion}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "1%", marginLeft: "3.5%" }}
              >
                <Grid item xs={1} style={{ marginTop: "-60px" }}>
                  <label>Descripción(*)</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{ marginTop: "40px", zIndex: 95 }}
                >
                  <TextareaAutosize
                    className="filter-input"
                    maxRows={7}
                    name="descripcion"
                    value={input.descripcion}
                    onChange={handleChange}
                    style={{
                      width: "102%",
                      height: 120,
                      resize: "none",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Editar Proyección"
        open={modalEditarProyeccion}
        handleClose={() => {
          setModalEditarProyeccion(false);
        }}
        height={520}
        maxWidth={1100}
        loading={loadingModal}
        noSaveBtn
        deshabilitar={btnDeshabilitar}
        handleDeshabilitar={handleDeshabilitar}
      >
        <div className="inputs-asignaciones">
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12}>
              <Grid container alignItems="center" style={{ marginTop: "1%" }}>
                <Grid item xs={1} md={1} style={{ marginLeft: "3.2%" }}>
                  <label>Folio</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 99 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.folio}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  style={{ marginLeft: "11.3%", marginRight: "6%" }}
                >
                  <label>Creado por</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 99 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.creado_por}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  style={{ marginLeft: "7.1%", marginRight: "0.1%" }}
                >
                  <label>Fecha Creado</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 98 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.fecha_creado}
                  // name="fechaGeneracion"
                  // onChange={(e) => handleChange(e)}
                  ></input>
                </Grid>
              </Grid>
              <Grid container alignItems="center" style={{ marginTop: "2%" }}>
                <Grid item xs={1} md={1} style={{ marginLeft: "3.2%" }}>
                  <label>Campus(*)</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 99 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.campus}
                  // options={campusOptions2}
                  // onChange={setCampus}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginLeft: "10.3%", marginRight: "7.1%" }}
                >
                  <label>Escuela(*)</label>
                </Grid>
                <Grid item xs={12} md={5} style={{ zIndex: 99 }}>
                  <input
                    disabled
                    className="filter-input"
                    value={editarBoton.escuela}
                    style={{ width: "530px" }}
                  // options={escuelaOptions}
                  // onChange={setEscuela}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "2%", marginLeft: "0%" }}
              >
                <Grid item xs={1} md={2} style={{ marginLeft: "-5.1%" }}>
                  <label>Fecha Inicial(*)</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{ zIndex: 98, marginLeft: "-0.1%" }}
                >
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.fecha_inicial}
                  // name="fechaInicial"
                  // onChange={(e) => handleChange(e)}
                  ></input>
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  style={{ marginLeft: "4.5%", marginRight: "4.7%" }}
                >
                  <label>Fecha Final(*)</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 98 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.fecha_final}
                  // name="fechaFinal"
                  // onChange={(e) => handleChange(e)}
                  ></input>
                </Grid>
                <Grid item xs={1} md={2} style={{ marginLeft: "7%" }}>
                  <label>Fecha Generación(*)</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 98 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.fecha_generacion}
                  // name="fechaGeneracion"
                  // onChange={(e) => handleChange(e)}
                  ></input>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "2%", marginLeft: "3.5%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Año(*)</label>
                </Grid>
                <Grid item xs={12} md={2} style={{ zIndex: 97 }}>
                  <input
                    disabled
                    className="filter-input"
                    value={editarBoton.year}
                  // options={listaYear}
                  // onChange={(e) => GetListQuincena(e)}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ marginLeft: "-12.3%", marginRight: "6%" }}
                >
                  <label>Quincena(*)</label>
                </Grid>
                <Grid item xs={7} md={6} style={{ zIndex: 97 }}>
                  <input
                    disabled
                    className="filter-input"
                    value={editarBoton.quincena}
                  // options={quincenaOptions}
                  // isLoading={selectQuincenaIsLoading}
                  // onChange={setQuincena}
                  // noOptionsMessage={() => "No se encontraron elementos para la quincena en el año seleccionado"}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "2%", marginLeft: "3.5%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Proyección(*)</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  style={{ zIndex: 96, marginRight: "1%" }}
                >
                  <input
                    disabled
                    className="filter-input"
                    value={editarBoton.proyeccion}
                  // options={proyeccionOptions}
                  // onChange={setProyeccion}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={2}
                  style={{ marginLeft: "1.1%", marginRight: "-0.1%" }}
                >
                  <label>Estatus Proyección(*)</label>
                </Grid>
                <Grid item xs={12} md={6} style={{ zIndex: 96 }}>
                  <input
                    disabled
                    className="filter-input"
                    value={editarBoton.estatus_proyeccion}
                  // options={estatusProyeccionOptions}
                  // onChange={setEstatusProyeccion}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: "1%", marginLeft: "3.5%" }}
              >
                <Grid item xs={1} style={{ marginTop: "-60px" }}>
                  <label>Descripción(*)</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  style={{ marginTop: "40px", zIndex: 96 }}
                >
                  <TextareaAutosize
                    disabled
                    className="filter-input"
                    maxRows={7}
                    value={editarBoton.descripcion}
                    style={{
                      width: "102%",
                      height: 120,
                      resize: "none",
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="center" style={{ marginTop: "2%" }}>
                <Grid item xs={1} md={2} style={{ marginLeft: "-5.1%" }}>
                  <label>Estatus Folio</label>
                </Grid>
                <Grid item xs={12} md={1} style={{ zIndex: 99 }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "178px" }}
                    value={editarBoton.estatus_folio}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default ProyeccionesDeAsistencia;
