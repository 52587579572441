import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { useSelector } from "react-redux";
import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import { getRequest } from "../../Utils/Funciones/requester";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import { orderSchedules } from "../../Utils/Funciones/orderSchedules"

function Horario2() {
  const { enqueueSnackbar } = useSnackbar();
  const [modalConsultarIsOpen, setModalConsultarIsOpen] = useState(false);
  const [dataCalendario, setDataCalendario] = useState([]);
  const [loadingCalendario] = useState(false);
  const [dataDetalleCalendario, setDataDetalleCalendario] = useState([]);
  const [loadingDetalleCalendario] = useState(false);
  const [openHorarioDetail, setOpenHorarioDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profesorValue, setProfesorValue] = useState({
    label: "",
    value: "",
  });
  const [valueCiclo, setValueCiclo] = useState("");
  const [fechaInicialValue, setFechaInicialValue] = useState("");
  const [fechaFinalValue, setFechaFinalValue] = useState("");
  const [profesorModal, setClaveProfesorModal] = useState("");
  const [tipoGrupoValue, setTipoGrupoValue] = useState("");
  const [tipoGrupoOptions, setTipoGrupoOptions] = useState("");
  const [data, setData] = useState([]);
  const [dataFechasEspeciales, setDataFechasEspeciales] = useState([]);
  const id_campus = useSelector((state) => state.campusId);
  const [loadingModal, setLoadingModal] = useState(false);

  const headers = [
    {
      name: "Campus",
      col: 0,
    },
    {
      name: "Escuela",
      col: 1,
    },
    {
      name: "Dirección",
      col: 2,
    },
    {
      name: "Director",
      col: 3,
    },
    {
      name: "Grupo",
      col: 4,
    },
    {
      name: "Clave Materia",
      col: 5,
    },
    {
      name: "Nombre Materia",
      col: 6,
    },
    {
      name: "Lunes",
      col: 7,
    },
    {
      name: "Martes",
      col: 8,
    },
    {
      name: "Miercoles",
      col: 9,
    },
    {
      name: "Jueves",
      col: 10,
    },
    {
      name: "Viernes",
      col: 11,
    },
    {
      name: "Sábado",
      col: 12,
    },
    {
      name: "Domingo",
      col: 13,
    },
    {
      name: "Salón",
      col: 14,
    },
    {
      name: "T.Prof",
      col: 15,
    },
    {
      name: "T.Grupo",
      col: 16,
    },
    {
      name: "T.Asist",
      col: 17,
    },
    {
      name: "Módulo",
      col: 18,
    },
    {
      name: "Hrs Semana",
      col: 19,
    },
    {
      name: "Semanas",
      col: 20,
    },
    {
      name: "F.Alta",
      col: 21,
    },
    {
      name: "F.Baja",
      col: 22,
    },
    {
      name: "Fechas",
      col: 23,
    },
    // {
    //   name: "Acción",
    //   col: 123,
    // },
  ];
  const headersModal = [
    {
      name: "Día",
      col: 0,
    },
    {
      name: "Horario",
      col: 1,
    },
    {
      name: "Fecha",
      col: 2,
    },
  ]

  const headersCalendario = [
    {
      name: "Tipo Registro Asistencia",
      col: 0,
    },
    {
      name: "Modalidad",
      col: 1,
    },
    {
      name: "Campus",
      col: 2,
    },
    {
      name: "Escuela",
      col: 3,
    },
    {
      name: "Dirección",
      col: 4,
    },
    {
      name: "Director",
      col: 5,
    },
    {
      name: "Grupo",
      col: 6,
    },
    {
      name: "Clave Materia",
      col: 7,
    },
    {
      name: "Nombre Materia",
      col: 8,
    },
    {
      name: "Lun",
      col: 9,
    },
    {
      name: "Mar",
      col: 10,
    },
    {
      name: "Mier",
      col: 11,
    },
    {
      name: "Jue",
      col: 12,
    },
    {
      name: "Vie",
      col: 13,
    },
    {
      name: "Sab",
      col: 14,
    },
    {
      name: "Dom",
      col: 15,
    },
  ];

  const headersDetalleCalendario = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "Tipo Profesor",
      col: 2,
    },
    {
      name: "Fecha",
      col: 3,
    },
    {
      name: "Día",
      col: 4,
    },
    {
      name: "Inicia",
      col: 5,
    },
    {
      name: "Termina",
      col: 6,
    },
    {
      name: "Módulo",
      col: 7,
    },
    {
      name: "Horas por Semanas",
      col: 8,
    },
    {
      name: "Semanas",
      col: 9,
    },
    {
      name: "Fecha Alta",
      col: 10,
    },
    {
      name: "Fecha Baja",
      col: 11,
    },
  ];

  useEffect(() => {
    consultarCatalogo(21).then((data) => setTipoGrupoOptions(data));
    getCicloOptions();
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({
            id: id[i],
            value: `${clave[i]} - ${nombre[i]}`,
            label: `${clave[i]} - ${nombre[i]}`,
          });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCicloOptions = async () => {
    try {
      const consulta = `/buscar?tipo=ciclo&valor=`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        for (let i = 0; i < response.d.length; i++) {
          data.push({
            idRow: i,
            value: response.d[i],
            label: response.d[i],
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      //console.log(consulta);
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }
  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const labelStyles = { marginTop: "15px", display: "block", float: "right" };

  const openModalConsultar = async (id) => {
    setOpenHorarioDetail(true);
    setLoadingModal(true);
    const url = `/asignaciones/detalle-consulta?id=${id}`;
    const response = await getRequest(url)
    if (response.s === "OK") {
      const dataForTable = orderSchedules(response.d,`${profesorValue?.label?.split(" - ")?.[0]}`)
      setDataFechasEspeciales(dataForTable.filter((element) => element[2] !== '01-01-1900'))
      setLoadingModal(false);
    }
    else {
      setLoadingModal(false);
    }
  }

  const getDataTableHorario = async () => {
    let valido = true;
    if (profesorValue.value === "") {
      enqueueSnackbar("Falta Seleccionar Clave Profesor", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCiclo === '') {
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (profesorValue.value === "") {
      enqueueSnackbar("Falta Seleccionar Clave Profesor", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        const profesorValueF = profesorValue.value;
        let consulta = `/asignaciones/horario?id_campus=${id_campus}&id_profesor=${profesorValueF}&ciclo=${valueCiclo}`;

        const response = await getRequest(consulta);
        const datos = [];
        if (response.s === "OK") {
          if (response.d.length === 0 || response.d === "") {
            setData([])
            enqueueSnackbar("No se han encontrado datos", {
              variant: "info",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setLoading(false);
          }
          else {
            for (let i = 0; i < response.d[0]["campus"].length; i++) {
              datos.push([
                response.d[0]["campus"][i],
                response.d[1]["escuela"][i],
                response.d[2]["direccion"][i],
                response.d[3]["director"][i],
                response.d[4]["grupo"][i],
                response.d[5]["clave_materia"][i],
                response.d[6]["nombre_materia"][i],
                response.d[7]["lun"][i],
                response.d[8]["mar"][i],
                response.d[9]["mie"][i],
                response.d[10]["jue"][i],
                response.d[11]["vie"][i],
                response.d[12]["sab"][i],
                response.d[13]["dom"][i],
                response.d[14]["salon"][i],
                response.d[15]["tipo_profesor"][i],
                response.d[16]["tipo_grupo"][i],
                response.d[17]["tipo_asistencia"][i],
                response.d[18]["modulo"][i],
                response.d[19]["horas_semana"][i],
                response.d[25]["metodo"][i] !== 'ONLN' ? '-' : response.d[20]["semanas"][i],
                response.d[21]["fecha_alta"][i] === 'None' ? '-' : response.d[21]["fecha_alta"][i],
                response.d[22]["fecha_baja"][i] === 'None' ? '-' : response.d[22]["fecha_baja"][i],
                response.d[23]["fechas"][i] !== "" ?
                  <div I="edit" a="horario">
                    <EditButton
                      onClick={() => { openModalConsultar(response.d[26]["id"][i]) }}
                    >
                      Consultar
                    </EditButton>
                  </div>
                  : "",
              ])
            }
            setData(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setLoading(false);
          }
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  const getDetalleCalendario = (e) => {
    let data = [];
    const data_detalle = e.at(-1);
    data_detalle.forEach((calendario) => {
      data.push([
        calendario.clave_profesor,
        calendario.nombre_profesor,
        calendario.tipo_profesor,
        calendario.fecha,
        calendario.dia,
        calendario.inicia,
        calendario.termina,
        calendario.modulo,
        calendario.horas_por_semanas,
        calendario.semanas,
        calendario["Fecha de Alta"],
        calendario.fecha_baja,
      ]);
    });
    setDataDetalleCalendario(data);
  };
  return (
    <Contenedor title="Asignaciones / Horario">
      <div className="inputs-excepciones">
        <Grid container style={{ position: "relative" }}>
          <Grid container item xs={12} sm={12}>
            <Grid container alignItems="center">
              <Grid item xs={1} sm={1} style={{ textAlign: "end" }}>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={2} sm={2} style={{ zIndex: 99 }}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid item xs={3} sm={3} style={{ textAlign: "end", marginLeft: "-1%" }}>
                <label>Clave Profesor(*):</label>
              </Grid>
              <Grid item xs={3} sm={3} style={{ zIndex: 99 }}>
                <AsyncSelect
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  onChange={setProfesorValue}
                />
              </Grid>
              <Grid item xs={3} sm={3} justifyContent="flex-end" container>
                <NewButton
                  customText="Buscar"
                  noIcon
                  style={{ padding: "5px 15px", marginLeft: "auto" }}
                  onClick={(e) => {
                    getDataTableHorario();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <div style={{ height: "calc(100% - 85px)" }}>
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Fechas Especiales"
        open={openHorarioDetail}
        handleClose={() => {
          setOpenHorarioDetail(false)
        }}
        height={"50vh"}
        maxWidth={"50%"}
        noSaveBtn
        loading={loadingModal}
      >
        <div style={{ height: "90%", overflowY: "hidden", marginTop: "10px" }}>
          <div style={{ height: "100%", overflowY: "hidden" }}>
            <DataTable
              headers={headersModal}
              data={dataFechasEspeciales}
              loading={loadingDetalleCalendario}
              centrar={[1, 2, 3, 4]}
              paginate
              bordes
            />
          </div>
        </div>
      </NewModal>

      <NewModal
        title="Consultar Horario"
        open={modalConsultarIsOpen}
        handleClose={() => {
          setDataDetalleCalendario([]);
          setModalConsultarIsOpen(false)
        }}
        height={"80vh"}
        maxWidth={"90%"}
        noSaveBtn
        loading={loadingModal}
      >
        <div style={{ padding: "25px" }}>
          <Grid container spacing={2} style={{ marginTop: "-2%", alignItems: "center" }}>
            <Grid item xs={2}>
              <label style={labelStyles}>Clave Profesor:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                disabled
                className="filter-input"
                style={{
                  width: "100%",
                  marginRight: "10px",
                  marginTop: "15px",
                }}
                value={profesorModal}
              ></input>
            </Grid>
            <Grid item xs={1}>
              <label style={labelStyles}>Tipo:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                disabled
                className="filter-input"
                style={{ marginTop: "15px" }}
                value={tipoGrupoValue}
              />
            </Grid>

            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <label style={labelStyles}>Fecha Inicial:</label>
            </Grid>
            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <TextField
                disabled
                className="filter-input-fecha"
                label="Seleccionar fecha"
                type="date"
                value={fechaInicialValue}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "170px", marginTop: "15px" }}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <label style={labelStyles}>Fecha Final:</label>
            </Grid>
            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <TextField
                disabled
                className="filter-input-fecha"
                label="Seleccionar fecha"
                type="date"
                value={fechaFinalValue}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "170px", marginTop: "15px" }}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ height: "calc(100% - 120px)", overflowY: "scroll", marginTop: "-1%" }}>
          <div style={{ height: "45%", minHeight: "200px" }}>
            <label style={{ margin: "10px 0", display: "block" }}>
              Calendario
            </label>
            <DataTable
              headers={headersCalendario}
              data={dataCalendario}
              loading={loadingCalendario}
              centrar={[1, 2, 3, 4]}
              paginate
              bordes
              onClickRow={(e) => getDetalleCalendario(e)}
            />
          </div>

          <div style={{ height: "45%", minHeight: "200px" }}>
            <label style={{ margin: "10px 0", display: "block" }}>
              Detalle Horario
            </label>
            <DataTable
              headers={headersDetalleCalendario}
              data={dataDetalleCalendario}
              loading={loadingDetalleCalendario}
              centrar={[1, 2, 3, 4]}
              paginate
              bordes
            />
          </div>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default Horario2;
