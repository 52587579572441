import { View, Text } from '@react-pdf/renderer'
import HeaderPDFDefault from '../HeaderPDFDefault'
import ReportExtraordinaryPaymentsPDF from '../ReportExtraordinaryPaymentsPDF'
import ExtraordinaryPaymentsTotalBox from '../ExtraordinaryPaymentsTotalBox'

export default function ExtraordinaryPaymentsPDF({
    metadata,
    data,
    title,
    campus,
    startDate,
    endDate,
    totalBox
}) {
    return (
        <View>
            <ReportExtraordinaryPaymentsPDF
                metadata={metadata}
                data={data}
                campus={campus}
                startDate={startDate}
                endDate={endDate}
                title={title}
            />
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10, justifyContent: "flex-end" }}>
                <Text style={{ fontSize: 10, }}>Total General: {data.length}</Text>
            </View>
            <View break={true}>
                <HeaderPDFDefault title={title} />
                <View style={{ marginTop: 15 }}>
                    <ExtraordinaryPaymentsTotalBox
                        title={totalBox.header.title}
                        hour={totalBox.header.hour}
                        importTotal={totalBox.header.importTotal}
                        unit={totalBox.header.unit}
                        borderTop={1}
                        borderBottom={1}
                    />
                    {Object.keys(totalBox).map((element, index) => {
                        if (element === "header" || element === "total") {
                            return null
                        }
                        return (
                            <ExtraordinaryPaymentsTotalBox
                                key={index}
                                title={totalBox[element].title}
                                hour={totalBox[element].hour}
                                importTotal={totalBox[element].importTotal}
                                unit={totalBox[element].unit}
                                borderTop={0}
                                borderBottom={1}
                            />
                        )
                    })}
                    <ExtraordinaryPaymentsTotalBox
                        title={totalBox.total.title}
                        hour={totalBox.total.hour}
                        importTotal={totalBox.total.importTotal}
                        unit={totalBox.total.unit}
                        borderTop={0}
                        borderBottom={1}
                    />
                </View>
            </View>
            <View
                fixed
                style={{
                    height: 50,
                    position: 'absolute',
                    bottom: 30,
                    left: 0,
                    right: 0,
                    top: 520
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <View>
                        <Text
                            style={{
                                color: 'gray',
                                fontSize: 11,
                            }}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}