export const teachingAssignmentMaximumTeachingLoadHeader = [
    {
        accessorKey: 'nombre',
        header: 'Nombre',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'valor_director',
        header: 'Valor Director',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'valor_divisional',
        header: 'Valor Divisional',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'tipo_asistencia',
        header: 'Tipo Asistencia',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'fecha_modificado',
        header: 'Fecha Modificado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'usuario_modificado',
        header: 'Usuario Modificado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'fecha_creado',
        header: 'Fecha Creado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'usuario_creado',
        header: 'Usuario Creado',
        enableEditing: false,
        enableGlobalFilter: false
    },
]