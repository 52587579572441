import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../pdf_table/styled";

export default function ReportGlobalIndicatorsDirectorTable({
  metadata,
  data,
  rowstableColSpan,
}) {
  const Headers = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {rowstableColSpan.map((e, i) => (
          <View
            key={i}
            style={{
              width: e.width,
              ...styles.boxHeader,
              justifyContent: e.align,
            }}
          >
            <Text style={styles.cell}>{e.name}</Text>
          </View>
        ))}
      </View>
    );
  };
  const SubHeaders = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {metadata &&
          metadata.map((e, i) => (
            <View
              key={i}
              style={{
                width: e.width,
                ...styles.boxHeader,
                justifyContent: e.align,
              }}
            >
              <Text style={styles.cell}>{e.name}</Text>
            </View>
          ))}
      </View>
    );
  };

  return (
    <View style={{ marginTop: 15 }}>
      <View
        style={{
          marginTop: 10,
          borderLeft: "1px",
          borderLeftColor: "#828282",
          borderTop: "1px",
          borderTopColor: "#828282",
        }}
      >
        <Headers />
        <SubHeaders />
        {data &&
          data.map((e, i) => (
            <View style={{ flexDirection: "row" }} key={i}>
              {metadata.map((el, il) => (
                <View
                  key={il}
                  style={{
                    width: el.width,
                    ...styles.boxRow,
                    justifyContent: el.align,
                    borderTop: i === 0 ? "1px" : "0px",
                  }}
                >
                  {el.fn === null ? (
                    <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                  ) : (
                    <Text> {el.fn(e[el.id])}</Text>
                  )}
                </View>
              ))}
            </View>
          ))}
      </View>
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          marginTop: 10,
          justifyContent: "flex-start",
        }}
      ></View>
    </View>
  );
}
