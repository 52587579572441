import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";
export const getGroupType = async () => {
  try {
    let arrElements = [];
    const request = `/catalogo?id=21`;
    const response = await getRequest(request);
    if (response.s === "OK") {
      let nro = response.d.elementos[0]["id"].length;
      for (let i = 0; i < nro; i++) {
        arrElements.push({
          value: response.d.elementos[1]["clave"][i],
          label:
            response.d.elementos[1]["clave"][i] +
            " - " +
            response.d.elementos[2]["nombre"][i],
        });
      }
      return arrElements;
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  } catch (error) {
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
  }
};
