import moment from "moment";

export const convertDateForParameters = (infoArray) => {
  return infoArray?.map((info) => {
    const newInfo = { ...info };
    if ("creado" in newInfo) {
      newInfo.creado = newInfo.creado
        ? moment(newInfo.creado).format("DD-MM-YYYY HH:mm:ss")
        : "";
    }
    if ("modificado" in newInfo) {
      newInfo.modificado = newInfo.modificado
        ? moment(newInfo.modificado).format("DD-MM-YYYY HH:mm:ss")
        : "";
    }
    if ("fecha_creado" in newInfo) {
      newInfo.fecha_creado = newInfo.fecha_creado
        ? moment(newInfo.fecha_creado).format("DD-MM-YYYY HH:mm:ss")
        : "";
    }
    if ("fecha_modificado" in newInfo) {
      newInfo.fecha_modificado = newInfo.fecha_modificado
        ? moment(newInfo.fecha_modificado).format("DD-MM-YYYY HH:mm:ss")
        : "";
    }
    return newInfo;
  });
};
