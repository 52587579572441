import { useEffect, useMemo, useState } from "react";
import NewModal from "../Visuales/NewModal";
import { useSnackbar } from "notistack";
import { getModalReporteIndicadoresAcademicosSemanales } from "../data/getModalReporteIndicadoresAcademicosSemanales";
import DataTables from "../DataTables";
import { ModalHeadersSemanales } from "../data/reportesIndicadoresAcademicos";
import HeadersModalReporteIndicadoresAcademicosSemanales from "../HeadersModalReporteIndicadoresAcademicosSemanales";
import { Grid } from "@material-ui/core";
import SearchInput from "../Visuales/SearchInput";

export const ModalReporteIndicadoresAcademicosSemanales = ({
  modalConsulta,
  setModalConsulta,
  payload,
}) => {
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryFilter, setQueryFilter] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const filterData = useMemo(() => {
    return info.filter((item) => {
      return `${item}`.toLowerCase().includes(queryFilter);
    });
  }, [info, queryFilter]);

  useEffect(() => {
    if (modalConsulta) {
      getModalReporteIndicadoresAcademicosSemanales(
        payload,
        setInfo,
        setLoading,
        enqueueSnackbar
      );
    }
  }, [payload, enqueueSnackbar, modalConsulta]);

  const handleCloseModal = () => {
    setModalConsulta(false);
    setLoading(true);
  };

  const handleSearch = (e) => {
    setQueryFilter(e.toLowerCase());
  };

  return (
    <NewModal
      title={`Indicadores Académicos Semanales - Detalle de Profesor`}
      open={modalConsulta}
      handleClose={handleCloseModal}
      maxWidth={1500}
      noSaveBtn={true}
    >
      <HeadersModalReporteIndicadoresAcademicosSemanales
        info={info}
        infoHeaders={payload}
      />
      <Grid
        container
        style={{
          marginTop: 30,
          marginBottom: 20,
          alignItems: "baseline",
          paddingRight: 20,
        }}
      >
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={(e) => handleSearch(e)} />
        </Grid>
      </Grid>
      <div style={{ height: 310 }}>
        <DataTables
          headers={ModalHeadersSemanales}
          data={filterData}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          paginate
          bordes
        />
      </div>
    </NewModal>
  );
};
