import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import "./style.css";

export default function NewModal(props) {
  const [item, setItem] = useState(document.getElementById("Pagos"));
  useEffect(() => {
    item ? setItem("none !important") : setItem(null);
  }, [item]);

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      style={{
        marginTop: props.marginTop ? props.marginTop : "",
      }}
    >
      <div className="NewModal" style={{ maxWidth: props.maxWidth }}>
        <CloseIcon onClick={props.handleClose} />
        <h3>{props.title}</h3>
        <div
          className="NewModal-container"
          style={{
            height: props.height,
            paddingBottom: props.pb,
            minHeight: props.minHeight,
            overflowY: props.overflowY || "auto",
            marginBottom: props.marginBottom || "48px",
            maxHeight: props.maxHeight || "75vh",
          }}
        >
          {props.loading ? (
            <div style={{ textAlign: "center", paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div>
          ) : (
            <>
              {props.children}
              {props.noFooter ? null : (
                <div className="NewModal-footer" style={{ bottom: { item } }}>
                  {props.canDelete ? (
                    <button onClick={props.canDelete} className="delete-button">
                      Eliminar
                    </button>
                  ) : null}
                  {props.cancelarRegistro ? (
                    <button
                      onClick={props.cancelarRegistro}
                      className="cancelarRegistro-button"
                    >
                      Cancelar Registro
                    </button>
                  ) : null}
                  {props.handleClickEliminado ? (
                    <button
                      className={props.fdaButtonStyle5}
                      onClick={props.handleClickEliminado}
                    >
                      Eliminado
                    </button>
                  ) : null}
                  {props.deshabilitar ? (
                    <button
                      onClick={props.handleDeshabilitar}
                      className="deshabilitar-button"
                    >
                      Deshabilitar
                    </button>
                  ) : null}
                  {props.autorizar ? (
                    <button
                      className="autorizarBtn"
                      onClick={props.handleSubmit}
                      disabled={props.buttonDisabled}
                    >
                      Autorizar
                    </button>
                  ) : null}
                  {props.dispositivos ? (
                    <button
                      className="active-button"
                      onClick={props.handleActivar}
                    >
                      Activar
                    </button>
                  ) : null}
                  {props.dispositivosDesactivarBtn || props.desactivar ? (
                    <button
                      className="delete-button"
                      onClick={props.handleDesactivar}
                      disabled={props.buttonDisabledDesactivar}
                    >
                      Desactivar
                    </button>
                  ) : null}
                  {props.dispositivos ? (
                    <button
                      className="reiniciar-button"
                      onClick={props.handleReiniciar}
                    >
                      Reiniciar licencia
                    </button>
                  ) : null}
                  {props.noButtons ? null : (
                    <button className="gray-btn" onClick={props.handleClose}>
                      {props.mensaje
                        ? "Cerrar"
                        : props.noSaveBtn
                        ? "Cancelar"
                        : "Cerrar"}
                    </button>
                  )}
                  {props.noButtons ? null : props.mensaje ? (
                    <button className="black-btn" onClick={props.handleSubmit}>
                      Enviar
                    </button>
                  ) : props.guardar ? (
                    <button className="black-btn" onClick={props.handleSubmit}>
                      Guardar
                    </button>
                  ) : props.noSaveBtn ? null : (
                    <button
                      className={
                        props.buttonDisabled ? "gray-btn" : "black-btn"
                      }
                      onClick={props.handleSubmit}
                      disabled={props.buttonDisabled}
                    >
                      Aceptar
                    </button>
                  )}
                  {props.isPago ? (
                    <button
                      className={props.isPagoDisable ? "gray-btn" : "black-btn"}
                      onClick={props.handlePago}
                      disabled={props.isPagoDisable}
                    >
                      Pago
                    </button>
                  ) : null}

                  {props.fdaReportePre ? (
                    <button
                      className={props.fdaButtonStyle1}
                      disabled={props.fdaDisabled}
                      onClick={props.fdaReportePre}
                    >
                      Reporte Previo
                    </button>
                  ) : null}
                  {props.fdaReporteDef ? (
                    <button
                      className={props.fdaButtonStyle2}
                      disabled={props.fdaDisabled}
                      onClick={props.fdaReporteDef}
                    >
                      Reporte Definitivo
                    </button>
                  ) : null}
                  {props.fdaTrasladar ? (
                    <button
                      className={props.fdaButtonStyle3}
                      disabled={props.fdaDisabled}
                      onClick={props.fdaTrasladar}
                    >
                      Trasladar
                    </button>
                  ) : null}
                  {props.fdaEnvioBanner ? (
                    <button
                      className={props.fdaButtonStyle4}
                      disabled={props.fdaDisabled}
                      onClick={props.fdaEnvioBanner}
                    >
                      Envío Banner
                    </button>
                  ) : null}
                  {props.cancelarAsistencia ? (
                    <button
                      onClick={props.cancelarAsistencia}
                      className={
                        props.cancelarAsistenciadaDisabled
                          ? "gray-btnAsistencia"
                          : "cancelarRegistro-button"
                      }
                      disabled={props.cancelarAsistenciadaDisabled}
                    >
                      Cancelar Asistencia
                    </button>
                  ) : null}
                  {props.cancelarRegistroAsistencia ? (
                    <button
                      onClick={props.cancelarRegistroAsistencia}
                      className={
                        props.cancelarRegistroAsistenciadaDisabled
                          ? "gray-btnAsistencia2"
                          : "cancelarRegistro-button2"
                      }
                      disabled={props.cancelarRegistroAsistenciadaDisabled}
                    >
                      Cancelar Registro
                    </button>
                  ) : null}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
