import React, { useMemo } from "react";
import { View, Text } from "@react-pdf/renderer";
import ReportPreviousProductivityBonusTable from "../ReportPreviousProductivityBonusTable";
import { divideArrayIntoParts } from "../Funciones/divideArrayIntoParts";
import HeaderPDFDefault from "../HeaderPDFDefault";

export default function ReportPreviousProductivityBonusPDF({
  metadata,
  data,
  startDate,
  endDate,
  teacherKey = "",
  ciclo,
  escuela,
  title,
}) {
  const direccionName = useMemo(() => {
    const name = [];
    data.forEach((element) => {
      if (!name.includes(element.direccion)) {
        name.push(element.direccion);
      }
    });
    return name;
  }, [data]);
  return direccionName.map((item, index) => {
    const dataForTable = data.filter((element) => element.direccion === item);
    const splitDataIntoParts = divideArrayIntoParts(dataForTable, 6);
    return splitDataIntoParts.map((dataTable, index2) => {
      return (
        <View key={`${index}-${index2}`} style={{ height: 495 }}>
          <HeaderPDFDefault title={title} />
          <View style={{ marginTop: 15 }}>
            <View style={{ marginTop: 1 }}>
              {startDate ? (
                <View
                  style={{ flexDirection: "row", width: "100%", marginTop: 10 }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      color: "#000000",
                      marginRight: "25px",
                    }}
                  >
                    Fecha Inicial: {startDate}
                  </Text>
                  {endDate ? (
                    <Text
                      style={{
                        fontSize: 10,
                        color: "#000000",
                        marginRight: "25px",
                      }}
                    >
                      Fecha Final: {endDate}
                    </Text>
                  ) : null}
                </View>
              ) : null}
              <ReportPreviousProductivityBonusTable
                key={`${index}-${index2}`}
                metadata={metadata}
                data={dataTable}
                campus={dataTable[0]?.campus}
                teacherKey={teacherKey}
                ciclo={ciclo}
                escuela={escuela}
              />
            </View>
          </View>
        </View>
      );
    });
  });
}
