export const PDFformatForReporteIndicadoresAcademicosSemanales = async (
  data
) => {
  const dataPDF = [];
  let recorrido = 0;
  const totalTableInfo = {
    prog_h: 0,
    faltas_brutas_h: 0,
    porc_F_Brutas_h: 0,
    aut: 0,
    rep_h: 0,
    faltas_netas_h: 0,
    por_f_netas_h: 0,
    sust_h: 0,
    adic_h: 0,
    visit_h: 0,
    prog_s: 0,
    faltas_brutas_s: 0,
    ut: 0,
    rep_s: 0,
    faltas_netas_s: 0,
    sust_s: 0,
    adic_s: 0,
    visit_s: 0,
    s_ant: 0,
    ret_s: 0,
    ret_m: 0,
    can: 0,
  };
  if (data.length > 400) {
    recorrido = 300;
  } else {
    recorrido = data.length;
  }
  
  for (let i = 0; i < recorrido; i++) {
    dataPDF.push({
      ciclo: data[i][0],
      campus: data[i][1],
      escuela: data[i][2],
      direccion: data[i][3],
      director: data[i][4],
      prog_h: data[i][6],
      faltas_brutas_h: data[i][7],
      porc_F_Brutas_h: data[i][8],
      aut: data[i][9],
      rep_h: data[i][10],
      faltas_netas_h: data[i][11],
      por_f_netas_h: data[i][12],
      sust_h: data[i][13],
      adic_h: data[i][14],
      visit_h: data[i][15],
      prog_s: data[i][16],
      faltas_brutas_s: data[i][17],
      ut: data[i][18],
      rep_s: data[i][19],
      faltas_netas_s: data[i][20],
      sust_s: data[i][21],
      adic_s: data[i][22],
      visit_s: data[i][23],
      s_ant: data[i][24],
      ret_s: data[i][25],
      ret_m: data[i][26],
      can: data[i][27],
    });
    totalTableInfo.prog_h = totalTableInfo.prog_h + parseFloat(data[i][6]);
    totalTableInfo.faltas_brutas_h =
      totalTableInfo.faltas_brutas_h + parseFloat(data[i][7]);
    totalTableInfo.porc_F_Brutas_h =
      totalTableInfo.porc_F_Brutas_h + parseFloat(data[i][8]);
    totalTableInfo.aut = totalTableInfo.aut + parseFloat(data[i][9]);
    totalTableInfo.rep_h = totalTableInfo.rep_h + parseFloat(data[i][10]);
    totalTableInfo.faltas_netas_h =
      totalTableInfo.faltas_netas_h + parseFloat(data[i][11]);
    totalTableInfo.por_f_netas_h =
      totalTableInfo.por_f_netas_h + parseFloat(data[i][12]);
    totalTableInfo.sust_h = totalTableInfo.sust_h + parseFloat(data[i][13]);
    totalTableInfo.adic_h = totalTableInfo.adic_h + parseFloat(data[i][14]);
    totalTableInfo.visit_h = totalTableInfo.visit_h + parseFloat(data[i][15]);
    totalTableInfo.prog_s = totalTableInfo.prog_s + parseFloat(data[i][16]);
    totalTableInfo.faltas_brutas_s =
      totalTableInfo.faltas_brutas_s + parseFloat(data[i][17]);
    totalTableInfo.ut = totalTableInfo.ut + parseFloat(data[i][18]);
    totalTableInfo.rep_s = totalTableInfo.rep_s + parseFloat(data[i][19]);
    totalTableInfo.faltas_netas_s =
      totalTableInfo.faltas_netas_s + parseFloat(data[i][22]);
    totalTableInfo.sust_s = totalTableInfo.sust_s + parseFloat(data[i][21]);
    totalTableInfo.adic_s = totalTableInfo.adic_s + parseFloat(data[i][22]);
    totalTableInfo.visit_s = totalTableInfo.visit_s + parseFloat(data[i][23]);
    totalTableInfo.s_ant = totalTableInfo.s_ant + parseFloat(data[i][24]);
    totalTableInfo.ret_s = totalTableInfo.ret_s + parseFloat(data[i][25]);
    totalTableInfo.ret_m = totalTableInfo.ret_m + parseFloat(data[i][26]);
    totalTableInfo.can = totalTableInfo.can + parseFloat(data[i][27]);
  }
  dataPDF.push({
    director: "Totales",
    prog_h: parseFloat(totalTableInfo.prog_h).toFixed(0),
    faltas_brutas_h: parseFloat(totalTableInfo.faltas_brutas_h).toFixed(0),
    porc_F_Brutas_h: parseFloat(totalTableInfo.porc_F_Brutas_h).toFixed(2),
    aut: parseFloat(totalTableInfo.aut).toFixed(0),
    rep_h: parseFloat(totalTableInfo.rep_h).toFixed(0),
    faltas_netas_h: parseFloat(totalTableInfo.faltas_netas_h).toFixed(0),
    por_f_netas_h: parseFloat(totalTableInfo.por_f_netas_h).toFixed(2),
    sust_h: parseFloat(totalTableInfo.sust_h).toFixed(0),
    adic_h: parseFloat(totalTableInfo.adic_h).toFixed(0),
    visit_h: parseFloat(totalTableInfo.visit_h).toFixed(0),
    prog_s: parseFloat(totalTableInfo.prog_s).toFixed(0),
    faltas_brutas_s: parseFloat(totalTableInfo.faltas_brutas_s).toFixed(0),
    ut: parseFloat(totalTableInfo.ut).toFixed(0),
    rep_s: parseFloat(totalTableInfo.rep_s).toFixed(0),
    faltas_netas_s: parseFloat(totalTableInfo.faltas_netas_s).toFixed(0),
    sust_s: parseFloat(totalTableInfo.sust_s).toFixed(0),
    adic_s: parseFloat(totalTableInfo.adic_s).toFixed(0),
    visit_s: parseFloat(totalTableInfo.visit_s).toFixed(0),
    s_ant: parseFloat(totalTableInfo.s_ant).toFixed(0),
    ret_s: parseFloat(totalTableInfo.ret_s).toFixed(0),
    ret_m: parseFloat(totalTableInfo.ret_m).toFixed(0),
    can: parseFloat(totalTableInfo.can).toFixed(0),
  });
  return dataPDF;
};
