import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import Select from "react-select";
import { useSelector } from "react-redux";
import excelIcon from "../../images/excel-icon.png";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import "./index.css";
import { ExportCSV } from "../../Utils/Excel";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ReportedeAutorizaciondeFyC = () => {
  const headers = [
    {
      name: "Ciclo",
      col: 0,
      id: "ciclo",
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "escuela",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 3,
      id: "direccion",
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 4,
      id: "clave_profesor",
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 5,
      id: "nombre_profesor",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Clase",
      col: 6,
      id: "tipo_de_clase",
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 7,
      id: "grupo",
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      col: 8,
      id: "clave_materia",
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      col: 9,
      id: "descripcion_materia",
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      col: 10,
      id: "horario",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha de Clase",
      col: 11,
      id: "fecha_de_clase",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Autorización",
      col: 12,
      id: "fecha_autorizacion",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Creación",
      col: 13,
      id: "fecha_creacion",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Estado",
      col: 14,
      id: "estado",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Motivo",
      col: 15,
      id: "motivo",
      width: "12%",
      align: "center",
      fn: null,
    },
  ];

  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [optionsCampus] = useState(campusUser);
  const [valueCampus, setValueCampus] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueTipoClase, setValueTipoClase] = useState("");
  const [optionsTipoClase, setOptionsTipoClase] = useState("");
  const [input, setInput] = useState({
    fechaInicial: "",
    fechaFinal: "",
  });

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      else if (row[3].toLowerCase().includes(text)) return true;
      else if (row[4].toLowerCase().includes(text)) return true;
      else if (row[5].toLowerCase().includes(text)) return true;
      else if (row[6].toLowerCase().includes(text)) return true;
      else if (row[7].toLowerCase().includes(text)) return true;
      else if (row[8].toLowerCase().includes(text)) return true;
      else if (row[9].toLowerCase().includes(text)) return true;
      else if (row[10].toLowerCase().includes(text)) return true;
      else if (row[11].toLowerCase().includes(text)) return true;
      else if (row[12].toLowerCase().includes(text)) return true;
      else if (row[13].toLowerCase().includes(text)) return true;
      else if (row[14].toLowerCase().includes(text)) return true;
      else if (row[15].toLowerCase().includes(text)) return true;
      return false;
    });
    setData(result);
  }

  const { enqueueSnackbar } = useSnackbar();

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setSelectEscuela(data));
    getClassTypesOptions();
    setValueCampus(
      optionsCampus?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  async function getClassTypesOptions() {
    try {
      const endpoint = `/catalogo?id=22`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          if (response.d.elementos[1]["clave"][i] !== "001") {
            data.push({
              label:
                response.d.elementos[1]["clave"][i] +
                " - " +
                response.d.elementos[2]["name"][i],
              value: response.d.elementos[0]["id"][i],
            });
          }
        }

        const notAllowedValues = ["7", "8", "11", "12", "13", "14", "15", "17"];

        const filterData = data.filter(
          (e) => !notAllowedValues.includes(e.value)
        );

        setOptionsTipoClase(filterData);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        Ciclo: e[0],
        Campus: e[1],
        Escuela: e[2],
        Dirección: e[3],
        "Clave Profesor": e[4],
        "Nombre Profesor": e[5],
        "Tipo de Clase": e[6],
        Grupo: e[7],
        "Clave Materia": e[8],
        "Descripción Materia": e[9],
        Horario: e[10],
        "Fecha de Clase": e[11],
        "Fecha Autorización": e[12],
        "Fecha Creación": e[13],
        Estado: e[14],
        Motivo: e[15],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(
      data_,
      `Reporte_Autorizacion_de_Faltas_y_Cancelaciones${fecha}_${hora}`
    );
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        clave_profesor: e[4],
        nombre_profesor: e[5],
        tipo_de_clase: e[6],
        grupo: e[7],
        clave_materia: e[8],
        descripcion_materia: e[9],
        horario: e[10],
        fecha_de_clase: e[11],
        fecha_autorizacion: e[12],
        fecha_creacion: e[15],
        estado: e[13],
        motivo: e[14],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Reportes de Autorización de Faltas y Cancelaciones`}
        campus={valueCampus.label}
        startDate={input.fechaInicial}
        endDate={input.fechaFinal}
        teacherKey={
          valueClaveProfesor.value === undefined ? "" : valueClaveProfesor.label
        }
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Autorizacion_de_FyC${fecha}_${hora}.pdf`);
    setReq(false);
  };

  function handleChange(e) {
    e.preventDefault();
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  }

  async function handleClickGenerar() {
    setLoading(true);
    setData([]);
    if (valueCiclo === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (input.fechaInicial === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Fecha Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (input.fechaFinal === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valueCampus === "") {
      setLoading(false);
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        const datos = [];
        let url = `/reportes/autorizacion_faltas_cancelaciones?ciclo=${valueCiclo}&fi=${input.fechaInicial}&ff=${input.fechaFinal}&id_campus=${valueCampus.value}`;
        if (valueClaveProfesor)
          url += `&id_profesor=${valueClaveProfesor.value}`;
        if (selectedEscuela) url += `&id_escuela=${selectedEscuela.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        if (valueTipoClase) url += `&id_tipo_clase=${valueTipoClase.value}`;
        const request = url;
        const response = await getRequest(request);
        //console.log(response.d);
        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["escuela"][i],
              response.d[3]["direccion"][i],
              response.d[4]["clave_profesor"][i],
              response.d[5]["nombre_profesor"][i],
              response.d[6]["tipo_clase"][i],
              response.d[7]["grupo"][i],
              response.d[8]["clave_materia"][i],
              response.d[9]["descripcion_materia"][i],
              response.d[10]["horario"][i],
              response.d[11]["fecha_clase"][i],
              response.d[12]["fecha_autorizacion"][i],
              response.d[15]["fecha_creacion"][i],
              response.d[13]["estado"][i],
              response.d[14]["motivo"][i],
            ]);
          }

          setData(datos);
          setDataOriginal(datos);
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
      setShowTable(true);
    }
  }

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Autorización de Faltas y Cancelaciones">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={12} style={{ marginLeft: "-2.5%" }}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginLeft: "0.5%" }}
            >
              <Grid item xs={1} sm={1} style={{ textAlign: "end" }}>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={1} sm={1}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid xs={1} md={2}>
                <label>Fecha Inicial(*):</label>
              </Grid>
              <Grid
                xs={1}
                md={1}
                style={{ marginLeft: "-7%", marginRight: "5%" }}
              >
                <input
                  className="filter-input"
                  style={{ width: "140px" }}
                  value={input.fechaInicial}
                  name="fechaInicial"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid xs={1} md={2}>
                <label>Fecha Final(*):</label>
              </Grid>
              <Grid
                xs={1}
                md={1}
                style={{ marginLeft: "-7.5%", marginRight: "4.5%" }}
              >
                <input
                  className="filter-input"
                  style={{ width: "140px" }}
                  value={input.fechaFinal}
                  name="fechaFinal"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>
              <Grid item xs={1} md={1}>
                <label>Campus(*):</label>
              </Grid>
              <Grid
                item
                xs={1}
                sm={2}
                style={{ marginLeft: "-1%", marginRight: "3%" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={optionsCampus}
                  onChange={setValueCampus}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginLeft: "0.5%" }}
            >
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Clave Profesor:</label>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                style={{ zIndex: 99, marginRight: "0.8%" }}
              >
                <AsyncSelect
                  className="select-modal-container input-claveProfesor clave-profesor-index0"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  isClearable={true}
                  loadOptions={promiseOptions}
                  value={valueClaveProfesor}
                  onChange={setValueClaveProfesor}
                  placeholder="Buscar Profesor"
                />
              </Grid>
              <Grid xs={1} md={1}>
                <label>Escuela:</label>
              </Grid>
              <Grid
                xs={1}
                md={2}
                item
                style={{ zIndex: 99, marginLeft: "-4%", marginRight: "-1%" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectEscuela}
                  value={selectedEscuela}
                  onChange={setSelectedEscuela}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginLeft: "0.5%" }}
            >
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Tipo de Clase:</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueTipoClase}
                  options={optionsTipoClase}
                  onChange={setValueTipoClase}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={3}
            spacing={2}
            style={{ marginTop: "1.5%", marginLeft: "-22.5%" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {" "}
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>

        <div style={{ height: "calc(100% - 215px)" }} id="Pagos">
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ReportedeAutorizaciondeFyC;
