export const reportPreviousProductivityBonusPDFHeaders = [
    {
        "name": "Clave Profesor",
        "id": "clave_profesor",
        "col": 0,
        "fn": null,
        "width": "15%",
        "align": "center"
    },
    {
        "name": "Nombre",
        "id": "nombre_profesor",
        "col": 1,
        "fn": null,
        "width": "25%",
        "align": "center"
    },
    {
        "name": "Grupo",
        "id": "grupo",
        "col": 2,
        "fn": null,
        "width": "15%",
        "align": "center"
    },
    {
        "name": "Materia",
        "id": "clave_materia",
        "col": 3,
        "fn": null,
        "width": "15%",
        "align": "center"
    },
    // {
    //     "name": "Nombre Materia",
    //     "id": "nombre_materia",
    //     "col": 8,
    //     "fn": null,
    //     "width": "18%",
    //     "align": "center"
    // },
    {
        "name": "Asis",
        "id": "asignados",
        "col": 4,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Falta",
        "id": "falta",
        "col": 5,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "F.Aut",
        "id": "falta_autorizada",
        "col": 6,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Sust",
        "id": "sustitucion",
        "col": 7,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Rep",
        "id": "reposicion",
        "col": 8,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Adic",
        "id": "adicional",
        "col": 9,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Reta",
        "id": "retardo",
        "col": 10,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Salida A",
        "id": "salida_anticipada",
        "col": 11,
        "fn": null,
        "width": "15%",
        "align": "center"
    },
    {
        "name": "T Clases",
        "id": "total_clases",
        "col": 12,
        "fn": null,
        "width": "15%",
        "align": "center"
    },
    {
        "name": "F.Alta",
        "id": "fecha_alta",
        "col": 18,
        "fn": null,
        "width": "17%",
        "align": "center"
    },
    {
        "name": "F Baja",
        "id": "fecha_baja",
        "col": 19,
        "fn": null,
        "width": "17%",
        "align": "center"
    },
    {
        "name": "Eval",
        "id": "evaluacion",
        "col": 20,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Marca",
        "id": "marca",
        "col": 21,
        "fn": null,
        "width": "12%",
        "align": "center"
    },
    {
        "name": "Ev Prom",
        "id": "evaluacion_promedio",
        "col": 22,
        "fn": null,
        "width": "15%",
        "align": "center"
    },
    {
        "name": "%Bono",
        "id": "bono",
        "col": 23,
        "fn": null,
        "width": "15%",
        "align": "center"
    }
]