import { View, Image, Text } from '@react-pdf/renderer'
import logo from '../../images/unitec-en-linea.jpeg'
import { styles } from '../pdf_table/styled'
import moment from 'moment'

const HeaderPDFDefault = ({ title }) => {
    const dateTime = moment().format("DD/MM/YYYY HH:mm:ss");
    return (
        <View style={{ flexDirection: 'row' }} >
            <View style={{ width: '20%', position: "absolute" }}>
                <Image src={logo} style={styles.logo} />
            </View>
            <View style={{ width: '100%', marginVertical: 'auto', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <View style={{ backgroundColor: '#acc7ed' }}>
                    <Text style={{ fontSize: 10, fontFamily: 'Bold', paddingTop: '3px', paddingBottom: '3px', paddingRight: '120px', paddingLeft: '120px' }}>Universidad Tecnológica de México</Text>
                </View>

                <Text style={{ fontSize: 10, marginBottom: 10 }}>{title}</Text>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", width: '100%' }}>
                    <Text style={{ fontSize: 8 }}>
                        Fecha Reporte: {dateTime}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", width: '100%' }}>
                    <View style={{ width: '100%', height: '2px', backgroundColor: 'gray' }} />
                </View>
            </View>
        </View>
    )
}

export default HeaderPDFDefault