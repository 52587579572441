export const salonesHeaders = [
    {
        name: "Dias",
        col: 0,
      },
  {
    name: "07:00",
    col: 1,
  },
  {
    name: "07:30",
    col: 2,
  },
  {
    name: "08:00",
    col: 3,
  },
  {
    name: "08:30",
    col: 4,
  },
  {
    name: "09:00",
    col: 5,
  },
  {
    name: "09:30",
    col: 6,
  },
  {
    name: "10:00",
    col: 7,
  },
  {
    name: "10:30",
    col: 8,
  },
  {
    name: "11:00",
    col: 9,
  },
  {
    name: "11:30",
    col: 10,
  },
  {
    name: "12:00",
    col: 11,
  },
  {
    name: "12:30",
    col: 12,
  },
  {
    name: "13:00",
    col: 13,
  },
  {
    name: "13:30",
    col: 14,
  },
  {
    name: "14:00",
    col: 15,
  },
  {
    name: "14:30",
    col: 16,
  },
  {
    name: "15:00",
    col: 17,
  },
  {
    name: "15:30",
    col: 18,
  },
  {
    name: "16:00",
    col: 19,
  },
  {
    name: "16:30",
    col: 20,
  },
  {
    name: "17:00",
    col: 21,
  },
  {
    name: "17:30",
    col: 22,
  },
  {
    name: "18:00",
    col: 23,
  },
  {
    name: "18:30",
    col: 24,
  },
  {
    name: "19:00",
    col: 25,
  },
  {
    name: "19:30",
    col: 26,
  },
  {
    name: "20:00",
    col: 27,
  },
  {
    name: "20:30",
    col: 28,
  },
  {
    name: "21:00",
    col: 29,
  },
  {
    name: "21:30",
    col: 30,
  },
  {
    name: "22:00",
    col: 31,
  },
  {
    name: "22:30",
    col: 32,
  },
];
