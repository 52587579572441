
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../pdf_table/styled'

export default function ReportIndicadoresAcademicosPDF({
    metadata,
    data,
    campus,
    startDate,
    endDate,
    ciclo,
    escuela,
    direccion,
    tipo_clase
}) {
    const Headers = () => {
        return (
            <View style={{ flexDirection: 'row' }}>
                {
                    metadata && (
                        metadata.map((e, i) => (
                            <View key={i} style={{ width: e.width, ...styles.boxHeader, justifyContent: e.align }}>
                                <Text style={styles.cell}>{e.name}</Text>
                            </View>
                        ))
                    )
                }
            </View>
        )
    }
    return (
        <View style={{ marginTop: 15 }}>
            <View style={{ marginTop: 1 }}>
                {startDate ? (
                    <View style={{ flexDirection: 'row', width: '100%', marginTop: 2 }}>
                        <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Inicial: {startDate}</Text>
                        {endDate ? (
                            <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Final: {endDate}</Text>
                        ) : null}
                    </View>
                ) : null}

                <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                    <Text style={{ fontSize: 10, color: '#000000', width: '25%' }}>Campus: {campus}</Text>
                    <Text style={{ fontSize: 10, color: '#000000' }}>Ciclo: {ciclo}</Text>
                </View>
                <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                    <Text style={{ fontSize: 10, color: '#000000', width: '25%' }}>Escuela: {escuela}</Text>
                    <Text style={{ fontSize: 10, color: '#000000' }}>Dirección: {direccion}</Text>
                </View>

                <View style={{ borderLeft: '1px', borderLeftColor: '#828282', borderTop: '1px', borderTopColor: '#828282', marginTop: 5 }}>
                    <Headers />
                    {
                        data && (
                            data.map((e, i) => (
                                <View style={{ flexDirection: 'row' }} key={i}>
                                    {metadata.map((el, il) => (
                                        <View key={il} style={{ width: el.width, ...styles.boxRow, justifyContent: el.align }}>
                                            {
                                                el.fn === null ? (
                                                    <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                                                ) :
                                                    <Text> {el.fn(e[el.id])}</Text>
                                            }
                                        </View>
                                    ))}
                                </View>
                            ))
                        )
                    }
                </View>
            </View>
        </View>
    )
}