import { useState } from "react";

const InputCheck = ({ status, handleCheckEstatus, data, index }) => {
  const [isCheck, setIsCheck] = useState(true);
  const handleChenge = (e) => {
    setIsCheck(e.target.checked);
    handleCheckEstatus(data, index, e);
  };
  return (
    <input
      type="checkbox"
      checked={isCheck}
      onChange={(e) => handleChenge(e)}
    />
  );
};

export default InputCheck;
