import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import Select from "react-select";
import { useSelector } from "react-redux";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import { ExportCSV } from "../../Utils/Excel";
import saveAs from "file-saver";
import styles from "./styles.module.css";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ReporteProfesoresTitularesConGrupos = () => {
  const headers = [
    {
      name: "Ciclo",
      col: 0,
      id: "ciclo",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      col: 1,
      id: "campus",
      width: "30%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Profesor",
      col: 6,
      id: "tipo_profesor",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 2,
      id: "escuela",
      width: "30%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 3,
      id: "direccion",
      width: "40%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 4,
      id: "clave_profesor",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 5,
      id: "nombre_profesor",
      width: "30%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 7,
      id: "grupo",
      width: "17%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      col: 8,
      id: "clave_materia",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      col: 9,
      id: "descripcion_materia",
      width: "30%",
      align: "center",
      fn: null,
    },
    {
      name: "Lun",
      col: 10,
      id: "lun",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Mar",
      col: 11,
      id: "mar",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Mie",
      col: 12,
      id: "mie",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Jue",
      col: 13,
      id: "jue",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Vie",
      col: 14,
      id: "vie",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Sab",
      col: 15,
      id: "sab",
      width: "20%",
      align: "center",
      fn: null,
    },
  ];

  const headers_pdf = [
    {
      name: "Campus",
      col: 0,
      id: "campus",
      width: "30%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      col: 1,
      id: "clave_profesor",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      col: 2,
      id: "nombre_profesor",
      width: "30%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Profesor",
      col: 3,
      id: "tipo_profesor",
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Dirección",
      col: 4,
      id: "direccion",
      width: "40%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      col: 5,
      id: "grupo",
      width: "17%",
      align: "center",
      fn: null,
    },
    {
      name: "Materia",
      col: 6,
      id: "clave_materia",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      col: 7,
      id: "descripcion_materia",
      width: "30%",
      align: "center",
      fn: null,
    },
  ];

  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const campusUser = useSelector((state) => state.campusUser2);
  const campusName = useSelector((state) => state.campusName);
  const [optionsCampus] = useState(campusUser);
  const [valueCampus, setValueCampus] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function handleClickGenerar() {
    setLoading(true);
    if (valueCiclo === "") {
      setLoading(false);
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valueCampus === "") {
      setLoading(false);
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        const datos = [];
        let url = `/reportes/profesores_titulares_grupos_asistentes?ciclo=${valueCiclo}&id_campus=${valueCampus.value}`;
        if (selectedEscuela) url += `&escuela=${selectedEscuela.value}`;
        if (valueClaveProfesor)
          url += `&id_profesor=${valueClaveProfesor.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[6]["tipo_profesor"][i],
              response.d[2]["escuela"][i],
              response.d[3]["direccion"][i],
              response.d[4]["clave_profesor"][i],
              response.d[5]["nombre_profesor"][i],
              response.d[7]["grupo"][i],
              response.d[8]["clave_materia"][i],
              response.d[9]["descripcion_materia"][i],
              response.d[10]["lun"][i],
              response.d[11]["mar"][i],
              response.d[12]["mie"][i],
              response.d[13]["jue"][i],
              response.d[14]["vie"][i],
              response.d[15]["sab"][i],
            ]);
          }
          setData(datos);
          setDataOriginal(datos);
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        setLoading(false);
        //console.log(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoading(false);
      setShowTable(true);
    }
  }

  useEffect(() => {
    searchEscuela();
    setValueCampus(
      optionsCampus?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  async function searchEscuela() {
    try {
      setLoading(true);
      let arrElements = [];
      const request = `/catalogo?id=9`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        let nroEscuelas = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nroEscuelas; i++) {
          arrElements.push({
            value: response.d.elementos[0]["id"][i],
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["nombre"][i],
          });
        }
        setLoading(false);
        setSelectEscuela(arrElements);
      } else {
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const DownloadPDF = async () => {
    setReq(true);

    const sortedData = [...data].sort(
      (a, b) => a[5].localeCompare(b[5]) // Ordenar por clave_profesor
    );

    const data_ = sortedData.map((e) => {
      const data2 = {
        //ciclo: e[0],
        campus: e[1],
        // escuela: e[3],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        tipo_profesor: e[2],
        direccion: e[4],
        grupo: e[7],
        clave_materia: e[8],
        descripcion_materia: e[9],
        // lun: e[10],
        // mar: e[11],
        // mie: e[12],
        // jue: e[13],
        // vie: e[14],
        // sab: e[15],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers_pdf ?? []}
        data={data_ ?? []}
        title={`Reportes Reporte de Profesores Titulares con Grupos de Asistentes`}
        //campus={valueCampus.label}
        ciclo={valueCiclo}
        //total_profesores_grupo_materia={data.length ?? 0}
        teacherKey={
          valueClaveProfesor.value === undefined ? "" : valueClaveProfesor.label
        }
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_ProfesoresTitularesConGrupos_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const sortedData = [...data].sort(
      (a, b) => a[5].localeCompare(b[5]) // Ordenar por clave_profesor
    );

    const data_ = sortedData.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        tipo_profesor: e[2],
        escuela: e[3],
        direccion: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        grupo: e[7],
        clave_materia: e[8],
        descripcion_materia: e[9],
        lun: e[10],
        mar: e[11],
        mie: e[12],
        jue: e[13],
        vie: e[14],
        sab: e[15],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_ProfesoresTitularesConGrupos_${fecha}_${hora}`);
    setReq(false);
  };

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Profesores Titulares con Grupos de Asistentes">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={12} style={{ marginTop: "-0.5%" }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                xs={12}
                sm={1}
                style={{
                  textAlign: "end",
                  marginLeft: "-2%",
                  marginRight: "-0.5%",
                }}
              >
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={12} sm={1}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                  style={{ width: "120%" }}
                />
              </Grid>
              <Grid item xs={1} sm={1} style={{ textAlign: "end" }}>
                <label>Campus(*):</label>
              </Grid>
              <Grid item xs={1} md={1}>
                <Select
                  className={styles.select}
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={optionsCampus}
                  onChange={setValueCampus}
                />
              </Grid>
              <Grid xs={1} md={1} style={{ textAlign: "end" }}>
                <label>Escuela:</label>
              </Grid>
              <Grid xs={1} md={1} item style={{ zIndex: 99 }}>
                <Select
                  className={styles.select}
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectEscuela}
                  onChange={setSelectedEscuela}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                className="textEnd"
                style={{ marginLeft: "3%" }}
              >
                <label>Clave Profesor:</label>
              </Grid>
              <Grid item xs={12} sm={2} style={{ zIndex: 99 }}>
                <AsyncSelect
                  className="select-modal-container input-claveProfesor clave-profesor-index0"
                  classNamePrefix="select-modal"
                  styles={asyncSelectStyles}
                  isClearable={true}
                  loadOptions={promiseOptions}
                  onChange={setValueClaveProfesor}
                  placeholder="Buscar Profesor"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={10}
            sm={3}
            spacing={2}
            style={{ marginLeft: "-27%" }}
          >
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    onClick={DownloadPDF}
                    disabled={req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>

        <div style={{ height: "calc(100% - 170px)" }} id="Pagos">
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ReporteProfesoresTitularesConGrupos;
