import { createStore } from "redux";
import reducer from "./reducers";

const initialState = {
  isLoged: false,
  comentariosPagos: false,
  campusUser: [],
  campusUser2: [],
  campusId: "",
  campusName: "",
  personId: "",
  permisos: {},
  userName: "",
  rol: ""
};

const store = createStore(
  reducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
