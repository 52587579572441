import React, { useState } from "react";
import { Grid } from "@material-ui/core";

const CreateModalInputs = ({
  label,
  inputValue,
  disabled = true,
  onChange = () => {},
  sm = 2,
}) => {
  const [inputValue2, setInputValue2] = useState(inputValue);
  const [typingTimeout, setTypingTimeout] = useState(null);
  // This function is called when the user types in the input field
  function handleInputChange(event) {
    const newValue = event;
    setInputValue2(newValue);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newTypingTimeout = setTimeout(() => {
      onChange(newValue);
    }, 500);
    setTypingTimeout(newTypingTimeout);
  }

  return (
    <Grid container style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
      <Grid
        item
        sm={sm}
        style={{
          textAlign: "end",
          marginRight: 20,
        }}
      >
        <label>{label}:</label>
      </Grid>
      <Grid style={{ flexGrow: 1 }}>
        <input
          className="filter-input input-ciclo"
          placeholder=""
          value={inputValue2}
          disabled={disabled}
          onChange={(event) => handleInputChange(event.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default CreateModalInputs;
