import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const getCampusCatalog = async () => {
    try {
        const consulta = `/catalogo?id=5`;
        const response = await getRequest(consulta);
        let data = [];
        if (response.s === "OK") {
            let id = response.d.elementos[0].id;
            let clave = response.d.elementos[1].clave;
            let nombre = response.d.elementos[2].nombre;
            let active = response.d.elementos[3].active;
            let creado_por = response.d.elementos[4].creado_por;
            let creado = response.d.elementos[5].creado;
            let modificado_por = response.d.elementos[6].modificado_por;
            let modificado = response.d.elementos[7].modificado;
            id.forEach((item, i) => {
                data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}`, clave: clave[i], nombre: nombre[i], active: active[i], creado_por: creado_por[i], creado: creado[i], modificado_por: modificado_por[i], modificado: modificado[i] });
            });
            return data;
        } else {
            enqueueSnackbar(response.m, {
                variant: "error", autoHideDuration: null,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            });
        }
    } catch (error) {
        console.error(error);
        enqueueSnackbar(error, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
                vertical: "top",
                horizontal: "left",
            },
        });
    }
}