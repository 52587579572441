import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";

function Bitacora() {
  const headers = [
    {
      name: "Escuela",
      col: 0,
    },
    {
      name: "Tipo de Curso",
      col: 1,
    },
    {
      name: "Tipo Movimiento",
      col: 2,
    },
    {
      name: "Clave Profesor",
      col: 3,
    },
    {
      name: "Nombre Profesor",
      col: 4,
    },
    {
      name: "Grupo",
      col: 5,
    },
    {
      name: "Clave Materia",
      col: 6,
    },
    {
      name: "Nombre Materia",
      col: 7,
    },
    {
      name: "Fecha Creación",
      col: 8,
    },
    {
      name: "Fecha Movimiento",
      col: 9,
    },
    {
      name: "Día",
      col: 10,
    },
    {
      name: "Horario",
      col: 11,
    },
    {
      name: "Módulo",
      col: 11,
    },
    {
      name: "Acciones",
      col: 12,
    },
  ];

  const headersTablaDetalle = [
    {
      name: "Mov.",
      col: 0,
    },
    {
      name: "Clave Profesor",
      col: 1,
    },
    {
      name: "Nombre Profesor",
      col: 2,
    },
    {
      name: "Tipo Profesor",
      col: 3,
    },
    {
      name: "Día",
      col: 4,
    },
    {
      name: "Horario",
      col: 5,
    },
    {
      name: "Fecha Aplicación",
      col: 6,
    },
    {
      name: "Horas por Semana",
      col: 7,
    },
    {
      name: "N semanas",
      col: 8,
    },
    {
      name: "Módulo",
      col: 9,
    },
    {
      name: "Fecha Creado Registro",
      col: 10,
    },
    {
      name: "Usuario",
      col: 11,
    },
  ];

  const headersTablaHorarioDetalle = [
    {
      name: "Lunes",
      col: 0,
    },
    {
      name: "Martes",
      col: 1,
    },
    {
      name: "Miércoles",
      col: 2,
    },
    {
      name: "Jueves",
      col: 3,
    },
    {
      name: "Viernes",
      col: 4,
    },
    {
      name: "Sábado",
      col: 5,
    },
    {
      name: "Domingo",
      col: 6,
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detalleModalIsOpen, setDetalleModalIsOpen] = useState(false);
  const [loadingModalDetalle, setLoadingModalDetalle] = useState(false);
  const [, setDataHorarioDetalleOriginal] = useState([]);
  const [dataHorarioDetalleFinal, setDataHorarioDetalleFinal] = useState([]);
  const [loadingHorarioDetalle] = useState(false);
  const [loadingTablaDetalle] = useState(false);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [assignmentTableData, setAssignmentTableData] = useState([]);

  const [campus, setCampus] = useState("");
  const campusName = useSelector((state) => state.campusName);
  const [optionsCampus, setOptionsCampus] = useState("");
  const [profesor, setProfesor] = useState("");
  const [grupo, setGrupo] = useState("");
  const [ciclo, setCiclo] = useState("");
  const [materia, setMateria] = useState("");

  const [assignmentData, setAssignmentData] = useState({
    ciclo: "",
    clave_direccion: "",
    clave_escuela: "",
    clave_materia: "",
    clave_tipo_grupo: "",
    complementario: "",
    grupo: "",
    materia: "",
    nombre_direccion: "",
    nombre_director: "",
    nombre_escuela: "",
    tipo_asistencia: "",
    tipo_grupo: "",
  });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  async function openModalDetalle(assignmentId) {
    setDetalleModalIsOpen(true);
    setLoadingModalDetalle(true);

    let assignmentUrl = `/asignaciones/detalle?id=${assignmentId}`;
    const assignmentResponse = await getRequest(assignmentUrl);

    let assignmentTableUrl = `/asignaciones/bitacora?id=${assignmentId}&id_profesor=${profesor.value}`;
    const assignmentTableResponse = await getRequest(assignmentTableUrl);

    if (
      assignmentResponse.s === "OK" &&
      assignmentTableResponse.s === "OK" &&
      assignmentResponse.d &&
      assignmentTableResponse.d
    ) {
      const scheduleData = [
        [
          assignmentResponse.d.horario.lunes[0],
          assignmentResponse.d.horario.martes[0],
          assignmentResponse.d.horario.miercoles[0],
          assignmentResponse.d.horario.jueves[0],
          assignmentResponse.d.horario.viernes[0],
          assignmentResponse.d.horario.sabado[0],
          assignmentResponse.d.horario.domingo[0],
        ],
      ];

      setDataHorarioDetalleOriginal(scheduleData);
      setDataHorarioDetalleFinal(scheduleData);

      setAssignmentData({
        ciclo: assignmentResponse.d.ciclo,
        clave_direccion: assignmentResponse.d.clave_direccion,
        clave_escuela: assignmentResponse.d.clave_escuela,
        clave_materia: assignmentResponse.d.clave_materia,
        clave_tipo_grupo: assignmentResponse.d.clave_tipo_grupo,
        complementario: assignmentResponse.d.complementario,
        grupo: assignmentResponse.d.grupo,
        materia: assignmentResponse.d.materia,
        nombre_direccion: assignmentResponse.d.nombre_direccion,
        nombre_director: assignmentResponse.d.nombre_director,
        nombre_escuela: assignmentResponse.d.nombre_escuela,
        tipo_asistencia: assignmentResponse.d.tipo_asistencia,
        tipo_grupo: assignmentResponse.d.tipo_grupo,
      });

      const keys = assignmentTableResponse.d.map((e) => Object.keys(e)[0]);
      const rows = assignmentTableResponse.d[0][keys[0]].length;
      const data = [];

      for (let i = 0; i < rows; i++) {
        data.push(
          assignmentTableResponse.d.reduce((acc, val, index) => {
            acc.push(val[keys[index]][i]);
            return acc;
          }, [])
        );
      }

      setAssignmentTableData(data);
      setLoadingModalDetalle(false);
    } else {
      setLoadingModalDetalle(false);
      setDetalleModalIsOpen(false);
      enqueueSnackbar(assignmentResponse.m, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(5).then((data) => setOptionsCampus(data));
    setCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  async function handleSearch() {
    let valido = true;
    if (campus === "") {
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (ciclo === "") {
      enqueueSnackbar("Falta llenar campo Ciclo", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (profesor === "") {
      enqueueSnackbar("Falta seleccionar Profesor", {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        const datos = [];
        let url = `/asignaciones/bitacora?id_campus=${campus.value}&ciclo=${ciclo}&id_profesor=${profesor.value}`;
        if (grupo) url += `&grupo=${grupo}`;
        if (materia) url += `&materia=${materia}`;
        const response = await getRequest(url);

        if (response.s === "OK") {
          if (response.d.length === 0) {
            enqueueSnackbar("No se encontraron asignaciones que coincidan con la búsqueda", {
              variant: "error", autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
            setData([]);
          }else{
            const nro = response.d[1]["escuela"].length;
            for (let i = 0; i < nro; i++) {
              datos.push([
                response.d[1]["escuela"][i],
                response.d[2]["tipo_curso"][i],
                response.d[3]["tipo_movimiento"][i],
                response.d[4]["clave_profesor"][i],
                response.d[5]["nombre_profesor"][i],
                response.d[6]["grupo"][i],
                response.d[7]["clave_materia"][i],
                response.d[8]["nombre_materia"][i],
                response.d[9]["fecha_creacion"][i],
                response.d[10]["fecha_movimiento"][i],
                response.d[11]["dia"][i],
                response.d[12]["horario"][i],
                response.d[13]["modulo"][i],
                <div>
                  <EditButton
                    onClick={() => openModalDetalle(response.d[0]["id"][i])}
                  >
                    {response.d[14]["acciones"][i]}
                  </EditButton>
                </div>,
              ]);
            }
            setData(datos);
            enqueueSnackbar(response.m, {
              variant: "success",
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
          
        } else {
          enqueueSnackbar(response.m, {
            variant: "error", autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            name: item.nombre,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  return (
    <Contenedor title="Asignaciones / Bitácora">
      <div
        container
        className="inputs-excepciones"
        style={{ position: "relative", zIndex: 9 }}
      >
        <Grid container item xs={12} sm={12} style={{ marginTop: "1%" }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={1} md={1} style={{ marginRight: "-1%" }}>
              <label>Campus(*):</label>
            </Grid>
            <Grid item xs={2} sm={2}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={campus}
                options={campusValores}
                onChange={setCampus}
              />
            </Grid>
            <Grid item xs={2} style={{ marginLeft: "-8.5%", marginRight: "-1%" }}>
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className='filter-input'
                style={{ marginRight: "10px" }}
                onChange={(e) => setCiclo(e.target.value)}
              ></input>
            </Grid>
            <Grid
              item
              xs={1}
              md={2}
              style={{ marginRight: "-1%", marginLeft: "-2.5%" }}
            >
              <label>Clave Profesor(*):</label>
            </Grid>
            <Grid item xs={3} sm={3}>
              <AsyncSelect
                className="select-modal-container input-claveProfesor clave-profesor-index0"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                value={profesor}
                onChange={setProfesor}
                placeholder="Buscar Profesor"
              />
            </Grid>
            <Grid item xs={1} md={1} style={{ marginLeft: "2%" }}>
              <NewButton
                customText="Buscar"
                noIcon
                style={{ padding: "5px 15px" }}
                onClick={handleSearch}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{ marginRight: "-1%" }}
            >
              <label>Grupo:</label>
            </Grid>
            <Grid item xs={1} sm={2}>
              <input
                className="filter-input"
                style={{ width: "100%" }}
                onChange={(e) => setGrupo(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              style={{ marginLeft: "-0.2%", marginRight: "-1%" }}
            >
              <label>Materia:</label>
            </Grid>
            <Grid item xs={1} sm={2}>
              <input
                className="filter-input"
                style={{ width: "100%" }}
                onChange={(e) => setMateria(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "calc(100% - 100px)", marginTop: "2%" }}>
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Detalle Bitácora"
        open={detalleModalIsOpen}
        handleClose={() => setDetalleModalIsOpen(false)}
        loading={loadingModalDetalle}
        handleSubmit={() => {}}
        height={830}
        maxWidth={1500}
        noButtons
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={12}>
              <Grid container style={{ marginTop: "-0.5%" }}>
                <Grid item xs={1} md={1} style={{ marginLeft: "1.3%" }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid xs={2} md={3}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "200px" }}
                    value={assignmentData.ciclo}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "-7.5%" }}>
                  <label>Escuela:</label>
                </Grid>
                <Grid xs={4} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "90px",
                      marginRight: "10px",
                    }}
                    value={assignmentData.clave_escuela}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "305px" }}
                    value={assignmentData.nombre_escuela}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "0.5%" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "1.3%" }}>
                  <label>Dirección:</label>
                </Grid>
                <Grid xs={5} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "200px" }}
                    value={assignmentData.nombre_direccion}
                  />
                </Grid>
                <Grid xs={2} md={2} style={{ marginLeft: "-7.5%" }}>
                  <label>Tipo de Grupo:</label>
                </Grid>
                <Grid xs={3} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "90px",
                      marginRight: "10px",
                    }}
                    value={assignmentData.clave_tipo_grupo}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "305px" }}
                    value={assignmentData.tipo_grupo}
                  />
                </Grid>
                <Grid item xs={2} md={2} style={{ marginLeft: "-13.7%" }}>
                  <label>Tipo Asistencia:</label>
                </Grid>
                <Grid item xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "150px" }}
                    value={assignmentData.tipo_asistencia}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "0.5%" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "1.3%" }}>
                  <label>Grupo:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "200px" }}
                    value={assignmentData.grupo}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "0.8%" }}>
                  <label>Materia:</label>
                </Grid>
                <Grid xs={3} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{
                      width: "90px",
                      marginRight: "10px",
                    }}
                    value={assignmentData.clave_materia}
                  />
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "305px" }}
                    value={assignmentData.materia}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "-5.3%" }}>
                  <label>Complementario:</label>
                </Grid>
                <Grid xs={2} md={2} style={{ marginRight: "3%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "150px" }}
                    value={assignmentData.complementario ? "SI" : "NO"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            height: "65px",
            width: "80%",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "-1%",
          }}
        >
          <DataTable
            headers={headersTablaHorarioDetalle}
            data={dataHorarioDetalleFinal}
            loading={loadingHorarioDetalle}
            centrar={[1, 2, 3, 4, 5, 6, 7]}
            bordes
          />
        </div>

        <div
          style={{
            height: "calc(100% - 275px)",
            width: "89%",
            margin: "auto",
            marginTop: "30px",
          }}
        >
          <p style={{ fontSize: "20px" }}>Detalle:</p>
          <DataTable
            headers={headersTablaDetalle}
            data={assignmentTableData}
            loading={loadingTablaDetalle}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default Bitacora;
