import moment from "moment"
export const calendarioQuincenaHeaders = [
    {
        accessorKey: 'año',
        header: 'Año',
        enableEditing: true,
        size: 10,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'Número Quincena',
        header: 'Número Quincena',
        enableEditing: true,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'fecha_inicio',
        header: 'Fecha Inicio',
        enableEditing: true,
        enableGlobalFilter: true,
        sortingFn: (rowA, rowB, columnId) => {
            return moment(`${rowA.getValue(columnId).split("-")[2]}-${rowA.getValue(columnId).split("-")[1]}-${rowA.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") > moment(`${rowB.getValue(columnId).split("-")[2]}-${rowB.getValue(columnId).split("-")[1]}-${rowB.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") ? 1 : -1
        }
    },
    {
        accessorKey: 'fecha_fin',
        header: 'Fecha Fin',
        enableEditing: true,
        enableGlobalFilter: true,
        sortingFn: (rowA, rowB, columnId) => {
            return moment(`${rowA.getValue(columnId).split("-")[2]}-${rowA.getValue(columnId).split("-")[1]}-${rowA.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") > moment(`${rowB.getValue(columnId).split("-")[2]}-${rowB.getValue(columnId).split("-")[1]}-${rowB.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") ? 1 : -1
        }
    },
    {
        accessorKey: 'fecha_proceso',
        header: 'Fecha Proceso',
        enableEditing: true,
        enableGlobalFilter: true,
        sortingFn: (rowA, rowB, columnId) => {
            return moment(`${rowA.getValue(columnId).split("-")[2]}-${rowA.getValue(columnId).split("-")[1]}-${rowA.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") > moment(`${rowB.getValue(columnId).split("-")[2]}-${rowB.getValue(columnId).split("-")[1]}-${rowB.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") ? 1 : -1
        }
    },
    {
        accessorKey: 'fecha_pago',
        header: 'Fecha Pago',
        enableEditing: true,
        enableGlobalFilter: true,
        sortingFn: (rowA, rowB, columnId) => {
            return moment(`${rowA.getValue(columnId).split("-")[2]}-${rowA.getValue(columnId).split("-")[1]}-${rowA.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") > moment(`${rowB.getValue(columnId).split("-")[2]}-${rowB.getValue(columnId).split("-")[1]}-${rowB.getValue(columnId).split("-")[0]}`).format("YYYY-MM-DD") ? 1 : -1
        }
    },
    {
        accessorKey: 'modificado_por',
        header: 'Modificado por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'modificado',
        header: 'Fecha Modificada',
        enableEditing: false,
        enableGlobalFilter: false,
        sortingFn: (rowA, rowB, columnId) => {
            const firstRow = rowA.getValue(columnId).trim() === "" ? "" : moment(`${rowA.getValue(columnId).split(" ")[0].split("-")[2]}-${rowA.getValue(columnId).split("-")[1]}-${rowA.getValue(columnId).split("-")[0]} ${rowA.getValue(columnId).split(" ")[1]}`).format("YYYY-MM-DD HH:mm:ss")
            const secondRow = rowB.getValue(columnId).trim() === "" ? "" : moment(`${rowB.getValue(columnId).split(" ")[0].split("-")[2]}-${rowB.getValue(columnId).split("-")[1]}-${rowB.getValue(columnId).split("-")[0]} ${rowA.getValue(columnId).split(" ")[1]}`).format("YYYY-MM-DD HH:mm:ss")
            return firstRow > secondRow ? 1 : -1
        }
    },
    {
        accessorKey: 'creado_por',
        header: 'Creado Por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'creado',
        header: 'Fecha Creado',
        enableEditing: false,
        enableGlobalFilter: false,
        sortingFn: (rowA, rowB, columnId) => {
            const firstRow = rowA.getValue(columnId).trim() === "" ? "" : moment(`${rowA.getValue(columnId).split(" ")[0].split("-")[2]}-${rowA.getValue(columnId).split("-")[1]}-${rowA.getValue(columnId).split("-")[0]} ${rowA.getValue(columnId).split(" ")[1]}`).format("YYYY-MM-DD HH:mm:ss")
            const secondRow = rowB.getValue(columnId).trim() === "" ? "" : moment(`${rowB.getValue(columnId).split(" ")[0].split("-")[2]}-${rowB.getValue(columnId).split("-")[1]}-${rowB.getValue(columnId).split("-")[0]} ${rowA.getValue(columnId).split(" ")[1]}`).format("YYYY-MM-DD HH:mm:ss")
            return firstRow > secondRow ? 1 : -1
        }
    },
    {
        accessorKey: 'id',
        header: 'id',
        size: 10,
        enableEditing: false,
        enableGlobalFilter: false
    },
]