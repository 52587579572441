import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import MultiSelect from "../../Utils/Visuales/MultiSelect";
import Contenedor from "../../Utils/Visuales/Contenedor";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { CustomSpinner } from "../../Utils/Loader";
import { ExportCSV } from "../../Utils/Excel";
import { getRequest } from "../../Utils/Funciones/requester";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { useSnackbar } from "notistack";
import "./index.css";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";

function TotalProfCamEsc() {
  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 3,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 4,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      id: "descripcion_materia",
      col: 5,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Persona",
      id: "tipo_persona",
      col: 6,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Ingreso",
      id: "tipo_ingreso",
      col: 7,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 8,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 9,
      width: "15%",
      align: "center",
      fn: null,
    },
    // {
    //   name: "Total de Grupos",
    //   id: "total_grupos",
    //   width: "7%",
    //   col: 10,
    //   align: "center",
    //   fn: null,
    // },
    {
      name: "Total Horas Asignadas",
      id: "total_horas_asignadas",
      col: 10,
      width: "7%",
      align: "center",
      fn: null,
    },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const campusId = useSelector((state) => state.campusId);
  const campusUser = useSelector((state) => state.campusUser);
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [campusSelected, setCampusSelected] = useState(
    campusUser.map((item) => ({
      ...item,
      isActive: item.value.includes(`${campusId}`),
    }))
  );

  const handleChangeOpcionCampus = (selected) => {
    if (selected.label === "Todos") {
      const findTodos = campusSelected.find(
        (campus) => campus.label === "Todos"
      );
      setCampusSelected((prev) =>
        prev.map((campus) => ({ ...campus, isActive: !findTodos.isActive }))
      );
    } else {
      setCampusSelected((prev) => {
        const newPrev = prev.map((campus) => {
          if (campus.value === selected.value) {
            return { ...campus, isActive: !campus.isActive };
          }
          return { ...campus };
        });
        const allActive = newPrev.every(
          (campus) => campus.value === "0" || campus.isActive
        );
        return newPrev.map((campus) => {
          if (campus.label === "Todos") {
            return {
              ...campus,
              isActive: allActive,
            };
          }
          return { ...campus };
        });
      });
    }
  };

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  const DownloadPDF = async () => {
    if (data.length >= 500) {
      enqueueSnackbar("Supera limites de Páginas.", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        grupo: e[3],
        clave_materia: e[4],
        descripcion_materia: e[5],
        tipo_persona: e[6],
        tipo_ingreso: e[7],
        clave_profesor: e[8],
        nombre_profesor: e[9],
        // total_grupos: e[10],
        total_horas_asignadas: e[10],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Reportes Total Horas Asignadas por Profesor, Campus y Escuela`}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_totales_${fecha}_${hora}.pdf`);
    //console.log(showTable)
    setReq(false);
  };

  async function getDataMultiConsulta() {
    const allCampusSelected = campusSelected.filter(
      (campus) => campus.isActive
    );
    let valido = true;
    if (allCampusSelected.length === 0) {
      enqueueSnackbar("No se ha seleccionado ningún campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setButtonDisabled(true);
      try {
        const arrayCampus = allCampusSelected.map((campus) => campus.value);
        setLoading(true);
        const datosFinal = await dataConsulta(arrayCampus);
        setShowTable(true);
        setDataOriginal(datosFinal);
        setData(datosFinal);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
      setButtonDisabled(false);
    }
  }

  async function dataConsulta(id) {
    if (Array.isArray(id)) {
      const multiplesDatos = [];
      for (let llamados = 0; llamados < id.length; llamados++) {
        const consulta = `/reportes/total_horas_asignadas?id=${id[llamados]}`;
        const response = await getRequest(consulta);
        try {
          if (response.s === "OK") {
            // console.log(response.d);
            const nro = response.d[0]["ciclo"].length;
            for (let i = 0; i < nro; i++) {
              multiplesDatos.push([
                response.d[0]["ciclo"][i],
                response.d[1]["campus"][i],
                response.d[2]["escuela"][i],
                response.d[9]["grupo"][i],
                response.d[10]["clave_materia"][i],
                response.d[11]["descripcion_corta"][i],
                response.d[3]["tipo_persona"][i],
                response.d[4]["tipo_ingreso"][i],
                response.d[5]["clave_profesor"][i],
                response.d[6]["nombre_profesor"][i],
                // response.d[7]["total_grupos"][i],
                response.d[8]["total_horas_asignadas"][i],
              ]);
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      return multiplesDatos;
    } else {
      const datos = [];
      const consulta = `/reportes/total_horas_asignadas?id=${id}`;
      const response = await getRequest(consulta);
      try {
        if (response.s === "OK") {
          // console.log(response.d);
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["escuela"][i],
              response.d[9]["grupo"][i],
              response.d[10]["clave_materia"][i],
              response.d[11]["descripcion_corta"][i],
              response.d[3]["tipo_persona"][i],
              response.d[4]["tipo_ingreso"][i],
              response.d[5]["clave_profesor"][i],
              response.d[6]["nombre_profesor"][i],
              // response.d[7]["total_grupos"][i],
              response.d[8]["total_horas_asignadas"][i],
            ]);
          }
          return datos;
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        Ciclo: e[0],
        Campus: e[1],
        Escuela: e[2],
        Grupo: e[3],
        "Clave Materia": e[4],
        "Descripción Materia": e[5],
        "Tipo Persona": e[6],
        "Tipo Ingreso": e[7],
        "Clave Profesor": e[8],
        "Nombre Profesor": e[9],
        // "Total Grupos": e[10],
        "Total Horas Asignadas": e[10],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_totales_${fecha}_${hora}`);
    setReq(false);
  };

  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Total Horas Asignadas por Profesor, Campus y Escuela">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={12}>
            <Grid
              container
              alignItems="center"
              spacing={1}
              style={{ marginTop: "0.5%" }}
            >
              <Grid item xs={1}>
                <label>Campus(*)</label>
              </Grid>
              <Grid item xs={2} className="nuevoUsuarioForm">
                <MultiSelectCampus
                  options={campusSelected}
                  onChange={handleChangeOpcionCampus}
                  value={{
                    value: `${
                      campusSelected.filter((item) => item.isActive).length
                    }`,
                    label: `Total: ${
                      campusSelected.filter((item) => item.isActive).length
                    }`,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ padding: "20px 10px", marginTop: "-3.5%" }}>
          <Grid
            xs={12}
            container
            justifyContent={"flex-end"}
            style={{ marginRight: "3.5%" }}
          >
            <EditButton
              onClick={getDataMultiConsulta}
              disabled={buttonDisabled || loading}
              style={{ padding: "10px 25px", zIndex: 99 }}
            >
              Generar
            </EditButton>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ paddingRight: "50px" }}
        >
          <Grid
            style={{
              backgroundColor: "red",
              display: "flex",
              marginTop: "-1%",
            }}
            className="export-buttons"
          >
            <Typography style={{ marginBottom: "auto" }}>Descargar:</Typography>
            <div>
              <button
                className={"buttonDownload"}
                onClick={DownloadExcel}
                disabled={req}
                variant="contained"
              >
                <img src={excelIcon} alt="excel-icon" />
              </button>
              {/* <button
                className={"buttonDownload"}
                onClick={DownloadPDF}
                disabled={req}
                variant="contained"
              >
                <img src={pdfFileIcon} alt="pdf-file-icon" />
              </button> */}
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 200px)", marginTop: "15px" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
}

export default TotalProfCamEsc;
