import React, { useMemo } from 'react'
import { View, Text } from '@react-pdf/renderer'
import HeaderPDFDefault from "../HeaderPDFDefault";
import { divideArrayIntoParts } from "../Funciones/divideArrayIntoParts";
import ReporetDireccionesAcademicasPDFTable from "../ReporteDireccionesAcademicasPDFTable";

export default function ReporetDireccionesAcademicasPDF({
  title,
  metadata,
  data,
  campus,
  startDate,
  endDate,
  teacherKey = "",
  ciclo
}) {
  const escuelaName = useMemo(() => {
    const name = []
    data.forEach(element => {
      if (!name.includes(element.escuela)) {
        name.push(element.escuela)
      }
    });
    return name
  }, [data])
  return escuelaName.map((item, index) => {
    const dataForTable = data.filter((element) => element.escuela === item)
    const splitDataIntoParts = divideArrayIntoParts(dataForTable, 6)
    return splitDataIntoParts.map((dataTable, index2) => {
      return (
        <View key={`${index}-${index2}`} style={{ height: 495 }}>
          <HeaderPDFDefault title={title} />
          <View style={{ marginTop: 15 }}>
            <View style={{ marginTop: 1 }}>
              {startDate ? (
                <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
                  <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Inicial: {startDate}</Text>
                  {endDate ? (
                    <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Final: {endDate}</Text>
                  ) : null}
                </View>
              ) : null}
              <ReporetDireccionesAcademicasPDFTable
                key={`${index}-${index2}`}
                metadata={metadata}
                data={dataTable}
                teacherKey={teacherKey}
                ciclo={ciclo}
                escuela={item}
                campus={campus}
              />
            </View>
          </View>
        </View>
      )
    })
  })

}
