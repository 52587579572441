import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

function DetalleIncidencias() {
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [optionsTipoIncidencia, setOptionsTipoIncidencia] = useState("");
  const [valueCampus, setValueCampus] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [valueTipoIncidencia, setValueTipoIncidencia] = useState("");
  const [valueTipoReporte, setValueTipoReporte] = useState("incidencia");
  const [valueTipoAsistencia, setValueTipoAsistencia] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [filtroType, setFiltroType] = useState(1);
  const [optionsTipoAsistencia, setOptionsTipoAsistencia] = useState([]);
  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function handleClick() {
    let valido = true;
    if (valueCiclo === "" || valueCiclo === undefined) {
      enqueueSnackbar("Falta llenar campo ciclo ", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaInicio === "" || fechaInicio === undefined) {
      enqueueSnackbar("Falta llenar campo Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaFin === "" || fechaFin === undefined) {
      enqueueSnackbar("Falta llenar campo Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === "" || valueCampus === undefined) {
      enqueueSnackbar("Falta llenar campo Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    let nrofechaInicial = parseInt(
      fechaInicio.substring(0, 4) +
      fechaInicio.substring(5, 7) +
      fechaInicio.substring(8, 10)
    );
    let nrofechaFinal = parseInt(
      fechaFin.substring(0, 4) +
      fechaFin.substring(5, 7) +
      fechaFin.substring(8, 10)
    );
    let nrofechaActual = parseInt(moment().format("YYYYMMDD"));
    if (nrofechaInicial > nrofechaActual) {
      enqueueSnackbar("Fecha Inicial es superior a la Fecha Actual", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (nrofechaFinal > nrofechaActual) {
      enqueueSnackbar("Fecha Final es superior a la Fecha Actual", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (nrofechaInicial > nrofechaFinal) {
      enqueueSnackbar("Fecha Inicial es Superior a la Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        const fechaInicioTime = moment(fechaInicio);
        const fechaFinTime = moment(fechaFin);
        const diff = fechaFinTime.diff(fechaInicioTime, "days") + 1;
        let msgDictamen = "";
        let data = [];
        for (let i = 0; i < diff; i += 1) {
          const fecha =
            i === 0
              ? fechaInicioTime.format("YYYY-MM-DD")
              : fechaInicioTime.add(1, "days").format("YYYY-MM-DD");
          let url = `/reportes/detalle-incidencias`;
          if (valueCiclo) url += `?ciclo=${valueCiclo}`;
          if (fechaInicio) url += `&fecha_inicio=${fecha}`;
          if (fechaFin) url += `&fecha_fin=${fecha}`;
          if (valueCampus.value) url += `&id_campus=${valueCampus.value}`;
          if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
          if (valueClaveProfesor)
            url += `&id_profesor=${valueClaveProfesor.value}`;
          if (valueTipoIncidencia)
            url += `&tipo_incidencia=${valueTipoIncidencia.value}`;
          if (valueTipoAsistencia)
            url += `&tipo_asistencia=${valueTipoAsistencia.value}`;
          if (valueTipoReporte) url += `&tipo_reporte=${valueTipoReporte}`;

          const consulta = url;
          const response = await getRequest(consulta);

          if (response.s === "OK") {
            msgDictamen = response.m;
            response.d.forEach((e) => {
              data.push([
                e.ciclo,
                e.campus,
                e.escuela,
                e.direccion,
                e.tipo_incidencia,
                e.estatus,
                e.hora_incidencia,
                e.tipo_clase,
                e.hora_registro,
                e.autorizado_da,
                e.grupo,
                e.clave_materia,
                e.nombre_materia,
                e.clave_profesor,
                e.nombre_profesor,
                e.comentario,
                e.dia,
                e.fecha,
                e.horario,
                e.fecha_reposicion,
                e.fecha_original,
                e.profesor_original,
                e.creado,
                e.creado_por,
              ]);
            });
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        }
        if (data.length === 0) {
          setData([]);
          setLoading(false);
          enqueueSnackbar("No se han encontrado datos", {
            variant: "info",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData([]);
          setDataOriginal([]);
          setLoading(false);
        } else {
          enqueueSnackbar(msgDictamen, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setData(data);
          setDataOriginal(data);
          setShowTable(true);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    getOptionsIncidencia(30).then((data) => setOptionsTipoIncidencia(data));
    getOptionsAsistencia(1).then((data) => setOptionsTipoAsistencia(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const getOptionsIncidencia = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        //console.log(response.d.elementos);
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let name = response.d.elementos[2].name;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${name[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const getOptionsAsistencia = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let name = response.d.elementos[2].name;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${id[i]} - ${name[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const headers = [
    {
      name: "Ciclo",
      id: "Ciclo",
      col: 0,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "Campus",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "Escuela",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "Direccion",
      col: 3,
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de incidencia",
      id: "Tipo_Incidencia",
      col: 4,
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Estatus",
      id: "Estatus",
      col: 5,
      width: "13%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Captura",
      id: "Hora_Captura",
      col: 6,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Clase",
      id: "Tipo_Clase",
      col: 7,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Registro",
      id: "Hora_Registro",
      col: 8,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Autorizado por DA",
      id: "Autorizado_por_DA",
      col: 9,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "Grupo",
      col: 10,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "Clave_Materia",
      col: 11,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Materia",
      id: "Nombre_Materia",
      col: 12,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "Clave_Profesor",
      col: 13,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "Nombre_Profesor",
      col: 14,
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Comentario",
      id: "Comentario",
      col: 15,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Día Clase",
      id: "Dia_Clase",
      col: 16,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Clase",
      id: "Fecha_Clase",
      col: 17,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      id: "Horario",
      col: 18,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Reposición",
      id: "fecha_reposicion",
      col: 19,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Original",
      id: "fecha_orignal",
      col: 20,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Profesor Original",
      id: "profesor_riginal",
      col: 21,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Creado",
      id: "Creado",
      col: 22,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Creado_por",
      id: "Creado_por",
      col: 23,
      width: "15%",
      align: "center",
      fn: null,
    },
  ];

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        Ciclo: e[0],
        Campus: e[1],
        Escuela: e[2],
        Direccion: e[3],
        Tipo_Incidencia: e[4],
        Estatus: e[5],
        Hora_Captura: e[6],
        Tipo_Clase: e[7],
        Hora_Registro: e[8],
        Autorizado_por_DA: e[9],
        Grupo: e[10],
        Clave_Materia: e[11],
        Nombre_Materia: e[12],
        Clave_Profesor: e[13],
        Nombre_Profesor: e[14],
        Comentario: e[15],
        Dia_Clase: e[16],
        Fecha_Clase: e[17],
        Horario: e[18],
        "Fecha Original": e[19],
        "Profesor Original": e[20],
        Creado: e[21],
        "Creado Por": e[22],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={
          headers.filter(
            (element) => element.name !== "Campus" && element.name !== "Ciclo"
          ) ?? []
        }
        data={data_ ?? []}
        title={`Reportes Detalle de Incidencias`}
        campus={valueCampus.label}
        startDate={fechaInicio}
        endDate={fechaFin}
        ciclo={valueCiclo}
        teacherKey={
          valueClaveProfesor.value === undefined ? "" : valueClaveProfesor.label
        }
        rows={4}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_detalle_incidencias_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        Ciclo: e[0],
        Campus: e[1],
        Escuela: e[2],
        Direccion: e[3],
        Tipo_Incidencia: e[4],
        Estatus: e[5],
        Hora_Captura: e[6],
        Tipo_Clase: e[7],
        Hora_Registro: e[8],
        Autorizado_por_DA: e[9],
        Grupo: e[10],
        Clave_Materia: e[11],
        Nombre_Materia: e[12],
        Clave_Profesor: e[13],
        Nombre_Profesor: e[14],
        Comentario: e[15],
        Dia_Clase: e[16],
        Fecha_Clase: e[17],
        Horario: e[18],
        Fecha_Reposicion: e[19],
        Fecha_Original: e[20],
        Profesor_Original: e[21],
        Creado: e[22],
        Creado_Por: e[23],
      };
      return data2;
    });

    var today = new Date();
    var fecha = `${today.getFullYear()}${today.getMonth() + 1
      }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_detalle_incidencias_${fecha}_${hora}`);
    setReq(false);
  };

  function handleChangeTipo(ev) {
    if (ev.target.value === "incidencia") {
      setFiltroType(1);
      setValueTipoReporte("incidencia");
      setValueTipoAsistencia("");
    } else {
      setFiltroType(2);
      setValueTipoReporte("asistencia");
      setValueTipoIncidencia("");
    }
  }
  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Detalle de Incidencias">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <input
                  className="filter-input"
                  value={valueCiclo}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Fecha Inicio(*):</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <input
                  className="filter-input"
                  label="Seleccionar fecha"
                  type="date"
                  value={fechaInicio}
                  onChange={(e) => setFechaInicio(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  max={moment().format("YYYY-MM-DD")}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Fecha Fin(*):</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <input
                  className="filter-input"
                  label="Seleccionar fecha"
                  type="date"
                  disabled={fechaInicio !== "" ? false : true}
                  value={fechaFin}
                  onChange={(e) => setFechaFin(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  min={fechaInicio}
                  max={moment().format("YYYY-MM-DD")}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
                <label>Campus(*):</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Select
                  className="select-modal-container input-campus"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusValores}
                  onChange={setValueCampus}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Tipo asistencia:</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueTipoAsistencia}
                  options={optionsTipoAsistencia}
                  onChange={setValueTipoAsistencia}
                  isDisabled={filtroType === 1 ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Profesor:</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <AsyncSelect
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  styles={asyncSelectStyles}
                  loadOptions={promiseOptions}
                  onChange={setValueClaveProfesor}
                  placeholder="Buscar Profesor"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Tipo Reporte (*):</label>
              </Grid>
              <Grid item xs={12} sm={2}>
                <form className="radio-form" style={{ marginLeft: 0 }}>
                  <fieldset>
                    <div>
                      <div
                        onChange={handleChangeTipo}
                        style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex" }}>
                          <input
                            type="radio"
                            id="incidencia"
                            name="tipo-registro"
                            value="incidencia"
                            checked={filtroType === 1}
                          />
                          <label for="incidencia">Incidencia</label>
                        </div>
                        <div style={{ display: "flex" }}>
                          <input
                            type="radio"
                            id="asistencia"
                            name="tipo-registro"
                            value="asistencia"
                            checked={filtroType === 2}
                          />
                          <label for="asistencia">Asistencia</label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Tipo incidencia:</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueTipoIncidencia}
                  options={optionsTipoIncidencia}
                  onChange={setValueTipoIncidencia}
                  isDisabled={filtroType === 2 ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} spacing={2} style={{ paddingRight: "50px" }}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}>
              <EditButton
                onClick={handleClick}
                disabled={loading}
                style={{ padding: "10px 25px" }}>
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained">
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  {/* <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained">
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 190px)" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
}

export default DetalleIncidencias;
