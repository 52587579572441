import React from "react";
import { Grid } from "@material-ui/core";

const HoursInput = ({
  label,
  inputValue,
  disabled = true,
  onChange = () => {},
}) => {
  const [hours, minutes] = inputValue.split(":");

  function handleInputChange(event, type) {
    const number = parseInt(event.target.value) || 0;
    const value = number < 10 ? `0${number}` : `${number}`;
    if (type === "hours" && number < 25) {
      onChange(value, minutes);
    } else if (type === "minutes" && number < 60) {
      onChange(hours, value);
    }
  }

  return (
    <Grid container style={{ marginTop: 20 }} alignItems="center">
      <Grid
        item
        sm={3}
        style={{
          textAlign: "center",
          marginRight: 20,
        }}
      >
        <label>{label}:</label>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <input
          className="filter-input input-ciclo"
          placeholder=""
          value={hours}
          disabled={disabled}
          onChange={(e) => {
            handleInputChange(e, "hours");
          }}
          style={{ maxWidth: 125 }}
        />
        <span style={{ marginLeft: 10, marginRight: 10, marginBottom: 4 }}>
          :
        </span>
        <input
          className="filter-input input-ciclo"
          placeholder=""
          value={minutes}
          disabled={disabled}
          onChange={(e) => {
            handleInputChange(e, "minutes");
          }}
          style={{ maxWidth: 125 }}
        />
      </Grid>
    </Grid>
  );
};

export default HoursInput;
