import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";

import Contenedor from "../../Utils/Visuales/Contenedor";
import SearchInput from "../../Utils/Visuales/SearchInput";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import NewModal from "../../Utils/Visuales/NewModal";
import styles from "./styles.module.css";
import { getRequest } from "../../Utils/Funciones/requester";

function Profesores() {
  const [loading, setLoading] = useState(false);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [modalData, setModalData] = useState({
    clave: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    sexo: "",
    fechaAlta: "",
    correo: "",
    telefono: "",
    nivelEstudios: "",
    tipoProfesor: "",
    tipoPersona: "",
    vigencia: "",
    curp: "",
  });

  const { enqueueSnackbar } = useSnackbar();

  // Limpiar campos cada vez que se abre o se cierra el modal
  useEffect(() => {
    setModalData({
      clave: "",
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      sexo: "",
      fechaAlta: "",
      correo: "",
      telefono: "",
      nivelEstudios: "",
      tipoProfesor: "",
      tipoPersona: "",
      vigencia: "",
      curp: "",
    });
  }, [modalIsOpen]);

  async function openModalConsultar(id) {
    setModalIsOpen(true);

    setModalIsLoading(true);
    try {
      const response = await getRequest(`/docentes?id=${id}`);
      if (response.s === "OK") {
        setModalData({
          clave: response.d[0]["nss"],
          nombre: response.d[0]["name"],
          apellidoPaterno: response.d[0]["first_last_name"],
          apellidoMaterno: response.d[0]["second_last_name"],
          fechaNacimiento: response.d[0]["fecha_nacimiento"],
          sexo: response.d[0]["gender"],
          fechaAlta: response.d[0]["fecha_alta"],
          correo: response.d[0]["email"],
          telefono: response.d[0]["telephone_number"],
          nivelEstudios: response.d[0]["nivel_estudio"],
          tipoProfesor: response.d[0]["tipo_profesor"],
          tipoPersona: response.d[0]["tipo_persona"],
          vigencia: response.d[0]["vigencia"],
          curp: response.d[0]["CURP"],
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      setModalIsOpen(true);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setModalIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }

  const headers = [
    {
      name: "Clave",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "Acciones",
      col: 2,
    },
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const consulta = `/docentes`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        let data = [];
        response.d.forEach((profesor) => {
          data.push([
            profesor.nss,
            profesor.name,
            <div I='edit' a='profesores'>
              <EditButton
                onClick={() => {
                  openModalConsultar(profesor.id);
                }}
              >
                Consultar
              </EditButton>
            </div>,
          ]);
        });
        setDataOriginal(data);
        setDataFinal(data);
        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error", autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error", autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  return (
    <Contenedor title='Profesores'>
      <div className={styles.opcionesBar}>
        <div style={{ float: "right" }}>
          <SearchInput hideIcon search={search} />
        </div>
      </div>
      <div style={{ height: "calc(100% - 65px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3]}
          paginate
          bordes
          moreCells={8}
        />
      </div>
      <NewModal
        title='Datos de Profesor'
        open={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
        loading={modalIsLoading}
        height={600}
        noSaveBtn
      >
        <div className={styles.modalContentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label>Clave profesor</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.clave}
              />
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={4}>
              <label>Nombre</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Apellido Paterno</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.apellidoPaterno}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Apellido Materno</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.apellidoMaterno}
              />
            </Grid>

            {/* <Grid item xs={4}>
              <label>Fecha de Nacimiento</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.fechaNacimiento}
              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <label>Sexo</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.sexo}
              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <label>Fecha de Alta</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.fechaAlta}
              />
            </Grid> */}

            <Grid item xs={8}>
              <label>Correo electronico</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.correo}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <label>Telefono</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.telefono}
              />
            </Grid> */}
            <Grid item xs={4} />
            <Grid item xs={4}>
              <label>Nivel de estudios</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.nivelEstudios}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Tipo de Persona</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.tipoPersona}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <label>Vigencia</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.vigencia}
              />
            </Grid> */}

            {/* <Grid item xs={4}>
              <label>CURP</label>
              <input
                disabled
                className={styles.filterInput}
                value={modalData.curp}
              />
            </Grid> */}
            <Grid item xs={8} />
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default Profesores;
