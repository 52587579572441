import { Grid, Typography } from "@material-ui/core";
import SearchInput from "../Visuales/SearchInput";
import moment from "moment";

const SearchBarBox = ({ showTable, handleSearch }) => {
  return (
    <Grid
      container
      style={{ marginTop: 30, marginBottom: 20, alignItems: "baseline" }}
    >
      <Grid item xs={12} md={5}>
        <Typography style={{ fontWeight: "bold" }}>
          Fecha Reporte:
          {showTable && (
            <>
              {moment().format("DD/MM/YYYY") +
                " - " +
                moment().format("HH:mm:ss")}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item md={3} style={{ marginLeft: "auto" }}>
        <SearchInput hideIcon search={(e) => handleSearch(e)} />
      </Grid>
    </Grid>
  );
};

export default SearchBarBox;
