export const removeHearsFromPDF= (ciclo, campus, escuela, direccion, teacherKey, tipoIncidencias, originalHeaders) => {
    const headersNeedToRemove = []
    if (ciclo !== "" && ciclo !== undefined) {
        headersNeedToRemove.push("Ciclo")
    }
    if (campus !== "" && ciclo !== undefined) {
        headersNeedToRemove.push("Campus")
    }
    if (escuela !== "" && escuela !== undefined) {
        headersNeedToRemove.push("Escuela")
    }
    if (direccion !== "" && direccion !== undefined) {
        headersNeedToRemove.push("Dirección")
    }
    if (teacherKey !== "" && teacherKey !== undefined) {
        headersNeedToRemove.push("Nombre Profesor")
        headersNeedToRemove.push("Clave Profesorr")
    }
    if (tipoIncidencias !== "" && tipoIncidencias !== undefined) {
        headersNeedToRemove.push("Incidencia")
    }

    const newHeaders = originalHeaders.filter((item) => !headersNeedToRemove.includes(item.name))
    return newHeaders
}