import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import AsyncSelect from "react-select/async";
import moment from "moment";
import Select from "react-select";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { useEffect } from "react";

function BitacoraAsignacion() {
  const headers = [
    {
      name: "Campus",
      id: "campus",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Ciclo",
      id: "ciclo",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Curso",
      id: "tipo_curso",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 3,
      width: "18%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 4,
      width: "19%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Profesor",
      id: "tipo_profesor",
      col: 5,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 6,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 7,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Materia",
      id: "materia",
      col: 8,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Movimiento",
      id: "tipo_movimiento",
      col: 9,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Grupo",
      id: "tipo_grupo",
      col: 10,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo de Asistencia",
      id: "tipo_asistencia",
      col: 11,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Creación",
      id: "fecha_creacion",
      col: 12,
      width: "9%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Movimiento",
      id: "fecha_movimiento",
      col: 13,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Fin Clase",
      id: "fecha_fin_clase",
      col: 14,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Día",
      id: "dia",
      col: 15,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Horario",
      id: "horario",
      col: 16,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Módulo",
      id: "modulo",
      col: 17,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 18,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 19,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Carrera",
      id: "carrera",
      col: 20,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Usuario Creado",
      id: "usuario_creado",
      col: 21,
      width: "10%",
      align: "center",
      fn: null,
    },
  ];

  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [valueGrupo, setValueGrupo] = useState("");
  const [valueCiclo, setValueCiclo] = useState("");
  const [valueMateria, setValueMateria] = useState("");
  const [valueClaveProfesor, setValueClaveProfesor] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const campusName = useSelector((state) => state.campusName);
  const [campus, setCampus] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [disabledButton, setDisabledButton] = useState(false);

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      else if (row[3].toLowerCase().includes(text)) return true;
      else if (row[4].toLowerCase().includes(text)) return true;
      else if (row[5].toLowerCase().includes(text)) return true;
      else if (row[6].toLowerCase().includes(text)) return true;
      else if (row[7].toLowerCase().includes(text)) return true;
      else if (row[8].toLowerCase().includes(text)) return true;
      else if (row[9].toLowerCase().includes(text)) return true;
      else if (row[10].toLowerCase().includes(text)) return true;
      else if (row[11].toLowerCase().includes(text)) return true;
      else if (row[12].toLowerCase().includes(text)) return true;
      else if (row[13].toLowerCase().includes(text)) return true;
      else if (row[14].toLowerCase().includes(text)) return true;
      else if (row[15].toLowerCase().includes(text)) return true;
      else if (row[16].toLowerCase().includes(text)) return true;
      else if (row[17]?.toLowerCase().includes(text)) return true;
      else if (row[18].toLowerCase().includes(text)) return true;
      else if (row[19].toLowerCase().includes(text)) return true;
      else if (row[20].toLowerCase().includes(text)) return true;
      else if (row[21].toLowerCase().includes(text)) return true;
      return false;
    });
    setData(result);
  }

  async function handleClick() {
    let valido = true;
    if (fechaInicio === "") {
      enqueueSnackbar("Falta llenar campo fecha inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaFin === "") {
      enqueueSnackbar("Falta llenar campo fecha fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    let nrofechaInicial = parseInt(
      fechaInicio.substring(0, 4) +
        fechaInicio.substring(5, 7) +
        fechaInicio.substring(8, 10)
    );
    let nrofechaFinal = parseInt(
      fechaFin.substring(0, 4) +
        fechaFin.substring(5, 7) +
        fechaFin.substring(8, 10)
    );
    if (nrofechaInicial > nrofechaFinal) {
      enqueueSnackbar("Fecha Inicio es superior a Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (valido) {
      setLoading(true);
      const fechaInicioTime = moment(fechaInicio);
      const fechaFinTime = moment(fechaFin);
      const diff = fechaFinTime.diff(fechaInicioTime, "days") + 1;
      // setDisabledButton(true);
      let msgBitacora = "";
      let datos = [];
      for (let i = 0; i < diff; i += 1) {
        const fecha =
          i === 0
            ? fechaInicioTime.format("YYYY-MM-DD")
            : fechaInicioTime.add(1, "days").format("YYYY-MM-DD");
        try {
          let url = `/reportes/bitacora_de_asignacion?fi=${fecha}&ff=${fecha}`;
          if (valueGrupo) url += `&grupo=${valueGrupo}`;
          if (valueClaveProfesor)
            url += `&id_profesor=${valueClaveProfesor.value}`;
          if (valueMateria) url += `&materia=${valueMateria}`;
          if (campus?.value !== undefined) url += `&campus=${campus?.value}`;
          if (valueCiclo) url += `&ciclo=${valueCiclo}`;
          const resquest = url;
          const response = await getRequest(resquest);
          if (response.s === "OK") {
            msgBitacora = response.m;
            const nro = response.d[0]?.["ciclo"]?.length;
            for (let j = 0; j < nro; j++) {
              datos.push([
                response.d[20]["campus"][j],
                response.d[0]["ciclo"][j],
                response.d[1]["tipo_curso"][j],
                response.d[2]["clave_profesor"][j],
                response.d[3]["nombre_profesor"][j],
                response.d[4]["tipo_profesor"][j],
                response.d[5]["grupo"][j],
                response.d[6]["clave_materia"][j],
                response.d[7]["materia"][j],
                response.d[8]["tipo_movimiento"][j],
                response.d[9]["tipo_grupo"][j],
                response.d[10]["tipo_asistencia"][j],
                response.d[11]["fecha_creacion"][j],
                response.d[12]["fecha_movimiento"][j],
                response.d[13]["fecha_fin"][j],
                response.d[14]["dia"][j],
                response.d[15]["horario"][j],
                response.d[16]["modulo"][j],
                response.d[17]["escuela"][j],
                response.d[18]["direccion"][j],
                response.d[19]["carrera"][j],
                response.d[21]["creado_por"][j],
              ]);
            }
          } else {
            enqueueSnackbar(response.m, {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      if (datos.length === 0) {
        setData([]);
        setLoading(false);
        enqueueSnackbar("No se han encontrado datos", {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(msgBitacora, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        const newDatos = datos
          .sort((a, b) => (a[12] > b[12] ? 1 : b[12] > a[12] ? -1 : 0))
          .map((element) => {
            const newElement = element;
            newElement[13] = moment(`${element[13]}`).format("DD/MM/YYYY");
            return newElement;
          });
        setData(newDatos);
        setDataOriginal(newDatos);
      }
      setLoading(false);
      setShowTable(true);
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;
  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        campus: e[0],
        ciclo: e[1],
        tipo_curso: e[2],
        clave_profesor: e[3],
        nombre_profesor: e[4],
        tipo_profesor: e[5],
        grupo: e[6],
        clave_materia: e[7],
        materia: e[8],
        tipo_movimiento: e[9],
        tipo_grupo: e[10],
        tipo_asistencia: e[11],
        fecha_creacion: e[12],
        fecha_movimiento: e[13],
        fecha_fin_clase: e[14],
        dia: e[15],
        horario: e[16],
        modulo: e[17],
        escuela: e[18],
        direccion: e[19],
        carrera: e[20],
        usuario_creado: e[21],
      };
      return data2;
    });
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Reporte Bitácora de Asignación`}
        campus={campus.label}
        startDate={fechaInicio}
        endDate={fechaFin}
        teacherKey={
          valueClaveProfesor.value === undefined ? "" : valueClaveProfesor.label
        }
        rows={3}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_bitacora_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        campus: e[0],
        ciclo: e[1],
        tipo_curso: e[2],
        clave_profesor: e[3],
        nombre_profesor: e[4],
        tipo_profesor: e[5],
        grupo: e[6],
        clave_materia: e[7],
        materia: e[8],
        tipo_movimiento: e[9],
        tipo_grupo: e[10],
        tipo_asistencia: e[11],
        fecha_creacion: e[12],
        fecha_movimiento: e[13],
        fecha_fin_clase: e[14],
        dia: e[15],
        horario: e[16],
        modulo: e[17],
        escuela: e[18],
        direccion: e[19],
        carrera: e[20],
        usuario_creado: e[21],
      };
      return data2;
    });

    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_bitacora_${fecha}_${hora}`);
    setReq(false);
  };

  async function handleCampus(e) {
    setCampus(e);
  }

  useEffect(() => {
    setCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Bitácora de Asignación">
        <Grid
          container
          style={{ position: "relative", zIndex: 9, marginLeft: 10 }}
        >
          <Grid container item xs={12} sm={9}>
            <Grid container spacing={2}>
              <Grid
                style={{
                  width: 320,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                <Grid item xs={12} style={{ textAlign: "initial", width: 120 }}>
                  <label>Fecha Inicio(*):</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "initial" }}>
                  <input
                    // ref={startDateInputRef}
                    // onClick={handleIStartDateDateClick}
                    className="filter-input"
                    type="date"
                    value={fechaInicio}
                    onChange={(e) => {
                      setFechaInicio(e.target.value);
                      setFechaFin("");
                    }}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  width: 320,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                <Grid item xs={12} style={{ textAlign: "initial", width: 120 }}>
                  <label>Fecha Fin(*):</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "initial" }}>
                  <input
                    // ref={endDateInputRef}
                    className="filter-input"
                    type="date"
                    value={fechaFin}
                    onChange={(e) => setFechaFin(e.target.value)}
                    min={fechaInicio}
                    disabled={fechaInicio !== "" ? false : true}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  width: 320,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                <Grid item xs={12} style={{ textAlign: "initial", width: 120 }}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "initial" }}>
                  <input
                    className="filter-input"
                    value={valueCiclo}
                    onChange={(e) => setValueCiclo(e.target.value)}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  width: 320,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                  marginTop: 20,
                }}
              >
                <Grid item xs={12} style={{ textAlign: "initial", width: 120 }}>
                  <label>Grupo:</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "initial" }}>
                  <input
                    className="filter-input"
                    value={valueGrupo}
                    onChange={(e) => setValueGrupo(e.target.value)}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  width: 320,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                  zIndex: 9999,
                  marginTop: 20,
                }}
              >
                <Grid item xs={12} style={{ textAlign: "initial", width: 120 }}>
                  <label>Campus:</label>
                </Grid>
                <Grid style={{ width: 530 }}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    options={campusValores}
                    value={campus}
                    onChange={(e) => handleCampus(e)}
                    isClearable={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 10 }}>
              <Grid
                style={{
                  width: 662,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                  zIndex: 999,
                  marginTop: 40,
                }}
              >
                <Grid style={{ textAlign: "initial", width: 145 }}>
                  <label>Clave Profesor:</label>
                </Grid>
                <Grid style={{ width: "100%" }}>
                  <AsyncSelect
                    classNamePrefix="select-modal"
                    styles={asyncSelectStyles}
                    loadOptions={promiseOptions}
                    onChange={setValueClaveProfesor}
                    value={valueClaveProfesor}
                    placeholder="Buscar Profesor"
                    isClearable={true}
                  />
                </Grid>
              </Grid>
              <Grid
                style={{
                  width: 320,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 40,
                }}
              >
                <Grid item xs={12} style={{ textAlign: "initial", width: 120 }}>
                  <label>Materia:</label>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "initial" }}>
                  <input
                    className="filter-input"
                    value={valueMateria}
                    maxLength={10}
                    onChange={(e) => setValueMateria(e.target.value)}
                    style={{ width: 200 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={3} spacing={2}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClick}
                disabled={disabledButton || loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div
                style={{ display: "flex", marginLeft: "10px" }}
                className="export-buttons"
              >
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  {/* <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {" "}
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(100% - 175px)" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
}

export default BitacoraAsignacion;
