import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import Contenedor from "../../Utils/Visuales/Contenedor";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { ExportCSV } from "../../Utils/Excel";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { removeHearsFromPDF } from "../../Utils/Funciones/removeHearsFromPDF";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ComentariosDePrefectura = () => {
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const { enqueueSnackbar } = useSnackbar();
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const campusName = useSelector((state) => state.campusName);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueCampus, setValueCampus] = useState("");
  const [optionsIncidencia, setOptionsIncidencia] = useState([]);
  const [valueIncidencia, setValueIncidencia] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [fechaInicioValue, setFechaInicioValue] = useState("");
  const [fechaFinValue, setFechaFinValue] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueEscuela, setValueEscuela] = useState("");
  const [profesorValue, setProfesorValue] = useState("");
  const [ciclo, setCiclo] = useState("");

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 3,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Incidencia",
      id: "incidencia",
      col: 4,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 5,
      fn: null,
      width: "18%",
      align: "center",
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 6,
      fn: null,
      width: "19%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 7,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 8,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Nombre Materia",
      id: "nombre_materia",
      col: 9,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Día",
      id: "dia",
      col: 10,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 11,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Horario",
      id: "horario",
      col: 12,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Hora Registro",
      id: "hora_registro",
      col: 13,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Salón",
      id: "salon",
      col: 14,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Comentario",
      id: "comentario",
      col: 15,
      fn: null,
      width: "15%",
      align: "center",
    },
  ];

  const headersPDF = [
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 5,
      fn: null,
      width: "10%",
      align: "center",
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 6,
      fn: null,
      width: "20%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 7,
      fn: null,
      width: "10%",
      align: "center",
    },
    {
      name: "Materia",
      id: "clave_materia",
      col: 8,
      fn: null,
      width: "10%",
      align: "center",
    },
    {
      name: "Descripción Materia",
      id: "nombre_materia",
      col: 9,
      fn: null,
      width: "20%",
      align: "center",
    }, 
    {
      name: "Día",
      id: "dia",
      col: 10,
      fn: null,
      width: "12%",
      align: "center",
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 11,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Hora",
      id: "horario",
      col: 12,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Hora Registro",
      id: "hora_registro",
      col: 13,
      fn: null,
      width: "10%",
      align: "center",
    },
    {
      name: "Salón",
      id: "salon",
      col: 14,
      fn: null,
      width: "10%",
      align: "center",
    },
    {
      name: "Comentario",
      id: "comentario",
      col: 15,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Tipo de Incidencia",
      id: "incidencia",
      col: 4,
      fn: null,
      width: "15%",
      align: "center",
    },
  ];

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      //console.log(consulta)
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 8 }),
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    getOptionsIncidencia(30).then((data) => setOptionsIncidencia(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const getOptionsIncidencia = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        //console.log(response.d.elementos);
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let name = response.d.elementos[2].name;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${name[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function handleClick() {
    let valido = true;
    if (fechaInicioValue === "") {
      enqueueSnackbar("Debe ingresar Fecha Inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (fechaFinValue === "") {
      enqueueSnackbar("Debe ingresar Fecha Fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }

    if (valido) {
      setLoading(true);
      try {
        const datos = [];
        let url = `/reportes/comentarios_prefectura?fi=${fechaInicioValue}&ff=${fechaFinValue}`;
        if (ciclo) url += `&ciclo=${ciclo}`;
        if (valueCampus) url += `&id_campus=${valueCampus.value}`;
        if (valueEscuela) url += `&id_escuela=${valueEscuela.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        if (profesorValue) url += `&id_profesor=${profesorValue.value}`;
        if (valueIncidencia)
          url += `&id_tipo_incidencia=${valueIncidencia.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          // setTotalData(nro);
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["escuela"][i],
              response.d[3]["direccion"][i],
              response.d[4]["incidencia"][i],
              response.d[5]["clave_profesor"][i],
              response.d[6]["nombre_profesor"][i],
              response.d[7]["grupo"][i],
              response.d[8]["clave_materia"][i],
              response.d[9]["nombre_materia"][i],
              response.d[10]["dia"][i],
              response.d[11]["fecha"][i],
              response.d[12]["horario"][i],
              response.d[13]["hora_registro"][i],
              response.d[14]["salon"][i],
              response.d[15]["comentario"][i],
            ]);
          }

          setData(datos);
          setDataOriginal(datos);
          setShowTable(true);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        //console.log(error);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoading(false);
    }
  }

  const DownloadPDF = async () => {
    setReq(true);
    const date = moment().format("DD/MM/YYYY");
    const hour = moment().format("HH:mm:ss");
    let dataPDF = data.map((e) => {
      const dataPDF2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        incidencia: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        grupo: e[7],
        clave_materia: e[8],
        nombre_materia: e[9],
        dia: e[10],
        fecha: e[11],
        horario: e[12],
        hora_registro: e[13],
        salon: e[14],
        comentario: e[15],
        // total_general: ""
      };
      return dataPDF2;
    });
    const doc = (
      <DocumentPDFTable
        metadata={headersPDF}
        data={dataPDF ?? []}
        title={`Reporte Comentarios de Prefectura`}
        prefectura={true}
        startDate={fechaInicioValue}
        endDate={fechaFinValue}
        ciclo={ciclo}
        campus={valueCampus?.value}
        // escuela={valueEscuela?.value}
        // direccion={valueDireccion?.value}
        // teacherKey={profesorValue?.label}
        // tipoIncidencias={valueIncidencia?.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_prefactura_${date}_${hour}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    let data_ = data.map((e) => {
      const dataPDF2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        incidencia: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        grupo: e[7],
        clave_materia: e[8],
        nombre_materia: e[9],
        dia: e[10],
        fecha: e[11],
        horario: e[12],
        hora_registro: e[13],
        salon: e[14],
        comentario: e[15],
        // total_general: ""
      };
      return dataPDF2;
    });
    // data_[0].total_general = totalData;
    const fecha = moment().format("L");
    const hora = moment().format("LTS");
    ExportCSV(data_, `Reporte_comentarios_${fecha}_${hora}`);
    setReq(false);
  };

  return (
    <Contenedor title="Reportes / Comentarios de Prefectura">
      <Grid
        container
        className="nuevoUsuarioForm"
        style={{ position: "relative", zIndex: 9 }}
      >
        <Grid container item xs={12} sm={12} style={{ marginLeft: "-1.4%" }}>
          {/* Primer Fila */}
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              xs={10}
              sm={1}
              style={{ textAlign: "initial", marginRight: "0.9%" }}
            >
              <label>Ciclo:</label>
            </Grid>
            <Grid item xs={14} sm={2}>
              <input
                className="filter-input"
                style={{ width: "130%" }}
                value={ciclo}
                onChange={(e) => setCiclo(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{ textAlign: "end", marginLeft: "-3.3%" }}
            >
              <label>Fecha Inicio(*):</label>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{ marginLeft: "-1.1%", zIndex: 99 }}
            >
              <input
                className="filter-input"
                type="date"
                onChange={(e) => setFechaInicioValue(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                max={moment().format("YYYY-MM-DD")}
                style={{ zIndex: 99 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              style={{
                textAlign: "end",
                marginLeft: "-9%",
                marginRight: "-1%",
              }}
            >
              <label>Fecha Fin(*):</label>
            </Grid>
            <Grid item xs={12} sm={2} style={{ zIndex: 99 }}>
              <input
                className="filter-input"
                type="date"
                onChange={(e) => setFechaFinValue(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                max={moment().format("YYYY-MM-DD")}
                style={{ zIndex: 99 }}
              />
            </Grid>
          </Grid>
          {/* Segunda Fila */}
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              xs={10}
              sm={1}
              style={{ textAlign: "initial", marginRight: "0.9%" }}
            >
              <label>Campus:</label>
            </Grid>
            <Grid item xs={16} sm={2} style={{ zIndex: 12 }}>
              <Select
                className={styles.primeros}
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueCampus}
                options={campusValores}
                onChange={setValueCampus}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              style={{ textAlign: "end", marginRight: "-1%", marginLeft: "5%" }}
            >
              <label>Escuela:</label>
            </Grid>
            <Grid item xs={14} sm={2} style={{ zIndex: 13 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueEscuela}
                options={optionsEscuela}
                onChange={setValueEscuela}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={1}
              style={{ textAlign: "end", marginLeft: "-1.7%" }}
            >
              <label>Dirección:</label>
            </Grid>
            <Grid item xs={12} sm={2} style={{ zIndex: 10 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueDireccion}
                options={optionsDireccion}
                onChange={setValueDireccion}
              />
            </Grid>
          </Grid>
          {/* Tercera Fila */}
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={2} style={{ marginRight: "-7.4%" }}>
              <label>Clave Profesor:</label>
            </Grid>
            <Grid item xs={16} sm={2}>
              <AsyncSelect
                className={styles.primeros}
                classNamePrefix="select-modal"
                isClearable={true}
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                onChange={setProfesorValue}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={1}
              className="textEnd"
              style={{ marginLeft: "4.8%", marginRight: "-0.8%" }}
            >
              <label>Tipo de Incidencia:</label>
            </Grid>
            <Grid item xs={12} sm={2} style={{ zIndex: 12 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueIncidencia}
                options={optionsIncidencia}
                onChange={setValueIncidencia}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={3}
          spacing={2}
          style={{ marginLeft: "-23.5%" }}
        >
          <Grid
            container
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              gap: 16,
            }}
          >
            <EditButton onClick={handleClick} style={{ padding: "10px 25px" }} disabled={loading}>
              Generar
            </EditButton>
            <div style={{ display: "flex" }} className="export-buttons">
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button
                  onClick={DownloadExcel}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={excelIcon} alt="excel-icon" />
                </button>
                <button
                  onClick={DownloadPDF}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={pdfFileIcon} alt="pdf-file-icon" />
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 180px)" }}>
        {/* 280px cuando tenga los totales comentados abajo */}
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
};

export default ComentariosDePrefectura;
