import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import moment from "moment";
import { useSelector } from "react-redux";
import NewButton from "../../Utils/Botones/NewButton";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import NewModal from "../../Utils/Visuales/NewModal";
import styles from "./styles.module.css";
import SearchInput from "../../Utils/Visuales/SearchInput";
import useMediaQuery from "@mui/material/useMediaQuery";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { generateCSV } from "../../Utils/generateCSV/generateCSV";
import "./index.css";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";

const AsignacionesKardexAcademico = () => {
  const headers = [
    {
      name: "Clase",
      id: "clase",
      col: 0,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Prof.",
      id: "tipo_prof",
      col: 1,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 2,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 3,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Dirección",
      id: "clave_direccion",
      col: 4,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Director",
      id: "directo",
      col: 5,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 6,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 7,
      width: "12%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      id: "descripcion_material",
      col: 8,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Status Asist.",
      id: "status_asist",
      col: 9,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Asistencia",
      id: "asistencia",
      col: 10,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Inicio",
      id: "hora_inicio",
      col: 11,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Final",
      id: "hora_final",
      col: 12,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Reg",
      id: "hora_reg",
      col: 13,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Día",
      id: "dia",
      col: 14,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 15,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "A",
      id: "a",
      col: 16,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "C",
      id: "c",
      col: 17,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Q",
      id: "q",
      col: 18,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Env. Nómina",
      id: "fecha_env_nomina",
      col: 19,
      width: "20%",
      align: "center",
      fn: null,
    },
  ];

  const headersTable = [
    {
      name: "Clase",
      id: "clase",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Tipo Prof.",
      id: "tipo_prof",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 3,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Dirección",
      id: "clave_direccion",
      col: 4,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Director",
      id: "directo",
      col: 5,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 6,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 7,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Descripción Materia",
      id: "descripcion_material",
      col: 8,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Status Asist.",
      id: "status_asist",
      col: 9,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Asistencia",
      id: "asistencia",
      col: 10,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Inicio",
      id: "hora_inicio",
      col: 11,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Final",
      id: "hora_final",
      col: 12,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Hora Reg",
      id: "hora_reg",
      col: 13,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Día",
      id: "dia",
      col: 14,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha",
      id: "fecha",
      col: 15,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "A",
      id: "a",
      col: 16,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "C",
      id: "c",
      col: 17,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Q",
      id: "q",
      col: 18,
      width: "8%",
      align: "center",
      fn: null,
    },
    {
      name: "Fecha Env. Nómina",
      id: "fecha_env_nomina",
      col: 19,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Acciones",
      id: "Acciones",
      col: 20,
      width: "15%",
      align: "center",
      fn: null,
    },
  ];

  const [dataOriginal, setDataOriginal] = useState([]);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [dataFinal, setDataFinal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingDownloadCsv, setLoadingDownloadCsv] = useState(false);
  const [req, setReq] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [campusOptions, setCampusOptions] = useState([]);
  const [campus, setCampus] = useState("");
  const [direccion, setDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState([]);
  const [profesor, setProfesor] = useState("");
  const [materia, setMateria] = useState("");
  const [grupo, setGrupo] = useState("");
  const [fechaInicial, setFechaInicial] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");

  const [consultaValues, setConsultaValues] = useState({
    ciclo: "",
    direccion: "",
    director: "",
    profesor: "",
    tipo_profesor: "",
    registro: "",
    quincena: "",
    resultados: "",
    envio_nomina: "",
    tipo_registro: "",
    tema: "",
    detalle_tipo_registro: "",
  });

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  useEffect(() => {
    getCampusLogin();
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    setCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const getCampusLogin = async () => {
    try {
      setLoading(true);
      const request = `/login`;
      let response = await getRequest(request);
      if (response.s === "OK") {
        const arrCampus = [];
        response.d.campus.map((item) => {
          arrCampus.push({
            value: parseInt(item.id),
            label: item.clave + " - " + item.nombre,
          });
        });
        setCampusOptions(arrCampus);
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({
            id: id[i],
            value: `${clave[i]} - ${nombre[i]}`,
            label: `${clave[i]} - ${nombre[i]}`,
          });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const waitTime = 2000;
  let timer;

  async function searchProfesor(valor) {
    try {
      const options = [];
      const request = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            id: item.id,
            label: item.nss + " - " + item.nombre,
            value: item.nss + " - " + item.nombre,
          });
        });
        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const promiseProfesor = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length > 2) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const [loadingModal, setLoadingModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function openModalConsultar(id_verdict) {
    setModalIsOpen(true);
    setLoadingModal(true);
    try {
      const request = `/asignaciones/kardex-academico?id=${id_verdict}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        setConsultaValues({
          ciclo: response.d.ciclo,
          direccion: response.d.direccion,
          director: response.d.director,
          profesor: response.d.profesor,
          tipo_profesor: response.d.tipo_profesor,
          registro: response.d.registro,
          quincena: response.d.quincena,
          resultados: response.d.resultados,
          envio_nomina: response.d.envio_nomina,
          tipo_registro: response.d.tipo_registro,
          tema: response.d.tema,
          detalle_tipo_registro: response.d.detalle_tipo_registro,
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModal(false);
    }
  }

  const matches = useMediaQuery("(min-width:960px)");
  const [fechaInicioKardex, setFechaInicioKardex] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [fechaFinKardex, setFechaFinKardex] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [diasKardex, setDiasKardex] = useState(0);
  // const handleDateChangeEditStart = () => {

  // }
  useEffect(() => {
    let aux1 = moment(fechaFinKardex);
    let aux2 = moment(fechaInicioKardex);
    setDiasKardex(aux1.diff(aux2, "days"));
  }, [fechaInicioKardex, fechaFinKardex]);

  function search(text) {
    text = text.toLowerCase();
    let result = dataFinal.filter((row) => {
      if (row[2].toString().toLowerCase().includes(text)) return true;
      else if (row[0].toString().toLowerCase().includes(text)) return true;
      else if (row[1].toString().toLowerCase().includes(text)) return true;
      else if (row[3].toLowerCase().includes(text)) return true;
      else if (row[4].toLowerCase().includes(text)) return true;
      else if (row[5].toLowerCase().includes(text)) return true;
      else if (row[6].toLowerCase().includes(text)) return true;
      else if (row[7].toLowerCase().includes(text)) return true;
      else if (row[8].toLowerCase().includes(text)) return true;
      else if (row[9].toLowerCase().includes(text)) return true;
      else if (row[10].toLowerCase().includes(text)) return true;
      else if (row[11].toLowerCase().includes(text)) return true;
      else if (row[12].toLowerCase().includes(text)) return true;
      else if (row[13].toLowerCase().includes(text)) return true;
      else if (row[14].toLowerCase().includes(text)) return true;
      else if (row[15].toLowerCase().includes(text)) return true;
      else if (row[16].toLowerCase().includes(text)) return true;
      else if (row[17].toLowerCase().includes(text)) return true;
      else if (row[18].toLowerCase().includes(text)) return true;
      else if (row[19].includes(text)) return true;
      return false;
    });
    if (text.length === 0) result = dataOriginal;
    setDataFinal(result);
  }

  const handleGeneratedReport = async () => {
    const date = moment().format("DD/MM/YYYY");
    const dataTable = dataFinal.map((e) => {
      //console.log(e)
      const data2 = {
        clase: e[0],
        tipo_prof: e[1],
        clave_profesor: e[2],
        nombre_profesor: e[3],
        clave_direccion: e[4],
        directo: e[5],
        grupo: e[6],
        clave_materia: e[7],
        descripcion_material: e[8],
        status_asist: e[9],
        asistencia: e[10],
        hora_inicio: e[11],
        hora_final: e[12],
        hora_reg: e[13],
        dia: e[14],
        fecha: e[15],
        a: e[16],
        c: e[17],
        q: e[18],
        fecha_env_nomina: e[19],
      };
      return data2;
    });
    await generateCSV({
      data: dataTable,
      fileName: `Asignaciones-kardex-academico-${date}.csv`,
    });
  };

  function downloadCsv() {
    setLoadingDownloadCsv(true);
    handleGeneratedReport().finally(() => {
      setLoadingDownloadCsv(false);
    });
  }

  const DownloadPDF = async () => {
    setReq(true);
    const date = moment().format("L");
    const hour = moment().format("LTS");
    const dataTable = dataFinal.map((e) => {
      const data2 = {
        clase: e[0],
        tipo_prof: e[1],
        clave_profesor: e[2],
        nombre_profesor: e[3],
        clave_direccion: e[4],
        directo: e[5],
        grupo: e[6],
        clave_materia: e[7],
        descripcion_material: e[8],
        status_asist: e[9],
        asistencia: e[10],
        hora_inicio: e[11],
        hora_final: e[12],
        hora_reg: e[13],
        dia: e[14],
        fecha: e[15],
        a: e[16],
        c: e[17],
        q: e[18],
        fecha_env_nomina: e[19],
      };
      return data2;
    });
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={dataTable ?? []}
        title={`Asignaciones kardex academico`}
        campus={campus.label}
        startDate={fechaInicial}
        endDate={fechaFinal}
        teacherKey={profesor.value === undefined ? "" : profesor.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Asignaciones_kardex_academico_${date}_${hour}.pdf`);
    setReq(false);
  };

  const realizarBusqueda = async () => {
    if (campus === "") {
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaInicial === "") {
      enqueueSnackbar("Falta seleccionar Fecha Inicial", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (fechaFinal === "") {
      enqueueSnackbar("Falta seleccionar Fecha Final", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    if (profesor === "") {
      enqueueSnackbar("Falta seleccionar Clave Profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        setLoading(true);
        const datos = [];
        let url = `/asignaciones/kardex-academico?id_campus=${campus.value}&fi=${fechaInicial}&ff=${fechaFinal}&id_profesor=${profesor.id}`;
        if (grupo) url += `&grupo=${grupo}`;
        if (materia) url += `&id_materia=${materia}`;
        if (direccion) url += `&id_direccion=${direccion.id}`;
        const response = await getRequest(url);
        if (response.d.length === 0) {
          enqueueSnackbar("No se han encontrado datos", {
            variant: "default",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          return;
        }
        if (response.s === "OK") {
          const nro = response.d["id_assignacion"].length;
          //console.log(response.d);
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d["clase"][i],
              response.d["tipo_prof"][i],
              response.d["clave_profesor"][i],
              response.d["nombre_profesor"][i],
              response.d["clave_direccion"][i],
              response.d["director"][i],
              response.d["grupo"][i],
              response.d["clave_materia"][i],
              response.d["descripcion_materia"][i],
              response.d["status_asist"][i],
              response.d["asistencia"][i],
              response.d["hora_inicio"][i],
              response.d["hora_final"][i],
              response.d["hora_reg"][i],
              response.d["dia"][i],
              response.d["fecha"][i],
              response.d["a"][i],
              response.d["c"][i],
              response.d["q"][i],
              response.d["fecha_env_nomina"][i],
              <div>
                <EditButton
                  onClick={() => {
                    openModalConsultar(response.d["id_verdict"][i]);
                  }}
                >
                  Consultar
                </EditButton>
              </div>,
              response.d["id_assignacion"][i],
              response.d["id_verdict"][i],
            ]);
          }
          setDataOriginal(datos);
          setDataFinal(datos);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Contenedor title="Asignaciones / Kardex Académico">
        <div className="inputs-asignaciones">
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid container item xs={12} sm={9}>
              <Grid container alignItems="center" spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  className="textEnd"
                  style={{ marginLeft: "-4%", marginRight: "-1%" }}
                >
                  <label>Campus(*):</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{ zIndex: 9, marginRight: "2.5%" }}
                >
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    style={{ width: "200px" }}
                    value={campus}
                    options={campusValores}
                    onChange={setCampus}
                  />
                </Grid>
                <Grid item xs={12} sm="auto" style={{ marginRight: "-1%" }}>
                  <label>Fecha Inicial(*):</label>
                </Grid>
                <Grid
                  className="nuevoUsuarioForm"
                  item
                  xs={12}
                  sm={2}
                  style={{ marginRight: "-0.5%" }}
                >
                  <input
                    className="filter-input"
                    label="Seleccionar fecha"
                    type="date"
                    onChange={(ev) => setFechaInicial(ev.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm="auto" style={{ marginRight: "-1%" }}>
                  <label>Fecha Final(*):</label>
                </Grid>
                <Grid
                  className="nuevoUsuarioForm"
                  item
                  xs={12}
                  sm={2}
                  style={{ zIndex: 99 }}
                >
                  <input
                    className="filter-input"
                    label="Seleccionar fecha"
                    type="date"
                    onChange={(ev) => setFechaFinal(ev.target.value)}
                    disabled={fechaInicial !== "" ? false : true}
                    min={fechaInicial}
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{ marginTop: "0.5%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={2}
                  className="textEnd"
                  style={{ marginLeft: "-4%", marginRight: "-1%" }}
                >
                  <label>Dirección:</label>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Select
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    value={direccion}
                    options={optionsDireccion}
                    onChange={setDireccion}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className="textEnd"
                  style={{ marginLeft: "-9%" }}
                >
                  <label>Clave Profesor(*):</label>
                </Grid>
                <Grid xs={1} sm={5} style={{ zIndex: "99" }}>
                  <AsyncSelect
                    className="select-modal-container"
                    classNamePrefix="select-modal"
                    styles={asyncSelectStyles}
                    loadOptions={promiseProfesor}
                    onChange={setProfesor}
                    placeholder="Buscar Profesor"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                spacing={2}
                style={{ marginTop: "0.5%" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={2}
                  className="textEnd"
                  style={{ marginLeft: "-4%", marginRight: "-1%" }}
                >
                  <label>Grupo:</label>
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginRight: "2.1%" }}>
                  <input
                    className="filter-input"
                    style={{ width: "155%" }}
                    value={grupo}
                    onChange={(e) => setGrupo(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{ marginRight: "-1%", marginLeft: "5.7%" }}
                >
                  <label>Materia:</label>
                </Grid>
                <Grid item xs={12} sm={5} style={{ marginRight: "-1%" }}>
                  <input
                    className="filter-input"
                    maxLength={10}
                    style={{ width: "104%" }}
                    value={materia}
                    onChange={(e) => setMateria(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={3} spacing={2}>
              <Grid
                container
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 16,
                }}
              >
                <NewButton
                  customText="Buscar"
                  noIcon
                  style={{
                    padding: "5px 15px",
                  }}
                  onClick={() => realizarBusqueda()}
                />
                <div style={{ display: "flex" }} className="export-buttons">
                  <Typography
                    style={{ marginTop: "auto", marginBottom: "auto" }}
                  >
                    Descargar:
                  </Typography>
                  <div>
                    <button
                      onClick={downloadCsv}
                      className={"buttonDownload"}
                      variant="contained"
                    >
                      <img src={excelIcon} alt="excel-icon" />
                    </button>
                    <button
                      onClick={DownloadPDF}
                      className={"buttonDownload"}
                      variant="contained"
                    >
                      <img src={pdfFileIcon} alt="pdf-file-icon" />
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={styles.opcionesBar} style={{ marginTop: "-0.5%" }}>
          <div style={{ float: "right", width: "300px" }}>
            <SearchInput hideIcon search={search} />
          </div>
        </div>
        <div style={{ height: "calc(100% - 215px)" }}>
          <DataTable
            headers={headersTable}
            data={dataFinal}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7]}
            paginate
            bordes
          />
        </div>

        <NewModal
          title={"Detalles Registro Kardex Académico"}
          open={modalIsOpen}
          handleClose={() => setModalIsOpen(false)}
          loading={loadingModal}
          maxWidth={1050}
          pb={50}
          minHeight={350}
          noSaveBtn
        >
          <div className="nuevoUsuarioForm">
            <Grid container spacing={1}>
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <label>Ciclo:</label>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginLeft: "-6%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.ciclo}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <label>Dirección:</label>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginLeft: "-6%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.direccion}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.director}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <label>Clave profesor:</label>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  style={{ marginLeft: "-6%", marginRight: "0%" }}
                >
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.profesor}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  className={`${matches ? styles.alignRight : ""}`}
                >
                  <label>Tipo profesor:</label>
                </Grid>
                <Grid item xs={12} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.tipo_profesor}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <label>Registro:</label>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginLeft: "-6%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.registro}
                  />
                </Grid>
                <Grid item xs={12} md={2} />
                <Grid
                  item
                  xs={12}
                  md={2}
                  className={`${matches ? styles.alignRight : ""}`}
                >
                  <label>Quincena:</label>
                </Grid>
                <Grid item xs={12} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.quincena}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <label>Resultados:</label>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginLeft: "-6%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.resultados}
                  />
                </Grid>
                <Grid item xs={12} md={2} />
                <Grid
                  item
                  xs={12}
                  md={2}
                  className={`${matches ? styles.alignRight : ""}`}
                >
                  <label>Envio a Nomina:</label>
                </Grid>
                <Grid item xs={12} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.envio_nomina}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1} alignItems="center">
                <Grid item xs={12} md={2}>
                  <label>Tipo registro:</label>
                </Grid>
                <Grid item xs={12} md={3} style={{ marginLeft: "-6%" }}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.tipo_registro}
                  />
                </Grid>
                <Grid item xs={12} md={1} />
                <Grid
                  item
                  xs={12}
                  md={3}
                  className={`${matches ? styles.alignRight : ""}`}
                >
                  <label>Detalle tipo de Registro:</label>
                </Grid>
                <Grid item xs={12} md={3}>
                  <input
                    disabled
                    className="filter-input"
                    value={consultaValues.detalle_tipo_registro}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12} md={2}>
                  <label>Tema:</label>
                </Grid>
                <Grid item xs={12} md={5} style={{ marginLeft: "-6%" }}>
                  <textarea
                    rows={4}
                    disabled
                    className="filter-input"
                    style={{ height: "auto", resize: "none" }}
                  >
                    {consultaValues.tema}
                  </textarea>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </NewModal>
      </Contenedor>
    </>
  );
};

export default AsignacionesKardexAcademico;
