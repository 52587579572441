export const hourIndex = {
  "07:00": 1,
  "07:30": 2,
  "08:00": 3,
  "08:30": 4,
  "09:00": 5,
  "09:30": 6,
  "10:00": 7,
  "10:30": 8,
  "11:00": 9,
  "11:30": 10,
  "12:00": 11,
  "12:30": 12,
  "13:00": 13,
  "13:30": 14,
  "14:00": 15,
  "14:30": 16,
  "15:00": 17,
  "15:30": 18,
  "16:00": 19,
  "16:30": 20,
  "17:00": 21,
  "17:30": 22,
  "18:00": 23,
  "18:30": 24,
  "19:00": 25,
  "19:30": 26,
  "20:00": 27,
  "20:30": 28,
  "21:00": 29,
  "21:30": 30,
  "22:00": 31,
  "22:30": 32,
};
