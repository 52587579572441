
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../pdf_table/styled'

export default function ReportTotalProfCamEscPDF({
    metadata,
    data,
    campus,
    startDate,
    endDate,
    ciclo,
    escuela,
    direccion,
    tipo_clase
}) {
    const Headers = () => {
        return (
            <View style={{ flexDirection: 'row' }}>
                {
                    metadata && (
                        metadata.map((e, i) => (
                            <View key={i} style={{ width: e.width, ...styles.boxHeader, justifyContent: e.align }}>
                                <Text style={styles.cell}>{e.name}</Text>
                            </View>
                        ))
                    )
                }
            </View>
        )
    }
    return (
        <View style={{ marginTop: 15 }}>
            <View style={{ marginTop: 1 }}>
                {startDate ? (
                    <View style={{ flexDirection: 'row', width: '100%', marginTop: 2 }}>
                        <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Inicial: {startDate}</Text>
                        {endDate ? (
                            <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Fecha Final: {endDate}</Text>
                        ) : null}
                    </View>
                ) : null}

                <View style={{ flexDirection: 'row', width: '100%', marginTop: 5 }}>
                    {ciclo ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Ciclo: {ciclo}</Text>) : null}
                    {campus ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Campus: {campus}</Text>) : null}
                    {escuela ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Escuela: {escuela}</Text>) : null}
                    {direccion ? (<Text style={{ fontSize: 10, color: '#000000' }}>Dirección: {direccion}</Text>) : null}
                </View>

                <View style={{ borderLeft: '1px', borderLeftColor: '#828282', borderTop: '1px', borderTopColor: '#828282', marginTop: 5 }}>
                    <Headers />
                    {
                        data && (
                            data.map((e, i) => (
                                <View style={{ flexDirection: 'row' }} key={i}>
                                    {metadata.map((el, il) => (
                                        <View key={il} style={{ width: el.width, ...styles.boxRow, justifyContent: el.align }}>
                                            {
                                                el.fn === null ? (
                                                    <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                                                ) :
                                                    <Text> {el.fn(e[el.id])}</Text>
                                            }
                                        </View>
                                    ))}
                                </View>
                            ))
                        )
                    }
                </View>

                <View style={{ flexDirection: 'row', width: '100%', marginTop: 15, fontFamily: 'Bold'}}>
                    <Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Tipo de Clase: {tipo_clase}</Text>
                </View>

                <View style={{ marginTop: 3, flexDirection: 'row', width: '100%', marginTop: 20 }}>
                    <View style={{ width: '10%', }}>
                        <Text style={{ fontSize: 10, color: '#000000', textAlign: 'right' }}>Elaboro:</Text>
                    </View>

                    <View style={{ width: '20%' }}>
                        <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                    </View>

                    <View style={{ width: '20%' }}>
                        <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                    </View>

                    <View style={{ width: '10%', }}>
                        <Text style={{ fontSize: 10, color: '#000000', textAlign: 'right' }}>Capturo:</Text>
                    </View>

                    <View style={{ width: '20%' }}>
                        <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                    </View>

                    <View style={{ width: '20%' }}>
                        <View style={{ width: '80%', height: '2px', backgroundColor: '#000000', marginTop: 10 }} />
                    </View>
                </View>

                <View style={{ marginTop: 3, flexDirection: 'row', width: '100%', marginTop: 10 }}>
                    <View style={{ width: '12%', }}/>

                    <View style={{ width: '26%' }}>
                        <Text style={{ fontSize: 10, color: '#000000',  }}>Nombre del Perfecto</Text>
                    </View>

                    <View style={{ width: '17%' }}>
                        <Text style={{ fontSize: 10, color: '#000000',  }}>Firma</Text>
                    </View>

                    <View style={{ width: '10%', }}/>

                    <View style={{ width: '16%' }}>
                        <Text style={{ fontSize: 10, color: '#000000', textAlign: 'center' }}>Nombre</Text>
                    </View>

                    <View style={{ width: '24%' }}>
                        <Text style={{ fontSize: 10, color: '#000000', textAlign: 'center' }}>Firma</Text>
                    </View>
                </View>
            </View>
        </View>
    )
}