import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import { CustomSpinner } from "../../Utils/Loader";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import "./index.css";
import Select from "react-select";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useSelector } from "react-redux";
import SearchInput from "../../Utils/Visuales/SearchInput";

function ReporteIndicadoresDirector() {
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const { enqueueSnackbar } = useSnackbar();
  const [valueCiclo, setValueCiclo] = useState("");
  const [optionsCampus, setOptionsCampus] = useState("");
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [req, setReq] = useState(false);
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [valueCampus, setValueCampus] = useState({
    label: "",
    value: "",
  });
  const [valueEscuela, setValueEscuela] = useState({
    label: "",
    value: "",
  });
  const [valueDireccion, setValueDireccion] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsDireccion, setOptionsDireccion] = useState("");

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  const GetDataTable = async () => {
    let valido = true;
    if (valueCiclo === "") {
      enqueueSnackbar("Falta ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus.value === "") {
      enqueueSnackbar("Falta campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela.value === "") {
      enqueueSnackbar("Falta escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      let consulta = `/reportes/indicadores_globales_detalles_director?ciclo=${valueCiclo}&id_campus=${valueCampus.value}&id_escuela=${valueEscuela.value}`;
      if (valueDireccion) consulta += `&id_direccion=${valueDireccion.value}`;
      const response = await getRequest(consulta);
      let rowsP = "";
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        //data
        const array1 = [];
        const array2 = [];
        const arrayRows = [];
        response.d.map((e, i) => {
          const objp = Object.values(response.d[i]);
          array1[i] = objp;
        });

        const numRows_ = array1[0][0].length;

        console.log(numRows_);
        for (let step = 0; step < numRows_; step++) {
          response.d.map((e, i) => {
            Object.values(response.d[i]).map((value) => {
              let numj = i - i + step;
              array2[i] = value[numj];
            });
          });
          arrayRows[step] = [...array2];
        }
        rowsP = arrayRows;
        console.log(rowsP);
        setData(rowsP);
        setDataOriginal(rowsP);

        setLoading(false);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setData([]);
        setDataOriginal([]);
        setLoading(false);
      }
    }
  };

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Dirección",
      id: "direccion",
      col: 3,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Director",
      id: "director",
      col: 4,
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Prog",
      id: "prog_h",
      col: 5,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Brutas",
      id: "faltas_brutas_h",
      col: 6,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "%F.Brutas",
      id: "porc_F_Brutas_h",
      col: 7,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Aut",
      id: "aut",
      col: 8,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Rep",
      id: "rep_h",
      col: 9,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Netas",
      id: "faltas_netas_h",
      col: 10,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "%F.Netas",
      id: "por_f_netas_h",
      col: 11,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Sust",
      id: "sust_h",
      col: 12,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Adic",
      id: "adic_h",
      col: 13,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Visit",
      id: "visit_h",
      col: 14,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Prog",
      id: "prog_s",
      col: 15,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Brutas",
      id: "faltas_brutas_s",
      col: 16,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Aut",
      id: "ut",
      col: 17,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Rep",
      id: "rep_s",
      col: 18,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Faltas Netas",
      id: "faltas_netas_s",
      col: 19,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Sust",
      id: "sust_s",
      col: 20,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Adic",
      id: "adic_s",
      col: 21,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Visit",
      id: "visit_s",
      col: 22,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "S.Ant",
      id: "s_ant",
      col: 23,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Ret",
      id: "ret_s",
      col: 24,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Ret",
      id: "ret_m",
      col: 25,
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Can",
      id: "can",
      col: 26,
      width: "10%",
      align: "center",
      fn: null,
    },
  ];

  const DownloadPDF = async () => {
    setReq(true);
    const dataPDF = [];
    let recorrido = 0;
    const totalTableInfo = {
      prog_h: 0,
      faltas_brutas_h: 0,
      porc_F_Brutas_h: 0,
      aut: 0,
      rep_h: 0,
      faltas_netas_h: 0,
      por_f_netas_h: 0,
      sust_h: 0,
      adic_h: 0,
      visit_h: 0,
      prog_s: 0,
      faltas_brutas_s: 0,
      ut: 0,
      rep_s: 0,
      faltas_netas_s: 0,
      sust_s: 0,
      adic_s: 0,
      visit_s: 0,
      s_ant: 0,
      ret_s: 0,
      ret_m: 0,
      can: 0,
    };
    if (data.length > 400) {
      recorrido = 300;
    } else {
      recorrido = data.length;
    }
    for (let i = 0; i < recorrido; i++) {
      dataPDF.push({
        ciclo: data[i][0],
        campus: data[i][1],
        escuela: data[i][2],
        direccion: data[i][3],
        director: data[i][4],
        prog_h: data[i][5],
        faltas_brutas_h: data[i][6],
        porc_F_Brutas_h: data[i][7],
        aut: data[i][8],
        rep_h: data[i][9],
        faltas_netas_h: data[i][10],
        por_f_netas_h: data[i][11],
        sust_h: data[i][12],
        adic_h: data[i][13],
        visit_h: data[i][14],
        prog_s: data[i][15],
        faltas_brutas_s: data[i][16],
        ut: data[i][17],
        rep_s: data[i][18],
        faltas_netas_s: data[i][19],
        sust_s: data[i][20],
        adic_s: data[i][21],
        visit_s: data[i][22],
        s_ant: data[i][23],
        ret_s: data[i][24],
        ret_m: data[i][25],
        can: data[i][26],
      });
      totalTableInfo.prog_h = totalTableInfo.prog_h + parseFloat(data[i][5]);
      totalTableInfo.faltas_brutas_h =
        totalTableInfo.faltas_brutas_h + parseFloat(data[i][6]);
      totalTableInfo.porc_F_Brutas_h =
        totalTableInfo.porc_F_Brutas_h + parseFloat(data[i][7]);
      totalTableInfo.aut = totalTableInfo.aut + parseFloat(data[i][8]);
      totalTableInfo.rep_h = totalTableInfo.rep_h + parseFloat(data[i][9]);
      totalTableInfo.faltas_netas_h =
        totalTableInfo.faltas_netas_h + parseFloat(data[i][10]);
      totalTableInfo.por_f_netas_h =
        totalTableInfo.por_f_netas_h + parseFloat(data[i][11]);
      totalTableInfo.sust_h = totalTableInfo.sust_h + parseFloat(data[i][12]);
      totalTableInfo.adic_h = totalTableInfo.adic_h + parseFloat(data[i][13]);
      totalTableInfo.visit_h = totalTableInfo.visit_h + parseFloat(data[i][14]);
      totalTableInfo.prog_s = totalTableInfo.prog_s + parseFloat(data[i][15]);
      totalTableInfo.faltas_brutas_s =
        totalTableInfo.faltas_brutas_s + parseFloat(data[i][16]);
      totalTableInfo.ut = totalTableInfo.ut + parseFloat(data[i][17]);
      totalTableInfo.rep_s = totalTableInfo.rep_s + parseFloat(data[i][18]);
      totalTableInfo.faltas_netas_s =
        totalTableInfo.faltas_netas_s + parseFloat(data[i][19]);
      totalTableInfo.sust_s = totalTableInfo.sust_s + parseFloat(data[i][20]);
      totalTableInfo.adic_s = totalTableInfo.adic_s + parseFloat(data[i][21]);
      totalTableInfo.visit_s = totalTableInfo.visit_s + parseFloat(data[i][22]);
      totalTableInfo.s_ant = totalTableInfo.s_ant + parseFloat(data[i][23]);
      totalTableInfo.ret_s = totalTableInfo.ret_s + parseFloat(data[i][24]);
      totalTableInfo.ret_m = totalTableInfo.ret_m + parseFloat(data[i][25]);
      totalTableInfo.can = totalTableInfo.can + parseFloat(data[i][26]);
    }
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const newHeaders = headers.filter(
      (element) => !["Ciclo", "Campus", "Escuela"].includes(element.name)
    );
    dataPDF.push({
      director: "Totales",
      prog_h: parseFloat(totalTableInfo.prog_h).toFixed(0),
      faltas_brutas_h: parseFloat(totalTableInfo.faltas_brutas_h).toFixed(0),
      porc_F_Brutas_h: parseFloat(totalTableInfo.porc_F_Brutas_h).toFixed(2),
      aut: parseFloat(totalTableInfo.aut).toFixed(0),
      rep_h: parseFloat(totalTableInfo.rep_h).toFixed(0),
      faltas_netas_h: parseFloat(totalTableInfo.faltas_netas_h).toFixed(0),
      por_f_netas_h: parseFloat(totalTableInfo.por_f_netas_h).toFixed(2),
      sust_h: parseFloat(totalTableInfo.sust_h).toFixed(0),
      adic_h: parseFloat(totalTableInfo.adic_h).toFixed(0),
      visit_h: parseFloat(totalTableInfo.visit_h).toFixed(0),
      prog_s: parseFloat(totalTableInfo.prog_s).toFixed(0),
      faltas_brutas_s: parseFloat(totalTableInfo.faltas_brutas_s).toFixed(0),
      ut: parseFloat(totalTableInfo.ut).toFixed(0),
      rep_s: parseFloat(totalTableInfo.rep_s).toFixed(0),
      faltas_netas_s: parseFloat(totalTableInfo.faltas_netas_s).toFixed(0),
      sust_s: parseFloat(totalTableInfo.sust_s).toFixed(0),
      adic_s: parseFloat(totalTableInfo.adic_s).toFixed(0),
      visit_s: parseFloat(totalTableInfo.visit_s).toFixed(0),
      s_ant: parseFloat(totalTableInfo.s_ant).toFixed(0),
      ret_s: parseFloat(totalTableInfo.ret_s).toFixed(0),
      ret_m: parseFloat(totalTableInfo.ret_m).toFixed(0),
      can: parseFloat(totalTableInfo.can).toFixed(0),
      ciclo: valueCiclo,
      campus: `${valueCampus.value} - ${valueCampus.label}`,
      escuela: `${valueEscuela.value} - ${valueEscuela.label}`,
    });
    const doc = (
      <DocumentPDFTable
        metadata={newHeaders ?? []}
        data={dataPDF ?? []}
        title={`Reportes Indicadores Globales por Director`}
        campus={valueCampus.label}
        rowstableColSpan={rowstableColSpanPDF}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Indicadores_Globales_Director_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const totalTableInfo = {
      Prog_h: 0,
      Faltas_Brutas_h: 0,
      "% F_Brutas_h": 0,
      Aut_h: 0,
      Rep_h: 0,
      Faltas_Netas_h: 0,
      "% F_netas_h": 0,
      Sust_h: 0,
      Adic_h: 0,
      Visit_h: 0,
      Prog_s: 0,
      Faltas_Brutas_s: 0,
      Aut_s: 0,
      Rep_s: 0,
      Faltas_Netas_s: 0,
      Sust_s: 0,
      Adic_s: 0,
      Visit_s: 0,
      "S.Ant_s": 0,
      Ret_s: 0,
      Ret_m: 0,
      Can_m: 0,
    };
    const data_ = data.map((e, i) => {
      const data2 = {
        Ciclo: e[0],
        Campus: e[1],
        Escuela: e[2],
        Dirección: e[3],
        Director: e[4],
        Prog_h: e[5],
        Faltas_Brutas_h: e[6],
        "% F_Brutas_h": e[7],
        Aut_h: e[8],
        Rep_h: e[9],
        Faltas_Netas_h: e[10],
        "% F_netas_h": e[11],
        Sust_h: e[12],
        Adic_h: e[13],
        Visit_h: e[14],
        Prog_s: e[15],
        Faltas_Brutas_s: e[16],
        Aut_s: e[17],
        Rep_s: e[18],
        Faltas_Netas_s: e[19],
        Sust_s: e[20],
        Adic_s: e[21],
        Visit_s: e[22],
        "S.Ant_s": e[23],
        Ret_s: e[24],
        Ret_m: e[25],
        Can_m: e[26],
      };
      totalTableInfo.Prog_h = totalTableInfo.Prog_h + parseFloat(data[i][5]);
      totalTableInfo.Faltas_Brutas_h =
        totalTableInfo.Faltas_Brutas_h + parseFloat(data[i][6]);
      totalTableInfo["% F_Brutas_h"] =
        totalTableInfo["% F_Brutas_h"] + parseFloat(data[i][7]);
      totalTableInfo.Aut_h = totalTableInfo.Aut_h + parseFloat(data[i][8]);
      totalTableInfo.Rep_h = totalTableInfo.Rep_h + parseFloat(data[i][9]);
      totalTableInfo.Faltas_Netas_h =
        totalTableInfo.Faltas_Netas_h + parseFloat(data[i][10]);
      totalTableInfo["% F_netas_h"] =
        totalTableInfo["% F_netas_h"] + parseFloat(data[i][11]);
      totalTableInfo.Sust_h = totalTableInfo.Sust_h + parseFloat(data[i][12]);
      totalTableInfo.Adic_h = totalTableInfo.Adic_h + parseFloat(data[i][13]);
      totalTableInfo.Visit_h = totalTableInfo.Visit_h + parseFloat(data[i][14]);
      totalTableInfo.Prog_s = totalTableInfo.Prog_s + parseFloat(data[i][15]);
      totalTableInfo.Faltas_Brutas_s =
        totalTableInfo.Faltas_Brutas_s + parseFloat(data[i][16]);
      totalTableInfo.Aut_s = totalTableInfo.Aut_s + parseFloat(data[i][17]);
      totalTableInfo.Rep_s = totalTableInfo.Rep_s + parseFloat(data[i][18]);
      totalTableInfo.Faltas_Netas_s =
        totalTableInfo.Faltas_Netas_s + parseFloat(data[i][19]);
      totalTableInfo.Sust_s = totalTableInfo.Sust_s + parseFloat(data[i][20]);
      totalTableInfo.Adic_s = totalTableInfo.Adic_s + parseFloat(data[i][21]);
      totalTableInfo.Visit_s = totalTableInfo.Visit_s + parseFloat(data[i][22]);
      totalTableInfo["S.Ant_s"] =
        totalTableInfo["S.Ant_s"] + parseFloat(data[i][23]);
      totalTableInfo.Ret_s = totalTableInfo.Ret_s + parseFloat(data[i][24]);
      totalTableInfo.Ret_m = totalTableInfo.Ret_m + parseFloat(data[i][25]);
      totalTableInfo.Can_m = totalTableInfo.Can_m + parseFloat(data[i][26]);
      return data2;
    });
    data_.push({
      Ciclo: "",
      Campus: "",
      Escuela: "",
      Dirección: "",
      Director: "Totales",
      Prog_h: parseFloat(parseFloat(totalTableInfo.Prog_h).toFixed(0)),
      Faltas_Brutas_h: parseFloat(
        parseFloat(totalTableInfo.Faltas_Brutas_h).toFixed(0)
      ),
      "% F_Brutas_h": parseFloat(
        parseFloat(totalTableInfo["% F_Brutas_h"]).toFixed(2)
      ),
      Aut_h: parseFloat(parseFloat(totalTableInfo.Aut_h).toFixed(0)),
      Rep_h: parseFloat(parseFloat(totalTableInfo.Rep_h).toFixed(0)),
      Faltas_Netas_h: parseFloat(
        parseFloat(totalTableInfo.Faltas_Netas_h).toFixed(0)
      ),
      "% F_netas_h": parseFloat(
        parseFloat(totalTableInfo["% F_netas_h"]).toFixed(2)
      ),
      Sust_h: parseFloat(parseFloat(totalTableInfo.Sust_h).toFixed(0)),
      Adic_h: parseFloat(parseFloat(totalTableInfo.Adic_h).toFixed(0)),
      Visit_h: parseFloat(parseFloat(totalTableInfo.Visit_h).toFixed(0)),
      Prog_s: parseFloat(parseFloat(totalTableInfo.Prog_s).toFixed(0)),
      Faltas_Brutas_s: parseFloat(
        parseFloat(totalTableInfo.Faltas_Brutas_s).toFixed(0)
      ),
      Aut_s: parseFloat(parseFloat(totalTableInfo.Aut_s).toFixed(0)),
      Rep_s: parseFloat(parseFloat(totalTableInfo.Rep_s).toFixed(0)),
      Faltas_Netas_s: parseFloat(
        parseFloat(totalTableInfo.Faltas_Netas_s).toFixed(0)
      ),
      Sust_s: parseFloat(parseFloat(totalTableInfo.Sust_s).toFixed(0)),
      Adic_s: parseFloat(parseFloat(totalTableInfo.Adic_s).toFixed(0)),
      Visit_s: parseFloat(parseFloat(totalTableInfo.Visit_s).toFixed(0)),
      "S.Ant_s": parseFloat(parseFloat(totalTableInfo["S.Ant_s"]).toFixed(0)),
      Ret_s: parseFloat(parseFloat(totalTableInfo.Ret_s).toFixed(0)),
      Ret_m: parseFloat(parseFloat(totalTableInfo.Ret_m).toFixed(0)),
      Can_m: parseFloat(parseFloat(totalTableInfo.Can_m).toFixed(0)),
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_Indicadores_Globales_Director_${fecha}_${hora}`);
    setReq(false);
  };

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(5).then((data) => setOptionsCampus(data));
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const rowstableColSpan = [
    {
      name: "",
      col: 5,
    },
    {
      name: "Horas",
      col: 10,
    },
    {
      name: "Sesiones",
      col: 10,
    },
    {
      name: "Minutos",
      col: 2,
    },
  ];
  const rowstableColSpanPDF = [
    {
      name: "",
      width: 94,
      align: "center",
    },
    {
      name: "Horas",
      width: 312,
      align: "center",
    },
    {
      name: "Sesiones",
      width: 312,
      align: "center",
    },
    {
      name: "Minutos",
      width: 63,
      align: "center",
    },
  ];
  return (
    <>
      <CustomSpinner visible={req} />
      <Contenedor title="Reportes / Indicadores Globales por Director">
        <Grid container>
          <Grid item xs={9}>
            <Grid
              container
              style={{ padding: "10px 0px" }}
              className="nuevoUsuarioForm"
            >
              <Grid item sm={2} container>
                <label>Ciclo(*):</label>
              </Grid>
              <Grid sm={4} item>
                <input
                  className="filter-input"
                  //style={{ width: "170px" }}
                  onChange={(e) => setValueCiclo(e.target.value)}
                />
              </Grid>
              <Grid item sm={2} container justifyContent="flex-end">
                <label>Campus(*):</label>
              </Grid>
              <Grid sm={4} item style={{ zIndex: 9 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={campusValores}
                  onChange={setValueCampus}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ padding: "10px 0px" }}
              className="nuevoUsuarioForm"
            >
              <Grid item sm={2} container>
                <label style={{ margin: "2px 5px" }}>Escuela(*):</label>
              </Grid>
              <Grid sm={4} item style={{ zIndex: 9 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueEscuela}
                  options={optionsEscuela}
                  onChange={setValueEscuela}
                />
              </Grid>
              <Grid item sm={2} container justifyContent="flex-end">
                <label>Dirección:</label>
              </Grid>
              <Grid sm={4} item style={{ zIndex: 8 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container style={{ padding: "20px 10px" }}>
              <Grid justifyContent={"center"} container>
                <EditButton
                  disabled={loading}
                  onClick={() => GetDataTable()}
                  style={{ padding: "10px 25px" }}
                >
                  Generar
                </EditButton>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadExcel}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    className={"buttonDownload"}
                    onClick={DownloadPDF}
                    disabled={req}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>
        <div style={{ height: "calc(98% - 200px)", marginTop: "40px" }}>
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
            rowstableColSpan={rowstableColSpan}
          />
        </div>
      </Contenedor>
    </>
  );
}

export default ReporteIndicadoresDirector;
