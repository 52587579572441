import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import EditButton from "../../Utils/Botones/EditButton";
import DataTable from "../../Utils/DataTables";
import { getRequest } from "../../Utils/Funciones/requester";
import { ExportCSV } from "../../Utils/Excel";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import Contenedor from "../../Utils/Visuales/Contenedor";
import "./index.css";
import SearchInput from "../../Utils/Visuales/SearchInput";

const ReporteDireccionesAcademicas = () => {
  const headers = [
    {
      name: "Campus",
      col: 0,
      id: "campus",
      width: "15%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      col: 1,
      id: "escuela",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      col: 2,
      id: "direccion",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Director",
      col: 3,
      id: "clave_director",
      width: "10%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Director",
      col: 4,
      id: "nombre_director",
      width: "25%",
      align: "center",
      fn: null,
    },
  ];
  const headers_pdf = [
    {
      name: "Clave Dirección",
      col: 0,
      id: "clave_direccion",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Dirección",
      col: 1,
      id: "nombre_direccion",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Director",
      col: 2,
      id: "clave_director",
      width: "25%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Director",
      col: 3,
      id: "nombre_director",
      width: "25%",
      align: "center",
      fn: null,
    },
  ];

  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [optionsCampus] = useState(campusUser);
  const [valueCampus, setValueCampus] = useState("");
  const [selectEscuela, setSelectEscuela] = useState([]);
  const [selectedEscuela, setSelectedEscuela] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  async function handleClickGenerar() {
    setLoading(true);
    if (valueCampus === "") {
      setLoading(false);
      enqueueSnackbar("Falta seleccionar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else {
      try {
        const datos = [];
        let url = `/reportes/direcciones_academicas?id_campus=${valueCampus.value}`;
        if (selectedEscuela) url += `&id_escuela=${selectedEscuela.value}`;
        if (valueDireccion.value)
          url += `&id_direccion=${valueDireccion.value}`;
        const consulta = url;
        const response = await getRequest(consulta);

        if (response.s === "OK") {
          const nro = response.d[0]["campus"].length;
          //console.log(nro);
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["campus"][i],
              response.d[1]["escuela"][i],
              response.d[2]["direccion"][i],
              response.d[3]["clave_director"][i],
              response.d[4]["nombre_director"][i],
            ]);
          }
          setData(datos);
          setDataOriginal(datos);
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        //console.log(error);
        setLoading(false);
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setShowTable(true);
      setLoading(false);
    }
  }

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setSelectEscuela(data));
    setValueCampus(
      optionsCampus?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const DownloadExcel = () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        campus: e[0],
        escuela: e[1],
        direccion: e[2],
        clave_director: e[3],
        nombre_director: e[4],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_Direcciones_Academicas_${fecha}_${hora}`);
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        campus: e[0],
        escuela: e[1],
        clave_direccion: e[2].split("-")[0],
        nombre_direccion: e[2].split("-")[1],
        clave_director: e[3],
        nombre_director: e[4],
      };
      return data2;
    });
    if (data_.length > 230) {
      enqueueSnackbar("Supera limites de Páginas", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers_pdf ?? []}
        data={data_ ?? []}
        title={`Reportes Direcciones Académicas`}
        campus={valueCampus.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Direcciones_Academicas_${fecha}_${hora}.pdf`);
    setReq(false);
  };

  return (
    <>
      {/* <CustomSpinner visible={req} /> */}
      <Contenedor title="Reportes / Direcciones Académicas">
        <Grid container style={{ position: "relative", zIndex: 9 }}>
          <Grid container item xs={12} sm={9}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ marginTop: "-3%" }}
            >
              <Grid item xs={1} sm={1} style={{ textAlign: "end" }}>
                <label>Campus(*)</label>
              </Grid>
              <Grid item xs={1} sm={3} style={{ marginRight: "2%" }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCampus}
                  options={optionsCampus}
                  onChange={setValueCampus}
                />
              </Grid>
              <Grid xs={1} md={1}>
                <label>Escuela:</label>
              </Grid>
              <Grid
                xs={1}
                md={3}
                item
                style={{ zIndex: 99, marginLeft: "-3%" }}
              >
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  options={selectEscuela}
                  onChange={setSelectedEscuela}
                />
              </Grid>
              <Grid item xs={12} sm={1} className="textEnd">
                <label>Dirección:</label>
              </Grid>
              <Grid item xs={1} sm={3}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  isClearable={true}
                  value={valueDireccion}
                  options={optionsDireccion}
                  onChange={setValueDireccion}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={10} sm={3} spacing={2}>
            <Grid
              container
              item
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <EditButton
                onClick={handleClickGenerar}
                disabled={loading}
                style={{ padding: "10px 25px" }}
              >
                Generar
              </EditButton>
              <div style={{ display: "flex" }} className="export-buttons">
                <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                  Descargar:
                </Typography>
                <div>
                  <button
                    onClick={DownloadExcel}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={excelIcon} alt="excel-icon" />
                  </button>
                  <button
                    onClick={DownloadPDF}
                    disabled={!showTable || req}
                    className={"buttonDownload"}
                    variant="contained"
                  >
                    <img src={pdfFileIcon} alt="pdf-file-icon" />
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
          <Grid item xs={12} md={5}>
            <Typography style={{ fontWeight: "bold" }}>
              Fecha Reporte:
              {showTable && (
                <>
                  {moment().format("DD/MM/YYYY") +
                    " - " +
                    moment().format("HH:mm:ss")}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} style={{ marginLeft: "auto" }}>
            <SearchInput hideIcon search={search} />
          </Grid>
        </Grid>

        <div style={{ height: "calc(100% - 185px)" }} id="Pagos">
          <DataTable
            headers={headers}
            data={data}
            loading={loading}
            centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            paginate
            bordes
          />
        </div>
      </Contenedor>
    </>
  );
};

export default ReporteDireccionesAcademicas;
