import { useMemo, useState } from "react";
import DataTables from "../../Utils/DataTables";
import ReporteIndicadoresAcademicosSemanalesHeader from "../../Utils/ReportesIndicadoresAcademicosSemanalesHeader";
import SearchBarBox from "../../Utils/SearchBarBox";
import Contenedor from "../../Utils/Visuales/Contenedor";
import {
  headersSemanales,
  rowstableColSpan,
} from "../../Utils/data/reportesIndicadoresAcademicos";
import { ModalReporteIndicadoresAcademicosSemanales } from "../../Utils/ModalReporteIndicadoresAcademicosSemanales";

export const ReportesIndicadoresAcademicosSemanales = () => {
  const [showTable, setShowTable] = useState(false);
  const [payload, setPayload] = useState(1);
  const [loading, setLoading] = useState(false);
  const [reportInfo, setReportInfo] = useState([]);
  const [queryFilter, setQueryFilter] = useState([]);
  const [modalConsulta, setModalConsulta] = useState(false);
  const filterData = useMemo(() => {
    return reportInfo.filter((item) => {
      return `${item}`.toLowerCase().includes(queryFilter);
    });
  }, [reportInfo, queryFilter]);

  const handleSearch = (e) => {
    setQueryFilter(e.toLowerCase());
  };

  const handleOpenModal = (payload) => {
    setModalConsulta(true);
    setPayload(payload);
  };

  return (
    <Contenedor title="Reportes / Indicadores Académicos Semanales">
      <ReporteIndicadoresAcademicosSemanalesHeader
        showTable={showTable}
        setShowTable={setShowTable}
        loading={loading}
        setLoading={setLoading}
        reportInfo={reportInfo}
        setReportInfo={setReportInfo}
        handleOpenModal={handleOpenModal}
      />
      <SearchBarBox showTable={showTable} handleSearch={handleSearch} />
      <div style={{ height: "calc(100% - 180px)" }}>
        <DataTables
          headers={headersSemanales}
          data={filterData}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          paginate
          bordes
          rowstableColSpan={rowstableColSpan}
        />
      </div>
      <br />
      <ModalReporteIndicadoresAcademicosSemanales
        modalConsulta={modalConsulta}
        setModalConsulta={setModalConsulta}
        payload={payload}
      />
    </Contenedor>
  );
};
