export const EXCELformatForReporteIndicdoresAcademicosSemanalesDatalle = (
  data,
  headers
) => {
  const data_ = data.map((e, i) => {
    const data2 = {
      ciclo: headers.ciclo,
      campus: headers.campus,
      escuela: headers.escuela,
      direccion: headers.claveDireccion,
      director: headers.nombreDirector,
      clave_profesor: data[i][0],
      Nombre_profesor: data[i][1],
      Grupo: data[i][2],
      Materia: data[i][3],
      Clase: data[i][4],
      Fecha_origen: data[i][5],
      Fecha_clase_especial: data[i][6],
      Clave_profesor_clase_especial: data[i][7],
      Nombre_profesor_clase_especial: data[i][8],
      Asistencia: data[i][9],
      A: data[i][10],
      C: data[i][11],
    };
    return data2;
  });
  return data_;
};
