import moment from "moment";
export function getWeekdayNamesInRange(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);
  const dayNames = [];

  while (start <= end) {
    if (start.day() !== 0  && !dayNames.includes(start.day())) {
      dayNames.push(start.day());
    }
    start.add(1, "days");
  }
  const stringOfDays = dayNames
    .sort()
    .map((day) => {
      switch (day) {
        case 1:
          return "L";
        case 2:
          return "M";
        case 3:
          return "Mi";
        case 4:
          return "J";
        case 5:
          return "V";
        case 6:
          return "S";
        default:
          return "";
      }
    })
    .join(" ");
  return stringOfDays;
}
