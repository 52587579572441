export const SelectTipoClaseParametro64 = [
  {
    label: "2 - Reposición",
    value: "2",
  },
  {
    label: "3 - Adicional",
    value: "3",
  },
  {
    label: "4 - Reposición Adelantada",
    value: "4",
  },
  {
    label: "5 - Sustitución",
    value: "5",
  },
  {
    label: "6 - Visita de Campo",
    value: "6",
  },
  {
    label: "7 - Visitas Guiadas",
    value: "7",
  },
  {
    label: "9 - Reposición por Incapacidad",
    value: "9",
  },
  {
    label: "10 - Sustitución por Incapacidad",
    value: "10",
  },
  {
    label: "16 - Sustitución por Paternidad",
    value: "16",
  },
];
