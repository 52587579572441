import React, { useMemo } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../pdf_table/styled";
import { divideArrayIntoParts } from "../Funciones/divideArrayIntoParts";
import HeaderPDFDefault from "../HeaderPDFDefault";

export default function ReportKardexAcademicoPDF({
  metadata,
  data,
  fechaReporte,
  tipo_profesor,
  title,
  totalBox,
}) {
  const Headers = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {metadata &&
          metadata.map((e, i) => (
            <View
              key={i}
              style={{
                width: e.width,
                ...styles.boxHeader,
                justifyContent: e.align,
              }}
            >
              <Text style={styles.cell}>{e.name}</Text>
            </View>
          ))}
      </View>
    );
  };

  const splitData = useMemo(() => {
    const name = [];
    Object.keys(totalBox).forEach((key_1) => {
      console.log(totalBox[key_1]);
      Object.keys(totalBox[key_1]).forEach((key_2) => {
        name.push([...totalBox[key_1][key_2]]);
      });
    });
    return name;
  }, [totalBox]);

  return splitData.map((item, index) => {
    const splitDataIntoParts = divideArrayIntoParts(item, 6);
    return splitDataIntoParts.map((dataTable, index2) => {
      console.log(splitDataIntoParts);
      return (
        <View
          break={index === 0 && index2 === 0 ? false : true}
          key={`${index}-${index2}`}
          style={{ height: 495 }}
        >
          <HeaderPDFDefault title={title} />
          <View style={{ marginTop: 15 }}>
            <View style={{ marginTop: 1 }}>
              <View style={{ flexDirection: "row", width: "100%" }}>
                <Text
                  style={{
                    fontSize: 10,
                    color: "#000000",
                    marginRight: "240px",
                  }}
                >
                  Fecha Reporte: {fechaReporte}
                </Text>
                <Text style={{ fontSize: 10, color: "#000000" }}>
                  Campus: {dataTable[0].campus}
                </Text>
              </View>
              <View
                style={{ flexDirection: "row", width: "100%", marginTop: 20 }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    color: "#000000",
                    marginRight: "40px",
                  }}
                >
                  Profesor: {`${dataTable[0].clave_profesor} - ${dataTable[0].nombre_profesor}`}
                </Text>
                {tipo_profesor ? (
                  <Text style={{ fontSize: 8, color: "#000000" }}>
                    Tipo Profesor: {dataTable[0].tipo_profesor}
                  </Text>
                ) : null}
              </View>
              <View
                style={{ flexDirection: "row", width: "100%", marginTop: 5 }}
              >
                <Text
                  style={{
                    fontSize: 8,
                    color: "#000000",
                    marginRight: "20px",
                  }}
                >
                  Dirección: {dataTable[0].direccion}
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    color: "#000000",
                    marginRight: "20px",
                  }}
                >
                  Director: {dataTable[0].director}
                </Text>
                <Text
                  style={{
                    fontSize: 8,
                    color: "#000000",
                    marginRight: "20px",
                  }}
                >
                  Grupo: {dataTable[0].grupo}
                </Text>
                <Text style={{ fontSize: 8, color: "#000000" }}>
                  Materia: {`${dataTable[0].clave_materia} - ${dataTable[0].materia}`}
                </Text>
              </View>
              <View
                style={{
                  marginTop: 10,
                  borderLeft: "1px",
                  borderLeftColor: "#828282",
                  borderTop: "1px",
                  borderTopColor: "#828282",
                }}
              >
                <Headers />
                {dataTable &&
                  dataTable.map((e, i) => {
                    if (i > 230) {
                      return null;
                    }
                    return (
                      <View style={{ flexDirection: "row" }} key={i}>
                        {metadata.map((el, il) => (
                          <View
                            key={il}
                            style={{
                              width: el.width,
                              ...styles.boxRow,
                              justifyContent: el.align,
                              borderTop: i === 0 ? "1px" : "0px",
                            }}
                          >
                            {el.fn === null ? (
                              <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                            ) : (
                              <Text> {el.fn(e[el.id])}</Text>
                            )}
                          </View>
                        ))}
                      </View>
                    );
                  })}
              </View>
            </View>
          </View>
        </View>
      );
    });
  });
}
