import React, { useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { useSnackbar } from "notistack";
import { getRequest } from "../../Utils/Funciones/requester";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { ExportCSV } from "../../Utils/Excel";
import Contenedor from "../../Utils/Visuales/Contenedor";
import DataTable from "../../Utils/DataTables";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import EditButton from "../../Utils/Botones/EditButton";
import "./styles.module.css";
import SearchInput from "../../Utils/Visuales/SearchInput";
import { useEffect } from "react";

function Divisionales() {
  const { enqueueSnackbar } = useSnackbar();
  const campusUser = useSelector((state) => state.campusUser2);
  const campusName = useSelector((state) => state.campusName);
  const [campusValue, setCampusValue] = useState("");
  const heading = [
    "campus",
    "clave_divisional",
    "nombre_divisional",
    "escuela",
    "direccion",
  ];

  const [dataFinal, setDataFinal] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        return (
          typeof element === "string" && element.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }

  async function handleClick() {
    setLoading(true);
    let valido = true;
    if (campusValue === "") {
      enqueueSnackbar("Falta seleccionar campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        const datos = [];
        let url = `/reportes/divisionales?id_campus=${campusValue.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["campus"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["campus"][i],
              response.d[1]["clave_divisional"][i],
              response.d[2]["nombre_divisional"][i],
              response.d[3]["escuela"][i],
              response.d[4]["direccion"][i],
            ]);
          }
          setDataFinal(datos);
          setDataOriginal(datos);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    setShowTable(true);
    setLoading(false);
  }

  const headers = [
    {
      name: "Campus",
      id: "campus",
      col: 0,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Clave Divisional",
      id: "clave_divisional",
      col: 1,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Nombre Divisional",
      id: "nombre_divisional",
      col: 2,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 3,
      width: "20%",
      align: "center",
      fn: null,
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 4,
      width: "20%",
      align: "center",
      fn: null,
    },
  ];

  /* Adding Functionality to Download Button */

  const DownloadPDF = async () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        campus: e[0],
        clave_divisional: e[1],
        nombre_divisional: e[2],
        escuela: e[3],
        direccion: e[4],
      };
      return data2;
    });
    if (data_.length > 230) {
      enqueueSnackbar("Supera limites de Páginas", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
    const fecha = moment().format("DD/MM/YYYY");
    const hora = moment().format("HH:mm:ss");
    const doc = (
      <DocumentPDFTable
        metadata={headers ?? []}
        data={data_ ?? []}
        title={`Reportes Divisionales`}
        campus={campusValue.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Divisionales_${fecha}_${hora}.pdf`);

    setReq(false);
  };

  const DownloadExcel = () => {
    setReq(true);
    const data_ = dataFinal.map((e) => {
      const data2 = {
        campus: e[0],
        clave_divisional: e[1],
        nombre_divisional: e[2],
        escuela: e[3],
        direccion: e[4],
      };
      return data2;
    });
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Reporte_Divisionales_${fecha}_${hora}`, heading);
    setReq(false);
  };

  useEffect(() => {
    setCampusValue(
      campusUser?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  return (
    <Contenedor title="Reportes / Divisionales">
      <div className="inputs-excepciones nuevoUsuarioForm">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <label>Campus(*):</label>
          </Grid>
          <Grid item xs={3}>
            <Select
              className="select-modal-container value-tipo"
              classNamePrefix="select-modal"
              value={campusValue}
              options={campusUser}
              onChange={setCampusValue}
              placeholder="Seleccione campus"
            />
          </Grid>
          <Grid item xs={12} sm={3} />
          <Grid
            container
            item
            xs={2}
            style={{
              alignItems: "center",
              marginLeft: "21%",
              marginTop: "-0.5%",
            }}
          >
            <EditButton onClick={handleClick} style={{ padding: "10px 25px" }} disabled={loading}>
              Generar
            </EditButton>
          </Grid>
          <Grid item xs={9} />
          <div style={{ display: "flex", marginLeft: "60px" }} className="export-buttons">
            <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
              Descargar:
            </Typography>
            <div>
              <button
                onClick={DownloadExcel}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={excelIcon} alt="excel-icon" />
              </button>
              {/* <button
                onClick={DownloadPDF}
                disabled={!showTable || req}
                className={"buttonDownload"}
                variant="contained"
              >
                <img src={pdfFileIcon} alt="pdf-file-icon" />
              </button> */}
            </div>
          </div>
        </Grid>
        {/* Adding Button format */}
      </div>
      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>
      <div style={{ height: "calc(100% - 170px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4, 5]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
}

export default Divisionales;
