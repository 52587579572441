import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useSnackbar } from "notistack";

import NewModal from "../../../Utils/Visuales/NewModal";
import NewButton from "../../../Utils/Botones/NewButton";
import DataTable from "../../../Utils/DataTables";
import EditButton from "../../../Utils/Botones/EditButton";
import { getRequest, postRequest } from "../../../Utils/Funciones/requester";
import styles from "../styles.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { checkIfJExists } from "../../../Utils/Funciones/checkIfJExists";

let grupoTimer;
function Adicional(props) {
  const [loading, setLoading] = useState(false);
  const [modalBuscarFaltaIsOpen, setModalBuscarFaltaIsOpen] = useState(false);
  const [dataFinal, setDataFinal] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [profesor, setProfesor] = useState({
    label: "",
    value: "",
  });
  const [grupo, setGrupo] = useState("");
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [materia, setMateria] = useState({
    label: "",
    value: "",
  });
  const [dataHorarioFinal, setDataHorarioFinal] = useState([]);
  const [detalleAsignacion, setDetalleAsignacion] = useState("");
  const [horaInicia, setHoraInicia] = useState("");
  const [minutosInicia, setMinutosInicia] = useState("");
  const [horaTermina, setHoraTermina] = useState("");
  const [minutosTermina, setMinutosTermina] = useState("");
  const [tipoSalonOptions, setTipoSalonOptions] = useState([]);
  const [selectTipoSalonIsLoading, setSelectTipoSalonIsLoading] =
    useState(false);
  const [tipoSalon, setTipoSalon] = useState({
    label: "",
    value: "",
  });
  const [salonOptions, setSalonOptions] = useState([]);
  const [salon, setSalon] = useState({
    label: "",
    value: "",
  });
  const [selectSalonIsLoading, setSelectSalonIsLoading] = useState(false);
  const [fechaMovimiento, setFechaMovimiento] = useState("");
  const [tema, setTema] = useState("");

  useEffect(() => {
    setProfesor({
      label: "",
      value: "",
    });
    setGrupo("");
    setMateria({
      label: "",
      value: "",
    });
    setFechaMovimiento("");
    setHoraInicia("");
    setMinutosInicia("");
    setHoraTermina("");
    setMinutosTermina("");
    setTipoSalon({
      label: "",
      value: "",
    });
    setSalon({
      label: "",
      value: "",
    });
    setTema("");
    if (props.open === true) {
      getTipoSalonCatalogo();
    }
  }, [props.open]);

  const campusId = useSelector((state) => state.campusId);
  useEffect(() => {
    clearTimeout(grupoTimer);
    if (grupo.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(
          campusId,
          profesor.value,
          grupo,
          props.ciclo,
          props.direccion.value
        );
      }, waitTime);
    }
  }, [grupo]);

  useEffect(() => {
    if (materia.value !== "") {
      consultarDetalleHorario(materia.value);
    }
  }, [materia]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setDataFinal([
      [
        "TF0701",
        "TF0701",
        "Materia 1",
        "110910",
        "LOVERA JURADO, ANYUMAR",
        "01/11/2022",
        "07:00",
        "",
        <EditButton>Seleccionar</EditButton>,
      ],
      [
        "TD412",
        "JK452",
        "Materia 2",
        "110910",
        "LOVERA JURADO, ANYUMAR",
        "10/11/2022",
        "09:00",
        "",
        <EditButton>Seleccionar</EditButton>,
      ],
      [
        "TF102",
        "TF478",
        "Radio II",
        "110910",
        "LOVERA JURADO, ANYUMAR",
        "24/11/2022",
        "09:00",
        "",
        <EditButton>Seleccionar</EditButton>,
      ],
    ]);
  }, []);

  function handleClickBuscarFalta() {
    setModalBuscarFaltaIsOpen(true);
  }

  const headers = [
    {
      name: "Grupo",
      col: 0,
    },
    {
      name: "Clave Materia",
      col: 1,
    },
    {
      name: "Materia",
      col: 2,
    },
    {
      name: "Clave Profesor",
      col: 3,
    },
    {
      name: "Nombre Profesor",
      col: 4,
    },
    {
      name: "Fecha",
      col: 5,
    },
    {
      name: "Hora Inicio",
      col: 6,
    },
    {
      name: "Hora Llegada",
      col: 7,
    },
    {
      name: "Acciones",
      col: 8,
    },
  ];

  async function consultarDetalleHorario(id) {
    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint).then(
      (res) => res.d
    );

    const scheduleData = [
      assignmentDetailResponse.horario.lunes[0],
      assignmentDetailResponse.horario.martes[0],
      assignmentDetailResponse.horario.miercoles[0],
      assignmentDetailResponse.horario.jueves[0],
      assignmentDetailResponse.horario.viernes[0],
      assignmentDetailResponse.horario.sabado[0],
      assignmentDetailResponse.horario.domingo[0],
    ];

    setDetalleAsignacion(assignmentDetailResponse);
    setDataHorarioFinal(scheduleData);
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  function promiseOptions(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  function handleChangeGrupo(ev) {
    if (profesor.value === "") {
      enqueueSnackbar("Debes de selecionar una clave profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      const regex = /^[a-zA-Z0-9]*$/;
      if (regex.test(ev.target.value)) {
        setGrupo(ev.target.value);
        setMateria({
          label: "",
          value: "",
        });
        setMateriaOptions([]);
      }
    }
  }

  async function searchMateria(id_campus, id_person, grupo, ciclo, direccion) {
    setSelectMateriaIsLoading(true);
    try {
      const options = [];

      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${id_person},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
          });
        });

        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  function handleHoraInicia(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      setHoraInicia(value);
    }
  }

  function handleHoraTermina(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      setHoraTermina(value);
    }
  }

  function handleMinutosInicia(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      setMinutosInicia(value);
    }
  }

  function handleMinutosTermina(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      setMinutosTermina(value);
    }
  }
  const horarios = `dias: ${moment(fechaMovimiento).format(
    "dddd"
  )} horas_inicio: ${horaInicia}:${minutosInicia} horas_fin: ${horaTermina}:${minutosTermina}`;
  async function getSalonOptions(id_tipo_salon) {
    setSelectSalonIsLoading(true);
    try {
      const endpoint = `/buscar?tipo=salon&valor=id_tipo_salon:${id_tipo_salon},id_campus:${campusId},fecha:${fechaMovimiento},hora_inicio:${horaInicia},hora_fin:${horaTermina}`;
      const response = await getRequest(endpoint);
      if (response.s === "OK") {
        const request = `/reportes/salones_ocupados?fecha_inicial=${fechaMovimiento}&fecha_final=${fechaMovimiento}&id_campus=${campusId}&horario=${horarios}`;
        const response2 = await getRequest(request);
        if (response2.s === "OK") {
          const busyClassroomsInitial = [];
          const stringDay = moment(fechaMovimiento)
            .format("dddd")[0]
            .toUpperCase();
          response2.d.forEach((responseItem) => {
            if (
              checkIfJExists(
                `${horaInicia}:${minutosInicia}`,
                `${horaTermina}:${minutosTermina}`,
                responseItem.timeslots,
                stringDay === "M" ? "Mi" : stringDay
              )
            ) {
              busyClassroomsInitial.push(responseItem.id_classroom);
            }
          });
          const dataObjectInicio = response.d
            .map((item, i) => {
              if (!busyClassroomsInitial.includes(`${item.id_salon}`)) {
                const data_ = {
                  label: item.nombre,
                  value: item.id_salon,
                };
                return data_;
              }
              return null;
            })
            .filter((item) => item !== null);
          setSalonOptions(dataObjectInicio);
        } else {
          enqueueSnackbar(response2.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectSalonIsLoading(false);
    }
  }

  function handleChangeTipoSalon(ev) {
    setTipoSalon(ev);
    getSalonOptions(ev.value);
  }

  function handleChangeSalon(ev) {
    setSalon(ev);
  }

  async function getTipoSalonCatalogo() {
    setSelectTipoSalonIsLoading(true);

    try {
      const endpoint = `/catalogo?id=24`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["type"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setTipoSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectTipoSalonIsLoading(false);
    }
  }

  async function handleSubmit() {
    if (
      profesor.value === "" ||
      materia.value === "" ||
      fechaMovimiento === "" ||
      horaInicia === "" ||
      minutosInicia === "" ||
      horaTermina === "" ||
      minutosTermina === "" ||
      salon.value === "" ||
      tema === ""
    ) {
      enqueueSnackbar("Se deben llenar todos los datos de la clase", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const endpoint = `/asignaciones/clases-especiales/nueva`;
      const body = {
        id_asignacion: materia.value,
        id_profesor: profesor.value,
        id_profesor_nuevo: profesor.value,
        id_dictamen: null,
        fecha: fechaMovimiento,
        fechaNuevo: fechaMovimiento,
        hora_inicio: `${horaInicia}:${minutosInicia}`,
        hora_fin: `${horaTermina}:${minutosTermina}`,
        id_salon: salon.value,
        tema: tema,
        tipo_clase_especial: props.tipoClaseEspecial,
      };
      const response = await postRequest(endpoint, body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        props.handleClose();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    moment.locale("es");
  }, []);

  useEffect(() => {
    setTipoSalon({
      label: "",
      value: "",
    });
    setSalon({
      label: "",
      value: "",
    });
  }, [
    fechaMovimiento,
    horaInicia,
    minutosInicia,
    profesor,
    horaTermina,
    minutosTermina,
  ]);

  return (
    <>
      <NewModal
        title="Clase Adicional"
        open={props.open}
        handleClose={props.handleClose}
        marginTop={"-35px"}
        height={700}
        maxWidth={1150}
        loading={loading}
        overflowY={"unset"}
        handleSubmit={handleSubmit}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "30px", alignItems: "center" }}
          >
            <Grid item xs={1} style={{ marginLeft: "-3%" }}>
              <label className={styles.inputLabel}>Ciclo:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                className={styles.filterInput}
                style={{ width: "130%" }}
                value={props.ciclo}
                disabled
              />
            </Grid>

            <Grid item xs={1} style={{ marginLeft: "3%" }}>
              <label className={styles.inputLabel}>Dirección:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.direccion.label}
                disabled
              />
            </Grid>

            <Grid item xs={1}>
              <label className={styles.inputLabel}>Director:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={props.director}
                disabled
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "25px",
              alignItems: "center",
            }}
          >
            <Grid item xs={4}>
              <h4 className={styles.modalSectionName}>
                Datos Generales del Grupo
              </h4>
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={6} item style={{ padding: 2, marginTop: 5 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 20 }),
                }}
                loadOptions={promiseOptions}
                onChange={setProfesor}
                value={profesor}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={grupo}
                onChange={handleChangeGrupo}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={3}>
              <Select
                classNamePrefix="select-modal"
                options={materiaOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 19 }),
                }}
                isLoading={selectMateriaIsLoading}
                onChange={setMateria}
                value={materia}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <h4 className={styles.modalSectionName}>
                Datos de la Clase Adicional
              </h4>
            </Grid>
            <Grid item xs={9} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Fecha Movimiento:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                label="Seleccionar fecha"
                type="date"
                value={fechaMovimiento}
                onChange={(ev) => setFechaMovimiento(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "160px", fontSize: "16px" }}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Inicia:</label>
            </Grid>
            <Grid item xs={2} style={{ display: "flex" }}>
              <input
                className="filter-input"
                type="number"
                min="0"
                max="23"
                // placeholder="23"
                value={horaInicia}
                onChange={(e) => handleHoraInicia(e.target.value)}
              />
              <input
                className="filter-input"
                style={{ marginLeft: "1%" }}
                type="number"
                min="0"
                max="59"
                // placeholder="00"
                value={minutosInicia}
                onChange={(e) => handleMinutosInicia(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Termina:</label>
            </Grid>
            <Grid item xs={2} style={{ display: "flex" }}>
              <input
                className="filter-input"
                type="number"
                min="0"
                max="23"
                // placeholder="23"
                value={horaTermina}
                onChange={(e) => handleHoraTermina(e.target.value)}
              />
              <input
                className="filter-input"
                style={{ marginLeft: "1%" }}
                type="number"
                min="0"
                max="59"
                // placeholder="00"
                value={minutosTermina}
                onChange={(e) => handleMinutosTermina(e.target.value)}
              />
              {/* <input
                // className={styles.filterInput}
                className="filter-input"
                type='time'
                value={horaTermina}
                onChange={(e) => setHoraTermina(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tipo Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                className={styles.tipoSalon}
                classNamePrefix="select-modal"
                options={tipoSalonOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 15 }),
                }}
                isLoading={selectTipoSalonIsLoading}
                onChange={handleChangeTipoSalon}
                value={tipoSalon}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={salonOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 14 }),
                }}
                isLoading={selectSalonIsLoading}
                onChange={handleChangeSalon}
                value={salon}
              />
            </Grid>
            <Grid item xs={5} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tema:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 10px)",
                  marginRight: "10px",
                }}
                value={tema}
                onChange={(ev) => setTema(ev.target.value)}
              ></input>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Búsqueda de Faltas"
        open={modalBuscarFaltaIsOpen}
        handleClose={() => setModalBuscarFaltaIsOpen(false)}
        height={750}
        maxWidth={1000}
      >
        <div style={{ padding: "25px" }}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <label className={styles.inputLabel}>Clave Profesor</label>
            </Grid>
            <Grid item xs={8}>
              <input
                className={styles.filterInput}
                style={{ width: "150px", marginRight: "10px" }}
                value={"110910"}
              ></input>
              <input
                disabled
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 150px - 10px - 10px)",
                  marginRight: "10px",
                }}
                value={"LOVERA JURADO, ANYUMAR"}
              ></input>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <NewButton
                customText="Buscar"
                noIcon
                style={{ padding: "5px 15px" }}
                onClick={handleClickBuscarFalta}
              />
            </Grid>

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia</label>
            </Grid>
            <Grid item xs={5}>
              <input
                className={styles.filterInput}
                style={{ width: "150px", marginRight: "10px" }}
              ></input>
              <input
                disabled
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 150px - 10px - 10px)",
                  marginRight: "10px",
                }}
              ></input>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </div>

        <div style={{ height: "calc(100% - 150px)" }}>
          <DataTable
            headers={headers}
            data={dataFinal}
            loading={loadingTable}
            centrar={[3, 4]}
            paginate
            bordes
          />
        </div>
      </NewModal>
    </>
  );
}

export default Adicional;
