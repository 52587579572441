import { postRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const createCatalogo = async (id, name, body, setShowCreateModal, setLoading, setShowTable, setTableEdit, getDataTable) => {
    try {
        const payload = {
            ...body,
            type: `${name}`,
            id: id
        }
        delete payload.creado
        delete payload.creado_por
        delete payload.modificado
        delete payload.modificado_por
        setShowCreateModal(false)
        setLoading(true);
        setShowTable(false);
        const response = await postRequest(
            `/catalogo`,
            payload
        );
        if (response.s === "OK") {
            enqueueSnackbar(response.m, {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            });
            setTableEdit(false);
            getDataTable(id, false);
        } else {
            setShowCreateModal(false)
            setLoading(false);
            setShowTable(true);
            enqueueSnackbar(response.m, {
                variant: "error", autoHideDuration: null,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            });
        }
    } catch (error) {
        console.log(error)
        setShowCreateModal(false)
        setLoading(false);
        setShowTable(true);
    }
}