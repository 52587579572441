import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import EditButton from "../../Utils/Botones/EditButton";
import NewButton from "../../Utils/Botones/NewButton";
import DataTable from "../../Utils/DataTables";
import { ExportCSV } from "../../Utils/Excel";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import Contenedor from "../../Utils/Visuales/Contenedor";
import NewModal from "../../Utils/Visuales/NewModal";
import ModalAdelantada from "./Modales/Adelantada";
import ModalAdicional from "./Modales/Adicional";
import ModalReposicion from "./Modales/Reposicion";
import ModalReposicionIncapacidad from "./Modales/ReposicionIncapacidad";
import ModalSustitucion from "./Modales/Sustitucion";
import ModalSustitucionIncapacidad from "./Modales/SustitucionIncapacidad";
import ModalSustitucionPaternidad from "./Modales/SustitucionPaternidad";
import ModalVisitaDeCampo from "./Modales/VisitaDeCampo";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";

function AsignacionesClasesEspeciales() {
  const [, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalNuevoIsOpen, setModalNuevoIsOpen] = useState(false);
  const [tipoClase, setTipoClase] = useState("");
  const [modalReposicionIsOpen, setModalReposicionIsOpen] = useState(false);
  const [
    modalReposicionIncapacidadIsOpen,
    setModalReposicionIncapacidadIsOpen,
  ] = useState(false);
  const [modalAdelantadaIsOpen, setModalAdelantadaIsOpen] = useState(false);
  const [modalSustitucionIsOpen, setModalSustitucionIsOpen] = useState(false);
  const [
    modalSustitucionPaternidadIsOpen,
    setModalSustitucionPaternidadIsOpen,
  ] = useState(false);
  const [
    modalSustitucionIncapacidadIsOpen,
    setModalSustitucionIncapacidadIsOpen,
  ] = useState(false);
  const [modalVisitaDeCampoIsOpen, setModalVisitaDeCampoIsOpen] =
    useState(false);
  const [modalAdicionalIsOpen, setModalAdicionalIsOpen] = useState(false);
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [direccionValue, setDireccionValue] = useState("");
  const [direccionModalValue, setDireccionModalValue] = useState({
    label: "",
    value: "",
  });
  const [profesorValue, setProfesorValue] = useState("");
  const [diaValue, setDiaValue] = useState("");
  const [fechaInicioValue, setFechaInicioValue] = useState("");
  const [fechaFinValue, setFechaFinValue] = useState("");
  const [horaValue, setHoraValue] = useState("");
  const [minutosValue, setMinutosValue] = useState("");
  const [tipoClaseOptions, setTipoClaseOptions] = useState("");
  const [ciclo, setCiclo] = useState("");
  const [req, setReq] = useState(false);
  const [profesorModalValue, setProfesorModalValue] = useState("");
  const [grupoModalValue, setGrupoModalValue] = useState("");
  const [materiaModalValue, setMateriaModalValue] = useState("");
  const [fechaModalValue, setFechaModalValue] = useState("");
  const [iniciaModalValue, setIniciaModalValue] = useState("");
  const [terminaModalValue, setTerminaModalValue] = useState("");
  const [profesorSustitucionModal, setProfesorSustitucionModal] = useState("");
  const [temaModal, setTemaModal] = useState("");
  const [horarioModalValue, setHorarioModalValue] = useState("");
  const [fechaMovimientoModal, setFechaMovimientoModal] = useState("");
  const [tipoSalonModal, setTipoSalonModal] = useState("");
  const [salonModal, setSalonModal] = useState("");
  const [modalResponse, setModalResponse] = useState({
    nombre_direccion: "",
    director: "",
    campus: "",
    creado: "",
    creado_por: "",
  });
  const campusId = useSelector((state) => state.campusId);
  const permisos = useSelector((state) => state.permisos);
  const rol = useSelector((state) => state.rol);
  const [director, setDirector] = useState("");
  const [consultaModalIsOpen, setConsultaModalIsOpen] = useState(false);
  const [loadingModalConsulta, setLoadingModalConsulta] = useState(false);
  const [modalConsultaValues, setModalConsultaValues] = useState("");
  const [bajaModalIsOpen, setBajaModalIsOpen] = useState(false);
  const [loadingModalBaja, setLoadingModalBaja] = useState(false);
  const [loadingDisabled, setLoadingDisabled] = useState(true);
  const [modalBajaValues, setModalBajaValues] = useState("");
  const [motivoModalValue, setMotivoModalValue] = useState("");
  const [optionsMotivo, setOptionsMotivo] = useState([]);
  const [comentarioModalValue, setComentarioModalValue] = useState("");
  const [autorizarModalIsOpen, setAutorizarModalIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [modalConfirmarAutorizacion, setModalConfirmarAutorizacion] =
    useState(false);
  const [
    loadingModalConfirmarAutorizacion,
    setLoadingModalConfirmarAutorizacion,
  ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const headers = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "Grupo",
      col: 2,
    },
    {
      name: "Clave Materia",
      col: 3,
    },
    {
      name: "Nombre Materia",
      col: 4,
    },
    {
      name: "Fecha Clase",
      col: 5,
    },
    {
      name: "Tipo Clase",
      col: 6,
    },
    {
      name: "Horario",
      col: 7,
    },
    {
      name: "Salón",
      col: 8,
    },
    {
      name: "Estado",
      col: 9,
    },
    {
      name: "Acciones",
      col: 10,
    },
  ];

  const heading = [
    "clave_profesor",
    "nombre_profesor",
    "grupo",
    "clave_materia",
    "nombre_materia",
    "fecha_clase",
    "tipo_clase",
    "horario",
    "salon",
    "estado",
  ];

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "143px",
    }),
  };

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      // setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  useEffect(() => {
    consultarCatalogo(27).then((data) => {
      const filterData = data.filter((item) => item.label !== "7 - Error Preasignacion");
      setOptionsMotivo(filterData);
    });
    consultarCatalogo(7).then(async (data) => {
      setOptionsDireccion(data);
      setLoadingDisabled(false);
    });
  }, []);

  async function openModalNuevo() {
    setCiclo("");
    setDireccionModalValue({
      label: "",
      value: "",
    });
    setDirector("");
    setTipoClase("");
    setModalNuevoIsOpen(true);

    try {
      const endpoint = `/catalogo?id=22`;
      const response = await getRequest(endpoint);
      if (response.s === "OK") {
        const validIdIfRol = ["2", "4", "9"];
        const data = [
          {
            label: "2 - Reposición",
            value: "2",
          },
          {
            label: "3 - Adicional",
            value: "3",
          },
          {
            label: "4 - Reposición Adelantada",
            value: "4",
          },
          {
            label: "5 - Sustitución",
            value: "5",
          },
          {
            label: "6 - Visita de Campo",
            value: "6",
          },
          {
            label: "7 - Visitas Guiadas",
            value: "7",
          },
          {
            label: "9 - Reposición por Incapacidad",
            value: "9",
          },
          {
            label: "10 - Sustitución por Incapacidad",
            value: "10",
          },
          {
            label: "16 - Sustitución por Paternidad",
            value: "16",
          },
        ];
        if (rol === "Coordinador de Apoyo") {
          const newData = data.filter((item) =>
            validIdIfRol.includes(item.value)
          );
          setTipoClaseOptions(newData);
        } else {
          setTipoClaseOptions(data);
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      // setLoadingModal(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function handleChangeTipoClase(e) {
    setTipoClase(e.value);
  }

  async function getModalResponse() {
    try {
      const response = await getRequest(
        `/direccion?id=${direccionModalValue.value}&id_campus=${campusId}`
      );
      if (response.s === "OK") {
        const datos = {
          nombre_direccion: response.d.nombre,
          director: response.d.director,
          campus: response.d.campus,
          creado: response.d.creado,
          creado_por: response.d.creado_por,
        };
        setModalResponse(datos);
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmit() {
    if (ciclo === "") {
      enqueueSnackbar("Debe seleccionar un ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (direccionModalValue.value === "") {
      enqueueSnackbar("Debe seleccionar una dirección", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (tipoClase === "") {
      enqueueSnackbar("Debe seleccionar un tipo de clase", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    setModalNuevoIsOpen(false);

    if (tipoClase === "2") {
      setModalReposicionIsOpen(true);
    } else if (tipoClase === "3") {
      getModalResponse();
      setModalAdicionalIsOpen(true);
    } else if (tipoClase === "4") {
      setModalAdelantadaIsOpen(true);
    } else if (tipoClase === "5") {
      setModalSustitucionIsOpen(true);
    } else if (tipoClase === "6") {
      setModalVisitaDeCampoIsOpen(true);
    } else if (tipoClase === "7") {
      setModalVisitaDeCampoIsOpen(true);
    } else if (tipoClase === "9") {
      setModalReposicionIncapacidadIsOpen(true);
    } else if (tipoClase === "10") {
      setModalSustitucionIncapacidadIsOpen(true);
    } else if (tipoClase === "16") {
      setModalSustitucionPaternidadIsOpen(true);
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const diaOptions = [
    { label: "Lunes", value: "lunes" },
    { label: "Martes", value: "martes" },
    { label: "Miércoles", value: "miercoles" },
    { label: "Jueves", value: "jueves" },
    { label: "Viernes", value: "viernes" },
    { label: "Sábado", value: "sabado" },
    { label: "Domingo", value: "domingo" },
  ];

  const openModalConsulta = async (
    id,
    nombre_direccion,
    tipo_clase,
    clave_profesor,
    nombre_profesor,
    grupo,
    clave_materia,
    nombre_materia,
    fecha_clase,
    horario,
    salon,
    estado,
    tema,
    detalle,
    nombre_profesor_sustituir,
    fecha_original,
    creado,
    creado_por
  ) => {
    setConsultaModalIsOpen(true);
    setLoadingModalConsulta(true);
    setModalConsultaValues({
      id: id,
      direccion: nombre_direccion,
      tipo_clase: tipo_clase,
      clave_profesor: clave_profesor,
      nombre_profesor: nombre_profesor,
      grupo: grupo,
      clave_materia: clave_materia,
      nombre_materia: nombre_materia,
      fecha: fecha_clase,
      horario: horario,
      salon: salon,
      estado: estado,
      tema: tema,
      nombre_profesor_sustituir:
        tipo_clase === "Adicional" ? "" : nombre_profesor_sustituir,
      fecha_original: tipo_clase === "Adicional" ? "" : fecha_original,
      creado,
      creado_por,
      // detalle: detalle
    });
    setLoadingModalConsulta(false);
  };

  const openModalBaja = async (
    id,
    id_profesor,
    estado,
    direccion,
    director,
    clave_profesor,
    nombre_profesor,
    motivo_baja,
    comentario_baja
  ) => {
    setBajaModalIsOpen(true);
    setLoadingModalBaja(true);
    if (estado === "B") {
      setButtonDisabled(true);
      setMotivoModalValue(motivo_baja);
      setComentarioModalValue(comentario_baja);
    } else {
      setButtonDisabled(false);
    }
    setModalBajaValues({
      id: id,
      id_profesor: id_profesor,
      direccion: direccion,
      nombre: director,
      clave_profesor: clave_profesor,
      nombre_profesor: nombre_profesor,
    });
    setLoadingModalBaja(false);
  };

  const openModalAutorizar = async (
    id,
    estado,
    tipo_clase,
    clave_profesor,
    nombre_profesor,
    grupo,
    clave_materia,
    nombre_materia,
    fecha_clase,
    horario,
    salon,
    tema,
    detalle,
    nombre_profesor_sustituir,
    fecha_original,
    id_assignment
  ) => {
    setAutorizarModalIsOpen(true);
    setLoadingModalConsulta(true);
    if (estado === "B") {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setModalConsultaValues({
      id: id,
      id_assignment: id_assignment,
      direccion: direccionValue.label,
      tipo_clase: tipo_clase,
      clave_profesor: clave_profesor,
      nombre_profesor: nombre_profesor,
      grupo: grupo,
      clave_materia: clave_materia,
      nombre_materia: nombre_materia,
      fecha: fecha_clase,
      horario: horario,
      salon: salon,
      tema: tema,
      nombre_profesor_sustituir: nombre_profesor_sustituir,
      fecha_original: fecha_original,
    });
    setLoadingModalConsulta(false);
  };

  async function handleClickBuscar() {
    if (fechaInicioValue === "") {
      enqueueSnackbar("Debe seleccionar una fecha inicio", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (fechaFinValue === "") {
      enqueueSnackbar("Debe seleccionar una fecha fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    } else if (fechaInicioValue > fechaFinValue) {
      enqueueSnackbar("Fecha inicio debe ser menor que fecha fin", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }
    setLoading(true);
    try {
      let consulta = `/asignaciones/clases-especiales?fecha_inicio=${fechaInicioValue}&fecha_fin=${fechaFinValue}&id_campus=${campusId}`;
      if (direccionValue) consulta += `&id_direccion=${direccionValue.value}`;
      if (profesorValue) consulta += `&id_profesor=${profesorValue.value}`;
      if (diaValue) consulta += `&dia=${diaValue.value}`;
      if (horaValue && minutosValue)
        consulta += `&hora=${horaValue}:${minutosValue}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        let data = [];
        if (response.d.length > 0) {
          response.d.forEach((item) => {
            data.push([
              item.clave_profesor,
              item.nombre_profesor,
              item.grupo,
              item.clave_materia,
              item.nombre_materia,
              item.fecha_clase,
              item.tipo_clase,
              item.horario,
              item.salon,
              item.estado,
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <EditButton
                  onClick={() =>
                    openModalConsulta(
                      item.id_assignment_schedule,
                      item.nombre_direccion,
                      item.tipo_clase,
                      item.clave_profesor,
                      item.nombre_profesor,
                      item.grupo,
                      item.clave_materia,
                      item.nombre_materia,
                      item.fecha_clase,
                      item.horario,
                      item.salon,
                      item.estado,
                      item.tema,
                      item.detalle,
                      item.nombre_profesor_a_sustituir,
                      item.fecha_clase_original,
                      item.creado,
                      item.creado_por
                    )
                  }
                >
                  Consultar
                </EditButton>
                <EditButton
                  style={{
                    display:
                      permisos["Asignaciones->Clases Especiales"].eliminar ===
                      "1"
                        ? "block"
                        : "none",
                  }}
                  onClick={() =>
                    openModalBaja(
                      item.id_assignment,
                      item.id_profesor,
                      item.estado,
                      item.nombre_direccion,
                      item.nombre_director,
                      item.clave_profesor,
                      item.nombre_profesor,
                      item.motivo_baja,
                      item.comentario_baja
                    )
                  }
                >
                  Baja
                </EditButton>
                <EditButton
                  style={{
                    display:
                      permisos["Asignaciones->Clases Especiales"].modificar ===
                      "1"
                        ? "block"
                        : "none",
                  }}
                  onClick={() =>
                    openModalAutorizar(
                      item.id_assignment_schedule,
                      item.estado,
                      item.tipo_clase,
                      item.clave_profesor,
                      item.nombre_profesor,
                      item.grupo,
                      item.clave_materia,
                      item.nombre_materia,
                      item.fecha_clase,
                      item.horario,
                      item.salon,
                      item.tema,
                      item.detalle,
                      item.nombre_profesor_a_sustituir,
                      item.fecha_clase_original,
                      item.id_assignment
                    )
                  }
                >
                  Autorizar
                </EditButton>
              </div>,
              item.id_assignment,
              item.id_assignment_schedule,
            ]);
          });
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setDataOriginal(data);
          setDataFinal(data);
        } else {
          setDataOriginal([]);
          setDataFinal([]);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setDataOriginal([]);
        setDataFinal([]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const DownloadExcel = () => {
    setReq(true);
    const data_ = dataFinal.map((row) => ({
      clave_profesor: row[0],
      nombre_profesor: row[1],
      grupo: row[2],
      clave_materia: row[3],
      nombre_materia: row[4],
      fecha_clase: row[5],
      tipo_clase: row[6],
      horario: row[7],
      salon: row[8],
      estado: row[9],
    }));
    var today = new Date();
    var fecha = `${today.getFullYear()}${
      today.getMonth() + 1
    }${today.getDate()}`;
    var hora = `${today.getHours()}${today.getMinutes()}`;
    ExportCSV(data_, `Clases_especiales_${fecha}_${hora}`, heading);
    setReq(false);
  };

  const handleSubmitBaja = async () => {
    let valido = true;
    if (motivoModalValue === "") {
      enqueueSnackbar("Falta Seleccionar Motivo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (comentarioModalValue === "") {
      enqueueSnackbar("Falta Llenar Campo Comentario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      try {
        setLoadingModalBaja(true);
        const payload = {
          id_asignacion: modalBajaValues.id,
          id_profesor: modalBajaValues.id_profesor,
          id_motivo_baja: parseInt(motivoModalValue.value),
          comentario: comentarioModalValue,
        };
        const response = await postRequest(
          "/asignaciones/clases-especiales/baja",
          payload
        );
        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          setMotivoModalValue("");
          setComentarioModalValue("");
          setModalBajaValues("");
          handleClickBuscar();
          setLoadingModalBaja(false);
          setBajaModalIsOpen(false);
        } else {
          setLoadingModalBaja(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function getDirector(value) {
    try {
      const consulta = `/direccion?id=${value}&id_campus=${campusId}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        setDirector(response.d.director || "");
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  function handleHoraValue(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      setHoraValue(value);
    }
  }

  function handleMinutosValue(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      setMinutosValue(value);
    }
  }

  const handleDireccion = (e) => {
    setDireccionModalValue(e);
    getDirector(e.value);
  };

  const handleDireccionPrincipal = (e) => {
    setDireccionValue(e);
    getDirector(e.value);
  };

  const handleAutorizar = async () => {
    try {
      setLoadingModalConfirmarAutorizacion(true);
      const json = {
        id_assignment: modalConsultaValues.id_assignment,
      };
      const response = await postRequest(
        "/asignaciones/clases-especiales/autorizar",
        json
      );
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingModalConfirmarAutorizacion(false);
      setModalConfirmarAutorizacion(false);
      setAutorizarModalIsOpen(false);
    }
  };

  const handleCloseModal = () => {
    setHorarioModalValue("");
    setFechaMovimientoModal("");
    setTipoSalonModal("");
    setSalonModal("");
    setModalAdelantadaIsOpen(false);
    setCiclo("");
    setDireccionModalValue("");
    setTipoClase("");
    setProfesorModalValue("");
    setGrupoModalValue("");
    setMateriaModalValue("");
    setFechaModalValue("");
    setIniciaModalValue("");
    setTerminaModalValue("");
    setProfesorSustitucionModal("");
    setTemaModal("");
    setModalSustitucionIsOpen(false);
  };

  return (
    <Contenedor title="Asignaciones / Clases Especiales / Consulta y Bajas">
      <div className={styles.inputsClasesEspeciales}>
        <Grid container style={{ alignItems: "center" }} spacing={2}>
          <Grid item xs={2}>
            <NewButton
              style={{
                display:
                  permisos["Asignaciones->Clases Especiales"].escribir === "1"
                    ? "block"
                    : "none",
              }}
              onClick={openModalNuevo}
              noIcon
            />
          </Grid>
          <Grid item xs={10} />
          <Grid item xs={2} style={{ marginLeft: "-7%" }}>
            <label>Dirección:</label>
          </Grid>
          <Grid
            item
            xs={2}
            style={{ padding: 1, zIndex: 80, marginTop: 5, marginLeft: "-1%" }}
          >
            <Select
              className="select-modal-container input-tipo"
              classNamePrefix="select-modal"
              isDisabled={loadingDisabled}
              isClearable={true}
              value={direccionValue}
              options={optionsDireccion}
              onChange={(e) => handleDireccionPrincipal(e)}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ padding: 1, zIndex: 80, marginTop: 5, marginLeft: "1%" }}
          >
            <input
              className={styles.filterInput}
              disabled
              value={direccionValue ? director : ""}
              style={{ width: "90%" }}
            />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-6%" }}>
            <label>Clave Profesor:</label>
          </Grid>
          <Grid
            xs={2}
            item
            style={{ padding: 1, zIndex: 82, marginTop: 5, marginLeft: "-1%" }}
          >
            <AsyncSelect
              className="select-modal-container"
              classNamePrefix="select-modal"
              styles={asyncSelectStyles}
              loadOptions={promiseOptions}
              onChange={setProfesorValue}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={2} />

          <Grid item xs={2} style={{ marginLeft: "-7%" }}>
            <label>Fecha Inicio(*):</label>
          </Grid>
          <Grid item xs={2} style={{ zIndex: 78, marginLeft: "-1.5%" }}>
            <input
              className="filter-input"
              label="Seleccionar fecha"
              type="date"
              value={fechaInicioValue}
              onChange={(ev) => setFechaInicioValue(ev.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "105%" }}
            />
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-5%" }}>
            <label>Fecha Fin(*):</label>
          </Grid>
          <Grid item xs={2} style={{ marginLeft: "-1.5%" }}>
            <input
              className="filter-input"
              label="Seleccionar fecha"
              type="date"
              value={fechaFinValue}
              onChange={(ev) => setFechaFinValue(ev.target.value)}
              disabled={fechaInicioValue !== "" ? false : true}
              min={fechaInicioValue}
              style={{ width: "85%" }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "2%" }}>
            <label>Día:</label>
          </Grid>
          <Grid
            item
            xs={2}
            style={{ padding: 1, zIndex: 79, marginLeft: "-1%" }}
          >
            <Select
              className={styles.diaSelect}
              classNamePrefix="select-modal"
              options={diaOptions}
              onChange={setDiaValue}
              isClearable={true}
            />
          </Grid>
          <Grid item xs={1} style={{ marginLeft: "-2%" }}>
            <label>Hora:</label>
          </Grid>
          <Grid item xs={2} style={{ display: "flex", marginLeft: "-1%" }}>
            <input
              className="filter-input"
              type="number"
              min="0"
              max="23"
              // placeholder="23"
              value={horaValue}
              onChange={(e) => handleHoraValue(e.target.value)}
            />
            <input
              className="filter-input"
              style={{ marginLeft: "1%" }}
              type="number"
              min="0"
              max="59"
              // placeholder="00"
              value={minutosValue}
              onChange={(e) => handleMinutosValue(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <NewButton
              disabled={dataFinal.length === 0 || req}
              customText="Descarga"
              noIcon
              onClick={DownloadExcel}
            />
          </Grid>
          <Grid item xs={9} />
          <Grid item xs={1}>
            <NewButton customText="Buscar" noIcon onClick={handleClickBuscar} />
          </Grid>
        </Grid>
      </div>

      <div style={{ height: "calc(100% - 180px)", marginTop: "20px" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
        />
      </div>

      <NewModal
        title="Consulta Clase Especial"
        open={consultaModalIsOpen}
        handleClose={() => {
          setModalConsultaValues("");
          setConsultaModalIsOpen(false);
        }}
        loading={loadingModalConsulta}
        height={500}
        maxWidth={800}
        noSaveBtn
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              style={{ marginTop: "-0.5%", marginLeft: "1%" }}
            >
              <Grid
                container
                style={{ alignItems: "center", marginLeft: "9%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Dirección:</label>
                </Grid>
                <Grid xs={2} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.direccion}
                  />
                </Grid>
                <Grid xs={1} md={5} />
                <Grid
                  container
                  xs={1}
                  md={2}
                  style={{
                    marginTop: "2%",
                    marginLeft: "-7%",
                    alignItems: "center",
                  }}
                >
                  <label>Tipo de Clase:</label>
                </Grid>
                <Grid
                  xs={2}
                  md={5}
                  style={{ marginTop: "2%", marginLeft: "-1.3%" }}
                >
                  <input
                    disabled
                    className="filter-input"
                    value={modalConsultaValues.tipo_clase}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Clave Profesor:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.clave_profesor}
                  />
                </Grid>
                <Grid xs={1} md={8} style={{ marginLeft: "6.5%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "94.5%" }}
                    value={modalConsultaValues.nombre_profesor}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Grupo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.grupo}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "7%" }}>
                  <label>Materia:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.clave_materia}
                  />
                </Grid>
                <Grid xs={1} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.nombre_materia}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Fecha:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.fecha}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "7%" }}>
                  <label>Horario:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.horario}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  <label>Estado:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.estado}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Salón:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.salon}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Tema:</label>
                </Grid>
                <Grid xs={1} md={6}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "155%", textAlign: "left" }}
                    value={modalConsultaValues.tema}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Prof a Sustituir:</label>
                </Grid>
                <Grid xs={1} md={6}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "155%", textAlign: "left" }}
                    value={modalConsultaValues.nombre_profesor_sustituir}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Fecha Original:</label>
                </Grid>
                <Grid xs={1} md={4}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.fecha_original}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Creado Por:</label>
                </Grid>
                <Grid xs={1} md={6}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "155%" }}
                    value={modalConsultaValues.creado_por}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Creado:</label>
                </Grid>
                <Grid xs={1} md={4}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.creado}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Baja Clase Especial"
        open={bajaModalIsOpen}
        handleClose={() => {
          setMotivoModalValue("");
          setComentarioModalValue("");
          setModalBajaValues("");
          setBajaModalIsOpen(false);
        }}
        loading={loadingModalBaja}
        height={420}
        maxWidth={800}
        handleSubmit={handleSubmitBaja}
        noSaveBtn={buttonDisabled}
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              style={{ marginTop: "-0.5%", marginLeft: "1%" }}
            >
              <Grid
                container
                style={{ alignItems: "center", marginLeft: "9%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Dirección:</label>
                </Grid>
                <Grid xs={2} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalBajaValues.direccion}
                  />
                </Grid>
                <Grid xs={2} md={5} style={{ marginLeft: "1%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "103%" }}
                    value={modalBajaValues.nombre}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Clave Profesor:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalBajaValues.clave_profesor}
                  />
                </Grid>
                <Grid xs={1} md={8} style={{ marginLeft: "6.5%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "94.5%" }}
                    value={modalBajaValues.nombre_profesor}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: "12%", alignItems: "center" }}
              >
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Motivo:</label>
                </Grid>
                {buttonDisabled ? (
                  <Grid xs={1} md={3}>
                    <input
                      disabled
                      className="filter-input"
                      style={{ width: "94.5%" }}
                      value={motivoModalValue}
                    />
                  </Grid>
                ) : (
                  <Grid xs={1} md={3}>
                    <Select
                      className="select-modal-container input-tipo"
                      classNamePrefix="select-modal"
                      isDisabled={buttonDisabled}
                      styles={selectStyles}
                      value={motivoModalValue}
                      options={optionsMotivo}
                      onChange={setMotivoModalValue}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Comentario:</label>
                </Grid>
                <Grid xs={1} md={9}>
                  <TextareaAutosize
                    className="filter-input value-comentario"
                    maxRows={7}
                    style={{
                      width: "103%",
                      height: 80,
                      resize: "none",
                      fontSize: "16px",
                    }}
                    disabled={buttonDisabled}
                    value={comentarioModalValue}
                    onChange={(e) => setComentarioModalValue(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Autorizar Clase Especial"
        open={autorizarModalIsOpen}
        handleClose={() => {
          setModalConsultaValues("");
          setAutorizarModalIsOpen(false);
        }}
        loading={loadingModalConsulta}
        height={500}
        maxWidth={800}
        noSaveBtn
        handleSubmit={() => setModalConfirmarAutorizacion(true)}
        autorizar
      >
        <div className="inputs-asignaciones" style={{ marginTop: "25px" }}>
          <Grid container style={{ position: "relative", zIndex: 9 }}>
            <Grid
              container
              item
              xs={12}
              sm={12}
              style={{ marginTop: "-0.5%", marginLeft: "1%" }}
            >
              <Grid
                container
                style={{ alignItems: "center", marginLeft: "9%" }}
              >
                <Grid item xs={1} md={1}>
                  <label>Dirección:</label>
                </Grid>
                <Grid xs={2} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.direccion}
                  />
                </Grid>
                <Grid xs={1} md={5} />
                <Grid
                  xs={1}
                  md={2}
                  style={{
                    marginLeft: "-8.3%",
                    marginTop: "2%",
                    alignItems: "center",
                  }}
                >
                  <label>Tipo de Clase:</label>
                </Grid>
                <Grid xs={1} md={5} style={{ marginTop: "2%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "100%" }}
                    value={modalConsultaValues.tipo_clase}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Clave Profesor:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.clave_profesor}
                  />
                </Grid>
                <Grid xs={1} md={8} style={{ marginLeft: "6.5%" }}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "94.5%" }}
                    value={modalConsultaValues.nombre_profesor}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Grupo:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.grupo}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "7%" }}>
                  <label>Materia:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.clave_materia}
                  />
                </Grid>
                <Grid xs={1} md={5}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.nombre_materia}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Fecha:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.fecha}
                  />
                </Grid>
                <Grid xs={1} md={1} style={{ marginLeft: "7%" }}>
                  <label>Horario:</label>
                </Grid>
                <Grid xs={1} md={2}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "90%" }}
                    value={modalConsultaValues.horario}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Salón:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "160%" }}
                    value={modalConsultaValues.salon}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={1} style={{ marginLeft: "8.3%" }}>
                  <label>Tema:</label>
                </Grid>
                <Grid xs={1} md={6}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "155%", textAlign: "left" }}
                    value={modalConsultaValues.tema}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Prof a Sustituir:</label>
                </Grid>
                <Grid xs={1} md={6}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "155%", textAlign: "left" }}
                    value={modalConsultaValues.nombre_profesor_sustituir}
                  />
                </Grid>
              </Grid>
              <Grid container style={{ marginTop: "2%", alignItems: "center" }}>
                <Grid xs={1} md={2}>
                  <label>Fecha Original:</label>
                </Grid>
                <Grid xs={1} md={1}>
                  <input
                    disabled
                    className="filter-input"
                    style={{ width: "162%" }}
                    value={modalConsultaValues.fecha_original}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Clase Especial"
        open={modalNuevoIsOpen}
        handleClose={() => setModalNuevoIsOpen(false)}
        height={200}
        maxWidth={1000}
        maxHeight={"unset"}
        overflowY={"unset"}
        handleSubmit={handleSubmit}
      >
        <div style={{ padding: "25px" }}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item xs={1} style={{ marginLeft: "6.2%" }}>
              <label>Ciclo (*):</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={ciclo}
                onChange={(ev) => setCiclo(ev.target.value)}
              />
            </Grid>
            <Grid item xs={1} md={6} />
            <Grid item xs={1} style={{ marginLeft: "6.2%" }}>
              <label className={styles.inputLabel}>Dirección(*):</label>
            </Grid>
            <Grid item xs={4}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                value={direccionModalValue}
                options={optionsDireccion}
                onChange={(e) => handleDireccion(e)}
              />
            </Grid>
            <Grid item xs={4} style={{ marginLeft: "2%" }}>
              <input className={styles.filterInput} disabled value={director} />
            </Grid>
            <Grid item xs={1} md={2} />
            <Grid item xs={1} md={2}>
              <label>Tipo de clase (*):</label>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: "-2%" }}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={tipoClaseOptions}
                onChange={handleChangeTipoClase}
                name="tipo"
                placeholder="Selección..."
                isSearchable={false}
              />
            </Grid>
            <Grid item xs={5} />
          </Grid>
        </div>
      </NewModal>

      <ModalReposicion
        open={modalReposicionIsOpen}
        handleClose={() => {
          handleCloseModal();
          setModalReposicionIsOpen(false);
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
      />

      <ModalReposicionIncapacidad
        open={modalReposicionIncapacidadIsOpen}
        handleClose={() => {
          handleCloseModal();
          setModalReposicionIncapacidadIsOpen(false);
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
      />

      <ModalAdelantada
        open={modalAdelantadaIsOpen}
        handleClose={() => {
          setModalAdelantadaIsOpen(false);
          handleCloseModal();
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
        claveProfesor={profesorModalValue}
        setClaveProfesor={setProfesorModalValue}
        grupo={grupoModalValue}
        setGrupo={setGrupoModalValue}
        materia={materiaModalValue}
        setMateria={setMateriaModalValue}
        fecha={fechaModalValue}
        setFecha={setFechaModalValue}
        horario={horarioModalValue}
        setHorario={setHorarioModalValue}
        profesorSustitucion={profesorSustitucionModal}
        setProfesorSustitucion={setProfesorSustitucionModal}
        fechaMovimiento={fechaMovimientoModal}
        setFechaMovimiento={setFechaMovimientoModal}
        inicia={iniciaModalValue}
        setInicia={setIniciaModalValue}
        termina={terminaModalValue}
        setTermina={setTerminaModalValue}
        tipoSalon={tipoSalonModal}
        setTipoSalon={setTipoSalonModal}
        salon={salonModal}
        setSalon={setSalonModal}
        tema={temaModal}
        setTema={setTemaModal}
      />

      <ModalSustitucion
        open={modalSustitucionIsOpen}
        handleClose={() => {
          setModalSustitucionIsOpen(false);
          handleCloseModal();
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
        clave_profesor={profesorModalValue}
        setClave_profesor={setProfesorModalValue}
        grupo={grupoModalValue}
        setGrupo={setGrupoModalValue}
        materia={materiaModalValue}
        setMateria={setMateriaModalValue}
        fecha={fechaModalValue}
        setFecha={setFechaModalValue}
        inicia={iniciaModalValue}
        setInicia={setIniciaModalValue}
        termina={terminaModalValue}
        setTermina={setTerminaModalValue}
        salon={salonModal}
        profesorSustitucion={profesorSustitucionModal}
        setProfesorSustitucion={setProfesorSustitucionModal}
        tema={temaModal}
        setTema={setTemaModal}
      />

      <ModalSustitucionPaternidad
        open={modalSustitucionPaternidadIsOpen}
        handleClose={() => {
          handleCloseModal();
          setModalSustitucionPaternidadIsOpen(false);
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
      />

      <ModalSustitucionIncapacidad
        open={modalSustitucionIncapacidadIsOpen}
        handleClose={() => {
          handleCloseModal();
          setModalSustitucionIncapacidadIsOpen(false);
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
      />

      <ModalVisitaDeCampo
        open={modalVisitaDeCampoIsOpen}
        handleClose={() => {
          handleCloseModal();
          setModalVisitaDeCampoIsOpen(false);
        }}
        ciclo={ciclo}
        direccion={direccionModalValue}
        director={director}
        tipoClaseEspecial={tipoClase}
      />

      <ModalAdicional
        open={modalAdicionalIsOpen}
        handleClose={() => {
          handleCloseModal();
          setModalAdicionalIsOpen(false);
        }}
        ciclo={ciclo}
        director={director}
        tipoClaseEspecial={tipoClase}
        direccion={direccionModalValue}
      />

      <NewModal
        title={"Confirmación"}
        open={modalConfirmarAutorizacion}
        handleClose={() => {
          setModalConfirmarAutorizacion(false);
        }}
        handleSubmit={handleAutorizar}
        loading={loadingModalConfirmarAutorizacion}
        style
        height={100}
        maxWidth={600}
        marginTop={"8%"}
      >
        <Grid container style={{ marginTop: "2%", marginLeft: "-1%" }}>
          <Grid
            container
            item
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: "0.5%" }}
          >
            <Grid item xs={12} md={5} style={{ textAlign: "center" }}>
              <label>¿Desea autorizar la clase especial?</label>
            </Grid>
          </Grid>
        </Grid>
      </NewModal>
    </Contenedor>
  );
}

export default AsignacionesClasesEspeciales;
