import { Grid } from "react-bootstrap";

export const CommentInput = ({ label, value, onChange, rows }) => {
  return (
    <Grid style={{ marginTop: 20, display: "flex", width: "100%" }}>
      <Grid
        style={{
          textAlign: "center",
          marginRight: 17,
        }}
      >
        <label>{label}:</label>
      </Grid>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <textarea
          className="text-area-modal"
          rows={rows}
          placeholder="Escribe aquí"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        ></textarea>
      </Grid>
    </Grid>
  );
};
