export const getTranslateHeader = (value) => {
    switch (value) {
        case 'clave':
            return 'Clave'
        case 'creado_por':
            return 'Creado Por'
        case 'fecha_inicio':
            return 'Fecha Inicio'
        case 'fecha_fin':
            return 'Fecha Fin'
        case 'fecha_proceso':
            return 'Fecha Proceso'
        case 'fecha_pago':
            return 'Fecha Pago'
        case 'modificado_por':
            return 'Modificado Por'
        case 'creado':
            return 'Fecha creado'
        case 'modificado':
            return 'Fecha modificada'
        case 'name':
            return 'Nombre'
        case 'type':
            return 'Tipo'
        case 'año':
            return 'Año'
        case 'clave_campus':
            return 'Clave Campus'
        case 'nombre_campus':
            return 'Nombre Campus'
        case 'clave_tipo_salon':
            return 'clave Tipo Salon'
        case 'tipo_salon':
            return 'Tipo Salon'
        case 'nombre_salon':
            return 'Nombre Salon'
        default:
            return value
    }
}