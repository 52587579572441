export const EXCELformatForReporteIndicdoresAcademicosSemanales = (data) => {
  const totalTableInfo = {
    Prog_h: 0,
    Faltas_Brutas_h: 0,
    "% F_Brutas_h": 0,
    Aut_h: 0,
    Rep_h: 0,
    Faltas_Netas_h: 0,
    "% F_netas_h": 0,
    Sust_h: 0,
    Adic_h: 0,
    Visit_h: 0,
    Prog_s: 0,
    Faltas_Brutas_s: 0,
    Aut_s: 0,
    Rep_s: 0,
    Faltas_Netas_s: 0,
    Sust_s: 0,
    Adic_s: 0,
    Visit_s: 0,
    "S.Ant_s": 0,
    Ret_s: 0,
    Ret_m: 0,
    Can_m: 0,
  };
  const data_ = data.map((e, i) => {
    const data2 = {
      Ciclo: e[0],
      Campus: e[1],
      Escuela: e[2],
      Dirección: e[3],
      Director: e[4],
      Prog_h: e[6],
      Faltas_Brutas_h: e[7],
      "% F_Brutas_h": e[8],
      Aut_h: e[9],
      Rep_h: e[10],
      Faltas_Netas_h: e[11],
      "% F_netas_h": e[12],
      Sust_h: e[13],
      Adic_h: e[14],
      Visit_h: e[15],
      Prog_s: e[16],
      Faltas_Brutas_s: e[17],
      Aut_s: e[18],
      Rep_s: e[19],
      Faltas_Netas_s: e[20],
      Sust_s: e[21],
      Adic_s: e[22],
      Visit_s: e[23],
      "S.Ant_s": e[24],
      Ret_s: e[25],
      Ret_m: e[26],
      Can_m: e[27],
    };
    totalTableInfo.Prog_h = totalTableInfo.Prog_h + parseFloat(data[i][6]);
    totalTableInfo.Faltas_Brutas_h =
      totalTableInfo.Faltas_Brutas_h + parseFloat(data[i][7]);
    totalTableInfo["% F_Brutas_h"] =
      totalTableInfo["% F_Brutas_h"] + parseFloat(data[i][8]);
    totalTableInfo.Aut_h = totalTableInfo.Aut_h + parseFloat(data[i][9]);
    totalTableInfo.Rep_h = totalTableInfo.Rep_h + parseFloat(data[i][10]);
    totalTableInfo.Faltas_Netas_h =
      totalTableInfo.Faltas_Netas_h + parseFloat(data[i][11]);
    totalTableInfo["% F_netas_h"] =
      totalTableInfo["% F_netas_h"] + parseFloat(data[i][12]);
    totalTableInfo.Sust_h = totalTableInfo.Sust_h + parseFloat(data[i][13]);
    totalTableInfo.Adic_h = totalTableInfo.Adic_h + parseFloat(data[i][14]);
    totalTableInfo.Visit_h = totalTableInfo.Visit_h + parseFloat(data[i][15]);
    totalTableInfo.Prog_s = totalTableInfo.Prog_s + parseFloat(data[i][16]);
    totalTableInfo.Faltas_Brutas_s =
      totalTableInfo.Faltas_Brutas_s + parseFloat(data[i][17]);
    totalTableInfo.Aut_s = totalTableInfo.Aut_s + parseFloat(data[i][18]);
    totalTableInfo.Rep_s = totalTableInfo.Rep_s + parseFloat(data[i][19]);
    totalTableInfo.Faltas_Netas_s =
      totalTableInfo.Faltas_Netas_s + parseFloat(data[i][20]);
    totalTableInfo.Sust_s = totalTableInfo.Sust_s + parseFloat(data[i][21]);
    totalTableInfo.Adic_s = totalTableInfo.Adic_s + parseFloat(data[i][22]);
    totalTableInfo.Visit_s = totalTableInfo.Visit_s + parseFloat(data[i][23]);
    totalTableInfo["S.Ant_s"] =
      totalTableInfo["S.Ant_s"] + parseFloat(data[i][24]);
    totalTableInfo.Ret_s = totalTableInfo.Ret_s + parseFloat(data[i][25]);
    totalTableInfo.Ret_m = totalTableInfo.Ret_m + parseFloat(data[i][26]);
    totalTableInfo.Can_m = totalTableInfo.Can_m + parseFloat(data[i][27]);
    return data2;
  });
  data_.push({
    Ciclo: "",
    Campus: "",
    Escuela: "",
    Dirección: "",
    Director: "Totales",
    Prog_h: parseFloat(parseFloat(totalTableInfo.Prog_h).toFixed(0)),
    Faltas_Brutas_h: parseFloat(
      parseFloat(totalTableInfo.Faltas_Brutas_h).toFixed(0)
    ),
    "% F_Brutas_h": parseFloat(
      parseFloat(totalTableInfo["% F_Brutas_h"]).toFixed(2)
    ),
    Aut_h: parseFloat(parseFloat(totalTableInfo.Aut_h).toFixed(0)),
    Rep_h: parseFloat(parseFloat(totalTableInfo.Rep_h).toFixed(0)),
    Faltas_Netas_h: parseFloat(
      parseFloat(totalTableInfo.Faltas_Netas_h).toFixed(0)
    ),
    "% F_netas_h": parseFloat(
      parseFloat(totalTableInfo["% F_netas_h"]).toFixed(2)
    ),
    Sust_h: parseFloat(parseFloat(totalTableInfo.Sust_h).toFixed(0)),
    Adic_h: parseFloat(parseFloat(totalTableInfo.Adic_h).toFixed(0)),
    Visit_h: parseFloat(parseFloat(totalTableInfo.Visit_h).toFixed(0)),
    Prog_s: parseFloat(parseFloat(totalTableInfo.Prog_s).toFixed(0)),
    Faltas_Brutas_s: parseFloat(
      parseFloat(totalTableInfo.Faltas_Brutas_s).toFixed(0)
    ),
    Aut_s: parseFloat(parseFloat(totalTableInfo.Aut_s).toFixed(0)),
    Rep_s: parseFloat(parseFloat(totalTableInfo.Rep_s).toFixed(0)),
    Faltas_Netas_s: parseFloat(
      parseFloat(totalTableInfo.Faltas_Netas_s).toFixed(0)
    ),
    Sust_s: parseFloat(parseFloat(totalTableInfo.Sust_s).toFixed(0)),
    Adic_s: parseFloat(parseFloat(totalTableInfo.Adic_s).toFixed(0)),
    Visit_s: parseFloat(parseFloat(totalTableInfo.Visit_s).toFixed(0)),
    "S.Ant_s": parseFloat(parseFloat(totalTableInfo["S.Ant_s"]).toFixed(0)),
    Ret_s: parseFloat(parseFloat(totalTableInfo.Ret_s).toFixed(0)),
    Ret_m: parseFloat(parseFloat(totalTableInfo.Ret_m).toFixed(0)),
    Can_m: parseFloat(parseFloat(totalTableInfo.Can_m).toFixed(0)),
  });
  return data_;
};
