import React from "react";
import { Grid } from "@material-ui/core";

const DateInput = ({
  lable,
  onChange,
  min = "",
  max = "",
  value = "",
  sm = 2,
}) => {
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 20,
        alignItems: "center",
      }}
    >
      <Grid item sm={sm} style={{ textAlign: "end", marginRight: 20 }}>
        <label>{lable}</label>
      </Grid>
      <Grid style={{ flexGrow: 1 }}>
        <input
          className="filter-input"
          label="Seleccionar fecha"
          type="date"
          value={value}
          min={min}
          max={max}
          onChange={(e) => onChange(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default DateInput;
