import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getRequest, postRequest } from "../../../Utils/Funciones/requester";
import NewModal from "../../../Utils/Visuales/NewModal";
import styles from "../styles.module.css";
import moment from "moment";
import "moment/locale/es";
import { checkIfJExists } from "../../../Utils/Funciones/checkIfJExists";

let grupoTimer;
function Adelantada({
  open,
  claveProfesor,
  grupo,
  materia,
  ciclo,
  direccion,
  fechaMovimiento,
  inicia,
  termina,
  profesorSustitucion,
  fecha,
  tipoSalon,
  salon,
  tema,
  horario,
  tipoClaseEspecial,
  director,
  handleClose,
  setFecha,
  setHorario,
  setTipoSalon,
  setInicia,
  setTermina,
  setSalon,
  setClaveProfesor,
  setGrupo,
  setMateria,
  setProfesorSustitucion,
  setFechaMovimiento,
  setTema,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [selectMateriaIsLoading, setSelectMateriaIsLoading] = useState(false);
  const [selectTipoSalonIsLoading, setSelectTipoSalonIsLoading] =
    useState(false);
  const [selectSalonIsLoading, setSelectSalonIsLoading] = useState(false);
  const [materiaOptions, setMateriaOptions] = useState([]);
  const [dataHorarioFinal, setDataHorarioFinal] = useState([]);
  const [minutosInicia, setMinutosInicia] = useState("");
  const [tipoSalonOptions, setTipoSalonOptions] = useState([]);
  const [salonOptions, setSalonOptions] = useState([]);
  const campusId = useSelector((state) => state.campusId);

  useEffect(() => {
    setDataHorarioFinal([]);
    setTipoSalonOptions([]);
    setSalonOptions([]);

    if (open === true) {
      getTipoSalonCatalogo(enqueueSnackbar);
    }

    if (claveProfesor.value && grupo && materia.value) {
      consultarDetalleHorario(materia.value, enqueueSnackbar, claveProfesor);
    }
  }, [open, claveProfesor, grupo, materia, enqueueSnackbar]);

  useEffect(() => {
    clearTimeout(grupoTimer);
    if (grupo.length >= 4) {
      grupoTimer = setTimeout(() => {
        searchMateria(
          campusId,
          claveProfesor.value,
          grupo,
          ciclo,
          direccion.value,
          enqueueSnackbar
        );
      }, waitTime);
      return () => clearInterval(grupoTimer);
    }
  }, [grupo, campusId, enqueueSnackbar, claveProfesor, ciclo, direccion]);

  async function consultarDetalleHorario(id, enqueueSnackbar, claveProfesor) {
    setTableIsLoading(true);
    const endpoint = `/asignaciones/detalle?id=${id}`;
    const assignmentDetailResponse = await getRequest(endpoint).then((res) => {
      return res.d;
    });

    if (assignmentDetailResponse) {
      const horarioProfesor = assignmentDetailResponse.horario_profesor.filter(
        (item) => item.nss === claveProfesor.nss
      );
      if (horarioProfesor.length) {
        const scheduleData = [
          horarioProfesor[0].horario.lunes[0],
          horarioProfesor[0].horario.martes[0],
          horarioProfesor[0].horario.miercoles[0],
          horarioProfesor[0].horario.jueves[0],
          horarioProfesor[0].horario.viernes[0],
          horarioProfesor[0].horario.sabado[0],
          horarioProfesor[0].horario.domingo[0],
        ];
        setDataHorarioFinal(scheduleData);
      }
      setTableIsLoading(false);
    } else {
      enqueueSnackbar("La materia no tiene horario", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });

      setTableIsLoading(false);
    }
  }

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
            nss: item.nss,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  function promiseOptions(inputValue) {
    return new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });
  }

  async function searchMateria(
    id_campus,
    id_person,
    grupo,
    ciclo,
    direccion,
    enqueueSnackbar
  ) {
    setSelectMateriaIsLoading(true);
    try {
      const options = [];

      const consulta = `/buscar?tipo=asignacion&valor=id_campus:${id_campus},id_person:${id_person},grupo:${grupo},ciclo:${ciclo},id_direccion:${direccion}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave_materia + " - " + item.nombre_materia,
            value: item.id_asignacion,
          });
        });

        setMateriaOptions(options);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectMateriaIsLoading(false);
    }
  }

  async function getSalonOptions(id_tipo_salon) {
    setSelectSalonIsLoading(true);
    try {
      const endpoint = `/buscar?tipo=salon&valor=id_tipo_salon:${id_tipo_salon},id_campus:${campusId},fecha:${fechaMovimiento},hora_inicio:${inicia},hora_fin:${termina.substring(
        0,
        2
      )}`;
      const horarios = `dias: ${moment(fechaMovimiento).format(
        "dddd"
      )} horas_inicio: ${inicia}:${minutosInicia} horas_fin: ${termina}`;
      const response = await getRequest(endpoint);
      if (response.s === "OK") {
        const request = `/reportes/salones_ocupados?fecha_inicial=${fechaMovimiento}&fecha_final=${fechaMovimiento}&id_campus=${campusId}&horario=${horarios}`;
        const response2 = await getRequest(request);
        if (response2.s === "OK") {
          const busyClassroomsInitial = [];
          const stringDay = moment(fechaMovimiento)
            .format("dddd")[0]
            .toUpperCase();
          response2.d.forEach((responseItem) => {
            if (
              checkIfJExists(
                `${inicia}:${minutosInicia}`,
                `${termina}`,
                responseItem.timeslots,
                moment(fechaMovimiento).format("dddd") === "miércoles"
                  ? "Mi"
                  : stringDay
              )
            ) {
              busyClassroomsInitial.push(responseItem.id_classroom);
            }
          });
          const dataObjectInicio = response.d
            .map((item, i) => {
              if (!busyClassroomsInitial.includes(`${item.id_salon}`)) {
                const data_ = {
                  label: item.nombre,
                  value: item.id_salon,
                };
                return data_;
              }
              return null;
            })
            .filter((item) => item !== null);
          setSalonOptions(dataObjectInicio);
        } else {
          enqueueSnackbar(response2.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectSalonIsLoading(false);
    }
  }

  function handleChangeTipoSalon(ev) {
    setTipoSalon(ev);
    getSalonOptions(ev.value);
  }

  async function getTipoSalonCatalogo(enqueueSnackbar) {
    setSelectTipoSalonIsLoading(true);
    try {
      const endpoint = `/catalogo?id=24`;
      const response = await getRequest(endpoint);
      const data = [];
      if (response.s === "OK") {
        const nTipos = response.d.elementos[0]["id"].length;
        for (let i = 0; i < nTipos; i++) {
          data.push({
            label:
              response.d.elementos[1]["clave"][i] +
              " - " +
              response.d.elementos[2]["type"][i],
            value: response.d.elementos[0]["id"][i],
          });
        }
        setTipoSalonOptions(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setSelectTipoSalonIsLoading(false);
    }
  }

  async function handleSubmit() {
    if (
      claveProfesor.value === "" ||
      profesorSustitucion.value === "" ||
      fecha === "" ||
      materia.value === "" ||
      fechaMovimiento === "" ||
      inicia === "" ||
      minutosInicia === "" ||
      tipoSalon.value === "" ||
      salon.value === "" ||
      tema === ""
    ) {
      enqueueSnackbar("Se deben llenar todos los datos de la reposición", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const endpoint = `/asignaciones/clases-especiales/nueva`;
      const body = {
        id_asignacion: materia.value,
        id_profesor: claveProfesor.value,
        id_profesor_nuevo: profesorSustitucion.value,
        id_dictamen: "",
        fecha: fecha,
        hora: horario,
        fechaNuevo: fechaMovimiento,
        hora_inicio: `${inicia}:${minutosInicia}`,
        id_salon: salon.value,
        tema: tema,
        tipo_clase_especial: tipoClaseEspecial,
      };
      const response = await postRequest(endpoint, body);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        cleanStates();
        handleClose();
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  function DissabledDate() {
    var today, dd, mm, yyyy;
    today = new Date();
    dd = today.getDate();
    mm = today.getMonth() + 1;
    yyyy = today.getFullYear();
    var mesStr = `${mm}`;
    var mes = "";
    if (mesStr.length === 1) {
      mes = `0${mesStr}`;
    } else {
      mes = mesStr;
    }
    return yyyy + "-" + mes + "-" + dd;
  }

  const handleDate = async (date) => {
    const selectedDay = new Date(date).getDay();
    const allowedDays = dataHorarioFinal.reduce((acc, val, index) => {
      if (val !== undefined) acc.push(index);
      return acc;
    }, []);

    if (!allowedDays.includes(selectedDay)) {
      enqueueSnackbar("Seleccione una fecha que coincida con los horarios", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      setFecha("");
      return;
    } else {
      setFecha(date);
      setHorario(dataHorarioFinal[selectedDay]);
      setInicia(dataHorarioFinal[selectedDay].substring(0, 2));
      setMinutosInicia(dataHorarioFinal[selectedDay].substring(3, 5));
      setTermina(dataHorarioFinal[selectedDay].substring(6, 11));
    }
  };

  function handleHoraInicia(value) {
    if (value >= 0 && value <= 23 && value.length < 3) {
      setInicia(value);
      const format = "HH:mm"; // specify the format of the time strings
      const initalHour = moment(horario.substring(0, 5), format);
      const finalHour = moment(horario.substring(6, 11), format);
      const diffDuration = moment.duration(finalHour.diff(initalHour));
      const hourDiff = diffDuration.hours();
      const minuteDiff = diffDuration.minutes();
      const horaFin = moment(`${value}:${minutosInicia}`, "HH:mm")
        .add(hourDiff, "hours")
        .add(minuteDiff, "minutes")
        .format("HH:mm");
      setTermina(horaFin);
    }
  }

  function handleMinutosInicia(value) {
    if (value >= 0 && value <= 59 && value.length < 3) {
      if (value < 10) {
        let valueModificado = `0${value}`;
        setMinutosInicia(valueModificado);
        const format = "HH:mm"; // specify the format of the time strings
        const initalHour = moment(horario.substring(0, 5), format);
        const finalHour = moment(horario.substring(6, 11), format);
        const diffDuration = moment.duration(finalHour.diff(initalHour));
        const hourDiff = diffDuration.hours();
        const minuteDiff = diffDuration.minutes();
        const horaFin = moment(`${inicia}:${valueModificado}`, "HH:mm")
          .add(hourDiff, "hours")
          .add(minuteDiff, "minutes")
          .format("HH:mm");
        setTermina(horaFin);
      } else {
        setMinutosInicia(value);
        const format = "HH:mm"; // specify the format of the time strings
        const initalHour = moment(horario.substring(0, 5), format);
        const finalHour = moment(horario.substring(6, 11), format);
        const diffDuration = moment.duration(finalHour.diff(initalHour));
        const hourDiff = diffDuration.hours();
        const minuteDiff = diffDuration.minutes();
        const horaFin = moment(`${inicia}:${value}`, "HH:mm")
          .add(hourDiff, "hours")
          .add(minuteDiff, "minutes")
          .format("HH:mm");
        setTermina(horaFin);
      }
    }
  }

  const cleanStates = () => {
    setMinutosInicia([]);
    setTipoSalonOptions([]);
    setSalonOptions([]);
    setMateriaOptions([]);
    setDataHorarioFinal([]);
  };

  useEffect(() => {
    moment.locale("es");
  }, []);

  useEffect(() => {
    setTipoSalon({
      label: "",
      value: "",
    });
    setSalon({
      label: "",
      value: "",
    });
  }, [
    fechaMovimiento,
    inicia,
    minutosInicia,
    claveProfesor,
    profesorSustitucion,
    setTipoSalon,
    setSalon,
  ]);

  return (
    <>
      <NewModal
        title="Reposición Adelantada"
        open={open}
        handleClose={() => {
          cleanStates();
          handleClose();
        }}
        marginTop={"-35px"}
        height={700}
        maxWidth={1000}
        loading={loading}
        overflowY={"unset"}
        handleSubmit={() => {
          handleSubmit();
        }}
      >
        <div style={{ padding: "25px" }}>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "30px", alignItems: "center" }}
          >
            <Grid item xs={1} style={{ marginLeft: "-3%" }}>
              <label className={styles.inputLabel}>Ciclo:</label>
            </Grid>
            <Grid item xs={1}>
              <input
                className={styles.filterInput}
                style={{ width: "130%" }}
                value={ciclo}
                disabled
              />
            </Grid>

            <Grid item xs={1} style={{ marginLeft: "3%" }}>
              <label className={styles.inputLabel}>Dirección:</label>
            </Grid>
            <Grid item xs={4}>
              <input
                className={styles.filterInput}
                value={direccion.label}
                disabled
              />
            </Grid>

            <Grid item xs={1}>
              <label className={styles.inputLabel}>Director:</label>
            </Grid>
            <Grid item xs={4}>
              <input className={styles.filterInput} value={director} disabled />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "25px",
              marginTop: "-1.5%",
              alignItems: "center",
            }}
          >
            <Grid item xs={4}>
              <h4 className={styles.modalSectionName}>
                Datos de la clase en que va a faltar
              </h4>
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={2} style={{ marginTop: "-2%" }}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={8} item style={{ padding: 2, marginTop: "-2%" }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 20 }),
                }}
                loadOptions={promiseOptions}
                value={claveProfesor}
                onChange={setClaveProfesor}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "150px" }}
                value={grupo}
                onChange={(e) => setGrupo(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={5} style={{ marginTop: "-2%", marginBottom: "-2%" }}>
              <Select
                classNamePrefix="select-modal"
                options={materiaOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 19 }),
                }}
                isLoading={selectMateriaIsLoading}
                value={materia}
                onChange={setMateria}
              />
            </Grid>
            <Grid item xs={4} />

            <table className={styles.tableDias}>
              <thead>
                <tr>
                  <th>Lunes</th>
                  <th>Martes</th>
                  <th>Miercoles</th>
                  <th>Jueves</th>
                  <th>Viernes</th>
                  <th>Sabado</th>
                  <th>Domingo</th>
                </tr>
              </thead>
              {tableIsLoading ? null : (
                <tbody>
                  <tr>
                    <td>{dataHorarioFinal[0]}</td>
                    <td>{dataHorarioFinal[1]}</td>
                    <td>{dataHorarioFinal[2]}</td>
                    <td>{dataHorarioFinal[3]}</td>
                    <td>{dataHorarioFinal[4]}</td>
                    <td>{dataHorarioFinal[5]}</td>
                    <td>{dataHorarioFinal[6]}</td>
                  </tr>
                </tbody>
              )}
            </table>

            {tableIsLoading ? (
              <div
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <CircularProgress size={50} />
              </div>
            ) : null}

            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <label className={styles.inputLabel}>Fecha:</label>
            </Grid>
            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <input
                min={DissabledDate()}
                className="filter-input"
                type="date"
                value={fecha}
                onChange={(e) => handleDate(e.target.value)}
                style={{ width: "160px", fontSize: "16px" }}
              />
            </Grid>
            <Grid item xs={1} style={{ marginTop: "-1%" }}>
              <label className={styles.inputLabel}>Horario:</label>
            </Grid>
            <Grid item xs={2} style={{ marginTop: "-1%" }}>
              <input
                className="filter-input"
                style={{ fontSize: "16px", width: "150%" }}
                value={horario}
                disabled
                // onChange={(e) => handleTime(e.target.value)}
                // disabled={allowedTime ? false : true}
                // step="3600" min="00:00" max="23:59" pattern="[0-2][0-9]:[0-5][0-9]"
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              backgroundColor: "#e9ecef",
              padding: "10px",
              borderRadius: "5px",
              alignItems: "center",
            }}
          >
            <Grid item xs={3}>
              <h4 className={styles.modalSectionName}>Datos del Adelanto</h4>
            </Grid>
            <Grid item xs={9} />

            <Grid item xs={2} style={{ marginTop: "-1.5%" }}>
              <label className={styles.inputLabel}>Clave Profesor:</label>
            </Grid>
            <Grid xs={6} item style={{ padding: 2, marginTop: "-1.5%" }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 16 }),
                }}
                loadOptions={promiseOptions}
                value={profesorSustitucion}
                onChange={setProfesorSustitucion}
              />
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Grupo:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                value={grupo}
                style={{ width: "160px" }}
                disabled
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Materia:</label>
            </Grid>
            <Grid item xs={7}>
              <input
                disabled
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 150px - 10px - 10px)",
                  marginRight: "10px",
                }}
                value={materia.label}
              />
            </Grid>

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Fecha Movimiento:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className="filter-input"
                label="Seleccionar fecha"
                type="date"
                value={fechaMovimiento}
                onChange={(ev) => setFechaMovimiento(ev.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "160px", fontSize: "16px" }}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Inicia:</label>
            </Grid>
            <Grid item xs={2} style={{ display: "flex" }}>
              <input
                className="filter-input"
                type="number"
                min="0"
                max="23"
                // placeholder="23"
                value={inicia}
                onChange={(e) => handleHoraInicia(e.target.value)}
              />
              <input
                className="filter-input"
                style={{ marginLeft: "1%" }}
                type="number"
                min="0"
                max="59"
                // placeholder="00"
                value={minutosInicia}
                onChange={(e) => handleMinutosInicia(e.target.value)}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Termina:</label>
            </Grid>
            <Grid item xs={2}>
              <input
                className={styles.filterInput}
                style={{ width: "135px" }}
                value={termina}
                disabled
              />
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tipo Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                className={styles.tipoSalon}
                classNamePrefix="select-modal"
                options={tipoSalonOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 15 }),
                }}
                isLoading={selectTipoSalonIsLoading}
                onChange={handleChangeTipoSalon}
                value={tipoSalon}
              />
            </Grid>
            <Grid item xs={1}>
              <label className={styles.inputLabel}>Salón:</label>
            </Grid>
            <Grid item xs={2}>
              <Select
                className="select-modal-container input-tipo"
                classNamePrefix="select-modal"
                options={salonOptions}
                styles={{
                  container: (styles) => ({ ...styles, zIndex: 14 }),
                }}
                isLoading={selectSalonIsLoading}
                onChange={setSalon}
                value={salon}
              />
            </Grid>
            <Grid item xs={5} />

            <Grid item xs={2}>
              <label className={styles.inputLabel}>Tema:</label>
            </Grid>
            <Grid item xs={10}>
              <input
                className={styles.filterInput}
                style={{
                  width: "calc(100% - 10px)",
                  marginRight: "10px",
                }}
                value={tema}
                onChange={(ev) => setTema(ev.target.value)}
              ></input>
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </>
  );
}

export default Adelantada;
