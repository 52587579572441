import React from "react";
import { Grid } from "@material-ui/core";
import NewButton from "../Botones/NewButton";
import CustomTable from "../CustomTable";
import Select from "react-select";

export default function CombinationBetweenCampuses({
    permisos,
    handleClickCancelar,
    handleClickEditar,
    data,
    loading,
    showButtons,
    handleSaveRow,
    tiposPlanDisabled,
    optionsTiposPlan,
    tiposPlan,
    handleTiposPlan,
    estatusDisabled,
    optionsEstatus,
    estatus,
    handleEstatus
}) {
    const asyncRowSelectStyles = {
        menu: (base) => ({
            ...base,
            position: 'static',
        }),
    };
    const headers = [
        {
            accessorKey: "tipos_de_plan",
            header: "Tipos de Plan",
            Cell: ({ row }) => (
                <Select
                    className="select-modal-container"
                    styles={asyncRowSelectStyles}
                    isDisabled={tiposPlanDisabled}
                    options={optionsTiposPlan}
                    value={tiposPlan[row.original.id]}
                    onChange={(event) => handleTiposPlan(event, row.original)}
                />
            ),
        },
        {
            accessorKey: "estatus",
            header: "Estatus",
            Cell: ({ row }) => (
                <Select
                    className="select-modal-container"
                    styles={asyncRowSelectStyles}
                    isDisabled={estatusDisabled}
                    options={optionsEstatus}
                    value={estatus[row.original.id]}
                    onChange={(event) => handleEstatus(event, row.original)}
                />
            ),
        },
        {
            accessorKey: "modificado",
            header: "Modificado",
        },
        {
            accessorKey: "modificado_por",
            header: "Modificado por",
        },
        {
            accessorKey: "creado",
            header: "Creado",
        },
        {
            accessorKey: "creado_por",
            header: "Creado por",
        }
    ];
    return (
        <Grid style={{ display: "flex", flexDirection: "column", padding: 10, paddingBottom: 50 }}>
            <Grid style={{ display: 'flex', width: '100%', justifyContent: "space-around", paddingTop: 10, paddingBottom: 10 }}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <label>Campus con los que se permite la combinación</label>
                    </Grid>
                </Grid>
                <Grid>
                    <NewButton
                        customText="Editar"
                        noIcon
                        style={{ padding: "5px 15px", display: permisos['Configuración->Campus'].modificar === '1' ? 'block' : 'none' }}
                        onClick={handleClickEditar}
                    />
                </Grid>
            </Grid>
            <CustomTable
                data={loading ? [] : data}
                headers={headers}
                loading={loading}
                isFooterSaveButtons={showButtons}
                onEditingRowSave={handleSaveRow}
                handleClickCancelar={handleClickCancelar}
            />
        </Grid>
    );
}