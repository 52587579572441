import { View, Text } from '@react-pdf/renderer'
import ReportPDFDefault from '../ReportPDFDefault'

export default function PDFDefault({
    metadata,
    data,
    title,
    campus,
    startDate,
    endDate,
    teacherKey = "",
    ciclo = "",
    escuela = "",
    direccion = "",
    prefectura = false,
    director,
    total_profesores_grupo_materia,
    rows
 }) {
    return (
        <View>
            <ReportPDFDefault
                title={title}
                metadata={metadata}
                data={data}
                campus={campus}
                startDate={startDate}
                endDate={endDate}
                teacherKey={teacherKey}
                ciclo={ciclo}
                escuela={escuela}
                direccion={direccion}
                prefectura={prefectura}
                director={director}
                total_profesores_grupo_materia={total_profesores_grupo_materia}
                rows={rows}
                />
            <View
                fixed
                style={{
                    height: 50,
                    position: 'absolute',
                    bottom: 30,
                    left: 0,
                    right: 0,
                    top: 520
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <View>
                        <Text
                            style={{
                                color: 'gray',
                                fontSize: 11,
                            }}
                            render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}