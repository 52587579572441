export const PDFformatForReporteIndicadoresAcademicosSemanalesDatalle = async (
  data
) => {
  const dataPDF = [];
  let recorrido = 0;
  if (data.length > 400) {
    recorrido = 300;
  } else {
    recorrido = data.length;
  }
  for (let i = 0; i < recorrido; i++) {
    dataPDF.push({
      clave_profesor: data[i][0],
      nombre_profesor: data[i][1],
      grupo: data[i][2],
      materia: data[i][3],
      clase: data[i][4],
      fecha_origen: data[i][5],
      fecha_clase_especial: data[i][6],
      clave_profesor_clase_especial: data[i][7],
      nombre_profesor_clase_especial: data[i][8],
      asistencia: data[i][9],
      autorizado: data[i][10],
      incidencia: data[i][11],
    });
  }
  return dataPDF;
};
