export const escuelaHeaders = [
    {
        accessorKey: 'clave',
        header: 'Clave',
        enableEditing: false,
        size: 10,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'nombre',
        header: 'Nombre',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'ciclo_actual',
        header: 'Ciclo actual',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'ciclo_anterior',
        header: 'Ciclo anterior',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'nivel',
        header: 'Nivel',
        enableEditing: false,
        size: 10,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'fecha_inicio',
        header: 'Fecha inicio',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'fecha_fin',
        header: 'Fecha fin',
        enableEditing: false,
        enableGlobalFilter: true
    },
    {
        accessorKey: 'modificado_por',
        header: 'Modificado por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'modificado',
        header: 'Fecha Modificado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'creado_por',
        header: 'Creado Por',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'creado',
        header: 'Fecha Creado',
        enableEditing: false,
        enableGlobalFilter: false
    },
    {
        accessorKey: 'id',
        header: 'id',
        size: 10,
        enableEditing: false,
        enableGlobalFilter: true
    },
]