import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import DataTable from "../../Utils/DataTables";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import "./styles.css";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import NewButton from "../../Utils/Botones/NewButton";

const ConsultarEvaluacion = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profesorValue, setProfesorValue] = useState("");
  const [cicloValue, setCicloValue] = useState("");
  const [valueSumaCalificacionEval, setValueSumaCalificacionEval] =
    useState("");
  const [valueSumaAlumnosEval, setValueSumaAlumnosEval] = useState("");
  const [valuePromedio, setValuePromedio] = useState("");

  const headers = [
    {
      name: "Campus",
      id: "campus",
      col: 1,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 7,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 8,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Nombre Materia",
      id: "nombre_materia",
      col: 6,
      fn: null,
      width: "19%",
      align: "center",
    },
    {
      name: "Calificación de la Evaluación",
      id: "calificacion_evaluacion",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Cantidad Alum. Evaluados",
      id: "cantidad_alumn_evaluados",
      col: 2,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Total Alumn. Inscritos",
      id: "total_alumn_inscritos",
      col: 3,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Promedio",
      id: "promedio",
      col: 4,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Director Académico",
      id: "director_academico",
      col: 5,
      fn: null,
      width: "18%",
      align: "center",
    },
  ];

  const table = [
    <div style={{ height: "calc(100% - 260px)", marginTop: "2%" }}>
      <DataTable
        headers={headers}
        data={data}
        loading={loading}
        //centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
        paginate
        bordes
      />
    </div>,
    <Grid
      container
      alignItems="center"
      spacing={2}
      style={{ marginTop: "-1%" }}
      xs={12}
    >
      <Grid
        item
        container
        alignItems="center"
        style={{ marginTop: "1%" }}
        xs={4}
      >
        <Grid
          item
          container
          alignItems="center"
          style={{ marginTop: "1%" }}
          xs={12}
        >
          <Grid item xs={6}>
            {" "}
            <label>Total Calificación de la Evaluación:</label>{" "}
          </Grid>
          <Grid item xs={6}>
            {" "}
            <input
              className="filter-input"
              disabled
              defaultValue={valueSumaCalificacionEval}
            />{" "}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        style={{ marginTop: "1%" }}
        xs={4}
      >
        <Grid
          item
          container
          alignItems="center"
          style={{ marginTop: "1%" }}
          xs={12}
        >
          <Grid item xs={6}>
            {" "}
            <label>Total Cantidad Alumnos Evaluados:</label>{" "}
          </Grid>
          <Grid item xs={6}>
            {" "}
            <input
              className="filter-input"
              disabled
              defaultValue={valueSumaAlumnosEval}
            />{" "}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        style={{ marginTop: "1%" }}
        xs={4}
      >
        <Grid
          item
          container
          alignItems="center"
          style={{ marginTop: "1%" }}
          xs={12}
        >
          <Grid item xs={6}>
            {" "}
            <label>Promedio del Profesor</label>{" "}
          </Grid>
          <Grid item xs={6}>
            {" "}
            <input
              className="filter-input"
              disabled
              defaultValue={valuePromedio}
            />{" "}
          </Grid>
        </Grid>
      </Grid>
    </Grid>,
  ];

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
  }, []);

  async function searchProfesor(valor) {
    try {
      const options = [];

      const consulta = `/buscar?tipo=profesor&valor=${valor}`;
      //console.log(consulta)
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.nss + " - " + item.nombre,
            value: item.id,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const minChars = 5;
  const waitTime = 2000;

  let timer;
  const asyncSelectStyles = {
    container: (styles) => ({ ...styles, zIndex: 9 }),
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.map((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  async function handleClick() {
    setValueSumaCalificacionEval("");
    setValueSumaAlumnosEval("");
    setValuePromedio("");
    let valido = true;
    if (profesorValue === "") {
      enqueueSnackbar("Falta clave profesor", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (cicloValue === "") {
      enqueueSnackbar("Debe ingresar Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }

    if (valido) {
      setLoading(true);
      setShowTable(true);
      try {
        let url = `/evaluacion-docente?ciclo=${cicloValue}&id_persona=${profesorValue.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        const request = url;

        const response = await getRequest(request);
        if (response.s === "OK") {
          console.log(response.m);
          let data = [];
          let sum_calificacion_eval = 0;
          let sum_alumnos_eval = 0;
          let sum_promedio = 0;
          response.d.forEach((e) => {
            data.push([
              e.campus,
              e.grupo,
              e.clave_materia,
              e.nombre_materia,
              e.calificacion_eval,
              e.alumnos_eval,
              e.alumnos_insc,
              e.promedio,
              e.director_academico,
            ]);
            sum_calificacion_eval += e.alumnos_eval * e.calificacion_eval;
            sum_alumnos_eval += e.alumnos_eval;
          });
          setData(data);
          sum_promedio = (sum_calificacion_eval / sum_alumnos_eval).toFixed(2);
          setValueSumaCalificacionEval(sum_calificacion_eval.toFixed(2));
          setValueSumaAlumnosEval(sum_alumnos_eval);
          setValuePromedio(sum_promedio);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  }

  return (
    <Contenedor title="Profesores / Consultar Evaluación">
      <Grid
        container
        className="nuevoUsuarioForm"
        style={{ position: "relative", zIndex: 9 }}
      >
        <Grid container item xs={12} sm={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              xs={12}
              sm={1}
              style={{ textAlign: "end", marginLeft: "-2%" }}
            >
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={14} sm={2}>
              <input
                className="filter-input input-ciclo"
                placeholder="Indique el ciclo..."
                value={cicloValue}
                onChange={(e) => setCicloValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={1} style={{ textAlign: "end" }}>
              <label>Dirección:</label>
            </Grid>
            <Grid item xs={14} sm={2} style={{ zIndex: 10 }}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueDireccion}
                options={optionsDireccion}
                onChange={setValueDireccion}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={3}
              style={{
                textAlign: "end",
                marginLeft: "-12%",
                marginRight: "-1%",
              }}
            >
              <label>Clave Profesor(*):</label>
            </Grid>
            <Grid item xs={14} sm={3}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                styles={asyncSelectStyles}
                loadOptions={promiseOptions}
                onChange={setProfesorValue}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              style={{ float: "right", marginLeft: "4%" }}
            >
              <NewButton noIcon onClick={handleClick} customText="Buscar" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {table}
    </Contenedor>
  );
};

export default ConsultarEvaluacion;
