import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import { useSnackbar } from "notistack";
import { patchRequest } from "../../Utils/Funciones/requester";
import moment from "moment";
import { useSelector } from "react-redux";

import Contenedor from "../../Utils/Visuales/Contenedor";
import SearchInput from "../../Utils/Visuales/SearchInput";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import NewModal from "../../Utils/Visuales/NewModal";
import NewButton from "../../Utils/Botones/NewButton";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import styles from "./styles.module.css";
import { getPersonCatalog } from "../../Utils/Funciones/getPersonCatalog";
import { sleep } from "../../Utils/Funciones/sleep";

function Profesiones() {
  const [loading, setLoading] = useState(false);
  const permisos = useSelector((state) => state.permisos);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataFinal, setDataFinal] = useState([]);
  const [modalMantenimientoIsOpen, setModalMantenimientoIsOpen] =
    useState(false);
  const [loadingModalMantenimiento, setLoadingModalMantenimiento] =
    useState(false);
  const [loadingModalNueva, setLoadingModalNueva] = useState(false);
  const [fechaVencimiento, setFechaVencimiento] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [tipoPersonaOptions, setTipoPersonaOptions] = useState([]);
  const [modalDetalleIsOpen, setModalDetalleIsOpen] = useState(false);
  const [modalEditarIsOpen, setModalEditarIsOpen] = useState(false);
  const [modalConsultarIsOpen, setModalConsultarIsOpen] = useState(false);
  const [loadingModalEditar, setLoadingModalEditar] = useState(false);
  const [loadingModalDetalle, setLoadingModalDetalle] = useState(false);
  const [loadingModalConsultar, setLoadingModalConsultar] = useState(false);
  const [dataModalEditar, setDataModalEditar] = useState({});
  const [professionId, setProfessionId] = useState("");
  const [dataModalDetalle, setDataModalDetalle] = useState({
    id: "",
    clave: "",
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    sexo: "",
    fechaAlta: "",
    correo: "",
    telefono: "",
    nivelEstudios: "",
    tipoProfesor: "",
    tipoPersona: "",
    vigencia: "",
    curp: "",
  });
  const [dataModalMantenimiento, setDataModalMantenimiento] = useState({
    id: "",
    nss: "",
    nombre: "",
  });
  const [gradoAcad, setGradoAcad] = useState("");
  const [dataGradoAcad, setDataGradoAcad] = useState("");
  const [dataGradoAcadFilter, setDataGradoAcadFilter] = useState([]);
  const [dataTiposFilter, setDataTiposFilter] = useState([]);
  const [tipo, setTipo] = useState("");
  const [dataTipo, setDataTipo] = useState("");
  const [otorgadoPor, setOtorgadoPor] = useState("");
  const [titulo, setTitulo] = useState("");
  const [escuelaEgreso, setEscuelaEgreso] = useState("");
  const [estado, setEstado] = useState("");
  const [dataEstado, setDataEstado] = useState("");
  const [carrera, setCarrera] = useState("");
  const [establecimiento, setEstablecimiento] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [numeroLicencia, setNumeroLicencia] = useState("");
  const [restricciones, setRestricciones] = useState("");
  const [dataConsultaFinal, setDataConsultaFinal] = useState([]);
  const [modalNuevoIsOpen, setModalNuevoIsOpen] = useState(false);
  const [cambioTipoPersona, setCambioTipoPersona] = useState(false);
  const [mantenimientoInfo, setMantenimientoInfo] = useState({});
  const [editTitulo, setEditTitulo] = useState("");
  const [estadoEdit, setEstadoEdit] = useState("");
  const [tipoEdit, setTipoEdit] = useState("");
  const [numeroLicenciaEdit, setNumeroLicenciaEdit] = useState("");
  const [gradoAcadEdit, setGradoAcadEdit] = useState("");
  const [escuelaEgresoEdit, setEscuelaEgresoEdit] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    fetchData().then(() => setLoading(false));
    getPersonCatalog().then((data) => setTipoPersonaOptions(data));
    tiposDeGradoAcad();
    tiposDeTipos();
    tiposDeEstado();
  }, [dataModalEditar]);

  async function openModalMantenimiento(id, nss, nombre) {
    setMantenimientoInfo({ id, nss, nombre });
    setModalMantenimientoIsOpen(true);
    setLoadingModalMantenimiento(true);
    try {
      const consulta = `/docente-profesiones?id_profesor=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setDataModalMantenimiento({
          id: id,
          nss: nss,
          nombre: nombre,
        });
        const data = [];
        response.d.forEach((item, index) => {
          //console.log(item);
          data.push([
            item.grado_academico,
            item.profesion,
            item.creado,
            item.creado_por,
            <div className={styles.buttonsContainer}>
              <EditButton onClick={() => openModalConsultar(id, index)}>
                Consultar{" "}
              </EditButton>
              <EditButton
                onClick={() => openModalEditar(id, index)}
                disabled={
                  permisos["Profesores->Mant. Profesiones"].modificar === "1"
                    ? false
                    : true
                }
              >
                Editar{" "}
              </EditButton>
            </div>,
          ]);
        });

        setDataConsultaFinal(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoadingModalMantenimiento(false);
    }
  }

  async function openModalEditar(id, index) {
    setModalEditarIsOpen(true);
    setLoadingModalEditar(true);
    try {
      const consulta = `/docente-profesiones?id_profesor=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setDataModalEditar({
          carrera: response.d[index].carrera,
          grado_academico: response.d[index].grado_academico,
          clave_profesor: response.d[index].clave_profesor,
          comentario: response.d[index].comentario,
          creado: response.d[index].creado,
          creado_por: response.d[index].creado_por,
          escuela_egreso: response.d[index].escuela_egreso,
          establecimiento: response.d[index].establecimiento,
          fecha_vencimiento: response.d[index].fecha_vencimiento,
          id: response.d[index].id,
          nombre_titulo: response.d[index].nombre_titulo,
          numero_licencia: response.d[index].numero_licencia,
          otorgado_por: response.d[index].otorgado_por,
          profesion: response.d[index].profesion,
          restricciones: response.d[index].restricciones,
          id_estado_profesion: response.d[index].id_estado_profesion,
        });
        setProfessionId(response.d[index].id);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoadingModalEditar(false);
    }
  }

  async function openModalConsultar(id, index) {
    setModalConsultarIsOpen(true);
    setLoadingModalConsultar(true);
    try {
      const consulta = `/docente-profesiones?id_profesor=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setDataModalEditar({
          carrera: response.d[index].carrera,
          grado_academico: response.d[index].grado_academico,
          clave_profesor: response.d[index].clave_profesor,
          comentario: response.d[index].comentario,
          creado: response.d[index].creado,
          creado_por: response.d[index].creado_por,
          escuela_egreso: response.d[index].escuela_egreso,
          establecimiento: response.d[index].establecimiento,
          fecha_vencimiento: response.d[index].fecha_vencimiento,
          id: response.d[index].id,
          nombre_titulo: response.d[index].nombre_titulo,
          numero_licencia: response.d[index].numero_licencia,
          otorgado_por: response.d[index].otorgado_por,
          profesion: response.d[index].profesion,
          restricciones: response.d[index].restricciones,
          id_estado_profesion: response.d[index].id_estado_profesion,
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  async function openModalDetalle(id) {
    setModalDetalleIsOpen(true);
    setLoadingModalDetalle(true);
    try {
      const consulta = `/docentes?id=${id}`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        setDataModalDetalle({
          id: id,
          clave: response.d[0]["nss"],
          nombre: response.d[0]["name"],
          apellidoPaterno: response.d[0]["first_last_name"],
          apellidoMaterno: response.d[0]["second_last_name"],
          fechaNacimiento: response.d[0]["fecha_nacimiento"],
          sexo: response.d[0]["gender"],
          fechaAlta: response.d[0]["fecha_alta"],
          correo: response.d[0]["email"],
          telefono: response.d[0]["telephone_number"],
          nivelEstudios: response.d[0]["nivel_estudio"],
          tipoProfesor: response.d[0]["tipo_profesor"],
          tipoPersona: [
            {
              value: parseInt(response.d[0]["id_tipo_persona"]),
              label: response.d[0]["tipo_persona"],
            },
          ],
          vigencia: response.d[0]["vigencia"],
          curp: response.d[0]["CURP"],
        });

        setTipo(response.d[0]["tipo_profesor"]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } finally {
      setLoadingModalDetalle(false);
    }
  }

  function openModalNuevo() {
    setModalNuevoIsOpen(true);
  }

  async function fetchData() {
    try {
      setDataFinal([]);
      setLoading(true);
      const consulta = `/docentes`;
      const response = await getRequest(consulta);
      if (response.s === "OK") {
        //console.log(response);

        const data = [];
        response.d.forEach((item) => {
          data.push([
            item.nss,
            item.name,
            item.tipo_profesion,
            <div className={styles.buttonsContainer}>
              <EditButton
                onClick={() =>
                  openModalMantenimiento(item.id, item.nss, item.name)
                }
              >
                Mantenimiento
              </EditButton>
              <EditButton onClick={() => openModalDetalle(item.id)}>
                Persona
              </EditButton>
            </div>,
            item.id,
          ]);
        });
        setDataFinal(data);
        setDataOriginal(data);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setDataFinal(result);
  }
  /* /* /* CONSUMIENDO WS DESPUES DE ACEPTAR EL CAMBIO TIPO PERSONA */
  async function handleSubmit() {
    let valido = true;
    if (!dataModalDetalle.tipoPersona) {
      enqueueSnackbar("Falta seleccionar Tipo de Persona", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (cambioTipoPersona === false) {
      setModalDetalleIsOpen(false);
      valido = false;
    }
    if (valido) {
      try {
        setLoadingModalDetalle(true);
        const payload = {
          id: parseInt(dataModalDetalle.id),
          id_tipo_persona: parseInt(dataModalDetalle.tipoPersona.value),
        };
        const response = await patchRequest(`/usuarios`, payload);
        if (response.s === "OK") {
          let newData = dataOriginal.map((item) => {
            if (item[0] === dataModalDetalle.clave) {
              item[2] = tipo;
            }
            return item;
          });
          fetchData();
          setDataFinal(newData);
          setCambioTipoPersona(false);
          setModalDetalleIsOpen(false);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingModalDetalle(false);
      }
    }
  }

  const headers = [
    {
      name: "Clave Profesor",
      col: 0,
    },
    {
      name: "Nombre Profesor",
      col: 1,
    },
    {
      name: "Tipo Persona",
      col: 2,
    },
    {
      name: "Acciones",
      col: 3,
    },
  ];

  const headersConsulta = [
    {
      name: "Nivel Académico",
      col: 0,
    },
    {
      name: "Profesión",
      col: 1,
    },
    {
      name: "Fecha Creado",
      col: 2,
    },
    {
      name: "Creado Por",
      col: 3,
    },
    {
      name: "Acciones",
      col: 3,
    },
  ];

  // const tipos = [
  //   {
  //     value: "Docente",
  //     label: "Docente",
  //   },
  // ];

  const selectCustomStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: "215px",
    }),
  };

  const tiposDeGradoAcad = async () => {
    try {
      const consulta = `/catalogo?id=17`;
      const response = await getRequest(consulta);
      let data = [];
      let data_2 = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
          data_2.push({
            value: id[i],
            label: `${clave[i]} - ${nombre[i]}`,
            filter: nombre[i],
          });
        });
        if (dataModalEditar?.numero_licencia && modalNuevoIsOpen === false) {
          const newType = data_2.filter(
            (element) => element.filter === dataModalEditar.grado_academico
          );
          setEditTitulo(dataModalEditar.nombre_titulo);
          setEscuelaEgresoEdit(dataModalEditar.escuela_egreso);
          setNumeroLicenciaEdit(dataModalEditar.numero_licencia);
          if (newType.length > 0) {
            setGradoAcadEdit(newType[0]);
          }
          await sleep(500);
          setLoadingModalConsultar(false);
        }
        setDataGradoAcad(data);
        setDataGradoAcadFilter(data_2);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const tiposDeTipos = async () => {
    try {
      const consulta = `/catalogo?id=18`;
      const response = await getRequest(consulta);
      let data = [];
      let data_2 = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
          data_2.push({
            value: id[i],
            label: `${clave[i]} - ${nombre[i]}`,
            filter: nombre[i],
          });
        });
        setDataTipo(data);

        if (dataModalEditar?.numero_licencia && modalNuevoIsOpen === false) {
          const newType = data_2.filter(
            (element) => element.filter === dataModalEditar.profesion
          );
          if (newType.length > 0) {
            setTipoEdit(newType[0]);
          }
        } else {
          setTipo({});
        }
        setDataTiposFilter(data_2);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const tiposDeEstado = async () => {
    try {
      const consulta = `/catalogo?id=19`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        id.forEach((item, i) => {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        });
        setDataEstado(data);
        if (dataModalEditar?.numero_licencia && modalNuevoIsOpen === false) {
          const newState = data.filter(
            (element) => element.value === dataModalEditar.id_estado_profesion
          );
          if (newState.length > 0) {
            setEstadoEdit(newState[0]);
          }
        }
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleNuevaProfesion = async () => {
    let valido = true;
    if (gradoAcad === "") {
      enqueueSnackbar("Se debe de seleccionar un Grado Académico", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    } else if (tipo?.value === undefined) {
      enqueueSnackbar("Se debe de seleccionar una Profesión", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoadingModalNueva(true);
      const json = {
        id_profesor: parseInt(dataModalMantenimiento.id),
        id_grado_academico: parseInt(gradoAcad.value),
        id_tipo_profesion: parseInt(tipo.value),
        nombre_titulo: titulo,
        id_estado_profesion: parseInt(
          estado.value === undefined ? "" : estado.value
        ),
        establecimiento: establecimiento,
        otorgado_por: otorgadoPor,
        escuela_egreso: escuelaEgreso,
        carrera: carrera,
        comentario: comentarios,
        numero_licencia: numeroLicencia,
        restricciones: restricciones,
        fecha_vencimiento: fechaVencimiento,
      };
      const response = await postRequest("/docente-profesiones", json);
      if (response.s === "OK") {
        setModalNuevoIsOpen(false);
        limpiarModalNuevaProf();
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        openModalMantenimiento(
          dataModalMantenimiento.id,
          dataModalMantenimiento.nss,
          dataModalMantenimiento.nombre
        );
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
      setLoadingModalNueva(false);
    }
  };
  const handleDeleteProfession = async () => {
    setModalEditarIsOpen(true);
    setLoadingModalEditar(true);
    const url = `/docente-profesiones/cancelar`;
    const json = {
      id: professionId,
    };
    const response = await postRequest(url, json);
    if (response.s === "OK") {
      setModalEditarIsOpen(false);
      setLoadingModalEditar(false);
      openModalMantenimiento(
        mantenimientoInfo.id,
        mantenimientoInfo.nss,
        mantenimientoInfo.nombre
      );
    } else {
      setLoadingModalEditar(false);
      enqueueSnackbar(response.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const handleEditProfession = async () => {
    setModalEditarIsOpen(true);
    setLoadingModalEditar(true);
    const url = `/docente-profesiones/editar`;
    const json = {
      id_profesion: parseInt(professionId),
      id_grado_academico: parseInt(gradoAcadEdit.value),
      id_tipo_profesion: parseInt(tipoEdit.value),
      nombre_titulo: editTitulo,
      id_estado_profesion: parseInt(estadoEdit.value),
      escuela_egreso: escuelaEgresoEdit,
      numero_licencia: numeroLicenciaEdit,
    };
    const response = await postRequest(url, json);
    if (response.s === "OK") {
      setModalEditarIsOpen(false);
      setLoadingModalEditar(false);
      limpiarModalNuevaProf();
      openModalMantenimiento(
        mantenimientoInfo.id,
        mantenimientoInfo.nss,
        mantenimientoInfo.nombre
      );
      enqueueSnackbar("Profesión actualizado correctamente.", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      setLoadingModalEditar(false);
      limpiarModalNuevaProf();
      enqueueSnackbar(response.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  function handleTipoPersona(e) {
    setCambioTipoPersona(true);
    setDataModalDetalle({
      ...dataModalDetalle,
      tipoPersona: e,
    });
  }

  function limpiarModalNuevaProf() {
    setGradoAcad("");
    setTipo("");
    setTitulo("");
    setEstado("");
    setEstablecimiento("");
    setOtorgadoPor("");
    setEscuelaEgreso("");
    setCarrera("");
    setComentarios("");
    setNumeroLicencia("");
    setRestricciones("");
    setFechaVencimiento(moment().format("YYYY-MM-DD"));
  }

  return (
    <Contenedor title="Profesores / Profesiones">
      <div className={styles.opcionesBar}>
        <div style={{ float: "right" }}>
          <SearchInput hideIcon search={search} />
        </div>
      </div>
      <div style={{ height: "calc(100% - 65px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 2, 3, 4]}
          paginate
          bordes
          moreCells={8}
        />
      </div>

      <NewModal
        title="Consulta de Profesión"
        open={modalMantenimientoIsOpen}
        handleClose={() => setModalMantenimientoIsOpen(false)}
        loading={loadingModalMantenimiento}
        height={600}
        maxWidth={1500}
        noButtons
      >
        <div className={styles.modalContentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <label>Clave Profesor:</label>
            </Grid>
            <Grid item xs={5}>
              <input
                disabled
                className="filter-input"
                style={{ width: "90px", marginRight: "10px" }}
                value={dataModalMantenimiento.nss}
              ></input>
              <input
                disabled
                className="filter-input"
                style={{ width: "250px" }}
                value={dataModalMantenimiento.nombre}
              ></input>
            </Grid>
            <Grid item xs={3} />

            <Grid item xs={2}>
              <NewButton
                onClick={() => {
                  tiposDeGradoAcad();
                  tiposDeTipos();
                  tiposDeEstado();
                  openModalNuevo();
                }}
                noIcon
                style={{
                  marginTop: "10px",
                  display:
                    permisos["Profesores->Mant. Profesiones"].escribir === "1"
                      ? "block"
                      : "none",
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ height: "calc(100% - 150px)", margin: "0 25px" }}>
          <DataTable
            headers={headersConsulta}
            data={dataConsultaFinal}
            centrar={[1, 2, 3, 4]}
            paginate
            bordes
          />
        </div>
      </NewModal>

      <NewModal
        title="Nueva Profesión"
        open={modalNuevoIsOpen}
        handleClose={() => {
          setModalNuevoIsOpen(false);
          limpiarModalNuevaProf();
        }}
        height={500}
        maxWidth={1200}
        loading={loadingModalNueva}
        handleSubmit={handleNuevaProfesion}
      >
        <div className={styles.modalContentContainer}>
          <Grid container spacing={2}>
            <Grid container item spacing={1} alignItems="center">
              <Grid item xs={2} md={1} style={{ marginLeft: "-0.3%" }}>
                <label>Profesor:</label>
              </Grid>
              <Grid item xs={2} md={5}>
                <input
                  disabled
                  value={`${dataModalMantenimiento.nss} - ${dataModalMantenimiento.nombre}`}
                  className={styles.filterInput}
                  style={{ width: "101%" }}
                />
              </Grid>
              {/* <Grid item xs={0} md={1} /> */}
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Profesión(*):</label>
              </Grid>
              <Grid xs={2} md={5}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  placeholder="Seleccione tipo"
                  value={tipo}
                  options={dataTipo}
                  onChange={setTipo}
                />
              </Grid>
              <Grid item xs={1} md={2} style={{ marginLeft: "1.7%" }}>
                <label>Grado Académico(*):</label>
              </Grid>
              <Grid item xs={1} md={4}>
                <Select
                  className="select-modal-container input-gradoAcad"
                  classNamePrefix="select-modal"
                  placeholder="Seleccione grado académico"
                  value={gradoAcad}
                  options={dataGradoAcad}
                  onChange={setGradoAcad}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Título:</label>
              </Grid>
              <Grid xs={2} md={5}>
                <input
                  className="filter-input input-titulo"
                  value={titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />
              </Grid>
              <Grid xs={1} md={2} style={{ marginLeft: "2%" }}>
                <label>Escuela Egreso:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-escuelaEgreso"
                  value={escuelaEgreso}
                  onChange={(e) => setEscuelaEgreso(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Estado:</label>
              </Grid>
              <Grid xs={2} md={5}>
                <Select
                  className="select-modal-container input-estado"
                  classNamePrefix="select-modal"
                  placeholder="Seleccione estado"
                  value={estado.label === undefined ? "" : estado.label}
                  options={dataEstado}
                  onChange={setEstado}
                />
              </Grid>
              <Grid xs={1} md={2} style={{ marginLeft: "2%" }}>
                <label>Número de Cédula:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-numeroLicencia"
                  value={numeroLicencia}
                  onChange={(e) => setNumeroLicencia(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Consultar Profesión"
        open={modalConsultarIsOpen}
        handleClose={() => setModalConsultarIsOpen(false)}
        height={250}
        maxWidth={1200}
        loading={loadingModalConsultar}
        noSaveBtn
      >
        <div className={styles.modalContentContainer}>
          <Grid container spacing={2}>
            <Grid container item spacing={1} alignItems="center">
              <Grid item xs={2} md={1} style={{ marginLeft: "-0.25%" }}>
                <label>Profesor:</label>
              </Grid>
              <Grid item xs={2} md={4}>
                <input
                  disabled
                  defaultValue={`${dataModalMantenimiento.nss} - ${dataModalMantenimiento.nombre}`}
                  className={styles.filterInput}
                  style={{ width: "133%" }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Profesión:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  disabled
                  className="filter-input input-otorgado"
                  value={tipoEdit.label === undefined ? "" : tipoEdit.label}
                  style={{ width: "130%" }}
                />
              </Grid>
              <Grid item xs={0} md={2} />
              <Grid item xs={1} md={2}>
                <label>Grado Académico:</label>
              </Grid>
              <Grid item xs={1} md={4}>
                <input
                  disabled
                  className="filter-input input-otorgado"
                  value={
                    gradoAcadEdit.label === undefined ? "" : gradoAcadEdit.label
                  }
                />
              </Grid>
              {/* <Grid xs={1} md={2}>
                <label>Otorgado Por</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-otorgado"
                  value={dataModalConsultar.otorgado_por}
                  disabled
                />
              </Grid> */}
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Título:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-titulo"
                  value={editTitulo}
                  disabled
                  style={{ width: "130%" }}
                />
              </Grid>
              <Grid item xs={0} md={2} />
              <Grid xs={1} md={2}>
                <label>Escuela Egreso:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-titulo"
                  value={escuelaEgresoEdit}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Estado:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  disabled
                  className="filter-input input-otorgado"
                  style={{ width: "130%" }}
                  value={estadoEdit.label === undefined ? "" : estadoEdit.label}
                />
              </Grid>
              <Grid item xs={0} md={2} />
              <Grid xs={1} md={2}>
                <label>Número de Cédula:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-carrera"
                  value={numeroLicenciaEdit}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Editar Profesión"
        open={modalEditarIsOpen}
        handleClose={() => {
          setModalEditarIsOpen(false);
          limpiarModalNuevaProf();
        }}
        height={500}
        maxWidth={1200}
        loading={loadingModalEditar}
        canDelete={
          permisos["Profesores->Mant. Profesiones"].eliminar === "1"
            ? () => {
                handleDeleteProfession();
                return true;
              }
            : false
        }
        handleSubmit={handleEditProfession}
      >
        <div className={styles.modalContentContainer}>
          <Grid container spacing={2}>
            <Grid container item spacing={1} alignItems="center">
              <Grid item xs={2} md={1} style={{ marginLeft: "-0.25%" }}>
                <label>Profesor:</label>
              </Grid>
              <Grid item xs={2} md={5}>
                <input
                  disabled
                  defaultValue={`${dataModalMantenimiento.nss} - ${dataModalMantenimiento.nombre}`}
                  className={styles.filterInput}
                  style={{ width: "101%" }}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Profesión (*):</label>
              </Grid>
              <Grid xs={2} md={5}>
                <Select
                  className="select-modal-container input-tipo"
                  classNamePrefix="select-modal"
                  placeholder="Seleccione tipo"
                  defaultValue={(e) => {
                    const default_value = dataTiposFilter.filter(
                      (e) => e.filter === dataModalEditar.profesion
                    );
                    return default_value;
                  }}
                  options={dataTipo}
                  onChange={setTipoEdit}
                />
              </Grid>
              <Grid item xs={1} md={2} style={{ marginLeft: "2%" }}>
                <label>Grado Académico (*):</label>
              </Grid>
              <Grid item xs={1} md={4}>
                <Select
                  className="select-modal-container input-gradoAcad"
                  classNamePrefix="select-modal"
                  placeholder="Seleccione grado académico"
                  defaultValue={(e) => {
                    const default_value = dataGradoAcadFilter.filter(
                      (e) => e.filter === dataModalEditar.grado_academico
                    );
                    return default_value;
                  }}
                  options={dataGradoAcad}
                  onChange={setGradoAcadEdit}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Título:</label>
              </Grid>
              <Grid xs={2} md={5}>
                <input
                  className="filter-input input-titulo"
                  defaultValue={dataModalEditar.nombre_titulo}
                  onChange={(e) => setEditTitulo(e.target.value)}
                />
              </Grid>
              <Grid xs={1} md={2} style={{ marginLeft: "2.4%" }}>
                <label>Escuela Egreso:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-titulo"
                  defaultValue={dataModalEditar.escuela_egreso}
                  onChange={(e) => setEscuelaEgresoEdit(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} alignItems="center">
              <Grid xs={1} md={1}>
                <label>Estado:</label>
              </Grid>
              <Grid xs={2} md={5}>
                <Select
                  className="select-modal-container input-estado"
                  classNamePrefix="select-modal"
                  placeholder="Seleccione estado"
                  defaultValue={
                    estadoEdit.label === undefined ? "" : estadoEdit
                  }
                  options={dataEstado}
                  onChange={setEstadoEdit}
                />
              </Grid>
              <Grid xs={1} md={2} style={{ marginLeft: "2.4%" }}>
                <label>Número de Cédula:</label>
              </Grid>
              <Grid xs={2} md={4}>
                <input
                  className="filter-input input-carrera"
                  defaultValue={dataModalEditar.numero_licencia}
                  onChange={(e) => setNumeroLicenciaEdit(e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </NewModal>

      <NewModal
        title="Datos de Profesor"
        open={modalDetalleIsOpen}
        handleClose={() => {
          setCambioTipoPersona(false);
          setModalDetalleIsOpen(false);
          setLoadingModalDetalle(false);
        }}
        loading={loadingModalDetalle}
        height={450}
        handleSubmit={handleSubmit}
      >
        <div className={styles.modalContentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label>Clave Profesor</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.clave}
              />
            </Grid>
            <Grid item xs={8} />

            <Grid item xs={4}>
              <label>Nombre</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.nombre}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Apellido Paterno</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.apellidoPaterno}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Apellido Materno</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.apellidoMaterno}
              />
            </Grid>

            {/* <Grid item xs={4}>
              <label>Fecha de Nacimiento</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.fechaNacimiento}
              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <label>Sexo</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.sexo}
              />
            </Grid> */}
            {/* <Grid item xs={4}>
              <label>Fecha de Alta</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.fechaAlta}
              />
            </Grid> */}

            <Grid item xs={8}>
              <label>Correo Electrónico</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.correo}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <label>Teléfono</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.telefono}
              />
            </Grid> */}

            <Grid item xs={4} />
            <Grid item xs={4}>
              <label>Nivel de Estudios</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.nivelEstudios}
              />
            </Grid>
            <Grid item xs={4}>
              <label>Tipo de Persona</label>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isDisabled={
                  permisos["Profesores->Mant. Profesiones"].modificar === "1"
                    ? false
                    : true
                }
                options={tipoPersonaOptions}
                onChange={(e) => handleTipoPersona(e)}
                value={dataModalDetalle.tipoPersona}
                styles={selectCustomStyles}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <label>Vigencia</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.vigencia}
              />
            </Grid> */}

            {/* <Grid item xs={4}>
              <label>CURP</label>
              <input
                disabled
                className={styles.filterInput}
                value={dataModalDetalle.curp}
              />
            </Grid> */}

            <Grid item xs={4} />
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}

export default Profesiones;
