import { getTranslateHeader } from "./getTranslateHeader";
import { isEnableEditing } from "./isEnableEditing";
export const getDefaultCatalogoHeaders = (data, valueCatalogo) => {
    const elementos_map = data.flatMap(Object.keys)
    const headers = elementos_map.map((e, i) => {
        if (e === 'active' || e === 'id') {
            return null
        }
        let data_ = {
            accessorKey: e,
            header: getTranslateHeader(e),
            enableEditing: isEnableEditing(e, valueCatalogo),
            size: ['clave', 'active', 'id', 'año'].includes(e) ? 10 : null,
            enableGlobalFilter: !['id', 'creado_por', 'creado', 'modificado_por', 'modificado'].includes(e)
        }
        return data_;
    });
    headers.push({
        accessorKey: 'id',
        header: 'id',
        enableEditing: false,
        size: 10,
        enableGlobalFilter: false
    })
    return headers.filter((element) => element !== null)
}
