export const tabuladoresHeaders = [
  {
    accessorKey: "tabulador",
    header: "Tabulador",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "estatus",
    header: "Estatus",
    size: 80,
    enableEditing: true,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "modificado_por",
    header: "Modificado por",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "fecha_modificado",
    header: "Fecha modificado",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "creado_por",
    header: "Creado por",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "fecha_creado",
    header: "Fecha creado",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "id",
    header: "id",
    size: 10,
    enableEditing: false,
    enableGlobalFilter: false,
  },
];
