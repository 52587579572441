let URL_BASE = "https://o15svsoex7.execute-api.us-west-1.amazonaws.com/prod";
let URL_DUMMY = "https://o15svsoex7.execute-api.us-west-1.amazonaws.com/prod";
let URL_SESSION_OPENID ="https://o15svsoex7.execute-api.us-west-1.amazonaws.com/prod/sesion";
let URL_API = "https://rgvjef9g7j.execute-api.us-west-1.amazonaws.com/prod";
export const version = "1.0.0";

export async function getRequest(
  ws,
  signal = new AbortController().signal,
  dummy = false,
  session = false,
  api = false
) {
  try {
    let token = localStorage.getItem("token_sica");
    let url;
    if (dummy) {
      url = URL_DUMMY;
    } else if (session) {
      url = URL_SESSION_OPENID;
    } else if (api) {
      url = URL_API;
    } else {
      url = URL_BASE;
    }
    let response = await fetch(url + ws, {
      headers: {
        tk: token,
      },
      signal: signal,
    });
    let data = await response.json();
    if (data.m === "Token expirado") {
      localStorage.removeItem("token_sica");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
}

export async function postRequest(
  ws,
  json = {},
  signal = new AbortController().signal,
  dummy = false,
  api = false
) {
  let token = localStorage.getItem("token_sica");
  let url;
  if (dummy) {
    url = URL_DUMMY;
  } else if (api) {
    url = URL_API;
  } else {
    url = URL_BASE;
  }
  try {
    let response = await fetch(url + ws, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        tk: token,
      },
      body: JSON.stringify(json),
      signal: signal,
    });
    let data = await response.json();
    if (data.m === "Token expirado") {
      localStorage.removeItem("token_sica");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return data;
    } else {
      return data;
    }
  } catch (error) {
    console.log("Error");
  }
}

export async function postLogin(ws, json = {}) {
  let response = await fetch(URL_BASE + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });
  let data = await response.json();
  if (data.m === "Token expirado") {
    localStorage.removeItem("token_sica");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    return data;
  } else {
    return data;
  }
}

export async function putRequest(url, file) {
  let token = localStorage.getItem("token_sica");
  await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "image/png",
      "Content-Disposition": "inline",
      tk: token,
    },
  });
  return { status: "OK" };
}
export async function patchRequest(
  ws,
  json = {},
  signal = new AbortController().signal,
  dummy = false
) {
  let token = localStorage.getItem("token_sica");
  let url = dummy ? URL_DUMMY : URL_BASE;
  let response = await fetch(url + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      tk: token,
    },
    body: JSON.stringify(json),
    signal: signal,
  });
  let data = await response.json();
  if (data.m === "Token expirado") {
    localStorage.removeItem("token_sica");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
    return data;
  } else {
    return data;
  }
}
