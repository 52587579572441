import React from "react";
import { Grid } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "../index.css";
import AsignacionIndividual from "./asignacion_individual";
import { AsignacionFechas } from "./asignacion_fechas";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

function ModalEspacios() {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.localStorage.setItem("asignacion", JSON.stringify([]));
    window.localStorage.setItem("dataTable", JSON.stringify([]));
    window.localStorage.removeItem("asignacion");
    window.localStorage.removeItem("dataTable");
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Grid sx={{ borderBottom: 1, borderColor: "divider" }} container>
        <Typography
          style={{ marginBottom: "auto", marginTop: "auto", marginRight: 20 }}
        >
          Tipo de Reserva
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Asignación Individual" {...a11yProps(0)} />
          <Tab label=" Asignación por Fechas" {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <TabPanel value={value}>
        {value === 0 ? <AsignacionIndividual /> : <AsignacionFechas />}
      </TabPanel>
    </Box>
  );
}

export default ModalEspacios;
