import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import DataTable from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import Contenedor from "../../Utils/Visuales/Contenedor";
import excelIcon from "../../images/excel-icon.png";
import pdfFileIcon from "../../images/pdf-file-icon.png";
import { ExportCSV } from "../../Utils/Excel";
import moment from "moment";
import { DocumentPDFTable } from "../../Utils/pdf_table/document";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { getRequest } from "../../Utils/Funciones/requester";
import { useSnackbar } from "notistack";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSelector } from "react-redux";
import { reportPreviousProductivityBonusPDFHeaders } from "../../Utils/data/reportPreviousProductivityBonusPDFHeaders";
import { sleep } from "../../Utils/Funciones/sleep";
import SearchInput from "../../Utils/Visuales/SearchInput";

const PrevioBonoProductividad = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [cicloValue, setCicloValue] = useState("");
  const [valueCampus, setValueCampus] = useState("");
  const [optionsEscuela, setOptionsEscuela] = useState("");
  const [orderByName, setOrderByName] = useState(true);
  const [valueEscuela, setValueEscuela] = useState("");
  const [optionsDireccion, setOptionsDireccion] = useState("");
  const [valueDireccion, setValueDireccion] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [req, setReq] = useState(false);
  const [data, setData] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [loading, setLoading] = useState(false);
  const campusName = useSelector((state) => state.campusName);
  const campusUser = useSelector((state) => state.campusUser2);
  const [campusValores] = useState(campusUser);
  const [totalValue, setTotalValue] = useState(0);

  const headers = [
    {
      name: "Ciclo",
      id: "ciclo",
      col: 0,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Campus",
      id: "campus",
      col: 1,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Escuela",
      id: "escuela",
      col: 2,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Dirección",
      id: "direccion",
      col: 3,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Director",
      id: "director",
      col: 4,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Clave Profesor",
      id: "clave_profesor",
      col: 5,
      fn: null,
      width: "19%",
      align: "center",
    },
    {
      name: "Nombre Profesor",
      id: "nombre_profesor",
      col: 6,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Grupo",
      id: "grupo",
      col: 7,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Clave Materia",
      id: "clave_materia",
      col: 8,
      fn: null,
      width: "9%",
      align: "center",
    },
    {
      name: "Nombre Materia",
      id: "nombre_materia",
      col: 9,
      fn: null,
      width: "18%",
      align: "center",
    },
    {
      name: "Asignados",
      id: "asignados",
      col: 10,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Falta",
      id: "falta",
      col: 11,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Falta Autorizada",
      id: "falta_autorizada",
      col: 12,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Sustitución",
      id: "sustitucion",
      col: 13,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Reposición",
      id: "reposicion",
      col: 14,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Adicional",
      id: "adicional",
      col: 15,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Retardo",
      id: "retardo",
      col: 16,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Salida Anticipada",
      id: "salida_anticipada",
      col: 17,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Total Clases",
      id: "total_clases",
      col: 18,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fecha Alta",
      id: "fecha_alta",
      col: 19,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Fecha Baja",
      id: "fecha_baja",
      col: 20,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Evaluación",
      id: "evaluacion",
      col: 21,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Marca",
      id: "marca",
      col: 22,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "Evaluación Promedio",
      id: "evaluacion_promedio",
      col: 23,
      fn: null,
      width: "15%",
      align: "center",
    },
    {
      name: "% Bono",
      id: "bono",
      col: 24,
      fn: null,
      width: "15%",
      align: "center",
    },
  ];

  function search(text) {
    text = text.toLowerCase();
    let result = dataOriginal.filter((row) => {
      return row.some((element) => {
        const elementString =
          typeof element === "number" ? element.toString() : element;
        return (
          typeof elementString === "string" &&
          elementString.toLowerCase().includes(text)
        );
      });
    });
    setData(result);
  }

  useEffect(() => {
    consultarCatalogo(7).then((data) => setOptionsDireccion(data));
    consultarCatalogo(9).then((data) => setOptionsEscuela(data));
    setValueCampus(
      campusValores?.filter((item) => item.label.includes(campusName))[0]
    );
  }, []);

  const consultarCatalogo = async (id) => {
    try {
      const consulta = `/catalogo?id=${id}`;
      const response = await getRequest(consulta);
      let data = [];
      if (response.s === "OK") {
        let id = response.d.elementos[0].id;
        let clave = response.d.elementos[1].clave;
        let nombre = response.d.elementos[2].nombre;
        for (let i = 0; i < id.length; i++) {
          data.push({ value: id[i], label: `${clave[i]} - ${nombre[i]}` });
        }
        return data;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  };

  const DownloadExcel = async () => {
    setReq(true);
    const data_ = data.map((e) => {
      const data2 = {
        ciclo: e[0],
        campus: e[1],
        escuela: e[2],
        direccion: e[3],
        director: e[4],
        clave_profesor: e[5],
        nombre_profesor: e[6],
        grupo: e[7],
        clave_materia: e[8],
        nombre_materia: e[9],
        asignados: e[10],
        falta: e[11],
        falta_autorizada: e[12],
        sustitucion: e[13],
        reposicion: e[14],
        adicional: e[15],
        retardo: e[16],
        salida_anticipada: e[17],
        total_clases: e[18],
        fecha_alta: e[19],
        fecha_baja: e[20],
        evaluacion: e[21],
        marca: e[22],
        evaluacion_promedio: e[23],
        bono: e[24],
      };

      return data2;
    });
    //const concatArray = heading.concat(data_);
    const date = moment().format("L");
    const hour = moment().format("LTS");
    ExportCSV(
      data_,
      `Reporte_Previo_BonoProductividad_${date}_${hour}`,
      null,
      true
    );
    setReq(false);
  };

  const DownloadPDF = async () => {
    setReq(true);
    const date = moment().format("L");
    const hour = moment().format("LTS");
    const dataPDF = data
      .map((e) => {
        return {
          ciclo: e[0],
          campus: e[1],
          escuela: e[2],
          direccion: e[3],
          director: e[4],
          clave_profesor: e[5],
          nombre_profesor: e[6],
          grupo: e[7],
          clave_materia: e[8],
          nombre_materia: e[9],
          asignados: e[10],
          falta: e[11],
          falta_autorizada: e[12],
          sustitucion: e[13],
          reposicion: e[14],
          adicional: e[15],
          retardo: e[16],
          salida_anticipada: e[17],
          total_clases: e[18],
          fecha_alta: e[19],
          fecha_baja: e[20],
          evaluacion: e[21],
          marca: e[22],
          evaluacion_promedio: e[23],
          bono: e[24],
        };
      })
      .sort((a, b) => {
        if (a.direccion < b.direccion) {
          return -1;
        } else if (a.direccion > b.direccion) {
          return 1;
        } else {
          if (orderByName) {
            if (a.nombre_profesor < b.nombre_profesor) {
              return -1;
            } else if (a.nombre_profesor > b.nombre_profesor) {
              return 1;
            } else {
              return 0;
            }
          } else {
            if (a.evaluacion > b.evaluacion) {
              return -1;
            } else if (b.evaluacion > a.evaluacion) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });

    const doc = (
      <DocumentPDFTable
        metadata={reportPreviousProductivityBonusPDFHeaders}
        data={dataPDF ?? []}
        title={`Reportes Previo Bono de Productividad`}
        campus={valueCampus.label}
        ciclo={cicloValue}
        escuela={valueEscuela.label}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    saveAs(blob, `Reporte_Previo_BonoProductividad_${date}_${hour}.pdf`);
    setReq(false);
  };

  async function handleClick() {
    let valido = true;
    if (cicloValue === "") {
      enqueueSnackbar("Debe Ingresar Ciclo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueEscuela === "") {
      enqueueSnackbar("Debe Ingresar Escuela", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valueCampus === "") {
      enqueueSnackbar("Debe Ingresar Campus", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
      valido = false;
    }
    if (valido) {
      setLoading(true);
      try {
        const datos = [];
        let url = `/reportes/previo_bono_productividad?ciclo=${cicloValue}&id_campus=${valueCampus.value}&id_escuela=${valueEscuela.value}`;
        if (valueDireccion) url += `&id_direccion=${valueDireccion.value}`;
        const request = url;
        const response = await getRequest(request);

        if (response.s === "OK") {
          const nro = response.d[0]["ciclo"].length;
          for (let i = 0; i < nro; i++) {
            datos.push([
              response.d[0]["ciclo"][i],
              response.d[1]["campus"][i],
              response.d[2]["escuela"][i],
              response.d[3]["direccion"][i],
              response.d[4]["director"][i],
              response.d[5]["clave_profesor"][i],
              response.d[6]["nombre_profesor"][i],
              response.d[7]["grupo"][i],
              response.d[8]["clave_materia"][i],
              response.d[9]["nombre_materia"][i],
              response.d[10]["asignados"][i],
              response.d[11]["falta"][i],
              response.d[12]["falta_autorizada"][i],
              response.d[13]["sustitucion"][i],
              response.d[14]["reposicion"][i],
              response.d[15]["adicional"][i],
              response.d[16]["retardo"][i],
              response.d[17]["salida_anticipada"][i],
              response.d[18]["total_clases"][i],
              response.d[19]["fecha_alta"][i],
              response.d[20]["fecha_baja"][i],
              response.d[21]["evaluacion"][i],
              response.d[22]["marca"][i],
              response.d[23]["evaluacion_promedio"][i],
              response.d[24]["bono"][i],
            ]);
          }

          setData(datos);
          setDataOriginal(datos);
          setTotalValue(nro);
          setShowTable(true);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        } else {
          setLoading(false);
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      } finally {
        setLoading(false);
      }
    }
  }

  const handleSortByName = async () => {
    setLoading(true);
    setOrderByName(true);
    const sortedByName = data.sort((a, b) =>
      a[6] > b[6] ? 1 : b[6] > a[6] ? -1 : 0
    );
    setData(sortedByName);
    setDataOriginal(sortedByName);
    await sleep(500);
    setLoading(false);
  };

  const handleSortByNote = async () => {
    setLoading(true);
    setOrderByName(false);
    const sortedByNote = data.sort((a, b) =>
      a[21] < b[21] ? 1 : b[21] < a[21] ? -1 : 0
    );
    setData(sortedByNote);
    await sleep(500);
    setLoading(false);
  };

  return (
    <Contenedor title="Reportes / Previo Bono de Productividad">
      <Grid
        container
        className="nuevoUsuarioForm"
        style={{ position: "relative", zIndex: 9 }}
      >
        {/* Container Formulario */}
        <Grid container item xs={12} sm={12} style={{ marginLeft: "-4%" }}>
          {/* Primera Fila */}
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              xs={10}
              sm={1}
              style={{
                textAlign: "end",
                marginLeft: "-0.3%",
                marginRight: "0.3%",
              }}
            >
              <label>Ciclo(*):</label>
            </Grid>
            <Grid item xs={14} sm={2} style={{ zIndex: 99 }}>
              <input
                className="filter-input input-ciclo"
                placeholder="Indique el ciclo..."
                value={cicloValue}
                onChange={(e) => setCicloValue(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={2}
              style={{ textAlign: "end", marginLeft: "-9%" }}
            >
              <label>Campus(*):</label>
            </Grid>
            <Grid item xs={10} sm={2}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={valueCampus}
                options={campusValores}
                onChange={setValueCampus}
              />
            </Grid>
            <Grid xs={1} sm={2} item style={{ marginLeft: "-8%" }}>
              <label style={{ float: "right" }}>Ordenar Por:</label>
            </Grid>
            <Grid
              item
              xs={7}
              sm={4}
              style={{ marginTop: "0.5%", marginLeft: "-1%" }}
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="nuevoFormularioLabel"
                >
                  <FormControlLabel
                    value="nombreProfesor"
                    control={<Radio />}
                    onClick={() => handleSortByName()}
                    label="Nombre Profesor"
                  />
                  <FormControlLabel
                    value="concepto"
                    control={<Radio />}
                    onClick={() => handleSortByNote()}
                    label="Promedio"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {/* Segunda Fila */}
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              xs={10}
              sm={1}
              style={{ textAlign: "end", padding: "25px 0" }}
            >
              <label>Escuela(*):</label>
            </Grid>
            <Grid item xs={10} sm={2}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                value={valueEscuela}
                options={optionsEscuela}
                onChange={setValueEscuela}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={2}
              style={{
                textAlign: "end",
                marginLeft: "-9.5%",
                marginRight: "0.5%",
              }}
            >
              <label>Dirección:</label>
            </Grid>
            <Grid item xs={10} sm={2}>
              <Select
                className="select-modal-container"
                classNamePrefix="select-modal"
                isClearable={true}
                value={valueDireccion}
                options={optionsDireccion}
                onChange={setValueDireccion}
              />
            </Grid>
            <Grid
              item
              xs={10}
              sm={1}
              style={{
                textAlign: "end",
                marginLeft: "-2.4%",
                marginRight: "3%",
              }}
            >
              <label>Total:</label>
            </Grid>
            <Grid item xs={10} sm={1}>
              <input className="filter-input" value={totalValue} />
            </Grid>
          </Grid>
          {/* Tercera Fila */}
        </Grid>
        {/* Container Botones */}
        <Grid
          container
          item
          xs={12}
          sm={3}
          spacing={2}
          style={{ marginLeft: "-21%" }}
        >
          <Grid
            container
            item
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              gap: 16,
            }}
          >
            <EditButton
              onClick={handleClick}
              style={{ padding: "10px 25px" }}
              disabled={loading}
            >
              Generar
            </EditButton>
            <div style={{ display: "flex" }} className="export-buttons">
              <Typography style={{ marginTop: "auto", marginBottom: "auto" }}>
                Descargar:
              </Typography>
              <div>
                <button
                  onClick={DownloadExcel}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={excelIcon} alt="excel-icon" />
                </button>
                <button
                  onClick={DownloadPDF}
                  disabled={!showTable || req}
                  className={"buttonDownload"}
                  variant="contained"
                >
                  <img src={pdfFileIcon} alt="pdf-file-icon" />
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "1%", alignItems: "baseline" }}>
        <Grid item xs={12} md={5}>
          <Typography style={{ fontWeight: "bold" }}>
            Fecha Reporte:
            {showTable && (
              <>
                {moment().format("DD/MM/YYYY") +
                  " - " +
                  moment().format("HH:mm:ss")}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item md={3} style={{ marginLeft: "auto" }}>
          <SearchInput hideIcon search={search} />
        </Grid>
      </Grid>

      <div style={{ height: "calc(100% - 190px)" }}>
        <DataTable
          headers={headers}
          data={data}
          loading={loading}
          centrar={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          paginate
          bordes
        />
      </div>
    </Contenedor>
  );
};

export default PrevioBonoProductividad;
