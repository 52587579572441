import React from "react";
import { Grid } from "@material-ui/core";
import ModalInput from "../ModalInput";
import ModalSelect from "../ModalSelect";

const ModalCreateConceptoPago = ({
  setClave,
  setDescription,
  setType,
  setMaxImport,
  setShowImporte,
  setShowSchedule,
}) => {
  const importOptions = [
    {
      value: "importe_de_horas",
      label: "importe_de_horas",
    },
    {
      value: "importe_de_unidades",
      label: "importe_de_unidades",
    },
    {
      value: "importe",
      label: "importe",
    },
  ];
  const booleanOptions = [
    {
      value: 1,
      label: "True",
    },
    {
      value: 0,
      label: "False",
    },
  ];
  return (
    <Grid style={{ padding: 20, paddingTop: 0}}>
      <ModalInput lable={"Clave:"} onChange={(event) => setClave(event)} />
      <ModalInput
        lable={"Descripción:"}
        onChange={(event) => setDescription(event)}
      />
      <ModalSelect
        label={"Tipo"}
        options={importOptions}
        onChange={(event) => setType(event)}
      />
      <ModalInput
        lable={"Importe:"}
        type={"number"}
        onChange={(event) => setMaxImport(event)}
      />
      <ModalSelect
        label={"Mostar Importe"}
        options={booleanOptions}
        onChange={(event) => setShowImporte(event)}
      />
      <ModalSelect
        label={"Mostar Horario"}
        options={booleanOptions}
        onChange={(event) => setShowSchedule(event)}
      />
    </Grid>
  );
};

export default ModalCreateConceptoPago;
