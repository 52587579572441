import React from "react";
import { Grid } from "@material-ui/core";
import Select from "react-select";

const ModalSelect = ({
  label,
  options,
  defaultValue = "",
  onChange,
  isLoading = false,
  width = "",
  isClearable = true,
  value,
}) => {
  return (
    <Grid
      container
      style={{ marginTop: 20, display: "flex", alignItems: "center" }}
    >
      <Grid item sm={2} style={{ textAlign: "end", marginRight: 20 }}>
        <label>{label}:</label>
      </Grid>
      <Grid item style={{ flexGrow: 1, width: width === "" ? "" : width }}>
        <Select
          className="select-modal-container"
          classNamePrefix="select-modal"
          options={options}
          isClearable={isClearable}
          defaultValue={defaultValue}
          onChange={(event) => {
            onChange(event)
          }}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          styles={{
            maxWidth: 604,
            menu: (styles) => ({
              ...styles,
              zIndex: 999,
            }),
            indicatorsContainer: (styles) => ({
              ...styles,
            }),
          }}
          isLoading={isLoading}
          value={value}
        />
      </Grid>
    </Grid>
  );
};

export default ModalSelect;
