
import { View, Text } from '@react-pdf/renderer'
import { styles } from '../pdf_table/styled'

export default function ReporteGradoSuperiorAcademicoPDFTable({
    metadata,
    data,
    campus,
    escuela,
    ciclo
}) {

    const sum_grupos = data.reduce((previous, current) => { return previous + current.grupos; }, 0);
    const sum_horas = data.reduce((previous, current) => { return previous + current.horas; }, 0);
    const licenciatura = data.reduce((previous, current) => { return previous + current.licenciatura; }, 0);
    const especialidad = data.reduce((previous, current) => { return previous + current.especialidad; }, 0);
    const maestria = data.reduce((previous, current) => { return previous + current.maestria; }, 0);
    const doctorado = data.reduce((previous, current) => { return previous + current.doctorado; }, 0);
    const post_doctorado = data.reduce((previous, current) => { return previous + current.post_doctorado; }, 0);
    const horas_gsi = data.reduce((previous, current) => { return previous + current.horas_gsi; }, 0);

    const total = {
        direccion: 'TOTALES',
        grupos: sum_grupos,
        horas: sum_horas,
        licenciatura: licenciatura,
        especialidad: especialidad,
        maestria: maestria,
        doctorado: doctorado,
        post_doctorado: post_doctorado,
        horas_gsi: horas_gsi,
        porcentaje_gsi: ((horas_gsi / sum_horas ) * 100).toFixed(2)
    }
    data.push(total)

    const Headers = () => {
        return (
            <View style={{ flexDirection: 'row' }}>
                {
                    metadata && (
                        metadata.map((e, i) => (
                            <View key={i} style={{ width: e.width, ...styles.boxHeader, justifyContent: e.align }}>
                                <Text style={styles.cell}>{e.name}</Text>
                            </View>
                        ))
                    )
                }
            </View>
        )
    }

    return (
        <View style={{ marginTop: 0 }} >
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 0 }}>
                {campus ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Ciclo: {ciclo}</Text>) : null}
                {campus ? (<Text style={{ fontSize: 10, color: '#000000', marginRight: '25px' }}>Campus: {campus}</Text>) : null}
            </View>
            <View style={{ flexDirection: 'row', width: '100%', marginTop: 10, justifyContent: "space-between" }}>
                <Text style={{ fontSize: 10, color: '#000000' }}>Escuela: {escuela}</Text>
            </View>

            <View style={{ marginTop: 10, borderLeft: '1px', borderLeftColor: '#828282', borderTop: '1px', borderTopColor: '#828282' }}>
                <Headers />
                {
                    data && (
                        data.map((e, i) => (
                            <View style={{ flexDirection: 'row' }} key={i}>
                                {metadata.map((el, il) => (
                                    <View key={il} style={{ width: el.width, ...styles.boxRow, justifyContent: el.align, borderTop: i === 0 ? "1px" : "0px" }}>
                                        {
                                            el.fn === null ? (
                                                <Text style={{ ...styles.rows }}>{e[el.id]}</Text>
                                            ) :
                                                <Text> {el.fn(e[el.id])}</Text>
                                        }
                                    </View>
                                ))}
                            </View>
                        ))
                    )
                }
            </View>
        </View>
    )
}